import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { checkAuth } from '../../actions/auth'
import { fetchStationById, updateStation } from '../../actions/station'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { Link } from 'react-router-dom'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_RANGE, TIMEZONE } from '../../config'
import { error } from '../../helpers/notifications'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'
import markerStation from'../../img/marker-station.png'
import moment from 'moment-timezone'


interface StationsAuthorizedEditProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  station: any,
  checkAuth: () => Promise<any>,
  fetchStationById: (id: number) => Promise<any>,
  updateStation: (options:{station: any}) => Promise<void>,
}


interface StationsAuthorizedEditState {
  station: any,
  id: string,
  custom_id: string,
  phone: string,
  refueled: string,
  registered: string,
  range: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
  formSubmitted: boolean,
  timezone: string
}


export class StationsAuthorizedEdit extends React.Component<StationsAuthorizedEditProps, StationsAuthorizedEditState> {


  state = {
    station: [],
    id: '',
    custom_id: '',
    phone: '',
    refueled: '',
    registered: '',
    range: DEFAULT_RANGE,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    formSubmitted: false,
    timezone: TIMEZONE
  }


  componentDidMount() {

    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    this.props.checkAuth()

    this.props.fetchStationById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.station.status === fetchStates.success) {
        const { station_id, custom_id, phone, refueled, registered, zoom, range, lat, lng } = this.props.station.station;
        this.setState({
          id: station_id,
          custom_id: custom_id,
          phone: phone !== undefined && phone !== null ? phone : '',
          refueled: refueled,
          registered: registered,
          mapLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          mapLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          markerLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          markerLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM,
          range: range !== undefined && range !== null ? range : DEFAULT_RANGE
        })
        this.drawMapCircle()
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    if(this.state.custom_id === '') {
      this.props.station.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2313)
    }
    const station = { 
      id: this.state.id, 
      custom_id: this.state.custom_id, 
      phone: this.state.phone,
      zoom: this.state.mapZoom,
      range: this.state.range,
      lat: this.state.markerLat,
      lng: this.state.markerLng 
    } 
    this.props.updateStation({ station })
    .then(() => {
      this.setState({ formSubmitted: false })
    })
  }


  handleRangeChange = (event: any) => {
    this.setState({ range: event.target.value });
  }


  handleMapClick = (event: any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
    })
    this.updateMapCenter()
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  drawMapCircle = () => {
    return <Circle
    // required
    center={{
      lat: this.state.markerLat,
      lng: this.state.markerLng
    }}
    // required
    options={{
      strokeColor: '#248E63',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#248E63',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: this.state.range,
      zIndex: 1
    }}
  />
  }


  render() {

    const { status, fields } = this.props.station;
    const { custom_id, phone, refueled, registered, range, mapLat, mapLng, mapZoom, markerLat, markerLng, formSubmitted, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(111)}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2017)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="phone">
                {t(2019)}
              </label>
              <input 
                type="text" 
                name="phone"
                id="phone" 
                value={phone}
                onChange={e => this.setState({ phone: e.target.value })}
              />
            </div>


            <div className="map-constraints-slider"> 
              <label htmlFor="range">{range + ' m'}</label>
              <input type="range" id="range" value={range} name="range" min="1" max="100" onChange={this.handleRangeChange}/>
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value={t(112)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            

            <div className="form-group">
              <label htmlFor="map">
                {markerLat && markerLng ? `${t(2021)}: ${markerLat}, ${markerLng}` : 'Click on the map to select a station postion'}
              </label>
                <GoogleMap
                  center={{ lat: mapLat, lng: mapLng }}
                  zoom={ mapZoom }
                  onClick={ (e) => this.handleMapClick(e) }
                >
                  { this.drawMapCircle() }

                  <Marker
                    position={{
                      lat: markerLat,
                      lng: markerLng
                    }}
                    icon={
                      // @ts-expect-error
                      new window.google.maps.MarkerImage(
                        markerStation,
                        null, /* size is determined at runtime */
                        null, /* origin is 0,0 */
                        null, /* anchor is bottom center of the scaled image */
                        new window.google.maps.Size(48, 48)
                      )
                    }
                  >
                  </Marker>

                  <div className="map-constraints-container" />
                </GoogleMap>
                <span className='form-group__explanation'>
                  {t(105)} <Link to='/settings'>{t(106)}</Link>
                </span>
            </div>
            

            <div className="form-group">
              <label htmlFor="refueled">
                {t(2022)}
              </label>
              <input 
                type="text" 
                name="refueled"
                id="refueled" 
                value={refueled}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="registered">
                {t(2023)}
              </label>
              <input 
                type="text" 
                name="registered"
                id="registered" 
                value={moment(registered).tz(timezone).format('DD/MM/YYYY')}
                disabled
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ station, auth }: RootState) => ({ station, auth }),
  { fetchStationById, updateStation, checkAuth }
)(StationsAuthorizedEdit)))
import t from '../components/translation/translate'


function alertTypesTitle(alert_type: number) {
  switch(alert_type) {
    case 1:
      return t(2078)
    case 2:
      return t(2079)
    case 3:
      return t(2080)
    case 4:
      return t(2081)
    case 5:
      return t(2082)
    case 6:
      return t(2083)
    case 7:
      return t(2084)
    case 8:
      return t(2413)
    case 9:
      return t(2435)
    default:
      return null
  }
}


export default alertTypesTitle
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchRefueledVehicleById } from '../../actions/vehicle'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Preloader from '../partials/Preloader'
import { IconStations, IconVisibility, IconNoData } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'


interface VehiclesRefuellHistoryProps {
  router: { params: {id: string}, location: {search: string}, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  fetchRefueledVehicleById: (options:{id: number, limit: number, skip: number}) => Promise<any>,
}


interface VehiclesRefuellHistoryState {
  vehicleRefueled: any, 
  vehicleRefueledCount: number, 
  vehicle: { custom_id: string, module_imei: string, connected: any, },
  currentPage: number,
  perPage: number,
  module_imei: string,
  noData: boolean,
  timezone: string
}


export class VehiclesRefuellHistory extends React.Component<VehiclesRefuellHistoryProps, VehiclesRefuellHistoryState> {

  state = {
    vehicleRefueled: [], 
    vehicleRefueledCount: 0, 
    vehicle: { custom_id: '', module_imei: '', connected: null, },
    currentPage: 0,
    perPage: 10,
    module_imei: '',
    noData: false,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
      this.props.router.navigate(`/vehicles/refuelled/${parseInt(this.props.router.params.id)}?page=${currentPage}&perPage=${perPage}`)
      }
    }
    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.props.router.navigate(`/vehicles/refuelled/${parseInt(this.props.router.params.id)}?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/vehicles/refuelled/${parseInt(this.props.router.params.id)}?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/vehicles/refuelled/${parseInt(this.props.router.params.id)}?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    const { perPage, currentPage } = options
    this.props.fetchRefueledVehicleById({ id: parseInt(this.props.router.params.id), limit: perPage, skip: perPage * currentPage })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
      const { vehicleRefueled, vehicleRefueledCount, vehicle } = this.props.vehicle
      this.setState({ vehicleRefueled, vehicleRefueledCount, vehicle, noData: false })
      }
      if(this.props.vehicle.vehicleRefueled && this.props.vehicle.vehicleRefueled.length === 0) {
        this.setState({ noData: true })
      }
    }) 
  }


  render() {

    const { status } = this.props.vehicle;
    const { vehicleRefueled, vehicleRefueledCount, vehicle, currentPage, perPage, noData, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          padding={vehicleRefueled && vehicleRefueled.length > 0 ? [''] : ['medium']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id, 10)}
          animate
        >
          { vehicle.connected !== null ? (
            <>
              { vehicle && noData === true ? 
                (
                  <CtaBox padding={false} type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                    {t(2176)}
                  </CtaBox>
                ) : (
                  <div className="table-wrapper">
                    <table className="table">
                      <TableHeader 
                        columns={['', 'ID', t(2050), t(2146), t(2177), t(2178)]} 
                      />
                        <tbody className="table__body table__body--striped">
                          {vehicleRefueled !== undefined && vehicleRefueled.length > 0 && 
                          vehicleRefueled.map((item: {id: number, refuel_ts: number, unlocked: number, loc_lt: number, loc_ln: number}) => {
                            return (
                            <tr key={item.id}>
                              <td className="simple">
                                <Link to={`/vehicles/refuelled/location/${item.id}`}>
                                  <IconVisibility task button color="#ffffff" />
                                </Link>
                              </td>
                              <td>
                                {item.id}
                              </td>
                              <td>
                                {moment(moment.utc(item.refuel_ts).toDate()).tz(timezone).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>
                                <Link to={`/vehicles/refuelled/location/${item.id}`}>
                                  {item.unlocked === 0 && (<IconStations table error color="#ffffff" />)}
                                  {item.unlocked === 1 && (<IconStations table success color="#ffffff" />)}
                                </Link>
                              </td>
                              <td>
                                {item.loc_lt}
                              </td>
                              <td>
                                {item.loc_ln}
                              </td>
                            </tr> 
                            )
                          })}
                        </tbody>
                      <TableFooter 
                        button={false}
                        colSpan={11}
                        currentPage={ currentPage }
                        perPage={ perPage }
                        itemsCount={ vehicleRefueledCount }
                        prevPage={() => this.prevPage(currentPage - 1)}
                        nextPage={() => this.nextPage(currentPage + 1)}
                        updatePagination={this.updatePagination}
                      />
                    </table>
                  </div>
                )
              }
            </>
          ) : (<></>)}
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle }: RootState) => ({ auth, vehicle }),
  { fetchRefueledVehicleById }
)(VehiclesRefuellHistory)))
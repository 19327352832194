import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllNewReports, fetchAllNewReportsSearch, fetchNewReportsDatesAll } from '../../actions/report'
import { fetchAllAuthGroups } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import { Link } from 'react-router-dom'
import Card from '../partials/Card'
import { Tooltip } from 'react-tooltip'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Preloader from '../partials/Preloader'
import { IconDetails } from '../partials/Icons'
import DatePicker from 'react-datepicker'
import queryString from 'query-string'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import moment from 'moment-timezone'
import createNewDayReportPDF from '../../functions/createNewDayReportPDF'
import createNewDayReportCSV from '../../functions/createNewDayReportCSV'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import withRouter from '../partials/WithRouter'


interface ReportsProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  report: any,
  group: any,
  auth: any,
  fetchAllAuthGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchNewReportsDatesAll: (options: { from: string, to: string, group_id: string }) => Promise<any>,
  fetchAllNewReportsSearch: (options: { search: string, limit: number, skip: number }) => Promise<any>,
  fetchAllNewReports: (options: { limit: number, skip: number, from: string, to: string, group_id: string }) => Promise<any>,
}


interface ReportsState {
  reports_new: any, 
  reportsNewCount: number,
  currentPage: number,
  perPage: number,
  search: string,
  filterDateFrom: any,
  filterDateTo: any,
  todayDate: any,
  defaultToDate: any,
  authGroups: Array<string>,
  group_id: string,
}


export class Reports extends React.Component<ReportsProps, ReportsState> {

  state = {
    reports_new: [], 
    reportsNewCount: 0,
    currentPage: 0,
    perPage: 10,
    search: '',
    filterDateFrom:  moment().subtract(1, 'month').toDate(),
    filterDateTo: moment().toDate(),
    todayDate: moment().toDate(),
    defaultToDate: moment().toDate(),
    authGroups: [],
    group_id: 'all',
  }


  componentDidMount() {
    // fetch groups list
    this.props.fetchAllAuthGroups({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, authGroups } = this.props.group
      if(status === fetchStates.success) {
        this.setState({ authGroups })
      }
    })

    // set the defaults
    let { currentPage, perPage, search, group_id } = this.state;
    let filterDateFrom = moment(this.state.filterDateFrom).format('YYYY-MM-DD');
    let filterDateTo= moment(this.state.filterDateTo).format('YYYY-MM-DD');

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.from !== null && parsed.from !== undefined) {
      filterDateFrom = parsed.from.toString()
    }
    if(parsed.to !== null && parsed.to !== undefined) {
      filterDateTo = parsed.to.toString()
    }
    if(parsed.group !== null && parsed.group !== undefined) {
      group_id = parsed.group.toString()
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/reports?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
      }
    }
    if(parsed.search !== null && parsed.search !== undefined) {
      search = parsed.search.toString()
    }
    this.setState({ perPage, currentPage, search, filterDateFrom: moment(filterDateFrom).toDate(), filterDateTo: moment(filterDateTo).toDate(), group_id })
    if(search !== '') {
      return this.handleSearch(search)
    }
    this.reloadPage({ perPage, currentPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    const { filterDateFrom, filterDateTo, group_id } = this.state;
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    this.props.router.navigate(`/reports?page=0&perPage=${e.target.value}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { filterDateFrom, filterDateTo, group_id } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    this.props.router.navigate(`/reports?page=${prevPage}&perPage=${this.state.perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { filterDateFrom, filterDateTo, group_id } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    this.props.router.navigate(`/reports?page=${nextPage}&perPage=${this.state.perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, filterDateFrom: string, filterDateTo: string, group_id: string }) => {
    const { perPage, currentPage, filterDateFrom, filterDateTo, group_id } = options
    const { search } = this.state
    if(search === '') {
      return this.props.fetchAllNewReports({ limit: perPage, skip: perPage * currentPage, from: filterDateFrom, to: filterDateTo, group_id })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reports_new, reportsNewCount } = this.props.report
          this.setState({ reports_new, reportsNewCount })
        }
      })
    } else {
      return this.props.fetchAllNewReportsSearch({ search: search, limit: perPage, skip: perPage * currentPage })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reports_new, reportsNewCount } = this.props.report
          this.setState({ reports_new, reportsNewCount })
        }
      })
    }
  }


  handleSearch = (search: string) => {
    if(search !== '') {
      this.props.fetchAllNewReportsSearch({ search: search, limit: 10, skip: 0 })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reports_new, reportsNewCount } = this.props.report
          this.setState({ reports_new, reportsNewCount })
        }
      })
      this.props.router.navigate(`/reports?search=${search}`)
    }
  }


  handleSelectGroup = (group_id: string) => {
    const { currentPage, perPage, filterDateFrom, filterDateTo } = this.state
    this.setState({ group_id })
    this.reloadPage({ currentPage, perPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    this.props.router.navigate(`/reports?page=${this.state.currentPage}&perPage=${this.state.perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  handleSearchClose = () => {
    const { filterDateFrom, filterDateTo, group_id } = this.state
    this.setState({ search: '', currentPage: 0,  });
    this.props.fetchAllNewReports({ limit: this.state.perPage, skip: 0, from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    .then(() => {
      if(this.props.report.status === fetchStates.success) {
        const { reports_new, reportsNewCount } = this.props.report
        this.setState({ reports_new, reportsNewCount })
      }
    })
    this.props.router.navigate(`/reports`)
  }


  handleDownloadCSV = () => {
    const { filterDateFrom, filterDateTo, group_id } = this.state
    this.props.fetchNewReportsDatesAll({ from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    .then(() => {
      const data = this.props.report.reportsNewDatesAll
      if(data && data.length > 0) {
        createNewDayReportCSV({ data, from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD') });
      }
    });
  }


  handleDownloadPDF = () => {
    const { filterDateFrom, filterDateTo, group_id } = this.state
    this.props.fetchNewReportsDatesAll({ from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD'), group_id })
    .then(() => {
      const data = this.props.report.reportsNewDatesAll
      if(data && data.length > 0) {
        createNewDayReportPDF({ data, currency: this.props.auth.currency, from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD') });
      }
    });
  }


  updateFilterFromDate = (filterDateFrom: Date) => {
    this.setState({ filterDateFrom });
    const { perPage, currentPage, filterDateTo, group_id } = this.state;
    this.reloadPage({ perPage, currentPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id });
    this.props.router.navigate(`/reports?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  updateFilterToDate = (filterDateTo: Date) => {
    this.setState({ filterDateTo });
    const { perPage, currentPage, filterDateFrom, group_id } = this.state;
    this.reloadPage({ perPage, currentPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), group_id });
    this.props.router.navigate(`/reports?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  render() {

    const { status } = this.props.report;
    const { fields } = this.props.group;
    const { reports_new, reportsNewCount, currentPage, perPage, search, filterDateFrom, filterDateTo, todayDate, authGroups, group_id } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(5)} 
          time={false}
          animate
          search={true} 
          searchPlaceholder={`${t(228)} YYYY-MM-DD`}
          searchValue={search}
          handleSearchValue={e => this.setState({ search: e.target.value })} 
          handleSearch={() => this.handleSearch(search)}
          handleSearchClose={() => this.handleSearchClose()}
          button={true}
          buttonTitle='PDF'
          buttonClickable={true}
          buttonHandleOnClick={() => this.handleDownloadPDF()}
          buttonLink=''
          secondButton={true}
          secondButtonTitle='CSV'
          secondButtonClickable={true}
          secondButtonHandleOnClick={() => this.handleDownloadCSV()}
          secondButtonLink=''
        >
          <>

            <form className="form">
              <div className="filter">
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(1123)}</span>
                    <span className="filter_filter-select">
                      <DatePicker
                        selected={filterDateFrom}
                        selectsStart
                        onChange={this.updateFilterFromDate}
                        dateFormat='dd/MM/yyyy'
                        maxDate={filterDateTo}
                        required={true}
                      />
                    </span>
                </span>
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(1124)}</span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDateTo}
                      selectsStart
                      onChange={this.updateFilterToDate}
                      dateFormat='dd/MM/yyyy'
                      maxDate={todayDate}
                      minDate={filterDateFrom}
                      required={true}
                    />
                  </span>
                </span>


                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(2429)}</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleSelectGroup(e.target.value)}
                      className={classnames('', { 'input-error': fields && fields.includes('groups') })}
                      value={group_id}
                    >
                      <option key="0" value="all">{t(1152)}</option>
                      { 
                        authGroups.map((group: {group_id: string, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                      }
                    </select>
                  </span>
                </span>


              </div>
            </form>

            <div className="table-wrapper">
              <table className="table">
                <TableHeader 
                  columns={['', t(2050)]} 
                />
              <tbody className="table__body table__body--striped">
                {reports_new && reports_new.map((item: {d_stamp_to: number}, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="simple">
                        <Tooltip/>
                        <span data-tip={t(33)}>
                          <Link to={`/reports/date?date=${item.d_stamp_to}&group=${group_id}`}>
                            <IconDetails task button color="#ffffff" />
                          </Link>
                        </span>
                      </td>
                      <td>
                        {item.d_stamp_to}
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
                <TableFooter 
                  button={false}
                  colSpan={8}
                  currentPage={ currentPage }
                  perPage={ perPage }
                  itemsCount={ reportsNewCount }
                  prevPage={() => this.prevPage(currentPage - 1)}
                  nextPage={() => this.nextPage(currentPage + 1)}
                  updatePagination={this.updatePagination}
                />
              </table>
            </div>

          </>
        </Card>
      </TemplatePage>
    )
  }


}


export default withAuthorization(withRouter(connect(
  ({ report, auth, group }: RootState) => ({ report, auth, group }),
  { fetchAllNewReports, fetchAllNewReportsSearch, fetchNewReportsDatesAll, fetchAllAuthGroups }
)(Reports)))
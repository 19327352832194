import moment from 'moment-timezone'


const cardsCreateLegend = (routes: any, timezone: string, rfid: any) => {
  const markerColors = [ '#e91e63', '#9c27b0', '#3f51b5', '#03a9f4', '#009688', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#607d8b', '#f44336', '#9c27b0', '#3f51b5', '#4caf50', '#cddc39', '#ffc107', '#ff5722', '#9e9e9e', '#f72585', '#f3722c', '#7209b7', '#90be6d', '#4361ee', '#820263', '#291720', '#006ba6' ];
  let previousCard = routes[0].hwi_ca;
  let currentCard = routes[0].hwi_ca;
  let routeStart = routes[0].ts;
  let routeEnd = 0;
  let colorIndex = 0;
  let distance = 0;
  let legend: any = [];
  let custom_id = '';

  routes.map((route: {hwi_ca: string, rfid: number,}, index: number) => {
    currentCard = route.hwi_ca;
    if(currentCard !== previousCard || index === 1) {
      routeEnd = previousCard
      rfid.map((rfid: {rfid_number: string, custom_id: string}) => {
        if(rfid.rfid_number === route.hwi_ca) {
          custom_id = rfid.custom_id
        }
        return null
      })
      legend.push({ card: markerColors[colorIndex], routeStart: moment(moment.utc(routeStart*1000).toDate()).tz(timezone).format('HH:mm:ss'), routeEnd: moment(moment.utc(routeEnd*1000).toDate()).tz(timezone).format('HH:mm:ss'), duration: routeEnd - routeStart, distance: (distance).toFixed(2), hwi_ca: route.hwi_ca, custom_id });
      routeStart = currentCard
      colorIndex++;
    }
    previousCard = route.hwi_ca;
    return null;
  });

  if(legend && legend.length < 2) {
    legend = []
  }

  return legend
}


export default cardsCreateLegend
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllAccounts, addNewDistributorAccountGroup } from '../../actions/account'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { v4 as uuidv4 } from 'uuid'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'
import { IconClose } from '../partials/Icons'


interface DistributorAccountsAddProps {
  router: { navigate: (to: string) => any },
  account: any,
  fetchAllAccounts: (options: { limit: number, skip: number }) => Promise<any>,
  addNewDistributorAccountGroup: (account: any) => Promise<any>,
}


interface DistributorAccountsAddState {
  existingAccounts: Array<string>,
  selectedAccounts: Array<string>,
  unselectedAccounts: Array<string>,
  distributor_group_id: string,
  distributor_group_title: string,
  distributor_account_ids: Array<any>,
  formSubmitted: boolean,
}


export class DistributorAccountsAdd extends React.Component<DistributorAccountsAddProps, DistributorAccountsAddState> {

  state = {
    distributor_group_id: uuidv4(),
    distributor_group_title: '',
    distributor_account_ids: [],
    existingAccounts: [],
    selectedAccounts: [],
    unselectedAccounts: [],
    account_ids: [],
    formSubmitted: false,
  }


  componentDidMount() {
    this.props.fetchAllAccounts({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, accounts } = this.props.account;
      if(status === fetchStates.success) {
        const existingAccounts = accounts;
        const unselectedAccounts = accounts;
        this.setState({ existingAccounts, unselectedAccounts });
      }
    });
  }


  handleDistributorAccountSelect = (id: string) => {
    let selectedAccounts: any = [];
    this.state.existingAccounts.map((account:{account_id: string}) => {
      if(account.account_id === id) {
        selectedAccounts.push(...this.state.selectedAccounts, account);
      }
      return account.account_id !== id;
    });
    const filteredAccounts = this.state.unselectedAccounts.filter((account:{account_id: string}) => account.account_id !== id);
    this.setState({ selectedAccounts, unselectedAccounts: filteredAccounts });
  }


  handleDistributorAccountRemove = (id: string) => {
    const filteredAccounts = this.state.selectedAccounts.filter((account: {account_id: string}) => {
      if(account.account_id === id) {
        const unselectedAccounts: any = []
        unselectedAccounts.push(...this.state.unselectedAccounts, account)
        this.setState({ unselectedAccounts })
      }
      return account.account_id !== id
    })
    this.setState({ selectedAccounts: filteredAccounts })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { distributor_group_id, distributor_group_title, selectedAccounts } = this.state
    const distributor_account_groups = { distributor_group_id, distributor_group_title, distributor_account_ids: selectedAccounts.map((account:any) => account.account_id) }
    this.props.addNewDistributorAccountGroup(distributor_account_groups)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        this.props.router.navigate('/distributor-accounts');
      }
      this.setState({ formSubmitted: false });
    })
  }


  render() {

    const { fields, status } = this.props.account;
    const { distributor_group_title, selectedAccounts, unselectedAccounts, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(245)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          buttonLink='/distributor-accounts' 
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

          <div className="form-group">
            <label htmlFor="group_title">
              {t(1223)}
            </label>
            <input 
              type="text" 
              name="group_title"
              id="group_title" 
              value={distributor_group_title}
              onChange={e => this.setState({ distributor_group_title: e.target.value })}
              className={classnames('', { 'input-error': fields && fields.includes('distributor_group_title') })}
            />
          </div>

          <div className="form-group">
              <label htmlFor="distributor_account_ids">
                {t(1224)}
              </label>
              <div className="selected-tags">
                { selectedAccounts && selectedAccounts.map((account:{ account_id: string, company_name: string }) => (
                  <span className="tag tag--small" key={account.account_id}>
                    <span className="tag__title">{account.company_name}</span>
                    <span className="tag__remove" onClick={e => this.handleDistributorAccountRemove(account.account_id)}>
                      <IconClose color="#ffffff"/>
                    </span>
                  </span>
                ))}
              </div>
              <select 
                name="distributor_account_ids" 
                onChange={e => this.handleDistributorAccountSelect(e.target.value)}
                className={classnames('', { 'input-error': fields && fields.includes('distributor_account_ids') })}
              >
                <option key={0} value="">{t(1225)}</option>
                { 
                  unselectedAccounts.map((account:{ account_id: string, company_name: string }) => <option key={account.account_id} value={account.account_id}>{account.company_name}</option>)
                }
              </select>
            </div>

          <div className="form-group">
            <input 
              type="submit" 
              value={t(245)} 
              className="btn btn--primary card__footer--btn-left"
              disabled={ formSubmitted ? true : false }
            />
          </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account }: RootState) => ({ account }),
  { fetchAllAccounts, addNewDistributorAccountGroup }
)(DistributorAccountsAdd)))
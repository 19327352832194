import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { checkAuth } from '../../actions/auth'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import Switch from '../partials/Switch'
import t from '../translation/translate'
import getCookie from '../../functions/cookieGet'
import setCookie from '../../functions/cookieSet'
import { Store } from 'react-notifications-component'
import { PHRASES_NUMBERING_EXPIRE_DAYS } from '../../config'


interface SettingsAdminProps {
  router: { navigate: (to: string) => any },
  auth: {status: string, person_id: number, lat: number, lng: number, zoom: number, currency: string, language: string, timezone: string},
  checkAuth: () => Promise<any>,
}


interface SettingsAdminState {
  phrases_numbering: boolean,
}


export class SettingsAdmin extends React.Component<SettingsAdminProps, SettingsAdminState> {

  state = {
    phrases_numbering: false,
  }


  componentDidMount() {
    if(getCookie('zonePhrasesNumbering') === 'true') {
      this.setState({ phrases_numbering: true });
    }
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if(this.state.phrases_numbering === true) {
      setCookie('zonePhrasesNumbering', 'true', PHRASES_NUMBERING_EXPIRE_DAYS )
      Store.addNotification({
        message: "Phrases numbering for translations activated",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } else {
      setCookie('zonePhrasesNumbering', 'false', PHRASES_NUMBERING_EXPIRE_DAYS )
      Store.addNotification({
        message: "Phrases numbering for translations deactivated",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }


  render() {

    const { phrases_numbering } = this.state

    return (
      <TemplatePage>

        <Card 
          title={t(11)} 
          padding={['large']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate} 
          time={false}
          animate
          tabnav="settings"
          tabnavId={0}
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <h2 className="form-group__title">{t(2380)}</h2>
            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ phrases_numbering: !phrases_numbering })}>
                <Switch switchState={phrases_numbering} />
                <span className="label-title">{t(2381)}</span>
              </label>
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(104)} 
                className="btn btn--primary card__footer--btn-left"
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person }: RootState) => ({ auth, person }),
  { checkAuth }
)(SettingsAdmin)))
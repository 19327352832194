import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchSelectedVehicles, payVehiclesSelected, fetchAccountSettings, fetchTemporaryInvoiceNumber, calculateSubscribtionTotals } from '../../actions/invoice'
import { fetchAccountById } from '../../actions/account'
import Preloader from './Preloader'
import { checkAuth } from '../../actions/auth'
import fetchStates from '../../types/fetchStates'
import t from '../translation/translate'
import { IconPaypal, IconCreditCard, IconProformaInvoice, IconSelfManaged, IconNotifyInfo } from './Icons'
import classnames from 'classnames'


interface ModalSelectVehiclesProps {
  checkedArray: Array<string>,
  invoice: any,
  auth: any,
  account: any,
  setShowModal: (show: boolean) => any,
  checkAuth: () => any,
  fetchAccountById: (id: string) => any,
  fetchAccountSettings: () => any,
  fetchSelectedVehicles: (selectedVehicles: Array<string>) => any,
  fetchTemporaryInvoiceNumber: () => any,
  calculateSubscribtionTotals: (options: { selected_term: number, selected_vehicles: Array<string> }) => any,
  payVehiclesSelected: (options: { selected_term: number, selected_vehicles: Array<string>, temp_number: string, payment_method: string, sequential_number: number }) => any,
}


interface ModalSelectVehiclesState {
  formSubmitted: boolean,
  paymentOptionSelected: string,
  termOptionSelected: number,
  vehiclesSelected: Array<string>,
  total_cost: number,
  temp_number: string,
  sequential_number: number, 
  client_abb: string, 
  month: string, 
  year: string,
  option_2_savings: number,
  option_3_savings: number,
  subscribe_payment_limit_days: string
}


export class ModalSelectVehicles extends React.Component<ModalSelectVehiclesProps, ModalSelectVehiclesState> {

  state = {
    formSubmitted: false,
    paymentOptionSelected: 'none',
    termOptionSelected: 12,
    vehiclesSelected: [],
    total_cost: 0,
    temp_number: '',
    sequential_number: 0, 
    client_abb: '', 
    month: '', 
    year: '',
    option_2_savings: 0,
    option_3_savings: 0,
    subscribe_payment_limit_days: '0'
  }

  
  componentDidMount() {
    this.props.checkAuth()
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        if(this.props.auth.billing_selfmanaged) {
          this.setState({ paymentOptionSelected: 'selfmanaged'})
        }
        if(this.props.auth.billing_paypal) {
          this.setState({ paymentOptionSelected: 'paypal'})
        }
        if(this.props.auth.billing_stripe) {
          this.setState({ paymentOptionSelected: 'stripe'})
        }
        if(this.props.auth.billing_proforma) {
          this.setState({ paymentOptionSelected: 'proforma'})
        }
      }

      this.props.fetchAccountById(this.props.auth.account_id)
      .then(() => {
        const { subscribe_payment_limit_days } = this.props.account.account
        this.setState({ subscribe_payment_limit_days })
      });
    })
    if(this.props.checkedArray.length > 0) {
      this.props.fetchSelectedVehicles(this.props.checkedArray)
      .then(() => {
        if(this.props.invoice.status === fetchStates.success) {
          const { vehiclesSelected } = this.props.invoice;
          this.setState({ vehiclesSelected })
          this.props.fetchAccountSettings()
          .then(() => {
            if(this.props.invoice.status === fetchStates.success) {
              this.handleCalculateTotals(this.state.termOptionSelected)
            }
          })
        }
      })
    }
    this.props.fetchTemporaryInvoiceNumber()
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        const { temp_number, sequential_number, client_abb, month, year } = this.props.invoice;
        this.setState({ temp_number, sequential_number, client_abb, month, year })
      }
    })
  }





  handlePaymentOptionChange = (paymentOptionSelected: string) => {
    this.setState({ paymentOptionSelected })
  }


  handleTermOptionChange = (termOptionSelected: number) => {
    this.setState({ termOptionSelected })
    this.handleCalculateTotals(termOptionSelected)
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: false })
    const { termOptionSelected, temp_number, paymentOptionSelected, sequential_number } = this.state
    this.props.payVehiclesSelected({ selected_term: termOptionSelected, selected_vehicles: this.props.checkedArray, temp_number, payment_method: paymentOptionSelected, sequential_number })
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        this.props.setShowModal(false)
        this.setState({ total_cost: 0, vehiclesSelected: [], paymentOptionSelected: 'proforma',
        termOptionSelected: 12 })
      }
      if(this.props.invoice.status === fetchStates.error) {
        this.props.setShowModal(false)
        this.setState({ total_cost: 0, vehiclesSelected: [], paymentOptionSelected: 'proforma',
        termOptionSelected: 12 })
      }
    })
  }


  handleCalculateTotals = (termOptionSelected: number) => {
    this.props.calculateSubscribtionTotals({ selected_term: termOptionSelected, selected_vehicles: this.props.checkedArray })
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        const { subscription_total, option_2_savings, option_3_savings } = this.props.invoice
        this.setState({ total_cost: subscription_total, option_2_savings, option_3_savings })
      }
    })
  }


  render() {

    const { formSubmitted, paymentOptionSelected, termOptionSelected, vehiclesSelected, total_cost, option_2_savings, option_3_savings, temp_number, subscribe_payment_limit_days } = this.state
    const { status, fields } = this.props.invoice;
    const { billing_paypal, billing_stripe, billing_proforma, billing_selfmanaged, currency } = this.props.auth;

    console.log(this.props.account.account.subscribe_payment_limit_days)

    return (
      <div className="modal__content">

        { (!status || status === fetchStates.fetching) && <Preloader type="fullscreen" /> }

        <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>

          <div className="modal__title">
            <h2>{t(142)}</h2>
          </div>

          <div className="modal__body" ref="myscroll">
            <div className="modal-body__section">
              <h3>{t(143)}</h3>
              <ul className="selected__vehicles">
              {
                vehiclesSelected && vehiclesSelected.length > 0 && vehiclesSelected.map((vehicle: { vehicle_id: string, custom_id: string}) => (
                  <li key={vehicle.vehicle_id}>{vehicle.custom_id}</li>
                ))
              }
              </ul>

            </div>

            <div className="modal-body__section">
              <h3>{t(145)}</h3>
              <div className="payment__options">
                { billing_paypal &&
                  <div className={classnames('payment-option payment-option--stripe', { 
                    'payment-option--selected': paymentOptionSelected === 'stripe'
                    })} onClick={() => this.handlePaymentOptionChange('stripe')}>
                    <div className="payment-option__title">{t(146)}</div>
                    <IconCreditCard />
                  </div>
                }
                { billing_stripe &&
                  <div className={classnames('payment-option payment-option--stripe', { 
                    'payment-option--selected': paymentOptionSelected === 'paypal'
                    })} onClick={() => this.handlePaymentOptionChange('paypal')}>
                    <div className="payment-option__title">{t(147)}</div>
                    <IconPaypal />
                  </div>
                }
                {
                  billing_proforma &&
                  <div className={classnames('payment-option payment-option--proforma', { 
                    'payment-option--selected': paymentOptionSelected === 'proforma'
                    })} onClick={() => this.handlePaymentOptionChange('proforma')}>
                    <div className="payment-option__title">{t(199)}</div>
                    <IconProformaInvoice />
                  </div>
                }
                {
                  billing_selfmanaged &&
                  <div className={classnames('payment-option payment-option--selfmanaged', { 
                    'payment-option--selected': paymentOptionSelected === 'selfmanaged'
                    })} onClick={() => this.handlePaymentOptionChange('selfmanaged')}>
                    <div className="payment-option__title">{t(200)}</div>
                    <IconSelfManaged />
                  </div>
                }
              </div>
            </div>

            {
              billing_proforma && paymentOptionSelected === 'proforma' &&
              <div className="modal-body__section">
                <div className="modal-body__section">
                  <h3>{t(199)}</h3>
                  <div className="form-group">
                    <label htmlFor="temp_number">
                      {t(1166)}
                    </label>
                    <input 
                      type="text" 
                      name="temp_number"
                      id="temp_number" 
                      disabled
                      value={ temp_number }
                      onChange={e => this.setState({ temp_number: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('temp_number') })}
                    />
                  </div>
                </div>
              </div>
            }


            <div className="modal-body__section">
              <div className="modal-body__section">
                <h3>{t(144)}</h3>
                
                <div className="payment__term">
                  <div className={classnames('term-option', { 
                    'term-option--selected': termOptionSelected === 3
                    })} onClick={() => this.handleTermOptionChange(3)}>
                    <div className="payment-option__title">{t(148)}</div>
                  </div>
                  <div className={classnames('term-option', { 
                    'term-option--selected': termOptionSelected === 6
                    })} onClick={() => this.handleTermOptionChange(6)}>
                    <div className="payment-option__title">{t(149)}</div>
                    { option_2_savings > 0 && (
                      <div className="payment-option__savings">{ option_2_savings }{t(151)}</div>
                    )}
                  </div>
                  <div className={classnames('term-option', { 
                    'term-option--selected': termOptionSelected === 12
                    })} onClick={() => this.handleTermOptionChange(12)}>
                    <div className="payment-option__title">{t(150)}</div>
                    { option_3_savings > 0 && (
                      <div className="payment-option__savings">{ option_3_savings }{t(151)}</div>
                    )}
                  </div>
                </div>

              </div>
            </div>


            <div className="modal-body__section">
              <div className="modal-body__section">
                <h3>
                  {termOptionSelected === 3 && t(1054)}
                  {termOptionSelected === 6 && `${t(1055)} ${option_2_savings}${t(151)}`}
                  {termOptionSelected === 12 && `${t(1055)} ${option_3_savings}${t(151)}`}
                </h3>
                <div>{t(153)} <strong>{ vehiclesSelected.length }</strong></div>
                <div>{t(154)} <strong>{termOptionSelected} {t(152)} + difference if applicable</strong></div>
                <div className="payment__total">{ (total_cost).toFixed(2) + ' ' + currency }</div>
              </div>
            </div>


            <div className="modal-body__section">
              <div className="modal-body__section">
                <div className="msg msg--info"><IconNotifyInfo color="#ffffff" />{t(2519)}: {subscribe_payment_limit_days}</div>
              </div>
            </div>
          

          </div>
          <div className="modal__footer">
            <input 
              type="submit" 
              value={t(182)} 
              className="btn btn--primary btn--margin-right"
              disabled={ formSubmitted && status === 'fetching' ? true : false }
            />
          </div>

        </form>
      </div>
      
    )
  }
}


export default connect(
  ({ invoice, auth, account }: RootState) => ({ invoice, auth, account }),
  { checkAuth, fetchSelectedVehicles, payVehiclesSelected, fetchAccountSettings, fetchTemporaryInvoiceNumber, calculateSubscribtionTotals, fetchAccountById }
)(ModalSelectVehicles);
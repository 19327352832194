import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchVehicleById, fetchVehicleOverviewById, fetchPositionVehicleById, fetchVehicleToday } from '../../actions/vehicle'
import { fetchAllStations } from '../../actions/station'
import { fetchAllCompetitors } from '../../actions/competitor'
import { checkAuth } from '../../actions/auth'
import fetchStates from '../../types/fetchStates'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, TIMEZONE } from '../../config'
import { GoogleMap, Marker, Circle } from '@react-google-maps/api'
import markerVehicleMoving from'../../img/marker-vehicle-moving.png'
import markerVehicleStopped from'../../img/marker-vehicle-stopped.png'
import markerStation from'../../img/marker-station.png'
import markerCompetitor from'../../img/marker-competitor.png'
import { IconOverviewSpeed, IconOverviewRpm, IconOverviewFuelLevel, IconLinkOn, IconLockOpen, IconOverviewModuleSupply, IconOverviewGsmSignal, IconOverviewBattery, IconOverviewPetrolInjection, IconOverviewGasInjection, IconOverviewGasPressure, IconOverviewManifoldPressure, IconOverviewGasTemperature, IconOverviewTemperatureReducer, IconLinkOff, IconLockClosed, StatsDistance, IconAlertsGeoFence, StatsTime } from '../partials/Icons'
import moment from 'moment-timezone'
import 'moment-duration-format'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import routesCalculateDistance from '../../functions/routesCalculateDistance'
import overviewExplanationIntelligentGasLvl from '../../helpers/overview/overviewExplanationIntelligentGasLvl'
import SwitchFixed from '../partials/SwitchFixed'


// browser detect visibility change
let visibilityChange: string = '';
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange';
}


interface VehiclesNowProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  vehicle: any, 
  station: any, 
  competitor: any,
  checkAuth: () => Promise<any>,
  fetchPositionVehicleById: (options: {id: number, limit: number}) => Promise<any>,
  fetchVehicleById: (id: number) => Promise<any>,
  fetchVehicleToday: (id: number) => Promise<any>,
  fetchVehicleOverviewById: (id: number) => Promise<any>,
  fetchAllStations: (options: {limit: number, skip: number}) => Promise<any>,
  fetchAllCompetitors: (options: {limit: number, skip: number}) => Promise<any>,
}


interface VehiclesNowState {
  timezone: string,
  runInterval: boolean,
  mapLat: number,
  mapLng: number,
  markerLat: number,
  markerLng: number,
  mapZoom: number,
  custom_id: string,
  module_imei: string,
  vehicleFetchStatus: string,
  vehicleDataLoaded: boolean,
  overviewDataLoaded: boolean,
  routeDataLoaded: boolean,
  stationsDataLoaded: boolean,
  competitorsDataLoaded: boolean,
  vehicleOnline: boolean,
  vehicleIgnition: number,
  vehiclePaired: number,
  vehicleUnlocked: number,
  vehicleConnected: number,
  vehicleControllerConnected: number,
  vehicleControllerCompatible: number,
  vehicleFuelLvl: number,

  vehicleDistanceDefault: string, 
  vehicleDistanceAlternative: string, 
  vehicleTimeDefault: string, 
  vehicleTimeAlternative: string, 
  vehicleRouteStart: number, 
  vehicleRouteEnd: string, 
  vehicleDistance: string,
  vehicleTime: number,
  vehicleIgl: number,
  vehicleController: boolean,
  vehicleTodayLoaded: boolean,

  overviewGasInjectionTime: string,
  overviewGasPressure: string,
  overviewGasTemperature: string,
  overviewSupplyVoltage: string,
  overviewEngineRpm: string,
  overviewPetrolInjectionTime: string,
  overviewManifoldPressure: string,
  overviewReducerTemperature: string,
  overviewIgl: string,
  overviewGsmSignal: string,
  overviewGsmBattery: string,
  overviewGsmSupplyVoltage: string,
  overviewSpeed: string,
  stations: any,
  competitors: any,
  vehicleRoute: any,
  ts: number,
  intervalId: any,

  followRoute: boolean,
  mapref: any,
  draggedMapLat: number,
  draggedMapLng: number,
}


export class VehiclesNow extends React.Component<VehiclesNowProps, VehiclesNowState> {

  state = {    
    timezone: TIMEZONE,
    runInterval: true,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    markerLat: 0,
    markerLng: 0,
    mapZoom: DEFAULT_ZOOM,
    custom_id: '',
    module_imei: '',
    vehicleFetchStatus: '',
    vehicleDataLoaded: false,
    overviewDataLoaded: false,
    routeDataLoaded: false,
    stationsDataLoaded: false,
    competitorsDataLoaded: false,
    vehicleOnline: false,
    vehicleIgnition: 0,
    vehiclePaired: 0,
    vehicleUnlocked: 0,
    vehicleConnected: 0,
    vehicleControllerConnected: 0,
    vehicleControllerCompatible: 0,
    vehicleFuelLvl: -1,

    vehicleDistanceDefault: 'n/a', 
    vehicleDistanceAlternative: 'n/a', 
    vehicleTimeDefault: 'n/a', 
    vehicleTimeAlternative: 'n/a', 
    vehicleRouteStart: 0, 
    vehicleRouteEnd: 'n/a', 
    vehicleDistance: 'n/a',
    vehicleTime: 0,
    vehicleIgl: 0,
    vehicleController: false,
    vehicleTodayLoaded: false,

    overviewGasInjectionTime: 'n/a',
    overviewGasPressure: 'n/a',
    overviewGasTemperature: 'n/a',
    overviewSupplyVoltage: 'n/a',
    overviewEngineRpm: 'n/a',
    overviewPetrolInjectionTime: 'n/a',
    overviewManifoldPressure: 'n/a',
    overviewReducerTemperature: 'n/a',
    overviewIgl: 'n/a',
    overviewGsmSignal: 'n/a',
    overviewGsmBattery: 'n/a',
    overviewGsmSupplyVoltage: 'n/a',
    overviewSpeed: 'n/a',
    stations: [],
    competitors: [],
    vehicleRoute: [],
    ts: 0,
    intervalId: 0,

    followRoute: true,
    mapref: null,
    draggedMapLat: 53,
    draggedMapLng: 23,
  }


  handleVisibilityChange = () => {
    if (document['hidden']) {
      this.setState({runInterval: false});
    } else {
      this.setState({runInterval: true});
      this.props.checkAuth();
      this.getVehicleData();
      if(this.state.vehicleOnline === true) {
        this.getVehicleRoute(1);
        this.getVehicleOverview();
        this.getVehicleToday();
      }
    }
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // add event listener for visibilityChange
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

    const intervalId = setInterval(this.timer, 10000);
    this.setState({intervalId: intervalId});
    this.props.checkAuth();
    this.getVehicleRoute(1);
    this.getVehicleData();
    this.getVehicleOverview();
    this.getVehicleToday();
    if(this.props.auth.authorized.stations === true) {
      this.getStationsData();
    }
    if(this.props.auth.authorized.competitors === true) {
      this.getCompetitorsData();
    }
  }


  componentWillUnmount() {
    // remove event listener for visibilityChange
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    this.setState({ runInterval: false });
    clearInterval(this.state.intervalId);
  }


  timer = () => {
    if(this.state.followRoute === true) {
      this.setState({ runInterval: true });
    }
    this.props.checkAuth();
    this.getVehicleData();
    if(this.state.vehicleOnline === true) {
      this.getVehicleRoute(1);
      this.getVehicleOverview();
      this.getVehicleToday();
    }
  }


  getVehicleRoute = (limit: number) => {
    this.setState({ routeDataLoaded: false })
    this.props.fetchPositionVehicleById({ id: parseInt(this.props.router.params.id), limit })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        if(this.props.vehicle.vehiclePosition.length > 0) {
          const { vehiclePosition } = this.props.vehicle;
          const { ts, loc_lt, loc_ln } = this.props.vehicle.vehiclePosition[0];
          let vehicleRoute: any = this.state.vehicleRoute
          vehicleRoute = [...vehiclePosition,...vehicleRoute]
          //let vehicleRoute = vehiclePosition.filter((item,index) => index !== 0 && item)
          // latest vehicle position
          this.setState({
            ts: parseInt(ts),
            mapLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
            mapLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
            markerLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
            markerLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
            mapZoom: this.props.auth.zoom ? parseFloat(this.props.auth.zoom) : DEFAULT_ZOOM,
            vehicleRoute,
            routeDataLoaded: true
          });

          if(this.state.followRoute) {
            this.setState({
              draggedMapLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
              draggedMapLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
            })
          }
        } else {
          this.setState({ routeDataLoaded: false })
        }
      }
    })
  }


  getVehicleData = () => {
    this.setState({ vehicleDataLoaded: false })
    this.props.fetchVehicleById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { status } = this.props.vehicle;
        const { custom_id, module_imei, ignition, paired, unlocked, fuel_lvl, igl, connected, controller_connected, compatible } = this.props.vehicle.vehicle;
        let vehicleOnline = false;
        if(ignition === 1 && (parseInt(moment(connected).format('X')) > parseInt(moment().format('X')) - 300)) {
          vehicleOnline = true;
        }
        let vehicleController = false;
        if(controller_connected === 1 && compatible === 0) {
          vehicleController = true;
        }
        this.setState({ custom_id, module_imei, vehicleIgnition: ignition, vehicleFetchStatus: status, vehicleFuelLvl: fuel_lvl, vehiclePaired: paired, vehicleUnlocked: unlocked, vehicleIgl: igl, vehicleConnected: connected, vehicleControllerConnected: controller_connected, vehicleControllerCompatible: compatible, vehicleController, vehicleOnline, vehicleDataLoaded: true })
      }
    })
  }


  getVehicleOverview = () => {
    this.setState({ overviewDataLoaded: false })
    this.props.fetchVehicleOverviewById(parseInt(this.props.router.params.id, 10))
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        if(this.props.vehicle.vehicleOverview) {
          const { gmd_ig, gmd_gp, gmd_gt, gmd_vo, gmd_rp, gmd_ip, gmd_mp, gmd_rt, gmd_is, gsm_si, gsm_bt, gsm_sl, loc_sp } = this.props.vehicle.vehicleOverview;
          this.setState({
            overviewGasInjectionTime: gmd_ig === undefined || gmd_ig === null ? 'n/a' : gmd_ig + ' ms',
            overviewGasPressure: gmd_gp === undefined || gmd_gp === null ? 'n/a' : parseInt(gmd_gp)/100 + ' bar',
            overviewGasTemperature: gmd_gt === undefined || gmd_gt === null ? 'n/a' : gmd_gt + ' C',
            overviewSupplyVoltage: gmd_vo === undefined || gmd_vo === null ? 'n/a' : gmd_vo + ' V',
            overviewEngineRpm: gmd_rp === undefined || gmd_rp === null ? 'n/a' : gmd_rp + ' RPM',
            overviewPetrolInjectionTime: gmd_ip === undefined || gmd_ip === null ? 'n/a' : gmd_ip + ' ms',
            overviewManifoldPressure: gmd_mp === undefined || gmd_mp === null ? 'n/a' : parseInt(gmd_mp)/100 + ' bar',
            overviewReducerTemperature: gmd_rt === undefined || gmd_rt === null ? 'n/a' : gmd_rt + ' C',
            overviewIgl: gmd_is === undefined || gmd_is === null ? 'n/a' : gmd_is,
            overviewGsmSignal: gsm_si === undefined || gsm_si === null ? 'n/a' : gsm_si + ' %',
            overviewGsmBattery: gsm_bt === undefined || gsm_bt === null ? 'n/a' : gsm_bt + ' V',
            overviewGsmSupplyVoltage: gsm_sl === undefined || gsm_sl === null ? 'n/a' : gsm_sl + ' V',
            overviewSpeed: loc_sp === undefined || loc_sp === null ? 'n/a' : loc_sp,
            overviewDataLoaded: true
          });
        }
      }
    });
  }


  getVehicleToday = () => {
    this.setState({ vehicleTodayLoaded: false })
    this.props.fetchVehicleToday(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicleToday } = this.props.vehicle;
        if(vehicleToday && vehicleToday.length > 0) {
          const result = this.calculateVehicleSoFar(vehicleToday)
          this.setState({ 
            vehicleDistance: Math.round(result.vehicleDistance * 100) / 100 + ' km',
            vehicleTime: result.vehicleTime,
            vehicleRouteStart: result.vehicleRouteStart, 
            vehicleTodayLoaded: true
          })
        }
      }
    })
  }


  calculateVehicleSoFar = (vehicleToday: any) => {
    let vehicleDistance = 0
    let vehicleTime = 0
    let vehicleRouteStart = vehicleToday[0].ts * 1000
    // time
    let previousTime = vehicleToday[0].ts;
    let currentTime = vehicleToday[0].ts;
    let routeStart = vehicleToday[0].ts;
    let routeEnd = 0;
    // distance
    let distance = 0;
    let loc_ln1 = vehicleToday[0].loc_ln;
    let loc_lt1 = vehicleToday[0].loc_lt;
    let loc_ln2 = vehicleToday[0].loc_ln;
    let loc_lt2 = vehicleToday[0].loc_lt;



    vehicleToday.map((route: {loc_lt: number, loc_ln: number, ts: number}, index: number) => {
      // time
      currentTime = route.ts;
      if(currentTime - previousTime > 300 || index === vehicleToday.length - 1) {
        routeEnd = previousTime
        vehicleTime = vehicleTime + (routeEnd - routeStart)
        routeStart = currentTime
      }
      previousTime = route.ts;
      // distance
      loc_lt1 = route.loc_lt;
      loc_ln1 = route.loc_ln;
      let distanceDifference = routesCalculateDistance({loc_lt1, loc_ln1, loc_lt2, loc_ln2});
      distance = distance + distanceDifference;
      vehicleDistance = distance/1000;
      loc_lt2 = route.loc_lt;
      loc_ln2 = route.loc_ln;

      return null;
    });

    const result = { vehicleDistance, vehicleTime, vehicleRouteStart }
    return result
  }


  getStationsData = () => {
    this.props.fetchAllStations({ limit: 1000, skip: 0 })
    .then(() => {
      const { stations } = this.props.station
      if(stations.length > 0) {
        this.setState({ stations, stationsDataLoaded: true })
      }
    })
  }


  getCompetitorsData = () => {
    this.props.fetchAllCompetitors({ limit: 1000, skip: 0 })
    .then(() => {
      const { competitors } = this.props.competitor
      if(competitors.length > 0) {
        this.setState({ competitors, competitorsDataLoaded: true })
      }
    })
  }


  toggleFollowRoute = () => {
    this.setState({ followRoute: !this.state.followRoute });
    if(!this.state.followRoute === false) {
      this.setState({ runInterval: false });
    } else {
      this.setState({ runInterval: true });
    }
  }



  drawStationCircle = (options: { lat: number, lng: number, range: number }) => {
    const { lat, lng, range } = options
    return <Circle
      // required
      center={{
        lat, lng
      }}
      // required
      options={{
        strokeColor: '#248E63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#248E63',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: range,
        zIndex: 1
      }}
    />
  }


  drawCompetitorCircle = (options: { lat: number, lng: number, range: number }) => {
    const { lat, lng, range } = options
    return <Circle
      // required
      center={{
        lat, lng
      }}
      // required
      options={{
        strokeColor: '#E34B5F',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#E34B5F',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: range,
        zIndex: 1
      }}
    />
  }


  drawRouteMarker = (route: any, index: number) => {
    const lat = parseFloat(route.loc_lt);
    const lng = parseFloat(route.loc_ln);
    const deg = parseFloat(route.loc_hd);

    if(index !== 0) {
      const svgMarker = {
        path: "M18.2063158,6.92210526 L9.78526316,0.185263158 C9.47920442,-0.0565874416 9.04711137,-0.0565874416 8.74105263,0.185263158 L0.32,6.92210526 C0.118957956,7.08096622 0.00118128148,7.32271834 1.77635684e-15,7.57894737 L1.77635684e-15,14.3157895 C0.000916965625,14.6397132 0.187550499,14.9343977 0.48,15.0736842 C0.769917372,15.2112156 1.11279931,15.1720291 1.36421053,14.9726316 L9.26315789,8.65684211 L17.1621053,14.9726316 C17.3102647,15.0915037 17.4942617,15.1567929 17.6842105,15.1578947 C17.8099947,15.1599724 17.9343559,15.1310512 18.0463158,15.0736842 C18.3387653,14.9343977 18.5253988,14.6397132 18.5263158,14.3157895 L18.5263158,7.57894737 C18.5251345,7.32271834 18.4073578,7.08096622 18.2063158,6.92210526 Z",
        fillColor: '#2CBFFC',
        strokeColor: '#000000',
        fillOpacity: ((this.state.vehicleRoute.length/index) / 10 ),
        strokeWeight: 2,
        rotation: deg,
        scale: 1,
        anchor: new window.google.maps.Point(8, 16),
      };
  
      return <Marker
        key={index}
        zIndex={100}
        position={{
          lat,
          lng
        }}
        icon={svgMarker}
      />
    }
  }


  handleCenterChanged = () => {
    if(this.state.followRoute === false) {
      const mapref: any = this.state.mapref
      if (mapref !== null && mapref.getCenter()) {
        const newCenter:any = mapref.getCenter();
        this.setState({ draggedMapLat: newCenter.lat(), draggedMapLng: newCenter.lng()});
      }
    }

  };


  handleOnLoad = (map:any) => {
    this.setState({ mapref: map });
  };


  render() {

    const { mapLat, mapLng, mapZoom, markerLat, markerLng, custom_id, module_imei, vehicleConnected, vehicleOnline, vehicleControllerConnected, vehicleControllerCompatible, vehicleDataLoaded, vehiclePaired, vehicleIgl, vehicleUnlocked, overviewSpeed, vehicleFuelLvl, overviewEngineRpm, overviewGsmSignal, overviewGsmBattery, overviewGsmSupplyVoltage, overviewPetrolInjectionTime, overviewGasInjectionTime, overviewGasPressure, overviewManifoldPressure, overviewReducerTemperature, overviewGasTemperature, routeDataLoaded, stations, competitors, stationsDataLoaded, competitorsDataLoaded, vehicleController, vehicleRoute, vehicleRouteStart, vehicleDistance, vehicleTime, vehicleTodayLoaded, timezone, followRoute, draggedMapLat, draggedMapLng } = this.state;

    const { hide_lock_feature } = this.props.auth;

    return (
      <TemplatePage>

        <Card 
          button={false}
          title={`${custom_id} - IMEI ${module_imei}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          time={false}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id)}
          auth={this.props.auth}
          animate
        >
          <div className="vehicle-now">

            <div className="vehicle-now__vehicle-now">


              <div className="vehicle-now__state">
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                      'vehicle-overview__overview-item--data': vehicleOnline === true,
                    })}
                  >
                    <div className={classnames('vehicle-overview__overview-icon')}>
                      <IconOverviewSpeed size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(2212)} [Km/h]
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {overviewSpeed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                      'vehicle-overview__overview-item--data': vehicleOnline === true,
                    })}
                  >
                    <div className="vehicle-overview__overview-icon">
                      <IconOverviewRpm size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(2208)}
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {overviewEngineRpm}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                      'vehicle-overview__overview-item--data': vehicleOnline === true,
                    })}
                  >
                    <div
                      className={classnames('vehicle-overview__overview-icon', {          
                        'vehicle-overview__overview-icon--danger': vehicleFuelLvl === 0,
                        'vehicle-overview__overview-icon--warning': vehicleFuelLvl === 1 || vehicleFuelLvl === 2,
                        'vehicle-overview__overview-icon--success': vehicleFuelLvl === 3 || vehicleFuelLvl === 4,
                      })}
                    >
                      <IconOverviewFuelLevel size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(2072)} [0-4]
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {vehicleFuelLvl === -1 ? 'n/a' : vehicleFuelLvl}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleTodayLoaded === false,
                      'vehicle-overview__overview-item--data': vehicleTodayLoaded === true,
                    })}
                  >
                    <div className="vehicle-overview__overview-icon">
                      <StatsDistance color="#ffffff" size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(2052)}
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {vehicleDistance}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleTodayLoaded === false,
                      'vehicle-overview__overview-item--data': vehicleTodayLoaded === true,
                    })}
                  >
                    <div className="vehicle-overview__overview-icon">
                      <StatsTime color="#ffffff" size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(1031)}
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {vehicleTime !== 0 ? moment.duration(vehicleTime, 'seconds').format("hh:mm:ss") : vehicleTime}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vehicle-now__state-item">
                  <div className={classnames('vehicle-overview__overview-item', {          
                      'vehicle-overview__overview-item--nodata': vehicleTodayLoaded === false,
                      'vehicle-overview__overview-item--data': vehicleTodayLoaded === true,
                    })}
                  >
                    <div className="vehicle-overview__overview-icon">
                      <IconAlertsGeoFence color="#ffffff" size={24} />
                    </div>
                    <div className="vehicle-overview__overview-description">
                      <div className="vehicle-overview__overview-description-title">
                        {t(4265)}
                      </div>
                      <div className="vehicle-overview__overview-description-value">
                        {vehicleRouteStart !== 0 ? moment(vehicleRouteStart).tz(timezone).format("hh:mm:ss") : vehicleRouteStart}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="vehicle-now__map">
                <GoogleMap
                  center={{ lat: followRoute ? mapLat : draggedMapLat, lng: followRoute ? mapLng : draggedMapLng }}
                  zoom={ mapZoom }
                  onDragEnd={this.handleCenterChanged}
                  onLoad={this.handleOnLoad}
                >

                  { 
                    // DRAW STATION
                    stationsDataLoaded && stations.map((station: { station_id: number, lat: number, lng: number, range: number}) => {
                      return <Marker
                        key={station.station_id}
                        position={{
                          lat: station.lat,
                          lng: station.lng
                        }}
                        icon={
                          // @ts-expect-error
                          new window.google.maps.MarkerImage(
                            markerStation,
                            null, /* size is determined at runtime */
                            null, /* origin is 0,0 */
                            null, /* anchor is bottom center of the scaled image */
                            new window.google.maps.Size(48, 48)
                          )
                        }
                      >
                        { this.drawStationCircle({lat:station.lat, lng:station.lng, range:station.range}) }
                      </Marker>         
                    })
                  }



                  { 
                    // DRAW COMPETITOR STATION
                    competitorsDataLoaded && competitors.map((competitor: {station_id: number, lat: number, lng: number, range: number}) => {
                      return <Marker
                        key={competitor.station_id}
                        position={{
                          lat: competitor.lat,
                          lng: competitor.lng
                        }}
                        icon={
                          // @ts-expect-error
                          new window.google.maps.MarkerImage(
                            markerCompetitor,
                            null, /* size is determined at runtime */
                            null, /* origin is 0,0 */
                            null, /* anchor is bottom center of the scaled image */
                            new window.google.maps.Size(48, 48)
                          )
                        }
                      >
                        { this.drawCompetitorCircle({lat:competitor.lat, lng:competitor.lng, range:competitor.range}) }
                      </Marker>         

                    })
                  }



                  {
                    // DRAW ROUTE MARKER
                    routeDataLoaded && vehicleRoute.length > 0 && vehicleRoute.map((route, index) => {
                      return this.drawRouteMarker(route, index)
                    })
                  }



                  {
                    // DRAW VEHICLE MARKER (DRIVING/STOPPED)
                    routeDataLoaded && <Marker
                      position={{
                        lat: markerLat,
                        lng: markerLng
                      }}
                      icon={
                        // @ts-expect-error
                        new window.google.maps.MarkerImage(
                          vehicleOnline ? markerVehicleMoving : markerVehicleStopped,
                          null, /* size is determined at runtime */
                          null, /* origin is 0,0 */
                          null, /* anchor is bottom center of the scaled image */
                          new window.google.maps.Size(48, 48)
                        )
                      }
                    />
                  }

                
                <div className="map-constraints-container">
                <div className="map-follow-route--on-off">
                  <form className="form form--padding">
                    <div className="form-group__switch form-group__switch--fixed">
                      <label onClick={() => this.toggleFollowRoute()}>
                        <SwitchFixed switchState={followRoute} disabled={false} />
                        <span className="label-title">Follow the route</span>
                      </label>
                    </div>
                  </form>
                </div>
                </div>
                </GoogleMap>

              </div>


            </div>

            <div className="vehicle-now__vehicle-overview">
              <div className="vehicle-overview__content">
                <div className="vehicle-overview__overview">


                  <div className="vehicle-overview__overview-section overview-section--small">
                    <div className="overview-section__time">
                      {t(2496)}: <em className={classnames('', {          
                    'overview-section__time--error': vehicleOnline === false,
                    'overview-section__time--success': vehicleOnline === true,
                  })}>{vehicleConnected !== 0 ? moment(vehicleConnected).tz(timezone).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}</em>
                    </div>
                  </div>

                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleDataLoaded === false,
                        'vehicle-overview__overview-item--data': vehicleDataLoaded === true,
                      })}
                    >
                      <div
                        className={classnames('vehicle-overview__overview-icon', {          
                          'vehicle-overview__overview-icon--info': vehicleControllerConnected === 0,
                          'vehicle-overview__overview-icon--success': vehicleControllerConnected === 1
                        })}
                      >
                        <IconOverviewModuleSupply color="#ffffff" size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2494)}
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          { vehicleControllerConnected === 1 && 'CONNECTED' }
                          { vehicleControllerConnected === 0 && 'DISCONNECTED' }
                        </div>
                      </div>
                    </div>

                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleDataLoaded === false || vehicleControllerConnected === 0 || vehicleOnline === false,
                        'vehicle-overview__overview-item--data': vehicleDataLoaded === true,
                      })}
                    >
                      <div
                        className={classnames('vehicle-overview__overview-icon', {          
                          'vehicle-overview__overview-icon--danger': vehicleControllerCompatible === 1 && vehicleControllerConnected === 0,
                          'vehicle-overview__overview-icon--success': vehicleControllerCompatible === 0 && vehicleControllerConnected === 1,
                        })}
                      >
                        <IconOverviewModuleSupply color="#ffffff" size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2493)}
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          { vehicleOnline === true && vehicleControllerConnected === 1 && vehicleControllerCompatible === 0 && 'COMPATIBLE' }
                          { vehicleOnline === true && vehicleControllerConnected === 1 && vehicleControllerCompatible === 1 && 'INCOMPATIBLE' }
                          { (vehicleControllerConnected === 0 || vehicleOnline === false) && 'n/a'}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="vehicle-overview__overview-section">

                    { !hide_lock_feature &&
                      <div className={classnames('vehicle-overview__overview-item', {          
                          'vehicle-overview__overview-item--nodata': vehicleDataLoaded === false,
                          'vehicle-overview__overview-item--data': vehicleDataLoaded === true,
                        })}
                      >
                        <div
                          className={classnames('vehicle-overview__overview-icon', {          
                            'vehicle-overview__overview-icon--info': vehiclePaired === 0 || vehiclePaired === 2,
                            'vehicle-overview__overview-icon--success': vehiclePaired === 1,
                          })}
                        >
                          {
                            vehiclePaired === 1 ? (
                              <IconLinkOn color="#ffffff" size={24} />
                            ) : (
                              <IconLinkOff color="#ffffff" size={24} />
                            )
                          }

                        </div>
                        <div className="vehicle-overview__overview-description">
                          <div className="vehicle-overview__overview-description-title">
                            {t(2491)}
                          </div>
                          <div className="vehicle-overview__overview-description-value">
                            {vehiclePaired === 1 ? ('PAIRED') : ('UNPAIRED')}
                          </div>
                        </div>

                      </div>
                    }


                    { !hide_lock_feature &&
                      <div className={classnames('vehicle-overview__overview-item', {          
                          'vehicle-overview__overview-item--nodata': vehicleDataLoaded === false || vehiclePaired === 0 || vehiclePaired === 2,
                          'vehicle-overview__overview-item--data': vehicleDataLoaded === true && vehiclePaired === 1,
                        })}
                      >
                        <div
                          className={classnames('vehicle-overview__overview-icon', {          
                            'vehicle-overview__overview-icon--danger': vehiclePaired === 1 && vehicleUnlocked === 0,
                            'vehicle-overview__overview-icon--success': vehiclePaired === 1 && vehicleUnlocked === 1,
                          })}
                        >
                          {
                            vehicleUnlocked === 1 ? (
                              <IconLockOpen color="#ffffff" size={24} />
                            ) : (
                              <IconLockClosed color="#ffffff" size={24} />
                            )
                          }
                        </div>
                        <div className="vehicle-overview__overview-description">
                          <div className="vehicle-overview__overview-description-title">
                            {t(2492)}
                          </div>
                          <div className="vehicle-overview__overview-description-value">
                            {vehiclePaired === 1 && vehicleUnlocked === 1 && ('UNLOCKED')}
                            {vehiclePaired === 1 && vehicleUnlocked === 0 && ('LOCKED')}
                            {(vehiclePaired === 0 || vehiclePaired === 2) && 'n/a'}
                          </div>
                        </div>
                      </div>
                    }


                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleDataLoaded === false || vehicleConnected === 0 || vehicleController === false,
                        'vehicle-overview__overview-item--data': vehicleDataLoaded === true && vehicleConnected === 1,
                      })}
                    >
                      <div
                        className={classnames('vehicle-overview__overview-icon', {          
                          'vehicle-overview__overview-icon--info': vehicleIgl === 0,
                          'vehicle-overview__overview-icon--success': vehicleIgl === 1,
                          'vehicle-overview__overview-icon--warning': vehicleIgl > 1,
                        })}
                      >
                        <IconOverviewModuleSupply color="#ffffff" size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          IGL (Intelligent Gas Level)
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          <span className="vehicle-overview__tiny-value">{overviewExplanationIntelligentGasLvl(vehicleIgl)}</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div
                        className={classnames('vehicle-overview__overview-icon', {         
                          'vehicle-overview__overview-icon--success': parseInt(overviewGsmSignal) > 74,
                          'vehicle-overview__overview-icon--warning': parseInt(overviewGsmSignal) > 44 && parseInt(overviewGsmSignal) < 75,
                          'vehicle-overview__overview-icon--danger': parseInt(overviewGsmSignal) < 45
                        })}
                      >
                        <IconOverviewGsmSignal color="#ffffff" size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2209)} [%]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGsmSignal}
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewBattery size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2210)} [V]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGsmBattery}
                        </div>
                      </div>
                    </div>
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewModuleSupply size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2211)} [V]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGsmSupplyVoltage}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewPetrolInjection size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2201)} [ms]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewPetrolInjectionTime}
                        </div>
                      </div>
                    </div>
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewGasInjection size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2202)} [ms]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGasInjectionTime}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewGasPressure size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2203)} [bar]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGasPressure}
                        </div>
                      </div>
                    </div>
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewManifoldPressure size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2204)} (MAP) [bar]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewManifoldPressure}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="vehicle-overview__overview-section">
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewGasTemperature size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2205)} [C]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewGasTemperature}
                        </div>
                      </div>
                    </div>
                    <div className={classnames('vehicle-overview__overview-item', {          
                        'vehicle-overview__overview-item--nodata': vehicleOnline === false || vehicleControllerConnected === 0,
                        'vehicle-overview__overview-item--data': vehicleOnline === true,
                      })}
                    >
                      <div className="vehicle-overview__overview-icon">
                        <IconOverviewTemperatureReducer size={24} />
                      </div>
                      <div className="vehicle-overview__overview-description">
                        <div className="vehicle-overview__overview-description-title">
                          {t(2206)} [C]
                        </div>
                        <div className="vehicle-overview__overview-description-value">
                          {overviewReducerTemperature}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, station, competitor }: RootState) => ({ auth, vehicle, station, competitor }),
  { checkAuth, fetchVehicleById, fetchVehicleOverviewById, fetchPositionVehicleById, fetchAllStations, fetchAllCompetitors, fetchVehicleToday }
)(VehiclesNow)))
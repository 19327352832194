import { ALERT } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchAlert = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: ALERT.FETCH });
  return fetch(`${API_ADDRESS}/alert/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: ALERT.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: ALERT.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllAlerts = (options: { limit: number, skip: number }) => fetchAlert({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_ALL_SUCCESS
});


export const fetchDiagnosticsAlertsById = (id: number) => fetchAlert({
  endpoint: `diagnostics/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_DIAGNOSTICS_ID_SUCCESS
})


export const fetchAlertsNewCount = () => fetchAlert({
  endpoint: 'new',
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_NEW_COUNT_SUCCESS
});


export const fetchAlertsPresets = () => fetchAlert({
  endpoint: 'presets',
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_ALERTS_PRESETS_SUCCESS
});


export const fetchAlertById = (id: number) => fetchAlert({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_ID_SUCCESS
});


export const fetchAlertsSettingsById = (id: number) => fetchAlert({
  endpoint: `settings/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ALERT.FETCH_SETTINGS_ID_SUCCESS
});


export const fetchAlertsSettingsByIdAndTs = (options: { id: number, tss: number }) => fetchAlert({
  endpoint: `settings-ts/${options.id}`,
  options: { 
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ tss: options.tss }),
    headers: { 'Content-Type': 'application/json' }
  },
  SUCCESS_TYPE: ALERT.FETCH_SETTINGS_ID_SUCCESS
});


export const markAlertAsRedById = (id: number) => fetchAlert({
  endpoint: `red/${id}`,
  options: {     
    method: 'PUT',
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_ID_RED_SUCCESS
});


export const deleteAlerts = (data: any) => fetchAlert({
  endpoint: 'delete',
  options: {
    method: 'DELETE',
    body: JSON.stringify({ data }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ALERT.FETCH_DELETE_SUCCESS
});


export const updateAlertSpeed = (options: { id: number, value: string, mt: string, mp: string }) => fetchAlert({
  endpoint: `speed/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ value: options.value, mt: options.mt, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SPEED_ID
});


export const updateAlertGeoFence = (options: { id: number, lt: number, ln: number, rd: number, invert: boolean, mt: string, mp: string }) => fetchAlert({
  endpoint: `geo-fence/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ lt: options.lt, ln: options.ln, rd: options.rd, mp: options.mp, mt: options.mt, invert: options.invert }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_GEO_FENCE_ID
});


export const updateAlertEngineTemperature = (options: { id: number, value: string, mt: string, mp: string }) => fetchAlert({
  endpoint: `engine-temperature/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, value: options.value, mt: options.mt, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_ENGINE_TEMPERATURE_ID
});


export const updateAlertRpm = (options: { id: number, value: string, mt: string, mp: string }) => fetchAlert({
  endpoint: `rpm/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, value: options.value, mt: options.mt, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_RPM_ID
});


export const updateAlertGasolineUse = (options: { id: number, value: string, mp: string }) => fetchAlert({
  endpoint: `gasoline-use/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, value: options.value, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_GASOLINE_USE_ID
});


export const updateAlertGasMalfunction = (options: { id: number, mp: string }) => fetchAlert({
  endpoint: `gas-malfunction/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_GAS_MALFUNCTION_ID
});


export const updateAlertGasMaintenance = (options: { id: number, value: string, mp: string }) => fetchAlert({
  endpoint: `gas-maintenance/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, value: options.value, mp: options.mp }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_GAS_MAINTENANCE_ID
});


export const updateAlertGasPercentage = (options:{ id: number, value: string }) => fetchAlert({
  endpoint: `gas-percentage/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ id: options.id, value: options.value }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_GAS_PERCENTAGE_ID
});


export const updateAlertSpeedSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `speed/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_SPEED_ID
});


export const updateAlertGeoFenceSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `geo-fence/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_GEO_FENCE_ID
});


export const updateAlertEngineTemperatureSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `engine-temperature/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_ENGINE_TEMPERATURE_ID
});


export const updateAlertRpmSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `rpm/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_RPM_ID
});


export const updateAlertGasolineUseSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `gasoline-use/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_GASOLINE_USE_ID
});


export const updateAlertGasMalfunctionSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `gas-malfunction/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_GAS_MALFUNCTION_ID
});


export const updateAlertGasMaintenanceSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `gas-maintenance/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_GAS_MAINTENANCE_ID
});


export const updateAlertGasPercentageSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `gas-percentage/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_GAS_PERCENTAGE_ID
});


export const updateAlertLockedSwitch = (options: { id: number, enabled: boolean }) => fetchAlert({
  endpoint: `locked/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_SWITCH_LOCKED_ID
});


export const updateEmailNotifictaionVehicleId = (options: { id: number, enabled: boolean, email: string }) => fetchAlert({
  endpoint: `vehicle-email-notification/switch/${options.id}`,
  options: {     
    method: 'PUT',
    body: JSON.stringify({ enabled: options.enabled, email: options.email }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_UPDATE_EMAIL_NOTIFICATION_ID
});


export const overrideGroupAlertSettings = (options: { group_id: string, vehicle_id: number, enabled: boolean }) => fetchAlert({
  endpoint: `override/`,
  options: {     
    method: 'POST',
    body: JSON.stringify({ group_id: options.group_id, vehicle_id: options.vehicle_id, enabled: options.enabled }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include' 
  },
  SUCCESS_TYPE: ALERT.FETCH_OVERRIDE_GROUP_SETTINGS
});
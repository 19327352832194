import { PERSON } from '../types'
import fetchStates from '../types/fetchStates'


interface PersonState {
  people : [],
  peopleCount: number,
  person: {
    groups: []
  },
}


const DEFAULT_PERSON: PersonState = {
  people : [],
  peopleCount: 0,
  person: {
    groups: []
  }
}


const person = (state = DEFAULT_PERSON, action:any) => {
  switch(action.type) {
    case PERSON.FETCH:
      return { 
        ...state,
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case PERSON.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case PERSON.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        people: action.people,
        peopleCount: parseInt(action.peopleCount, 10),
      };
    case PERSON.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        person: action.person,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        person: { ...person, groups: action.groups },
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case PERSON.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_SELECTED_VEHICLES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_SETTINGS_PASSWORD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_SETTINGS_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_SETTINGS_FEATURES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.FETCH_TABLES_OPTIONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case PERSON.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case PERSON.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default person
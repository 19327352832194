import { VEHICLE } from '../types'
import fetchStates from '../types/fetchStates'


interface VehicleState {
  vehicle: [],
  vehicles : [],
  maintenance_requests: [],
  vehicleScoreGasPercentage: [],
  vehicleScoreCo2Savings: [],
  vehiclesCount: number,
  vehiclesTotalCount: number,
  vehiclesIgl: [],
  lockedVehicles: [],
  lockedVehiclesCount: number,
  vehiclesBilling: [],
  vehiclesBillingCount: number,
  vehiclesNoCommunication: [],
  vehiclesNoCommunicationCount: number,
  vehicleHistory: [],
  vehiclePosition: [],
  vehicleRoutes: [],
  vehicleVisits: [],
  vehicleRefueled: [],
  vehicleFuelUsage: [],
  vehicleOverview: [],
  vehicleModule: [],
  vehicleReports: [],
  vehicleReportsCount: [],
  vehicleAllReports: [],
  vehicleReportsNew: [],
  vehicleReportsNewCount: [],
  vehicleAllReportsNew: [],
  vehicleSavings: [],
  vehicleAlerts: [],
  vehicleRoaming: number,
  vehicleDataFrequency: number,
  vehiclesMap: [],
  vehicleToday: [],
  reasons: [],
  fleetStatisticsFromReportsByMonthNew: [],
  groupStatisticsFromReportsByMonthNew: [],
  fuelPrices: [],
  vehiclesOnTheFly: [],
  vehiclesOnTheFlyCount: number,
  fleet_savings: [],
  vehiclesEcoScore: [],
  vehiclesEcoScoreCount: number
}


const DEFAULT_VEHICLES:VehicleState = {
  vehicle: [],
  vehicles : [],
  maintenance_requests: [],
  vehicleScoreGasPercentage: [],
  vehicleScoreCo2Savings: [],
  vehiclesCount: 0,
  vehiclesTotalCount: 0,
  vehiclesIgl: [],
  lockedVehicles: [],
  lockedVehiclesCount: 0,
  vehiclesBilling: [],
  vehiclesBillingCount: 0,
  vehiclesNoCommunication: [],
  vehiclesNoCommunicationCount: 0,
  vehicleHistory: [],
  vehiclePosition: [],
  vehicleRoutes: [],
  vehicleVisits: [],
  vehicleRefueled: [],
  vehicleFuelUsage: [],
  vehicleOverview: [],
  vehicleModule: [],
  vehicleReports: [],
  vehicleReportsCount: [],
  vehicleAllReports: [],
  vehicleReportsNew: [],
  vehicleReportsNewCount: [],
  vehicleAllReportsNew: [],
  vehicleSavings: [],
  vehicleAlerts: [],
  vehicleRoaming: 0,
  vehicleDataFrequency: 10,
  vehiclesMap: [],
  vehicleToday: [],
  reasons: [],
  fleetStatisticsFromReportsByMonthNew: [],
  groupStatisticsFromReportsByMonthNew: [],
  fuelPrices: [],
  vehiclesOnTheFly: [],
  vehiclesOnTheFlyCount: 0,
  fleet_savings: [],
  vehiclesEcoScore: [],
  vehiclesEcoScoreCount: 0
}


const vehicle = (state = DEFAULT_VEHICLES, action: any) => {
  switch(action.type) {
    case VEHICLE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case VEHICLE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case VEHICLE.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicles: action.vehicles,
        vehiclesCount: parseInt(action.vehiclesCount, 10),
      };
    case VEHICLE.FETCH_FLEET_EXPLOITATION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesExploitation: action.vehiclesExploitation,
        vehiclesExploitationCount: parseInt(action.vehiclesExploitationCount, 10),
      };
    case VEHICLE.FETCH_FLEET_SAVINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        fleet_savings: action.fleet_savings,
      };
    case VEHICLE.FETCH_MAINTENANCE_REQUESTS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        maintenance_requests: action.maintenance_requests,
      };
    case VEHICLE.FETCH_COUNT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesTotalCount: parseInt(action.vehiclesTotalCount, 10),
      };
    case VEHICLE.FETCH_BILLING_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesBilling: action.vehiclesBilling,
        vehiclesBillingCount: parseInt(action.vehiclesBillingCount, 10),
      };
    case VEHICLE.FETCH_NO_COMMUNICATION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesNoCommunication: action.vehiclesNoCommunication,
        vehiclesNoCommunicationCount: parseInt(action.vehiclesNoCommunicationCount, 10),
      };
    case VEHICLE.FETCH_ARRAY_MAP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesMap: action.vehiclesMap
      };
    case VEHICLE.FETCH_LOCKED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        lockedVehicles: action.lockedVehicles,
        lockedVehiclesCount: parseInt(action.lockedVehiclesCount, 10),
      };
    case VEHICLE.FETCH_LOCK_CALENDAR_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      }
    case VEHICLE.FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicles: action.vehicles,
        vehiclesCount: parseInt(action.vehiclesCount, 10),
      };
    case VEHICLE.FETCH_BILLING_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesBilling: action.vehiclesBilling,
        vehiclesBillingCount: parseInt(action.vehiclesBillingCount, 10),
      };
    case VEHICLE.FETCH_LOCKED_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        lockedVehicles: action.lockedVehicles,
        lockedVehiclesCount: parseInt(action.lockedVehiclesCount, 10),
      };
    case VEHICLE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_HISTORY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleHistory: action.vehicleHistory,
        vehicleHistoryCount: action.vehicleHistoryCount,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_SAVINGS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleSavings: action.vehicleSavings,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_REPORTS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleReports: action.vehicleReports,
        vehicleReportsCount: action.vehicleReportsCount,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_ALL_REPORTS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleAllReports: action.vehicleAllReports,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_REPORTS_NEW_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleReportsNew: action.vehicleReportsNew,
        vehicleReportsNewCount: action.vehicleReportsNewCount,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_DATES_REPORTS_NEW_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleAllReportsNew: action.vehicleAllReportsNew,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_POSITION_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclePosition: action.vehiclePosition,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_ROUTES_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleRoutes: action.vehicleRoutes,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_MODULE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleModule: action.vehicleModule,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_ALERTS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicle: action.vehicle,
        vehicleAlerts: action.vehicleAlerts,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      }
    case VEHICLE.FETCH_VISITS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleVisits: action.vehicleVisits,
        vehicleVisitsCount: action.vehicleVisitsCount,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_VISIT_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        visit: action.visit,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_VEHICLES_IGL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesIgl: action.vehiclesIgl,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_REFUEL_COUNT_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleRefuelCount: action.refuelCount,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_REFUELED_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleRefueled: action.vehicleRefueled,
        vehicleRefueledCount: action.vehicleRefueledCount,
        vehicle: action.vehicle,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_FUEL_USAGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleFuelUsage: action.vehicleFuelUsage,
        vehicleCorrectRefuels: action.vehicleCorrectRefuels,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case VEHICLE.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case VEHICLE.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_UNLOCK_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case VEHICLE.FETCH_LOCK_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case VEHICLE.FETCH_FUEL_PRICES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        fuelPrices: action.fuelPrices,
      }
    case VEHICLE.UPDATE_FUEL_PRICES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      }
    case VEHICLE.FETCH_REASONS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reasons: action.reasons,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_OVERVIEW_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleOverview: action.vehicleOverview,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case VEHICLE.FETCH_ROAMING_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleRoaming: action.vehicleRoaming,
      };
    case VEHICLE.FETCH_DATA_FREQUENCY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleDataFrequency: action.vehicleDataFrequency,
      };
    case VEHICLE.FETCH_REPORT_BY_MONTH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleReportByMonth: action.vehicleReportByMonth,
      }
    case VEHICLE.FETCH_FLEET_ECOSCORE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesEcoScore: action.vehiclesEcoScore,
        vehiclesEcoScoreCount: action.vehiclesEcoScoreCount
      }
    case VEHICLE.FETCH_FLEET_STATISTICS_FROM_REPORTS_NEW_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        fleetStatisticsFromReportsByMonthNew: action.fleetStatisticsFromReportsByMonthNew
      }
    case VEHICLE.FETCH_GROUP_STATISTICS_FROM_REPORTS_NEW_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        groupStatisticsFromReportsByMonthNew: action.groupStatisticsFromReportsByMonthNew
      }

    case VEHICLE.FETCH_SCORE_GAS_PERCENTAGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleScoreGasPercentage: action.vehicleScoreGasPercentage,
      };
    case VEHICLE.FETCH_SCORE_CO2_SAVINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleScoreCo2Savings: action.vehicleScoreCo2Savings,
      };
    case VEHICLE.FETCH_VEHICLE_TODAY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehicleToday: action.vehicleToday,
      };
    case VEHICLE.FETCH_ON_THE_FLY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesOnTheFly: action.vehiclesOnTheFly,
        vehiclesOnTheFlyCount: action.vehiclesOnTheFlyCount
      }
    case VEHICLE.PUSH_MESSAGE:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        fields: action.fields
      };
    case VEHICLE.FETCH_DEACTIVATE_PAIRING_MAINTENANCE_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      }
    case VEHICLE.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default vehicle
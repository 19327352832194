import React from 'react'
import { Link } from 'react-router-dom'
import overviewExplanationIntelligentGasLvl from '../../helpers/overview/overviewExplanationIntelligentGasLvl'
import t from '../translation/translate'
import { NoIglWarning, IconVehicleSettings } from './Icons'


interface NoIglProps {
  igl: number,
  padding: boolean,
}


const NoIgl: React.FC<NoIglProps> = ({ igl, padding }) => {
  const noIgl = <div className="no-igl">
    <div className="no-igl__graphic">
      <IconVehicleSettings size={130} />
      <NoIglWarning size={60} />
    </div>
    <div className="no-igl__content">
      <h3 className="no-igl__content-title">
        {t(2433)}: {overviewExplanationIntelligentGasLvl(igl)}
      </h3>
      <p>
        {t(1141)}
      </p>
      <Link className="btn btn--primary" to={{ pathname: "https://help.stagtelematic.com/setting-up-igl-guide" }} target="_blank">{t(2360)}</Link>
    </div>
  </div>
  return  padding ? <div className="no-igl__padding-wrapper">{noIgl}</div> : <>{noIgl}</>
}


export default NoIgl
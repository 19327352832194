import { DIALOGFLOW } from '../types'
import fetchStates from '../types/fetchStates'


interface DialogflowState {
}


const DEFAULT_DIALOGFLOW:DialogflowState = {
}


const dialogflow = (state = DEFAULT_DIALOGFLOW, action:any) => {
  switch(action.type) {
    case DIALOGFLOW.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case DIALOGFLOW.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case DIALOGFLOW.SUBMIT_QUESTION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        bot_response: action.bot_response,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      }
    default:
      return state;
  }
}


export default dialogflow
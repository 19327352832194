import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchVehiclesNoCommunication } from '../../actions/vehicle';
import Card from './Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from './WithRouter'
import fetchStates from '../../types/fetchStates'
import Authorization from './Authorization'
import Preloader from './Preloader'
import moment from 'moment-timezone'
import { Tooltip } from 'react-tooltip'
import TableFooter from './TableFooter'
import { Link } from 'react-router-dom'
import { IconVisibility, IconDropUp, IconDropDown } from '../partials/Icons'


interface CardVehiclesNoCommunicationProps {
  auth: any,
  vehicle: any,
  fetchVehiclesNoCommunication: (options: { limit: number, skip: number, param: string, paramValue: boolean }) => Promise<any>,
}


interface CardVehiclesNoCommunicationState {
  currentPage: number,
  perPage: number,
  vehicles: any,
  vehiclesCount: number,
  param: string, 
  paramValue: boolean,
}


export class CardVehiclesNoCommunication extends React.Component<CardVehiclesNoCommunicationProps, CardVehiclesNoCommunicationState> {

  state = {
    currentPage: 0,
    perPage: 12,
    vehicles: [],
    vehiclesCount: 0,
    param: 'custom_id',
    paramValue: true,
  }


  componentDidMount() {
    if(this.props.auth.authorized.vehicles === true) {
      const { param, paramValue } = this.state
      this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue })
    }
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage, param, paramValue: !this.state.paramValue })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { param, paramValue } = this.state
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param, paramValue })
  }


  prevPage = (prevPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param, paramValue })
  }


  nextPage = (nextPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param, paramValue })
  }


  reloadPage = (options: { currentPage: number, perPage: number, param: string, paramValue: boolean }) => {
    const { currentPage, perPage, param, paramValue } = options
    this.props.fetchVehiclesNoCommunication({ limit: perPage, skip: perPage * currentPage, param, paramValue })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehiclesNoCommunication, vehiclesNoCommunicationCount } = this.props.vehicle
        this.setState({ vehicles: vehiclesNoCommunication, vehiclesCount: vehiclesNoCommunicationCount })
      }
    }); 
  }


  render() {

    const { vehicles, vehiclesCount, currentPage, perPage, param, paramValue } = this.state
    const { status } = this.props.vehicle

    return (
      <Authorization requiresAuth="vehicles">
        { vehiclesCount && vehiclesCount > 0 ? (
          <Card
            button={false} 
            title={t(180)} 
            time={false}
            animate
          >
            <div className="table-wrapper">
                { status === fetchStates.fetching ? (
                  <Preloader type="preloader-center" />
                ) : (
                <table className="table">
                  <thead className="table__header">
                    <tr>
                      <th></th>
                      <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                      <th className="th--clickable" onClick={() => this.setParam('connected')}>Last communication (days ago) {param === 'connected' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                    </tr>
                  </thead>
                  <tbody className="table__body table__body--striped">
                    {
                      vehicles && vehicles.map((item: {connected: number, vehicle_id: number, custom_id: string, module_imei: string }, index: number) => {
                        const now = moment()
                        const days = now.diff(item.connected, "days")

                        return (
                          <tr key={item.vehicle_id}>
                            <td className="simple">
                              <Tooltip anchorId={`tooltip-on-the-fly${index}`} />
                              <Link to={`/vehicles/now/${item.vehicle_id}`}>
                                <span id={`tooltip-on-the-fly${index}`} data-tooltip-content={t(16)}>
                                  <IconVisibility 
                                    task 
                                    button 
                                    color="#ffffff"
                                  />
                                </span>
                              </Link>
                            </td>
                            <td>{item.custom_id}</td>
                            <td>
                              {days} <span>{t(2403)}</span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  <TableFooter 
                    button={false}
                    colSpan={6}
                    currentPage={ currentPage }
                    perPage={ perPage }
                    itemsCount={ vehiclesCount }
                    prevPage={() => this.prevPage(currentPage - 1)}
                    nextPage={() => this.nextPage(currentPage + 1)}
                    updatePagination={this.updatePagination}
                  />
                </table>
              )}
            </div>
          </Card>
        ) : <></>}
      </Authorization>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle }: RootState) => ({ auth, vehicle }),
  { fetchVehiclesNoCommunication }
)(CardVehiclesNoCommunication)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllWorkerLogs } from '../../actions/worker'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import moment from 'moment-timezone'
import { TIMEZONE } from '../../config'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface WorkersProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  auth: { timezone: any },
  worker: any,
  fetchAllWorkerLogs: (options: {limit: number, skip: number}) => Promise<any>,
}


interface WorkersState {
  workers: Array<any>,
  workersCount: number,
  currentPage: number,
  perPage: number,
  timezone: string,
}


export class Workers extends React.Component<WorkersProps, WorkersState> {

  state = {
    workers: [],
    workersCount: 0,
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/workers?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/workers?page=${currentPage}&perPage=${perPage}`)
    }

    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/workers?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/workers?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/workers?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    return this.props.fetchAllWorkerLogs({ limit: options.perPage, skip: options.perPage * options.currentPage })
    .then(() => {
      if(this.props.worker.status === fetchStates.success) {
        const { workers, workersCount } = this.props.worker
        this.setState({ workers, workersCount })
      }
    });
  }

  render() {

    const { status } = this.props.worker;
    const { currentPage, perPage, timezone, workers, workersCount } = this.state;

    return (
      <TemplatePage>

        { status === 'fetching' ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(227)} 
          button={false} 
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['ID', t(1147), t(1148), t(1149), t(1150)]} 
              />
              <tbody className="table__body table__body--striped">
                { workers && workers.map((item: {id: string, worker_name: string, log_ts_start: number, log_ts_end: number, diff: number}) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        {item.id}
                      </td>
                      <td>
                        {item.worker_name ? item.worker_name : 'n/a'}
                      </td>
                      <td>
                        {moment(item.log_ts_start).tz(timezone).format('DD/MM/YYYY hh:mm:ss')}
                      </td>
                      <td>
                        {moment(item.log_ts_end).tz(timezone).format('DD/MM/YYYY hh:mm:ss')}
                      </td>
                      <td>
                        {item.diff} ms
                      </td>
                    </tr>
                    )
                  })
                }
              </tbody>
              <TableFooter 
                colSpan={8}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ workersCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
                button={false}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, worker }: RootState) => ({ auth, worker }),
  { fetchAllWorkerLogs }
)(Workers)))
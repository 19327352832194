import moment from 'moment-timezone'


const routesCreateLegend = (routes: any, timezone: string) => {
  const markerColors = [ '#e91e63', '#9c27b0', '#3f51b5', '#03a9f4', '#009688', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#607d8b', '#f44336', '#9c27b0', '#3f51b5', '#4caf50', '#cddc39', '#ffc107', '#ff5722', '#9e9e9e', '#f72585', '#f3722c', '#7209b7', '#90be6d', '#4361ee', '#820263', '#291720', '#006ba6' ];
  let previousTime = routes[0].ts;
  let currentTime = routes[0].ts;
  let routeStart = routes[0].ts;
  let routeEnd = 0;
  let colorIndex = 0;
  let distance = 0;
  let legend: any = [];

  routes.map((route: {ts: number, distance: number}, index: number) => {
    currentTime = route.ts;
    if(currentTime - previousTime > 300 || index === routes.length - 1) {
      routeEnd = previousTime
      legend.push({ color: markerColors[colorIndex], routeStart: moment(routeStart*1000).tz(timezone).format('HH:mm:ss'), routeEnd: moment(routeEnd*1000).tz(timezone).format('HH:mm:ss'), duration: routeEnd - routeStart, distance: (distance).toFixed(2) });
      routeStart = currentTime
      colorIndex++;
    }
    previousTime = route.ts;
    distance = route.distance;
    return null;
  });

  return legend;
}


export default routesCreateLegend
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertGasolineUse, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormGasolineUseProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertGasolineUse: (options: {id: number, value: string, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormGasolineUseState {
  formSubmitted: boolean,
  gasoline_use_limit: string,
  alert_max_frequency: string
}


export class AlertFormGasolineUse extends React.Component<AlertFormGasolineUseProps, AlertFormGasolineUseState> {


  state = {
    formSubmitted: false,
    gasoline_use_limit: '1',
    alert_max_frequency: '3600'
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {
      const { alerts_fuel_mp, alerts_fuel_v } = this.props.alert.alertsSettings
      this.setState({
        gasoline_use_limit: alerts_fuel_v !== null ? alerts_fuel_v : '1',
        alert_max_frequency: alerts_fuel_mp !== null ? alerts_fuel_mp : '3600'
      })
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertGasolineUse({ id: this.props.modalId, value: this.state.gasoline_use_limit, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }

  render() {

    const { formSubmitted, gasoline_use_limit, alert_max_frequency } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2082)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="gasoline_use_limit">{t(2101)} [km]</label>
              <input 
                type="text" 
                name="gasoline_use_limit"
                id="gasoline_use_limit" 
                value={gasoline_use_limit}
                onChange={e => this.setState({ gasoline_use_limit: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2102)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_max_frequency">{t(2093)}</label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>

            
            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </div>
      </div>
      
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertGasolineUse, fetchAlertsSettingsById }
)(AlertFormGasolineUse);
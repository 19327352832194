const routesCalculateDistance = (options: {loc_lt1: number, loc_ln1: number, loc_lt2: number, loc_ln2: number}) => {

  const { loc_lt1, loc_ln1, loc_lt2, loc_ln2 } = options

  const PI = 3.14159265359;

  function deg2rad(deg: number) {
    return (deg * PI / 180);
  }
  function rad2deg(rad: number) {
    return (rad * 180 / PI);
  }

  function get_distance(loc_lt1: number, loc_ln1: number, loc_lt2: number, loc_ln2: number) {

    let theta;
    let dist;
    const lat1 = loc_lt1
    const lon1 = loc_ln1
    const lat2 = loc_lt2
    const lon2 = loc_ln2

    theta = lon1 - lon2;
    dist = (Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2))) + (Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta)));

    dist = Math.acos(dist);
    if(isNaN(dist)) {
      return 0;
    } else {
      dist = rad2deg(dist);
      dist = dist * 60 * 1.1515 * 1609.344;
      return dist;
    }
  }

  return get_distance(loc_lt1, loc_ln1, loc_lt2, loc_ln2);
}


export default routesCalculateDistance
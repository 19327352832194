import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchVehicleById, setLockCalendarDate } from '../../actions/vehicle'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import fetchStates from '../../types/fetchStates'
import moment from 'moment-timezone'
import t from '../translation/translate'
import { IconLinkOff, IconWarning } from '../partials/Icons'
import DatePicker from 'react-datepicker';
import CtaBox from '../partials/CtaBox'
import Switch from '../partials/Switch'


interface VehiclesCalendarProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  vehicle: any,
  fetchVehicleById: (id: number) => Promise<void>,
  setLockCalendarDate: (options: { id: number, calendar_lock: boolean, calendar_lock_date: string }) => Promise<void>,
}


interface VehiclesCalendarState {
  vehicle: { vehicle_id: number, custom_id: string, module_imei: string, paired: number, unlocked: number, connected: number, ignition: number, controller_connected: number, compatible: number }, 
  lockUnlockDisabled: boolean,
  filterDate: Date,
  filterTodayDate: Date,
  filterVehicleSubscribed: Date,
  calendar_lock: boolean,
  formSubmitted: boolean,
}


export class HomePage extends React.Component<VehiclesCalendarProps, VehiclesCalendarState> {

  state = {
    vehicle: { 
      vehicle_id: 0, 
      custom_id: '', 
      module_imei: '', 
      paired: -1, 
      unlocked: -1, 
      connected: -1, 
      ignition: -1, 
      controller_connected: -1, 
      compatible: -1 
    }, 
    lockUnlockDisabled: false,
    filterDate: new Date(),
    filterTodayDate: new Date(),
    filterVehicleSubscribed: new Date(),
    calendar_lock: false,
    formSubmitted: false,
  }


  componentDidMount() {
    this.fetchVehicleById()
  }


  fetchVehicleById = async () => {
    if( this.props.router.params.id !== undefined ) {
      await this.props.fetchVehicleById(parseInt(this.props.router.params.id));
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicle } = this.props.vehicle;
        let lockUnlockDisabled = false
        if(vehicle.controller_connected === 0) {
          vehicle.compatible = -1
        }
        // disable option to pair the module if any of those are true
        if( vehicle.compatible === 1 || vehicle.controller_connected === 0 || vehicle.paired === 0 ) {
          lockUnlockDisabled = true;
        }
        this.setState({ vehicle, lockUnlockDisabled, filterVehicleSubscribed: new Date(vehicle.payment_date), filterDate: vehicle.calendar_lock_date !== null ? new Date(vehicle.calendar_lock_date) : new Date(), calendar_lock: vehicle.calendar_lock  })
      }
    }
  }


  updateFilterDate = (date:any) => {
    this.setState({ filterDate: date });
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.setState({ formSubmitted: true });
    const { filterDate, calendar_lock } = this.state
    await this.props.setLockCalendarDate({ id: parseInt(this.props.router.params.id), calendar_lock, calendar_lock_date: moment(filterDate).format('YYYY-MM-DD') })
    this.setState({ formSubmitted: false });
  }
  

  render() {

    const { vehicle, filterDate, filterTodayDate, lockUnlockDisabled, filterVehicleSubscribed, calendar_lock, formSubmitted } = this.state

    return (
      <TemplatePage>
        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
          <>
            <h2>{t(2514)}</h2>
            {
              lockUnlockDisabled === true ? (
                <div>
                  <CtaBox padding={true} type="info" title="Connect and pair the GSM module" icon={<IconLinkOff size={54} color="#ffffff" />}>
                    {t(2515)}
                  </CtaBox>
                </div>
              ) : (
                <>
                {
                  vehicle.unlocked === 0 && (
                    <div className="msg msg--danger"><IconWarning color="#ffffff" /> {t(2516)}</div>
                  )  
                }
                    <form className="form" onSubmit={this.handleOnSubmit}>
                      <div className="form-group">

                      </div>
                      <div className="form-group__switch form-group__switch--large">
                        <label onClick={e => this.setState({ calendar_lock: !this.state.calendar_lock })}>
                          <Switch switchState={calendar_lock} />
                          <span className="label-title">
                            {t(2517)}
                          </span>
                        </label>
                      </div>
                      <div className="form-group">
                        <label htmlFor="role">
                          {t(2518)} ({t(116)})
                        </label>
                        <DatePicker
                          selected={filterDate}
                          selectsStart
                          onChange={this.updateFilterDate}
                          dateFormat='yyyy-MM-dd'
                          minDate={filterTodayDate}
                          maxDate={filterVehicleSubscribed}
                          required={true}
                        />
                    </div>
                    <div className="form-group">
                      <input 
                        type="submit" 
                        value="Update calendar settings" 
                        className="btn btn--primary card__footer--btn-left"
                        disabled={ formSubmitted ? true : false }
                      />
                    </div>
                  </form>
                </>
              )
            }
          </>

        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle }: RootState) => ({ vehicle }),
  { fetchVehicleById, setLockCalendarDate }
)(HomePage)))
import React from 'react'
import { connect } from 'react-redux'
import { fetchAllStations } from '../../actions/station'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import CardStations from '../partials/CardStations'


const StationsOverview: React.FC = () => {
  return (
    <TemplatePage>
      <Card 
        title={t(2)} 
        button={false} 
        time={false}
        padding={['medium']}
        tabnav="stations"
        tabnavId={0}
        animate
      >
        <CardStations />
      </Card>
    </TemplatePage>
  )
}


export default withAuthorization(withRouter(connect(
  ({ station, auth }) => ({ station, auth }),
  { fetchAllStations }
)(StationsOverview)))
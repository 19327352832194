import { ERROR } from '../types'
import fetchStates from '../types/fetchStates'


interface ErrorState {
  errorsLog : [],
  errorsLogCount: number,
}


const DEFAULT_ERROR_LOGS:ErrorState = {
  errorsLog : [],
  errorsLogCount: 0,
}


const error = (state = DEFAULT_ERROR_LOGS, action:any) => {
  switch(action.type) {
    case ERROR.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ERROR.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case ERROR.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        errorsLog: action.errorsLog,
        errorsLogCount: parseInt(action.errorsLogCount, 10),
      };
    default:
      return state;
  }
}


export default error
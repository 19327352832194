// collection of all currently translated languages
const languageTranslations = [
  {
    title: 'English',
    shortcut: 'en'
  },
  /*
  {
    title: 'Español',
    shortcut: 'es'
  },
  {
    title: 'Polski',
    shortcut: 'pl'
  },
  {
    title: 'Rусский',
    shortcut: 'ru'
  },
  */
]


export default languageTranslations
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { checkAuth } from '../../actions/auth'
import { updatePersonPasswordSettings } from '../../actions/person'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { IconVisibility, IconVisibilityOff } from '../partials/Icons'
import classnames from 'classnames'
import t from '../translation/translate'
import Preloader from '../partials/Preloader'
import fetchStates from '../../types/fetchStates'


interface SettingsPasswordManagementProps {
  router: { navigate: (to: string) => any },
  person: { status: string, fields: Array<string>},
  auth: {status: string, person_id: number, lat: number, lng: number, zoom: number, currency: string, language: string, timezone: string},
  checkAuth: () => Promise<any>,
  updatePersonPasswordSettings: (options: { id: number, password_old: string, password_new: string, password_new_confirm: string }) => Promise<any>,
}


interface SettingsPasswordManagementState {
  password_old: string,
  password_new: string,
  password_new_confirm: string,
  passwordOldShow: boolean,
  passwordNewShow: boolean,
  passwordNewConfirmShow: boolean,
  formSubmitted: boolean
}


export class SettingsPasswordManagement extends React.Component<SettingsPasswordManagementProps, SettingsPasswordManagementState> {

  state = {
    password_old: '',
    password_new: '',
    password_new_confirm: '',
    passwordOldShow: false,
    passwordNewShow: false,
    passwordNewConfirmShow: false,
    formSubmitted: false,
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const {password_old, password_new, password_new_confirm} = this.state
    this.props.updatePersonPasswordSettings({ id: this.props.auth.person_id, password_old, password_new, password_new_confirm })
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        this.setState({ password_old: '', password_new: '', password_new_confirm: '' });
      }
      this.setState({ formSubmitted: false })
    })
  }


  render() {

    const { formSubmitted, passwordOldShow, passwordNewShow, passwordNewConfirmShow, password_old, password_new, password_new_confirm } = this.state
    const { status, fields } = this.props.person

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(11)} 
          padding={['large']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate} 
          time={false}
          animate
          tabnav="settings"
          tabnavId={0}
        >
          <form className="form" onSubmit={this.handleOnSubmit}>


            <h2 className="form-group__title">{t(231)}</h2>

            <div className="form-group">
              <label htmlFor='password_old'>
                {t(1167)}
                <span className="password-reveal" onClick={e => this.setState({ passwordOldShow: !passwordOldShow })}>
                  { passwordOldShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='password_old'
                type={ passwordOldShow === false ? 'password' : 'text' } 
                value={password_old}
                className={classnames('', { 'input-error': fields && fields.includes('password_old') })}
                onChange={e => this.setState({ password_old: e.target.value })}
              />
              <PasswordStrengthMeter password={password_old} />
            </div>

            <div className="form-group">
              <label htmlFor='password_new'>
                {t(1168)}
                <span className="password-reveal" onClick={e => this.setState({ passwordNewShow: !passwordNewShow })}>
                  { passwordNewShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='password_new'
                type={ passwordNewShow === false ? 'password' : 'text' } 
                value={password_new}
                className={classnames('', { 'input-error': fields && fields.includes('password_new') })}
                onChange={e => this.setState({ password_new: e.target.value })}
              />
              <PasswordStrengthMeter password={password_new} />
            </div>

            <div className="form-group">
              <label htmlFor='password_confirm'>
                {t(1169)}
                <span className="password-reveal" onClick={e => this.setState({ passwordNewConfirmShow: !passwordNewConfirmShow })}>
                  { passwordNewConfirmShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='password_confirm'
                type={ passwordNewConfirmShow === false ? 'password' : 'text' } 
                autoComplete='current-password'
                value={password_new_confirm}
                className={classnames('', { 'input-error': fields && fields.includes('password_new_confirm') })}
                onChange={e => this.setState({ password_new_confirm: e.target.value })}
              />
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(1170)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted && status === 'fetching' ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person }: RootState) => ({ auth, person }),
  { checkAuth, updatePersonPasswordSettings }
)(SettingsPasswordManagement)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllAccounts } from '../../actions/account'
import { Link } from 'react-router-dom'
import TemplatePage from '../templates/TemplatePage'
import Preloader from '../partials/Preloader'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { Tooltip } from 'react-tooltip'
import { IconEdit, IconDelete } from '../partials/Icons'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'


interface AccountsProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  account: any,
  fetchAllAccounts: (options: { limit: number, skip: number }) => Promise<any>,
}


interface AccountsState {
  currentPage: number,
  perPage: number,
  accounts: any, 
  accountsCount: number,
}


export class Accounts extends React.Component<AccountsProps, AccountsState> {

  state = {
    currentPage: 0,
    perPage: 10,
    accounts: [], 
    accountsCount: 0,
  }


  componentDidMount() {

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search);
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/accounts?page=${currentPage}&perPage=${perPage}`)
      }
    }

    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage }); 

  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.props.router.navigate(`/accounts?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/accounts?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/accounts?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    this.props.fetchAllAccounts({ limit: options.perPage, skip: options.perPage * options.currentPage })
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        const { accounts, accountsCount } = this.props.account
        this.setState({ accounts, accountsCount })
      }
    });
  }


  render() {

    const { status } = this.props.account;
    const { currentPage, perPage, accounts, accountsCount } = this.state

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(189)}
          time={false}
          button={true} 
          buttonLink='/accounts/add' 
          buttonTitle={t(191)}
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', '', 'ID', t(155), t(157), t(158), t(159), t(160), t(161), t(2402), t(1072), t(2043), t(1082), t(1097), t(1162), t(1215), t(1213), t(1209) , t(1210), t(1211), t(1212), t(1214)]} 
              />
              <tbody className="table__body table__body--striped">
              { accounts && accounts.map((item: { account_id: string, vehicles_count: string, company_name: string, street_name: string, city: string, postcode: string, country: string, vat: string, timezone: string, account_email: string, account_language: string, google_maps_api_key: string, login_count: number, account_unit_cost_option_1: number, account_unit_cost_option_2: number,
              account_unit_cost_option_3: number, subscribe_payment_limit_days: number, billing_currency: string, billing_client_abb: string, billing_enabled: boolean }, index: number) => {
                  return (
                  <tr key={item.account_id}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-accounts-edit${index}`} />
                      <Link to={`/accounts/edit/${item.account_id}`}>
                        <span id={`tooltip-accounts-edit${index}`} data-tooltip-content={t(24)}>
                          <IconEdit 
                            task 
                            button 
                            color="#ffffff"
                          />
                        </span>
                      </Link>
                    </td>
                    { item.vehicles_count && item.vehicles_count === '0' ? (
                      <td className="simple">
                        <Tooltip anchorId={`tooltip-accounts-delete${index}`} />
                        <Link to={`/accounts/delete/${item.account_id}`}>
                          <span id={`tooltip-accounts-delete${index}`} data-tooltip-content={t(29)}>
                            <IconDelete 
                              task 
                              button 
                              color="#ffffff"
                            />
                          </span>
                        </Link>
                      </td>
                    ) : (
                      <td>

                      </td>
                    )

                    }
                    <td>
                      {item.account_id}
                    </td>
                    <td>
                      {item.company_name}
                    </td>
                    <td>
                      {item.street_name}
                    </td>
                    <td>
                      {item.city}
                    </td>
                    <td>
                      {item.postcode}
                    </td>
                    <td>
                      {item.country}
                    </td>
                    <td>
                      {item.vat}
                    </td>

                    <td>
                      {item.timezone}
                    </td>
                    <td>
                      {item.account_email}
                    </td>
                    <td>
                      {item.account_language}
                    </td>
                    <td>
                      {item.google_maps_api_key}
                    </td>
                    <td>
                      {item.login_count}
                    </td>
                    <td>
                      {item.vehicles_count}
                    </td>
                    <td>
                      <div className={classnames('traffic-light', { 
                        'traffic-light--green': item.billing_enabled === true,
                        'traffic-light--red': item.billing_enabled === false,
                        })}>
                      </div>
                    </td>
                    <td>
                      {item.billing_currency}
                    </td>
                    <td>
                      {item.subscribe_payment_limit_days}
                    </td>
                    <td>
                      {item.account_unit_cost_option_1}
                    </td>
                    <td>
                      {item.account_unit_cost_option_2}
                    </td>
                    <td>
                      {item.account_unit_cost_option_3}
                    </td>
                    <td>
                      {item.billing_client_abb}
                    </td>
                  </tr>
                  )
                })
              }
              </tbody>

              <TableFooter 
                button={false}
                colSpan={7}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ accountsCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account, auth }: RootState) => ({ account, auth }),
  { fetchAllAccounts }
)(Accounts)))
import Button from '../Button'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { DEFAULT_ZOOM } from '../../../config'
import alertTypes from '../../../helpers/alertTypesToString'
import alertTypesTitle from '../../../helpers/alertTypesTitle'
import alertIconFromNumber from '../../../helpers/alertIconFromNumber'
import markerVehiclePosition from'../../../img/marker-vehicle-position.png'
import moment from 'moment-timezone'
import t from '../../translation/translate'


interface AlertBoxGasSpeedProps {
  alert: any,
  settings: any,
  timezone: string,
}


const AlertBoxGasSpeed: React.FC<AlertBoxGasSpeedProps> = ({ alert, settings, timezone }) => {
  return (
    <div className="alert-box alert-box--speed">
    <div className="alert-box__icon">{alertIconFromNumber(parseInt(alert.type), 64, '#ffffff')}</div>
      <div className="alert-box__body">
        <h2 className="alert-box__title">{alertTypesTitle(parseInt(alert.type))}</h2>
        <div className="alert-box__date">{moment(alert.ts*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}</div>
        <div className="alert-box__values">
          <div className="alert-box__value value--true">
            { alert.value }      
            <span className="alert-box__units">
              km/h
            </span>
            <div className="alert-box__subtitle">
              {t(2085)}
            </div>
          </div>
          <div className="alert-box__value value--default">
            { parseInt(settings.alerts_spd_v) }
            <span className="alert-box__units">
              km/h
            </span>
            <div className="alert-box__subtitle">
              {t(2086)}
            </div>
          </div>
        </div>

        {
          alert.loc_lt && alert.loc_ln ? (
            <GoogleMap
            center={{ 
              lat: parseFloat(alert.loc_lt), 
              lng: parseFloat(alert.loc_ln) 
            }}
            zoom={ DEFAULT_ZOOM }
          >

            <Marker
              position={{
                lat: parseFloat(alert.loc_lt),
                lng: parseFloat(alert.loc_ln)
              }}
              icon={
                // @ts-expect-error
                new window.google.maps.MarkerImage(
                  markerVehiclePosition,
                  null, /* size is determined at runtime */
                  null, /* origin is 0,0 */
                  null, /* anchor is bottom center of the scaled image */
                  new window.google.maps.Size(48, 48)
                )
              }
            />

            <div className="map-constraints-container" />
          </GoogleMap> ) :
          (
            <div className="alert__long-lat-missing">
              <div className="msg msg--danger">Sorry, there are no longitude and latitude data available.</div>
            </div>
          )
        }


      </div>
      <div className="alert-box__footer">
        <Button classes='btn btn--primary btn--centered' link={`/vehicles/alerts/${alert.vehicle_id}/${alertTypes(parseInt(alert.type))}`} title={t(2077)} clickable={false} handleOnClick={() => console.log()} />
      </div>
    </div>
  )
}


export default AlertBoxGasSpeed
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchVehicleById, fetchModuleVehicleById } from '../../actions/vehicle'
import { fetchPairingRequestsHistory, pairingModule } from '../../actions/module'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { IconLinkOn, IconLinkOff, IconVehicleSettings, IconVisibilityOff, IconSettings, IconNotifyInfo, IconNotifyError, IconNotifySuccess, IconNotifyClose, IconNotifyWarning, IconModuleGas, IconModuleTelemetry } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { TIMEZONE } from '../../config'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'


interface VehiclePairingProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  moduleState: any,
  fetchVehicleById: (id: number) => Promise<void>,
  fetchModuleVehicleById: (id: number) => Promise<void>,
  fetchPairingRequestsHistory: (id: number) => Promise<void>,
  pairingModule: (options: { id: number, pair_req: number, req_date?: string }) => Promise<void>,
}


interface VehiclePairingState {
  vehicle: { custom_id: string, module_imei: string, paired: number, maint_prog: number, connected: number, maint_plan: number, mainten: number, compatible: number, ignition: number, controller_connected: number }, // -1 as "unknown" initial state
  interval: boolean,
  formSubmitted: boolean,
  pairingDisabled: boolean,
  requestSent: boolean,
  circleUnprocessed: boolean,
  circleProcessing: boolean,
  circleProcessed: boolean,
  pairing_requests_history: any,
  filterDate: any,
  todayDate: any,
  modulesInfo: boolean,
  hardwareVersion: string,
  firmwareVersion: string,
  gasControlerModel: string,
  gasControlerFirmware: string,
  gasControlerFirmwareDate: string,
  intervalId: any,
  timezone: string,
  lastConnected: number,
}


export class VehiclePairing extends React.Component<VehiclePairingProps, VehiclePairingState> {

  state = {
    vehicle: { custom_id: '', module_imei: '', paired: -1, maint_prog: -1, connected: -1, maint_plan: -1, mainten: -1, compatible: -1, ignition: -1, controller_connected: -1 }, // -1 as "unknown" initial state
    interval: false,
    formSubmitted: false,
    pairingDisabled: false,
    requestSent: false,
    circleUnprocessed: true,
    circleProcessing: false,
    circleProcessed: false,
    pairing_requests_history: [],
    filterDate: moment(`${moment().format('YYYY/MM/DD')} 00:00`).toDate(),
    todayDate: moment(`${moment().format('YYYY/MM/DD')} 00:00`).toDate(),
    modulesInfo: false,
    hardwareVersion: 'n/a',
    firmwareVersion: 'n/a',
    gasControlerModel: 'n/a',
    gasControlerFirmware: 'n/a',
    gasControlerFirmwareDate: 'n/a',
    intervalId: 0,
    timezone: TIMEZONE,
    lastConnected: 0,
  }


  componentDidMount() {
    this.fetchVehiclePairingState();
    this.fetchPairingRequestsHistory();
    const intervalId = setInterval(this.timer, 10000);
    this.setState({ intervalId: intervalId, timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE });
  }


  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }


  timer = () => {
    this.fetchVehiclePairingState();
    this.fetchPairingRequestsHistory();
    this.setState({ interval: true, vehicle: {...this.state.vehicle, paired: -1 } }); // -1 as "unknown" initial state
  }


  fetchVehiclePairingState = () => {
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchVehicleById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          const { vehicle } = this.props.vehicle;
          let pairingDisabled = false
          let lastConnected = 0
          if(vehicle.controller_connected === 1) {
            this.fetchModuleInfo();
          }
          if(vehicle.controller_connected === 0) {
            vehicle.compatible = -1
          }
          // if last timestamp is less then 10m then we count this as still connected
          if(moment(vehicle.connected).format('X') > moment().subtract(10, 'minutes').format('X') && vehicle.ignition === 1) {
            lastConnected = 1
          }
          // disable option to pair the module if any of those are true
          if(vehicle.ignition === 0 || vehicle.compatible === 1 || lastConnected === 0 || vehicle.controller_connected === 0) {
            pairingDisabled = true;
          }
          this.setState({ vehicle, pairingDisabled, lastConnected })
        }
      })
    }
  }


  fetchPairingRequestsHistory = () => {
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchPairingRequestsHistory(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.moduleState.status === fetchStates.success) {
          const { pairing_requests_history } = this.props.moduleState;
          this.setState({ pairing_requests_history })
        }
      })
    }
  }


  fetchModuleInfo = () => {
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchModuleVehicleById(parseInt(this.props.router.params.id))
      .then(() => {
        const { hwi_hv, hwi_fv, gmi_mo, gmi_fv, gmi_cd } = this.props.vehicle.vehicleModule;
        this.setState({ 
          hardwareVersion: hwi_hv,
          firmwareVersion: hwi_fv,
          gasControlerModel: gmi_mo,
          gasControlerFirmware: gmi_fv,
          gasControlerFirmwareDate: gmi_cd,
          modulesInfo: true
        });
      })
    }
  }


  updateFilterDate = (filterDate: Date) => {
    this.setState({ filterDate });
  }


  handlePairing = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true, requestSent: true });
    this.props.pairingModule({ id: parseInt(this.props.router.params.id), pair_req: 1 })
    .then(() => {
      this.fetchVehiclePairingState();
      this.fetchPairingRequestsHistory();
      this.setState({ formSubmitted: false });
    })
  }


  handleUnpairing = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true, requestSent: true });
    this.props.pairingModule({ id: parseInt(this.props.router.params.id), pair_req: -1 })
    .then(() => {
      this.fetchVehiclePairingState();
      this.fetchPairingRequestsHistory();
      this.setState({ formSubmitted: false });
    })
  }


  handleMaintenanceUnpairing = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true, requestSent: true });
    this.props.pairingModule({ id: parseInt(this.props.router.params.id), pair_req: -2, req_date: moment(this.state.filterDate).format('YYYY-MM-DD') })
    .then(() => {
      this.fetchVehiclePairingState();
      this.fetchPairingRequestsHistory();
      this.setState({ formSubmitted: false });
    })
  }


  handleCancelMaintenanceUnpairing = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true, requestSent: true });
    this.props.pairingModule({ id: parseInt(this.props.router.params.id), pair_req: 2 })
    .then(() => {
      this.fetchVehiclePairingState();
      this.fetchPairingRequestsHistory();
      this.setState({ formSubmitted: false });
    })
  }


  render() {

    const { vehicle, pairing_requests_history, formSubmitted, pairingDisabled, lastConnected, requestSent, filterDate, todayDate, hardwareVersion,firmwareVersion, gasControlerModel, gasControlerFirmware, gasControlerFirmwareDate, modulesInfo, timezone } = this.state
    const { status } = this.props.vehicle;

    return (
      <TemplatePage>

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >


          <div className="pairing">

          <div className="pairing-state">
            <div className="pairing-instructions">
              <div className="pairing-state-title">{t(2071)}:</div>
              <div className="pairing-state-phrase">
                { status === 'fetching' && t(2448)}
                { status !== 'fetching' && vehicle.paired === -1 && 'n/a' }
                { status !== 'fetching' && vehicle.paired === 0 && t(2452)}
                { status !== 'fetching' && vehicle.paired === 1 && t(2453) }
                { status !== 'fetching' && vehicle.paired === 2 && t(2454) }
                
              </div>

            </div>

            <div className="pairing-circle-wrapper">

              <div className={classnames('pairing-circle', {          
                  'pairing-circle__completed': status === 'success',
                  'pairing-circle__processing': status === 'fetching',
                  'pairing-circle__unprocessed': status === 'error'
                })}
              >
                <IconVehicleSettings size={200} />

                <span className={classnames('pairing-state-icon pairing-state-icon--paired', {          
                    'pairing-state-icon--processed': status === 'success',
                    'pairing-state-icon--processing': status === 'fetching',
                    'pairing-state-icon--unprocessed': status === 'error',
                    'pairing-state-icon--success': vehicle.paired === 1 && status === 'success',
                    'pairing-state-icon--info': (vehicle.paired === -1 || vehicle.paired === -2 || vehicle.paired === 2 || vehicle.paired === 0 ) && status === 'success'
                  })}
                >
                  {
                    vehicle.paired === -1 && <IconVisibilityOff size={48} color="#dfdfdf" />
                  }
                  {
                    vehicle.paired === 0 && vehicle.maint_prog === 0 && <IconLinkOff size={48} color="#ffffff" />
                  }
                  {
                    vehicle.paired === 0 && vehicle.maint_prog === 1 && <IconSettings size={48} color="#ffffff" />
                  }
                  {
                    vehicle.paired === 1 && <IconLinkOn size={48} color="#ffffff" />
                  }
                  {
                    vehicle.paired === 2 && <IconLinkOff size={48} color="#ffffff" />
                  }
                </span>

                <div
                  className={classnames('pairing-state-time', {          
                    'pairing-state-time--processed': status === 'success',
                    'pairing-state-time--processing': status === 'fetching',
                    'pairing-state-time--unprocessed': status === 'error',
                  })}
                >
                
                  { 
                    lastConnected === 0 ? (
                      <span className="text-error">{t(2461)}:<br/></span>
                    ) : (
                      <span>{t(2462)}:<br/></span>
                    )
                  }
                  {vehicle.connected !== 0 ? moment(vehicle.connected).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}
                </div>
              </div>

              {
                (vehicle.maint_plan === 1 || vehicle.maint_prog === 1) && (
                  <div className={classnames('maintenance-state-icon', {
                    'state-icon--processed': status === 'success',
                    'state-icon--processing': status === 'fetching',
                    'state-icon--unprocessed': status === 'error'
                  })}>
                    {
                      vehicle.maint_prog === 1 ? (
                        <span className="icon-state"><IconNotifyInfo color='#70B1C7'/>{t(2463)}</span>
                      ) : (
                        <span className="icon-state"><IconNotifyWarning color='#ECBF58'/>
                          {t(2464)}
                          {vehicle.mainten && vehicle.mainten !== 0 && <br/>}
                          {vehicle.mainten && vehicle.mainten !== 0 ? <span className="maintenance-time"><span>{t(2399)}: {moment(vehicle.mainten * 1000).tz(timezone).subtract(24, 'h').format('YYYY/MM/DD HH:mm:ss')}</span><br/><span>{t(2361)}: {moment(vehicle.mainten * 1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}</span></span> : null}
                        </span>
                      )
                    }
                  </div>
                )
              }

              {
                modulesInfo && vehicle.compatible === 1 && (

                  <div className="modules-info">

                    <div className="module-info module-info--gas">
                      <div className="module-info__icon">
                        <IconModuleGas size={80} />
                      </div>
                      <div className="module-info__label">
                        <div>{t(1023)}: {hardwareVersion}</div>
                        <div>{t(1024)}: {firmwareVersion}</div>
                      </div>
                    </div>

                    <div className="module-info module-info--telemetry">
                      <div className="module-info__icon">
                        <IconModuleTelemetry size={80} />
                      </div>
                      <div className="module-info__label">
                        <div>{t(4249)}: {gasControlerModel}</div>
                        <div>{t(4250)}: {gasControlerFirmware}</div>
                        <div>{t(4252)}: {gasControlerFirmwareDate}</div>
                      </div>
                    </div>
                    
                  </div>

                )
              }


              

              <div className={classnames('driving-state-icon', {
                'state-icon--processed': status === 'success',
                'state-icon--processing': status === 'fetching',
                'state-icon--unprocessed': status === 'error'
              })}>
                { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                { status === 'success' && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2465)}</span>}
                { status === 'success' && vehicle.ignition === 0 && <span className="icon-state"><IconNotifyError color="#E34B5F"/>{t(2466)}</span>}
              </div>

              <div className={classnames('connected-state-icon', {
                'state-icon--processed': status === 'success' && vehicle.ignition === 1,
                'state-icon--processing': status === 'fetching',
                'state-icon--unprocessed': status === 'error'
              })}>
                { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                { vehicle.ignition === 0 && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
                { status === 'success' && vehicle.controller_connected === 1 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2467)}</span> }
                { status === 'success' && vehicle.controller_connected === 0 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(2468)}</span> }
              </div>

              <div className={classnames('compatible-state-icon', {
                'state-icon--processed': status === 'success' && vehicle.ignition === 1 && vehicle.compatible !== -1,
                'state-icon--processing': status === 'fetching',
                'state-icon--unprocessed': status === 'error' || vehicle.compatible === -1
              })}>
                { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                { (vehicle.ignition === 0 || vehicle.compatible === -1) && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
                { status === 'success' && vehicle.compatible === 0 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(1070)}</span>}
                { status === 'success' && vehicle.compatible === 1 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(1071)}</span>}
              </div>




            </div>


            

          </div>

          {
            requestSent && (
              <div className="pairing-information">
                <div className="msg msg--info"><IconNotifyInfo color="#ffffff" />{t(2143)}</div>
              </div>
            )
          }



            <div className="pairing-buttons pairing-buttons--three">

              <div 
                className={classnames('pairing-button', {
                  'pairing-button--disabled': pairingDisabled === true
                })}
              >
                <div className="pairing-button__icon pairing-button__icon--success">
                  <IconLinkOn size={64} color={pairingDisabled ? '#d4d4d4' : '#2A2C44'} />
                </div>
                <form className="form" onSubmit={this.handlePairing}>
                  <div className="form-group">
                    <input 
                      type="submit" 
                      value={t(124)}
                      className="btn btn--primary card__footer--btn-left"
                      disabled={ (formSubmitted && status === 'fetching') || pairingDisabled ? true : false }
                    />
                  </div>
                </form>
              </div>

              <div 
                className={classnames('pairing-button', {
                  'pairing-button--disabled': pairingDisabled === true
                })}
              >
                <div className="pairing-button__icon pairing-button__icon--info">
                  <IconLinkOff size={64} color={pairingDisabled ? '#d4d4d4' : '#2A2C44'}/>
                </div>
                <form className="form" onSubmit={this.handleUnpairing}>
                  <div className="form-group">
                    <input 
                      type="submit" 
                      value={t(129)} 
                      className="btn btn--primary card__footer--btn-left"
                      disabled={ (formSubmitted && status === 'fetching') || pairingDisabled ? true : false }
                    />
                  </div>
                </form>
              </div>


              <div 
                className={classnames('pairing-button pairing-button--datepicker', {
                  'pairing-button--disabled': pairingDisabled === true
                })}
              >
                <div className="pairing-button__icon pairing-button__icon--info">
                  <IconSettings size={64} color={pairingDisabled ? '#d4d4d4' : '#2A2C44'}/>
                </div>
                <form className="form">
                  <div className="form-group">
                    <DatePicker
                      selected={filterDate}
                      selectsStart
                      onChange={this.updateFilterDate}
                      dateFormat='dd/MM/yyyy'
                      minDate={todayDate}
                      required={true}
                      disabled={ (formSubmitted && status === 'fetching') || pairingDisabled ? true : false }
                    />
                  </div>
                </form>
                <form className="form" onSubmit={this.handleMaintenanceUnpairing}>
                  <div className="form-group">
                    <input 
                      type="submit" 
                      value={`Unpair for maintenance`} 
                      className="btn btn--primary card__footer--btn-left"
                      disabled={ (formSubmitted && status === 'fetching') || pairingDisabled ? true : false }
                    />
                  </div>
                </form>
                <form className="form" onSubmit={this.handleCancelMaintenanceUnpairing}>
                <div className="form-group">
                  <input 
                    type="submit" 
                    value={`Cancel maintenance`} 
                    className="btn btn--primary card__footer--btn-left"
                    disabled={ (formSubmitted && status === 'fetching') || pairingDisabled ? true : false }
                  />
                </div>
                </form>
              </div>
            </div>
          </div>

          {
            pairing_requests_history && pairing_requests_history.length > 0 ? (
              <div className="pairing-history">
                <h3>{t(2444)}</h3>
                <div className="table-wrapper">
                  <table className="table">
                    <thead className="table__header">
                      <tr>
                        <th>{t(1173)}</th>
                        <th>{t(2445)}</th>
                        <th>{t(2458)}</th>
                        <th>{t(2459)}</th>
                        <th>{t(2460)}</th>
                      </tr>
                    </thead>
                    <tbody className="table__body table__body--striped">
                      {
                        pairing_requests_history.map((item: {set_ts: number, pair_req: number, pair_ts: number, pair_by_mod: number, mainten_ts: string}) => {
                          return <tr key={item.set_ts}>
                                    <td>
                                      {moment(item.set_ts * 1000).format('YYYY/MM/DD HH:mm:ss')}
                                    </td>
                                    <td>
                                      {item.pair_req === -2 && `${t(2446)} (-2)`}
                                      {item.pair_req === -1 && `${t(2447)} (-1)`}
                                      {item.pair_req === 0 && `${t(2450)} (0)`}
                                      {item.pair_req === 1 && `${t(2449)} (1)`}
                                      {item.pair_req === 2 && `${t(2455)} (2)`}
                                    </td>
                                    <td>
                                      { item.pair_ts !== null && item.pair_by_mod === 1 && `${t(2456)} (1)` }

                                      {
                                        item.pair_ts !== null && (item.pair_by_mod === 0 || item.pair_by_mod === null) && `${t(2457)} (0)`
                                      }

                                      { item.pair_ts === null && t(2448)}
                                    </td>
                                    <td>
                                      {item.pair_ts !== null ? moment(item.pair_ts).format('YYYY/MM/DD HH:mm:ss') : t(2448)}
                                    </td>
                                    <td>
                                      {item.mainten_ts === '0' ? 'n/a' : moment(parseInt(item.mainten_ts) * 1000).subtract(24, 'h').format('YYYY/MM/DD')}
                                    </td>
                                  </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            ) : <></>
          }

        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, moduleState }: RootState) => ({ auth, vehicle, moduleState }),
  { fetchVehicleById, fetchPairingRequestsHistory, pairingModule, fetchModuleVehicleById }
)(VehiclePairing)))
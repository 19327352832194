import React from 'react'
import ButtonBack from './ButtonBack'
import { LogoStelematicNew } from './Logos'


interface CardInfoProps {
  children: JSX.Element|JSX.Element[],
}


const CardInfo: React.FC<CardInfoProps> = ({ children }) => {
  return (
    <div className="card-info">
      <div className="card-info__header">
        <LogoStelematicNew color="#ffffff" size={100} />
      </div>
      <div className="card-info__body">
        <div className="card-info__body-nav">
          <ButtonBack navigate={false} backLink="/" />
        </div>
        <div>
          {children}
        </div>
      </div>
      <div className="card-info__footer">
      </div>
    </div>
  )
}


export default CardInfo
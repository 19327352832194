import { AUTH } from '../types'
import { API_ADDRESS } from '../config'
import t from '../components/translation/translate';
import { error } from '../helpers/notifications';


let prevMessage = 0;


const fetchAuth = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {
  dispatch({ type: AUTH.FETCH });



  return fetch(`${API_ADDRESS}/auth/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: AUTH.FETCH_ERROR, ...json, authenticated: false });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
          }
        })
        .catch(error => dispatch({
          type: AUTH.FETCH_ERROR, errors: [{ msg: error }], authenticated: false
        }));
}


export const login = (options: { email: string, password: string, keepMeSigned: boolean }) => fetchAuth({
  endpoint: 'login',
  options: {
    method: 'POST',
    body: JSON.stringify({ email: options.email, password: options.password, keepMeSigned: options.keepMeSigned }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: AUTH.FETCH_LOGIN_SUCCESS
});


export const checkAuth = () => fetchAuth({
  endpoint: '',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_AUTHENTICATED_SUCCESS
});


export const logout = () => fetchAuth({
  endpoint: 'logout',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_LOGOUT_SUCCESS
});


export const logoutAll = () => fetchAuth({
  endpoint: 'logoutall',
  options: { credentials: 'include' },
  SUCCESS_TYPE: AUTH.FETCH_LOGOUTALL_SUCCESS
});


export const forgotPassword = (options: { email: string }) => fetchAuth({
  endpoint: 'password/forgot',
  options: {
    method: 'POST',
    body: JSON.stringify({ email: options.email }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_FORGOT_SUCCESS
});


export const checkTokenValid = (options: { token: string }) => fetchAuth({
  endpoint: 'password/check',
  options: {
    method: 'POST',
    body: JSON.stringify({ token: options.token }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_CHECK_SUCCESS
});


export const resetPassword = (options: { password: string, confirm_password: string, token: string }) => fetchAuth({
  endpoint: 'password/reset',
  options: {
    method: 'POST',
    body: JSON.stringify({ password: options.password, confirm_password: options.confirm_password, token: options.token }),
    headers: { 'Content-Type': 'application/json' },
  },
  SUCCESS_TYPE: AUTH.FETCH_RESET_SUCCESS
});
import React from 'react'
import { RootState } from '../../reducers'
import { fetchVehicleById, deleteVehicleById } from '../../actions/vehicle'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'
import { error } from '../../helpers/notifications'


interface VehicleDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  vehicle: any,
  fetchVehicleById: (id: number) => Promise<any>,
  deleteVehicleById: (id: number) => Promise<any>,
}


interface VehicleDeleteState {
  vehicle: any,
  module_imei: string,
  formSubmitted: boolean,
}


export class VehicleDelete extends React.Component<VehicleDeleteProps, VehicleDeleteState> {

  state = {
    vehicle: { custom_id: '', module_imei: ''},
    module_imei: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchVehicleById(parseInt(this.props.router.params.id))
    .then(() => {
      const { vehicle } = this.props.vehicle
      this.setState({ vehicle })
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.module_imei === '') {
      error(t(2163))
      this.props.vehicle.fields = ['module_imei']
      this.setState({ formSubmitted: false })
    }
    if(this.state.module_imei !== this.props.vehicle.vehicle.module_imei) {
      error(t(2164))
      this.props.vehicle.fields = ['module_imei']
      this.setState({ formSubmitted: false })
    }
    if(this.state.module_imei === this.props.vehicle.vehicle.module_imei) {
      this.props.deleteVehicleById(this.props.vehicle.vehicle.vehicle_id)
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          this.props.router.navigate('/vehicles')
        }
        this.setState({ formSubmitted: false })
      })
    }
  }


  render() {

    const { status, fields } = this.props.vehicle;
    const { vehicle, module_imei, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
          <p>{t(2159)} <strong>{vehicle.module_imei}</strong>. {t(2160)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="module_imei">
                {t(2002)}
              </label>
              <input 
                type="text" 
                name="module_imei"
                id="module_imei" 
                value={module_imei}
                onChange={e => this.setState({ module_imei: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('module_imei') })}
              />
              <span className='form-group__explanation'>
                {t(2161)}
              </span>
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(130)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle }: RootState) => ({ vehicle }),
  { fetchVehicleById, deleteVehicleById }
)(VehicleDelete)))
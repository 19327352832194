import { STATE } from '../types'
import fetchStates from '../types/fetchStates'


interface ModuleState {
  moduleState : [],
}


const DEFAULT_STATE:ModuleState = {
  moduleState : [],
}


const moduleState = (state = DEFAULT_STATE, action:any) => {
  switch(action.type) {
    case STATE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case STATE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case STATE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        moduleState: action.moduleState,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case STATE.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default moduleState
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertGasMalfunction, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormGasSystemMalfunctionProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertGasMalfunction: (options: {id: number, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormGasSystemMalfunctionState {
  formSubmitted: boolean,
  alert_max_frequency: string
}


export class AlertFormGasSystemMalfunction extends React.Component<AlertFormGasSystemMalfunctionProps, AlertFormGasSystemMalfunctionState> {

  state = {
    formSubmitted: false,
    alert_max_frequency: '86400'
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {
      const { alerts_malf_mp } = this.props.alert.alertsSettings
        this.setState({
          alert_max_frequency: alerts_malf_mp !== null ? alerts_malf_mp : '86400'
        })
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertGasMalfunction({ id: this.props.modalId, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }


  render() {

    const { formSubmitted, alert_max_frequency } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2083)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="alert_max_frequency">{t(2093)}</label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>
            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertGasMalfunction, fetchAlertsSettingsById }
)(AlertFormGasSystemMalfunction);
export const errors = [
  // buttons and navigation
  { 
    id: 256,
    en: 'Failed to connect to switchboard',
    pl: 'Nie udało się nawiązać połączenia z centralką', 
    es: '', 
    ru: 'Не удалось установить связь с коммутатором'
  },
  { 
    id: 257,
    en: 'Lost connection with switchboard',
    pl: 'Utracono komunikację z centralką', 
    es: '', 
    ru: 'Утрачено соединение с коммутатором'
  },
  { 
    id: 512,
    en: 'No reducer temperature sensor',
    pl: 'Brak czujnika temperatury reduktora', 
    es: '', 
    ru: 'Отсутствие датчика температуры редуктора'
  },
  { 
    id: 513,
    en: 'No gas temperature sensor',
    pl: 'Brak czujnika temperatury gazu', 
    es: '', 
    ru: 'Отсутствие датчика температуры газа'
  },
  { 
    id: 514,
    en: 'Short circuit in reducer temperature sensor circuit',
    pl: 'Zwarcie w obwodzie czujnika temperatury reduktora', 
    es: '', 
    ru: 'Короткое замыкание в цепи датчика температуры редуктора'
  },
  { 
    id: 515,
    en: 'Short circuit in gas temperature sensor circuit',
    pl: 'Zwarcie w obwodzie czujnika temperatury gazu', 
    es: '', 
    ru: 'Короткое замыкание в цепи датчика температуры газа'
  },
  { 
    id: 516,
    en: 'High internal temperature',
    pl: 'Wysoka temperatura wewnętrzna', 
    es: '', 
    ru: 'Высокая внутренняя температура'
  },
  { 
    id: 517,
    en: 'No exhaust temperature sensor or circuit failure',
    pl: 'Brak czujnika temperatury spalin lub awaria obwodu', 
    es: '', 
    ru: 'Отсутствие датчика температуры выхлопных газов или неисправность цепи'
  },
  { 
    id: 518,
    en: 'High exhaust temperature',
    pl: 'Wysoka temperatura spalin', 
    es: '', 
    ru: 'Высокая температура выхлопных газов'
  },
  { 
    id: 768,
    en: 'No manifold vacuum sensor',
    pl: 'Brak czujnika podciśnienia kolektora', 
    es: '', 
    ru: 'Датчик давления впуск.коллектора отсутствует'
  },
  { 
    id: 769,
    en: 'No gas pressure sensor',
    pl: 'Brak czujnika ciśnienia gazu', 
    es: '', 
    ru: 'Датчик давления газа отсутствует'
  },
  { 
    id: 770,
    en: 'Short circuit in manifold vacuum sensor circuit',
    pl: 'Zwarcie w obwodzie czujnika podciśnienia kolektora', 
    es: '', 
    ru: 'Короткое замыкание в цепи датчика разряжения'
  },
  { 
    id: 771,
    en: 'Short circuit in gas pressure sensor circuit',
    pl: 'Zwarcie w obwodzie czujnika ciśnienia gazu', 
    es: '', 
    ru: 'Короткое замыкание в цепи датчика давления газа'
  },
  { 
    id: 772,
    en: 'Gas pressure sensor error (too low)',
    pl: 'Ciśnienie gazu niskie', 
    es: '', 
    ru: 'Ошибка датчика давления газа (слишком низкое)'
  },
  { 
    id: 773,
    en: 'Gas pressure sensor error (too high)',
    pl: 'Ciśnienie gazu zbyt wysokie', 
    es: '', 
    ru: 'Ошибка датчика давления газа (слишком высокое)'
  },
  { 
    id: 774,
    en: 'Gas installation leak detected',
    pl: 'Wykryto nieszczelność instalacji gazowej', 
    es: '', 
    ru: 'Обнаружена утечка в газовой установке'
  },
  { 
    id: 1024,
    en: 'No injection pulses - petrol injector 1',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 1', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 1'
  },
  { 
    id: 1025,
    en: 'No injection pulses - petrol injector 2',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 2', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 2'
  },
  { 
    id: 1026,
    en: 'No injection pulses - petrol injector 3',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 3', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 3'
  },
  { 
    id: 1027,
    en: 'No injection pulses - petrol injector 4',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 4', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 4'
  },
  { 
    id: 1028,
    en: 'No injection pulses - petrol injector 5',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 5', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 5'
  },
  { 
    id: 1029,
    en: 'No injection pulses - petrol injector 6',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 6', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 6'
  },
  { 
    id: 1030,
    en: 'No injection pulses - petrol injector 7',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 7', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 7'
  },
  { 
    id: 1031,
    en: 'No injection pulses - petrol injector 8',
    pl: 'Brak impulsów wtrysku - wtryskiwacz benzynowy 8', 
    es: '', 
    ru: 'Отсутствие импульсов инжекции - бензиновая форсунка 8'
  },
  { 
    id: 1032,
    en: 'No engine knock sensor or circuit failure',
    pl: 'Brak czujnika spalania stukowego lub awaria obwodu', 
    es: '', 
    ru: 'Отсутствие датчика детонации или неисправность цепи'
  },
  { 
    id: 1033,
    en: 'Detected engine knock',
    pl: 'Wykryto spalanie stukowe', 
    es: '', 
    ru: 'Обнаружена детонация'
  },
  { 
    id: 1034,
    en: 'Lambda sensor - open circuit',
    pl: 'Brak sondy lambda', 
    es: '', 
    ru: 'Отсутствие лямбда-зонда'
  },
  { 
    id: 1035,
    en: 'Lambda sensor - short circuit',
    pl: 'Zwarcie w obwodzie sondy lambda', 
    es: '', 
    ru: 'Короткое замыкание в цепи лямбда-зонда'
  },
  { 
    id: 1036,
    en: 'Error connecting the emulator, channel 1',
    pl: 'Błąd podłączenia emulatora, kanał 1', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 1'
  },
  { 
    id: 1037,
    en: 'Error connecting the emulator, channel 2',
    pl: 'Błąd podłączenia emulatora, kanał 2', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 2'
  },
  { 
    id: 1038,
    en: 'Error connecting the emulator, channel 3',
    pl: 'Błąd podłączenia emulatora, kanał 3', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 3'
  },
  { 
    id: 1039,
    en: 'Error connecting the emulator, channel 4',
    pl: 'Błąd podłączenia emulatora, kanał 4', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 4'
  },
  { 
    id: 1040,
    en: 'Error connecting the emulator, channel 5',
    pl: 'Błąd podłączenia emulatora, kanał 5', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 5'
  },
  { 
    id: 1041,
    en: 'Error connecting the emulator, channel 6',
    pl: 'Błąd podłączenia emulatora, kanał 6', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 6'
  },
  { 
    id: 1042,
    en: 'Error connecting the emulator, channel 7',
    pl: 'Błąd podłączenia emulatora, kanał 7', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 7'
  },
  { 
    id: 1043,
    en: 'Error connecting the emulator, channel 8',
    pl: 'Błąd podłączenia emulatora, kanał 8', 
    es: '', 
    ru: 'Ошибка подключения эмуляции, канал 8'
  },
  { 
    id: 1792,
    en: 'Failure of gas injector 1',
    pl: 'Awaria wtryskiwacza gazowego 1', 
    es: '', 
    ru: 'Неисправность газовой форсунки 1'
  },

  { 
    id: 1793,
    en: 'Failure of gas injector 2',
    pl: 'Awaria wtryskiwacza gazowego 2', 
    es: '', 
    ru: 'Неисправность газовой форсунки 2'
  },
  { 
    id: 1794,
    en: 'Failure of gas injector 3',
    pl: 'Awaria wtryskiwacza gazowego 3', 
    es: '', 
    ru: 'Неисправность газовой форсунки 3'
  },
  { 
    id: 1795,
    en: 'Failure of gas injector 4',
    pl: 'Awaria wtryskiwacza gazowego 4', 
    es: '', 
    ru: 'Неисправность газовой форсунки 4'
  },
  { 
    id: 1796,
    en: 'Failure of gas injector 5',
    pl: 'Awaria wtryskiwacza gazowego 5', 
    es: '', 
    ru: 'Неисправность газовой форсунки 5'
  },
  { 
    id: 1797,
    en: 'Failure of gas injector 6',
    pl: 'Awaria wtryskiwacza gazowego 6', 
    es: '', 
    ru: 'Неисправность газовой форсунки 6'
  },
  { 
    id: 1798,
    en: 'Failure of gas injector 7',
    pl: 'Awaria wtryskiwacza gazowego 7', 
    es: '', 
    ru: 'Неисправность газовой форсунки 7'
  },
  { 
    id: 1799,
    en: 'Failure of gas injector 8',
    pl: 'Awaria wtryskiwacza gazowego 8', 
    es: '', 
    ru: 'Неисправность газовой форсунки 8'
  },

  { 
    id: 1800,
    en: 'No gas injector 1',
    pl: 'Brak wtryskiwacza gazowego 1', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 1'
  },
  { 
    id: 1801,
    en: 'No gas injector 2',
    pl: 'Brak wtryskiwacza gazowego 2', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 2'
  },
  { 
    id: 1802,
    en: 'No gas injector 3',
    pl: 'Brak wtryskiwacza gazowego 3', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 3'
  },
  { 
    id: 1803,
    en: 'No gas injector 4',
    pl: 'Brak wtryskiwacza gazowego 4', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 4'
  },
  { 
    id: 1804,
    en: 'No gas injector 5',
    pl: 'Brak wtryskiwacza gazowego 5', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 5'
  },
  { 
    id: 1805,
    en: 'No gas injector 6',
    pl: 'Brak wtryskiwacza gazowego 6', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 6'
  },
  { 
    id: 1806,
    en: 'No gas injector 7',
    pl: 'Brak wtryskiwacza gazowego 7', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 7'
  },
  { 
    id: 1807,
    en: 'No gas injector 8',
    pl: 'Brak wtryskiwacza gazowego 8', 
    es: '', 
    ru: 'Отсутствие газовой форсунки 8'
  },
  { 
    id: 1808,
    en: 'Gas injection into the cylinder that is turned off',
    pl: 'Wtrysk gazu do wyłączonego cylindra', 
    es: '', 
    ru: 'Gas injection into the cylinder that is turned off'
  },
  { 
    id: 2048,
    en: 'Failure of peripherals supply circuit',
    pl: 'Awaria obwodu zasilania peryferii', 
    es: '', 
    ru: 'Неисправность цепи питания периферийных устройств'
  },
  { 
    id: 2049,
    en: 'Failure of injectors supply circuit',
    pl: 'Awaria obwodu zasilania wtryskiwaczy', 
    es: '', 
    ru: 'Неисправность цепи питания форсунок'
  },
  { 
    id: 2050,
    en: 'Failure of solenoid valves supply circuit',
    pl: 'Awaria obwodu zasilania elektrozaworów', 
    es: '', 
    ru: 'Неисправность цепи питания электроклапанов'
  },
  { 
    id: 2051,
    en: 'No solenoid valve',
    pl: 'Brak elektrozaworu', 
    es: '', 
    ru: 'Отсутствие электроклапана'
  },
  { 
    id: 2052,
    en: 'Low power supply voltage',
    pl: 'Niskie napięcie zasilania', 
    es: '', 
    ru: 'Низкое напряжение питания'
  },
  { 
    id: 2053,
    en: 'High power supply voltage',
    pl: 'Wysokie napięcie zasilania', 
    es: '', 
    ru: 'Высокое напряжение питания'
  },
  { 
    id: 2054,
    en: 'Failure / overload of AUX 12V circuit',
    pl: 'Awaria / przeciążenie obwodu AUX 12V', 
    es: '', 
    ru: 'Отказ / перегрузка AUX 12V цепи'
  },
  { 
    id: 2055,
    en: 'Gas level indicator short circuit or circuit failure',
    pl: 'Zwarcie lub awaria obwodu zasilania wskaźnika poziomu gazu', 
    es: '', 
    ru: 'Индикатор уровня газа - короткое замыкание или неисправность цепи'
  },
  { 
    id: 2056,
    en: 'Failure of gas return solenoid valve supply circuit',
    pl: 'Awaria obwodu zasilania elektrozaworu powrotu gazu', 
    es: '', 
    ru: 'Failure of gas return solenoid valve supply circuit'
  },
  { 
    id: 2057,
    en: 'No gas return solenoid valve',
    pl: 'Brak elektrozaworu powrotu gazu', 
    es: '', 
    ru: 'No gas return solenoid valve'
  },
  { 
    id: 2305,
    en: 'Data bus communication circuit fault',
    pl: 'Awaria obwodu magistrali komunikacyjnej', 
    es: '', 
    ru: 'Неисправность цепи коммуникационной магистрали'
  },
  { 
    id: 32769,
    en: 'Parameters error, check parameters',
    pl: 'Błąd nastaw, sprawdź nastawy', 
    es: '', 
    ru: 'Ошибка настроек, проверить настройки'
  },
  { 
    id: 32770,
    en: 'Map error, check map',
    pl: 'Błąd mapy, sprawdź mapę', 
    es: '', 
    ru: 'Ошибка карты, проверить карту'
  },
  { 
    id: 32771,
    en: 'Controller was restarted',
    pl: 'Sterownik był restartowany', 
    es: '', 
    ru: 'Блок управления был перезагружен'
  },

]
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { fetchRoutesVehicleById } from '../../actions/vehicle'
import { fetchAllStations } from '../../actions/station'
import { fetchAllCompetitors } from '../../actions/competitor'
import { fetchAllRfid } from '../../actions/rfid'
import fetchStates from '../../types/fetchStates'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import moment from 'moment-timezone'
import { DEFAULT_LAT, DEFAULT_LNG, TIMEZONE } from '../../config'
import { GoogleMap, Marker } from '@react-google-maps/api'
import routesColorRoute from '../../functions/routesColorRoutes'
import routesColorCards from '../../functions/routesColorCards'
import routesCreateLegend from '../../functions/routesCreateLegend'
import cardsCreateLegend from '../../functions/cardsCreateLegend'
import routesCreatePagination from '../../functions/routesCreatePagination'
import cardsCreatePagination from '../../functions/cardsCreatePagination'
import DatePicker from 'react-datepicker'
import { IconDropdown, IconNoData } from '../partials/Icons'
import CtaBox from '../partials/CtaBox'
import { motion } from 'framer-motion';
import { motion_container } from '../../helpers/motion'
import SwitchFixed from '../partials/SwitchFixed'
import { drawStationMarker, drawCompetitorMarker, drawCardStart, drawCardEnd, drawRouteStart, drawRouteEnd } from '../../functions/drawMarkers'
import Preloader from '../partials/Preloader'


interface VehiclesRoutesProps {
  router: { params: { id: string, date: string }, navigate: (to: string) => any },
  auth: any
  station: any,
  competitor: any, 
  vehicle: any, 
  rfid: any,
  fetchAllStations: (options: { limit: number, skip: number}) => Promise<any>, 
  fetchAllCompetitors: (options: { limit: number, skip: number}) => Promise<any>, 
  fetchRoutesVehicleById: (options: { id: number, date: string }) => Promise<any>, 
  checkAuth: () => Promise<any>, 
  fetchAllRfid: (options: { limit: number, skip: number}) => Promise<any>, 
}


interface VehiclesRoutesState {
  rfid: any,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  timezone: string,
  filterDate: any,
  todayDate: any,
  id_mod: string,
  locationPoints: number,
  noData: boolean,
  vehicleRoutes: any,
  filteredVehicleRoutes: any,
  filteredVehicleCards: any,
  routesLegend: any,
  routesPagination: any,
  vehicleCards: any,
  cardLegend: any,
  cardsPagination: any,
  currentRoute: number,
  currentCard: number,
  selected: string,
  openInfoBox: boolean,
  selectedPoint: any,
  showStations: boolean,
  coloredCards: any, 
  coloredRoutes: any,
  map: any,
}


export class VehiclesRoutes extends React.Component<VehiclesRoutesProps, VehiclesRoutesState> {

  state = {
    rfid: [],
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: 8,
    timezone: TIMEZONE,
    filterDate: new Date(),
    todayDate: new Date(),
    id_mod: '',
    locationPoints: 0,
    noData: false,
    vehicleRoutes: [],
    filteredVehicleRoutes: [],
    filteredVehicleCards: [],
    routesLegend: [],
    routesPagination: [],
    vehicleCards: [],
    cardLegend: [],
    cardsPagination: [],
    currentRoute: 0,
    currentCard: 0,
    selected: 'routes',
    openInfoBox: false,
    selectedPoint: { ts: 0, loc_sp: ''},
    showStations: true,
    coloredCards: [], 
    coloredRoutes: [],
    map: {}
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    if( this.props.router.params.date !== undefined ) {
      this.setState({ filterDate: new Date(this.props.router.params.date) })
    }
    this.props.checkAuth()
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        if( this.props.router.params.id !== undefined ) {
          if(this.props.auth.authorized.stations === true) {
            this.props.fetchAllStations({ limit: 1000, skip: 0 });
          }
          if(this.props.auth.authorized.competitors === true) {
            this.props.fetchAllCompetitors({ limit: 1000, skip: 0 });
          }
          if(this.props.auth.authorized.rfid === true) {
            this.props.fetchAllRfid({ limit: 1000, skip: 0 })
          }

          this.setState({ rfid: this.props.rfid.rfid })
          this.getRoutesData(parseInt(this.props.router.params.id), this.getTimezoneOffset(this.state.filterDate))

        }
      }
    })
  }


  updateFilterDate = (date:any) => {
    this.setState({ filterDate: date, noData: true });
    this.getRoutesData(parseInt(this.props.router.params.id), this.getTimezoneOffset(date))
    this.props.router.navigate(`/vehicles/routes/${parseInt(this.props.router.params.id)}/${moment(date).format('YYYY-MM-DD')}`)
  }

  
  getRoutesData = (id: number, date: string) => {
    this.props.fetchRoutesVehicleById({ id, date })
    .then(() => {

        const { vehicleRoutes } = this.props.vehicle;
        if( vehicleRoutes && vehicleRoutes.length > 0 ) {

          const { id_mod } = this.props.vehicle.vehicle;
          const { loc_ln, loc_lt } = vehicleRoutes[0];
          const locationPoints = vehicleRoutes.length;

          const coloredRoutes = routesColorRoute(vehicleRoutes);
          const routesLegend = routesCreateLegend(coloredRoutes, this.state.timezone);
          const routesPagination = routesCreatePagination(coloredRoutes);

          const coloredCards = routesColorCards(vehicleRoutes);
          const cardLegend = cardsCreateLegend(coloredCards, this.state.timezone, this.state.rfid);
          const cardsPagination = cardsCreatePagination(coloredCards);

          this.setState({
            id_mod,
            mapLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
            mapLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
            vehicleRoutes,
            coloredRoutes,
            coloredCards,
            locationPoints,
            routesLegend,
            routesPagination,
            cardLegend,
            cardsPagination,
            currentRoute: 0,
            currentCard: 0,
            noData: false,
          }, () => this.handleFilter(8));
    
        } else {
          this.setState({ noData: true })
        }

    })
  }
  

  getTimezoneOffset(time: any) {
    const UTCServerTime = moment(time).format('YYYY-MM-DD 00:00:00')
    return moment(UTCServerTime).format('X')
  }


  handleFilterRoutes = (zoom: number, vehicleRoutes: any) => {
    let filteredVehicleRoutes = []
    if(vehicleRoutes && vehicleRoutes.length > 5) {
      if(zoom < 13) {
        filteredVehicleRoutes = vehicleRoutes.filter(function(_: any, i: number) {
          return i % 5 === 0;
        })
      } else if (zoom === 13) {
        filteredVehicleRoutes = vehicleRoutes.filter(function(_: any, i: number) {
          return i % 3 === 0;
        })
      } else if (zoom === 14) {
        filteredVehicleRoutes = vehicleRoutes.filter(function(_: any, i: number) {
          return i % 2 === 0;
        })
      } else {
        filteredVehicleRoutes = vehicleRoutes
      }
    } else {
      filteredVehicleRoutes = vehicleRoutes
    }
    this.setState({ filteredVehicleRoutes, mapZoom: zoom })
  }

  
  selectRoute = (routeIndex: number) => {
    const { loc_ln, loc_lt } = this.state.routesPagination[routeIndex][0];
    this.setState({
      selected: 'routes',
      currentCard: 0,
      vehicleRoutes: this.state.routesPagination[routeIndex],
      currentRoute: routeIndex,
      mapLat: loc_lt !== undefined && loc_lt !== null ? loc_lt : DEFAULT_LAT,
      mapLng: loc_ln !== undefined && loc_ln !== null ? loc_ln : DEFAULT_LNG,
      openInfoBox: false
    })
    this.handleFilterRoutes(this.state.mapZoom, this.state.routesPagination[routeIndex])
  }


  selectCard = (cardIndex: number) => {
    const { loc_ln, loc_lt } = this.state.cardsPagination[cardIndex][0];
    this.setState({
      selected: 'cards',
      currentRoute: 0,
      vehicleCards: this.state.cardsPagination[cardIndex],
      currentCard: cardIndex,
      mapLat: loc_lt !== undefined && loc_lt !== null ? loc_lt : DEFAULT_LAT,
      mapLng: loc_ln !== undefined && loc_ln !== null ? loc_ln : DEFAULT_LNG,
      openInfoBox: false
    })
    this.handleFilterCards(this.state.mapZoom, this.state.cardsPagination[cardIndex])
  }


  handleFilterCards = (zoom: number, vehicleCards: any) => {
    let filteredVehicleCards = []
    if(vehicleCards.length > 5) {
      if(zoom < 13) {
        filteredVehicleCards = vehicleCards.filter(function(_: any, i: number) {
          return i % 5 === 0;
        })
      } else if (zoom === 13) {
        filteredVehicleCards = vehicleCards.filter(function(_: any, i: number) {
          return i % 3 === 0;
        })
      } else if (zoom === 14) {
        filteredVehicleCards = vehicleCards.filter(function(_: any, i: number) {
          return i % 2 === 0;
        })
      } else {
        filteredVehicleCards = vehicleCards
      }
    } else {
      filteredVehicleCards = vehicleCards
    }

    this.setState({ filteredVehicleCards, mapZoom: zoom })
  }
  

  handleFilter = (zoom: number) => {
    if(this.state.selected === 'routes') {
      this.handleFilterRoutes(zoom, this.state.routesPagination[this.state.currentRoute])
    }
    if(this.state.selected === 'cards') {
      this.handleFilterCards(zoom, this.state.cardsPagination[this.state.currentCard])
    }
  }



  drawRouteMarker = (route: any, index: number) => {
    const lat = parseFloat(route.loc_lt);
    const lng = parseFloat(route.loc_ln);
    const deg = parseFloat(route.loc_hd);

    const svgMarker = {
      path: "M18.2063158,6.92210526 L9.78526316,0.185263158 C9.47920442,-0.0565874416 9.04711137,-0.0565874416 8.74105263,0.185263158 L0.32,6.92210526 C0.118957956,7.08096622 0.00118128148,7.32271834 1.77635684e-15,7.57894737 L1.77635684e-15,14.3157895 C0.000916965625,14.6397132 0.187550499,14.9343977 0.48,15.0736842 C0.769917372,15.2112156 1.11279931,15.1720291 1.36421053,14.9726316 L9.26315789,8.65684211 L17.1621053,14.9726316 C17.3102647,15.0915037 17.4942617,15.1567929 17.6842105,15.1578947 C17.8099947,15.1599724 17.9343559,15.1310512 18.0463158,15.0736842 C18.3387653,14.9343977 18.5253988,14.6397132 18.5263158,14.3157895 L18.5263158,7.57894737 C18.5251345,7.32271834 18.4073578,7.08096622 18.2063158,6.92210526 Z",
      fillColor: route.color,
      strokeColor: '#000000',
      fillOpacity: 1,
      strokeWeight: 2,
      rotation: deg,
      scale: 1,
      anchor: new window.google.maps.Point(8, 16),
    };

    return <Marker
      key={index}
      zIndex={100}
      position={{
        lat,
        lng
      }}
      icon={svgMarker}
      onClick={() => this.openInfoBox(route)}
      // animation={route.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
    />
  }





  drawCardMarker = (route: any, index: number) => {
    const lat = parseFloat(route.loc_lt);
    const lng = parseFloat(route.loc_ln);
    const deg = parseFloat(route.loc_hd);

    const svgMarker = {
      path: "M18.2063158,6.92210526 L9.78526316,0.185263158 C9.47920442,-0.0565874416 9.04711137,-0.0565874416 8.74105263,0.185263158 L0.32,6.92210526 C0.118957956,7.08096622 0.00118128148,7.32271834 1.77635684e-15,7.57894737 L1.77635684e-15,14.3157895 C0.000916965625,14.6397132 0.187550499,14.9343977 0.48,15.0736842 C0.769917372,15.2112156 1.11279931,15.1720291 1.36421053,14.9726316 L9.26315789,8.65684211 L17.1621053,14.9726316 C17.3102647,15.0915037 17.4942617,15.1567929 17.6842105,15.1578947 C17.8099947,15.1599724 17.9343559,15.1310512 18.0463158,15.0736842 C18.3387653,14.9343977 18.5253988,14.6397132 18.5263158,14.3157895 L18.5263158,7.57894737 C18.5251345,7.32271834 18.4073578,7.08096622 18.2063158,6.92210526 Z",
      fillColor: route.color,
      strokeColor: '#000000',
      fillOpacity: 1,
      strokeWeight: 2,
      rotation: deg,
      scale: 1,
      anchor: new window.google.maps.Point(8, 16),
    };

    return <Marker
      key={index}
      zIndex={100}
      position={{
        lat,
        lng
      }}
      icon={svgMarker}
      onClick={() => this.openInfoBox(route)}
      // animation={route.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
    />
  }


  openInfoBox = (selectedPoint: any) => {
    this.setState({ openInfoBox: true, selectedPoint, mapLat: selectedPoint.loc_lt, mapLng: selectedPoint.loc_ln })
  }


  toggleMapStations = () => {
    this.setState({ showStations: !this.state.showStations })
  }


  render() {

    const { stations } = this.props.station;
    const { competitors } = this.props.competitor;
    const { vehicle, status } = this.props.vehicle;
    const { filterDate, todayDate, noData, coloredCards, coloredRoutes, filteredVehicleRoutes, filteredVehicleCards, mapLat, mapLng, mapZoom, routesLegend, cardLegend, currentRoute, currentCard, openInfoBox, selectedPoint, timezone, selected, showStations } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['none']}
          tabnav='vehicle-overview'
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >

          { vehicle.connected !== null ? (
              <>
                <form className="form">
                  <div className="filter">
                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(2050)}</span>
                      <span className="filter_filter-select">
                        <DatePicker
                          selected={filterDate}
                          selectsStart
                          onChange={this.updateFilterDate}
                          dateFormat='dd/MM/yyyy'
                          maxDate={todayDate}
                          required={true}
                        />
                      </span>
                    </span>
                  </div>
                </form>

                { 
                  vehicle && noData === true && (
                    <CtaBox padding type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(2181)} <em>{moment(filterDate).format('DD/MM/YYYY')}</em>. {t(2182)}
                    </CtaBox>
                  )
                }

                

                { noData === false && coloredCards && coloredRoutes &&
                  <div className="google-maps-wrapper google-maps-wrapper--vehicle-routes">
                  <GoogleMap
                    center={{ 
                      lat: mapLat, 
                      lng: mapLng
                    }}
                    zoom={ mapZoom }
                    onZoomChanged={() => this.handleFilter(mapZoom)}
                    onLoad={map => { this.setState({ map }) }}
                  >
                    { showStations && stations && stations.map((station:any) => (
                      drawStationMarker(station)
                    )) }

                    { showStations && competitors && competitors.map((competitor:any) => (
                      drawCompetitorMarker(competitor)
                    )) }

                    { selected === 'routes' && filteredVehicleRoutes && filteredVehicleRoutes.length > 0 && filteredVehicleRoutes.map((route, index) => {
                      if(index === 0) {
                        return drawRouteStart(route)
                      }
                      if(index > 0) {
                        if(index === filteredVehicleRoutes.length - 1) {
                          return drawRouteEnd(route)
                        }
                        return this.drawRouteMarker(route, index)
                      }
                      return null
                    }) }


                    { selected === 'cards' && filteredVehicleCards && filteredVehicleCards.length > 0 && filteredVehicleCards.map((route, index) => {
                      if(index === 0) {
                        return drawCardStart(route)
                      }
                      if(index > 0) {
                        if(index === filteredVehicleCards.length - 1) {
                          return drawCardEnd(route)
                        }
                        return this.drawCardMarker(route, index)
                      }
                      return null
                    }) }


                    {
                      routesLegend && routesLegend.length > 0 && (
                        <motion.div 
                          className="wrapper wrapper--top-left" 
                          variants={motion_container} 
                          initial="hidden"
                          animate="visible"
                        >
                          <div className="legend-wrapper legend-wrapper--routes">
                            <div className="legend-routes">
                              <div key={0} className={`legend-route ${0 === currentRoute && 'legend-route-active' }`} onClick={() => this.selectRoute(0)}>{t(2183)}</div>
                              {routesLegend.map((item: { color: string, duration: number, distance: number, routeStart: number, routeEnd: number }, index: number) => (
                                <div key={index+1} className={`legend-route ${index+1 === currentRoute && 'legend-route-active' }`} onClick={() => this.selectRoute(index+1)}><span className="route-item-color" style={{ backgroundColor: item.color }}></span> {item.routeStart} - {item.routeEnd} ({moment.utc(item.duration*1000).format('HH:mm:ss')} / {item.distance}km)</div>
                              ))}
                            </div>
                            {
                              routesLegend.length > 7 && (
                                <div className="legend-routes-more"><IconDropdown color="#ffffff" /></div>
                              )
                            }
                          </div>

                          {
                            openInfoBox && (
                              <motion.div className="legend-route-point-wrapper" variants={motion_container} initial="hidden"
                              animate="visible">
                                {selectedPoint && (
                                  <>
                                    <span>{moment(moment.utc(selectedPoint.ts*1000).toDate()).tz(timezone).format('HH:mm:ss')}</span>&nbsp;-&nbsp;
                                    <span>{parseFloat(selectedPoint.loc_sp)} km/h</span>
                                  </>
                                )}
                              </motion.div>
                            )
                          }
                          
                        </motion.div>
                      )
                    }


                    {
                      cardLegend && cardLegend.length > 0 && (
                        <motion.div className="legend-wrapper legend-wrapper--cards" variants={motion_container} initial="hidden"
                        animate="visible">
                          <div className="legend-routes">
                            <div key={0} className={`legend-route ${0 === currentCard && 'legend-route-active' }`} onClick={() => this.selectCard(0)}>All</div>
                            {cardLegend.map((item: {custom_id: string, card: string, hwi_ca: number}, index: number) => (
                              <div key={index+1} className={`legend-route ${index+1 === currentCard && 'legend-route-active' }`} onClick={() => this.selectCard(index+1)}><span className="route-item-color" style={{ backgroundColor: item.card }}></span> {item.custom_id ? item.custom_id : item.hwi_ca}</div>
                            ))}
                          </div>
                          {
                            cardLegend.length > 7 && (
                              <div className="legend-routes-more"><IconDropdown color="#ffffff" /></div>
                            )
                          }
                        </motion.div>
                      )
                    }



                    <div className="map-constraints-container" />
                    </GoogleMap>
                    <div className="map-statins--on-off">
                      <form className="form form--padding">
                        <div className="form-group__switch form-group__switch--fixed">
                          <label onClick={() => this.toggleMapStations()}>
                            <SwitchFixed switchState={showStations} disabled={false} />
                            <span className="label-title">{t(2500)}</span>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                }
              </>
            ) : <></> }       

        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, station, competitor, vehicle, rfid }: RootState) => ({ auth, station, competitor, vehicle, rfid }),
  { fetchAllStations, fetchAllCompetitors, fetchRoutesVehicleById, checkAuth, fetchAllRfid }
)(VehiclesRoutes)))
import React from 'react'
import { RootState } from '../../reducers'
import { fetchGroupById, deleteGroupById } from '../../actions/group';
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'


interface GroupsDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  group: any,
  fetchGroupById: (id: string) => Promise<any>,
  deleteGroupById: (id: string) => Promise<any>,
}


interface GroupsDeleteState {
  group: { group_title: string },
  group_title: string,
  formSubmitted: boolean
}


export class GroupsDelete extends React.Component<GroupsDeleteProps, GroupsDeleteState> {

  state = {
    group: { group_title: '' },
    group_title: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchGroupById(this.props.router.params.id)
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        this.setState({ group: this.props.group.group })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.group_title === '') {
      this.props.group.fields = ['group_title']
      this.setState({ formSubmitted: false })
      return error(2430)
    }
    if(this.state.group_title !== this.props.group.group.group_title) {
      this.props.group.fields = ['group_title']
      this.setState({ formSubmitted: false })
      return error(2431)
    }
    if(this.state.group_title === this.props.group.group.group_title) {
      this.props.deleteGroupById(this.props.group.group.group_id)
      .then(() => {
        if(this.props.group.status === fetchStates.success) {
          this.props.router.navigate('/groups');
        }
        this.setState({ formSubmitted: false });
      })
    }
  }


  render() {

    const { status, fields } = this.props.group;
    const { group, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(1145)} ${group.group_title}`}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(1144)} <strong>{group.group_title}</strong>. {t(2026)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="group_title">
                {t(1143)}
              </label>
              <input 
                type="text" 
                name="group_title"
                id="group_title" 
                value={this.state.group_title}
                onChange={e => this.setState({ group_title: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('group_title') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(217)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ group }: RootState) => ({ group }),
  { fetchGroupById, deleteGroupById }
)(GroupsDelete)))
import { jsPDF } from "jspdf"
import moment from 'moment-timezone'
import logoImage from'../img/logo-client.png'
import t from '../components/translation/translate'


function createNewReportCheckpointsPDF(data: any) {

  // date
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();
  const todayDate = `${yyyy}-${mm}-${dd}`;


  // document settings
  const unit = "mm";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);


  // building up the report
  const tableTitle = 'Reports for ' + todayDate;
  const numTableCols = countProperties();
  function countProperties() {
    return 5//Object.keys(obj).length - 2; // count of the two we want to hide
  }


  // table defaults
  const fontSize = 8;
  const rowHeight = 10; // max 10 rowHeight with 15 perPage
  const rowWidth = 265;
  const perPage = 15; // max 15 perPage with 10 rowHeight
  const pagesNum = Math.ceil(data.length / perPage);
  const headerOffset = 35;
  const linePosition = headerOffset + rowHeight / 2 + fontSize / rowHeight;


  // add logo
  var img = new Image();
  img.src = logoImage;


  // loop throug pages
  for (let i = 0; i < pagesNum; i++) {

    if (i > 0) {
      doc.addPage();
    }

    // title
    doc.setTextColor(0, 0, 0);
    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.setFontSize(18);
    //doc.setFontStyle('normal');
    doc.addImage(img, 'PNG', 15, 14, 8, 8);
    doc.text(tableTitle, 27, 20);

    
    // table header
    doc.setFontSize(fontSize);
    // doc.setFontStyle('bold');
    doc.line(15, 25, 280, 25);
    

    const tableHeaderArray = ['Vehicle', t(1118), t(1119), t(1120), t(1121)];

    
    doc.setFillColor(255, 255, 255);
    doc.rect(15, 25, rowWidth, rowHeight, 'F');
    for (let x = 0; x < tableHeaderArray.length; x++) {
      doc.text(tableHeaderArray[x], 17 + rowWidth / numTableCols * x, 31.5);
    }
    doc.line(15, headerOffset, 280, headerOffset);
    


    // table content
    const pageOffset = i * perPage;
    const slicedData = data.slice(0 + pageOffset, 15 + pageOffset);
    const pageNum = i + 1;


    console.log(data)


    slicedData.forEach(function (item: any, index: number) {


      const rowTopPosition = headerOffset + rowHeight * index;
      // making the row stripy
      if (index % 2 === 0) {
        doc.setFillColor(231, 251, 255);
      } else {
        doc.setFillColor(255, 255, 255);
      }
      doc.rect(15, rowTopPosition, rowWidth, rowHeight, 'F');


      // unitsConverter(this.state.units, 'km', item.distance, 3)
      // looping through items in given row

      // checkpoint ID
      doc.text(item.vehicle, 17, linePosition + rowHeight * index);

      // arrived
      doc.text(item.custom_id, 17 + rowWidth / numTableCols * 1, linePosition + rowHeight * index);

      // left
      doc.text(item.stop_ts, 17 + rowWidth / numTableCols * 2, linePosition + rowHeight * index);

      // left
      doc.text(item.start_ts, 17 + rowWidth / numTableCols * 3, linePosition + rowHeight * index);

      // total time
      doc.text(moment("1900-01-01 00:00:00").add(item.diff, 'seconds').format("HH:mm:ss"), 17 + rowWidth / numTableCols * 4, linePosition + rowHeight * index);

      return;
      
    });


    // table footer
    doc.setTextColor(0, 0, 0);
    doc.text('PAGE ' + pageNum + '/' + pagesNum, rowWidth / 2, perPage * rowHeight + headerOffset + rowHeight);


  }


  // save document
  doc.save('stelematics-reports-' + todayDate + '.pdf')

}


export default createNewReportCheckpointsPDF
import { ACCOUNT } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchAccount = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: ACCOUNT.FETCH });
  return fetch(`${API_ADDRESS}/account/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: ACCOUNT.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: ACCOUNT.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllAccounts = (options: { limit: number, skip: number }) => fetchAccount({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ALL_SUCCESS
});


export const fetchAccountById = (id: string) => fetchAccount({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ID_SUCCESS
});


export const updateAccountById = (options: { account: any }) => fetchAccount({
  endpoint: `${options.account.account_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.account }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_UPDATE_SUCCESS
});


export const deleteAccountById = (id: string) => fetchAccount({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_DELETE_SUCCESS
});


export const addNewAccount = (account: any) => fetchAccount({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...account }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_ADD_SUCCESS
});


export const fetchAllDistributorAccountGroups = (options: { limit: number, skip: number }) => fetchAccount({
  endpoint: 'distributor-accounts/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ALL_DISTRIBUTOR_GROUPS_SUCCESS
});


export const addNewDistributorAccountGroup = (distributor_account_groups: any) => fetchAccount({
  endpoint: 'distributor-accounts',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...distributor_account_groups }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_ADD_DISTRIBUTOR_GROUPS_SUCCESS
});


export const fetchDistributorAccountGroupById = (id: string) => fetchAccount({
  endpoint: `distributor-accounts/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ID_DISTRIBUTOR_GROUP_SUCCESS
});


export const deleteDistributorAccountGroupById = (id: string) => fetchAccount({
  endpoint: `distributor-accounts/${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_DELETE_DISTRIBUTOR_GROUP_SUCCESS
});


export const updateDistributorAccountGroupById = (distributor_account_groups: any) => fetchAccount({
  endpoint: `distributor-accounts/${distributor_account_groups.distributor_group_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...distributor_account_groups }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_UPDATE_DISTRIBUTOR_GROUP_SUCCESS
});


export const changeUserAccount = (account_id: string) => fetchAccount({
  endpoint: 'distributor-accounts/change-account',
  options: {
    method: 'POST',
    body: JSON.stringify({ account_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: ACCOUNT.FETCH_CHANGE_DISTRIBUTOR_ACCOUNT_SUCCESS
});


export const fetchDistributorAccountGroups = () => fetchAccount({
  endpoint: 'distributor-accounts/account-groups',
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ALL_DISTRIBUTOR_ACCOUNTS_SUCCESS
});


export const fetchAccountVehicleUsage = (options: { limit: number, skip: number, date: string, account_id: string, filter_type: string }) => fetchAccount({
  endpoint: `account-vehicle-usage/?limit=${options.limit}&skip=${options.skip}&date=${options.date}&account_id=${options.account_id}&filter_type=${options.filter_type}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ACCOUNT_VEHICLE_USAGE_SUCCESS
});


export const fetchAllAccountVehicleUsage = (options: {date: string, account_id: string, filter_type: string }) => fetchAccount({
  endpoint: `account-vehicle-usage-report/?date=${options.date}&account_id=${options.account_id}&filter_type=${options.filter_type}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: ACCOUNT.FETCH_ACCOUNT_VEHICLE_USAGE_REPORT_SUCCESS
})
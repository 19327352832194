import { GROUP } from '../types'
import fetchStates from '../types/fetchStates'


interface GroupState {
  group: {},
  groups: [],
  groupsCount: number,
  authGroups: [],
  authGroupsCount: number,
}


const DEFAULT_GROUP: GroupState = {
  group: {},
  groups: [],
  groupsCount: 0,
  authGroups: [],
  authGroupsCount: 0
}


const group = (state = DEFAULT_GROUP, action: any) => {
  switch(action.type) {
    case GROUP.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case GROUP.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case GROUP.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        groups: action.groups,
        groupsCount: parseInt(action.groupsCount, 10),
      };
    case GROUP.FETCH_ALL_AUTH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        authGroups: action.authGroups,
        authGroupsCount: parseInt(action.authGroupsCount, 10),
      };
    case GROUP.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        group: action.group,
      };
    case GROUP.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case GROUP.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case GROUP.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case GROUP.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case GROUP.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default group
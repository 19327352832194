import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabBilling: React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/subscription' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/subscription' })}>
        {t(3)}
      </NavLink>

      <NavLink to={{ pathname: '/billing/statements' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/billing/statements' })}>
        {t(140)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>

      <NavLink to={{ pathname: '/billing/settings' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/billing/settings' })}>
        Billing Settings
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>

    </nav>
  )
}


export default TabBilling
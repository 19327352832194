import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { addNewGroup } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { v4 as uuidv4 } from 'uuid'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface GroupsAddProps {
  router: { navigate: (to: string) => any },
  group: any,
  addNewGroup: (group: any) => Promise<any>,
}


interface GroupsAddState {
  group_id: string,
  group_title: string,
  formSubmitted: boolean,
}


export class GroupsAdd extends React.Component<GroupsAddProps, GroupsAddState> {

  state = {
    group_id: uuidv4(),
    group_title: '',
    formSubmitted: false,
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { group_id, group_title } = this.state
    const group = { group_id, group_title }
    this.props.addNewGroup(group)
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        this.props.router.navigate('/groups');
      }
      this.setState({ formSubmitted: false });
    })
  }


  render() {

    const { fields, status } = this.props.group;
    const { group_title, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(222)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          buttonLink='/groups' 
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

          <div className="form-group">
            <label htmlFor="group_title">
              {t(223)}
            </label>
            <input 
              type="text" 
              name="group_title"
              id="group_title" 
              value={group_title}
              onChange={e => this.setState({ group_title: e.target.value })}
              className={classnames('', { 'input-error': fields && fields.includes('group_title') })}
            />
          </div>

          <div className="form-group">
            <input 
              type="submit" 
              value={t(224)} 
              className="btn btn--primary card__footer--btn-left"
              disabled={ formSubmitted ? true : false }
            />
          </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ group }: RootState) => ({ group }),
  { addNewGroup }
)(GroupsAdd)))
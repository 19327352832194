
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'


interface AuthProps {
  auth: { role: number },
  requiresRole: Array<number>,
  children: JSX.Element,
}


export class Auth extends React.Component<AuthProps> {

  render() {

    let { requiresRole } = this.props

    if(requiresRole === undefined) {
      requiresRole = [1]
    }

    if(!requiresRole.includes(this.props.auth.role)) {
      return null
    }

    return (
      <>
        {this.props.children}
      </>
    );
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  {}
)(Auth);
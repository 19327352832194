import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchReportsNewVehicleById, fetchDatesReportsNewVehicleById, fetchFuelPrices } from '../../actions/vehicle'
import fetchStates from '../../types/fetchStates'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Preloader from '../partials/Preloader'
import EstimationNote from '../partials/EstimationNote'
import DatePicker from 'react-datepicker'
import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom'
import { IconNoData, IconMap } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import moment from 'moment-timezone'
import queryString from 'query-string'
import createNewReportPDF from '../../functions/createNewReportPDF'
import createNewReportCSV from '../../functions/createNewReportCSV'
import calculateObjectReportSavingsNew from '../../functions/calculateObjectReportSavingsNew'


interface VehiclesReportProps {
  router: { params: { id: string }, location: {search: string}, navigate: (to: string) => any },
  vehicle: any,
  auth: any,
  fetchReportsNewVehicleById: (options: {id: number, limit: number, skip: number, from: string, to: string}) => Promise<any>,
  fetchDatesReportsNewVehicleById: (options: {id: number, from: string, to: string}) => Promise<any>,
}


interface VehiclesReportState {
  currentPage: number,
  perPage: number,
  module_imei: string,
  noData: boolean,
  date: any,
  temp_cost_petrol: number,
  temp_cost_diesel: number,
  temp_cost_lpg: number,
  temp_cost_cng: number,
  filterDateFrom: any,
  filterDateTo: any,
  todayDate: any,
  defaultToDate: any,
  vehicle: { custom_id: string, module_imei: string, connected: string},
  vehicleReportsNew: any, 
  vehicleReportsNewCount: number,
}


export class VehiclesReport extends React.Component<VehiclesReportProps, VehiclesReportState> {

  state = {
    currentPage: 0,
    perPage: 10,
    module_imei: '',
    noData: false,
    date: new Date(),
    temp_cost_petrol: 0,
    temp_cost_diesel: 0,
    temp_cost_lpg: 0,
    temp_cost_cng: 0,
    filterDateFrom:  moment().subtract(1, 'month').toDate(),
    filterDateTo: moment().toDate(),
    todayDate: moment().toDate(),
    defaultToDate: moment().toDate(),
    vehicle: { custom_id: '', module_imei: '', connected: ''},
    vehicleReportsNew: [], 
    vehicleReportsNewCount: 0
  }


  componentDidMount() {
    if( this.props.router.params.id !== undefined ) {

      // set the defaults
      let { currentPage, perPage } = this.state;
      let filterDateFrom = moment(this.state.filterDateFrom).format('YYYY-MM-DD');
      let filterDateTo= moment(this.state.filterDateTo).format('YYYY-MM-DD');

      // get the defaults from the URL query if it exists
      const parsed = queryString.parse(this.props.router.location.search)
      if(parsed.page !== null && parsed.page !== undefined) {
        currentPage = parseInt(parsed.page.toString())
      }
      if(parsed.from !== null && parsed.from !== undefined) {
        filterDateFrom = moment(parsed.from.toString()).format('YYYY-MM-DD')
      }
      if(parsed.to !== null && parsed.to !== undefined) {
        filterDateTo = moment(parsed.to.toString()).format('YYYY-MM-DD')
      }
      if(parsed.perPage !== null && parsed.perPage !== undefined) {
        if(parseInt(parsed.perPage.toString()) < 51) {
          perPage = parseInt(parsed.perPage.toString())
        } else {
          this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id)}?page=${currentPage}&perPage=${perPage}&from=${filterDateFrom}&to=${filterDateTo}`)
        }
      } else {
        this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id)}?page=${currentPage}&perPage=${perPage}&from=${filterDateFrom}&to=${filterDateTo}`)
      }

      // update state and load the data
      this.setState({ perPage, currentPage, filterDateFrom: moment(filterDateFrom).toDate(), filterDateTo: moment(filterDateTo).toDate() })

      this.reloadPage({ perPage, currentPage, filterDateFrom, filterDateTo })
    }
  }
  

  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    const { filterDateFrom, filterDateTo } = this.state
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, filterDateFrom, filterDateTo })
    this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id, 10)}?page=0&perPage=${e.target.value}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { perPage, filterDateFrom, filterDateTo } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage, filterDateFrom, filterDateTo })
    this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id, 10)}?page=${prevPage}&perPage=${this.state.perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { perPage, filterDateFrom, filterDateTo } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage, filterDateFrom, filterDateTo })
    this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id, 10)}?page=${nextPage}&perPage=${this.state.perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, filterDateFrom: any, filterDateTo: any }) => {
    const { perPage, currentPage, filterDateFrom, filterDateTo } = options
    this.props.fetchReportsNewVehicleById({ id: parseInt(this.props.router.params.id), limit: perPage, skip: perPage * currentPage, from: moment(filterDateFrom).format('YYYY-MM-DD'), to: moment(filterDateTo).format('YYYY-MM-DD') })
    .then(() => {
      const { vehicle, vehicleReportsNew, vehicleReportsNewCount } = this.props.vehicle
      let noData = false
      if(parseInt(vehicleReportsNewCount) === 0) {
        noData = true
      }
      this.setState({ vehicle, vehicleReportsNew, vehicleReportsNewCount, noData})
    });


  }


  handleDownloadPDF = () => {
    this.props.fetchDatesReportsNewVehicleById({ id: parseInt(this.props.router.params.id), from: moment(this.state.filterDateFrom).format('YYYY-MM-DD'), to: moment(this.state.filterDateTo).format('YYYY-MM-DD') })
    .then(() => {
      const data = this.props.vehicle.vehicleAllReportsNew
      if(data.length > 0) {
        createNewReportPDF(data, this.props.vehicle.vehicle, this.props.auth.currency);
      }
    });
  }


  handleDownloadCSV = () => {
    this.props.fetchDatesReportsNewVehicleById({ id: parseInt(this.props.router.params.id), from: moment(this.state.filterDateFrom).format('YYYY-MM-DD'), to: moment(this.state.filterDateTo).format('YYYY-MM-DD') })
    .then(() => {
      const data = this.props.vehicle.vehicleAllReportsNew
      if(data.length > 0) {
        createNewReportCSV(data, this.props.vehicle.vehicle);
      }
    });
  }


  updateFilterFromDate = (filterDateFrom:any) => {
    this.setState({ filterDateFrom });
    const { perPage, currentPage, filterDateTo } = this.state;
    this.reloadPage({ perPage, currentPage, filterDateFrom, filterDateTo: this.state.filterDateTo });
    this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id)}?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}`)
  }


  updateFilterToDate = (filterDateTo:any) => {
    this.setState({ filterDateTo });
    const { perPage, currentPage, filterDateFrom } = this.state;
    this.reloadPage({ perPage, currentPage, filterDateFrom: this.state.filterDateFrom, filterDateTo });
    this.props.router.navigate(`/vehicles/reports/${parseInt(this.props.router.params.id)}?page=${currentPage}&perPage=${perPage}&from=${moment(filterDateFrom).format('YYYY-MM-DD')}&to=${moment(filterDateTo).format('YYYY-MM-DD')}`)
  }


  render() {

    const { status } = this.props.vehicle;
    const { vehicle, currentPage, perPage, noData, filterDateTo, filterDateFrom, todayDate, vehicleReportsNew, vehicleReportsNewCount } = this.state;
    const { currency } = this.props.auth;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          padding={[]}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id, 10)}
          animate
          button={true}
          buttonTitle='PDF'
          buttonClickable={true}
          buttonHandleOnClick={() => this.handleDownloadPDF()}
          buttonLink=''
          secondButton={true}
          secondButtonTitle='CSV'
          secondButtonClickable={true}
          secondButtonHandleOnClick={() => this.handleDownloadCSV()}
          secondButtonLink=''
        >
          { 
            vehicle.connected !== null ? (
              <>
                <form className="form">
                  <div className="filter">
                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(1123)}</span>
                        <span className="filter_filter-select">
                          <DatePicker
                            selected={filterDateFrom}
                            selectsStart
                            onChange={this.updateFilterFromDate}
                            dateFormat='dd/MM/yyyy'
                            maxDate={filterDateTo}
                            required={true}
                          />
                        </span>
                    </span>
                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(1124)}</span>
                      <span className="filter_filter-select">
                        <DatePicker
                          selected={filterDateTo}
                          selectsStart
                          onChange={this.updateFilterToDate}
                          dateFormat='dd/MM/yyyy'
                          maxDate={todayDate}
                          minDate={filterDateFrom}
                          required={true}
                        />
                      </span>
                    </span>
                  </div>
                </form>

                {
                  vehicle && noData === true ? (
                    <CtaBox padding type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(2184)}
                    </CtaBox>
                  ) : (
                    <div className="table-wrapper">
                    <table className="table">
                      <TableHeader 
                        columns={['', t(2050), t(2052), `${t(2052)} LPG/CNG`, `${t(2052)} PET/DIE`, t(2053), `LPG/CNG ${t(2057)}`, `LPG/CNG ${t(2054)}*`, `${t(2056)} PET/DIE ${t(2054)}*`, `${t(2056)} ${t(2054)} ${t(2059)} LPG/CNG*`, `% ${t(2060)} LPG/CNG*`, `${t(2056)} ${t(2055)}*`, `${t(2058)} ${t(2055)}*`, `CO2 ${t(2055)}*`, `${t(1061)}`]} 
                      />
                        <tbody className="table__body table__body--striped">
                          {vehicleReportsNew && vehicleReportsNew.map((item: {alternative_fuel_type: string, vehicle_id: number, d_stamp_to: string, d_stamp_from: string}, index: number) => {

                            const objectReportSavings = calculateObjectReportSavingsNew(item)
                            const alternative_fuel_units = item.alternative_fuel_type === 'lpg' ? 'l' : 'm3'

                            return (
                              <tr key={index}>
                                <td className="simple">
                                    <Link to={`/vehicles/routes/${item.vehicle_id}/${item.d_stamp_to}`}>
                                      <Tooltip anchorId={`tooltip-vehicle-route${index}`}/>
                                      <span id={`tooltip-vehicle-route${index}`} data-tooltip-content="Routes">
                                        <IconMap task button color="#ffffff" />
                                      </span>
                                    </Link>
                                </td>
                                <td>
                                  { item.d_stamp_from !== null ? item.d_stamp_from.replaceAll("-", "/") : '' }<br/>
                                  { item.d_stamp_to !== null ? item.d_stamp_to.replaceAll("-", "/") : '' }
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.TOTAL_DISTANCE_KM * 100) / 100 } km
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.ALTERNATIVE_DISTANCE_KM * 100) / 100 } km
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.DEFAULT_DISTANCE_KM * 100) / 100 } km
                                </td>
                                <td>
                                  { moment("1900-01-01 00:00:00").add(objectReportSavings.TOTAL_TIME_S, 'seconds').format("HH:mm:ss") }
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * 100) / 100 + ' ' + alternative_fuel_units }
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.ALTERNATIVE_FUEL_COST * 100) / 100 } { currency }
                                </td>
                                <td>
                                  { Math.round(objectReportSavings.DEFAULT_FUEL_COST * 100) / 100 } { currency }
                                </td>
                                <td>
                                  { Math.round(objectReportSavings.DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE * 100) / 100 } { currency }
                                </td>
                                <td>
                                  { Math.round(objectReportSavings.TOTAL_ALTERNATIVE_PERCENT * 100) / 100 } %
                                </td>
                                <td>
                                  { Math.round(objectReportSavings.ESTIMATED_SAVINGS * 100) / 100 } { currency }
                                </td>
                                <td>
                                  { Math.round(objectReportSavings.ESTIMATED_POTENTIAL_SAVINGS * 100) / 100 } { currency }
                                </td>
                                <td>
                                 { Math.round(objectReportSavings.TOTAL_SAVINGS_CO2_KG * 100) / 100 } kg
                                </td>
                                <td>
                                  { objectReportSavings.TOTAL_DATA_KB ? `${ Math.round((objectReportSavings.TOTAL_DATA_KB/1024) * 100) / 100 } MB` : `0 MB` }
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <TableFooter 
                          button={false}
                          colSpan={7}
                          currentPage={ currentPage }
                          perPage={ perPage }
                          itemsCount={ vehicleReportsNewCount }
                          prevPage={() => this.prevPage(currentPage - 1)}
                          nextPage={() => this.nextPage(currentPage + 1)}
                          updatePagination={this.updatePagination}
                        />
                    </table>
                    <EstimationNote />
                  </div>
                  )
                }
              </>
            ) : (<></>)
          }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, auth }: RootState) => ({ vehicle, auth }),
  { fetchReportsNewVehicleById, fetchDatesReportsNewVehicleById, fetchFuelPrices }
)(VehiclesReport)))
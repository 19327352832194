import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertRpm, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormRpmProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertRpm: (options: {id: number, value: string, mt: string, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormRpmState {
  formSubmitted: boolean,
  rpm_limit: string,
  alert_min_time: string,
  alert_max_frequency: string
}


export class AlertFormRpm extends React.Component<AlertFormRpmProps, AlertFormRpmState> {

  state = {
    formSubmitted: false,
    rpm_limit: '7500',
    alert_min_time: '5',
    alert_max_frequency: '300'
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {
      const { alerts_rpm_mp, alerts_rpm_mt, alerts_rpm_v } = this.props.alert.alertsSettings

        this.setState({
          rpm_limit: alerts_rpm_v !== null ? alerts_rpm_v : '7500',
          alert_min_time: alerts_rpm_mt !== null ? alerts_rpm_mt : '5',
          alert_max_frequency: alerts_rpm_mp !== null ? alerts_rpm_mp : '300'
        })

    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertRpm({ id: this.props.modalId, value: this.state.rpm_limit, mt: this.state.alert_min_time, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }


  render() {

    const { formSubmitted, rpm_limit, alert_min_time, alert_max_frequency } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2081)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="rpm_limit">{t(2099)} [Rpm]</label>
              <input 
                type="text" 
                name="rpm_limit"
                id="rpm_limit" 
                value={rpm_limit}
                onChange={e => this.setState({ rpm_limit: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2100)}
              </span>
            </div>


            <div className="form-group">
              <label htmlFor="alert_min_time">{t(2091)}</label>
              <input 
                type="text" 
                name="alert_min_time"
                id="alert_min_time" 
                value={alert_min_time}
                onChange={e => this.setState({ alert_min_time: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2092)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_max_frequency">{t(2093)}</label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>

            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertRpm, fetchAlertsSettingsById }
)(AlertFormRpm)
interface LegendProps {
  labels: {color: string, title: string}[]
}


const Legend: React.FC<LegendProps> = ({ labels }) => {
  return (
    <section className="legend">
      {
        labels && labels.map((label: {color: string, title: string}, index: number) => (
          <span className="legend__label" key={index}>
            <span className="label__id" style={{backgroundColor: `${label.color}`}}></span>
            <span className="label__title">{label.title}</span>
          </span>
        ))
      }
    </section>
  )
}


export default Legend
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAlertById, markAlertAsRedById, fetchAlertsSettingsByIdAndTs } from '../../actions/alert'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import AlertBoxSpeed from '../partials/alerts/AlertBoxSpeed'
import AlertBoxGeoFence from '../partials/alerts/AlertBoxGeoFence'
import AlertBoxEngineTemperature from '../partials/alerts/AlertBoxEngineTemperature'
import AlertBoxRpm from '../partials/alerts/AlertBoxRpm'
import AlertBoxGasolineUse from '../partials/alerts/AlertBoxGasolineUse'
import AlertBoxGasMalfunction from '../partials/alerts/AlertBoxGasMalfunction'
import AlertBoxGasMaintenance from '../partials/alerts/AlertBoxGasMaintenance'
import AlertBoxGasPercentage from '../partials/alerts/AlertBoxGasPercentage'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface AlertProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  alert: any,
  vehicle: any,
  fetchAlertById: (id: number) => Promise<any>,
  markAlertAsRedById: (id: number) => Promise<any>,
  fetchAlertsSettingsByIdAndTs: (options: { id: number, tss: number }) => Promise<any>,
}


interface AlertState {
  alert: { custom_id: string, type: string },
  alertsSettings: any,
  timezone: string,
}


export class Alert extends React.Component<AlertProps, AlertState> {

  state = {
    alert: { custom_id: '', type: '0' },
    alertsSettings: [],
    timezone: TIMEZONE
  }


  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.fetchAlert()
  }


  fetchAlert = () => {
    this.props.fetchAlertById(parseInt(this.props.router.params.id))
    .then(() => {
      const { alert } = this.props.alert
      if(alert !== undefined) {
        if(parseInt(alert.type) === 9) {
          this.setState({ alert })
        } else {
          this.props.fetchAlertsSettingsByIdAndTs({ id: alert.vehicle_id, tss: alert.tss })
          .then(() => {
            const { alertsSettings } = this.props.alert
            this.setState({ alert, alertsSettings })
          })
        }
        if(alert.new === true) {
          this.props.markAlertAsRedById(parseInt(this.props.router.params.id))
        }
      }
    });
  }


  render() {

    const { status } = this.props.alert;
    const { alert, alertsSettings, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          time={false}
          title={`${t(2310)} ${alert.custom_id}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          animate={true}
        >
          { parseInt(alert.type) === 1 ? <AlertBoxSpeed alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 2 ? <AlertBoxGeoFence alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 3 ? <AlertBoxEngineTemperature alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 4 ? <AlertBoxRpm alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 5 ?  <AlertBoxGasolineUse alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 6 ? <AlertBoxGasMalfunction alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 7 ? <AlertBoxGasMaintenance alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }

          { parseInt(alert.type) === 9 ? <AlertBoxGasPercentage alert={alert} settings={alertsSettings} timezone={timezone} /> : <></> }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, alert, vehicle }: RootState) => ({ auth, alert, vehicle }),
  { fetchAlertById, markAlertAsRedById, fetchAlertsSettingsByIdAndTs }
)(Alert)))
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'


interface AuthorizationProps {
  auth: { authorized: any },
  requiresAuth: string,
  children: JSX.Element,
}


export class Authorization extends React.Component<AuthorizationProps> {

  render() {

    let { requiresAuth, auth } = this.props

    //console.log('[DEBUG: reports]', requiresAuth, auth.authorized[requiresAuth])
    
    if(requiresAuth === undefined) {
      console.error('Authorization component: Please set requirements for authorization')
      return null
    }

    if(auth.authorized[requiresAuth] === undefined) {
      console.error('Authorizadtion component: There is no such authorisation option', requiresAuth)
      return null
    }
    
    if (auth.authorized[requiresAuth] === true){
      return (
        <>
          {this.props.children}
        </>
      );
    } else {
      return true
    }
    
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  {}
)(Authorization);
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { fetchRefuelId } from '../../actions/refuel'
import { fetchAllStations } from '../../actions/station'
import { fetchAllCompetitors } from '../../actions/competitor'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import markerStation from'../../img/marker-station.png'
import markerCompetitor from'../../img/marker-competitor.png'
import markerVehicleRefuelSuccess from'../../img/marker-vehicle-refuel-success.png'
import markerVehicleRefuelAlert from'../../img/marker-vehicle-refuel-alert.png'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, TIMEZONE } from '../../config'
import { IconLockOpen, IconLockClosed } from '../partials/Icons'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface VehicleRefueledLocationProps {
  router: { params: { id: string } },
  auth: any,
  refuel: any, 
  station: any, 
  competitor: any,
  checkAuth: () => Promise<any>,
  fetchAllStations: (options: {limit: number, skip: number}) => Promise<any>,
  fetchAllCompetitors: (options: {limit: number, skip: number}) => Promise<any>,
  fetchRefuelId: (id: number) => Promise<any>,
}


interface VehicleRefueledLocationState {
  id: string,
  custom_id: string,
  id_mod: string,
  unlocked: number,
  refuel_unlocked: number,
  refuel_ts: number,
  mapLat: number,
  mapLng: number,
  markerLat: number,
  markerLng: number,
  mapZoom: number,
  stations: any,
  timezone: string,
}


export class VehicleRefueledLocation extends React.Component<VehicleRefueledLocationProps, VehicleRefueledLocationState> {

  state = {
    id: '',
    custom_id: '',
    id_mod: '',
    unlocked: 0,
    refuel_unlocked: 0,
    refuel_ts: 0,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    stations: [],
    timezone: TIMEZONE
  }

  componentDidMount() {

    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    this.props.checkAuth()
    .then(() => {
      if( this.props.router.params.id !== undefined ) {
        this.props.fetchAllStations({ limit: 1000, skip: 0 });
        this.props.fetchAllCompetitors({ limit: 1000, skip: 0 });
        this.props.fetchRefuelId(parseInt(this.props.router.params.id))
        .then(() => {
          if(this.props.refuel.refuel !== undefined) {
            const { id, custom_id, id_mod, refuel_ts, loc_lt, loc_ln, unlocked, refuel_unlocked } = this.props.refuel.refuel;
            this.setState({
              id,
              id_mod,
              custom_id,
              refuel_ts,
              refuel_unlocked,
              unlocked,
              mapLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
              mapLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
              markerLat: loc_lt !== undefined && loc_lt !== null ? parseFloat(loc_lt) : DEFAULT_LAT,
              markerLng: loc_ln !== undefined && loc_ln !== null ? parseFloat(loc_ln) : DEFAULT_LNG,
              mapZoom: this.props.auth.zoom ? parseInt(this.props.auth.zoom) : DEFAULT_ZOOM,
            });
          }
        });
      }
    });
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  drawStationCircle = (options: { lat: number, lng: number, range: number }) => {
    const {lat, lng, range} = options
    return <Circle
      // required
      center={{
        lat, lng
      }}
      // required
      options={{
        strokeColor: '#248E63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#248E63',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: range,
        zIndex: 1
      }}
    />
  }


  drawCompetitorCircle = (options: { lat: number, lng: number, range: number }) => {
    const {lat, lng, range} = options
    return <Circle
      // required
      center={{
        lat, lng
      }}
      // required
      options={{
        strokeColor: '#E34B5F',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#E34B5F',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: range,
        zIndex: 1
      }}
    />
  }


  render() {

    const { stations } = this.props.station;
    const { competitors } = this.props.competitor;
    const { status } = this.props.refuel;
    const { custom_id, id_mod, unlocked, refuel_unlocked, refuel_ts, mapLat, mapLng, markerLat, markerLng, mapZoom, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={true}
          title={`${custom_id} - IMEI ${id_mod}`} 
          buttonBack={true} 
          padding={['large']}
          time={false}
          success={refuel_unlocked === 1 && true}
          danger={refuel_unlocked === 0 && true}
          animate
        >

          <form className="form">
            {
              unlocked !== 2 && (
                <div className="form-group">
                  <label htmlFor="date">
                    {t(2349)}
                  </label>
                    { unlocked === 1 && <IconLockOpen success color="#ffffff"/> } 
                    { unlocked === 0 && <IconLockClosed error color="#ffffff" />}
                </div>
              )
            }

            <div className="form-group">
              <label htmlFor="date">
                {t(2350)}
              </label>
              <input 
                type="text" 
                name="date"
                id="date" 
                value={moment(refuel_ts).tz(timezone).format('DD/MM/YYYY')}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="latitude">
                {t(2351)}
              </label>
              <input 
                type="text" 
                name="latitude"
                id="latitude" 
                value={markerLat}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="longitude">
                {t(2352)}
              </label>
              <input 
                type="text" 
                name="longitude"
                id="longitude" 
                value={markerLng}
                disabled
              />
            </div>

            <div className="form-group">
            <label htmlFor="map">
              {t(2353)}
            </label>
              <GoogleMap
                center={{ lat: mapLat, lng: mapLng }}
                zoom={ mapZoom }
              >
                { refuel_unlocked === 1 && 
                  <Marker
                    position={{
                      lat: markerLat,
                      lng: markerLng
                    }}
                    icon={
                      // @ts-expect-error
                      new window.google.maps.MarkerImage(
                        markerVehicleRefuelSuccess,
                        null, /* size is determined at runtime */
                        null, /* origin is 0,0 */
                        null, /* anchor is bottom center of the scaled image */
                        new window.google.maps.Size(48, 48)
                      )
                    }
                  />
                }
                { refuel_unlocked === -1 && 
                  <Marker
                    position={{
                      lat: markerLat,
                      lng: markerLng
                    }}
                    icon={
                      // @ts-expect-error
                      new window.google.maps.MarkerImage(
                        markerVehicleRefuelAlert,
                        null, /* size is determined at runtime */
                        null, /* origin is 0,0 */
                        null, /* anchor is bottom center of the scaled image */
                        new window.google.maps.Size(48, 48)
                      )
                    }
                  />
                }
                { 
                  stations !== undefined && stations.map((station: {station_id: string, lat: number, lng: number, range: number}) => {
                    return <Marker
                      key={station.station_id}
                      position={{
                        lat: station.lat,
                        lng: station.lng
                      }}
                      icon={
                        // @ts-expect-error
                        new window.google.maps.MarkerImage(
                          markerStation,
                          null, /* size is determined at runtime */
                          null, /* origin is 0,0 */
                          null, /* anchor is bottom center of the scaled image */
                          new window.google.maps.Size(48, 48)
                        )
                      }
                    >
                      { this.drawStationCircle({lat:station.lat, lng:station.lng, range:station.range}) }
                    </Marker>         

                  })
                }
                { 
                  competitors !== undefined && competitors.map((competitor: {station_id: string, lat: number, lng: number, range: number}) => {
                    return <Marker
                      key={competitor.station_id}
                      position={{
                        lat: competitor.lat,
                        lng: competitor.lng
                      }}
                      icon={
                        // @ts-expect-error
                        new window.google.maps.MarkerImage(
                          markerCompetitor,
                          null, /* size is determined at runtime */
                          null, /* origin is 0,0 */
                          null, /* anchor is bottom center of the scaled image */
                          new window.google.maps.Size(48, 48)
                        )
                      }
                    >
                      { this.drawCompetitorCircle({lat:competitor.lat, lng:competitor.lng, range:competitor.range}) }
                    </Marker>         

                  })
                }
                <div className="map-constraints-container" />
              </GoogleMap>
              <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>
            </div>
            <Link className="btn btn--primary" to={`/stations/authorized/add?lat=${markerLat}&lng=${markerLng}`}>Set this location as authorized station</Link>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, refuel, station, competitor }: RootState) => ({ auth, refuel, station, competitor }),
  { fetchRefuelId, fetchAllStations, fetchAllCompetitors, checkAuth }
)(VehicleRefueledLocation)))
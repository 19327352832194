import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchVehicleById, lockVehicleById, unlockVehicleById, fetchLockReasons } from '../../actions/vehicle'
import { fetchLockUnlockRequestsHistory } from '../../actions/module'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { IconVehicleSettings, IconLockOpen, IconLockClosed, IconLinkOff, IconVisibilityOff, IconNotifyClose, IconNotifyError, IconNotifySuccess  } from '../partials/Icons'
import moment from 'moment-timezone'
import classnames from 'classnames'


interface VehicleLockUnlockProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  vehicle: any,
  moduleState: any,
  fetchLockReasons: () => Promise<void>,
  fetchVehicleById: (id: number) => Promise<void>,
  fetchLockUnlockRequestsHistory: (id: number) => Promise<void>,
  unlockVehicleById: (options: { id: number, reason: string, description: string }) => Promise<void>,
  lockVehicleById: (options: { id: number, reason: string, description: string }) => Promise<void>,
}


interface VehicleLockUnlockState {
  vehicle: { vehicle_id: number, custom_id: string, module_imei: string, paired: number, unlocked: number, connected: number, ignition: number, controller_connected: number, compatible: number }, 
  interval: boolean,
  formSubmitted: boolean,
  lockUnlockDisabled: boolean,
  requestSent: boolean,
  circleUnprocessed: boolean,
  circleProcessing: boolean,
  circleProcessed: boolean,
  lockunlock_requests_history: any,
  lockReason: string,
  unlockReason: string,
  lockReasons: any,
  unlockReasons: any,
  lockDescription: string,
  unlockDescription: string,
  intervalId: any,
  lastConnected: number,
}


export class VehicleLockUnlock extends React.Component<VehicleLockUnlockProps, VehicleLockUnlockState> {


  state = {
    vehicle: { vehicle_id: 0, custom_id: '', module_imei: '', paired: -1, unlocked: -1, connected: -1, ignition: -1, controller_connected: -1, compatible: -1 }, 
    interval: false,
    formSubmitted: false,
    lockUnlockDisabled: false,
    requestSent: false,
    circleUnprocessed: true,
    circleProcessing: false,
    circleProcessed: false,
    lockunlock_requests_history: [],
    lockReason: '1',
    unlockReason: '1',
    lockReasons: [],
    unlockReasons: [],
    lockDescription: '',
    unlockDescription: '',
    intervalId: 0,
    lastConnected: 0,
  }


  componentDidMount() {
    this.fetchVehicleLockUnlockState();
    this.fetchLockUnlockRequestsHistory();
    this.props.fetchLockReasons()
    .then(() => {
      const lockReasons = this.props.vehicle.reasons.filter((item: {reason_type: boolean}) => item.reason_type === true);
      const lockReason = lockReasons.length > 0 ? lockReasons[0].reason_number : 0;
      this.setState({
        lockReasons,
        lockReason
      });
    });


    this.props.fetchLockReasons()
    .then(() => {
      const unlockReasons = this.props.vehicle.reasons.filter((item: {reason_type: boolean}) => item.reason_type === true);
      const unlockReason = unlockReasons.length > 0 ? unlockReasons[0].reason_number : 0;
      this.setState({
        unlockReasons,
        unlockReason
      });
    });

    const intervalId = setInterval(this.timer, 10000);
    this.setState({ intervalId: intervalId });
  }


  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }


  timer = () => {
    this.fetchVehicleLockUnlockState();
    this.fetchLockUnlockRequestsHistory();
    this.setState({ interval: true });
  }


  fetchVehicleLockUnlockState = () => {
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchVehicleById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          const { vehicle } = this.props.vehicle;
          let lockUnlockDisabled = false
          let lastConnected = 0
          if(vehicle.controller_connected === 0) {
            vehicle.compatible = -1
          }
          // if last timestamp is less then 10m then we count this as still connected
          if(moment(vehicle.connected).format('X') > moment().subtract(10, 'minutes').format('X') && vehicle.ignition === 1) {
            lastConnected = 1
          }
          // disable option to pair the module if any of those are true
          if( vehicle.compatible === 1 || vehicle.controller_connected === 0 || vehicle.paired === 0) {
            lockUnlockDisabled = true;
          }
          this.setState({ vehicle, lockUnlockDisabled, lastConnected })
        }
      })
    }
  }


  fetchLockUnlockRequestsHistory = () => {
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchLockUnlockRequestsHistory(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.moduleState.status === fetchStates.success) {
          const { lockunlock_requests_history } = this.props.moduleState;
          this.setState({ lockunlock_requests_history })
        }
      })
    }
  }


  handleSubmitLock = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { vehicle, lockReason, lockDescription } = this.state
    this.props.lockVehicleById({ id: vehicle.vehicle_id, reason: lockReason, description: lockDescription })
    .then(() => {
      this.fetchVehicleLockUnlockState();
      this.fetchLockUnlockRequestsHistory();
      this.setState({ formSubmitted: false, lockDescription: '', unlockDescription: '', unlockReason: '0', lockReason: '0' });
    })
  }


  handleSubmitUnlock = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { vehicle, unlockReason, unlockDescription } = this.state
    this.props.unlockVehicleById({ id: vehicle.vehicle_id, reason: unlockReason, description: unlockDescription })
    .then(() => {
      this.fetchVehicleLockUnlockState();
      this.fetchLockUnlockRequestsHistory();
      this.setState({ formSubmitted: false, lockDescription: '', unlockDescription: '', unlockReason: '0', lockReason: '0' });
    })
  }


  render() {

    const { vehicle, formSubmitted, lockUnlockDisabled, lastConnected, lockunlock_requests_history, lockReasons, lockReason, lockDescription, unlockReasons, unlockReason, unlockDescription } = this.state
    const { status } = this.props.vehicle;

    return (
      <TemplatePage>

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
          <div className="pairing">

          <div className="pairing-state">
            <div className="pairing-instructions">
              <div className="pairing-state-title">{t(2071)}:</div>
              <div className="pairing-state-phrase">
                { vehicle.paired === -1 && 'n/a'}
                { vehicle.paired === 0 && <span className="text-error">{t(2452)}</span> }
                { vehicle.paired === 2 && t(2452) }
                { vehicle.paired === 1 && vehicle.unlocked === 1 && t(2471) }
                { vehicle.paired === 1 && vehicle.unlocked === 0 && t(2064) }
              </div>
            </div>
          </div>

          <div className="pairing-circle-wrapper">  
            <div className={classnames('pairing-circle', {          
                'pairing-circle__completed': status === 'success',
                'pairing-circle__processing': status === 'fetching',
                'pairing-circle__unprocessed': status === 'error'
              })}
            >
              <IconVehicleSettings size={200} />
              <span className={classnames('pairing-state-icon pairing-state-icon--paired', {          
                  'pairing-state-icon--processed': status === 'success',
                  'pairing-state-icon--processing': status === 'fetching',
                  'pairing-state-icon--unprocessed': status === 'error',
                  'pairing-state-icon--success': vehicle.paired === 1 && vehicle.unlocked === 1 && status === 'success',
                  'pairing-state-icon--alert': vehicle.paired === 1 && vehicle.unlocked === 0 && status === 'success',
                  'pairing-state-icon--info': ( vehicle.paired === 2  ) && status === 'success',
                  'pairing-state-icon--error': (vehicle.paired === 0) && status === 'success'
                })}
              >
                { vehicle.paired === -1 && <IconVisibilityOff size={48} color="#dfdfdf" /> }
                { vehicle.paired === 0 && <IconLinkOff size={48} color="#ffffff" /> }
                { vehicle.paired === 2 && <IconLinkOff size={48} color="#ffffff" /> }
                { vehicle.paired === 1 && vehicle.unlocked === 1 && <IconLockOpen size={48} color="#ffffff" /> }
                { vehicle.paired === 1 && vehicle.unlocked === 0 && <IconLockClosed size={48} color="#ffffff"/> }
              </span>

              <div
                className={classnames('pairing-state-time', {          
                  'pairing-state-time--processed': status === 'success',
                  'pairing-state-time--processing': status === 'fetching',
                  'pairing-state-time--unprocessed': status === 'error',
                })}
              >
              
                { 
                  lastConnected === 0 ? (
                    <span className="text-error">{t(2461)}:<br/></span>
                  ) : (
                    <span>{t(2462)}:<br/></span>
                  )
                }
                {vehicle.connected !== 0 ? moment(vehicle.connected).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}
              </div>
            </div>

            <div className={classnames('driving-state-icon', {
              'state-icon--processed': status === 'success',
              'state-icon--processing': status === 'fetching',
              'state-icon--unprocessed': status === 'error'
            })}>
              { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
              { status === 'success' && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2465)}</span>}
              { status === 'success' && vehicle.ignition === 0 && <span className="icon-state"><IconNotifyError color="#E34B5F"/>{t(2466)}</span>}
            </div>

            <div className={classnames('connected-state-icon', {
              'state-icon--processed': status === 'success' && vehicle.ignition === 1,
              'state-icon--processing': status === 'fetching',
              'state-icon--unprocessed': status === 'error'
            })}>
              { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
              { vehicle.ignition === 0 && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
              { status === 'success' && vehicle.controller_connected === 1 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2467)}</span> }
              { status === 'success' && vehicle.controller_connected === 0 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(2468)}</span> }
            </div>

            <div className={classnames('compatible-state-icon', {
              'state-icon--processed': status === 'success' && vehicle.ignition === 1 && vehicle.compatible !== -1,
              'state-icon--processing': status === 'fetching',
              'state-icon--unprocessed': status === 'error' || vehicle.compatible === -1
            })}>
              { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
              { (vehicle.ignition === 0 || vehicle.compatible === -1) && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
              { status === 'success' && vehicle.compatible === 0 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(1070)}</span>}
              { status === 'success' && vehicle.compatible === 1 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(1071)}</span>}
            </div>

          </div>






              <div className="pairing-buttons pairing-buttons--two">

                <form className="form" onSubmit={this.handleSubmitUnlock}>
                  <div 
                    className={classnames('pairing-button', {
                      'pairing-button--disabled': lockUnlockDisabled === true
                    })}
                  >
                    <div className="pairing-button__icon pairing-button__icon--success">
                      <IconLockOpen size={64} color={lockUnlockDisabled ? '#d4d4d4' : '#2A2C44'} />
                    </div>

                    <div className="form-group">
                      <select 
                        name="reason" 
                        onChange={e => this.setState({ unlockReason: e.target.value })}
                        value={unlockReason}
                        disabled={lockUnlockDisabled ? true : false}
                      >
                        {
                          unlockReasons.map((item: {reason_number: number, reason_description: string}) => {
                            return (
                              <option key={item.reason_number} value={item.reason_number}>{item.reason_description}</option>
                          )})
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <input 
                        type="text" 
                        name="description"
                        id="description" 
                        placeholder={t(2133)}
                        value={unlockDescription}
                        onChange={e => this.setState({ unlockDescription: e.target.value })}
                        disabled={lockUnlockDisabled ? true : false}
                      />
                    </div>

                    <div className="form-group">
                      <input 
                        type="submit" 
                        value={'UNLOCK MODULE'}
                        className="btn btn--primary card__footer--btn-left"
                        disabled={ (formSubmitted && status === 'fetching') || lockUnlockDisabled ? true : false }
                      />
                    </div>
                  </div>
                </form>

                <form className="form" onSubmit={this.handleSubmitLock}>
                  <div 
                    className={classnames('pairing-button', {
                      'pairing-button--disabled': lockUnlockDisabled === true
                    })}
                  >
                    <div className="pairing-button__icon pairing-button__icon--alert">
                      <IconLockClosed size={64} color={lockUnlockDisabled ? '#d4d4d4' : '#2A2C44'}/>
                    </div>
                    <div className="form-group">
                      <select 
                        name="lockReason" 
                        id="lockReason" 
                        value={lockReason}
                        onChange={e => this.setState({ lockReason: e.target.value })}
                        disabled={lockUnlockDisabled ? true : false}
                      >
                        {
                          lockReasons.map((item: {reason_number: number, reason_description: string}) => {
                            return (
                              <option key={item.reason_number} value={item.reason_number}>{item.reason_description}</option>
                          )})
                        }
                      </select>
                    </div>
                    <div className="form-group">
                      <input 
                        type="text" 
                        name="lockDescription"
                        id="lockDescription" 
                        placeholder={t(2133)}
                        value={lockDescription}
                        onChange={e => this.setState({ lockDescription: e.target.value })}
                        disabled={lockUnlockDisabled ? true : false}
                      />
                    </div>
                    <div className="form-group">
                      <input 
                        type="submit" 
                        value={'LOCK MODULE'}
                        className="btn btn--primary card__footer--btn-left"
                        disabled={ (formSubmitted && status === 'fetching') || lockUnlockDisabled ? true : false }
                      />
                    </div>
                  </div>
                </form>
              </div>
          </div>



            {
              lockunlock_requests_history && lockunlock_requests_history.length > 0 ? (
                <div className="pairing-history">
                  <h3>{t(2444)}</h3>
                  <div className="table-wrapper">
                    <table className="table">
                      <thead className="table__header">
                        <tr>
                          <th>{t(1173)}</th>
                          <th>{t(2445)}</th>
                          <th>{t(2458)}</th>
                          <th>{t(2459)}</th>
                        </tr>
                      </thead>
                      <tbody className="table__body table__body--striped">
                        {
                          lockunlock_requests_history.map((item: {set_ts: number, unlock_req: string}) => {
                            return <tr key={item.set_ts}>
                                      <td>
                                        {moment(item.set_ts * 1000).format('YYYY/MM/DD HH:mm:ss')}
                                      </td>
                                      <td>
                                        {item.unlock_req === '2145920461' && `${t(26)} (${item.unlock_req})`}
                                        {item.unlock_req < moment().format('x') && `${t(27)} (${item.unlock_req})`}
                                      </td>
                                      {
                                        /*
                                        TODO: once Andrzej adds info about this being processed
                                        <td>
                                        { item.unlock_ts !== null && item.unlock_by_mod === 1 && `${t(2456)} (1)` }

                                        {
                                          item.unlock_ts !== null && (item.unlock_by_mod === 0 || item.unlock_by_mod === null) && `${t(2457)} (0)`
                                        }

                                        { item.unlock_ts === null && t(2448)}
                                      </td>
                                      <td>
                                        {item.unlock_ts !== null ? moment(item.unlock_ts).format('YYYY/MM/DD HH:mm:ss') : t(2448)}
                                      </td>
                                        */
                                      }
                                      <td>n/a - will be added later</td>
                                      <td>n/a - will be added later</td>
                                    </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : ( <></> )
            }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, moduleState }: RootState) => ({ vehicle, moduleState }),
  { fetchVehicleById, lockVehicleById, unlockVehicleById, fetchLockUnlockRequestsHistory, fetchLockReasons }
)(VehicleLockUnlock)))
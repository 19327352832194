import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchCheckpointById, deleteCheckpointById } from '../../actions/checkpoint'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { error } from '../../helpers/notifications'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface CheckpointsDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  checkpoint: any,
  fetchCheckpointById: (id: number) => Promise<any>,
  deleteCheckpointById: (id: number) => Promise<any>, 
}


interface CheckpointsDeleteState {
  checkpoint: any,
  custom_id: string,
  formSubmitted: boolean,
}


export class CheckpointsDelete extends React.Component<CheckpointsDeleteProps, CheckpointsDeleteState> {

  state = {
    checkpoint: { custom_id: '' },
    custom_id: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchCheckpointById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        this.setState({ checkpoint: this.props.checkpoint.checkpoint })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    if(this.state.custom_id === '') {
      this.props.checkpoint.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2504)
    }
    if(this.state.custom_id !== this.props.checkpoint.checkpoint.custom_id) {
      this.props.checkpoint.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2505)
    }
    if(this.state.custom_id === this.props.checkpoint.checkpoint.custom_id) {
      this.props.deleteCheckpointById(this.props.checkpoint.checkpoint.checkpoint_id)
      .then(() => {
        if(this.props.checkpoint.status === fetchStates.success) {
          this.props.router.navigate('/checkpoints')
        }
      })
      this.setState({ formSubmitted: false })
    }
  }


  render() {

    const { status, fields } = this.props.checkpoint;
    const { checkpoint, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(1108)} ${checkpoint.custom_id}`}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(1106)} <strong>{checkpoint.custom_id}</strong>. {t(2026)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(1107)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={this.state.custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(1108)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint }: RootState) => ({ checkpoint }),
  { fetchCheckpointById, deleteCheckpointById }
)(CheckpointsDelete)))
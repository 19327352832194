const AUTH = {
  FETCH: 'AUTH_FETCH',
  FETCH_ERROR: 'AUTH_FETCH_ERROR',
  FETCH_LOGIN_SUCCESS: 'AUTH_FETCH_LOGIN_SUCCESS',
  FETCH_AUTHENTICATED_SUCCESS: 'AUTH_FETCH_AUTHENTICATED_SUCCESS',
  FETCH_LOGOUT_SUCCESS: 'AUTH_FETCH_LOGOUT_SUCCESS',
  FETCH_LOGOUTALL_SUCCESS: 'AUTH_FETCH_LOGOUTALL_SUCCESS',
  FETCH_FORGOT_SUCCESS: 'AUTH_FETCH_FORGOT_SUCCESS',
  FETCH_CHECK_SUCCESS: 'AUTH_FETCH_CHECK_SUCCESS',
  FETCH_RESET_SUCCESS: 'AUTH_FETCH_RESET_SUCCESS',
  RESET: 'AUTH_RESET'
}


const STATION = {
  FETCH: 'STATION_FETCH',
  FETCH_ERROR: 'STATION_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'STATION_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'STATION_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'STATION_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'STATION_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'STATION_FETCH_UPDATE_SUCCESS',
  PUSH_MESSAGE: 'STATION_PUSH_MESSAGE',
  RESET: 'STATION_RESET'
}


const COMPETITOR = {
  FETCH: 'COMPETITOR_FETCH',
  FETCH_ERROR: 'COMPETITOR_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'COMPETITOR_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'COMPETITOR_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'COMPETITOR_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'COMPETITOR_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'COMPETITOR_FETCH_UPDATE_SUCCESS',
  PUSH_MESSAGE: 'COMPETITOR_PUSH_MESSAGE',
  RESET: 'COMPETITOR_RESET'
}


const CHECKPOINT = {
  FETCH: 'CHECKPOINT_FETCH',
  FETCH_ERROR: 'CHECKPOINT_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'CHECKPOINT_FETCH_ALL_SUCCESS',
  FETCH_ALL_VISITS_SUCCESS: 'CHECKPOINT_FETCH_ALL_VISITS_SUCCESS',
  FETCH_ALL_VISITS_VEHICLE_SUCCESS: 'CHECKPOINT_FETCH_ALL_VISITS_VEHICLE_SUCCESS',
  FETCH_CHECKPOINT_VISIT_SUCCESS: 'CHECKPOINT_FETCH_CHECKPOINT_VISIT_SUCCESS',
  FETCH_ID_SUCCESS: 'CHECKPOINT_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'CHECKPOINT_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'CHECKPOINT_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'CHECKPOINT_FETCH_UPDATE_SUCCESS',
  PUSH_MESSAGE: 'CHECKPOINT_PUSH_MESSAGE',
  RESET: 'CHECKPOINT_RESET'
}


const VEHICLE = {
  FETCH: 'VEHICLE_FETCH',
  FETCH_ERROR: 'VEHICLE_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'VEHICLE_FETCH_ALL_SUCCESS',
  FETCH_BILLING_SUCCESS: 'VEHICLE_FETCH_BILLING_SUCCESS',
  FETCH_LOCKED_SUCCESS: 'VEHICLE_FETCH_LOCKED_SUCCESS',
  FETCH_ID_SUCCESS: 'VEHICLE_FETCH_ID_SUCCESS',
  FETCH_HISTORY_ID_SUCCESS: 'VEHICLE_FETCH_HISTORY_ID_SUCCESS',
  FETCH_POSITION_ID_SUCCESS: 'VEHICLE_FETCH_POSITION_ID_SUCCESS',
  FETCH_ROUTES_ID_SUCCESS: 'VEHICLE_FETCH_ROUTES_ID_SUCCESS',
  FETCH_MODULE_ID_SUCCESS: 'VEHICLE_FETCH_MODULE_ID_SUCCESS',
  FETCH_REPORTS_ID_SUCCESS: 'VEHICLE_FETCH_REPORTS_ID_SUCCESS',
  FETCH_ALL_REPORTS_ID_SUCCESS: 'VEHICLE_FETCH_ALL_REPORTS_ID_SUCCESS',
  FETCH_REPORTS_NEW_ID_SUCCESS: 'VEHICLE_FETCH_REPORTS_NEW_ID_SUCCESS',
  FETCH_DATES_REPORTS_NEW_ID_SUCCESS: 'VEHICLE_FETCH_DATES_REPORTS_NEW_ID_SUCCESS',
  FETCH_VISITS_ID_SUCCESS: 'VEHICLE_FETCH_VISITS_ID_SUCCESS',
  FETCH_VISIT_ID_SUCCESS: 'VEHICLE_FETCH_VISIT_ID_SUCCESS',
  FETCH_REFUELED_ID_SUCCESS: 'VEHICLE_FETCH_REFUELED_ID_SUCCESS',
  FETCH_SAVINGS_ID_SUCCESS: 'VEHICLE_FETCH_SAVINGS_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'VEHICLE_FETCH_DELETE_SUCCESS',
  FETCH_REFUEL_COUNT_ID_SUCCESS: 'VEHICLE_FETCH_REFUEL_COUNT_ID_SUCCESS',
  FETCH_ADD_SUCCESS: 'VEHICLE_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'VEHICLE_FETCH_UPDATE_SUCCESS',
  FETCH_UNLOCK_SUCCESS: 'VEHICLE_FETCH_UNLOCK_SUCCESS',
  FETCH_LOCK_SUCCESS: 'VEHICLE_FETCH_LOCK_SUCCESS',
  FETCH_SEARCH_SUCCESS: 'VEHICLE_FETCH_SEARCH_SUCCESS',
  FETCH_BILLING_SEARCH_SUCCESS: 'VEHICLE_FETCH_BILLING_SEARCH_SUCCESS',
  FETCH_LOCKED_SEARCH_SUCCESS: 'VEHICLE_FETCH_LOCKED_SEARCH_SUCCESS',
  FETCH_REASONS_SUCCESS: 'VEHICLE_FETCH_REASONS_SUCCESS',
  FETCH_FUEL_USAGE_SUCCESS: 'VEHICLE_FETCH_FUEL_USAGE_SUCCESS',
  FETCH_OVERVIEW_SUCCESS: 'VEHICLE_FETCH_OVERVIEW_SUCCESS',
  FETCH_ALERTS_ID_SUCCESS: 'VEHICLE_FETCH_ALERTS_ID_SUCCESS',
  FETCH_VEHICLES_IGL_SUCCESS: 'VEHICLE_FETCH_VEHICLES_IGL_SUCCESS',
  FETCH_ROAMING_ID_SUCCESS: 'VEHICLE_FETCH_ROAMING_ID_SUCCESS',
  FETCH_DATA_FREQUENCY_ID_SUCCESS: 'VEHICLE_FETCH_DATA_FREQUENCY_ID_SUCCESS',
  FETCH_ARRAY_MAP_SUCCESS: 'VEHICLE_FETCH_ARRAY_MAP_SUCCESS',
  FETCH_NO_COMMUNICATION_SUCCESS: 'VEHICLE_FETCH_NO_COMMUNICATION_SUCCESS',
  FETCH_REPORT_BY_MONTH_SUCCESS: 'VEHICLE_FETCH_REPORT_BY_MONTH_SUCCESS',
  FETCH_FLEET_STATISTICS_FROM_REPORTS_NEW_SUCCESS: 'VEHICLE_FETCH_FLEET_STATISTICS_FROM_REPORTS_NEW_SUCCESS',
  FETCH_GROUP_STATISTICS_FROM_REPORTS_NEW_SUCCESS: 'VEHICLE_FETCH_GROUP_STATISTICS_FROM_REPORTS_NEW_SUCCESS',
  FETCH_FUEL_PRICES_SUCCESS: 'VEHICLE_FETCH_FUEL_PRICES_SUCCESS',
  FETCH_COUNT_SUCCESS: 'VEHICLE_FETCH_COUNT_SUCCESS',
  UPDATE_FUEL_PRICES_SUCCESS: 'VEHICLE_UPDATE_FUEL_PRICES_SUCCESS',
  FETCH_MAINTENANCE_REQUESTS_SUCCESS: 'FETCH_MAINTENANCE_REQUESTS_SUCCESS',
  PUSH_MESSAGE: 'VEHICLE_PUSH_MESSAGE',
  RESET: 'VEHICLE_RESET',
  FETCH_DEACTIVATE_PAIRING_MAINTENANCE_ID_SUCCESS: 'VEHICLE_FETCH_DEACTIVATE_PAIRING_MAINTENANCE_ID_SUCCESS',
  FETCH_SCORE_GAS_PERCENTAGE_SUCCESS: 'VEHICLE_FETCH_SCORE_GAS_PERCENTAGE_SUCCESS',
  FETCH_SCORE_CO2_SAVINGS_SUCCESS: 'VEHICLE_FETCH_SCORE_CO2_SAVINGS_SUCCESS',
  FETCH_VEHICLE_TODAY_SUCCESS: 'VEHICLE_FETCH_VEHICLE_TODAY_SUCCESS',
  FETCH_ON_THE_FLY_SUCCESS: 'VEHICLE_FETCH_ON_THE_FLY_SUCCESS',
  FETCH_FLEET_SAVINGS_SUCCESS: 'VEHICLE_FETCH_FLEET_SAVINGS_SUCCESS',
  FETCH_FLEET_EXPLOITATION_SUCCESS: 'VEHICLE_FETCH_FLEET_EXPLOITATION_SUCCESS',
  FETCH_FLEET_ECOSCORE_SUCCESS: 'VEHICLE_FETCH_FLEET_ECOSCORE_SUCCESS',
  FETCH_LOCK_CALENDAR_SUCCESS: 'VEHICLE_FETCH_LOCK_CALENDAR_SUCCESS',
  FETCH_LOCK_CALENDAR_DATE_SUCCESS: 'VEHICLE_FETCH_LOCK_CALENDAR_DATE_SUCCESS'
}


const PERSON = {
  FETCH: 'PERSON_FETCH',
  FETCH_ERROR: 'PERSON_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'PERSON_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'PERSON_FETCH_ID_SUCCESS',
  FETCH_GROUPS_SUCCESS: 'PERSON_FETCH_GROUPS_SUCCESS',
  FETCH_DELETE_SUCCESS: 'PERSON_FETCH_DELETE_SUCCESS',
  FETCH_ADD_SUCCESS: 'PERSON_FETCH_ADD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'PERSON_FETCH_UPDATE_SUCCESS',
  FETCH_SELECTED_VEHICLES_SUCCESS: 'PERSON_FETCH_SELECTED_VEHICLES_SUCCESS',
  FETCH_SETTINGS_SUCCESS: 'PERSON_FETCH_SETTINGS_SUCCESS',
  FETCH_TABLES_OPTIONS_SUCCESS: 'PERSON_FETCH_TABLES_OPTIONS_SUCCESS',
  FETCH_SETTINGS_PASSWORD_SUCCESS: 'PERSON_FETCH_SETTINGS_PASSWORD_SUCCESS',
  FETCH_SETTINGS_NOTIFICATIONS_SUCCESS: 'PERSON_FETCH_SETTINGS_NOTIFICATIONS_SUCCESS',
  FETCH_SETTINGS_FEATURES_SUCCESS: 'PERSON_FETCH_SETTINGS_FEATURES_SUCCESS',
  FETCH_SETTINGS_ADMIN_SUCCESS: 'PERSON_FETCH_SETTINGS_ADMIN_SUCCESS',
  PUSH_MESSAGE: 'PERSON_PUSH_MESSAGE',
  RESET: 'PERSON_RESET'
}


const RFID = {
  FETCH: 'RFID_FETCH',
  FETCH_ERROR: 'RFID_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'RFID_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'RFID_FETCH_ID_SUCCESS',
  FETCH_ADD_SUCCESS: 'RFID_FETCH_ADD_SUCCESS',
  FETCH_DELETE_SUCCESS: 'RFID_FETCH_DELETE_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'RFID_FETCH_UPDATE_SUCCESS',
  RESET: 'RFID_RESET'
}


const REPORT = {
  FETCH: 'REPORT_FETCH',
  FETCH_ERROR: 'REPORT_FETCH_ERROR',
  FETCH_ALL_NEW_SUCCESS: 'REPORT_FETCH_ALL_NEW_SUCCESS',
  FETCH_ALL_NEW_SEARCH_SUCCESS: 'REPORT_FETCH_ALL_NEW_SEARCH_SUCCESS',
  FETCH_NEW_DATE_SEARCH_SUCCESS: 'FETCH_NEW_DATE_SEARCH_SUCCESS',
  FETCH_NEW_DATE_SUCCESS: 'FETCH_NEW_DATE_SUCCESS',
  FETCH_ALL_NEW_DATE_SUCCESS: 'FETCH_ALL_NEW_DATE_SUCCESS',
  FETCH_ALL_NEW_DATES_SUCCESS: 'FETCH_ALL_NEW_DATES_SUCCESS',
  RESET: 'REPORT_RESET',
}


const REFUEL = {
  FETCH: 'REFUEL_FETCH',
  FETCH_ERROR: 'REFUEL_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'REFUEL_FETCH_ALL_SUCCESS',
  FETCH_VEHICLE_REFUELS_SUCCESS: 'REFUEL_FETCH_VEHICLE_REFUELS_SUCCESS',
  FETCH_ID_SUCCESS: 'REFUEL_FETCH_ID_SUCCESS',
  FETCH_VEHICLE_REFUELS_DATE_AND_GROUP_SUCCESS: 'REFUEL_FETCH_VEHICLE_REFUELS_DATE_AND_GROUP_SUCCESS',
  RESET: 'REFUEL_RESET'
}


const IMEI = {
  FETCH: 'IMEI_FETCH',
  FETCH_ERROR: 'IMEI_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'IMEI_FETCH_ALL_SUCCESS',
  FETCH_SEARCH_SUCCESS: 'IMEI_FETCH_SEARCH_SUCCESS',
  FETCH_UPLOAD_SUCCESS: 'IMEI_FETCH_UPLOAD_SUCCESS',
  FETCH_ID_SUCCESS: 'IMEI_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'IMEI_FETCH_DELETE_SUCCESS',
  PUSH_MESSAGE: 'IMEI_PUSH_MESSAGE',
  RESET: 'IMEI_RESET'
}


const IP = {
  FETCH: 'IP_FETCH',
  FETCH_ERROR: 'IP_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'IP_FETCH_ALL_SUCCESS',
  FETCH_UPLOAD_SUCCESS: 'IP_FETCH_UPLOAD_SUCCESS',
  FETCH_ID_SUCCESS: 'IP_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'IP_FETCH_DELETE_SUCCESS',
  PUSH_MESSAGE: 'IP_PUSH_MESSAGE',
  RESET: 'IP_RESET'
}


const COMMUNICATION = {
  FETCH: 'COMMUNICATION_FETCH',
  FETCH_ERROR: 'COMMUNICATION_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'COMMUNICATION_FETCH_ALL_SUCCESS',
  FETCH_ACTIVE_IMEIS_SUCCESS: 'COMMUNICATION_FETCH_ACTIVE_IMEI_SUCCESS',
  FETCH_SEARCH_SUCCESS: 'COMMUNICATION_FETCH_SEARCH_SUCCESS',
  PUSH_MESSAGE: 'COMMUNICATION_PUSH_MESSAGE',
  RESET: 'COMMUNICATION_RESET'
}


const MODULE = {
  FETCH: 'MODULE_FETCH',
  FETCH_ERROR: 'MODULE_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'FETCH_ALL_SUCCESS',
  FETCH_SEARCH_SUCCESS: 'MODULE_FETCH_SEARCH_SUCCESS',
  FETCH_ID_SUCCESS: 'MODULE_FETCH_ID_SUCCESS',
  FETCH_ID_OLD_SUCCESS: 'MODULE_FETCH_ID_OLD_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'MODULE_FETCH_UPDATE_SUCCESS',
  FETCH_PAIRING_REQUESTS_HISTORY_SUCCESS: 'MODULE_FETCH_PAIRING_REQUESTS_HISTORY_SUCCESS',
  FETCH_LOCKUNLOCK_REQUESTS_HISTORY_SUCCESS: 'MODULE_FETCH_LOCKUNLOCK_REQUESTS_HISTORY_SUCCESS',
  RESET: 'MODULE_RESET',
}


const STATE = {
  FETCH: 'STATE_FETCH',
  FETCH_ERROR: 'STATE_FETCH_ERROR',
  FETCH_ID_SUCCESS: 'STATE_FETCH_ID_SUCCESS',
  RESET: 'STATE_RESET'
}


const ALERT = {
  FETCH: 'ALERT_FETCH',
  FETCH_ERROR: 'ALERT_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'ALERT_FETCH_ALL_SUCCESS',
  FETCH_NEW_COUNT_SUCCESS: 'ALERT_FETCH_NEW_COUNT_SUCCESS',
  FETCH_DIAGNOSTICS_ID_SUCCESS: 'ALERT_FETCH_DIAGNOSTICS_ID_SUCCESS',
  FETCH_ALERTS_PRESETS_SUCCESS: 'ALERT_FETCH_ALERTS_PRESETS_SUCCESS',
  FETCH_ID_SUCCESS: 'ALERT_FETCH_ID_SUCCESS',
  FETCH_SETTINGS_ID_SUCCESS: 'ALERT_FETCH_SETTINGS_ID_SUCCESS',
  FETCH_ID_RED_SUCCESS: 'ALERT_FETCH_ID_RED_SUCCESS',
  FETCH_DELETE_SUCCESS: 'ALERT_FETCH_DELETE_SUCCESS',
  FETCH_UPDATE_SPEED_ID: 'ALERT_FETCH_UPDATE_SPEED_ID',
  FETCH_UPDATE_GEO_FENCE_ID: 'ALERT_FETCH_UPDATE_GEO_FENCE_ID',
  FETCH_UPDATE_ENGINE_TEMPERATURE_ID: 'ALERT_FETCH_UPDATE_ENGINE_TEMPERATURE_ID',
  FETCH_UPDATE_RPM_ID: 'ALERT_FETCH_UPDATE_RPM_ID',
  FETCH_UPDATE_GASOLINE_USE_ID: 'ALERT_FETCH_UPDATE_GASOLINE_USE_ID',
  FETCH_UPDATE_GAS_MALFUNCTION_ID: 'ALERT_FETCH_UPDATE_GAS_MALFUNCTION_ID',
  FETCH_UPDATE_GAS_MAINTENANCE_ID: 'ALERT_FETCH_UPDATE_GAS_MAINTENANCE_ID',
  FETCH_UPDATE_GAS_PERCENTAGE_ID: 'ALERT_FETCH_UPDATE_GAS_PERCENTAGE_ID',
  FETCH_UPDATE_SWITCH_SPEED_ID: 'ALERT_FETCH_UPDATE_SWITCH_SPEED_ID',
  FETCH_UPDATE_SWITCH_GEO_FENCE_ID: 'ALERT_FETCH_UPDATE_SWITCH_GEO_FENCE_ID',
  FETCH_UPDATE_SWITCH_ENGINE_TEMPERATURE_ID: 'ALERT_FETCH_UPDATE_SWITCH_ENGINE_TEMPERATURE_ID',
  FETCH_UPDATE_SWITCH_RPM_ID: 'ALERT_FETCH_UPDATE_SWITCH_RPM_ID',
  FETCH_UPDATE_SWITCH_GASOLINE_USE_ID: 'ALERT_FETCH_UPDATE_SWITCH_GASOLINE_USE_ID',
  FETCH_UPDATE_SWITCH_GAS_MALFUNCTION_ID: 'ALERT_FETCH_UPDATE_SWITCH_GAS_MALFUNCTION_ID',
  FETCH_UPDATE_SWITCH_GAS_MAINTENANCE_ID: 'ALERT_FETCH_UPDATE_SWITCH_GAS_MAINTENANCE_ID',
  FETCH_UPDATE_SWITCH_GAS_PERCENTAGE_ID: 'ALERT_FETCH_UPDATE_SWITCH_GAS_PERCENTAGE_ID',
  FETCH_UPDATE_SWITCH_LOCKED_ID: 'ALERT_FETCH_UPDATE_SWITCH_LOCKED_ID',
  FETCH_UPDATE_EMAIL_NOTIFICATION_ID: 'ALERT_FETCH_UPDATE_EMAIL_NOTIFICATION_ID',
  FETCH_OVERRIDE_GROUP_SETTINGS: 'ALERT_FETCH_OVERRIDE_GROUP_SETTINGS',
  RESET: 'ALERT_RESET'
}


const INVOICE = {
  FETCH: 'INVOICE_FETCH',
  FETCH_ERROR: 'INVOICE_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'INVOICE_FETCH_ALL_SUCCESS',
  FETCH_OVERDUE_SUCCESS: 'INVOICE_FETCH_OVERDUE_SUCCESS',
  FETCH_UNPAID_SUCCESS: 'INVOICE_FETCH_UNPAID_SUCCESS',
  FETCH_OVERDUE_AND_UNPAID_SUCCESS: 'INVOICE_FETCH_OVERDUE_AND_UNPAID_SUCCESS',
  FETCH_ID_SUCCESS: 'INVOICE_FETCH_ID_SUCCESS',
  FETCH_ACCOUNT_SETTINGS_SUCCESS: 'INVOICE_FETCH_ACCOUNT_SETTINGS_SUCCESS',
  FETCH_ACCOUNT_UPDATE_SUCCESS: 'INVOICE_FETCH_ACCOUNT_UPDATE_SUCCESS',
  FETCH_SELECTED_SUCCESS: 'INVOICE_FETCH_SELECTED_SUCCESS',
  FETCH_CONFIRM_TRANSACTION_SUCCESS: 'INVOICE_FETCH_CONFIRM_TRANSACTION_SUCCESS',
  PAY_SELECTED_SUCCESS: 'PAY_FETCH_SELECTED_SUCCESS',
  FETCH_TEMP_NUMBER_SUCCESS: 'INVOICE_FETCH_TEMP_NUMBER_SUCCESS',
  FETCH_CALCULATE_SUBSCRIBTION_TOTALS_SUCCESS: 'CALCULATE_SUBSCRIBTION_TOTALS_SUCCESS',
  RESET: 'INVOICE_RESET',
}


const ACCOUNT = {
  FETCH: 'ACCOUNT_FETCH',
  FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'ACCOUNT_FETCH_ALL_SUCCESS',
  FETCH_ID_SUCCESS: 'ACCOUNT_FETCH_ID_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'ACCOUNT_FETCH_UPDATE_SUCCESS',
  FETCH_ADD_SUCCESS: 'ACCOUNT_FETCH_ADD_SUCCESS',
  FETCH_DELETE_SUCCESS: 'ACCOUNT_FETCH_DELETE_SUCCESS',
  FETCH_ALL_DISTRIBUTOR_GROUPS_SUCCESS: 'ACCOUNT_FETCH_ALL_DISTRIBUTOR_GROUPS_SUCCESS',
  FETCH_ADD_DISTRIBUTOR_GROUPS_SUCCESS: 'ACCOUNT_FETCH_ADD_DISTRIBUTOR_GROUPS_SUCCESS',
  FETCH_ID_DISTRIBUTOR_GROUP_SUCCESS: 'ACCOUNT_FETCH_ID_DISTRIBUTOR_GROUP_SUCCESS',
  FETCH_DELETE_DISTRIBUTOR_GROUP_SUCCESS: 'ACCOUNT_FETCH_DELETE_DISTRIBUTOR_GROUP_SUCCESS',
  FETCH_UPDATE_DISTRIBUTOR_GROUP_SUCCESS: 'ACCOUNT_FETCH_UPDATE_DISTRIBUTOR_GROUP_SUCCESS',
  FETCH_CHANGE_DISTRIBUTOR_ACCOUNT_SUCCESS: 'ACCOUNT_FETCH_CHANGE_DISTRIBUTOR_ACCOUNT_SUCCESS',
  FETCH_ALL_DISTRIBUTOR_ACCOUNTS_SUCCESS: 'ACCOUNT_FETCH_ALL_DISTRIBUTOR_ACCOUNTS_SUCCESS',
  FETCH_ACCOUNT_VEHICLE_USAGE_SUCCESS: 'ACCOUNT_FETCH_ACCOUNT_VEHICLE_USAGE_SUCCESS',
  FETCH_ACCOUNT_VEHICLE_USAGE_REPORT_SUCCESS: 'ACCOUNT_FETCH_ACCOUNT_VEHICLE_USAGE_REPORT_SUCCESS',
  RESET: 'ACCOUNT_RESET',
}


const GROUP = {
  FETCH: 'GROUP_FETCH',
  FETCH_ERROR: 'GROUP_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'GROUP_FETCH_ALL_SUCCESS',
  FETCH_ALL_AUTH_SUCCESS: 'GROUP_FETCH_ALL_AUTH_SUCCESS',
  FETCH_ID_SUCCESS: 'GROUP_FETCH_ID_SUCCESS',
  FETCH_DELETE_SUCCESS: 'GROUP_FETCH_DELETE_SUCCESS',
  FETCH_UPDATE_SUCCESS: 'GROUP_FETCH_UPDATE_SUCCESS',
  FETCH_ADD_SUCCESS: 'GROUP_FETCH_ADD_SUCCESS',
  PUSH_MESSAGE: 'GROUP_PUSH_MESSAGE',
  RESET: 'GROUP_RESET',
}


const WORKER = {
  FETCH: 'WORKER_FETCH',
  FETCH_ERROR: 'WORKER_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'WORKER_FETCH_ALL_SUCCESS',
  FETCH_WORKERS_STATE_SUCCESS: 'WORKER_FETCH_WORKERS_STATE_SUCCESS'
}


const ERROR = {
  FETCH: 'ERROR_FETCH',
  FETCH_ERROR: 'ERROR_FETCH_ERROR',
  FETCH_ALL_SUCCESS: 'ERROR_FETCH_ALL_SUCCESS',
}


const DIALOGFLOW = {
  FETCH: 'DIALOGFLOW_FETCH',
  FETCH_ERROR: 'DIALOGFLOW_FETCH_ERROR',
  SUBMIT_QUESTION_SUCCESS: 'DIALOGFLOW_SUBMIT_QUESTION_SUCCESS'
}


export { AUTH, STATION, COMPETITOR, CHECKPOINT, VEHICLE, PERSON, RFID, REPORT, ALERT, REFUEL, IMEI, IP, COMMUNICATION, MODULE, STATE, INVOICE, ACCOUNT, GROUP, WORKER, ERROR, DIALOGFLOW }
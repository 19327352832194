import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllPeople } from '../../actions/person'
import TemplatePage from '../templates/TemplatePage'
import { Link } from 'react-router-dom'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Preloader from '../partials/Preloader'
import { IconDelete, IconEdit } from '../partials/Icons'
import moment from 'moment-timezone'
import queryString from 'query-string'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import { Tooltip } from 'react-tooltip'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { TIMEZONE } from '../../config'


interface UsersProps {
  router: { location: {search: string}, navigate: (to: string) => any },
  person: any,
  fetchAllPeople: (options: { limit: number, skip: number }) => Promise<any>,
}


interface UsersState {
  people: any,
  peopleCount: number,
  currentPage: number,
  perPage: number,
  timezone: string,
}


export class Users extends React.Component<UsersProps, UsersState> {

  state = {
    people: [],
    peopleCount: 0,
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }

  componentDidMount() {
    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/users?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/users?page=${currentPage}&perPage=${perPage}`)
    }
    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/users?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/users?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/users?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    return this.props.fetchAllPeople({ limit: options.perPage, skip: options.perPage * options.currentPage })
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        const { people, peopleCount } = this.props.person
        this.setState({ people, peopleCount })
      }
    })
  }


  render() {

    const { status } = this.props.person;
    const { people, peopleCount, currentPage, perPage, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={true} 
          buttonLink='/users/add' 
          buttonTitle={t(102)} 
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          title={t(1020)} 
          time={false}
          animate
        >
        <div className="table-wrapper">
          <table className="table">
            <TableHeader 
              columns={['', t(2040), t(2041), t(2019), t(2039), t(2020), '']} 
            />

            <tbody className="table__body table__body--striped">
              { people && people.map((item: {person_id: number, name: string, email: string, phone: string, role: number, registered: number}, index: number) => {
                return (
                  <tr key={item.person_id}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-user-edit${index}`}/>
                      <span id={`tooltip-user-edit${index}`} data-tooltip-content={t(24)}>
                        <Link to={`/users/edit/${item.person_id}`}>
                          <IconEdit task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      {item.name ? item.name : 'n/a'}
                    </td>
                    <td>
                      {item.email}
                    </td>
                    <td>
                      {item.phone ? item.phone : 'n/a'}
                    </td>
                    <td>
                      {item.role}
                    </td>
                    <td>
                      {moment(item.registered).tz(timezone).format('DD/MM/YYYY')}
                    </td>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-user-delete${index}`}/>
                      <span id={`tooltip-user-delete${index}`} data-tooltip-content={t(29)}>
                        <Link to={`/users/delete/${item.person_id}`}>
                          <IconDelete task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                  )
                })
              }
            </tbody>

            <TableFooter 
              button={false}
              colSpan={8}
              currentPage={ currentPage }
              perPage={ perPage }
              itemsCount={ peopleCount }
              prevPage={() => this.prevPage(currentPage - 1)}
              nextPage={() => this.nextPage(currentPage + 1)}
              updatePagination={this.updatePagination}
            />
          </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, auth }: RootState) => ({ person, auth }),
  { fetchAllPeople }
)(Users)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllCheckpoints } from '../../actions/checkpoint'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import TableFooter from '../partials/TableFooter'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { IconDelete, IconEdit, IconDropUp, IconDropDown } from '../partials/Icons'
import { Tooltip } from 'react-tooltip'


interface TemplateProps {
  router: { navigate: any, location: {search: string} },
  auth: any,
  checkpoint: any,
  fetchAllCheckpoints: (options: { limit: number, skip: number, param: string, paramValue: boolean }) => Promise<any>,
}


interface TemplateState {
  currentPage: number,
  perPage: number,
  param: string,
  paramValue: boolean,
  timezone: string
}


export class Template extends React.Component<TemplateProps, TemplateState> {

  state = {
    currentPage: 0,
    perPage: 10,
    param: 'custom_id',
    paramValue: true,
    timezone: TIMEZONE
  }


  componentDidMount() {

    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    let param = this.state.param
    let paramValue = this.state.paramValue

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.param !== null && parsed.param !== undefined) {
      param = parsed.param.toString()
    }
    if(parsed.paramValue !== null && parsed.paramValue !== undefined) {
      paramValue = parsed.paramValue === 'true' ? true : false
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/checkpoints?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/checkpoints?page=${currentPage}&perPage=${perPage}`)
    }

    // update state and load the data
    this.setState({ perPage, currentPage, param, paramValue })
    this.reloadPage({ perPage, currentPage, param, paramValue })

  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param: this.state.param, paramValue: this.state.paramValue  })
    this.props.router.navigate(`/checkpoints?page=0&perPage=${e.target.value}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue })
    this.props.router.navigate(`/checkpoints?page=${prevPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue })
    this.props.router.navigate(`/checkpoints?page=${nextPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, param: string, paramValue: boolean }) => {
    const { perPage, currentPage, param, paramValue } = options
    let reloadParam = this.state.param
    let reloadParamValue = this.state.paramValue
    if(param) { reloadParam = param }
    if(paramValue) { reloadParamValue = paramValue }
    return this.props.fetchAllCheckpoints({ limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue });
  }

  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    this.props.fetchAllCheckpoints({ limit: this.state.perPage, skip: this.state.perPage * this.state.currentPage, param, paramValue: !this.state.paramValue }); 
    this.props.router.navigate(`/checkpoints?page=${this.state.currentPage}&perPage=${this.state.perPage}&param=${param}&paramValue=${!this.state.paramValue}`)
  }


  render() {

    const { checkpoints, checkpointsCount, status } = this.props.checkpoint;
    const { currentPage, perPage, param, paramValue, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card
          title={t(1118)} 
          button={true} 
          buttonLink='/checkpoints/add' 
          buttonTitle={t(1109)}
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          tabnav='checkpoints'
          tabnavId={0}
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <thead className="table__header">
                <tr>
                  <th></th>
                  <th className="th--clickable" onClick={() => this.setParam('custom_id')}>{t(1125)} {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                  <th className="th--clickable" onClick={() => this.setParam('address')}>{t(1002)} {param === 'address' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                  <th className="th--clickable" onClick={() => this.setParam('label')}>{t(1062)} {param === 'label' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                  <th className="th--clickable" onClick={() => this.setParam('visited')}>{t(1127)} {param === 'visited' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                  <th className="th--clickable" onClick={() => this.setParam('registered')}>{t(2020)} {param === 'registered' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="table__body table__body--striped">
                {checkpoints && checkpoints.length > 0 && 
                checkpoints.map((item: {checkpoint_id: number, custom_id: string, address: string, label: string, visited: boolean, registered: number}, index: number) => {
                  return (
                  <tr key={item.checkpoint_id}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-user-edit${index}`}/>
                      <span id={`tooltip-user-edit${index}`} data-tooltip-content={t(24)}>
                        <Link to={`/checkpoints/edit/${item.checkpoint_id}`}>
                          <IconEdit task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      {item.custom_id}
                    </td>
                    <td>
                      {item.address}
                    </td>
                    <td>
                      {item.label}
                    </td>
                    <td>
                      {item.visited}
                    </td>
                    <td>
                      {moment(item.registered).tz(timezone).format('DD/MM/YYYY')}
                    </td>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-user-delete${index}`}/>
                      <span id={`tooltip-user-delete${index}`} data-tooltip-content={t(29)}>
                        <Link to={`/checkpoints/delete/${item.checkpoint_id}`}>
                          <IconDelete task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                  </tr>
                  )
                })
              }
              </tbody>

              <TableFooter 
                button={false}
                colSpan={5} 
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ checkpointsCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, auth }: RootState) => ({ checkpoint, auth }),
  { fetchAllCheckpoints }
)(Template)))
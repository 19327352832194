import t from '../translation/translate'


export const EstimationNote = () => {
  return (
    <p className="small small--estimates">
      <strong>*</strong>{t(2400)} <a href="https://help.stagtelematic.com/estimates/">{t(2401)}</a>.
    </p>
  )
}


export default EstimationNote
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { addNewVehicle } from '../../actions/vehicle'
import { fetchAllGroups } from '../../actions/group'
import { fetchAccountById } from '../../actions/account'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import Switch from '../partials/Switch'
import classnames from 'classnames'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface VehiclesAddProps {
  router: { navigate: (to: string) => any },
  group: any,
  vehicle: any,
  auth: any,
  account: any,
  fetchAllGroups: (options: { limit: number, skip: number }) => Promise<any>,
  addNewVehicle: (vehicle: any) => Promise<any>,
  fetchAccountById: (id: string) => Promise<any>,
}


interface VehiclesAddState {
  custom_id: string,
  groups: any,
  group_id: string,
  module_imei: string,
  tank_size: string,
  phone: string,
  roaming: boolean,
  auto_lock: boolean,
  ignore_payment: boolean,
  engine_type: string,
  average_consumption: string,
  alternative_fuel_type: string,
  module_version: string,
  formSubmitted: boolean,
  customSettings: boolean,
  consumption_difference: string,
  data_frequency: number,
  notifications: { vehicle_payment_overdue: boolean, vehicle_communication: boolean },
  refuel_sensitivity: string,
  payment_per_vehicle: boolean
}


export class VehiclesAdd extends React.Component<VehiclesAddProps, VehiclesAddState> {

  state = {
    custom_id: '',
    groups: [],
    group_id: '',
    module_imei: '',
    tank_size: '',
    phone: '',
    roaming: true,
    auto_lock: true,
    ignore_payment: false,
    engine_type: 'petrol',
    average_consumption: '',
    alternative_fuel_type: 'lpg',
    module_version: '0',
    formSubmitted: false,
    customSettings: false,
    consumption_difference: '15',
    data_frequency: 10,
    notifications: { vehicle_payment_overdue: false, vehicle_communication: false },
    refuel_sensitivity: '2',
    payment_per_vehicle: false,
  }


  componentDidMount() {
    this.getAccount()
    this.props.fetchAllGroups({ limit: 1000, skip: 0 })
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        this.setState({ groups: this.props.group.groups, group_id: this.props.group.groups[0].group_id  })
      }
    })
    this.props.fetchAccountById(this.props.auth.account_id)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        this.setState({ refuel_sensitivity: this.props.account.account.refuel_sensitivity })
      }
    })
  }


  getAccount = () => {
    this.props.fetchAccountById(this.props.auth.account_id)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        const { payment_per_vehicle } = this.props.account.account
        this.setState({
          payment_per_vehicle: payment_per_vehicle !== null ? payment_per_vehicle : false,
        })
      }
    })
  }


  handleChangeAlternativeFuelType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ alternative_fuel_type: e.target.value })
    if(e.target.value === 'lpg' && (this.state.consumption_difference === '0'  || this.state.consumption_difference === '20')) {
      this.setState({ consumption_difference: '15' })
    }
    if(e.target.value === 'cng' && (this.state.consumption_difference === '0'|| this.state.consumption_difference === '15')) {
      this.setState({ consumption_difference: '20' })
    }
  }


  handleChangeDataFrequency = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ data_frequency: parseInt(e.target.value) })
  }


  handleChangeRefuelSensitivity = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ refuel_sensitivity: e.target.value })
  }


  handleChangeModuleType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ module_version: e.target.value })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { custom_id, module_imei, tank_size, average_consumption, consumption_difference, engine_type, phone, roaming, auto_lock, ignore_payment, data_frequency, alternative_fuel_type, notifications, group_id, module_version, refuel_sensitivity } = this.state
    const vehicle = { 
      custom_id, 
      module_imei, 
      tank_size, 
      average_consumption, 
      consumption_difference, 
      engine_type, 
      phone, 
      roaming,
      auto_lock,
      ignore_payment,
      data_frequency,
      alternative_fuel_type,
      notifications,
      group_id,
      module_version,
      refuel_sensitivity
    } 
    this.props.addNewVehicle(vehicle)
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        this.props.router.navigate('/vehicles');
      }
      this.setState({ formSubmitted: false });
    })
  }

  
  render() {

    const { fields, status } = this.props.vehicle;
    const { custom_id, module_imei, tank_size, engine_type, average_consumption, alternative_fuel_type, consumption_difference, phone, roaming, auto_lock, ignore_payment, formSubmitted, customSettings, data_frequency, notifications, groups, group_id, module_version, refuel_sensitivity, payment_per_vehicle } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title="Add vehicle"
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >

          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="group_id">
                {t(225)} ({t(116)})
              </label>
              <select 
                name="group_id" 
                value={group_id} 
                onChange={e => this.setState({ group_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('group_id') })}
              > 
                {
                  groups && groups.map((item: {group_id: string, group_title: string}, index: number) => (
                    <option key={index} value={item.group_id}>{item.group_title}</option>
                  ))
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="module_imei">
                {t(2002)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="module_imei"
                id="module_imei" 
                value={ module_imei }
                onChange={e => this.setState({ module_imei: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('module_imei') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(1022)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={ custom_id }
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">
                {t(1029)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="phone"
                id="phone" 
                value={ phone }
                onChange={e => this.setState({ phone: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('phone') })}
              />
              <span className='form-group__explanation'>{t(2117)}</span>
            </div>


            {
              ((payment_per_vehicle === true && this.props.auth.role === 1) || (payment_per_vehicle === true && this.props.auth.role === 2)) &&  
              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ ignore_payment: !ignore_payment })}>
                  <Switch switchState={ignore_payment} />
                  <span className="label-title">
                    {t(2418)}
                  </span>
                </label>
                <span className='form-group__explanation'>
                  {t(2419)}
                </span>
              </div>
            }

            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ auto_lock: !auto_lock })}>
                <Switch switchState={auto_lock} />
                <span className="label-title">
                  {t(2416)}
                </span>
              </label>
              <span className='form-group__explanation'>
                {t(2417)}
              </span>
            </div>

            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ roaming: !roaming })}>
                <Switch switchState={roaming} />
                <span className="label-title">
                  {t(2387)}
                </span>
              </label>
              <span className='form-group__explanation'>
                {t(2388)}
              </span>
            </div>


            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ notifications: { ...notifications, vehicle_payment_overdue: !notifications.vehicle_payment_overdue } })}>
                <Switch switchState={notifications.vehicle_payment_overdue} />
                <span className="label-title">{t(1056)}</span>
              </label>
              <span className='form-group__explanation'>{t(1057)}</span>
            </div>


            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ notifications: { ...notifications, vehicle_communication: !notifications.vehicle_communication } })}>
                <Switch switchState={notifications.vehicle_communication} />
                <span className="label-title">{t(1058)}</span>
                <span className='form-group__explanation'>{t(1059)}</span>
              </label>
            </div>


            <div className="form-group">
              <label htmlFor="engine_type">
                {t(2106)} ({t(116)})
              </label>
              <select 
                name="engine_type" 
                value={engine_type} 
                onChange={e => this.setState({ engine_type: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('engine_type') })}
              >
                <option>{t(2107)}</option>
                <option>{t(2108)}</option>
              </select>
            </div>


            <div className="form-group">
              <label htmlFor="average_consumption">
                {t(2109)} [l/100km] ({t(116)})
              </label>
              <input 
                type="text" 
                name="average_consumption"
                id="average_consumption" 
                value={ average_consumption }
                onChange={e => this.setState({ average_consumption: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('average_consumption') })}
              />
              <span className='form-group__explanation'>{t(2110)}(ex.: 8.5)</span>
            </div>

            <div className="form-group">
              <label htmlFor="alternative_fuel_type">
                {t(2111)} ({t(116)})
              </label>
              <select 
                name="alternative_fuel_type" 
                onChange={e => this.handleChangeAlternativeFuelType(e)}
                className={classnames('', { 'input-error': fields && fields.includes('alternative_fuel_type') })}
                value={alternative_fuel_type}
              >
                <option value="lpg">LPG</option>
                <option value="cng">CNG</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="tank_size">
                Maximum LPG[l]/CNG[m<sup>3</sup>] {t(2112)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="tank_size"
                id="tank_size" 
                value={ tank_size }
                onChange={e => this.setState({ tank_size: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('tank_size') })}
              />
              <span className='form-group__explanation'>
                {t(2113)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="module_version">
                {t(2497)}
              </label>
              <select 
                name="module_version" 
                onChange={e => this.handleChangeModuleType(e)}
                className={classnames('', { 'input-error': fields && fields.includes('module_version') })}
                value={module_version}
              >
                <option value="0">{t(2498)}</option>
                <option value="400">STAG 400 ({t(2512)})</option>
                <option value="500">STAG 500 ({t(2512)})</option>
                <option value="700">STAG 700 ({t(2512)})</option>
              </select>
              <span className='form-group__explanation'>
                {t(2499)}
              </span>
            </div>

            <div className="form-group__custom-settings">
              <div className="form-group__checkbox form-group__checkbox--large">
                <label>
                  <input 
                    onChange={e => this.setState({ customSettings: !customSettings })}
                    checked={customSettings}
                    type="checkbox" 
                    name="default"  
                  /> 
                    {t(2114)}
                </label>
              </div>


              { customSettings === true && 
                <>
                  <div className="form-group">
                    <label htmlFor="refuel_sensitivity">
                      Refuel sensitivity ({t(116)})
                    </label>
                    <select 
                      name="refuel_sensitivity" 
                      onChange={e => this.handleChangeRefuelSensitivity(e)}
                      className={classnames('', { 'input-error': fields && fields.includes('refuel_sensitivity') })}
                      value={refuel_sensitivity}
                    >
                      <option value="1">1 (highest sensitivity)</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4 (lowest sensitivity)</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="consumption_difference">
                      {t(2115)} ({t(116)})
                    </label>
                    <input 
                      type="text" 
                      name="consumption_difference"
                      id="consumption_difference" 
                      value={consumption_difference}
                      onChange={e => this.setState({ consumption_difference: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('consumption_difference') })}
                    />
                    <span className='form-group__explanation'>{t(2116)}</span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="data_frequency">
                    {t(2389)}
                    </label>
                    <select 
                    name="data_frequency" 
                    onChange={e => this.handleChangeDataFrequency(e)}
                    className={classnames('', { 'input-error': fields && fields.includes('data_frequency') })}
                    value={data_frequency}
                    >
                    <option value="10">10 seconds</option>
                    <option value="30">30 seconds</option>
                    <option value="60">1 minute</option>
                    </select>
                  </div>
                </>
              }
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value={t(101)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>

        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, group, auth, account }: RootState) => ({ vehicle, group, auth, account }),
  { addNewVehicle, fetchAllGroups, fetchAccountById }
)(VehiclesAdd)))
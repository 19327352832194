import { IconArrowLeft, IconArrowRight } from './Icons'
import Button from './Button'
import t from '../translation/translate'


interface TableFooterProps {
  colSpan?: number,
  currentPage: number,
  perPage: number,
  itemsCount: number,
  prevPage: (options: number) => void,
  nextPage: (options: number) => void,
  updatePagination: (event: React.ChangeEvent<HTMLSelectElement>) => any,
  button: boolean, 
  buttonLink?: string, 
  buttonTitle?: string, 
  buttonClickable?: boolean, 
  buttonDisabled?: boolean, 
  buttonHandleOnClick?: () => void,
}


const TableFooter: React.FC<TableFooterProps> = ({ colSpan, currentPage, perPage, itemsCount, prevPage, nextPage, updatePagination, button, buttonLink, buttonTitle, buttonClickable, buttonDisabled, buttonHandleOnClick }) => {
  return (
    <tfoot className="table__footer">
      <tr className="pagination">
        <td colSpan={colSpan}>
          <span className="pagination__nav">
          {currentPage > 0 ? (
            <span className="pagination__prev pagination__prev--active" onClick={() => prevPage(currentPage - 1)}>
              <IconArrowLeft  color="#ffffff"/>
            </span>
          ) : (
            <span className="pagination__prev">
              <IconArrowLeft color="#9697AE" />
            </span>
          )} 

          {itemsCount < perPage * (currentPage + 1) +1 ? (
            <span className="pagination__prev">
              <IconArrowRight color="#9697AE" />
            </span>
          ) : (                              
            <span className="pagination__next pagination__next--active" onClick={() => nextPage(currentPage + 1)}>
              <IconArrowRight color="#ffffff" />
            </span>
          )} 
          </span>
          <span className="pagination__items-text">{t(2000)}</span>
          <span className="pagination__perpage-text">{t(2001)}</span>
          <span className="pagination__perpage-select">
            <select className="pagination__select" value={perPage} onChange={updatePagination}>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
          </span>
          <span className="pagination__item-num">{itemsCount === 0 ? 0 : currentPage * perPage + 1} - {itemsCount < perPage * (currentPage + 1) ? itemsCount : perPage * (currentPage + 1)} {t(2075)} {itemsCount}</span>
        </td>
        { button === true &&
          <td className="table__footer--button">
            <Button 
              link={buttonLink ? buttonLink : ''} 
              title={buttonTitle ? buttonTitle : ''} 
              classes='btn btn--text btn--primary btn--card-header' 
              clickable={buttonClickable ? buttonClickable : false}
              handleOnClick={buttonHandleOnClick ? buttonHandleOnClick : () => null } 
              disabled={buttonDisabled}
            />
          </td>
        }
      </tr>
    </tfoot>
  )
}


export default TableFooter
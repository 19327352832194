import moment from 'moment-timezone'


function createNewReportCheckpointsCSV(data: any) {

  // date
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();
  const todayDate = `${yyyy}-${mm}-${dd}`;

  const report = calculateReport(data);
  const csv = JSON2CSV(report);
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = 'stelematics-reports-' + todayDate + '.csv';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  function calculateReport(data: any) {

    const report: any = []
    data.map((item: any) => {
      

      const row = {
        vehicle: item.vehicle,
        checkpoint: item.custom_id,
        arriving: item.start_ts,
        leaving: item.stop_ts,
        total_time: moment("1900-01-01 00:00:00").add(item.diff, 'seconds').format("HH:mm:ss")
      }
      report.push(row)
      return report
    })

    return report
  }


  function JSON2CSV(objArray: any) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // title to CSV
    const keys = Object.keys(array[0]);
    str += keys.join(',') + '\r\n';


    // data lines to CSV
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index of Object.keys(array[i])) {
        line += array[i][index] + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;

  }

}


export default createNewReportCheckpointsCSV
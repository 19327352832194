import { useState, useEffect } from "react"
import { motion } from "framer-motion"


export const Switch = (options: { switchState: boolean, disabled: boolean }) => {

  const { switchState, disabled } = options

  const [isOn, setIsOn] = useState(switchState);

  useEffect(
    () => {setIsOn(switchState)},
    [switchState],
  )

  const toggleSwitch = () => setIsOn(!isOn);

  if(disabled) {
    return (
      <span className="switch-wrapper--fixed">
      <span className="switch">
        <div className="handle" />
      </span>
    </span>
    )
  } 

  return (
    <span className="switch-wrapper--fixed">
      <span className="switch" data-ison={isOn} onClick={toggleSwitch}>
        <motion.div className="handle" layout transition={spring} />
      </span>
    </span>
  );
}


export default Switch


const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
}
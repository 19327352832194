import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import CardFleetStatistics from '../partials/CardFleetStatistics'
import CardVehiclesNoCommunication from '../partials/CardVehiclesNoCommunication'
import CardEcoScore from '../partials/CardEcoScore'
import CardFleetActivity from '../partials/CardFleetActivity'
import CardFleetSavingsGraph from '../partials/CardFleetSavingsGraph'
import CardFleetExploitation from '../partials/CardFleetExploitation'
import CardAuthorisedStations from '../partials/CardAuthorisedStations'


interface HomePageProps {
  checkAuth: () => Promise<any>,
}


export class HomePage extends React.Component<HomePageProps> {

  componentDidMount() {
    this.props.checkAuth()
  }

  render() {

    return (
      <TemplatePage>
        <CardFleetActivity />
        <CardFleetSavingsGraph />
        <CardFleetStatistics />
        <CardFleetExploitation />
        <CardEcoScore />
        <CardVehiclesNoCommunication />
        <CardAuthorisedStations />
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { checkAuth }
)(HomePage)))
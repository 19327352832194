import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAccountSettings, updateAccountSettings } from '../../actions/invoice'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface BillingSettingsProps {
  auth: any,
  invoice: any,
  fetchAccountSettings: () => Promise<void>,
  updateAccountSettings: (account: any) => Promise<void>,
}


interface BillingSettingsState {
  account_id: string,
  company_name: string,
  street_name: string,
  city: string,
  postcode: string,
  country: string,
  vat: string,
  account_email: string,
  formSubmitted: boolean,
}


export class BillingSettings extends React.Component<BillingSettingsProps, BillingSettingsState> {
  
  state = {
    account_id: '',
    company_name: '',
    street_name: '',
    city: '',
    postcode: '',
    country: '',
    vat: '',
    account_email: '',
    formSubmitted: false,
  }


  componentDidMount() {
    this.props.fetchAccountSettings()
    .then(() => {
      if(this.props.invoice.invoice !== undefined) {
        const { account_id, company_name, street_name, city, postcode, country, vat, account_email } = this.props.invoice.account
        this.setState({
          account_id: account_id !== null ? account_id : '',
          company_name: company_name !== null ? company_name : '',
          street_name: street_name !== null ? street_name : '',
          city: city !== null ? city : '',
          postcode: postcode !== null ? postcode : '',
          country: country !== null ? country : '',
          vat: vat !== null ? vat : '',
          account_email: account_email !== null ? account_email : ''
        })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const { account_id, company_name, street_name, city, postcode, country, vat, account_email } = this.state
    const account = { account_id, company_name, street_name, city, postcode, country, vat, account_email }
    this.props.updateAccountSettings(account)
    .then(() => {
      this.setState({ formSubmitted: false })
    })

  }


  render() {

    const { company_name, street_name, city, postcode, country, vat, account_email, formSubmitted } = this.state
    const { status, fields } = this.props.invoice

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card
          button={false} 
          title={t(139)}
          time={false}
          padding={['large']}
          tabnav='billing'
          tabnavId={0}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="company_name">{t(155)} ({t(116)})</label>
              <input 
                type="text" 
                name="company_name"
                id="company_name" 
                value={company_name}
                onChange={e => this.setState({ company_name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('company_name') })}
              />
            </div>

            
            <div className="form-group">
              <label htmlFor="street_name">{t(157)} ({t(116)})</label>
              <input 
                type="text" 
                name="street_name"
                id="street_name" 
                value={street_name}
                onChange={e => this.setState({ street_name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('street_name') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="city">{t(158)} ({t(116)})</label>
              <input 
                type="text" 
                name="city"
                id="city" 
                value={city}
                onChange={e => this.setState({ city: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('city') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="postcode">{t(159)} ({t(116)})</label>
              <input 
                type="text" 
                name="postcode"
                id="postcode" 
                value={postcode}
                onChange={e => this.setState({ postcode: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('postcode') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="country">{t(160)} ({t(116)})</label>
              <input 
                type="text" 
                name="country"
                id="country" 
                value={country}
                onChange={e => this.setState({ country: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('country') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="vat">{t(161)} ({t(116)})</label>
              <input 
                type="text" 
                name="vat"
                id="vat" 
                value={vat}
                onChange={e => this.setState({ vat: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('vat') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="vat">{t(1072)} ({t(116)})</label>
              <input 
                type="text" 
                name="account_email"
                id="account_email" 
                value={account_email}
                onChange={e => this.setState({ account_email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('account_email') })}
              />
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(141)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ invoice, auth }: RootState) => ({ invoice, auth }),
  { fetchAccountSettings, updateAccountSettings }
)(BillingSettings)))
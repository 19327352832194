import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchPersonGroups } from '../../actions/person'
import { fetchFleetActivity } from '../../actions/vehicle'
import { Link } from 'react-router-dom'
import Authorization from './Authorization'
import TableFooter from './TableFooter'
import DatePicker from 'react-datepicker'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from './WithRouter'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates';
import Preloader from './Preloader'
import { Tooltip } from 'react-tooltip'
import { IconVisibility, IconDropUp, IconDropDown, IconNoData } from '../partials/Icons'
import CtaBox from './CtaBox'


interface VehiclesActivityProps {
  auth: any,
  vehicle: any,
  person: any,
  fetchPersonGroups: () => Promise<void>,
  fetchFleetActivity: (options: { limit: number, skip: number, date: any, group_id: string, param: string, paramValue: boolean }) => Promise<any>,
}


interface VehiclesActivityState {
  currentPage: number,
  perPage: number,
  filterDate: any,
  todayDate: any,
  group_id: string,
  vehiclesOnTheFly: any,
  vehiclesOnTheFlyCount: number,
  timezone: string,
  param: string,
  paramValue: boolean,
  groups: any,
  filterGroup: string,
  dataLoaded: boolean,
  dataGroupsLoaded: boolean,
  fetchingData: boolean,
}


export class VehiclesActivity extends React.Component<VehiclesActivityProps, VehiclesActivityState> {

  state = {
    currentPage: 0,
    perPage: 10,
    filterDate: new Date(),
    todayDate: new Date(),
    group_id: 'all',
    vehiclesOnTheFly: [],
    vehiclesOnTheFlyCount: 0,
    timezone: TIMEZONE,
    param: 'dist',
    paramValue: true,
    groups: [],
    filterGroup: 'all',
    dataLoaded: false,
    dataGroupsLoaded: false,
    fetchingData: true,
  }


  componentDidMount = async () => {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE });
    this.fetchPersonGroups();
    const { perPage, currentPage, filterDate, group_id, param, paramValue } = this.state;
    this.reloadPage({ perPage, currentPage, filterDate, group_id, param, paramValue });
  }


  fetchPersonGroups = async () => {
    await this.props.fetchPersonGroups();
    if(this.props.person.status === fetchStates.success) {
      const { groups } = this.props.person.person;
      if(groups.length > 0) {
        let filterGroup = 'all';
        if(groups.length === 1) {
          filterGroup = groups[0].group_id;
        }     
        this.setState({ groups, filterGroup, dataGroupsLoaded: true });
      } else {
        this.setState({ dataLoaded: true, dataGroupsLoaded: false });
      }
    }
  }


  reloadPage = async (options: { perPage: number, currentPage: number, filterDate: any, group_id: string, param: string, paramValue: boolean }) => {
    const { perPage, currentPage, filterDate, group_id, param, paramValue } = options
    this.setState({ fetchingData: true })
    await this.props.fetchFleetActivity({ limit: perPage, skip: perPage * currentPage, date: moment(filterDate).format('YYYY-MM-DD'), group_id, param, paramValue });
    if(this.props.vehicle.status === fetchStates.success) {
      const { vehiclesOnTheFlyCount, vehiclesOnTheFly } = this.props.vehicle;
      this.setState({ vehiclesOnTheFlyCount, vehiclesOnTheFly, fetchingData: false });
    }
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    const { filterDate, group_id, param, paramValue } = this.state;
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, filterDate, group_id, param, paramValue })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { filterDate, group_id, param, paramValue } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, filterDate, group_id, param, paramValue })
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { filterDate, group_id, param, paramValue } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, filterDate, group_id, param, paramValue })
  }


  updateFilterDate = (date: any) => {
    this.setState({ filterDate: date, currentPage: 0 });
    const { param, paramValue } = this.state;
    this.reloadPage({ perPage: this.state.perPage, currentPage: 0, filterDate: date, group_id: this.state.group_id, param, paramValue })
  }


  handleSelectGroup = (group_id: string) => {
    this.setState({ group_id, currentPage: 0 });
    const { param, paramValue } = this.state;
    this.reloadPage({ perPage: this.state.perPage, currentPage: 0, filterDate: this.state.filterDate, group_id, param, paramValue })
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    const { perPage, currentPage, filterDate, group_id } = this.state;
    this.reloadPage({ perPage, currentPage, filterDate, group_id, param, paramValue: !this.state.paramValue })
  }


  render() {

    const { currentPage, perPage, filterDate, todayDate, group_id, groups, vehiclesOnTheFly, vehiclesOnTheFlyCount, fetchingData, timezone, param, paramValue, dataGroupsLoaded } = this.state

    return (
      <Authorization requiresAuth="vehicles">
      <div className="card animation--slide-in-from-bottom">
        <div className="card__header">
          <span>
            <h3 className="card__header-title">
              Vehicles activity
            </h3>
          </span>
        </div>
        <div className="card__body card__body--wrapper">
          <form className="form">
            <div className="filter">
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2050)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDate}
                    selectsStart
                    onChange={this.updateFilterDate}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    minDate={new Date(moment().subtract(3, 'months').add(1,'day').format())}
                    required={true}
                  />
                </span>
              </span>
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2429)}</span>
                <span className="filter_filter-select">
                  <select 
                    name="groups" 
                    onChange={e => this.handleSelectGroup(e.target.value)}
                    value={group_id}
                  >
                    <option key="0" value="all">{t(1152)}</option>
                    { 
                      groups && groups.map((group: {group_id: number, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                    }
                  </select>
                </span>
              </span>
            </div>
          </form>
          { 
              dataGroupsLoaded === false ? (
                <CtaBox padding={true} type="warning" title={t(4278)} icon={<IconNoData size={54} color="#ffffff" />}>
                  {t(2490)}
                </CtaBox>
              ) : (
                <div className="table-wrapper">
                  { fetchingData ? <Preloader type="preloader-center" /> : (
                    <table className="table">
                      <thead className="table__header">
                        <tr>
                          <th></th>
                          <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('ts_st')}>Started on {param === 'ts_st' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('ts_sp')}>Finished on {param === 'ts_sp' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('dist')}>Distance {param === 'dist' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('perc')}>Alternative fuel % {param === 'perc' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                        </tr>
                      </thead>
                      <tbody className="table__body table__body--striped">
                        {
                          vehiclesOnTheFly && vehiclesOnTheFly.map((item: {vehicle_id: string, custom_id: string, ts_st: number, ts_sp: number, rep_dp_diff: string, rep_dg_diff: string, dist: number, perc: number}, index: number) => {
                            return (
                              <tr key={item.vehicle_id}>
                                <td className="simple">
                                  <Tooltip anchorId={`tooltip-on-the-fly${index}`} />
                                  <Link to={`/vehicles/now/${item.vehicle_id}`}>
                                    <span id={`tooltip-on-the-fly${index}`} data-tooltip-content={t(16)}>
                                      <IconVisibility 
                                        task 
                                        button 
                                        color="#ffffff"
                                      />
                                    </span>
                                  </Link>
                                </td>
                                <td>{item.custom_id}</td>
                                <td>
                                  {
                                    this.props.auth.authorized.vehicle_routes === true ? (item.ts_st ? <Link to={`/vehicles/routes/${item.vehicle_id}/${moment(this.state.filterDate).format('YYYY-MM-DD')}`}>{moment(item.ts_st*1000).tz(timezone).format('HH:mm:ss')}</Link> : 'n/a') : (item.ts_st ? moment(item.ts_st*1000).tz(timezone).format('HH:mm:ss') : 'n/a')
                                  }
                                </td>
                                <td>
                                  {
                                    this.props.auth.authorized.vehicle_now === true ? (item.ts_sp ? <Link to={`/vehicles/now/${item.vehicle_id}`}>{moment(item.ts_sp*1000).tz(timezone).format('HH:mm:ss')}</Link> : 'n/a') : (item.ts_sp ? moment(item.ts_sp*1000).tz(timezone).format('HH:mm:ss') : 'n/a')
                                  }
                                </td>
                                <td>
                                  { item.dist === null ? 'n/a' : `${item.dist} km` }
                                </td>
                                <td>
                                  { item.perc === null ? 'n/a' : `${item.perc} %` }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                      <TableFooter 
                        button={false}
                        colSpan={6}
                        currentPage={ currentPage }
                        perPage={ perPage }
                        itemsCount={ vehiclesOnTheFlyCount }
                        prevPage={() => this.prevPage(currentPage - 1)}
                        nextPage={() => this.nextPage(currentPage + 1)}
                        updatePagination={this.updatePagination}
                      />
                    </table>
                  )}
                </div>
              )
          }
        </div>
    </div>
  </Authorization>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, person }: RootState) => ({ auth, vehicle, person }),
  { fetchPersonGroups, fetchFleetActivity }
)(VehiclesActivity)))
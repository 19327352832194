import t from '../../components/translation/translate'


function overviewExplanationIntelligentGasLvl(gmd_is: number) {
  switch (gmd_is) {
    case 0:
      return t(2342)
    case 1:
      return t(2343)
    case 2:
      return t(2344)
    case 3:
      return t(2345)
    case 4:
      return t(2346)
    case 5:
      return t(2347)
    case 6:
      return t(2348)
    default:
      return 'n/a'
  }
}


export default overviewExplanationIntelligentGasLvl
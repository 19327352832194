import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchVehicleById, fetchFuelUsage } from '../../actions/vehicle'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import NoIgl from '../partials/NoIgl'
import Switch from '../partials/Switch'
import Legend from '../partials/Legend'
import Preloader from '../partials/Preloader'
import ReactApexChart from 'react-apexcharts'
import moment from 'moment-timezone'
import { TIMEZONE } from '../../config'
import { IconNoData, IconCalendarInput } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import DatePicker from 'react-datepicker';
import { tseries, toptions, rseries, roptions } from '../../helpers/charts/fuelUsageChartTimeSettings'
import hex2bin from '../../helpers/hex2bin'
import gasLevelSensorsNames from '../../helpers/gasLevelSensorsNames'
import Auth from '../partials/Auth'


const scaleInvert = (data: any, min: number, max: number) => {
  const scaledData = data.map((item:[number, number]) => {
    return [item[0], max - item[1]]//max - item
  })
  return scaledData
}

interface VehiclesFuelUsageProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  fetchVehicleById: (id: number) => Promise<any>,
  fetchFuelUsage: (options: {id: number, month: string}) => Promise<any>, 
}


interface VehiclesFuelUsageState {
  vehicle: { custom_id: string, module_imei: string, connected: any, gas_usage: number, igl: number },
  filterDate: any,
  todayDate: any,
  graphInverted: boolean,
  module_imei: string,
  reasons: any,
  reason: number,
  description: string,
  timezone: string,
  dataLoaded: boolean,
  chartDataLoaded: boolean,
  tseries: any,
  toptions: any,
  rseries: any,
  roptions: any
}


export class VehiclesFuelUsage extends React.Component<VehiclesFuelUsageProps, VehiclesFuelUsageState> {

  state = {
    vehicle: { custom_id: '', module_imei: '', connected: null, gas_usage: 0, igl: 0, gas_sensor_type: 255, gas_level_thresholds: [0, 0, 0, 0] },
    filterDate: new Date(),
    todayDate: new Date(),
    graphInverted: false,
    module_imei: '',
    reasons: [],
    reason: 0,
    description: '',
    timezone: TIMEZONE,
    dataLoaded: false,
    chartDataLoaded: false,
    tseries,
    toptions,
    rseries,
    roptions
  }


  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.props.fetchVehicleById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicle } = this.props.vehicle
        this.setState({ vehicle: {...vehicle, graphInverted: vehicle.gas_level_sensor_reversed === 1 ? true : false, gas_level_thresholds: JSON.parse(vehicle.gas_level_thresholds)} })
        this.reloadPage(this.state.filterDate)
      }
    });
  }


  reloadPage = (date: any) => {
    this.setState({ chartDataLoaded: false, dataLoaded: false, tseries, toptions })
    this.props.fetchFuelUsage({ id: parseInt(this.props.router.params.id), month: moment(date).format('YYYY-MM') })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const timezoneOffset = moment.tz(moment.utc(this.state.filterDate), this.props.auth.timezone).utcOffset()
        const timezoneOffsetMs = timezoneOffset * 60 * 1000
        let { vehicleFuelUsage, vehicleCorrectRefuels } = this.props.vehicle

        // for debugging
        // console.log('timezoneOffsetMs', timezoneOffsetMs)
        // console.log('timezoneOffset', timezoneOffset)
        // console.log('vehicleFuelUsage', vehicleFuelUsage)
        // console.log('vehicleCorrectRefuels', vehicleCorrectRefuels)

        if(vehicleFuelUsage && vehicleFuelUsage.length > 0) {

          // resetting arrays
          const tseriesFuelUsage: any = [];
          const refuelAnnotiations: any = [];
          const seriesQuantityOfGas: any = [];
          let treasholdAnnotations: any = [];

          // FUEL LEVEL CHART MAIN DATA
          // showing the tank fuel level on the chart
          vehicleFuelUsage.reverse().map((fuel_lvl: {ts: number, gmd_la: number, gmd_rf: number}) => {
            tseriesFuelUsage.push([fuel_lvl.ts * 1000 + timezoneOffsetMs, fuel_lvl.gmd_la]);
            seriesQuantityOfGas.push([fuel_lvl.ts * 1000 + timezoneOffsetMs, fuel_lvl.gmd_rf]);
            return null;
          })


          // ALTERNATIVE vs DEFAULT FUEL HIGHLIGHT
          // highlights with transparent blue times when default fuel was used
          let new_value: any;
          let previous_value: any;
          let annotation_start: any;
          let annotation_end;
          const total_records = vehicleFuelUsage.length - 1;
          vehicleFuelUsage.reverse().map((fuel_type: {gmd_sf: number, ts: number}, index: number) => {
            new_value = parseInt(hex2bin(fuel_type.gmd_sf).slice(-1))
            if(previous_value !== new_value || index === total_records) {
              if((new_value === 0 && previous_value === 1) || (index === 0 && new_value === 0)) {
              annotation_start = fuel_type.ts * 1000 + timezoneOffsetMs
              }
              if((new_value === 1 && previous_value === 0) || (index === total_records && new_value === 0)) {
                annotation_end = fuel_type.ts * 1000 + timezoneOffsetMs

                refuelAnnotiations.push({
                  x: annotation_start,
                  x2: annotation_end,
                  fillColor: '#2cbffc',
                })
              }
            }
            previous_value = new_value
            return null;
          })



          // REFUEL ANNOTATIONS SECTION
          // labels for authorized refuel, unauthorized refuel and visit
          if(vehicleCorrectRefuels && vehicleCorrectRefuels.length > 0) {
            vehicleCorrectRefuels.reverse().map((refuel: {unlocked: number, ts: number}) => {

              // unauthorized refuel
              if(refuel.unlocked === -1) {
                refuelAnnotiations.push({
                  x: refuel.ts * 1000 + timezoneOffsetMs,
                  borderColor: '#D84A49',
                  label: {
                    borderColor: '#D84A49',
                    style: {
                      color: '#fff',
                      background: '#D84A49',
                    },
                    text: `${moment(refuel.ts*1000).tz(this.state.timezone).format('DD/MM/YYYY HH:mm:ss')}`,
                  }
                })
              }

              // visit
              if(refuel.unlocked === 0) {
                refuelAnnotiations.push({
                  x: refuel.ts * 1000 + timezoneOffsetMs,
                  borderColor: '#FACB4E',
                  label: {
                    borderColor: '#FACB4E',
                    style: {
                      color: '#fff',
                      background: '#FACB4E',
                    },
                    text: `${moment(refuel.ts*1000).tz(this.state.timezone).format('DD/MM/YYYY HH:mm:ss')}`,
                  }
                })
              }

              // authorized refuel
              if(refuel.unlocked === 1) {
                refuelAnnotiations.push({
                  x: refuel.ts * 1000 + timezoneOffsetMs,
                  borderColor: '#5BC3A3',
                  label: {
                    borderColor: '#5BC3A3',
                    style: {
                      color: '#fff',
                      background: '#5BC3A3',
                    },
                    text: `${moment(refuel.ts*1000).tz(this.state.timezone).format('DD/MM/YYYY HH:mm:ss')}`,
                  }
                })
              }
              
              return null
            })
          }


          // GAS LEVEL TREASHOLDS ANNOTATIONS SECTION
          if(this.state.vehicle.gas_level_thresholds && this.state.vehicle.gas_level_thresholds.length !== 0) {
            this.state.vehicle.gas_level_thresholds.map((lvl: number) => {
              console.log(lvl)
              treasholdAnnotations.push(
                {
                  y: lvl,
                  borderColor: '#000',
                  label: {
                    borderColor: '#000',
                    style: {
                      color: '#fff',
                      background: '#000'
                    },
                    text: lvl.toString()
                  }
                }
              )
              return null
            })            
          }


          // decide if any data were loaded
          let chartDataLoaded = false
          if(tseriesFuelUsage.length > 0) {
            chartDataLoaded = true
          }


          this.setState({
            tseries: this.state.graphInverted === true ? [{ data: scaleInvert(tseriesFuelUsage, 0, 1023), name: t(2168) }] : [{ data: tseriesFuelUsage, name: t(2168) }],
            toptions: {...this.state.toptions, xaxis: {...this.state.toptions.xaxis, labels: { ...this.state.toptions.xaxis.labels, style: { ...this.state.toptions.xaxis.labels.style }} }, annotations: {
              xaxis: refuelAnnotiations,
              yaxis: treasholdAnnotations
            } },
            rseries: [{ data: seriesQuantityOfGas, name: t(2168) }],
            roptions: {...this.state.roptions, xaxis: {...this.state.roptions.xaxis, labels: { ...this.state.roptions.xaxis.labels, style: { ...this.state.roptions.xaxis.labels.style }} }},
            chartDataLoaded,
            dataLoaded: true
          });

          
        } else {
          this.setState({ dataLoaded: true })
        }
      }
    });

  }


  updateFilterDate = (date:any) => {
    this.setState({ filterDate: date });
    this.reloadPage(date);
  }


  invertGraph = () => {
    this.setState({ graphInverted: !this.state.graphInverted,  tseries: [{ data: scaleInvert(this.state.tseries[0].data, 0, 1023), name: t(2168) }]})
  }


  render() {

    const { status } = this.props.vehicle;
    const { vehicle, dataLoaded, chartDataLoaded, graphInverted, toptions, tseries, roptions, rseries, filterDate, todayDate } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          padding={['medium']}
          time={false}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
            { dataLoaded ? (
              <>
                <div className="vehicle-savings-filter">
                  <span className="filter_filter-icon">
                    <IconCalendarInput />
                  </span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDate}
                      selectsStart
                      onChange={this.updateFilterDate}
                      dateFormat='yyyy-MM'
                      maxDate={todayDate}
                      required={true}
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </span>
                </div>

                {
                  chartDataLoaded === false ? ( 
                    <CtaBox padding={false} type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(2170)}
                    </CtaBox>
                  ) : (
                    vehicle.connected !== null && (
                      <>
                      <h3><center>{gasLevelSensorsNames(vehicle.gas_sensor_type)}</center></h3>
                        <form className="form form--padding">
                          <div className="form-group__switch form-group__switch--large">
                            <label onClick={() => this.invertGraph()}>
                              <Switch switchState={graphInverted} />
                              <span className="label-title">{t(2169)}</span>
                            </label>
                          </div>
                        </form>
                        <div className="fuel-level-chart">
                          <ReactApexChart
                            options={toptions}
                            series={tseries}
                            type="line"
                            width="100%"
                            height="300"
                          />
                        </div>

                        <Legend labels={[ { title: t(1132), color: '#5BC3A3' }, { title: t(1133), color: '#D84A49' }, { title: t(1134), color: '#FACB4E' }, { title: t(1135) + `(${(100 - vehicle.gas_usage).toFixed(1)}%)`, color: '#2cbffc' } ]}/>

                        <Auth requiresRole={[1]}>
                          <div className="fuel-level-chart">
                            <ReactApexChart
                              options={roptions}
                              series={rseries}
                              type="line"
                              width="100%"
                              height="300"
                            />
                          </div>
                        </Auth>


                        {vehicle.igl !== 1 && (
                          <NoIgl padding={false} igl={vehicle.igl} />
                        )}
                      </>
                    )
                  )
                }
              </>
            ) : (<></>)}
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, auth }: RootState) => ({ vehicle, auth }),
  { fetchVehicleById, fetchFuelUsage }
)(VehiclesFuelUsage)))

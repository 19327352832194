function createAccountReportCSV(options: { data: any, date: string, account: any, filter_type: string }) {

  // date
  const { data, date, account, filter_type } = options;

  const report = calculateReport(data);
  const csv = JSON2CSV(report);
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `stelematics-reports-${filter_type}-vehicles-${date}-${account.company_name}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  function calculateReport(data: any) {

    const report: any = []
    data.map((item: any) => {
      

      const row = {
        date: item.dt_stamp_to,
        custom_id: item.custom_id,
        imei: item.module_imei,
      }
      report.push(row)
      return report
    })

    return report
  }


  function JSON2CSV(objArray: any) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // title to CSV
    const keys = Object.keys(array[0]);
    str += keys.join(',') + '\r\n';


    // data lines to CSV
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index of Object.keys(array[i])) {
        line += array[i][index] + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;

  }

}


export default createAccountReportCSV
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchModuleVehicleById } from '../../actions/vehicle'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import getGasControlerModel from '../../helpers/gasControllerModel'
import getGasSensorType from '../../helpers/getGasSensorType'
import classnames from 'classnames'
import { IconModuleGas, IconModuleTelemetry, IconNotifySuccess, IconNotifyError } from '../partials/Icons'
import { motion } from 'framer-motion'
import { motion_container, motion_item } from '../../helpers/motion'
import Preloader from '../partials/Preloader'


interface VehiclesModuleProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  fetchModuleVehicleById: (id: number) => Promise<any>
}


interface VehiclesModuleState {
  timezone: string,
  hardwareVersion: string,
  firmwareVersion: string,
  settingsTimestamp: string,
  simcardImsi: string,
  gasControlerSensorType: string,
  gasControlerModel: string,
  gasControlerFirmware: string,
  gasControlerFirmwareDate: string,
  phoneNumber: string,
  buffer: string,
  bufferRecords: number,
  lastConnected: number,
  unlocked: number,
  paired: number,
  connected: number,
  ignition: number, 
  controller_connected: number,
  compatible: number,
}


export class VehiclesModule extends React.Component<VehiclesModuleProps, VehiclesModuleState> {


  state = {
    timezone: TIMEZONE,
    hardwareVersion: 'n/a',
    firmwareVersion: 'n/a',
    settingsTimestamp: 'n/a',
    simcardImsi: 'n/a',
    gasControlerSensorType: 'n/a',
    gasControlerModel: 'n/a',
    gasControlerFirmware: 'n/a',
    gasControlerFirmwareDate: 'n/a',
    phoneNumber: 'n/a',
    buffer: 'n/a',
    bufferRecords: -1,
    lastConnected: 0,
    unlocked: -1,
    paired: 0,
    connected: 0,
    ignition: 0, 
    controller_connected: 0,
    compatible: 0,
  }


  componentDidMount() {

    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    if( this.props.router.params.id !== undefined ) {
      this.props.fetchModuleVehicleById(parseInt(this.props.router.params.id))
      .then(() => {

        if(this.props.vehicle.status === fetchStates.success) {
          const { hwi_hv, hwi_fv, hwi_st, hwi_im, gmi_mo, gmi_fv, gmi_cd, gmi_gs } = this.props.vehicle.vehicleModule;
          const { phone_number, buffer_records, buffer, ignition, controller_connected, compatible, paired, unlocked, connected } = this.props.vehicle.vehicle

          // if last timestamp is less then 10m then we count this as still connected
          let lastConnected = 0
          if(moment(connected).format('X') > moment().subtract(10, 'minutes').format('X') && ignition === 1) {
            lastConnected = 1
          }

          this.setState({ 
            hardwareVersion: hwi_hv !== undefined ? hwi_hv : 'n/a',
            firmwareVersion: hwi_fv !== undefined ? hwi_fv : 'n/a',
            settingsTimestamp: hwi_st !== undefined ? moment(hwi_st*1000).format('YYYY/MM/DD HH:MM') : 'n/a',
            simcardImsi: hwi_im !== undefined ? hwi_im : 'n/a',
            gasControlerSensorType: gmi_gs !== undefined ? gmi_gs : 'n/a',
            gasControlerModel: gmi_mo !== undefined && gmi_mo !== null ? getGasControlerModel(gmi_mo) + ' ' + gmi_mo : 'n/a',
            gasControlerFirmware: gmi_fv !== undefined ? gmi_fv : 'n/a',
            gasControlerFirmwareDate: gmi_cd !== undefined ? gmi_cd : 'n/a',
            phoneNumber: phone_number !== undefined ? phone_number : 'n/a',
            buffer: buffer !== null ? moment(buffer*1000).tz(this.state.timezone).format('DD/MM/YYYY') : 'n/a',
            bufferRecords: buffer_records !== null ? buffer_records : 'n/a',
            ignition,
            controller_connected,
            compatible,
            paired,
            unlocked,
            lastConnected,
            connected
          });
        }

      })
    }
  }


  render() {

    const { vehicle, status } = this.props.vehicle;
    const { hardwareVersion, firmwareVersion, settingsTimestamp, simcardImsi, gasControlerSensorType, gasControlerModel, gasControlerFirmware, gasControlerFirmwareDate, phoneNumber, buffer, bufferRecords, ignition, controller_connected, compatible, paired, unlocked, connected, lastConnected } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          button={true} 
          buttonLink='/accounts/add' 
          buttonTitle={t(191)}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
            <div className={classnames('modules', {          
                        'modules--processed': status === 'success',
                        'modules--processing': status === 'fetching',
                        'modules--unprocessed': status === 'error',
                      })}>
              <div className="module module--gas">
                <div className="module__title">
                  {t(2472)}
                </div>
                <div className="module__icon">
                  <IconModuleGas size={80} />
                </div>
                <motion.div 
                  className="module__labels"
                  variants={motion_container}
                  initial="hidden"
                  animate="visible"
                >




                  <motion.div className={classnames('module__label', {
                    'label--unknown': gasControlerModel === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                  })}
                    key={1} 
                    variants={motion_item}
                    whileHover={{ scale: 1.1 }}
                  >
                    {t(4282)}: {getGasSensorType(gasControlerSensorType)}
                  </motion.div>






                  <motion.div className={classnames('module__label', {
                    'label--unknown': gasControlerModel === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                  })}
                    key={2} 
                    variants={motion_item}
                    whileHover={{ scale: 1.1 }}
                  >
                    {t(4249)}: {gasControlerModel}
                  </motion.div>
                  <motion.div className={classnames('module__label', {
                    'label--unknown': gasControlerFirmware === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                  })}
                  key={3} 
                  variants={motion_item}
                  whileHover={{ scale: 1.1 }}
                  >
                    {t(4250)}: {gasControlerFirmware}
                  </motion.div>
                  <motion.div className={classnames('module__label', {
                    'label--unknown': gasControlerFirmwareDate === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                  })}
                  key={4} 
                  variants={motion_item}
                  whileHover={{ scale: 1.1 }}
                  >
                    {t(4252)}: {gasControlerFirmwareDate}
                  </motion.div>
                </motion.div>
              </div>

              <div className="module module--telemetry">
                <div className="module__title">
                  {t(2473)}
                </div>
                <div className="module__icon">
                  <IconModuleTelemetry size={80} />
                </div>
                <motion.div 
                  className="module__labels"
                  variants={motion_container}
                  initial="hidden"
                  animate="visible"
                >
                  <div className="module__labels-group">
                    <motion.div className="module__label"
                      key={4} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {ignition === 1 ? <IconNotifySuccess color="#5BC3A3"/> : <IconNotifyError color="#E34B5F"/>}
                      {t(2469)}: 
                      {ignition === 1 && ' ON '}
                      {ignition === 0 && ' OFF '}
                      { lastConnected === 1 && <span>({moment(connected).format('YYYY/MM/DD - HH:mm:ss')})</span> }
                      { lastConnected === 0 && <span className="text-error">({moment(connected).format('YYYY/MM/DD - HH:mm:ss')})</span> }
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': ignition === 0 || lastConnected === 0
                    })}
                      key={5} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {t(2470)}: 
                      { (ignition === 0 || lastConnected === 0) && ' n/a'}
                      { controller_connected === 1 && ignition === 1 && lastConnected === 1 && <span><IconNotifySuccess color="#5BC3A3"/> {t(1068)}</span> }
                      { controller_connected === 0 && ignition === 1 && lastConnected === 1 && <span  className="text-error"><IconNotifyError color="#E34B5F"/> {t(1069)}</span> }
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': controller_connected !== 1 || ignition === 0 || lastConnected === 0
                    })}
                      key={6} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {(controller_connected === 0 || ignition === 0 || lastConnected === 0) && 'Compatible: n/a'}
                      {controller_connected === 1 && compatible === 1 && ignition === 1 && lastConnected === 1 && <span><IconNotifyError color="#E34B5F"/>{t(25)}: <span className="text-error">{t(1071)}</span></span> }
                      {controller_connected === 1 && compatible === 0 && ignition === 1 && lastConnected === 1 && <span><IconNotifySuccess color="#5BC3A3"/>{t(25)}: {t(2395)}</span> }
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': controller_connected !== 1 || ignition === 0 || lastConnected === 0
                    })}
                      key={7} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {controller_connected === 0 && 'Paired: n/a'}
                      {paired === 1 && controller_connected !== 0 && `${t(25)}: ${t(2453)}`}
                      {(paired === 0 || paired === 2) && controller_connected !== 0 && <span>{t(25)}: <span className="text-error">{t(2452)}</span></span>}
                    </motion.div>

                    { paired === 1 && controller_connected !== 0 && (
                      <motion.div className={classnames('module__label', {
                        'label--unknown': controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                        })}
                          key={8} 
                          variants={motion_item}
                          whileHover={{ scale: 1.1 }}
                        >
                        {(unlocked === -1 || controller_connected === 0) && `${t(2492)}: n/a`}
                        {unlocked === 0 && controller_connected !== 0 && <span>{t(25)}: <span className="text-error">{t(2064)}</span></span>}
                        {unlocked === 1 && controller_connected !== 0 && `${t(25)}: ${t(2471)}`}
                      </motion.div>
                    ) }



                  </div>
                  <div className="module__labels-group">
                  <motion.div className={classnames('module__label', {
                    'label--unknown': hardwareVersion === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                    })}
                      key={9} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {t(1023)}: {hardwareVersion}
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': firmwareVersion === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                    })}
                      key={10} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      { firmwareVersion === '0.6.3' && <IconNotifyError color="#E34B5F"/> }
                      {t(1024) + `: `} 
                      { firmwareVersion === '0.6.3' && <span className="text-error">{firmwareVersion}</span> }
                      { firmwareVersion !== '0.6.3' && <span>{firmwareVersion}</span> }
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': settingsTimestamp === 'n/a' || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                    })}
                      key={11} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {t(4226)}: {settingsTimestamp}
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': simcardImsi === 'n/a' || controller_connected !== 1 || ignition === 0 || lastConnected === 0
                    })}
                      key={12} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      SIM IMS: {simcardImsi}
                    </motion.div>
                    <motion.div className="module__label"
                      key={13} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {t(2019)}: {phoneNumber}
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': buffer === 'n/a' || controller_connected !== 1 || ignition === 0 || lastConnected === 0
                    })}
                      key={14} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      {t(2069)}: {buffer}
                    </motion.div>
                    <motion.div className={classnames('module__label', {
                    'label--unknown': bufferRecords === -1 || controller_connected !== 1 ||  ignition === 0 || lastConnected === 0
                    })}
                      key={15} 
                      variants={motion_item}
                      whileHover={{ scale: 1.1 }}
                    >
                      { bufferRecords > 10 && <IconNotifyError color="#E34B5F"/> }
                      {t(2405) + `: `}  
                      {bufferRecords === -1 && <span> {bufferRecords}</span>}
                      {bufferRecords !== -1 && bufferRecords < 5 && <span> {bufferRecords}</span>}
                      {bufferRecords !== -1 && bufferRecords > 4 && <span className="text-error"> {bufferRecords}</span>}
                    </motion.div>
                  </div>
                </motion.div>
              </div>
            </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle }: RootState) => ({ auth, vehicle }),
  { fetchModuleVehicleById }
)(VehiclesModule)))
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../../config'
import { Link } from 'react-router-dom'
import Switch from '../Switch'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import markerPosition from'../../../img/marker-position.png'
import { updateAlertGeoFence, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormGeoFenceProps {
  alert: any,
  auth: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertGeoFence: (options: {id: number, lt: number, ln: number, rd: number, invert: boolean, mt: string, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormGeoFenceState {
  formSubmitted: boolean,
  address: string,
  addressSelected: boolean,
  range: number,
  rangeValue: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
  alert_min_time: string,
  alert_max_frequency: string,
  invert: boolean
}


export class AlertFormGeoFence extends React.Component<AlertFormGeoFenceProps, AlertFormGeoFenceState> {

  state = {
    formSubmitted: false,
    address: '',
    addressSelected: false,
    range: 5000,
    rangeValue: 5,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    alert_min_time: '5',
    alert_max_frequency: '300',
    invert: false
  }


  componentDidMount() {

    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {

      const { alerts_geo_in, alerts_geo_ln, alerts_geo_lt, alerts_geo_mp, alerts_geo_mt, alerts_geo_v } = this.props.alert.alertsSettings

        const { lat, lng, zoom } = this.props.auth;

        this.setState({
          range: alerts_geo_v !== null ? parseInt(alerts_geo_v) : 5000,
          rangeValue: alerts_geo_v !== null ? parseInt(alerts_geo_v)/1000 : 5,
          mapLat: alerts_geo_lt !== null ? parseFloat(alerts_geo_lt) : lat !== null ? parseFloat(lat) : DEFAULT_LAT,
          mapLng: alerts_geo_ln !== null ? parseFloat(alerts_geo_ln) : lng !== null ? parseFloat(lng) : DEFAULT_LNG,
          markerLat: alerts_geo_lt !== null ? parseFloat(alerts_geo_lt) : lat !== null ? parseFloat(lat) : DEFAULT_LAT,
          markerLng: alerts_geo_ln !== null ? parseFloat(alerts_geo_ln) : lng !== null ? parseFloat(lng) : DEFAULT_LNG,
          mapZoom: zoom !== null ? parseInt(zoom) : DEFAULT_ZOOM,
          alert_min_time: alerts_geo_mt !== null ? alerts_geo_mt : 5,
          alert_max_frequency: alerts_geo_mp !== null ? alerts_geo_mp : 300,
          invert: alerts_geo_in !== null ? alerts_geo_in : false
        })

    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.setShowModal(false, 'alert-speed')
    this.props.updateAlertGeoFence({ id: this.props.modalId, lt: this.state.markerLat, ln: this.state.markerLng, rd: this.state.range, invert: this.state.invert, mt: this.state.alert_min_time, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }


  handleRangeChange = (event: any) => {
    this.setState({ range: parseInt(event.target.value), rangeValue: parseInt(event.target.value) / 1000 });
  }

  
  handleChangeAddress = (address: string) => {
    this.setState({ address, addressSelected: false });
  }


  handleSelect = (address: string) => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => 
          getLatLng(results[0])
          .then((result: any) => {
            this.setState({
              markerLat: result.lat,
              markerLng: result.lng,
              addressSelected: true
            })
            this.drawMarker();
            this.updateMapCenter();
        })
      )
      .catch(error => console.error('Error', error));
  }


  handleMapClick = (event: any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
      addressSelected: true
    });
    this.updateMapCenter();
  }


  drawMapCircle = () => {
      return <Circle
      // required
      center={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      // required
      options={{
        strokeColor: '#248E63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#248E63',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: this.state.range,
        zIndex: 1
      }}
    />
  }

  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }

  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerPosition,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }

  render() {

    const { formSubmitted, address, addressSelected, mapZoom, range, rangeValue, mapLat, mapLng, markerLat, markerLng, alert_min_time, alert_max_frequency, invert } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2079)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>

            <div className="map-constraints-slider"> 
              <label htmlFor="range">{t(2096)}: {rangeValue + ' km'}</label>
              <input type="range" id="range" value={range} name="range" min="1000" max="200000" onChange={this.handleRangeChange}/>
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {addressSelected === true && address ? `${t(2393)}: ${address}` : t(2392)}
              </label>

                <GoogleMap
                  center={{ 
                    lat: mapLat, 
                    lng: mapLng 
                  }}
                  zoom={ mapZoom }
                  onClick={ (e) => this.handleMapClick(e) }
                >
                  { this.drawMapCircle() }
                  { this.drawMarker() }

                  <div className="map-constraints-container" />
                </GoogleMap>

                <span className='form-group__explanation'>
                  Longitude: {markerLng}, Latitude: {markerLat}<br/>
                  {t(105)} <Link to='/settings'>{t(106)}</Link>
                </span>
            </div>


            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ invert: !invert })}>
                <Switch switchState={invert} />
                <span className="label-title">
                  {t(2095)}
                </span>
              </label>
            </div>


            <div className="form-group">
              <label htmlFor="alert_min_time">{t(2091)}</label>
              <input 
                type="text" 
                name="alert_min_time"
                id="alert_min_time" 
                value={alert_min_time}
                onChange={e => this.setState({ alert_min_time: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2092)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_max_frequency">{t(2093)}</label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>


            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </div>
      </div>
      
    )
  }
}

export default connect(
  ({ auth, alert }: RootState) => ({ auth, alert }),
  { updateAlertGeoFence, fetchAlertsSettingsById }
)(AlertFormGeoFence);
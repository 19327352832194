import { GROUP } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchGroup = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: GROUP.FETCH });
  return fetch(`${API_ADDRESS}/group/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: GROUP.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: GROUP.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllGroups = (options: { limit: number, skip: number }) => fetchGroup({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: GROUP.FETCH_ALL_SUCCESS
});


export const fetchAllAuthGroups = (options: { limit: number, skip: number }) => fetchGroup({
  endpoint: 'auth/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: GROUP.FETCH_ALL_AUTH_SUCCESS
});


export const fetchGroupById = (id: string) => fetchGroup({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: GROUP.FETCH_ID_SUCCESS
});


export const updateGroupById = (group: any) => fetchGroup({
  endpoint: `${group.group_id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...group }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: GROUP.FETCH_UPDATE_SUCCESS
});


export const deleteGroupById = (id: string) => fetchGroup({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: GROUP.FETCH_DELETE_SUCCESS
});


export const addNewGroup = (group: any) => fetchGroup({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...group }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: GROUP.FETCH_ADD_SUCCESS
});
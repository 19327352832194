const routesColorCards = (routes: any) => {
  const markerColors = [ '#e91e63', '#9c27b0', '#3f51b5', '#03a9f4', '#009688', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#607d8b', '#f44336', '#9c27b0', '#3f51b5', '#4caf50', '#cddc39', '#ffc107', '#ff5722', '#9e9e9e', '#f72585', '#f3722c', '#7209b7', '#90be6d', '#4361ee', '#820263', '#291720', '#006ba6' ];
  let colorIndex = 0;
  let hwiCa = 0;

  routes.map((route: {hwi_ca: number, card: string}) => {
    if(route.hwi_ca !== null && route.hwi_ca !== undefined) {
      colorIndex++;
      hwiCa = route.hwi_ca;
    }
    route.card = markerColors[colorIndex];
    route.hwi_ca = hwiCa;
    return null;
  })

  return routes;
}


export default routesColorCards
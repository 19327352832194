import { Link } from 'react-router-dom'


interface ButtonProps {
  clickable: boolean, 
  classes: string, 
  handleOnClick: () => void, 
  disabled?: boolean, 
  title: string, 
  link: string,
}


const Button: React.FC<ButtonProps> = ({ clickable, classes, disabled, title, link, handleOnClick }) => {
  if(clickable) {
    return (
      <button 
        className={classes}
        onClick={handleOnClick}
        disabled={disabled}
      >
        {title}
      </button>
    );
  }
  return (
    <Link 
      to={link} 
      className={classes}
    >
      {title}
    </Link>
  )
}


export default Button
import Button from '../Button'
import alertTypes from '../../../helpers/alertTypesToString'
import alertTypesTitle from '../../../helpers/alertTypesTitle'
import alertIconFromNumber from '../../../helpers/alertIconFromNumber'
import moment from 'moment-timezone'
import t from '../../translation/translate'


interface AlertBoxGasPercentageProps {
  alert: any,
  settings: any,
  timezone: string,
}


const AlertBoxGasPercentage: React.FC<AlertBoxGasPercentageProps> = ({ alert, settings, timezone }) => {
  return (
    <div className="alert-box alert-box--gas-percentage">
      <div className="alert-box__icon">{alertIconFromNumber(parseInt(alert.type), 64, '#ffffff')}</div>
        <div className="alert-box__body">
        <h2 className="alert-box__title">{alertTypesTitle(parseInt(alert.type))}</h2>
          <div className="alert-box__date">{moment(alert.ts*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}</div>
          <div className="alert-box__values">
              <div className="alert-box__value value--true">
                {alert.value}      
                <span className="alert-box__units">
                  %
                </span>
                <div className="alert-box__subtitle">
                  {t(2087)}
                </div>
              </div>
              <div className="alert-box__value value--default">
                {parseInt(alert.minimum_gas_usage)}
                <span className="alert-box__units">
                  %
                </span>
                <div className="alert-box__subtitle">
                  {t(2086)}
                </div>
              </div>
            </div>
        </div>
      <div className="alert-box__footer">
        <Button classes='btn btn--primary btn--centered' link={`/vehicles/alerts/${alert.vehicle_id}/${alertTypes(parseInt(alert.type))}`} title={t(2077)} clickable={false} handleOnClick={() => console.log()} />
      </div>
    </div>
  )
}


export default AlertBoxGasPercentage
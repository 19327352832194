import objectIsEmpty from '../helpers/objectIsEmpty';
import ReportFunctions from './functionsReportCalculation';


function processFleetReportsConsumption(options: { reports: any, fuel_prices: any }) {

  const { reports, fuel_prices } = options;
  console.log('REPORT SUMMARIES:', reports);


  // set data loaded to true
  const dataLoaded = true;
  let consumptionProcessed = false;

  // data
  let VEHICLES_COUNT = 0;
  let TOTAL_DISTANCE_KM = 0;
  let TOTAL_TIME_S = 0;
  let TOTAL_DATA_KB = 0;
  let TOTAL_SAVINGS_CO2_KG = 0;
  let TOTAL_LPG_CONSUMPTION_L = 0;
  let TOTAL_CNG_CONSUMPTION_L = 0;
  let TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = 0;
  let TOTAL_PETROL_CONSUMPTION_L = 0;
  let TOTAL_DIESEL_CONSUMPTION_L = 0;
  let TOTAL_PETROL_SAVED_L = 0;
  let TOTAL_DIESEL_SAVED_L = 0;
  let ALTERNATIVE_DISTANCE_KM = 0;
  let DEFAULT_DISTANCE_KM = 0;
  let TOTAL_ALTERNATIVE_PERCENT = 0;
  let TOTAL_COST_DEFAULT_FUEL = 0;
  let TOTAL_COST_SAVED_DEFAULT_FUEL = 0;
  let TOTAL_COST_ALTERNATIVE_FUEL = 0;
  let TOTAL_ESTIMATED_COST = 0;
  let TOTAL_ESTIMATED_SAVINGS = 0;


  // check we have reports and fuel_prices
  if(reports && !objectIsEmpty(reports) && fuel_prices && !objectIsEmpty(fuel_prices)) {

    const { cost_petrol, cost_diesel, cost_lpg, cost_cng } = fuel_prices;
    const { vehicles_count, alternative_distance_km, default_distance_km, total_cng_consumption_l, total_data_kb, total_diesel_consumption_l, total_distance_km, total_lpg_consumption_l, total_petrol_consumption_l, total_petrol_saved_l, total_diesel_saved_l, total_savings_co2_kg, total_time_s } = reports;




    // check we have basic values
    if(vehicles_count) {
      VEHICLES_COUNT = Number(vehicles_count);
    } else {
      console.error('[ERROR]: missing vehicles_count');
    }

    if(total_distance_km) {
      TOTAL_DISTANCE_KM = Number(total_distance_km);
    } else {
      console.error('[ERROR]: missing total_distance_km');
    }

    if(total_time_s) {
      TOTAL_TIME_S = Number(total_time_s);
    } else {
      console.error('[ERROR]: missing total_time_s');
    }

    if(total_data_kb) {
      TOTAL_DATA_KB = Number(total_data_kb);
    } else {
      console.error('[ERROR]: missing total_data_kb');
    }

    if(total_savings_co2_kg) {
      TOTAL_SAVINGS_CO2_KG = Number(total_savings_co2_kg);
    } else {
      console.error('[ERROR]: missing total_savings_co2_kg');
    }

    if(total_lpg_consumption_l) {
      TOTAL_LPG_CONSUMPTION_L = Number(total_lpg_consumption_l);
    } else {
      console.error('[ERROR]: missing total_lpg_consumption_l');
    }

    if(total_cng_consumption_l) {
      TOTAL_CNG_CONSUMPTION_L = Number(total_cng_consumption_l);
    } else {
      console.error('[ERROR]: missing total_cng_consumption_l');
    }

    if(alternative_distance_km) {
      ALTERNATIVE_DISTANCE_KM = Number(alternative_distance_km);
    } else {
      console.error('[ERROR]: missing alternative_distance_km');
    }

    if(default_distance_km) {
      DEFAULT_DISTANCE_KM = Number(default_distance_km);
    } else {
      console.error('[ERROR]: missing default_distance_km');
    }

    if(total_petrol_consumption_l) {
      TOTAL_PETROL_CONSUMPTION_L = Number(total_petrol_consumption_l);
    } else {
      console.error('[ERROR]: missing total_petrol_consumption_l');
    }

    if(total_diesel_consumption_l) {
      TOTAL_DIESEL_CONSUMPTION_L = Number(total_diesel_consumption_l);
    } else {
      console.error('[ERROR]: missing total_diesel_consumption_l');
    }

    if(total_petrol_saved_l) {
      TOTAL_PETROL_SAVED_L = Number(total_petrol_saved_l);
    } else {
      console.error('[ERROR]: missing total_petrol_savings_l');
    }

    if(total_diesel_saved_l) {
      TOTAL_DIESEL_SAVED_L = Number(total_diesel_saved_l);
    } else {
      console.error('[ERROR]: missing total_diesel_savings_l');
    }

    // calculation
    TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = TOTAL_LPG_CONSUMPTION_L + TOTAL_CNG_CONSUMPTION_L;
    TOTAL_ALTERNATIVE_PERCENT = ReportFunctions.alternativeFuelPercent({ DEFAULT_DISTANCE_KM, ALTERNATIVE_DISTANCE_KM });

    // fuel costs
    TOTAL_COST_DEFAULT_FUEL = (TOTAL_DIESEL_CONSUMPTION_L * cost_diesel) + (TOTAL_PETROL_CONSUMPTION_L * cost_petrol);
    TOTAL_COST_SAVED_DEFAULT_FUEL = (TOTAL_PETROL_SAVED_L * cost_petrol) + (TOTAL_DIESEL_SAVED_L * cost_diesel)
    TOTAL_COST_ALTERNATIVE_FUEL = (TOTAL_LPG_CONSUMPTION_L * cost_lpg) + (TOTAL_CNG_CONSUMPTION_L * cost_cng);
    TOTAL_ESTIMATED_COST = TOTAL_COST_DEFAULT_FUEL + TOTAL_COST_ALTERNATIVE_FUEL;
    TOTAL_ESTIMATED_SAVINGS = TOTAL_COST_SAVED_DEFAULT_FUEL; //(TOTAL_COST_DEFAULT_FUEL + TOTAL_COST_SAVED_DEFAULT_FUEL) - TOTAL_ESTIMATED_COST;  // estimatedSavings = estimatedFuelCostWithoutAlternative - totalCost;

    // set the processed reports flag to true
    consumptionProcessed = true;
  } 




  // RETURN CALCULATED DATA
  return { dataLoaded, consumptionProcessed, VEHICLES_COUNT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_DATA_KB, TOTAL_SAVINGS_CO2_KG, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_PERCENT, TOTAL_ESTIMATED_COST, TOTAL_ESTIMATED_SAVINGS }

}


export default processFleetReportsConsumption
import React from 'react'
import logoStag from '../../img/logo-stag@2x.png'
import logoAc from '../../img/logo-ac@2x.png'
import { LogoStelematicFullNew } from './Logos'


const AuthInfoPanel: React.FC = () => {
    return (
      <div className="auth-info">
        <div className="auth-info--logo-wrapper">
          <LogoStelematicFullNew size={214} color="#ffffff" />
        </div>
        <div className="auth-info--logos">
          <img src={logoStag} width="111" alt="" className="logo-stag" />
          <img src={logoAc} width="55" alt="" className="logo-ac" />
        </div>
      </div>
    )
}


export default AuthInfoPanel
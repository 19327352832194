import { REPORT } from '../types'
import fetchStates from '../types/fetchStates'


interface ReportState {
  reports_new : [],
  reportsNewCount: number,
  reportsNewDate: [],
  reportsNewDateCount: number,
  reportsNewDateAll: [],
  reportsNewDatesAll: [],
}


const DEFAULT_REPORT: ReportState = {
  reports_new : [],
  reportsNewCount: 0,
  reportsNewDate: [],
  reportsNewDateCount: 0,
  reportsNewDateAll: [],
  reportsNewDatesAll: [],
}


const report = (state = DEFAULT_REPORT, action:any) => {
  switch(action.type) {
    case REPORT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case REPORT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case REPORT.FETCH_ALL_NEW_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reports_new: action.reports_new,
        reportsNewCount: parseInt(action.reportsNewCount, 10),
      };
    case REPORT.FETCH_ALL_NEW_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reports_new: action.reports_new,
        reportsNewCount: parseInt(action.reportsNewCount, 10),
      };
    case REPORT.FETCH_NEW_DATE_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reportsNewDate: action.reportsNewDate,
        reportsNewDateCount: parseInt(action.reportsNewDateCount, 10),
      };
    case REPORT.FETCH_NEW_DATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reportsNewDate: action.reportsNewDate,
        reportsNewDateCount: parseInt(action.reportsNewDateCount, 10),
      };
    case REPORT.FETCH_ALL_NEW_DATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reportsNewDateAll: action.reportsNewDateAll,
      };
    case REPORT.FETCH_ALL_NEW_DATES_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        reportsNewDatesAll: action.reportsNewDatesAll,
      };
    case REPORT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default report
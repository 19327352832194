import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { checkTokenValid, resetPassword } from '../../actions/auth'
import TemplateAuth from '../templates/TemplateAuth'
import { Link, Navigate } from 'react-router-dom'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import { IconVisibility, IconVisibilityOff } from '../partials/Icons'
import { IconBack } from '../partials/Icons'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import t from '../translation/translate'


interface AuthResetPasswordProps {
  router: {params: {token: string}, navigate: any},
  auth: {status: string, fields: Array<string>, authenticated: boolean, token: string},
  checkTokenValid: (options: { token: string }) => Promise<any>,
  resetPassword: (options: { password: string, confirm_password: string, token: string }) => Promise<any>,
}


interface AuthResetPasswordState {
  formSubmitted: boolean,
  token: string,
  password: string,
  confirm_password: string,
  passwordShow: boolean,
  passwordConfirmShow: boolean,
}


export class AuthResetPassword extends React.Component<AuthResetPasswordProps, AuthResetPasswordState> {


  state = {
    formSubmitted: false,
    token: '',
    password: '',
    confirm_password: '',
    passwordShow: false,
    passwordConfirmShow: false
  }


  componentDidMount(){
    console.log(this.props.router.params.token)
    this.props.checkTokenValid({ token: this.props.router.params.token })
    .then(() => {
      this.setState({
        token: this.props.auth.token,
      })
    });
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { password, confirm_password, token } = this.state;
    console.log(password, confirm_password, token)
    this.props.resetPassword({ password, confirm_password, token })
    .then(() => {
      console.log(1, this.props.auth.status)
      if(this.props.auth.status === fetchStates.success) {
        console.log(2)
        this.setState({ password: '', confirm_password: '' });
        this.props.router.navigate('/');
        console.log(3)
      }
      this.setState({ formSubmitted: false });
    });
  }


  render() {

    const { fields, status } = this.props.auth;
    const { formSubmitted, password, confirm_password, passwordShow, passwordConfirmShow } = this.state;

    return this.props.auth.authenticated ? <Navigate to="/" /> : (
      <TemplateAuth>
        <div className="auth-form">
          <div className="auth-form__header">
            <Link to="/" className="btn--circular">
              <IconBack color="#ffffff" />
            </Link>
          </div>
          <h1 className="auth-form__title">{t(134)}</h1>
          <p>Please enter your new password.</p>

          <form className='form' onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor='password'>
                {t(2042)}
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='password'
                type={ passwordShow === false ? 'password' : 'text' } 
                autoComplete='current-password'
                value={password}
                className={classnames('', { 'input-error': fields && fields.includes('password') })}
                onChange={e => this.setState({ password: e.target.value })}
              />
              <PasswordStrengthMeter password={password} />
            </div>
            <div className="form-group">
              <label htmlFor='confirm_password'>
                {t(2216)}
                <span className="password-reveal" onClick={e => this.setState({ passwordConfirmShow: !passwordConfirmShow })}>
                  { passwordConfirmShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input
                id='confirm_password'
                type={ passwordConfirmShow === false ? 'password' : 'text' } 
                autoComplete='current-password'
                value={confirm_password}
                className={classnames('', { 'input-error': fields && fields.includes('confirm_password') })}
                onChange={e => this.setState({ confirm_password: e.target.value })}
              />
            </div>
            <div className="form-group">
              <input 
                type='submit' 
                className='btn btn--primary btn--large' 
                value={t(134)} 
                disabled={formSubmitted && status === 'fetching' ? true : false} 
              />
            </div>
          </form>
        </div>
      </TemplateAuth>
    )
  }
}


export default withRouter(connect(
  ({ auth }: RootState) => ({ auth }),
  { checkTokenValid, resetPassword  }
)(AuthResetPassword));
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllInvoices, confirmTransactionCompleted } from '../../actions/invoice'
import TemplatePage from '../templates/TemplatePage'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Card from '../partials/Card'
import { Link } from 'react-router-dom'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import { IconVisibility } from '../partials/Icons'
import moment from 'moment-timezone'
import { Tooltip } from 'react-tooltip'
import Preloader from '../partials/Preloader'


interface BillingStatementsProps {
  router: {location: {pathname: string, search: string}, navigate: (to: string) => any},
  auth: any,
  invoice: any,
  fetchAllInvoices: (options: {limit: number, skip: number}) => Promise<any>,
  confirmTransactionCompleted: (id: number) => Promise<any>,
}


interface BillingStatementsState {
  currentPage: number,
  perPage: number,
  timezone: string,
}


export class BillingStatements extends React.Component<BillingStatementsProps, BillingStatementsState> {

  state = {
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parsed && parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/billing/statements?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/billing/statements?page=${currentPage}&perPage=${perPage}`)
    }
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage }); 
  }


  confirmTransactionCompleted = (invoice_id: number) => {
    this.props.confirmTransactionCompleted(invoice_id)
    .then(() => {
      if(this.props.invoice.status === fetchStates.success) {
        this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage }); 
      }
    })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.props.router.navigate(`/billing/statements?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/billing/statements?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/billing/statements?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    const { perPage, currentPage } = options
    this.props.fetchAllInvoices({ limit: perPage, skip: perPage * currentPage }); 
  }


  render() {

    const { invoices, invoicesCount, status } = this.props.invoice;
    const { currentPage, perPage, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(139)}
          time={false}
          tabnav='billing'
          tabnavId={0}
          animate
        >

          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', t(1166), t(1049), t(1050), t(1051), 'Added time', t(1052), t(1053), t(1041)]} 
              />

              <tbody className="table__body table__body--striped">
                { invoices && invoices.map((item: {invoice_id: number, temp_number: number, invoice_number: string, created: number, vehicles_number: number, added_time: number, payment_method: string, amount: number, currency: string, status: number}, index: number) => {
                  return (
                  <tr key={item.invoice_id}>

                    <td className="simple">
                      <Tooltip anchorId={`tooltip-imei-delete${index}`} />
                      <span id={`tooltip-imei-delete${index}`} data-tooltip-content={t(24)}>
                        <Link to={`/billing/statements/${item.invoice_id}`}>
                          <IconVisibility task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      {item.temp_number}
                    </td>
                    <td>
                      {item.invoice_number}
                    </td>
                    <td>
                      {moment(item.created).tz(timezone).format('DD/MM/YYYY')}
                    </td>
                    <td>
                      {item.vehicles_number}
                    </td>
                    <td>
                      {item.added_time} {t(152)}
                    </td>
                    <td>
                      {item.payment_method}
                    </td>
                    <td>
                      {item.amount} {item.currency}
                    </td>
                    <td>
                      {item.status === 1 && <span className="table__highlight table__highlight--success">{t(1043)}</span>}
                      {item.status !== 1 && item.status !== 3 && <span className="table__highlight table__highlight--warning">{t(1047)} ...</span>}
                      {item.status === 3 && <span className="table__highlight table__highlight--danger">{t(1045)}</span>}
                    </td>
                    {
                      /*billing_proforma &&
                        <Auth requiresRole={[1]}>
                          <td>
                            {item.status === 2 && <span className="btn btn--primary btn--table" onClick={() => this.confirmTransactionCompleted(item.invoice_id)}>
                              Confirm
                            </span>}
                          </td>
                        </Auth>
                        */
                    }

                  </tr>
                  )
                })
              }
              </tbody>

              <TableFooter 
                button={false}
                colSpan={9}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ invoicesCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ invoice, auth }: RootState) => ({ invoice, auth }),
  { fetchAllInvoices, confirmTransactionCompleted }
)(BillingStatements)))
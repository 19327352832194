import { INVOICE } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchInvoice = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: INVOICE.FETCH });
  return fetch(`${API_ADDRESS}/invoice/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: INVOICE.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: INVOICE.FETCH_ERROR, errors: [{ msg: error }]
        }));
}



export const fetchTemporaryInvoiceNumber = () => fetchInvoice({
  endpoint: 'temp_number',
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_TEMP_NUMBER_SUCCESS
});


export const fetchAllInvoices = (options: { limit: number, skip: number }) => fetchInvoice({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_ALL_SUCCESS
});


export const fetchVehiclesOverdue = (options: { limit: number, skip: number }) => fetchInvoice({
  endpoint: 'overdue/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_OVERDUE_SUCCESS
});

export const fetchVehiclesUnpaid = (options: { limit: number, skip: number }) => fetchInvoice({
  endpoint: 'unpaid/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_UNPAID_SUCCESS
});


export const fetchVehiclesOverdueAndUnpaid = (options: { limit: number, skip: number }) => fetchInvoice({
  endpoint: 'overdue-unpaid/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_OVERDUE_AND_UNPAID_SUCCESS
});


export const fetchInvoiceById = (id: number) => fetchInvoice({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_ID_SUCCESS
});


export const fetchAccountSettings = () => fetchInvoice({
  endpoint: 'account',
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_ACCOUNT_SETTINGS_SUCCESS
});


export const updateAccountSettings = (account: any) => fetchInvoice({
  endpoint: 'account',
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...account }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: INVOICE.FETCH_ACCOUNT_UPDATE_SUCCESS
});


export const fetchSelectedVehicles = (array: Array<string>) => fetchInvoice({
  endpoint: `selected-vehicles`,
  options: {
    method: 'POST',
    body: JSON.stringify({ selected_vehicles: array }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: INVOICE.FETCH_SELECTED_SUCCESS
});


export const confirmTransactionCompleted = (invoice_id:number) => fetchInvoice({
  endpoint: `confirm-transaction/${invoice_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: INVOICE.FETCH_CONFIRM_TRANSACTION_SUCCESS
});


export const calculateSubscribtionTotals = (options: { selected_vehicles: Array<string>, selected_term: number }) => fetchInvoice({
  endpoint: `calculate-subscribtion-totals`,
  options: {
    method: 'POST',
    body: JSON.stringify({ selected_vehicles: options.selected_vehicles, selected_term: options.selected_term }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: INVOICE.FETCH_CALCULATE_SUBSCRIBTION_TOTALS_SUCCESS
})


export const payVehiclesSelected = (options: { selected_term: number, selected_vehicles: Array<string>, temp_number: string, payment_method: string, sequential_number: number }) => fetchInvoice({
  endpoint: `pay-vehicles`,
  options: {
    method: 'POST',
    body: JSON.stringify({ selected_vehicles: options.selected_vehicles, selected_term: options.selected_term, temp_number: options.temp_number, payment_method: options.payment_method, sequential_number: options.sequential_number }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: INVOICE.PAY_SELECTED_SUCCESS
});
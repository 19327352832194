import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertGasPercentage, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormGasPercentageProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertGasPercentage: (options: {id: number, value: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormGasPercentageState {
  formSubmitted: boolean,
  minimum_gas_usage: string,
}


export class AlertFormGasPercentage extends React.Component<AlertFormGasPercentageProps, AlertFormGasPercentageState> {

  state = {
    formSubmitted: false,
    minimum_gas_usage: '50',
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {
      const { minimum_gas_usage } = this.props.alert.alertsSettings
      this.setState({ minimum_gas_usage: minimum_gas_usage !== 0 ? minimum_gas_usage : '50' })
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertGasPercentage({ id: this.props.modalId, value: this.state.minimum_gas_usage })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }

  render() {

    const { formSubmitted, minimum_gas_usage } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2435)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="minimum_gas_usage">{t(2440)}</label>
              <input 
                type="text" 
                name="minimum_gas_usage"
                id="minimum_gas_usage" 
                value={minimum_gas_usage}
                onChange={e => this.setState({ minimum_gas_usage: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2441)}
              </span>
            </div>

            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </div>
      </div>
      
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertGasPercentage, fetchAlertsSettingsById }
)(AlertFormGasPercentage);
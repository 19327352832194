import TabVehicleSettings from './tabnavs/TabVehicleSettings'
import TabVehicleOverview from './tabnavs/TabVehicleOverview'
import TabStations from './tabnavs/TabStations'
import TabVehicles from './tabnavs/TabVehicles'
import TabBilling from './tabnavs/TabBilling'
import TabSettings from './tabnavs/TabSettings'
import TabCheckpoints from './tabnavs/TabCheckpoints'


interface TabNavsProps {
  tabnav: string, 
  tabnavId: number,
  auth: any
}


const TabNavs: React.FC<TabNavsProps> = ({ tabnav, tabnavId, auth }) => {
  return (
    <>
      {tabnav && tabnav === 'vehicle-settings' && (
        <TabVehicleSettings tabnavId={tabnavId} auth={auth} />
      )}

      {tabnav && tabnav === 'vehicle-overview' && (
        <TabVehicleOverview tabnavId={tabnavId} auth={auth} />
      )}

      {tabnav && tabnav === 'vehicles' && (
        <TabVehicles />
      )}

      {tabnav && tabnav === 'stations' && (
        <TabStations />
      )}

      {tabnav && tabnav === 'billing' && (
        <TabBilling />
      )}

      {tabnav && tabnav === 'settings' && (
        <TabSettings />
      )}

      {tabnav && tabnav === 'checkpoints' && (
        <TabCheckpoints />
      )}
    </>
  )
}


export default TabNavs
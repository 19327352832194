import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllDistributorAccountGroups } from '../../actions/account'
import withAuthorization from '../auth/withAuthorization'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import { IconDelete, IconEdit } from '../partials/Icons'
import t from '../translation/translate'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface DistributorAccountsProps {
  router: {location: {pathname: string, search: string}, navigate: (to: string) => any},
  account: any,
  auth: any,
  fetchAllDistributorAccountGroups: (options:{limit: number, skip: number}) => Promise<any>,
}


interface DistributorAccountsState {
  distributor_account_groups: Array<string>,
  distributorAccountGroupsCount: number,
  currentPage: number,
  perPage: number,
  timezone: string
}


export class DistributorAccounts extends React.Component<DistributorAccountsProps, DistributorAccountsState> {

  state = {
    distributor_account_groups: [],
    distributorAccountGroupsCount: 0,
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/distributor-accounts?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/distributor-accounts?page=${currentPage}&perPage=${perPage}`)
    }
    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/distributor-accounts?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/distributor-accounts?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/distributor-accounts?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    return this.props.fetchAllDistributorAccountGroups({ limit: options.perPage, skip: options.perPage * options.currentPage })
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        const { distributor_account_groups, distributorAccountGroupsCount } = this.props.account
        this.setState({ distributor_account_groups, distributorAccountGroupsCount })
      }
    });
  }

  render() {

    const { status } = this.props.account
    const { currentPage, perPage, timezone, distributor_account_groups, distributorAccountGroupsCount } = this.state

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={true}
          buttonLink='/distributor-accounts/add' 
          buttonTitle={t(245)}
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          title={t(246)} 
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', t(1223), t(2020), '' ]} 
              />
                <tbody className="table__body table__body--striped">
                  { distributor_account_groups && distributor_account_groups.map((item: { distributor_group_id: string, distributor_group_title: string, registered: string }, index: number) => {
                      return (
                        <tr key={item.distributor_group_id}>
                          <td className="simple">
                            <Tooltip anchorId={`tooltip-group-edit${index}`} />
                            <span id={`tooltip-group-edit${index}`} data-tooltip-content={t(24)}>
                              <Link to={`/distributor-accounts/edit/${item.distributor_group_id}`}>
                                <IconEdit task button color="#ffffff" />
                              </Link>
                            </span>
                          </td>
                          <td>
                            {item.distributor_group_title}
                          </td>
                          <td>
                            {moment(item.registered).tz(timezone).format('DD/MM/YYYY')}
                          </td>
                          <td className="simple">
                            <Tooltip anchorId={`tooltip-group-delete${index}`} />
                            <span id={`tooltip-group-delete${index}`} data-tooltip-content={t(29)}>
                              <Link to={`/distributor-accounts/delete/${item.distributor_group_id}`}>
                                <IconDelete task button color="#ffffff" />
                              </Link>
                            </span>
                          </td>
                        </tr>
                      )
                    }) 
                  }
                </tbody>
              <TableFooter 
                button={false}
                colSpan={4}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ distributorAccountGroupsCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account, auth }: RootState) => ({ account, auth }),
  { fetchAllDistributorAccountGroups }
)(DistributorAccounts)))
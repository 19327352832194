import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllAccounts, fetchAccountVehicleUsage, fetchAllAccountVehicleUsage } from '../../actions/account'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import Preloader from '../partials/Preloader'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import fetchStates from '../../types/fetchStates'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import createAccountReportCSV from '../../functions/createAccountReportCSV'
import createAccountReportPDF from '../../functions/createAccountReportPDF'


interface ReportsAccountsProps {
  account: any,
  fetchAllAccounts: (options: {limit: number, skip: number}) => Promise<any>,
  fetchAccountVehicleUsage: (options: {limit: number, skip: number, date: string, account_id: string, filter_type: string }) => Promise<any>,
  fetchAllAccountVehicleUsage: (options: {date: string, account_id: string, filter_type: string }) => Promise<any>,
}


interface ReportsAccountsState {
  currentPage: number,
  perPage: number,
  accounts: Array<any>,
  account_id: string,
  filterDate: any,
  todayDate: any,
  defaultToDate: any,
  filter_type: string,
  dataLoaded: boolean,
  dataAccountsLoaded: boolean,
  accountVehicleUsage: Array<any>, 
  accountVehicleUsageCount: number
}


export class ReportsAccountsPage extends React.Component<ReportsAccountsProps, ReportsAccountsState> {

  state = {
    currentPage: 0,
    perPage: 10,
    accounts: [],
    account_id: '',
    filterDate:  moment().subtract(1, 'month').toDate(),
    todayDate: moment().toDate(),
    defaultToDate: moment().toDate(),
    filter_type: 'active',
    dataLoaded: true,
    dataAccountsLoaded: false,
    accountVehicleUsage: [], 
    accountVehicleUsageCount: 0
  }


  componentDidMount = async () => {
    await this.fetchAllAccounts();
  }


  fetchAllAccounts = async () => {
    await this.props.fetchAllAccounts({ limit: 1000, skip: 0 });
    if(this.props.account.status === fetchStates.success) {
      const { accounts } = this.props.account;
      const { currentPage, perPage, filterDate, filter_type } = this.state;
      if(accounts.length > 0) {
        const account_id = accounts[0].account_id;
        this.setState({ accounts, account_id, dataAccountsLoaded: true });
        this.reloadPage({ currentPage, perPage, filterDate, account_id, filter_type });
      } else {
        this.setState({ dataLoaded: true, dataAccountsLoaded: false });
      }
    }
  }


  handleSelectAccount = (account_id: string) => {
    const { perPage, filterDate, filter_type } = this.state;
    this.setState({ account_id, currentPage: 0 }, () => this.reloadPage({ perPage, currentPage: 0, filterDate, account_id, filter_type }));
  }


  handleFilterDate = (filterDate: Date) => {
    this.setState({ filterDate });
    const { perPage, currentPage, account_id, filter_type } = this.state;
    this.reloadPage({ perPage, currentPage, filterDate: moment(filterDate).format('YYYY-MM-DD'), account_id, filter_type });
  }


  handleFilterType = (filter_type: string) => {
    this.setState({ filter_type });
    const { perPage, filterDate, account_id } = this.state;
    this.setState({ account_id, currentPage: 0 }, () => this.reloadPage({ perPage, currentPage: 0, filterDate, account_id, filter_type }));
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { filterDate, account_id, filter_type } = this.state;
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, filterDate, account_id, filter_type });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { perPage, filterDate, account_id, filter_type } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage, filterDate, account_id, filter_type });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { perPage, filterDate, account_id, filter_type } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage, filterDate, account_id, filter_type });
  }


  reloadPage = async (options: { perPage: number, currentPage: number, filterDate: any, account_id: string, filter_type: string }) => {
    const { perPage, currentPage, filterDate, account_id, filter_type } = options;
    this.setState({ dataLoaded: false });
    await this.props.fetchAccountVehicleUsage({ limit: perPage, skip: perPage * currentPage, date: moment(filterDate).format('YYYY-MM'), account_id, filter_type });
    if(this.props.account.status === fetchStates.success) {
      const { accountVehicleUsage, accountVehicleUsageCount } = this.props.account;
      return this.setState({ accountVehicleUsage, accountVehicleUsageCount, dataLoaded: true });
    }
    this.setState({ dataLoaded: true });
  }


  handleDownloadCSV = async () => {
    const { filterDate, accounts, account_id, filter_type } = this.state;
    const date = moment(filterDate).format('YYYY-MM')
    await this.props.fetchAllAccountVehicleUsage({ date, account_id, filter_type });
    const account = accounts.filter((account: any) => account.account_id === account_id);
    if(this.props.account.status === fetchStates.success) {
      const { accountVehicleUsageReport } = this.props.account;
      createAccountReportCSV({ data: accountVehicleUsageReport, date, account: account[0], filter_type });
    }
  }


  handleDownloadPDF = async () => {
    const { filterDate, accounts, account_id, filter_type } = this.state;
    const date = moment(filterDate).format('YYYY-MM')
    await this.props.fetchAllAccountVehicleUsage({ date , account_id, filter_type });
    const account = accounts.filter((account: any) => account.account_id === account_id);
    if(this.props.account.status === fetchStates.success) {
      const { accountVehicleUsageReport } = this.props.account;
      createAccountReportPDF({ data: accountVehicleUsageReport, date, account: account[0], filter_type });
    }
  }


  render() {

    const { currentPage, perPage, accounts, account_id, filter_type, filterDate, todayDate, dataAccountsLoaded, accountVehicleUsage, accountVehicleUsageCount, dataLoaded } = this.state;
    const { status } = this.props.account;

    return (
      <TemplatePage>
        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`Vehicles Report for Account`}
          time={false}
          animate
          button={true}
          buttonTitle='PDF'
          buttonClickable={true}
          buttonHandleOnClick={() => this.handleDownloadPDF()}
          buttonLink=''
          secondButton={true}
          secondButtonTitle='CSV'
          secondButtonClickable={true}
          secondButtonHandleOnClick={() => this.handleDownloadCSV()}
          secondButtonLink=''
        >

          <form className="form">

          {
            dataAccountsLoaded ? (
              <div className="filter">
                <span className="filter__filter-group">
                  <span className="filter__filter-text">Filter by account</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleSelectAccount(e.target.value)}
                      value={account_id}
                    >
                      { 
                        accounts && accounts.map((account: {account_id: number, company_name: string}) => <option key={account.account_id} value={account.account_id}>{account.company_name}</option>)
                      }
                    </select>
                  </span>
                </span>
                <span className="filter__filter-group">
                  <span className="filter__filter-text">Month</span>
                  <span className="filter_filter-select">
                    <DatePicker
                    selected={filterDate}
                    selectsStart
                    onChange={this.handleFilterDate}
                    dateFormat='yyyy-MM'
                    maxDate={todayDate}
                    minDate={new Date(2021, 0, 1, 0, 0, 0, 0)}
                    required={true}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    />
                  </span>
                </span>
                <span className="filter__filter-group">
                  <span className="filter__filter-text">Filter type</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleFilterType(e.target.value)}
                      value={ filter_type }
                    >
                      <option key="active" value="active">Active</option>
                      <option key="registered" value="registered">Registered</option>
                    </select>
                  </span>
                </span>
              </div>
            ) : (
              <Preloader type="" />
            )
          }
          </form>


          <div className="table-wrapper">

            {
              dataLoaded ? (
                <table className="table">
                  <TableHeader 
                    columns={['Date', 'Custom ID', 'IMEI']} 
                  />

                  <tbody className="table__body table__body--striped">
                    { accountVehicleUsage && accountVehicleUsage.map((item: { custom_id: string, module_imei: string, dt_stamp_to: string }) => {
                      return (
                      <tr key={item.module_imei}>
                        <td>
                          { item.dt_stamp_to }
                        </td>
                        <td>
                          { item.custom_id }
                        </td>
                        <td>
                          { item.module_imei }
                        </td>
                      </tr>
                      )
                    })
                  }
                  </tbody>

                  <TableFooter 
                    button={false}
                    colSpan={2}
                    currentPage={ currentPage }
                    perPage={ perPage }
                    itemsCount={ accountVehicleUsageCount }
                    prevPage={() => this.prevPage(currentPage - 1)}
                    nextPage={() => this.nextPage(currentPage + 1)}
                    updatePagination={this.updatePagination}
                  />
                </table>
              ) : (
                <Preloader type="" />
              )
            }
          </div>


        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account }: RootState) => ({ account }),
  { fetchAllAccounts, fetchAccountVehicleUsage, fetchAllAccountVehicleUsage }
)(ReportsAccountsPage)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAccountById, updateAccountById } from '../../actions/account'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import { TIMEZONE, GOOGLE_MAPS_API_KEY, DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import { GoogleMap, Marker } from '@react-google-maps/api'
import markerPosition from'../../img/marker-position.png'
import classnames from 'classnames'
import momentTimezones from '../../helpers/momentTimezones'
import Switch from '../partials/Switch'
import languageTranslations from '../../helpers/languagesTranslations'
import Preloader from '../partials/Preloader'


interface AccountsEditProps {
  router: {  params: { id: string }, navigate: (to: string) => any },
  auth: any,
  account: any,
  updateAccountById: any,
  fetchAccountById: (id: string) => Promise<any>,
}


interface AccountsEditState {
  currentPage: number,
  perPage: number,
  default_timezone: string,
  formSubmitted: boolean,
  lat: number,
  lng: number,
  zoom: number,
  account_email: string, 
  account_id: string, 
  account_language: string, 
  account_unit_cost_option_1: string,
  account_unit_cost_option_2: string,
  account_unit_cost_option_3: string,
  city: string, 
  company_name: string, 
  country: string, 
  currency: string, 
  postcode: string, 
  street_name: string, 
  timezone: string, 
  vat: string,
  google_maps_api_key: string,
  billing_enabled: boolean,
  billing_paypal: boolean,
  billing_stripe: boolean,
  billing_proforma: boolean,
  billing_selfmanaged: boolean,
  payment_per_vehicle: boolean,
  billing_currency: string,
  billing_client_abb: string,
  subscribe_payment_limit_days: string,
  refuel_sensitivity: string,
  exploitation_h_day: string,
  exploitation_satuday: boolean,
  exploitation_sunday: boolean,
  hide_lock_feature: boolean
}


export class AccountsEdit extends React.Component<AccountsEditProps, AccountsEditState> {

  state = {
    currentPage: 0,
    perPage: 10,
    default_timezone: TIMEZONE,
    formSubmitted: false,
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    zoom: DEFAULT_ZOOM,
    account_email: '', 
    account_id: '', 
    account_language: '', 
    account_unit_cost_option_1: '0',
    account_unit_cost_option_2: '0',
    account_unit_cost_option_3: '0',
    city: '', 
    company_name: '', 
    country: '', 
    currency: '', 
    postcode: '', 
    street_name: '', 
    timezone: '', 
    vat: '',
    google_maps_api_key: GOOGLE_MAPS_API_KEY,
    billing_enabled: false,
    billing_paypal: false,
    billing_stripe: false,
    billing_proforma: false,
    billing_selfmanaged: false,
    payment_per_vehicle: false,
    billing_currency: '',
    billing_client_abb: '',
    subscribe_payment_limit_days: '14',
    refuel_sensitivity: '2',
    exploitation_h_day: '8',
    exploitation_satuday: false,
    exploitation_sunday: false,
    hide_lock_feature: false
  }


  componentDidMount() {
    // timezone
    this.setState({ default_timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.getAccount()
  }

  getAccount = () => {
    this.props.fetchAccountById(this.props.router.params.id)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        const { account_email, account_id, account_language, account_unit_cost_option_1, account_unit_cost_option_2, account_unit_cost_option_3, city, company_name, country, currency, postcode, street_name, timezone, vat, google_maps_api_key, billing_enabled, billing_paypal, billing_stripe, billing_proforma, billing_selfmanaged, default_lat, default_lng, payment_per_vehicle, billing_currency, billing_client_abb, subscribe_payment_limit_days, refuel_sensitivity, exploitation_h_day, exploitation_satuday, exploitation_sunday, hide_lock_feature } = this.props.account.account
        this.setState({
          account_email: account_email !== null ? account_email : '',
          account_id: account_id !== null ? account_id : '',
          account_language: account_language !== null ? account_language : '',
          account_unit_cost_option_1: account_unit_cost_option_1 !== null ? account_unit_cost_option_1 : '0',
          account_unit_cost_option_2: account_unit_cost_option_2 !== null ? account_unit_cost_option_2 : '0',
          account_unit_cost_option_3: account_unit_cost_option_3 !== null ? account_unit_cost_option_3 : '0',
          city: city !== null ? city : '',
          company_name: company_name !== null ? company_name : '',
          country: country !== null ? country : '',
          currency: currency !== null ? currency : '',
          postcode: postcode !== null ? postcode : '',
          street_name: street_name !== null ? street_name : '',
          timezone: timezone !== null ? timezone : this.state.default_timezone,
          vat: vat !== null ? vat : '',
          google_maps_api_key: google_maps_api_key !== null ? google_maps_api_key : '',
          billing_enabled: billing_enabled !== null ? billing_enabled : false,
          billing_paypal: billing_paypal !== null ? billing_paypal : false,
          billing_stripe: billing_stripe !== null ? billing_stripe : false,
          billing_proforma: billing_proforma !== null ? billing_proforma : false,
          billing_selfmanaged: billing_selfmanaged !== null ? billing_selfmanaged : false,
          lat: default_lat !== null ? default_lat : false,
          lng: default_lng !== null ? default_lng : false,
          payment_per_vehicle: payment_per_vehicle !== null ? payment_per_vehicle : false,
          billing_currency: billing_currency !== null ? billing_currency : '',
          billing_client_abb: billing_client_abb !== null ? billing_client_abb : '',
          subscribe_payment_limit_days: subscribe_payment_limit_days !== null ? subscribe_payment_limit_days : '14',
          refuel_sensitivity: refuel_sensitivity !== null ? refuel_sensitivity : '2',
          exploitation_h_day: exploitation_h_day !== null ? exploitation_h_day : 8,
          exploitation_satuday: exploitation_satuday !== null ? exploitation_satuday : false,
          exploitation_sunday: exploitation_sunday !== null ? exploitation_sunday : false,
          hide_lock_feature: hide_lock_feature !== null ? hide_lock_feature : false,
        })
      }
    })
  }


  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.lat,
        lng: this.state.lng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerPosition,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }


  handleMapClick = (e: any) => {
    this.setState({
      lat: parseFloat(e.latLng.lat()),
      lng: parseFloat(e.latLng.lng())
    });
    this.drawMarker();
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { account_email, account_id, account_language, account_unit_cost_option_1, account_unit_cost_option_2, account_unit_cost_option_3, city, company_name, country, currency, postcode, street_name, timezone, vat, google_maps_api_key, billing_enabled, billing_paypal, billing_stripe, billing_proforma, billing_selfmanaged, lat, lng, payment_per_vehicle, billing_currency, billing_client_abb, subscribe_payment_limit_days, refuel_sensitivity, exploitation_h_day, exploitation_satuday, exploitation_sunday, hide_lock_feature } = this.state

    const account = { account_email, account_id, account_language, account_unit_cost_option_1, account_unit_cost_option_2, account_unit_cost_option_3, city, company_name, country, currency, postcode, street_name, timezone, vat, google_maps_api_key, billing_enabled, billing_paypal, billing_stripe, billing_proforma, billing_selfmanaged, default_lat: lat, default_lng: lng, payment_per_vehicle, billing_currency, billing_client_abb, subscribe_payment_limit_days, refuel_sensitivity, exploitation_h_day, exploitation_satuday, exploitation_sunday, hide_lock_feature }

    this.props.updateAccountById({ account })
    .then(() => {
      this.setState({ formSubmitted: false })
    })
  }


  render() {


    const { fields, status } = this.props.account;
    const { account_email, account_language, account_unit_cost_option_1, account_unit_cost_option_2, account_unit_cost_option_3, city, company_name, country, currency, postcode, street_name, timezone, vat, google_maps_api_key, billing_enabled, billing_proforma, formSubmitted, lat, lng, payment_per_vehicle, zoom, billing_currency, billing_client_abb, subscribe_payment_limit_days, refuel_sensitivity, exploitation_h_day, exploitation_satuday, exploitation_sunday, hide_lock_feature } = this.state;


    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(190)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-section">
              <h2 className="form-group__title">{t(193)}</h2>

              <div className="form-group">
                <label htmlFor="company_name">
                  {t(155)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="company_name"
                  id="company_name" 
                  value={ company_name }
                  onChange={e => this.setState({ company_name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('company_name') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="street_name">
                  {t(157)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="street_name"
                  id="street_name" 
                  value={ street_name }
                  onChange={e => this.setState({ street_name: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('street_name') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="city">
                  {t(158)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="city"
                  id="city" 
                  value={ city }
                  onChange={e => this.setState({ city: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('city') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="postcode">
                  {t(159)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="postcode"
                  id="postcode"
                  value={ postcode }
                  onChange={e => this.setState({ postcode: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('postcode') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="country">
                  {t(160)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="country"
                  id="country" 
                  value={ country }
                  onChange={e => this.setState({ country: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('country') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="vat">
                  {t(161)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="vat"
                  id="vat" 
                  value={ vat }
                  onChange={e => this.setState({ vat: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('vat') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="account_email">
                  {t(1072)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="account_email"
                  id="account_email" 
                  value={ account_email }
                  onChange={e => this.setState({ account_email: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('account_email') })}
                />
              </div>

            </div>
            <div className="form-section">
              <h2 className="form-group__title">{t(196)}</h2>

              <div className="form-group">
                <label htmlFor="currency">
                  {t(1011)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="currency"
                  id="currency" 
                  value={currency}
                  onChange={e => this.setState({ currency: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('currency') })}
                />
              </div>

              <div className="form-group">
                <label htmlFor="timezone">
                  {t(2402)} ({t(116)})
                </label>
                <select 
                  name="timezone" 
                  value={timezone}
                  onChange={e => this.setState({ timezone: e.target.value })}
                > 
                  {momentTimezones.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="account_language">
                  {t(2414)} ({t(116)})
                </label>
                <select 
                  name="account_language" 
                  value={account_language}
                  onChange={e => this.setState({ account_language: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('account_language') })}
                >
                  {
                    languageTranslations.map(language => (
                      <option key={language.shortcut} value={language.shortcut}>{language.title}</option>
                    ))
                  }
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="google_maps_api_key">
                  {t(1082)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="google_maps_api_key"
                  id="google_maps_api_key" 
                  value={google_maps_api_key}
                  onChange={e => this.setState({ google_maps_api_key: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('google_maps_api_key') })}
                />
              </div>

            </div>
            <div className="form-section">
              <h2 className="form-group__title">{t(1005)}</h2>
              <div className="form-group">
                  <div className="google-map google-map__settings">

                      <GoogleMap
                        center={{ lat, lng }}
                        zoom={ zoom }
                        onClick={ e => this.handleMapClick(e) }
                      >
                        {this.drawMarker()}
                        <div className="map-constraints-container" />
                      </GoogleMap>


                      <span className='form-group__explanation'>
                      {t(2178)}: {lng}, {t(2177)}: {lat}
                    </span>
                  </div>
                </div>
            </div>


            <div className="form-section">
              <h2 className="form-group__title">{t(1216)}</h2>
              <div className="form-group">
                <label htmlFor="refuel_sensitivity">
                  {t(1217)} ({t(116)})
                </label>
                <select 
                  name="refuel_sensitivity" 
                  value={refuel_sensitivity}
                  onChange={e => this.setState({ refuel_sensitivity: e.target.value })}
                > 
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
            </div>




            <div className="form-section">
              <h2 className="form-group__title">{t(197)}</h2>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ billing_enabled: !billing_enabled })}>
                <Switch switchState={billing_enabled} />
                <span className="label-title">{t(1090)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ billing_proforma: !billing_proforma, billing_selfmanaged: false })}>
                  <Switch switchState={billing_proforma} disabled={billing_enabled === false} />
                  <span className="label-title">{t(1093)}</span>
                </label>
                <div className="form-group__explanation">{t(1094)}</div>
              </div>

              {
                /*
                <div className="form-group__switch form-group__switch--large">
                  <label onClick={e => this.setState({ billing_selfmanaged: !billing_selfmanaged, billing_paypal: false, billing_stripe: false, billing_proforma: false })}>
                    <Switch switchState={billing_selfmanaged} disabled={billing_enabled === false} />
                    <span className="label-title">{t(1095)}</span>
                  </label>
                  <div className="form-group__explanation">{t(1096)}</div>
                </div>
                */
              }


              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ payment_per_vehicle: !payment_per_vehicle })}>
                <Switch switchState={payment_per_vehicle} />
                <span className="label-title">{t(1129)}</span>
                </label>
                <div className="form-group__explanation">{t(1130)}</div>
              </div>

              <div className="form-group">
                <label htmlFor="subscribe_initial_days">
                  {t(1219)} ({t(116)})
                </label>
                <input 
                  type="text" 
                  name="subscribe_payment_limit_days"
                  id="subscribe_payment_limit_days" 
                  value={subscribe_payment_limit_days}
                  onChange={e => this.setState({ subscribe_payment_limit_days: e.target.value })}
                  className={classnames('', { 'input-error': fields && fields.includes('subscribe_payment_limit_days') })}
                />
              </div>


            </div>
            <div className="form-section">
              <h2 className="form-group__title">{t(198)}</h2>


                <div className="form-group">
                  <label htmlFor="account_unit_cost_option_1">
                    {t(1220)} ({t(116)})
                  </label>
                  <input 
                    type="text" 
                    name="account_unit_cost_option_1"
                    id="account_unit_cost_option_1" 
                    value={ account_unit_cost_option_1 }
                    onChange={e => this.setState({ account_unit_cost_option_1: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('account_unit_cost_option_1') })}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="account_unit_cost_option_2">
                    {t(1221)} ({t(116)})
                  </label>
                  <input 
                    type="text" 
                    name="account_unit_cost_option_2"
                    id="account_unit_cost_option_2" 
                    value={ account_unit_cost_option_2 }
                    onChange={e => this.setState({ account_unit_cost_option_2: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('account_unit_cost_option_2') })}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="account_unit_cost_option_3">
                    {t(1222)} ({t(116)})
                  </label>
                  <input 
                    type="text" 
                    name="account_unit_cost_option_3"
                    id="account_unit_cost_option_3" 
                    value={ account_unit_cost_option_3 }
                    onChange={e => this.setState({ account_unit_cost_option_3: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('account_unit_cost_option_3') })}
                  />
                </div>

                  <div className="form-group">
                    <label htmlFor="billing_currency">
                      {t(1158)} ({t(116)})
                    </label>
                    <input 
                      type="text" 
                      name="billing_currency"
                      id="billing_currency" 
                      value={ billing_currency }
                      onChange={e => this.setState({ billing_currency: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('billing_currency') })}
                    />
                  </div>


                  <div className="form-group">
                    <label htmlFor="billing_client_abb">
                      {t(1165)} ({t(116)})
                    </label>
                    <input 
                      type="text" 
                      name="billing_client_abb"
                      id="billing_client_abb" 
                      value={ billing_client_abb }
                      onChange={e => this.setState({ billing_client_abb: e.target.value })}
                      className={classnames('', { 'input-error': fields && fields.includes('billing_client_abb') })}
                    />
                  </div>

            </div>



            <div className="form-section">
              <h2 className="form-group__title">Exploitation settings</h2>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ exploitation_satuday: !exploitation_satuday })}>
                  <Switch switchState={ exploitation_satuday } />
                  <span className="label-title">Including Saturdays</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ exploitation_sunday: !exploitation_sunday })}>
                  <Switch switchState={ exploitation_sunday } />
                  <span className="label-title">Including Sundays</span>
                </label>
              </div>


              <div className="form-group">
                  <label htmlFor="exploitation_h_day">
                    Exploitation hours per day
                  </label>
                  <input 
                    type="text" 
                    name="exploitation_h_day"
                    id="exploitation_h_day" 
                    value={ exploitation_h_day }
                    onChange={e => this.setState({ exploitation_h_day: e.target.value })}
                    className={classnames('', { 'input-error': fields && fields.includes('exploitation_h_day') })}
                  />
                </div>

            </div>




            <div className="form-section">
              <h2 className="form-group__title">Miscellaneous settings</h2>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ hide_lock_feature: !hide_lock_feature })}>
                  <Switch switchState={ hide_lock_feature } />
                  <span className="label-title">Hide lock feature</span>
                </label>
              </div>
            </div>




            <div className="form-group">
              <input 
                type="submit" 
                value={t(192)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account, auth }: RootState) => ({ account, auth }),
  { fetchAccountById, updateAccountById }
)(AccountsEdit)))
import React from 'react'
import TemplateAuth from '../templates/TemplateAuth'
import { Link } from 'react-router-dom'
import { IconBack } from '../partials/Icons'
import t from '../translation/translate'
import { ADDRESS_LINE1, ADDRESS_LINE2, ADDRESS_LINE3, ADDRESS_LINE4, ADDRESS_LINE5 } from '../../config';


const AuthSupport: React.FC = () => {
  return (
    <TemplateAuth>
      <div className="auth-form">
        <div className="auth-form__header">
          <Link to="/" className='btn--circular'>
            <IconBack color="#ffffff" />
          </Link>
        </div>
        <h1 className="auth-form__title">{t(15)}</h1>
        <div className="csection csection__large">
          <h3 className="csection-title">{t(1000)}</h3>
          {t(1001)}:
          <ul>
            <li>{t(2019)}: <a href="tel:0048857438117">{ ADDRESS_LINE5 }</a></li>
            <li>{t(2041)}: <a href="mailto:michal.balazi@ac.com.pl">michal.balazi@ac.com.pl</a></li>
          </ul>
        </div>

        <div className="csection csection__large">
          <h3 className="csection-title">{t(1002)}</h3>
          <ul>
            <li>{ ADDRESS_LINE1 }</li>
            <li>{ ADDRESS_LINE2 }</li>
            <li>{ ADDRESS_LINE3 }</li>
            <li>{ ADDRESS_LINE4 }</li>
          </ul>
        </div>
      </div>
    </TemplateAuth>
  )
}


export default AuthSupport
import { combineReducers } from 'redux'
import account from './account'
import alert from './alert'
import auth from './auth'
import error from './error'
import checkpoint from './checkpoint'
import communication from './communication'
import competitor from './competitor'
import group from './group'
import imei from './imei'
import invoice from './invoice'
import moduleState from './module'
import person from './person'
import refuel from './refuel'
import report from './report'
import state from './state'
import station from './station'
import worker from './worker'
import rfid from './rfid'
import vehicle from './vehicle'
import dialogflow from './dialogflow'


export const rootReducer = combineReducers({
  account,
  alert,
  auth,
  checkpoint,
  communication,
  competitor,
  group,
  imei,
  invoice,
  moduleState,
  person,
  refuel,
  report,
  rfid,
  state,
  station,
  vehicle,
  worker,
  error,
  dialogflow
})

export type RootState = ReturnType<typeof rootReducer>
import { COMMUNICATION } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchCommunication = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: COMMUNICATION.FETCH });
  return fetch(`${API_ADDRESS}/communication/${options.endpoint}`, options.options)
  .then(response => response.json())
  .then(json => {
    if (json.errors !== undefined) {
      dispatch({ type: COMMUNICATION.FETCH_ERROR, ...json });
      json && json.errors && json.errors.map((item: {msg: number}) => {
        if(item.msg) {
          
          if(item.msg === prevMessage && json.repeat === false) {
            return null
          } else {
            error(t(item.msg));
          }
          prevMessage = item.msg
          setTimeout(() => prevMessage = 0, 10000);
        }
        return null
      })
    } else {
      dispatch({ type: options.SUCCESS_TYPE, ...json });
      json && json.success && json.success.map((item: {msg: number}) => {
        if(item.msg) {
          
          if(item.msg === prevMessage && json.repeat === false) {
            return null
          } else {
            success(t(item.msg));
          }
          prevMessage = item.msg
          setTimeout(() => prevMessage = 0, 10000);
        }
        return null
      })
    }
  })
        .catch(error => dispatch({
          type: COMMUNICATION.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllCommunication = (options: { limit: number, skip: number }) => fetchCommunication({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_ALL_SUCCESS
});


export const fetchCommunicationSearch = (options: { search: string, limit: number, skip: number }) => fetchCommunication({
  endpoint: 'search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_SEARCH_SUCCESS
});


export const fetchAllFilteredCommunication = (options: { limit: number, skip: number, reason: string, date: string, imei: string }) => fetchCommunication({
  endpoint: 'filtered/?limit=' + options.limit + '&skip=' + options.skip + '&reason=' + options.reason + '&date=' + options.date + '&imei=' + options.imei,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_ALL_SUCCESS
});


export const fetchAllRawCommunication = (options: { limit: number, skip: number, reason: string, date: any, imei: string }) => fetchCommunication({
  endpoint: 'raw/?limit=' + options.limit + '&skip=' + options.skip + '&reason=' + options.reason + '&date=' + options.date + '&imei=' + options.imei,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_ALL_SUCCESS
});


export const fetchActiveImeis = (date:string) => fetchCommunication({
  endpoint: 'active-imeis/?date=' + date,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_ACTIVE_IMEIS_SUCCESS
});



export const fetchRawImeis = (date:string) => fetchCommunication({
  endpoint: 'raw-imeis/?date=' + date,
  options: { credentials: 'include' },
  SUCCESS_TYPE: COMMUNICATION.FETCH_ACTIVE_IMEIS_SUCCESS
});
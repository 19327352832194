import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { fetchCompetitorById, updateCompetitor } from '../../actions/competitor'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_RANGE, TIMEZONE } from '../../config'
import classnames from 'classnames'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import { Link } from 'react-router-dom'
import markerCompetitor from'../../img/marker-competitor.png'


interface StationsUnautorizedEditProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  competitor: any,
  checkAuth: () => Promise<any>,
  fetchCompetitorById: (id: number) => Promise<any>,
  updateCompetitor: (competitor: any) => Promise<any>,
}


interface StationsUnautorizedEditState {
  id: string,
  custom_id: string,
  company: string,
  visited: string,
  registered: string,
  range: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
  formSubmitted: boolean,
  timezone: string,
}


export class StationsUnautorizedEdit extends React.Component<StationsUnautorizedEditProps, StationsUnautorizedEditState> {

  state = {
    id: '',
    custom_id: '',
    company: '',
    visited: '',
    registered: '',
    range: DEFAULT_RANGE,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    formSubmitted: false,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.props.checkAuth()
    this.props.fetchCompetitorById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.competitor.status === fetchStates.success) {
        const { station_id, custom_id, company, visited, registered, zoom, range, lat, lng } = this.props.competitor.competitor;
        this.setState({
          id: station_id,
          custom_id: custom_id,
          company: company,
          visited: visited,
          registered: registered,
          mapLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          mapLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          markerLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          markerLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM,
          range: range !== undefined && range !== null ? range : DEFAULT_RANGE
        })
      }
      this.drawMapCircle()
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    if(this.state.custom_id === '') {
      this.props.competitor.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2313)
    }
    const competitor = { 
      id: this.state.id, 
      custom_id: this.state.custom_id, 
      company: this.state.company,
      zoom: this.state.mapZoom,
      range: this.state.range,
      lat: this.state.markerLat,
      lng: this.state.markerLng 
    } 
    this.props.updateCompetitor(competitor)
    .then(() => {
      this.setState({ formSubmitted: false })
    })
  }


  handleRangeChange = (event: any) => {
    this.setState({ range: event.target.value });
  }


  handleMapClick = (event: any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
    });
    this.updateMapCenter();
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }

  
  drawMapCircle = () => {
    return <Circle
    // required
    center={{
      lat: this.state.markerLat,
      lng: this.state.markerLng
    }}
    // required
    options={{
      strokeColor: '#E34B5F',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#E34B5F',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: this.state.range,
      zIndex: 1
    }}
  />
  }


  render() {

    const { status, fields } = this.props.competitor;
    const { custom_id, company, visited, registered, range, mapLat, mapLng, mapZoom, markerLat, markerLng, formSubmitted, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(114)} 
          button={true} 
          buttonTitle={t(191)}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2028)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">
                {t(2029)}
              </label>
              <input 
                type="text" 
                name="company"
                id="company" 
                value={company}
                onChange={e => this.setState({ company: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="latitude">
                {t(2030)}
              </label>
              <input 
                type="text" 
                name="latitude"
                id="latitude" 
                value={markerLat}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="longitude">
                {t(2031)}
              </label>
              <input 
                type="text" 
                name="longitude"
                id="longitude" 
                value={markerLng}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {t(2032)}
              </label>

                <GoogleMap
                  center={{ lat: mapLat, lng: mapLng }}
                  zoom={ mapZoom }
                  onClick={ (e) => this.handleMapClick(e) }
                >
                  { this.drawMapCircle() }

                  <Marker
                    position={{
                      lat: markerLat,
                      lng: markerLng
                    }}
                    icon={
                      // @ts-expect-error
                      new window.google.maps.MarkerImage(
                        markerCompetitor,
                        null, /* size is determined at runtime */
                        null, /* origin is 0,0 */
                        null, /* anchor is bottom center of the scaled image */
                        new window.google.maps.Size(48, 48)
                      )
                    }
                  >
                  </Marker>

                  <div className="map-constraints-container" />
                </GoogleMap>

                <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>

                <div className="map-constraints-slider"> 
                  <label htmlFor="range">{range + ' m'}</label>
                  <input type="range" id="range" value={range} name="range" min="1" max="100" onChange={this.handleRangeChange}/>
                </div>
            </div>

            <div className="form-group">
              <label htmlFor="visited">
                {t(2033)}
              </label>
              <input 
                type="text" 
                name="visited"
                id="visited" 
                value={visited}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="registered">
                {t(2034)}
              </label>
              <input 
                type="text" 
                name="registered"
                id="registered" 
                value={moment(registered).tz(timezone).format('DD/MM/YYYY')}
                disabled
              />
            </div>
            <div className="form-group">
              <input 
                type="submit" 
                value={t(115)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ competitor, auth }: RootState) => ({ competitor, auth }),
  { fetchCompetitorById, updateCompetitor, checkAuth }
)(StationsUnautorizedEdit)))
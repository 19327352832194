import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { addNewRfid } from '../../actions/rfid'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface RfidAddProps {
  router: { navigate: (to: string) => any },
  rfid: any,
  addNewRfid: (rfid: { rfid_number: string, custom_id: string, label: string }) => Promise<any>,
}


interface RfidAddState {
  rfid_number: string,
  custom_id: string,
  label: string,
  formSubmitted: boolean,
}


export class RfidAdd extends React.Component<RfidAddProps, RfidAddState> {

  state = {
    rfid_number: '',
    custom_id: '',
    label: '',
    formSubmitted: false,
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { rfid_number, custom_id, label } = this.state
    const rfid = { rfid_number, custom_id, label }
    this.props.addNewRfid(rfid)
    .then(() => {
      if(this.props.rfid.status === fetchStates.success) {
        this.props.router.navigate('/rfid');
      }
    })
    this.setState({ formSubmitted: false })
  }


  render() {

    const { fields, status } = this.props.rfid;
    const { rfid_number, custom_id, label, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={true}
          title={t(214)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="rfid_number">
                {t(1142)}
              </label>
              <input 
                type="text" 
                name="rfid_number"
                id="rfid_number" 
                value={rfid_number}
                onChange={e => this.setState({ rfid_number: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('rfid_number') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2105)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="label">
                {t(2410)}
              </label>
              <input 
                type="text" 
                name="label"
                id="label" 
                value={label}
                onChange={e => this.setState({ label: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('label') })}
              />
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value={t(214)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ rfid }: RootState) => ({ rfid }),
  { addNewRfid }
)(RfidAdd)))
import React from 'react'
import { connect } from 'react-redux'
import Preloader from './Preloader'
import { updateTablesOptions } from '../../actions/person'
import { checkAuth } from '../../actions/auth'
import fetchStates from '../../types/fetchStates'
import t from '../translation/translate'
import Switch from './Switch'


interface ModalTableVehiclesProps {
  auth: any,
  person: any,
  showModal: (show: boolean) => any,
  checkAuth: () => Promise<any>,
  updateTablesOptions: (options:{ tables_options: {} }) => Promise<any>,
}


interface ModalTableVehiclesState {
  formSubmitted: boolean,
  tables_options: {
    vehicles: {
      module_imei: boolean,
      buffer: boolean,
      paired: boolean,
      auto_lock: boolean,
      fuel_lvl: boolean,
      igl: boolean,
      ignition: boolean,
      refuels_number: boolean,
      ignore_payment: boolean,
      calendar_lock: boolean,
      unlocked: boolean,
      connected: boolean,
      last_connected: boolean,
      custom_id: boolean,
      gas_usage: boolean,
      compatible: boolean,
      locked_times: boolean,
      payment_date: boolean,
      visited_competitor: boolean,
      controller_connected: boolean,
      module_version: boolean,
      data_usage: boolean,
      group_id: boolean,
    }
  }
}


export class ModalTableVehicles extends React.Component<ModalTableVehiclesProps, ModalTableVehiclesState> {


  state = {
    formSubmitted: false,
    tables_options: {
      vehicles: {
        module_imei: false,
        buffer: false,
        paired: false,
        auto_lock: false,
        fuel_lvl: false,
        igl: false,
        ignition: false,
        refuels_number: false,
        ignore_payment: false,
        calendar_lock: false,
        unlocked: false,
        connected: false,
        last_connected: false,
        custom_id: false,
        gas_usage: false,
        compatible: false,
        locked_times: false,
        payment_date: false,
        visited_competitor: false,
        controller_connected: false,
        module_version: false,
        data_usage: false,
        group_id: false
      }
    }
  }


  componentDidMount() {
    this.getTablesOptions()
  }


  getTablesOptions = () => {
    this.props.checkAuth()
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        const { tables_options } = this.props.auth
        this.setState({ tables_options })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateTablesOptions({ tables_options: this.state.tables_options })
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.setState({ formSubmitted: false })
        this.props.showModal(false)
      }
      this.setState({ formSubmitted: false })
    })
  }


  render() {

    const { tables_options, formSubmitted } = this.state
    const { status } = this.props.auth


    return (
      <div className="modal__content">

        { (!status || status === fetchStates.fetching) && <Preloader type="fullscreen" /> }

        <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>

          <div className="modal__title">
            <h2>{t(183)}</h2>
          </div>

          <div className="modal__body">
          <div className="form-group__switch form-group__switch--samll">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, module_imei: !tables_options.vehicles.module_imei } } })}>
                <Switch switchState={tables_options.vehicles.module_imei} />
                <span className="label-title">Serial NO</span>
              </label>
            </div>


            <div className="form-group__switch form-group__switch--samll">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, custom_id: !tables_options.vehicles.custom_id } } })}>
                <Switch switchState={tables_options.vehicles.custom_id} />
                <span className="label-title">ID</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--samll">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, group_id: !tables_options.vehicles.group_id } } })}>
                <Switch switchState={tables_options.vehicles.group_id} />
                <span className="label-title">{t(225)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, payment_date: !tables_options.vehicles.payment_date } } })}>
                <Switch switchState={tables_options.vehicles.payment_date} />
                <span className="label-title">{t(2404)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, ignore_payment: !tables_options.vehicles.ignore_payment } } })}>
                <Switch switchState={tables_options.vehicles.ignore_payment} />
                <span className="label-title">{t(1153)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, ignition: !tables_options.vehicles.ignition } } })}>
                <Switch switchState={tables_options.vehicles.ignition} />
                <span className="label-title">{t(2397)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, gas_usage: !tables_options.vehicles.gas_usage } } })}>
                <Switch switchState={tables_options.vehicles.gas_usage} />
                <span className="label-title">{t(2061)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, fuel_lvl: !tables_options.vehicles.fuel_lvl } } })}>
                <Switch switchState={tables_options.vehicles.fuel_lvl} />
                <span className="label-title">{t(2062)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, igl: !tables_options.vehicles.igl } } })}>
                <Switch switchState={tables_options.vehicles.igl} />
                <span className="label-title">IGL</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, refuels_number: !tables_options.vehicles.refuels_number } } })}>
                <Switch switchState={tables_options.vehicles.refuels_number} />
                <span className="label-title">{t(2018)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, calendar_lock: !tables_options.vehicles.calendar_lock } } })}>
                <Switch switchState={tables_options.vehicles.calendar_lock} />
                <span className="label-title">{t(2513)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, unlocked: !tables_options.vehicles.unlocked } } })}>
                <Switch switchState={tables_options.vehicles.unlocked} />
                <span className="label-title">{t(2064)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, locked_times: !tables_options.vehicles.locked_times } } })}>
                <Switch switchState={tables_options.vehicles.locked_times} />
                <span className="label-title">{t(2065)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, visited_competitor: !tables_options.vehicles.visited_competitor } } })}>
                <Switch switchState={tables_options.vehicles.visited_competitor} />
                <span className="label-title">{t(2066)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, paired: !tables_options.vehicles.paired } } })}>
                <Switch switchState={tables_options.vehicles.paired} />
                <span className="label-title">{t(2067)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, auto_lock: !tables_options.vehicles.auto_lock } } })}>
                <Switch switchState={tables_options.vehicles.auto_lock} />
                <span className="label-title">{t(2416)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, last_connected: !tables_options.vehicles.last_connected } } })}>
                <Switch switchState={tables_options.vehicles.last_connected} />
                <span className="label-title">{t(2068)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, buffer: !tables_options.vehicles.buffer } } })}>
                <Switch switchState={tables_options.vehicles.buffer} />
                <span className="label-title">{t(2069)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, compatible: !tables_options.vehicles.compatible } } })}>
                <Switch switchState={tables_options.vehicles.compatible} />
                <span className="label-title">{t(2395)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, connected: !tables_options.vehicles.connected } } })}>
                <Switch switchState={tables_options.vehicles.connected} />
                <span className="label-title">{t(2396)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, module_version: !tables_options.vehicles.module_version } } })}>
                <Switch switchState={tables_options.vehicles.module_version} />
                <span className="label-title">{t(2412)}</span>
              </label>
            </div>

            <div className="form-group__switch form-group__switch--small">
              <label onClick={e => this.setState({ tables_options: { ...tables_options, vehicles: { ...tables_options.vehicles, data_usage: !tables_options.vehicles.data_usage } } })}>
                <Switch switchState={tables_options.vehicles.data_usage} />
                <span className="label-title">{t(2420)}</span>
              </label>
            </div>

          </div>

          <div className="modal__footer">
            <input 
              type="submit" 
              value={t(122)} 
              className="btn btn--primary btn--margin-right"
              disabled={ formSubmitted ? true : false }
            />
          </div>

        </form>
      </div>
      
    )
  }
}


export default connect(
  ({ auth, person }) => ({ auth, person }),
  { checkAuth, updateTablesOptions }
)(ModalTableVehicles);
import { jsPDF } from 'jspdf'
import { ADDRESS_LINE1, ADDRESS_LINE2, ADDRESS_LINE3, ADDRESS_LINE4, ADDRESS_LINE5, REGON, NIP } from '../config'
import logoImage from'../img/stagtelematic-logo.png'
import moment from 'moment-timezone'


function createInvoicePDF(pdfData: any) {

  // variables
  const invoice_number = pdfData.invoice_number;
  const temp_number = pdfData.temp_number;
  const invoice_status = pdfData.invoice_status;
  const created = pdfData.created;
  const payment_method = pdfData.payment_method; 
  const amount = pdfData.amount; 
  const currency = pdfData.currency;
  const vehicles_number = pdfData.vehicles_number;
  const company_name = pdfData.company_name;
  const street_name = pdfData.street_name;
  const city = pdfData.city;
  const country = pdfData.country;
  const postcode = pdfData.postcode;
  const vat = pdfData.vat;
  const timezone = pdfData.timezone;


  // document settings
  const unit = "mm";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);


  // building up the report
  const title = 'STAGTELEMATIC'


  // table defaults
  const largeTitle = 20;
  const smallTitle = 8;
  const text = 11;
  const textLineHeight = 6; 

  // add logo
  var img = new Image();
  img.src = logoImage;


  // title
  doc.setTextColor(0, 0, 0);
  doc.setDrawColor(0);
  doc.setLineWidth(0.2);
  doc.setFontSize(largeTitle);
  //doc.setFontStyle('normal');
  doc.addImage(img, 'PNG', 15, 14, 8, 8);
  doc.text(title, 27, 20);

  
  // small titles
  doc.setFontSize(smallTitle);
  doc.setTextColor(150, 151, 174);
  doc.text('FROM', 17, 50);
  doc.text('DETAILS', 117, 50);
  doc.text('BILL TO', 17, 120);
  doc.text('PAYMENT METHOD', 117, 120);


  // text settings
  doc.setFontSize(text);
  doc.setTextColor(51, 56, 90);


  // from text
  const fromYPosition = 57;
  const fromXPosition = 17;
  doc.text(ADDRESS_LINE1, fromXPosition, fromYPosition);
  doc.text(ADDRESS_LINE2, fromXPosition, fromYPosition + textLineHeight * 1);
  doc.text(ADDRESS_LINE3, fromXPosition, fromYPosition + textLineHeight * 2);
  doc.text(ADDRESS_LINE4, fromXPosition, fromYPosition + textLineHeight * 3);
  doc.text(ADDRESS_LINE5, fromXPosition, fromYPosition + textLineHeight * 4);
  doc.text(`REGON: ${REGON}`, fromXPosition, fromYPosition + textLineHeight * 5);
  doc.text(`NIP: ${NIP}`, fromXPosition, fromYPosition + textLineHeight * 6);


  // details text
  const detailsYPosition = 57;
  const detailsXPosition = 117;
  doc.text(`Temporary Invoice Number: ${temp_number}`, detailsXPosition, detailsYPosition);
  doc.text(`Invoice Number: ${invoice_number}`, detailsXPosition, detailsYPosition + textLineHeight * 1);
  doc.text(`Invoice Date: ${moment(created).tz(timezone).format('DD/MM/YYYY')}`, detailsXPosition, detailsYPosition + textLineHeight * 2);
  doc.text(`Invoice Status: ${invoice_status === '1' ? 'Paid' : ''}${invoice_status === '2' ? 'Processing...' : ''}${invoice_status === '3' ? 'Unpaid': ''}`, detailsXPosition, detailsYPosition + textLineHeight * 3);
  
  
  // bill to text
  const toYPosition = 127;
  const toXPosition = 17;
  doc.text(company_name, toXPosition, toYPosition);
  doc.text(street_name, toXPosition, toYPosition + textLineHeight * 1);
  doc.text(`${city} ${postcode}`, toXPosition, toYPosition + textLineHeight * 2);
  doc.text(country , toXPosition, toYPosition + textLineHeight * 3);
  doc.text(vat, toXPosition, toYPosition + textLineHeight * 4);


  // payment method text
  const paymentYPosition = 127;
  const paymentXPosition = 117;
  doc.text(payment_method, paymentXPosition, paymentYPosition);


  // invoice body table header
  doc.setFillColor(44, 191, 252);
  doc.setTextColor(255, 255, 255);
  const tableRowsWidth = 70;
  const rectXPostion = 17;
  const rectYPosition = 187;
  const rectWidth = 180;
  const rectHeight = 7;
  doc.rect(rectXPostion, rectYPosition, rectWidth, rectHeight, 'F');
  const theadYPosition = 192;
  const theadXPosition = 20;
  doc.text('Item', theadXPosition, theadYPosition);
  doc.text('Vehicle Quantity', theadXPosition + tableRowsWidth * 1, theadYPosition);
  doc.text('Amount', theadXPosition + tableRowsWidth * 2, theadYPosition);


  // invoice body item
  doc.setTextColor(51, 56, 90);
  const itemYPosition = 202;
  const itemXPosition = 20;
  doc.text('Basic', itemXPosition, itemYPosition);
  doc.text(vehicles_number, itemXPosition + tableRowsWidth * 1, itemYPosition);
  doc.text(`${currency} ${amount}`, itemXPosition + tableRowsWidth * 2, itemYPosition);


  // invoice totals
  const totalsYPosition = 250;
  const totalsXPosition = 17;
  doc.text(`Subtotal: ${currency} ${amount}`, totalsXPosition, totalsYPosition);
  doc.text(`Total: ${currency} ${amount}`, totalsXPosition, totalsYPosition + textLineHeight * 1);
  doc.text(`Paid: ${currency} ${amount}`, totalsXPosition, totalsYPosition + textLineHeight * 2);
  
  
  // save document
  doc.save('stelematics-invoice-' + invoice_number + '.pdf')

}


export default createInvoicePDF
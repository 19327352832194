import moment from 'moment-timezone'
import calculateObjectReportSavingsNew from './calculateObjectReportSavingsNew'


function createReportCSV(data: any, vehicle: any) {

  // date
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();
  const todayDate = `${yyyy}-${mm}-${dd}`;

  const report = calculateReport(data);
  const csv = JSON2CSV(report);
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = 'stelematics-reports-' + vehicle.custom_id + '-' + todayDate + '.csv';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  function calculateReport(data: any) {

    const report: any = []
    data.map((item: any) => {

      const objectReportSavings = calculateObjectReportSavingsNew(item);

      const row = {
        date: item.d_stamp_to,
        distance_km: objectReportSavings.TOTAL_DISTANCE_KM,
        distance_default_fuel_km: objectReportSavings.DEFAULT_DISTANCE_KM,
        distance_alternative_fuel_km: objectReportSavings.ALTERNATIVE_DISTANCE_KM,
        drive_time_s: objectReportSavings.TOTAL_TIME_S,
        drive_time_formatted: moment("1900-01-01 00:00:00").add(objectReportSavings.TOTAL_TIME_S, 'seconds').format("HH:mm:ss"),
        alternative_fuel_consumption_l: objectReportSavings.TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L,
        default_fuel_consumption_l: objectReportSavings.TOTAL_DEFAULT_FUEL_CONSUMPTION_L,
        alternative_fuel_cost: objectReportSavings.ALTERNATIVE_FUEL_COST, 
        default_fuel_cost: objectReportSavings.DEFAULT_FUEL_COST,
        cost_without_alternative_fuel: objectReportSavings.DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE,
        percent_distance_driven_on_gas: objectReportSavings.TOTAL_ALTERNATIVE_PERCENT,
        estimated_savings: objectReportSavings.ESTIMATED_SAVINGS,
        potential_savings: objectReportSavings.ESTIMATED_POTENTIAL_SAVINGS,
        co2_savings_kg: objectReportSavings.TOTAL_SAVINGS_CO2_KG,
        data_kb: objectReportSavings.TOTAL_DATA_KB,
        cost_petrol: item.cost_petrol,
        cost_diesel: item.cost_diesel,
        cost_lpg: item.cost_lpg,
        cost_cng: item.cost_cng
      }
      report.push(row)
      return report
    })

    return report
  }


  function JSON2CSV(objArray: any) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // title to CSV
    const keys = Object.keys(array[0]);
    str += keys.join(',') + '\r\n';


    // data lines to CSV
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index of Object.keys(array[i])) {
        line += array[i][index] + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;

  }

}


export default createReportCSV
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { checkAuth } from '../../actions/auth'
import { addNewStation } from '../../actions/station'
import TemplatePage from '../templates/TemplatePage'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_RANGE } from '../../config'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Card from '../partials/Card'
import { Link } from 'react-router-dom'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import queryString from 'query-string'
import markerStation from'../../img/marker-station.png'
import Preloader from '../partials/Preloader'
import classnames from 'classnames';


interface StationsAuthorizedAddProps {
  router: {location: {search: string}, navigate: (to: string) => any },
  station: any,
  auth: any,
  checkAuth: () => Promise<void>,
  addNewStation: (options:{station: any}) => Promise<void>,
}


interface StationsAuthorizedAddState {
  address: string,
  addressSelected: boolean,
  custom_id: string,
  phone: string,
  formSubmitted: boolean,
  range: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
}


export class StationsAuthorizedAdd extends React.Component<StationsAuthorizedAddProps, StationsAuthorizedAddState> {

  state = {
    address: '',
    addressSelected: false,
    custom_id: '',
    phone: '',
    formSubmitted: false,
    range: DEFAULT_RANGE,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
  }


  componentDidMount () {
    this.props.checkAuth()
    .then(() => {
      const { lat, lng, zoom } = this.props.auth;
      // get the defaults from the URL query if it exists
      const parsed = queryString.parse(this.props.router.location.search)
      if(parsed !== undefined && parsed !== null) {
        this.setState({
          mapLat: parsed.lat && parsed.lat !== null ? parseFloat(parsed.lat.toString()) : DEFAULT_LAT,
          mapLng: parsed.lng && parsed.lng !== null ? parseFloat(parsed.lng.toString()) : DEFAULT_LNG,
          markerLat: parsed.lat && parsed.lat !== null ? parseFloat(parsed.lat.toString()) : DEFAULT_LAT,
          markerLng: parsed.lng && parsed.lng !== null ? parseFloat(parsed.lng.toString()) : DEFAULT_LNG,
          mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM
        })
      } else {
        this.setState({
          mapLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          mapLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM
        });
      }
      this.drawMapCircle();
    });
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { custom_id, phone, mapZoom, range, mapLat, mapLng } = this.state
    const station = { 
      custom_id,
      phone,
      zoom: mapZoom, 
      range, 
      lat: mapLat, 
      lng: mapLng 
    } 
    this.props.addNewStation({ station })
    .then(() => {
      if(this.props.station.status === fetchStates.success) {
        this.props.router.navigate('/stations');
      }
      this.setState({ formSubmitted: false })
    })
  }


  handleRangeChange = (event: any) => {
    this.setState({ range: event.target.value });
  }


  handleChangeAddress = (address: string) => {
    this.setState({ address, addressSelected: false });
  }


  handleSelect = (address: string) => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => 
          getLatLng(results[0])
          .then((result: any) => {
            this.setState({
              markerLat: result.lat,
              markerLng: result.lng,
              addressSelected: true
            })
            this.drawMarker();
            this.updateMapCenter();
        })
      )
      .catch(error => console.error('Error', error));
  }


  handleMapClick = (event: any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
      addressSelected: true
    });
    this.updateMapCenter();
  }


  drawMapCircle = () => {
      return <Circle
      // required
      center={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      // required
      options={{
        strokeColor: '#248E63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#248E63',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: this.state.range,
        zIndex: 1
      }}
    />
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerStation,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }


  render() {

    const { fields, status } = this.props.station;
    const { address, addressSelected, custom_id, phone, formSubmitted, mapZoom, range, mapLat, mapLng, markerLat, markerLng } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(100)} 
          buttonBack={true} 
          buttonLink='/stations/authorized' 
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2017)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">
                {t(2019)}
              </label>
              <input 
                type="text" 
                name="phone"
                id="phone" 
                value={ phone }
                onChange={ e => this.setState({ phone: e.target.value }) }
                className={ classnames('', { 'input-error': fields && fields.includes('phone') }) }
              />
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {t(1002)}
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={ this.handleChangeAddress }
                onSelect={ this.handleSelect }
              >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: t(1018),
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>{t(2327)}</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            </PlacesAutocomplete>
            </div>

            <div className="map-constraints-slider"> 
              <label htmlFor="range">{t(2035)}: {range + ' m'}</label>
              <input type="range" id="range" value={range} name="range" min="1" max="100" onChange={this.handleRangeChange}/>
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(31)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ addressSelected === false || formSubmitted ? true : false }
              />
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {addressSelected === true && address ? `${t(2037)}: ${address}` : t(2036)}
              </label>

                <GoogleMap
                  center={{ 
                    lat: mapLat, 
                    lng: mapLng
                  }}
                  zoom={ mapZoom }
                  onClick={ (e) => this.handleMapClick(e) }
                >
                  { this.drawMapCircle() }
                  { this.drawMarker() }

                  <div className="map-constraints-container" />
                </GoogleMap>

                <span className='form-group__explanation'>
                  {t(2178)}: {markerLng}, {t(2177)}: {markerLat}<br/>
                  {t(105)} <Link to='/settings'>{t(106)}</Link>
                </span>

            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ station, auth }: RootState) => ({ station, auth }),
  { addNewStation, checkAuth }
)(StationsAuthorizedAdd)))
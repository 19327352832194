import { Link } from 'react-router-dom'
import { IconBack } from './Icons'


interface ButtonBackProps {
  backLink: any, 
  navigate: any
}


const ButtonBack: React.FC<ButtonBackProps> = ({ backLink, navigate }) => {
  if(navigate) {
    return (
      <div>
        <Link to="/" onClick={() => navigate(-1)} className='btn--circular'>
          <IconBack color="#ffffff" />
        </Link>
      </div>
    );
  } 
  if(backLink) {
    return (
    <Link to={backLink} className='btn--circular'>
      <IconBack color="#ffffff" />
    </Link>
    )
  }
  return null;
}


export default ButtonBack
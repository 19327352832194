import { REPORT } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';

let prevMessage = 0;

const fetchReport = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: REPORT.FETCH });
  return fetch(`${API_ADDRESS}/report/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: REPORT.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: REPORT.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllNewReports = (options: { limit: number, skip: number, from: string, to: string, group_id: string }) => fetchReport({
  endpoint: `new/?limit=${options.limit}&skip=${options.skip}&from=${options.from}&to=${options.to}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_ALL_NEW_SUCCESS
});


export const fetchAllNewReportsSearch = (options: { search: string, limit: number, skip: number }) => fetchReport({
  endpoint: 'new/search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_ALL_NEW_SEARCH_SUCCESS
});


export const fetchNewReportsDate = (options: { limit: number, skip: number, date: string, group_id: string }) => fetchReport({
  endpoint: 'new/date/?limit=' + options.limit + '&skip=' + options.skip + '&date=' + options.date + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_NEW_DATE_SUCCESS
});


export const fetchNewReportsDateSearch = (options: { limit: number, skip: number, date: string, search: string, group_id: string }) => fetchReport({
  endpoint: 'new/date/search/?limit=' + options.limit + '&skip=' + options.skip + '&date=' + options.date + '&search=' + options.search + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_NEW_DATE_SEARCH_SUCCESS
});


export const fetchNewReportsDateAll = (options: { date: string, group_id: string }) => fetchReport({
  endpoint: `new/date/all/?date=${options.date}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_ALL_NEW_DATE_SUCCESS
});


export const fetchNewReportsDatesAll = (options: { from: string, to: string, group_id: string }) => fetchReport({
  endpoint: `new/dates/all/?from=${options.from}&to=${options.to}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REPORT.FETCH_ALL_NEW_DATES_SUCCESS
});
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchNewReportsDate, fetchNewReportsDateAll, fetchNewReportsDateSearch } from '../../actions/report'
import { fetchAllAuthGroups } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { Link } from 'react-router-dom'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import queryString from 'query-string'
import createNewDayReportPDF from '../../functions/createNewDayReportPDF'
import createNewDayReportCSV from '../../functions/createNewDayReportCSV'
import EstimationNote from '../partials/EstimationNote'
import { IconMap } from '../partials/Icons'
import calculateObjectReportSavingsNew from '../../functions/calculateObjectReportSavingsNew'
import { Tooltip } from 'react-tooltip'
import Preloader from '../partials/Preloader'


interface TemplateProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  auth: any,
  group: any,
  report: any,
  fetchAllAuthGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchNewReportsDate: (options: { limit: number, skip: number, date: string, group_id: string }) => Promise<any>,
  fetchNewReportsDateSearch: (options: { limit: number, skip: number, date: string, search: string, group_id: string }) => Promise<any>,
  fetchNewReportsDateAll: (options:{ date: string, group_id: string }) => Promise<any>,
}


interface TemplateState {
  reportsNewDate: any, 
  reportsNewDateCount: number,
  currentPage: number,
  perPage: number,
  date: any,
  search: string,
  authGroups: any,
  group_id: string,
}


export class Template extends React.Component<TemplateProps, TemplateState> {

  state = {
    reportsNewDate: [], 
    reportsNewDateCount: 0,
    currentPage: 0,
    perPage: 10,
    date: moment().tz(this.props.auth.timezone ? this.props.auth.timezone : this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE).subtract(1, "days").format('YYYY-MM-DD'),
    search: '',
    authGroups: [],
    group_id: 'all',
  }


  componentDidMount() {
    // set the defaults
    let { currentPage, perPage, date, search, group_id } = this.state;
    // fetch groups list
    this.props.fetchAllAuthGroups({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, authGroups } = this.props.group
      if(status === fetchStates.success) {
        this.setState({ authGroups })
      }
    })
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search);
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.group !== null && parsed.group !== undefined) {
      group_id = parsed.group.toString()
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/reports/date?date=${this.state.date}?page=${currentPage}&perPage=${perPage}&group=${group_id}`)
      }
    } else {
      this.props.router.navigate(`/reports/date?date=${this.state.date}?page=${currentPage}&perPage=${perPage}&group=${group_id}`)
    }
    if(parsed.date !== null && parsed.date !== undefined) {
      date = parsed.date.toString()
    }
    if(parsed.search !== null && parsed.search !== undefined) {
      search = parsed.search.toString()
    }
    this.setState({ perPage, currentPage, date, search, group_id })
    if(search !== '') {
      return this.handleSearch(search)
    }
    this.reloadPage({ perPage, currentPage, date, group_id });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, date: this.state.date, group_id: this.state.group_id })
    this.props.router.navigate(`/reports/date?date=${this.state.date}&page=0&perPage=${e.target.value}&group=${this.state.group_id}`)
  }


  prevPage = (prevPage:number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ perPage: this.state.perPage, currentPage: prevPage, date: this.state.date, group_id: this.state.group_id })
    this.props.router.navigate(`/reports/date?date=${this.state.date}&page=${prevPage}&perPage=${this.state.perPage}&group=${this.state.group_id}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ perPage: this.state.perPage, currentPage: nextPage, date: this.state.date, group_id: this.state.group_id })
    this.props.router.navigate(`/reports/date?date=${this.state.date}&page=${nextPage}&perPage=${this.state.perPage}&group=${this.state.group_id}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, date: string, group_id: string }) => {
    const { perPage, currentPage, date, group_id} = options
    if(this.state.search === '') {
      return this.props.fetchNewReportsDate({ limit: perPage, skip: perPage * currentPage, date, group_id })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reportsNewDate, reportsNewDateCount } = this.props.report
          this.setState({ reportsNewDate, reportsNewDateCount })
        }
      })
    } else {
      return this.props.fetchNewReportsDateSearch({ limit: perPage, skip: perPage * currentPage, date, search: this.state.search, group_id: this.state.group_id })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reportsNewDate, reportsNewDateCount } = this.props.report
          this.setState({ reportsNewDate, reportsNewDateCount })
        }
      })
    }
  }


  handleSelectGroup = (group_id: string) => {
    const { perPage, date } = this.state
    this.setState({ group_id })
    this.reloadPage({ currentPage: 0, perPage, date, group_id })
    this.props.router.navigate(`/reports/date?page=${this.state.currentPage}&perPage=${this.state.perPage}&date=${moment(date).format('YYYY-MM-DD')}&group=${group_id}`)
  }


  handleSearch = (search: string) => {
    if(search !== '') {
      this.props.router.navigate(`/reports/date?date=${this.state.date}&search=${search}`)
      return this.props.fetchNewReportsDateSearch({ search: search, date: this.state.date, limit: 10, skip: 0, group_id: this.state.group_id })
      .then(() => {
        if(this.props.report.status === fetchStates.success) {
          const { reportsNewDate, reportsNewDateCount } = this.props.report
          this.setState({ reportsNewDate, reportsNewDateCount })
        }
      })
    }
  }


  handleSearchClose = () => {
    this.props.router.navigate(`/reports/date?date=${this.state.date}&group=${this.state.group_id}`)
    this.setState({ search: '', currentPage: 0 });
    this.props.fetchNewReportsDate({ limit: this.state.perPage, skip: 0, date: this.state.date, group_id: this.state.group_id })
    .then(() => {
      if(this.props.report.status === fetchStates.success) {
        const { reportsNewDate, reportsNewDateCount } = this.props.report
        this.setState({ reportsNewDate, reportsNewDateCount })
      }
    })
  }


  handleDownloadPDF = () => {
    const { date, group_id } = this.state
    this.props.fetchNewReportsDateAll({ date, group_id })
    .then(() => {
      const data = this.props.report.reportsNewDateAll
      if(data.length > 0) {
        createNewDayReportPDF({ data, currency: this.props.auth.currency, from: date, to: date })
      }
    });
  }


  handleDownloadCSV = () => {
    const { date, group_id } = this.state
    this.props.fetchNewReportsDateAll({ date, group_id })
    .then(() => {
      const data = this.props.report.reportsNewDateAll
      if(data.length > 0) {
        createNewDayReportCSV({ data, from: date, to: date })
      }
    });
  }


  render() {

    const { currency } = this.props.auth;
    const { status } = this.props.report;
    const { fields } = this.props.group;
    const { currentPage, perPage, date, search, group_id, authGroups, reportsNewDate, reportsNewDateCount, } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${t(2051)} ${date}`} 
          buttonBack={true} 
          button={true}
          buttonTitle='PDF'
          buttonClickable={true}
          buttonHandleOnClick={() => this.handleDownloadPDF()}
          buttonLink=''
          secondButton={true}
          secondButtonTitle='CSV'
          secondButtonClickable={true}
          secondButtonHandleOnClick={() => this.handleDownloadCSV()}
          secondButtonLink=''
          search={true} 
          searchPlaceholder="Search vehicle ID or IMEI"
          searchValue={search}
          handleSearchValue={e => this.setState({ search: e.target.value })} 
          handleSearch={() => this.handleSearch(search)}
          handleSearchClose={() => this.handleSearchClose()}
          time={false}
          animate
        >

          <form className="form">
            <div className="filter">
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2429)}</span>
                <span className="filter_filter-select">
                  <select 
                    name="groups" 
                    onChange={e => this.handleSelectGroup(e.target.value)}
                    className={classnames('', { 'input-error': fields && fields.includes('groups') })}
                    value={group_id}
                  >
                    <option key="0" value="all">{t(1152)}</option>
                    { 
                      authGroups.map((group: {group_id: string, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                    }
                  </select>
                </span>
              </span>
            </div>
          </form>


          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', 'ID', `${t(2399)} - ${t(2361)}`, t(2052), `${t(2052)} LPG/CNG`, `${t(2052)} PET/DIE`, t(2053), `LPG/CNG ${t(2057)}`, `LPG/CNG ${t(2054)}*`, `${t(2056)} PET/DIE ${t(2054)}*`, `${t(2056)} ${t(2054)} ${t(2059)} LPG*`, `% ${t(2060)} LPG`, `${t(2056)} ${t(2055)}*`, `${t(2058)} ${t(2055)}*`, `CO2 ${t(2055)}*`, `${t(1061)}`]} 
              />
            <tbody className="table__body table__body--striped">
              {reportsNewDate && reportsNewDate.map((item: any, index: number) => {
                const objectReportSavings = calculateObjectReportSavingsNew(item)
                const alternative_fuel_units = item.alternative_fuel_type === 'lpg' ? 'l' : 'm3'
                return (
                <tr key={index}>
                  <td className="simple">
                    <Tooltip anchorId={`tooltip-report-map${index}`}/>
                    <Link to={`/vehicles/routes/${item.vehicle_id}/${date}`}>
                      <span id={`tooltip-report-map${index}`} data-tooltip-content={t(19)}>
                        <IconMap task button color="#ffffff" />
                      </span>
                    </Link>
                  </td>
                  <td>
                    {item.custom_id}
                  </td>
                  <td>
                    {item.d_stamp_from !== null ? item.d_stamp_from.replaceAll("-", "/") : ''}<br/>
                    {item.d_stamp_to !== null ? item.d_stamp_to.replaceAll("-", "/") : ''}
                  </td>
                  <td>
                    { Math.round(objectReportSavings.TOTAL_DISTANCE_KM * 100) / 100 } km
                  </td>
                  <td>
                    { Math.round(objectReportSavings.ALTERNATIVE_DISTANCE_KM * 100) / 100 } km
                  </td>
                  <td>
                    { Math.round(objectReportSavings.DEFAULT_DISTANCE_KM * 100) / 100 } km
                  </td>
                  <td>
                    { moment("1900-01-01 00:00:00").add(objectReportSavings.TOTAL_TIME_S, 'seconds').format("HH:mm:ss") }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * 100) / 100 + ' ' + alternative_fuel_units }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.ALTERNATIVE_FUEL_COST * 100) / 100 } { currency }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.DEFAULT_FUEL_COST * 100) / 100 } { currency }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE * 100) / 100 } { currency }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.TOTAL_ALTERNATIVE_PERCENT * 100) / 100 } %
                  </td>
                  <td>
                    { Math.round(objectReportSavings.ESTIMATED_SAVINGS * 100) / 100 } { currency }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.ESTIMATED_POTENTIAL_SAVINGS * 100) / 100 } { currency }
                  </td>
                  <td>
                    { Math.round(objectReportSavings.TOTAL_SAVINGS_CO2_KG * 100) / 100 } kg
                  </td>
                  <td>
                    { objectReportSavings.TOTAL_DATA_KB ? `${ Math.round((objectReportSavings.TOTAL_SAVINGS_CO2_KG/1024) * 100) / 100 } MB` : 'n/a' }
                  </td>
                </tr>
                )
              })
            }
            </tbody>
              <TableFooter 
                button={ false }
                colSpan={8}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ reportsNewDateCount }
                prevPage={() => this.prevPage( currentPage - 1)}
                nextPage={() => this.nextPage( currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
            <EstimationNote />
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ report, auth, group }: RootState) => ({ report, auth, group }),
  { fetchNewReportsDate, fetchNewReportsDateAll, fetchNewReportsDateSearch, fetchAllAuthGroups }
)(Template)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchGroupById, updateGroupById } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import Preloader from '../partials/Preloader'


interface GroupsEditProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  group: any,
  fetchGroupById: (id: string) => Promise<any>,
  updateGroupById: (group: any) => Promise<any>,
}


interface GroupsEditState {
  group_id: string,
  group_title: string,
  timezone: string,
  formSubmitted: boolean
}


export class GroupsEdit extends React.Component<GroupsEditProps, GroupsEditState> {

  state = {
    group_id: '',
    group_title: '',
    timezone: TIMEZONE,
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchGroupById(this.props.router.params.id)
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        const { group_id, group_title } = this.props.group.group
        this.setState({ group_id, group_title })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { group_id, group_title } = this.state;
    const group = {group_id, group_title}
    this.props.updateGroupById(group) 
    .then(() => {
      this.setState({ formSubmitted: false });
    });
  }


  render() {

    const { fields, status } = this.props.group;
    const { group_title, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(218)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="group_title">
                {t(219)}
              </label>
              <input 
                type="text" 
                name="group_title"
                id="group_title" 
                value={group_title}
                onChange={e => this.setState({ group_title: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('group_title') })}
              />
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(220)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ group }: RootState) => ({ group }),
  { fetchGroupById, updateGroupById }
)(GroupsEdit)))
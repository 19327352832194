function alternativeFuelPercent(options: { ALTERNATIVE_DISTANCE_KM: number, DEFAULT_DISTANCE_KM: number }) {
  const { ALTERNATIVE_DISTANCE_KM, DEFAULT_DISTANCE_KM } = options;
  if( ALTERNATIVE_DISTANCE_KM > 0 ) {
    return ALTERNATIVE_DISTANCE_KM / ((DEFAULT_DISTANCE_KM + ALTERNATIVE_DISTANCE_KM) / 100);
  }
  return 0;
}


function calculateObjectVehicleSavingsNew(options: { vehicle_report: any, fuel_prices: any }) {

  let { vehicle_report, fuel_prices } = options;

  const { total_time_s, total_distance_km, default_distance_km, alternative_distance_km, total_petrol_consumption_l, total_diesel_consumption_l, total_lpg_consumption_l, total_cng_consumption_l, total_petrol_saved_l, total_diesel_saved_l, total_savings_co2_kg, total_data_kb } = vehicle_report;

  const { cost_petrol, cost_diesel, cost_lpg, cost_cng } = fuel_prices;


  // set defaults to export
  let TOTAL_DATA_KB = 0;
  let TOTAL_TIME_S = 0; 
  let TOTAL_DISTANCE_KM = 0; 
  let TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = 0;
  let TOTAL_ALTERNATIVE_PERCENT = 0;
  let TOTAL_ESTIMATED_SAVINGS = 0; 
  let TOTAL_ESTIMATED_COST = 0; 
  let TOTAL_SAVINGS_CO2_KG = 0;

  // set helper defaults
  let COST_PETROL = 0;
  let COST_DIESEL = 0;
  let COST_LPG = 0;
  let COST_CNG = 0;

  // set defaults for calculations
  let TOTAL_LPG_CONSUMPTION_L = 0;
  let TOTAL_CNG_CONSUMPTION_L = 0;
  let ALTERNATIVE_DISTANCE_KM = 0;
  let DEFAULT_DISTANCE_KM = 0;
  let TOTAL_COST_DEFAULT_FUEL = 0;
  let TOTAL_COST_SAVED_DEFAULT_FUEL = 0;
  let TOTAL_COST_ALTERNATIVE_FUEL = 0;


  let TOTAL_DIESEL_CONSUMPTION_L = 0;
  let TOTAL_PETROL_CONSUMPTION_L = 0;
  let TOTAL_PETROL_SAVED_L = 0;
  let TOTAL_DIESEL_SAVED_L = 0;


  // check we have data
  if(total_data_kb) {
    if(Math.sign(total_data_kb) === 1) {
      TOTAL_DATA_KB = Number(total_data_kb);
    } else {
      TOTAL_DATA_KB = 0;
    }
  } else {
    console.error('[ERROR]: missing total_data_kb');
  }

  if(total_time_s) {
    if(Math.sign(total_time_s) === 1) {
      TOTAL_TIME_S = Number(total_time_s);
    } else {
      TOTAL_TIME_S = 0;
    }
  } else {
    console.error('[ERROR]: missing total_time_s');
  }

  if(total_distance_km) {
    if(Math.sign(total_distance_km) === 1) {
      TOTAL_DISTANCE_KM = Number(total_distance_km);
    } else {
      TOTAL_DISTANCE_KM = 0;
    }
  } else {
    console.error('[ERROR]: missing total_distance_km');
  }

  if(total_cng_consumption_l) {
    if(Math.sign(total_cng_consumption_l) === 1) {
      TOTAL_CNG_CONSUMPTION_L = Number(total_cng_consumption_l);
    } else {
      TOTAL_CNG_CONSUMPTION_L = 0;
    }
  } else {
    console.error('[ERROR]: missing total_cng_consumption_l');
  }

  if(total_lpg_consumption_l) {
    if(Math.sign(total_lpg_consumption_l) === 1) {
      TOTAL_LPG_CONSUMPTION_L = Number(total_lpg_consumption_l);
    } else {
      TOTAL_LPG_CONSUMPTION_L = 0;
    }
  } else {
    console.error('[ERROR]: missing total_lpg_consumption_l');
  }

  if(alternative_distance_km) {
    if(Math.sign(alternative_distance_km) === 1) {
      ALTERNATIVE_DISTANCE_KM = Number(alternative_distance_km);
    } else {
      ALTERNATIVE_DISTANCE_KM = 0;
    }
  } else {
    console.error('[ERROR]: missing alternative_distance_km');
  }

  if(default_distance_km) {
    if(Math.sign(default_distance_km) === 1) {
      DEFAULT_DISTANCE_KM = Number(default_distance_km);
    } else {
      DEFAULT_DISTANCE_KM = 0;
    }
  } else {
    console.error('[ERROR]: missing default_distance_km');
  }

  if(cost_petrol) {
    COST_PETROL = Number(cost_petrol);
  } else {
    console.error('[ERROR]: missint cost_petrol');
  }

  if(cost_diesel) {
    COST_DIESEL = Number(cost_diesel);
  } else {
    console.error('[ERROR]: missint cost_diesel');
  }

  if(cost_lpg) {
    COST_LPG = Number(cost_lpg);
  } else {
    console.error('[ERROR]: missint cost_lpg');
  }

  if(cost_cng) {
    COST_CNG = Number(cost_cng);
  } else {
    console.error('[ERROR]: missint cost_cng');
  }

  if(total_savings_co2_kg) {
    TOTAL_SAVINGS_CO2_KG = total_savings_co2_kg;
  } else {
    console.error('[ERROR]: missing total_savings_co2_kg');
  }

  if(total_petrol_consumption_l) {
    TOTAL_PETROL_CONSUMPTION_L = Number(total_petrol_consumption_l);
  } else {
    console.error('[ERROR]: missing total_petrol_consumption_l');
  }

  if(total_diesel_consumption_l) {
    TOTAL_DIESEL_CONSUMPTION_L = Number(total_diesel_consumption_l);
  } else {
    console.error('[ERROR]: missing total_diesel_consumption_l');
  }

  if(total_petrol_saved_l) {
    TOTAL_PETROL_SAVED_L = Number(total_petrol_saved_l);
  } else {
    console.error('[ERROR]: missing total_petrol_savings_l');
  }

  if(total_diesel_saved_l) {
    TOTAL_DIESEL_SAVED_L = Number(total_diesel_saved_l);
  } else {
    console.error('[ERROR]: missing total_diesel_savings_l');
  }


  // calculations
  TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = TOTAL_LPG_CONSUMPTION_L + TOTAL_CNG_CONSUMPTION_L;
  TOTAL_ALTERNATIVE_PERCENT = alternativeFuelPercent({ ALTERNATIVE_DISTANCE_KM, DEFAULT_DISTANCE_KM });

  TOTAL_COST_DEFAULT_FUEL = (TOTAL_DIESEL_CONSUMPTION_L * COST_DIESEL) + (TOTAL_PETROL_CONSUMPTION_L * COST_PETROL);
  TOTAL_COST_SAVED_DEFAULT_FUEL = (TOTAL_PETROL_SAVED_L * COST_PETROL) + (TOTAL_DIESEL_SAVED_L * COST_DIESEL)
  TOTAL_COST_ALTERNATIVE_FUEL = (TOTAL_LPG_CONSUMPTION_L * COST_LPG) + (TOTAL_CNG_CONSUMPTION_L * COST_CNG);
  TOTAL_ESTIMATED_COST = TOTAL_COST_DEFAULT_FUEL + TOTAL_COST_ALTERNATIVE_FUEL;
  TOTAL_ESTIMATED_SAVINGS = TOTAL_COST_SAVED_DEFAULT_FUEL;


  return { TOTAL_DATA_KB, TOTAL_ALTERNATIVE_PERCENT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ESTIMATED_SAVINGS, TOTAL_ESTIMATED_COST, TOTAL_SAVINGS_CO2_KG };

}

export default calculateObjectVehicleSavingsNew;
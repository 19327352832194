import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import { Link } from 'react-router-dom'
import Authorization from '../Authorization'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


interface TabVehicleSettingsProps {
  tabnavId: number
  auth: any
}


export const TabVehicleSettings: React.FC<TabVehicleSettingsProps> = ({ tabnavId }) => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">

      <Authorization requiresAuth="vehicles">
        <NavLink to={{ pathname: `/vehicles/module/${tabnavId}` }} className={classnames('tabnav-tab', {'tabnav-tab--active': location.pathname.substring(0,17) === '/vehicles/module/'})}>
          {t(25)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_diagnostics">
        <NavLink to={{ pathname: `/vehicles/diagnostics/${tabnavId}` }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,22) === '/vehicles/diagnostics/' })}>
          {t(235)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_alerts_settings">
        <NavLink to={{ pathname: `/vehicles/alerts/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,17) === '/vehicles/alerts/' })}>
          {t(6)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_calendar">
        <NavLink to={{ pathname: `/vehicles/calendar/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,19) === '/vehicles/calendar/' })}>
          Calendar
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_lock_unlock">
        <NavLink to={{ pathname: `/vehicles/lock-unlock/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,22) === '/vehicles/lock-unlock/' })}>
          {t(27)}/{t(26)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_pairing">
        <NavLink to={{ pathname: `/vehicles/pairing/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,18) === '/vehicles/pairing/' })}>
          {t(28)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_edit">
        <NavLink to={{ pathname: `/vehicles/edit/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,15) === '/vehicles/edit/' })}>
          {t(24)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Authorization requiresAuth="vehicle_delete">
        <NavLink to={{ pathname: `/vehicles/delete/${tabnavId}`}} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname.substring(0,17) === '/vehicles/delete/' })}>
          {t(29)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>

      <Link to={`/vehicles/now/${tabnavId}`}>
        <span className="tabnav-wrapper-button">
          <span className="btn--circular btn--right">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="visibility-24px"><polygon points="0 0 24 0 24 24 0 24"></polygon><path d="M12,4.5 C7,4.5 2.73,7.61 1,12 C2.73,16.39 7,19.5 12,19.5 C17,19.5 21.27,16.39 23,12 C21.27,7.61 17,4.5 12,4.5 Z M12,17 C9.24,17 7,14.76 7,12 C7,9.24 9.24,7 12,7 C14.76,7 17,9.24 17,12 C17,14.76 14.76,17 12,17 Z M12,9 C10.34,9 9,10.34 9,12 C9,13.66 10.34,15 12,15 C13.66,15 15,13.66 15,12 C15,10.34 13.66,9 12,9 Z" id="Shape" fill="#ffffff" fillRule="nonzero"></path></g></g></svg>
          </span>
        </span>
      </Link>

    </nav>
  )
}


export default TabVehicleSettings
import { VEHICLE } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchVehicle = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: VEHICLE.FETCH });
  return fetch(`${API_ADDRESS}/vehicle/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: VEHICLE.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: VEHICLE.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllVehicles = (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => fetchVehicle({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip + '&param=' + options.param + '&paramValue=' + options.paramValue + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ALL_SUCCESS
});


export const fetchFleetExploitation = (options: { limit: number, skip: number, month: string, group_id: string, param: string, paramValue: boolean }) => fetchVehicle({
  endpoint: `fleet/exploitation/?limit=${options.limit}&skip=${options.skip}&month=${options.month}&group_id=${options.group_id}&param=${options.param}&paramValue=${options.paramValue}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_FLEET_EXPLOITATION_SUCCESS
});


export const fetchFleetSavings = (options: { date: string, group_id: string }) => fetchVehicle({
  endpoint: `fleet/savings/?date=${options.date}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_FLEET_SAVINGS_SUCCESS
});


export const fetchFleetActivity = (options: { limit: number, skip: number, date: string, group_id: string, param: string, paramValue: boolean }) => fetchVehicle({
  endpoint: `fleet/activity/?limit=${options.limit}&skip=${options.skip}&date=${options.date}&group_id=${options.group_id}&param=${options.param}&paramValue=${options.paramValue}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ON_THE_FLY_SUCCESS
});


export const fetchFleetEcoScore = (options: { limit: number, skip: number, month: string, group_id: string, param: string, paramValue: boolean }) => fetchVehicle({
  endpoint: `fleet/ecoscore/?limit=${options.limit}&skip=${options.skip}&month=${options.month}&group_id=${options.group_id}&param=${options.param}&paramValue=${options.paramValue}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_FLEET_ECOSCORE_SUCCESS
});


export const fetchBilling = (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => fetchVehicle({
  endpoint: 'billing/?limit=' + options.limit + '&skip=' + options.skip + '&param=' + options.param + '&paramValue=' + options.paramValue + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_BILLING_SUCCESS
});


export const fetchVehcilesMap = (vehicles: any) => fetchVehicle({
  endpoint: 'map',
  options: {
    method: 'POST',
    body: JSON.stringify({ selectedVehicles: vehicles }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: VEHICLE.FETCH_ARRAY_MAP_SUCCESS
});


export const fetchVehicleById = (id:number) => fetchVehicle({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ID_SUCCESS
});


export const fetchHistoryVehicleById = (options: { id: number, limit: number, skip: number }) => fetchVehicle({
  endpoint: `history/${options.id}?limit=${options.limit}&skip=${options.skip}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_HISTORY_ID_SUCCESS
});


export const fetchPositionVehicleById = (options: { id:number, limit: number}) => fetchVehicle({
  endpoint: `position/${options.id}?limit=${options.limit}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_POSITION_ID_SUCCESS
});


export const fetchSavingsVehicleById = (id:number) => fetchVehicle({
  endpoint: `savings/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_SAVINGS_ID_SUCCESS
});


export const fetchRoutesVehicleById = (options: { id: number, date: string }) => fetchVehicle({
  endpoint: `routes/${options.id}?date=${options.date}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ROUTES_ID_SUCCESS
});


export const fetchModuleVehicleById = (id:number) => fetchVehicle({
  endpoint: `module/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_MODULE_ID_SUCCESS
});


export const fetchReportsVehicleById = (options: { id: number, limit: number, skip: number }) => fetchVehicle({
  endpoint: `reports/${options.id}?limit=${options.limit}&skip=${options.skip}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_REPORTS_ID_SUCCESS
});


export const fetchAllReportsVehicleById = (id:number) => fetchVehicle({
  endpoint: `reports/all/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ALL_REPORTS_ID_SUCCESS
});


export const fetchAlertsVehicleById = (id:number) => fetchVehicle({
  endpoint: `alerts/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ALERTS_ID_SUCCESS
});


export const fetchVehicleToday = (id:number) => fetchVehicle({
  endpoint: `vehicle-today/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_VEHICLE_TODAY_SUCCESS
});


export const fetchReportsNewVehicleById = (options: { id: number, limit: number, skip: number, from: string, to: string }) => fetchVehicle({
  endpoint: `reports-new/${options.id}?limit=${options.limit}&skip=${options.skip}&from=${options.from}&to=${options.to}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_REPORTS_NEW_ID_SUCCESS
});


export const fetchDatesReportsNewVehicleById = (options: { id: number, from: string, to: string }) => fetchVehicle({
  endpoint: `reports-new/dates/${options.id}?from=${options.from}&to=${options.to}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_DATES_REPORTS_NEW_ID_SUCCESS
});


export const fetchVisitsVehicleById = (options: { id: number, limit: number, skip: number }) => fetchVehicle({
  endpoint: `visits/${options.id}?limit=${options.limit}&skip=${options.skip}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_VISITS_ID_SUCCESS
});


export const fetchVisitById = (id: number) => fetchVehicle({
  endpoint: `visit/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_VISIT_ID_SUCCESS
});


export const fetchVehiclesActiveByIgl = () => fetchVehicle({
  endpoint: `igl`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_VEHICLES_IGL_SUCCESS
});


export const fetchRefueledVehicleById = (options: { id: number, limit: number, skip: number }) => fetchVehicle({
  endpoint: `refuelled/${options.id}?limit=${options.limit}&skip=${options.skip}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_REFUELED_ID_SUCCESS
});


export const fetchRefuelCountVehicleById = (options: { id: number, dateFrom: string }) => fetchVehicle({
  endpoint: `refuel-count/${options.id}?dateFrom=${options.dateFrom}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_REFUEL_COUNT_ID_SUCCESS
});


export const deleteVehicleById = (id: number) => fetchVehicle({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_DELETE_SUCCESS
});


export const addNewVehicle = (vehicle: any) => fetchVehicle({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...vehicle }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: VEHICLE.FETCH_ADD_SUCCESS
});


export const updateVehicle = (vehicle: any) => fetchVehicle({
  endpoint: `${vehicle.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...vehicle }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: VEHICLE.FETCH_UPDATE_SUCCESS
});


export const fetchAllLockedVehicles = (options: { limit: number, skip: number }) => fetchVehicle({
  endpoint: 'locked/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_LOCKED_SUCCESS
});


export const unlockVehicleById = (options: { id: number, reason: string, description: string }) => fetchVehicle({
  endpoint: `unlock/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ reason: options.reason, description: options.description }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_UNLOCK_SUCCESS
});


export const lockVehicleById = (options: { id: number, reason: string, description: string }) => fetchVehicle({
  endpoint: `lock/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ reason: options.reason, description: options.description }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_LOCK_SUCCESS
});


export const setLockCalendarDate = (options: { id: number, calendar_lock: boolean, calendar_lock_date: string }) => fetchVehicle({
  endpoint: `calendar/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ calendar_lock: options.calendar_lock, calendar_lock_date: options.calendar_lock_date }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_LOCK_CALENDAR_SUCCESS
});


export const fetchVehiclesSearch = (options: { search: string, limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => fetchVehicle({
  endpoint: 'search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip + '&param=' + options.param + '&paramValue=' + options.paramValue + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_SEARCH_SUCCESS
});


export const fetchVehiclesBillingSearch = (options: { search: string, limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => fetchVehicle({
  endpoint: 'billing/search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip + '&param=' + options.param + '&paramValue=' + options.paramValue + '&group_id=' + options.group_id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_BILLING_SEARCH_SUCCESS
});


export const fetchLockedVehiclesSearch = (options: { search: string, limit: number, skip: number }) => fetchVehicle({
  endpoint: 'locked/search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_LOCKED_SEARCH_SUCCESS
});


export const fetchLockReasons = () => fetchVehicle({
  endpoint: `reasons`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_REASONS_SUCCESS
});


export const fetchFuelUsage = (options: { id: number, month: string }) => fetchVehicle({
  endpoint: `fuel-usage/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ month: options.month }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_FUEL_USAGE_SUCCESS
});


export const fetchVehicleOverviewById = (id:number) => fetchVehicle({
  endpoint: `overview/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_OVERVIEW_SUCCESS
});


export const fetchVehicleRoamingById = (id:number) => fetchVehicle({
  endpoint: `roaming/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_ROAMING_ID_SUCCESS
});


export const fetchDataFrequencyById = (id:number) => fetchVehicle({
  endpoint: `data-frequency/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_DATA_FREQUENCY_ID_SUCCESS
});


export const fetchVehiclesTotalCount = () => fetchVehicle({
  endpoint: `count/`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_COUNT_SUCCESS
});

export const fetchVehiclesNoCommunication = (options: { limit: number, skip: number, param: string, paramValue: boolean }) => fetchVehicle({
  endpoint: `no-communication/?limit=${options.limit}&skip=${options.skip}&param=${options.param}&paramValue=${options.paramValue}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_NO_COMMUNICATION_SUCCESS
});


export const fetchVehicleReportByMonth = (options: { id: number, month: string }) => fetchVehicle({
  endpoint: `vehicle-report-month/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ month: options.month }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_REPORT_BY_MONTH_SUCCESS
});


export const fetchFleetStatisticsFromReportsNew = (month:string) => fetchVehicle({
  endpoint: `fleet-statistics-from-reports-new`,
  options: {
    method: 'POST',
    body: JSON.stringify({ month }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_FLEET_STATISTICS_FROM_REPORTS_NEW_SUCCESS
});


export const fetchGroupStatisticsFromReportsNew = (options: { month: string, group: string }) => fetchVehicle({
  endpoint: `group-statistics-from-reports-new`,
  options: {
    method: 'POST',
    body: JSON.stringify({ month: options.month, group: options.group }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_GROUP_STATISTICS_FROM_REPORTS_NEW_SUCCESS
});


export const updateFuelPrices = (options: { month: string, cost_petrol: string, cost_diesel: string, cost_lpg: string, cost_cng: string, group_id: string }) => fetchVehicle({
  endpoint: `update-fuel-prices`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ month: options.month, cost_petrol: options.cost_petrol, cost_diesel: options.cost_diesel, cost_lpg: options.cost_lpg, cost_cng: options.cost_cng, group_id: options.group_id }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: VEHICLE.UPDATE_FUEL_PRICES_SUCCESS
});


export const fetchFuelPrices = (options:{ month: string, group_id: string }) => fetchVehicle({
  endpoint: `fuel-prices`,
  options: {
    method: 'POST',
    body: JSON.stringify({ month: options.month, group_id: options.group_id }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_FUEL_PRICES_SUCCESS
});


export const deactivatePairingForMaintenance = (options:{ id: number, request_date: string }) => fetchVehicle({
  endpoint: `deactivate-pairing-maintenance/${options.id}`,
  options: {
    method: 'POST',
    body: JSON.stringify({ request_date: options.request_date }),
    headers: { 'Content-Type': 'application/json' },
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: VEHICLE.FETCH_DEACTIVATE_PAIRING_MAINTENANCE_ID_SUCCESS
})


export const fetchMaintenanceRequests = (id: number) => fetchVehicle({
  endpoint: `maintenance_requests/${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_MAINTENANCE_REQUESTS_SUCCESS
});


export const fetchScoreGasPercentage = () => fetchVehicle({
  endpoint: `score-gas-percentage`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_SCORE_GAS_PERCENTAGE_SUCCESS
});


export const fetchScoreCo2Savings = () => fetchVehicle({
  endpoint: `score-co2-savings`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: VEHICLE.FETCH_SCORE_CO2_SAVINGS_SUCCESS
});
import React from 'react'
import classnames from 'classnames'
import Button from './Button'
import ButtonBack from './ButtonBack'
import ButtonSearch from './ButtonSearch'
import ButtonClose from './ButtonClose'
import TabNavs from './TabNavs'
import { IconSchedule } from './Icons'
import t from '../translation/translate'


interface CardProps {
  animate: boolean,
  button: boolean,
  time: any,
  title: any,
  danger?: boolean,
  warning?: boolean,
  success?: boolean,
  buttonBack?: any,
  navigate?: any,
  backLink?: any,
  buttonLink?: string,
  buttonTitle?: string,
  buttonClickable?: boolean,
  buttonHandleOnClick?: () => void,
  secondButton?: boolean,
  secondButtonLink?: string,
  secondButtonTitle?: string,
  secondButtonClickable?: boolean,
  secondButtonHandleOnClick?: () => void,
  search?: boolean,
  searchPlaceholder?: string,
  searchValue?: string,
  handleSearchValue?: (e: any) => void,
  handleSearchClose?: () => void,
  handleSearch?: () => any,
  groupFilter?: boolean,
  handleGroupFilterValue?: (e:any) => void,
  groupFilterGroups?: { group_id: string, group_title: string }[],
  tabnav?: string,
  tabnavId?: number,
  padding?: Array<string>,
  background?: string,
  children?: JSX.Element|JSX.Element[]|string,
  auth?: any
}

  
export class Card extends React.Component<CardProps> {

  handleEnterSearch = (e: any) => {
    if(e.key === 'Enter') {
      let handleSearch:any;
      handleSearch = this.props.handleSearch
      handleSearch()
    }
  } 

  render() {

  const { animate, danger, warning, success, buttonBack, navigate, backLink, title, time, button, buttonLink, buttonTitle, buttonClickable, buttonHandleOnClick, secondButton, secondButtonLink, secondButtonTitle, secondButtonClickable, secondButtonHandleOnClick, search, searchPlaceholder, searchValue, handleSearchValue, handleSearchClose, handleSearch, groupFilter, handleGroupFilterValue, groupFilterGroups, tabnav, tabnavId, padding, background, children, auth } = this.props

  return (
    <div className={classnames('card', {
      'animation--slide-in-from-bottom' : animate
    })}>
      <div className={classnames('card__header', {
        'card__header--danger': danger,
        'card__header--warning': warning,
        'card__header--success': success
      })}>
        <span>
          { buttonBack && 
            <ButtonBack navigate={navigate} backLink={backLink} />
          }
          <h3 className="card__header-title">{title}</h3><span className='card__header-subtitle'>{time && <span><IconSchedule header size={14} color="#D4D4D4" /> {`${time}`}</span> }</span>
        </span>


        <span className="card__header-actions">

          <span className="card__header-action">
            { button !== undefined && buttonLink !== undefined && buttonTitle !== undefined && buttonClickable !== undefined && buttonHandleOnClick !== undefined && 
              <Button link={buttonLink} title={buttonTitle} classes='btn btn--text btn--primary btn--card-header' clickable={buttonClickable} handleOnClick={buttonHandleOnClick}  />
            }
          </span>

          <span className="card__header-action">
            { secondButtonLink !== undefined && secondButtonTitle !== undefined && secondButtonClickable !== undefined && secondButtonHandleOnClick !== undefined && 
              secondButton && <Button link={secondButtonLink} title={secondButtonTitle} classes='btn btn--text btn--primary btn--card-header' clickable={secondButtonClickable} handleOnClick={secondButtonHandleOnClick}  />
            }
          </span>

          <span className="card__header-action">
            { search !== undefined && handleSearchClose !== undefined && handleSearch !== undefined && (
              <span className="card_header-search-container">
                <input className="card_header-search" type='text' placeholder={searchPlaceholder} value={searchValue} onChange={handleSearchValue} onKeyDown={(e) => this.handleEnterSearch(e)} />
                {searchValue !== '' && <ButtonClose handleClose={handleSearchClose} />}
                <ButtonSearch handleSearch={handleSearch} />
              </span>
            )}    
          </span>

          <span className="card__header-action">
            { groupFilter && (
              <span className="card_header-group-filter-container">
                <select className="card_header-group-filter" onChange={handleGroupFilterValue}>
                  <option key="all" value="all">{t(1152)}</option>
                  {
                    groupFilterGroups && groupFilterGroups.map((item: {group_id: string, group_title: string}, index: number) => (
                      <option key={index} value={item.group_id}>{item.group_title}</option>
                    ))
                  }
                </select>
              </span>
            )}
          </span>
        </span>
      </div>

      {
        tabnav !== undefined && tabnavId !== undefined && <TabNavs tabnav={tabnav} tabnavId={tabnavId} auth={auth} />
      }         
      {
        background ? (
          <div className={classnames('card__body', {
            'card__body--wrapper-large': padding && padding.includes('large'),
            'card__body--wrapper-medium': padding && padding.includes('medium'),
            'card__body--wrapper-small': padding && padding.includes('small'),
            'card__body--wrapper-chart': padding && padding.includes('chart')
          })}
          style={{ background: `url(${background})`, backgroundSize: 'cover'  }}
          >
            {children}
          </div>
        ) : (
          <div className={classnames('card__body', {
            'card__body--wrapper-large': padding && padding.includes('large'),
            'card__body--wrapper-medium': padding && padding.includes('medium'),
            'card__body--wrapper-small': padding && padding.includes('small'),
            'card__body--wrapper-chart': padding && padding.includes('chart')
          })}
          >
            {children}
          </div>
        )
      }

    </div>
  )

  }
  

}


export default Card
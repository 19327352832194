import { Routes, Route } from 'react-router-dom'
import HomePage from './components/pages/HomePage'
import NotFoundPage from './components/pages/NotFound'
import TermsConditionsPage from './components/pages/TermsConditions'

import AuthLoginPage from './components/pages/AuthLogin'
import AuthForgotPasswordPage from './components/pages/AuthForgotPassword'
import AuthResetPasswordPage from './components/pages/AuthResetPassword'
import AuthSupportPage from './components/pages/AuthSupport'

import VehiclesPage from './components/pages/Vehicles'
import VehiclesAddPage from './components/pages/VehiclesAdd'
import VehiclesEditPage from './components/pages/VehiclesEdit'
import VehiclesDeletePage from './components/pages/VehiclesDelete'

import VehiclesModulePage from './components/pages/VehiclesModule'
import VehiclesDiagnosticsPage from './components/pages/VehiclesDiagnostics'
import VehiclesPairingPage from './components/pages/VehiclesPairing'
import VehiclesCalendarPage from './components/pages/VehiclesCalendar'
import VehiclesLockUnlockPage from './components/pages/VehiclesLockUnlock'
import VehiclesAlertPage from './components/pages/VehiclesAlerts'

import VehiclesNowPage from './components/pages/VehiclesNow'
import VehiclesRoutesPage from './components/pages/VehiclesRoutes'
import VehiclesHistoryPage from './components/pages/VehiclesHistory'
import VehiclesRefuellHistoryPage from './components/pages/VehiclesRefuelHistory'
import VehiclesFuelUsagePage from './components/pages/VehiclesFuelUsage'
import VehiclesRefuelledLocationPage from './components/pages/VehicleRefuelledLocation'
import VehiclesReportsPage from './components/pages/VehiclesReports'
import VehiclesStatisticsPage from './components/pages/VehiclesStatisticsNew'

import AccountsPage from './components/pages/Accounts'
import AccountsAddPage from './components/pages/AccountsAdd'
import AccountsEditPage from './components/pages/AccountsEdit'
import AccountsDeletePage from './components/pages/AccountsDelete'

import DistributorAccountsPage from './components/pages/DistributorAccounts'
import DistributorAccountsAddPage from './components/pages/DistributorAccountsAdd'
import DistributorAccountsEditPage from './components/pages/DistributorAccountsEdit'
import DistributorAccountsDeletePage from './components/pages/DistributorAccountsDelete'

import UsersPage from './components/pages/Users'
import UsersAddPage from './components/pages/UsersAdd'
import UsersEditPage from './components/pages/UsersEdit'
import UsersDeletePage from './components/pages/UsersDelete'

import GroupsPage from './components/pages/Groups'
import GroupsAddPage from './components/pages/GroupsAdd'
import GroupsEditPage from './components/pages/GroupsEdit'
import GroupsDeletePage from './components/pages/GroupsDelete'

import AlertsPage from './components/pages/Alerts'
import AlertPage from './components/pages/Alert'

import ReportsPage from './components/pages/Reports'
import ReportsDatePage from './components/pages/ReportsDate'
import ReportsAccountsPage from './components/pages/ReportsAccounts'

import RfidPage from './components/pages/Rfid'
import RfidAddPage from './components/pages/RfidAdd'
import RfidEditPage from './components/pages/RfidEdit'
import RfidDeletePage from './components/pages/RfidDelete'

import StationsOverviewPage from './components/pages/StationsOverview'
import StationsRefuelsPage from './components/pages/StationsRefuels'
import StationsPage from './components/pages/StationsAuthorized'
import StationsAddPage from './components/pages/StationsAuthorizedAdd'
import StationsEditPage from './components/pages/StationsAuthorizedEdit'
import StationsDeletePage from './components/pages/StationsAuthorizedDelete'
import CompetitorsPage from './components/pages/StationsUnauthorized'
import CompetitorsAddPage from './components/pages/StationsUnauthorizedAdd'
import CompetitorsEditPage from './components/pages/StationsUnauthorizedEdit'
import CompetitorsDeletePage from './components/pages/StationsUnauthorizedDelete'

import CheckPointsPage from './components/pages/Checkpoints'
import CheckPointsAddPage from './components/pages/CheckpointsAdd'
import CheckPointsDeletePage from './components/pages/CheckpointsDelete'
import CheckPointsEditPage from './components/pages/CheckpointsEdit'
import CheckPointsMapPage from './components/pages/CheckpointsMap'
import CheckPointsFilterPage from './components/pages/CheckpointsFilter'
import CheckPointPage from './components/pages/Checkpoint'

import SubscriptionPage from './components/pages/Subscription'
import BillingSettingsPage from './components/pages/BillingSettings'
import BillingStatementsPage from './components/pages/BillingStatements'
import BillingStatementPage from './components/pages/BillingStatement'

import ImeiPage from './components/pages/Imei'
import ImeiUploadPage from './components/pages/ImeiUpload'
import ImeiDeletePage from './components/pages/ImeiDelete'

import SettingsPage from './components/pages/Settings'
import SettingsPasswordManagementPage from './components/pages/SettingsPasswordManagement';
import SettingsNotificationsPage from './components/pages/SettingsNotifications';
import SettingsAdminPage from './components/pages/SettingsAdmin';
import SettingsAccountsPage from './components/pages/SettingsAccounts';

import ModulesPage from './components/pages/Modules'
import DataMapPage from './components/pages/DataMap'
import CommunicationPage from './components/pages/Communication'
import WorkersPage from './components/pages/Workers'
import WorkersStatePage from './components/pages/WorkersState'
import ErrorsPage from './components/pages/ErrorLog'
import { AnimatePresence } from 'framer-motion'


const AppRoutes = () => (
  <AnimatePresence mode='wait'>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signin" element={<AuthLoginPage />} />
      <Route path="/forgot-password" element={<AuthForgotPasswordPage />} />
      <Route path="/reset-password/:token" element={<AuthResetPasswordPage />} />

      <Route path="/terms-conditions" element={<TermsConditionsPage />} />

      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/settings/password" element={<SettingsPasswordManagementPage />} />
      <Route path="/settings/notifications" element={<SettingsNotificationsPage />} />
      <Route path="/settings/admin" element={<SettingsAdminPage />} />
      <Route path="/settings/accounts" element={<SettingsAccountsPage />} />

      <Route path="/accounts" element={<AccountsPage />} />
      <Route path="/accounts/add" element={<AccountsAddPage />} />
      <Route path="/accounts/edit/:id" element={<AccountsEditPage />} />
      <Route path="/accounts/delete/:id" element={<AccountsDeletePage />} />

      <Route path="/distributor-accounts" element={<DistributorAccountsPage />} />
      <Route path="/distributor-accounts/add" element={<DistributorAccountsAddPage />} />
      <Route path="/distributor-accounts/edit/:id" element={<DistributorAccountsEditPage />} />
      <Route path="/distributor-accounts/delete/:id" element={<DistributorAccountsDeletePage />} />

      <Route path="/vehicles" element={<VehiclesPage />} />
      <Route path="/vehicles/add" element={<VehiclesAddPage />} />
      <Route path="/vehicles/edit/:id" element={<VehiclesEditPage />} />
      <Route path="/vehicles/delete/:id" element={<VehiclesDeletePage />} />

      <Route path="/vehicles/module/:id" element={<VehiclesModulePage />} />
      <Route path="/vehicles/diagnostics/:id" element={<VehiclesDiagnosticsPage />} />
      <Route path="/vehicles/pairing/:id" element={<VehiclesPairingPage />} />
      <Route path="/vehicles/calendar/:id" element={<VehiclesCalendarPage />} />
      <Route path="/vehicles/lock-unlock/:id" element={<VehiclesLockUnlockPage />} />
      <Route path="/vehicles/alerts/:id" element={<VehiclesAlertPage />} />
      <Route path="/vehicles/alerts/:id/:alertType?" element={<VehiclesAlertPage />} />

      <Route path="/vehicles/now/:id" element={<VehiclesNowPage />} />
      <Route path="/vehicles/history/:id" element={<VehiclesHistoryPage />} />
      <Route path="/vehicles/refuelled/:id" element={<VehiclesRefuellHistoryPage />} />
      <Route path="/vehicles/graph/:id" element={<VehiclesFuelUsagePage />} />

      <Route path="/vehicles/routes/:id/:date?" element={<VehiclesRoutesPage />} />
      
      <Route path="/vehicles/reports/:id" element={<VehiclesReportsPage />} />
      <Route path="/vehicles/statistics/:id" element={<VehiclesStatisticsPage />} />
      <Route path="/vehicles/refuelled/location/:id" element={<VehiclesRefuelledLocationPage />} />

      <Route path="/alerts" element={<AlertsPage />} />
      <Route path="/alerts/alert/:id" element={<AlertPage />} />

      <Route path="/reports" element={<ReportsPage />} />
      <Route path="/reports/date" element={<ReportsDatePage />} />
      <Route path="/reports/account" element={<ReportsAccountsPage />} />

      <Route path="/imei" element={<ImeiPage />} />
      <Route path="/imei/upload" element={<ImeiUploadPage />} />
      <Route path="/imei/delete/:id" element={<ImeiDeletePage />} />

      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/add" element={<UsersAddPage />} />
      <Route path="/users/edit/:id" element={<UsersEditPage />} />
      <Route path="/users/delete/:id" element={<UsersDeletePage />} />

      <Route path="/groups" element={<GroupsPage />} />
      <Route path="/groups/add" element={<GroupsAddPage />} />
      <Route path="/groups/edit/:id" element={<GroupsEditPage />} />
      <Route path="/groups/delete/:id" element={<GroupsDeletePage />} />

      <Route path="/rfid" element={<RfidPage />} />
      <Route path="/rfid/add" element={<RfidAddPage />} />
      <Route path="/rfid/edit/:id/:date?" element={<RfidEditPage />} />
      <Route path="/rfid/delete/:id" element={<RfidDeletePage />} />

      <Route path="/stations" element={<StationsOverviewPage />} />
      <Route path="/refuels" element={<StationsRefuelsPage />} />
      <Route path="/stations/authorized" element={<StationsPage />} />
      <Route path="/stations/authorized/delete/:id" element={<StationsDeletePage />} />
      <Route path="/stations/authorized/edit/:id" element={<StationsEditPage />} />
      <Route path="/stations/authorized/add" element={<StationsAddPage />} />
      <Route path="/stations/unauthorized" element={<CompetitorsPage />} />
      <Route path="/stations/unauthorized/delete/:id" element={<CompetitorsDeletePage />} />
      <Route path="/stations/unauthorized/edit/:id" element={<CompetitorsEditPage />} />
      <Route path="/stations/unauthorized/add" element={<CompetitorsAddPage />} />

      <Route path="/checkpoints/checkpoint/:id" element={<CheckPointPage />} />
      <Route path="/checkpoints" element={<CheckPointsPage />} />
      <Route path="/checkpoints/add" element={<CheckPointsAddPage />} />
      <Route path="/checkpoints/delete/:id" element={<CheckPointsDeletePage />} />
      <Route path="/checkpoints/edit/:id" element={<CheckPointsEditPage />} />
      <Route path="/checkpoints/map" element={<CheckPointsMapPage />} />
      <Route path="/checkpoints/filter" element={<CheckPointsFilterPage />} />

      <Route path="/subscription" element={<SubscriptionPage />} />
      <Route path="/billing/settings" element={<BillingSettingsPage />} />
      <Route path="/billing/statements" element={<BillingStatementsPage />} />
      <Route path="/billing/statements/:id" element={<BillingStatementPage />} />

      <Route path="/modules" element={<ModulesPage />} />
      <Route path="/data-map" element={<DataMapPage />} />
      <Route path="/communication" element={<CommunicationPage />} />
      <Route path="/workers" element={<WorkersPage />} />
      <Route path="/workers-state" element={<WorkersStatePage />} />
      <Route path="/errors" element={<ErrorsPage />} />
      <Route path="/support" element={<AuthSupportPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </AnimatePresence>
)


export default AppRoutes
import { PERSON } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchPerson = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: PERSON.FETCH });
  return fetch(`${API_ADDRESS}/person/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: PERSON.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: { msg: number }) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: PERSON.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllPeople = (options: { limit: number, skip: number }) => fetchPerson({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ALL_SUCCESS
});


export const fetchPersonId = (id: number) => fetchPerson({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_ID_SUCCESS
});


export const fetchPersonGroups = () => fetchPerson({
  endpoint: `groups`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: PERSON.FETCH_GROUPS_SUCCESS
});


export const deletePersonId = (id: number) => fetchPerson({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: PERSON.FETCH_DELETE_SUCCESS
});


export const addNewPerson = (person: any) => fetchPerson({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_ADD_SUCCESS
});


export const updatePerson = (person: any) => fetchPerson({
  endpoint: `${person.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...person }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_UPDATE_SUCCESS
});


export const updatePersonSettings = (options: { id: number, settings: any }) => fetchPerson({
  endpoint: `settings/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_SUCCESS
});


export const updatePersonPasswordSettings = (options: { id: number, password_old: string, password_new: string, password_new_confirm: string }) => fetchPerson({
  endpoint: `settings/password/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ password_old: options.password_old, password_new: options.password_new, password_new_confirm: options.password_new_confirm }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_PASSWORD_SUCCESS
});


export const updatePersonAdminSettings = (options: { id: number, phrases_numbering: any }) => fetchPerson({
  endpoint: `settings/admin/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify(options.phrases_numbering),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_ADMIN_SUCCESS
});


export const updatePersonNotificationsSettings = (options: { id: string, settings: any }) => fetchPerson({
  endpoint: `settings/notifications/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_NOTIFICATIONS_SUCCESS
});


export const updatePersonFeaturesSettings = (options: { id: number, settings: any }) => fetchPerson({
  endpoint: `settings/features/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.settings }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SETTINGS_FEATURES_SUCCESS
});



export const updateSelectedVehicles = (options: { id: number, selected_vehicles: any }) => fetchPerson({
  endpoint: `selected-vehicles/${options.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify(options.selected_vehicles),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_SELECTED_VEHICLES_SUCCESS
});


export const updateTablesOptions = (tables_options: any) => fetchPerson({
  endpoint: `tables-options/`,
  options: {
    method: 'PUT',
    body: JSON.stringify(tables_options),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: PERSON.FETCH_TABLES_OPTIONS_SUCCESS
})
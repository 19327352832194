import React from 'react';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import fetchStates from '../../types/fetchStates';
import { fetchFuelPrices, fetchFleetSavings } from '../../actions/vehicle'
import { fetchPersonGroups } from '../../actions/person'
import Card from './Card';
import t from '../translation/translate';
import withAuthorization from '../auth/withAuthorization';
import withRouter from './WithRouter';
import Authorization from './Authorization';
import ReactApexChart from 'react-apexcharts';
import 'moment-duration-format';
import { IconCalendarInput, IconNoData } from './Icons'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import Preloader from './Preloader'
import CtaBox from './CtaBox'


interface CardSavingsGraphProps {
  auth: any,
  vehicle: any,
  person: any,
  fetchPersonGroups: () => Promise<void>,
  fetchFleetSavings: (options: { date: string, group_id: string }) => Promise<void>,
}


interface CardSavingsGraphState {
  dataLoaded: boolean,
  dataStatsLoaded: boolean,
  dataGroupsLoaded: boolean,
  groups: Array<string>,
  filterGroup: string,
  filterDate: Date,
  todayDate: Date,
  series: Array<any>,
  options: any,
  current_savings: number,
  possible_savings: number
}


export class CardSavingsGraph extends React.Component<CardSavingsGraphProps, CardSavingsGraphState> {

  state = {
    dataLoaded: false,
    dataStatsLoaded: false,
    dataGroupsLoaded: false,
    groups: [],
    filterGroup: 'all',
    filterDate: new Date(),
    todayDate: new Date(),
    current_savings: 0,
    possible_savings: 0,

    series: [{
      name: 'SAVINGS',
      data: []
    }, {
      name: 'SAVINGS IF DRIVEN 100% ON ALTERNATIVE FUEL',
      data: []
    }],
    options: {
      chart: {
        type: 'bar' as 'bar',
        height: 350,
        fontFamily: 'Rubik-Regular, sans-serif',
        dropShadow: {
          enabled: true,
          color: '#000000',
          top: 4,
          left: 0,
          blur: 4,
          opacity: 0.1
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      colors: ['#2CBFFC', '#E72E5F'],
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#E7FBFF', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'],
      },
      yaxis: {
        labels: {
          formatter: (val: any) => {
            return val
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val
          }
        }
      }
    },
  };


  componentDidMount = async () => {
    if(this.props.auth.authorized.vehicles === true) {
      await this.fetchPersonGroups();
      await this.fetchFleetSavings();
    }
  }


  /* FUNCTIONS TO HANDLE FILTER */
  updateFilterDate = (date: Date) => {
    this.setState({ filterDate: date }, () => this.fetchFleetSavings());
  }


  updateFilterGroup = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ filterGroup: e.target.value }, () => this.fetchFleetSavings());
  }


  fetchPersonGroups = async () => {
    await this.props.fetchPersonGroups()
    if(this.props.person.status === fetchStates.success) {
      const { groups } = this.props.person.person
      if(groups.length > 0) {
        let filterGroup = 'all'
        if(groups.length === 1) {
          filterGroup = groups[0].group_id
        }     
        this.setState({ groups, filterGroup, dataGroupsLoaded: true })
      } else {
        this.setState({ dataLoaded: true, dataStatsLoaded: false })
      }
    }
  }


  fetchFleetSavings = async () => {
    this.setState({ dataLoaded: false })
    const { filterDate, filterGroup } = this.state;
    await this.props.fetchFleetSavings({ date: moment(filterDate).format('YYYY'), group_id: filterGroup });
    if(this.props.vehicle.status === fetchStates.success) {
      const { fleet_savings } = this.props.vehicle;
      const options = { ...this.state.options, 
        tooltip: {...this.state.options.tooltip, y: {...this.state.options.tooltip.y, formatter: (val:any) => {  return `${Math.round(val)} ${this.props.auth.currency}`  } }},
        yaxis: {...this.state.options.yaxis, labels: {...this.state.options.yaxis.labels, formatter: (val:any) => {  return `${Math.round(val)} ${this.props.auth.currency}`  } }}
      }
      let current_savings = 0;
      let possible_savings = 0;
      fleet_savings[0].data.map((item: number) => current_savings = current_savings + item);
      fleet_savings[1].data.map((item: number) => possible_savings = possible_savings + item);
      this.setState({ series: fleet_savings, options, dataLoaded: true, dataStatsLoaded: true, current_savings, possible_savings });
    }
  }


  render() {

    const { filterDate, dataLoaded, dataGroupsLoaded, groups, filterGroup, current_savings, possible_savings } = this.state;

    return (
      <Authorization requiresAuth="vehicles">
        <Card
          button={false}
          title={`Fleet savings`}
          time={false}
          animate
          padding={["medium"]}
        >
          <>
            {
              dataGroupsLoaded === false ? (
                <CtaBox padding={false} type="warning" title={t(4278)} icon={<IconNoData size={54} color="#ffffff" />}>
                  {t(2490)}
                </CtaBox>
              ) : (
                <>
                <div className="vehicle-savings-filter vehicle-savings-filter--extended">
                  <span className="filter_filter-icon">
                    <IconCalendarInput />
                  </span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDate}
                      onChange={this.updateFilterDate}
                      dateFormat='yyyy'
                      startDate={new Date(2023)}
                      showYearPicker
                      showYearDropdown
                      required
                      yearItemNumber={6}
                      yearDropdownItemNumber={6}
                      maxDate={new Date()}
                      minDate={new Date(2021, 0, 1, 0, 0, 0, 0)}
                    />
                  </span>
                  <span>
                    {t(226)}
                  </span>
                  <span className="filter_filter-select filter-select__group">
                  <select 
                      name="group" 
                      value={filterGroup}
                      onChange={(e) => this.updateFilterGroup(e)}
                    > 
                      { groups.length > 1 && <option key={1} value="all">{t(1186)}</option> }
                      { groups.map((group: { group_id: number, group_title: string}) => (<option key={group.group_id} value={group.group_id}>{group.group_title} {t(1151)}</option>))}
                    </select>
                  </span>
                </div>
                {
                  dataLoaded === false ? (
                    <Preloader type="preloader-center" />
                  ) : (
                    <>
                      <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width="100%"
                        height="300"
                      />
    
    
                      <div className="graph-legend">
                        <div className="graph-legend__title">Savings for {moment(filterDate).format('YYYY')}</div>
                        <div className="graph-legend__item">
                          <span className="graph-legend__item-color graph-legend__item-color--current"></span>
                          <span className="graph-legend__item-title">Current annual savings: </span>
                          <span className="graph-legend__item-value">{current_savings} {this.props.auth.currency}</span>
                        </div>
                        <div className="graph-legend__item">
                          <span className="graph-legend__item-color graph-legend__item-color--possible"></span>
                          <span className="graph-legend__item-title">Possible annual savings: </span> 
                          <span className="graph-legend__item-value">{possible_savings} {this.props.auth.currency}</span>
                        </div>
                      </div>
    
                    </>
                  )
                }
                </>
              )
            }



          </>
        </Card>
      </Authorization>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, person }:RootState) => ({ auth, vehicle, person }),
  {  fetchFuelPrices, fetchPersonGroups, fetchFleetSavings }
)(CardSavingsGraph)))
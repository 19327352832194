import { IconClose } from './Icons'


interface ButtonCloseProps {
  handleClose: () => void
}


const ButtonClose: React.FC<ButtonCloseProps> = ({ handleClose }) => {
  return (
    <span onClick={handleClose} className='card_header-close-button'>
      <IconClose color="#ffffff" size={20} />
    </span>
  )
}


export default ButtonClose
export const translations = [
  // buttons and navigation
  { 
    id: 1,
    en: 'Dashboard',
    pl: 'Pulpit', 
    es: '', 
    ru: ''
  },
  { 
    id: 2,
    en: 'Stations',
    pl: 'Stacje',
    es: '', 
    ru: ''
  },
  { 
    id: 3,
    en: 'Vehicles',
    pl: 'Pojazdy', 
    es: '', 
    ru: ''
  },
  { 
    id: 4,
    en: 'Users',
    pl: 'Użytkownicy', 
    es: '', 
    ru: ''
  },
  { 
    id: 5,
    en: 'Reports',
    pl: 'Raporty', 
    es: '', 
    ru: ''
  },
  { 
    id: 6,
    en: 'Alerts',
    pl: 'Alerty', 
    es: '', 
    ru: ''
  },
  { 
    id: 7,
    en: 'Modules',
    pl: 'Moduły', 
    es: '', 
    ru: ''
  },
  { 
    id: 8,
    en: 'Data Map',
    pl: 'Mapa danych', 
    es: '', 
    ru: ''
  },
  { 
    id: 9,
    en: 'Serial No List',
    pl: 'Lista N. Seryjny', 
    es: '', 
    ru: ''
  },
  { 
    id: 10,
    en: 'Communication',
    pl: 'Komunikacja', 
    es: '', 
    ru: ''
  },
  { 
    id: 11,
    en: 'Settings',
    pl: 'Ustawienia', 
    es: '', 
    ru: ''
  },
  { 
    id: 12,
    en: 'Help',
    pl: 'Pomoc', 
    es: '', 
    ru: ''
  },
  { 
    id: 13,
    en: 'Logout',
    pl: 'Wyloguj', 
    es: '', 
    ru: ''
  },
  { 
    id: 14,
    en: 'Logout everywhere',
    pl: 'Wyloguj się wszędzie', 
    es: '', 
    ru: ''
  },
  { 
    id: 15,
    en: 'Support',
    pl: 'Wsparcie', 
    es: '', 
    ru: ''
  },
  { 
    id: 16,
    en: 'Overview',
    pl: 'Przegląd', 
    es: '', 
    ru: ''
  },
  { 
    id: 17,
    en: 'Statistics',
    pl: 'Statystyka', 
    es: '', 
    ru: ''
  },
  { 
    id: 18,
    en: 'Fuel Usage',
    pl: 'Zużycie paliwa', 
    es: '', 
    ru: ''
  },
  { 
    id: 19,
    en: 'Routes',
    pl: 'Trasy', 
    es: '', 
    ru: ''
  },
  { 
    id: 20,
    en: 'Last Position',
    pl: 'Ostatnia pozycja', 
    es: '', 
    ru: ''
  },
  { 
    id: 21,
    en: 'Refuelled ',
    pl: 'Zatankowane', 
    es: '', 
    ru: ''
  },
  { 
    id: 22,
    en: 'Vehicle',
    pl: 'Pojazd', 
    es: '', 
    ru: ''
  },
  { 
    id: 23,
    en: 'History',
    pl: 'Historia', 
    es: '', 
    ru: ''
  },
  { 
    id: 24,
    en: 'Edit',
    pl: 'Edytować', 
    es: '', 
    ru: ''
  },
  { 
    id: 25,
    en: 'Module',
    pl: 'Moduł', 
    es: '', 
    ru: ''
  },
  { 
    id: 26,
    en: 'Unlock',
    pl: 'Odblokować', 
    es: '', 
    ru: ''
  },
  { 
    id: 27,
    en: 'Lock',
    pl: 'Zablokować', 
    es: '', 
    ru: ''
  },
  { 
    id: 28,
    en: 'Pairing',
    pl: 'Parowanie', 
    es: '', 
    ru: ''
  },
  { 
    id: 29,
    en: 'Delete',
    pl: 'Usunąć', 
    es: '', 
    ru: ''
  },
  {
    id: 30,
    en: 'Competitors Stations',
    pl: 'Stacje Konkurentów', 
    es: '', 
    ru: ''
  },
  {
    id: 31,
    en: 'Save station',
    pl: 'Zapisz stację', 
    es: '', 
    ru: ''
  },
  {
    id: 32,
    en: 'Save competitor station',
    pl: 'Zapisz stację konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 33,
    en: 'Details',
    pl: 'Detale', 
    es: '', 
    ru: ''
  },
  {
    id: 34,
    en: 'Dist. Accounts',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 35,
    en: 'Account Reports',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 36,
    en: 'Instructions',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 37,
    en: 'Checkpoints',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 100,
    en: 'Add station',
    pl: 'Dodaj stację', 
    es: '', 
    ru: ''
  },
  { 
    id: 101,
    en: 'Add vehicle',
    pl: 'Dodaj pojazd', 
    es: '', 
    ru: ''
  },
  { 
    id: 102,
    en: 'Add user',
    pl: 'Dodaj użytkownika', 
    es: '', 
    ru: ''
  },
  { 
    id: 103,
    en: 'Add Serial No',
    pl: 'Dodaj N. Seryjny', 
    es: '', 
    ru: ''
  },
  { 
    id: 104,
    en: 'Update Settings',
    pl: 'Zaktualizuj ustawienia', 
    es: '', 
    ru: ''
  },
  { 
    id: 105,
    en: 'Set your map',
    pl: 'Ustaw swoją mapę', 
    es: '', 
    ru: ''
  },
  { 
    id: 106,
    en: 'default position',
    pl: 'domyślna pozycja', 
    es: '', 
    ru: ''
  },
  { 
    id: 107,
    en: 'default position',
    pl: 'domyślna pozycja', 
    es: '', 
    ru: ''
  },
  { 
    id: 108,
    en: 'Search by Serial No ...',
    pl: 'Szukaj według N. Seryjny ...', 
    es: '', 
    ru: ''
  },
  { 
    id: 109,
    en: 'Upload Serial No',
    pl: 'Prześlij N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 110,
    en: 'Delete Serial No',
    pl: 'Usuń N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 111,
    en: 'Edit station',
    pl: 'Edytuj stację', 
    es: '', 
    ru: ''
  },
  {
    id: 112,
    en: 'Update station',
    pl: 'Aktualizacja stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 113,
    en: 'Delete station',
    pl: 'Usuń stację', 
    es: '', 
    ru: ''
  },
  {
    id: 114,
    en: 'Edit competitor station',
    pl: 'Edytuj stację konkurenta', 
    es: '', 
    ru: ''
  },
  {
    id: 115,
    en: 'Update competitor',
    pl: 'Zaktualizuj konkurenta', 
    es: '', 
    ru: ''
  },
  {
    id: 116,
    en: 'required',
    pl: 'wymagane', 
    es: '', 
    ru: ''
  },
  {
    id: 117,
    en: 'Add competitors station',
    pl: 'Dodaj stację konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 118,
    en: 'Delete competitors station',
    pl: 'Usuń stację konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 119,
    en: 'Update user',
    pl: 'Zaktualizuj użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 120,
    en: 'Delete user',
    pl: 'Usuń użytkownika', 
    es: '', 
    ru: ''
  },

  { 
    id: 121,
    en: 'Search by Serial No or ID...',
    pl: 'Szukaj według N. Seryjny lub ID...', 
    es: '', 
    ru: ''
  },
  { 
    id: 122,
    en: 'Update',
    pl: 'Aktualizuj', 
    es: '', 
    ru: ''
  },
  { 
    id: 123,
    en: 'Update vehicle',
    pl: 'Zaktualizuj pojazd', 
    es: '', 
    ru: ''
  },
  { 
    id: 124,
    en: 'Pair module',
    pl: 'Sparuj moduł', 
    es: '', 
    ru: ''
  },
  { 
    id: 125,
    en: 'There are no data for this vehicle.',
    pl: 'Brak danych dla tego pojazdu.', 
    es: '', 
    ru: ''
  },
  { 
    id: 126,
    en: 'You are trying to unlock vehicle that is not paired yet. Please make sure to pair the vehicle first',
    pl: 'Próbujesz odblokować pojazd, który nie jest jeszcze sparowany. Najpierw sparuj pojazd', 
    es: '', 
    ru: ''
  },
  { 
    id: 127,
    en: 'You are about to unlock vehicle with module with Serial No',
    pl: 'Zamierzasz odblokować pojazd z modułem o numerze N. Seryjny', 
    es: '', 
    ru: ''
  },
  { 
    id: 128,
    en: 'Lock module',
    pl: 'Zablokuj moduł', 
    es: '', 
    ru: ''
  },
  { 
    id: 129,
    en: 'Unpair module',
    pl: 'Rozparuj moduł', 
    es: '', 
    ru: ''
  },
  { 
    id: 130,
    en: 'Delete vehicle',
    pl: 'Usuń pojazd', 
    es: '', 
    ru: ''
  },
  { 
    id: 131,
    en: 'Unlock module',
    pl: 'Odblokuj moduł', 
    es: '', 
    ru: ''
  },
  { 
    id: 132,
    en: 'Login',
    pl: 'Zaloguj sie', 
    es: '', 
    ru: ''
  },
  { 
    id: 133,
    en: 'Forgot Password',
    pl: 'Zapomniałeś hasła', 
    es: '', 
    ru: ''
  },
  { 
    id: 134,
    en: 'Reset your password',
    pl: 'Zresetuj swoje hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 135,
    en: 'List',
    pl: 'Lista', 
    es: '', 
    ru: ''
  },
  {
    id: 136,
    en: 'Map',
    pl: 'Mapa', 
    es: '', 
    ru: ''
  },
  {
    id: 137,
    en: 'Select vehicles',
    pl: 'Wybierz pojazdy', 
    es: '', 
    ru: ''
  },
  {
    id: 138,
    en: 'Reset selection',
    pl: 'Zresetuj wybór', 
    es: '', 
    ru: ''
  },
  {
    id: 139,
    en: 'Subscription',
    pl: 'Dane do faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 140,
    en: 'Statements',
    pl: 'Faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 141,
    en: 'Update billing address',
    pl: 'Zaktualizuj adres rozliczeniowy', 
    es: '', 
    ru: ''
  },
  {
    id: 142,
    en: 'Subscription',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 143,
    en: 'Selected vehicles',
    pl: 'Wybrane pojazdy', 
    es: '', 
    ru: ''
  },
  {
    id: 144,
    en: 'Subscription plan',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 145,
    en: 'Payment method',
    pl: 'Metoda płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 146,
    en: 'Credit Card',
    pl: 'Karta kredytowa', 
    es: '', 
    ru: ''
  },
  {
    id: 147,
    en: 'PayPal',
    pl: 'PayPal', 
    es: '', 
    ru: ''
  },
  {
    id: 148,
    en: '3 months',
    pl: '3 miesiące', 
    es: '', 
    ru: ''
  },
  {
    id: 149,
    en: '6 months',
    pl: '6 miesięcy', 
    es: '', 
    ru: ''
  },
  {
    id: 150,
    en: '12 months',
    pl: '12 miesięcy', 
    es: '', 
    ru: ''
  },
  {
    id: 151,
    en: '% savings',
    pl: '% oszczędności', 
    es: '', 
    ru: ''
  },
  {
    id: 152,
    en: 'month(s)',
    pl: 'miesiące', 
    es: '', 
    ru: ''
  },
  {
    id: 153,
    en: 'Number of vehicles',
    pl: 'Liczba pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 154,
    en: 'Subscription period',
    pl: 'Okres', 
    es: '', 
    ru: ''
  },
  {
    id: 155,
    en: 'Company name',
    pl: 'Nazwa firmy', 
    es: '', 
    ru: ''
  },
  {
    id: 156,
    en: 'Period',
    pl: 'Okres', 
    es: '', 
    ru: ''
  },
  {
    id: 157,
    en: 'Street name, house number',
    pl: 'Nazwa ulicy, numer domu', 
    es: '', 
    ru: ''
  },
  {
    id: 158,
    en: 'City',
    pl: 'Miasto', 
    es: '', 
    ru: ''
  },
  {
    id: 159,
    en: 'Postcode',
    pl: 'Miasto', 
    es: '', 
    ru: ''
  },
  {
    id: 160,
    en: 'Country',
    pl: 'Kraj', 
    es: '', 
    ru: ''
  },
  {
    id: 161,
    en: 'VAT number',
    pl: 'Kraj', 
    es: '', 
    ru: ''
  },
  {
    id: 162,
    en: 'Download PDF',
    pl: 'ściągnij PDF', 
    es: '', 
    ru: ''
  },
  {
    id: 163,
    en: 'FROM',
    pl: 'OD', 
    es: '', 
    ru: ''
  },
  {
    id: 164,
    en: 'REGON',
    pl: 'REGON', 
    es: '', 
    ru: ''
  },
  {
    id: 165,
    en: 'NIP',
    pl: 'NIP', 
    es: '', 
    ru: ''
  },
  {
    id: 166,
    en: 'DETAILS',
    pl: 'DETALE', 
    es: '', 
    ru: ''
  },
  {
    id: 167,
    en: 'Invoice number',
    pl: 'Numer faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 168,
    en: 'Invoice date',
    pl: 'Data wystawienia faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 169,
    en: 'BILL TO',
    pl: 'RACHUNEK DO', 
    es: '', 
    ru: ''
  },
  {
    id: 170,
    en: 'PAYMENT METHOD',
    pl: 'METODA PŁATNOŚCI', 
    es: '', 
    ru: ''
  },
  {
    id: 171,
    en: 'Item',
    pl: 'Przedmiot', 
    es: '', 
    ru: ''
  },
  {
    id: 172,
    en: 'Number',
    pl: 'Numer', 
    es: '', 
    ru: ''
  },
  {
    id: 173,
    en: 'Amount',
    pl: 'Ilość', 
    es: '', 
    ru: ''
  },
  {
    id: 174,
    en: 'Vehicle standard',
    pl: 'Standardowy plan pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 175,
    en: 'Vehicle premium',
    pl: 'Plan premium', 
    es: '', 
    ru: ''
  },
  {
    id: 176,
    en: 'Subtotal',
    pl: 'Suma częściowa', 
    es: '', 
    ru: ''
  },
  {
    id: 177,
    en: 'Total',
    pl: 'Całkowity', 
    es: '', 
    ru: ''
  },
  {
    id: 178,
    en: 'Vehicles with overdue payment',
    pl: 'Pojazdy z zaległą płatnością', 
    es: '', 
    ru: ''
  },
  {
    id: 179,
    en: 'Paid',
    pl: 'Płatny', 
    es: '', 
    ru: ''
  },
  {
    id: 180,
    en: 'Vehicles with no communication with the app',
    pl: 'Pojazdy bez komunikacji z aplikacją', 
    es: '', 
    ru: ''
  },
  {
    id: 181,
    en: 'Renew now',
    pl: 'Odnowić teraz', 
    es: '', 
    ru: ''
  },
  {
    id: 182,
    en: 'Subscribe',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 183,
    en: 'Manage table columns',
    pl: 'Zarządzaj kolumnami tabeli', 
    es: '', 
    ru: ''
  },
  {
    id: 184,
    en: 'IP whitelist',
    pl: 'Biała lista IP', 
    es: '', 
    ru: ''
  },
  {
    id: 185,
    en: 'Whitelist connections',
    pl: 'Połączenia z białej listy', 
    es: '', 
    ru: ''
  },
  {
    id: 186,
    en: 'Add connection',
    pl: 'Dodaj połączenie', 
    es: '', 
    ru: ''
  },
  {
    id: 187,
    en: 'Delete IP record',
    pl: 'Usuń IP', 
    es: '', 
    ru: ''
  },
  {
    id: 188,
    en: 'Add IP record',
    pl: 'Dodaj rekord IP', 
    es: '', 
    ru: ''
  },
  {
    id: 189,
    en: 'Accounts',
    pl: 'Konta', 
    es: '', 
    ru: ''
  },
  {
    id: 190,
    en: 'Edit account',
    pl: 'Edytuj konto', 
    es: '', 
    ru: ''
  },
  {
    id: 191,
    en: 'Add account',
    pl: 'Dodaj konto', 
    es: '', 
    ru: ''
  },
  {
    id: 192,
    en: 'Update account',
    pl: 'Zaktualizuj konto', 
    es: '', 
    ru: ''
  },
  {
    id: 193,
    en: 'Account address details',
    pl: 'Ustawienia konta', 
    es: '', 
    ru: ''
  },
  {
    id: 194,
    en: 'Account administrator',
    pl: 'Domyślny administrator', 
    es: '', 
    ru: ''
  },
  {
    id: 195,
    en: 'Time range (months)',
    pl: 'Zakres czasu (miesiące)', 
    es: '', 
    ru: ''
  },
  {
    id: 196,
    en: 'Account preferences',
    pl: 'Preferencje konta', 
    es: '', 
    ru: ''
  },
  {
    id: 197,
    en: 'Account payment options',
    pl: 'Opcje płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 198,
    en: 'Account type pricing and billing',
    pl: 'Ceny i rozliczenia', 
    es: '', 
    ru: ''
  },
  {
    id: 199,
    en: 'Proforma invoice',
    pl: 'Faktura pro forma', 
    es: '', 
    ru: ''
  },
  {
    id: 200,
    en: 'Self managed',
    pl: 'Samozarządzane', 
    es: '', 
    ru: ''
  },
  {
    id: 201,
    en: 'Invoice number',
    pl: 'Numer faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 202,
    en: 'Confirm',
    pl: 'Potwierdz', 
    es: '', 
    ru: ''
  },
  {
    id: 203,
    en: 'Checkpoints',
    pl: 'Punkty kontrolne', 
    es: '', 
    ru: ''
  },
  {
    id: 204,
    en: 'Map',
    pl: 'Mapa', 
    es: '', 
    ru: ''
  },
  {
    id: 205,
    en: 'Filter',
    pl: '', 
    es: '', 
    ru: ''
  },

  {
    id: 206,
    en: 'Map of checkpoints',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 207,
    en: 'Checkpoints filter',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 208,
    en: 'Checkpoints filter',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 209,
    en: 'Save checkpoint',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 210,
    en: 'Fleet statistics',
    pl: 'Statystyki floty', 
    es: '', 
    ru: ''
  },
  {
    id: 211,
    en: 'Delete RFID',
    pl: 'Usuń RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 212,
    en: 'Edit RFID',
    pl: 'Edytuj RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 213,
    en: 'Update RFID',
    pl: 'Zaktualizuj RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 214,
    en: 'Add RFID',
    pl: 'Dodaj RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 215,
    en: 'Groups',
    pl: 'Grupy', 
    es: '', 
    ru: ''
  },
  { 
    id: 216,
    en: 'Add group',
    pl: 'Dodaj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 217,
    en: 'Delete group',
    pl: 'Usuń grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 218,
    en: 'Edit group',
    pl: 'Edytuj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 219,
    en: 'Group title',
    pl: 'Tytuł grupy', 
    es: '', 
    ru: ''
  },
  { 
    id: 220,
    en: 'Update group',
    pl: 'Aktualizuj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 221,
    en: 'Add group',
    pl: 'Dodaj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 222,
    en: 'Add group',
    pl: 'Dodaj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 223,
    en: 'Group title',
    pl: 'Tytuł grupy', 
    es: '', 
    ru: ''
  },
  { 
    id: 224,
    en: 'Add group',
    pl: 'Dodaj grupę', 
    es: '', 
    ru: ''
  },
  { 
    id: 225,
    en: 'Group ID',
    pl: 'ID grupy', 
    es: '', 
    ru: ''
  },
  { 
    id: 226,
    en: 'for',
    pl: 'za', 
    es: '', 
    ru: ''
  },
  { 
    id: 227,
    en: 'Workers',
    pl: 'Workery', 
    es: '', 
    ru: ''
  },
  { 
    id: 228,
    en: 'Search',
    pl: 'Szukaj', 
    es: '', 
    ru: ''
  },
  { 
    id: 229,
    en: 'Override group settings anyway',
    pl: 'Mimo to zastąp ustawienia grupy', 
    es: '', 
    ru: ''
  },
  { 
    id: 230,
    en: 'Password management',
    pl: 'Zarządzanie hasłem', 
    es: '', 
    ru: ''
  },
  { 
    id: 231,
    en: 'Change password',
    pl: 'Zmień hasło', 
    es: '', 
    ru: ''
  },
  { 
    id: 232,
    en: 'Notifications',
    pl: 'Powiadomienia', 
    es: '', 
    ru: ''
  },
  { 
    id: 233,
    en: 'Admin',
    pl: 'Admin', 
    es: '', 
    ru: ''
  },
  { 
    id: 234,
    en: 'Features',
    pl: 'Cechy', 
    es: '', 
    ru: ''
  },
  { 
    id: 235,
    en: 'Diagnostics',
    pl: 'Diagnostyka', 
    es: '', 
    ru: ''
  },
  { 
    id: 236,
    en: 'Maintenance',
    pl: 'Konserwacja', 
    es: '', 
    ru: ''
  },
  { 
    id: 237,
    en: 'Disable pairing for maintenance',
    pl: 'Wyłącz parowanie w celu konserwacji', 
    es: '', 
    ru: ''
  },
  { 
    id: 238,
    en: 'Refuel history',
    pl: 'Historia tankowania', 
    es: '', 
    ru: ''
  },
  { 
    id: 239,
    en: 'Lock/unlock history',
    pl: 'Zablokuj/odblokuj historię', 
    es: '', 
    ru: ''
  },
  { 
    id: 240,
    en: 'Overview',
    pl: 'Przegląd', 
    es: '', 
    ru: ''
  },
  { 
    id: 241,
    en: 'Authorized stations',
    pl: 'Autoryzowane stacje', 
    es: '', 
    ru: ''
  },
  { 
    id: 242,
    en: 'Unauthorized stations',
    pl: 'Nieautoryzowane stacje', 
    es: '', 
    ru: ''
  },
  { 
    id: 243,
    en: 'Error Logs',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 244,
    en: 'Tasks',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 245,
    en: 'Add distributor account',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 246,
    en: 'Distributor accounts',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 247,
    en: 'Edit distributor account',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 248,
    en: 'Authorized stations',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 249,
    en: 'Refuels',
    pl: '', 
    es: '', 
    ru: ''
  },

  
  

  // tables and forms
  {
    id: 1000,
    en: 'Direct contact',
    pl: 'Bezpośredni kontakt', 
    es: '', 
    ru: ''
  },
  {
    id: 1001,
    en: 'App related issues',
    pl: 'Problemy związane z aplikacja', 
    es: '', 
    ru: ''
  },
  {
    id: 1002,
    en: 'Address',
    pl: 'Adres', 
    es: '', 
    ru: ''
  },
  {
    id: 1003,
    en: 'App features',
    pl: 'Funkcje aplikacji', 
    es: '', 
    ru: ''
  },
  {
    id: 1004,
    en: 'Reports settings',
    pl: 'Ustawienia raportów', 
    es: '', 
    ru: ''
  },
  {
    id: 1005,
    en: 'Default map position',
    pl: 'Domyślna pozycja mapy', 
    es: '', 
    ru: ''
  },
  {
    id: 1006,
    en: 'Default language',
    pl: 'Domyślny język', 
    es: '', 
    ru: ''
  },
  {
    id: 1007,
    en: 'Vehicle refuel and lock control feature',
    pl: 'Tankowanie pojazdu i funkcja kontroli blokady', 
    es: '', 
    ru: ''
  },
  {
    id: 1008,
    en: 'Reports feature',
    pl: 'Funkcja raportów', 
    es: '', 
    ru: ''
  },
  {
    id: 1009,
    en: 'Routes feature',
    pl: 'Funkcja tras', 
    es: '', 
    ru: ''
  },
  {
    id: 1010,
    en: 'Alerts feature',
    pl: 'Funkcja alertów', 
    es: '', 
    ru: ''
  },
  {
    id: 1011,
    en: 'Currency',
    pl: 'Waluta', 
    es: '', 
    ru: ''
  },
  {
    id: 1012,
    en: 'Gas (LPG) cost [l]',
    pl: 'Koszt gazu (LPG) [l]', 
    es: '', 
    ru: ''
  },
  {
    id: 1013,
    en: 'Gas (CNG) cost [m3]',
    pl: 'Koszt gazu (CNG) [m3]', 
    es: '', 
    ru: ''
  },
  {
    id: 1014,
    en: 'Petrol (PET) cost [l]',
    pl: 'Koszt benzyny (PET) [l]', 
    es: '', 
    ru: ''
  },
  {
    id: 1015,
    en: 'Diesel (DIE) cost [l]',
    pl: 'Koszt oleju napędowego (DIE) [l]', 
    es: '', 
    ru: ''
  },
  {
    id: 1016,
    en: 'Enter address',
    pl: 'Podaj adres', 
    es: '', 
    ru: ''
  },
  {
    id: 1017,
    en: 'Default map zoom',
    pl: 'Domyślne powiększenie mapy', 
    es: '', 
    ru: ''
  },
  {
    id: 1018,
    en: 'Search places...',
    pl: 'Szukaj miejsc ...', 
    es: '', 
    ru: ''
  },
  {
    id: 1019,
    en: 'Delete Competitor with ID',
    pl: 'Usuń konkurenta z ID', 
    es: '', 
    ru: ''
  },
  {
    id: 1020,
    en: 'Users',
    pl: 'Użytkownicy', 
    es: '', 
    ru: ''
  },
  {
    id: 1021,
    en: 'Module ID',
    pl: 'ID modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 1022,
    en: 'Vehicle ID',
    pl: 'ID pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1023,
    en: 'Hardware v.',
    pl: 'W. sprzętu', 
    es: '', 
    ru: ''
  },
  {
    id: 1024,
    en: 'Firmware v.',
    pl: 'W. oprogramowania', 
    es: '', 
    ru: ''
  },
  {
    id: 1025,
    en: 'Gas con. model',
    pl: 'Gas con. model', 
    es: '', 
    ru: ''
  },
  {
    id: 1026,
    en: 'Gas con. firmware v.',
    pl: 'Gas con. firmware v.', 
    es: '', 
    ru: ''
  },
  {
    id: 1027,
    en: 'Gas con. firmware v.',
    pl: 'Gas con. firmware v.', 
    es: '', 
    ru: ''
  },
  {
    id: 1028,
    en: 'Gas con. firmware date',
    pl: 'Gas con. data firmware', 
    es: '', 
    ru: ''
  },
  {
    id: 1029,
    en: 'Phone number of module SIM',
    pl: 'Numer telefonu modułu SIM', 
    es: '', 
    ru: ''
  },

  {
    id: 1030,
    en: 'Type',
    pl: 'Rodzaj', 
    es: '', 
    ru: ''
  },
  {
    id: 1031,
    en: 'Time',
    pl: 'Czas', 
    es: '', 
    ru: ''
  },
  {
    id: 1032,
    en: 'Alerts email notifications',
    pl: 'Powiadomienia e-mail o alertach', 
    es: '', 
    ru: ''
  },
  {
    id: 1033,
    en: 'Speed alert',
    pl: 'Alarm prędkości', 
    es: '', 
    ru: ''
  },
  {
    id: 1034,
    en: 'Geo fence alert',
    pl: 'Alarm ogrodzenia geograficznego', 
    es: '', 
    ru: ''
  },
  {
    id: 1035,
    en: 'Engine temperature alert',
    pl: 'Alarm temperatury silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 1036,
    en: 'Rpm alert',
    pl: 'Alarm obrotów', 
    es: '', 
    ru: ''
  },
  {
    id: 1037,
    en: 'Gasoline use alert',
    pl: 'Ostrzeżenie o użyciu benzyny', 
    es: '', 
    ru: ''
  },
  {
    id: 1038,
    en: 'Gas malfunction alert',
    pl: 'Alarm awarii gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 1039,
    en: 'Gas maintenance alert',
    pl: 'Alarm konserwacji gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 1040,
    en: 'Default timezone',
    pl: 'Domyślna strefa czasowa', 
    es: '', 
    ru: ''
  },
  {
    id: 1041,
    en: 'Status',
    pl: 'Status', 
    es: '', 
    ru: ''
  },
  {
    id: 1042,
    en: 'Renew date',
    pl: 'Data odnowienia', 
    es: '', 
    ru: ''
  },
  {
    id: 1043,
    en: 'Paid',
    pl: 'Opłacony', 
    es: '', 
    ru: ''
  },
  {
    id: 1044,
    en: 'Overdue',
    pl: 'Zaległy', 
    es: '', 
    ru: ''
  },
  {
    id: 1045,
    en: 'Unpaid',
    pl: 'Nie zapłacony', 
    es: '', 
    ru: ''
  },

  {
    id: 1046,
    en: '',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1047,
    en: 'Processing',
    pl: 'Przetwarzanie', 
    es: '', 
    ru: ''
  },
  {
    id: 1048,
    en: 'Failed',
    pl: 'Nie powiodło sie', 
    es: '', 
    ru: ''
  },
  {
    id: 1049,
    en: 'Invoice No.',
    pl: 'Faktury nr.', 
    es: '', 
    ru: ''
  },
  {
    id: 1050,
    en: 'Invoice date',
    pl: 'Data wystawienia faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 1051,
    en: 'No of vehicles',
    pl: 'Liczba pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1052,
    en: 'Payment',
    pl: 'Zapłata', 
    es: '', 
    ru: ''
  },
  {
    id: 1053,
    en: 'Amount',
    pl: 'Ilość', 
    es: '', 
    ru: ''
  },
  {
    id: 1054,
    en: 'Total',
    pl: 'Całkowity', 
    es: '', 
    ru: ''
  },
  {
    id: 1055,
    en: 'Summary with',
    pl: '', 
    es: '', 
    ru: ''
  },


  {
    id: 1056,
    en: 'Payment expiry notification',
    pl: 'Powiadomienie o wygaśnięciu płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 1057,
    en: 'Notifies about vehicles with expired payment period, two weeks before and on the day of expiry.',
    pl: 'Powiadamia o pojazdach, których termin płatności minął, na dwa tygodnie przed iw dniu wygaśnięcia.', 
    es: '', 
    ru: ''
  },
  {
    id: 1058,
    en: 'Lost communication notification',
    pl: 'Powiadomienie o utracie komunikacji', 
    es: '', 
    ru: ''
  },
  {
    id: 1059,
    en: 'Notifies about vehicles that did not communicate with the application for more then three days.',
    pl: 'Powiadamia o pojazdach, które nie komunikowały się z aplikacją przez ponad trzy dni.', 
    es: '', 
    ru: ''
  },
  {
    id: 1060,
    en: 'Vehicle management email notifications',
    pl: 'Powiadomienia e-mail dotyczące zarządzania pojazdem', 
    es: '', 
    ru: ''
  },
  {
    id: 1061,
    en: 'Data',
    pl: 'Dane', 
    es: '', 
    ru: ''
  },
  {
    id: 1062,
    en: 'Label',
    pl: 'Etykieta', 
    es: '', 
    ru: ''
  },
  {
    id: 1063,
    en: 'IP address',
    pl: 'adres IP', 
    es: '', 
    ru: ''
  },
  {
    id: 1064,
    en: 'Created',
    pl: 'Utworzony', 
    es: '', 
    ru: ''
  },

  {
    id: 1065,
    en: 'Gas module is currently',
    pl: 'Moduł gazowy jest obecnie', 
    es: '', 
    ru: ''
  },
  {
    id: 1066,
    en: 'Gas and GSM module are',
    pl: 'Moduł gazowy i GSM są', 
    es: '', 
    ru: ''
  },
  {
    id: 1067,
    en: 'Gas controller is disconnected',
    pl: 'Sterownik gazu jest odłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1068,
    en: 'connected',
    pl: 'połączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1069,
    en: 'disconnected',
    pl: 'niepodłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1070,
    en: 'compatible',
    pl: 'zgodny', 
    es: '', 
    ru: ''
  },
  {
    id: 1071,
    en: 'incompatible',
    pl: 'niezgodny', 
    es: '', 
    ru: ''
  },
  {
    id: 1072,
    en: 'Account email',
    pl: 'E-mail konta', 
    es: '', 
    ru: ''
  },
  {
    id: 1073,
    en: 'Account type',
    pl: 'Typ konta', 
    es: '', 
    ru: ''
  },
  {
    id: 1074,
    en: 'Standard account cost',
    pl: 'Koszt standardowego konta', 
    es: '', 
    ru: ''
  },
  {
    id: 1075,
    en: 'Standard account % month',
    pl: 'Konto standardowe % miesiąc', 
    es: '', 
    ru: ''
  },
  {
    id: 1076,
    en: 'Standard account % quarter',
    pl: 'Konto standardowe % kwartał', 
    es: '', 
    ru: ''
  },
  {
    id: 1077,
    en: 'Standard account % year',
    pl: 'Konto standardowe % rok', 
    es: '', 
    ru: ''
  },
  {
    id: 1078,
    en: 'Premium account cost',
    pl: 'Koszt konta premium', 
    es: '', 
    ru: ''
  },
  {
    id: 1079,
    en: 'Premium account % month',
    pl: 'Konto premium % miesiąc', 
    es: '', 
    ru: ''
  },
  {
    id: 1080,
    en: 'Premium account % quarter',
    pl: 'Konto premium % kwartał', 
    es: '', 
    ru: ''
  },
  {
    id: 1081,
    en: 'Premium account % year',
    pl: 'Konto premium % rok', 
    es: '', 
    ru: ''
  },
  {
    id: 1082,
    en: 'Google Maps API key',
    pl: 'Google Maps API klucz', 
    es: '', 
    ru: ''
  },
  {
    id: 1083,
    en: 'premium',
    pl: 'premium', 
    es: '', 
    ru: ''
  },
  {
    id: 1084,
    en: 'basic',
    pl: 'basic', 
    es: '', 
    ru: ''
  },
  {
    id: 1085,
    en: 'Base cost',
    pl: 'Koszt podstawowy', 
    es: '', 
    ru: ''
  },
  {
    id: 1086,
    en: 'Monthly subscription',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1087,
    en: 'Quartal subscription',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1088,
    en: 'Annual subscription',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1089,
    en: 'Annual payment',
    pl: 'Roczna opłata', 
    es: '', 
    ru: ''
  },
  {
    id: 1090,
    en: 'Enable subscription feature',
    pl: 'Włącz funkcję płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 1091,
    en: 'Enable PayPal option for subscription',
    pl: 'Włącz opcję PayPal do rozliczeń', 
    es: '', 
    ru: ''
  },
  {
    id: 1092,
    en: 'Enable Stripe option for subscription',
    pl: 'Włącz opcję Stripe do rozliczeń', 
    es: '', 
    ru: ''
  },
  {
    id: 1093,
    en: 'Enable proforma invoice option for subscription',
    pl: 'Włącz opcję faktury proforma do rozliczeń', 
    es: '', 
    ru: ''
  },
  {
    id: 1094,
    en: 'Proforma invoice must be created and inserted by distributor',
    pl: 'Faktura proforma musi zostać utworzona i wstawiona przez dystrybutora', 
    es: '', 
    ru: ''
  },
  {
    id: 1095,
    en: 'Enable self managed option for subscription',
    pl: 'Włącz opcję samodzielnego zarządzania rozliczeniami', 
    es: '', 
    ru: ''
  },
  {
    id: 1096,
    en: 'Allowes local distributor to manage payment localy from their clients',
    pl: 'Umożliwia lokalnemu dystrybutorowi zarządzanie płatnościami lokalnie od swoich klientów', 
    es: '', 
    ru: ''
  },
  {
    id: 1097,
    en: 'Login count',
    pl: 'Liczba logowań', 
    es: '', 
    ru: ''
  },
  {
    id: 1098,
    en: 'Checkpoint title',
    pl: 'Tytuł punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1099,
    en: 'Checkpoint latitude',
    pl: 'Szerokość geograficzna punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1100,
    en: 'Checkpoint longitude',
    pl: 'Długość geograficzna punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1101,
    en: 'Checkpoint area',
    pl: 'Obszar punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1102,
    en: 'Number of checkpoint visits',
    pl: 'Liczba wizyt w punktach kontrolnych', 
    es: '', 
    ru: ''
  },
  {
    id: 1103,
    en: 'Number of checkpoint visits',
    pl: 'Liczba wizyt w punktach kontrolnych', 
    es: '', 
    ru: ''
  },
  {
    id: 1104,
    en: 'Update checkpoint',
    pl: 'Zaktualizuj punkt kontrolny', 
    es: '', 
    ru: ''
  },
  {
    id: 1105,
    en: 'Checkpoint area',
    pl: 'Obszar punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1106,
    en: 'You are about to delete checkpoint with the title',
    pl: 'Masz zamiar usunąć punkt kontrolny z tytułem', 
    es: '', 
    ru: ''
  },
  {
    id: 1107,
    en: 'Confirm your choice by typing in the checkpoint title',
    pl: 'Potwierdź swój wybór, wpisując tytuł punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 1108,
    en: 'Delete checkpoint',
    pl: 'Usuń punkt kontrolny', 
    es: '', 
    ru: ''
  },
  {
    id: 1109,
    en: 'Add checkpoint',
    pl: 'Dodaj punkt kontrolny', 
    es: '', 
    ru: ''
  },
  {
    id: 1110,
    en: 'time',
    pl: 'czas', 
    es: '', 
    ru: ''
  },
  {
    id: 1111,
    en: 'Vehicle ignition is turned off',
    pl: 'Zapłon pojazdu jest wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1112,
    en: 'The data you are currenlty previewing are older then 1h. It might be that your vehicle lost connection with the server.',
    pl: 'Dane, które aktualnie przeglądasz są starsze niż 1h. Możliwe, że Twój pojazd utracił połączenie z serwerem.', 
    es: '', 
    ru: ''
  },
  {
    id: 1113,
    en: 'Gas and GSM modules are incompatible. You won\'t be able to use the locking feature.',
    pl: 'Moduły gazowe i GSM są niekompatybilne. Nie będziesz mógł korzystać z funkcji blokowania.', 
    es: '', 
    ru: ''
  },
  {
    id: 1114,
    en: 'Gas and GSM modules are compatible.',
    pl: 'Moduły gazowe i GSM są kompatybilne.', 
    es: '', 
    ru: ''
  },
  {
    id: 1115,
    en: 'Some of the data from the module has not been transferred yet. This can affect the data.',
    pl: 'Część danych z modułu nie została jeszcze przeniesiona. Może to wpłynąć na dane.', 
    es: '', 
    ru: ''
  },
  {
    id: 1116,
    en: 'This vehicle is unpaid. There are no new data available for this vehicle.',
    pl: 'Ten pojazd jest bezpłatny. Brak nowych danych dla tego pojazdu.', 
    es: '', 
    ru: ''
  },
  {
    id: 1117,
    en: 'There are no data for this vehicle. This vehicle did not sent any data yet (maybe SIM card data problem).',
    pl: 'Brak danych dla tego pojazdu. Ten pojazd nie wysłał jeszcze żadnych danych (może problem z danymi karty SIM).', 
    es: '', 
    ru: ''
  },
  {
    id: 1118,
    en: 'Checkpoints',
    pl: 'Punkty kontrolne', 
    es: '', 
    ru: ''
  },
  {
    id: 1119,
    en: 'Arrived',
    pl: 'Przybył do obszaru', 
    es: '', 
    ru: ''
  },
  {
    id: 1120,
    en: 'Left',
    pl: 'Opuścił obszar', 
    es: '', 
    ru: ''
  },
  {
    id: 1121,
    en: 'Total time',
    pl: 'Czas całkowity', 
    es: '', 
    ru: ''
  },
  {
    id: 1122,
    en: 'Select vehicle',
    pl: 'Wybierz pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 1123,
    en: 'Date from',
    pl: 'Data od', 
    es: '', 
    ru: ''
  },
  {
    id: 1124,
    en: 'Date to',
    pl: 'Data do', 
    es: '', 
    ru: ''
  },
  {
    id: 1125,
    en: 'Checkpoint',
    pl: 'Punkt kontrolny', 
    es: '', 
    ru: ''
  },
  {
    id: 1126,
    en: 'All',
    pl: 'Wszystkie', 
    es: '', 
    ru: ''
  },
  {
    id: 1127,
    en: 'Visits',
    pl: 'Odwiedziny', 
    es: '', 
    ru: ''
  },
  {
    id: 1128,
    en: 'Account ID',
    pl: 'ID konta', 
    es: '', 
    ru: ''
  },
  {
    id: 1129,
    en: 'Payment per vehicle',
    pl: 'Płatność za pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 1130,
    en: 'Enables user to decide if given vehicle should be added to the payment schema',
    pl: 'Pozwala użytkownikowi zdecydować, czy dany pojazd ma zostać dodany do schematu płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 1131,
    en: 'Billing status',
    pl: 'Stan rozliczeń', 
    es: '', 
    ru: ''
  },
  {
    id: 1132,
    en: 'Refueled on station',
    pl: 'Tankowane na stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 1133,
    en: 'Unauthorized refuel',
    pl: 'Nieautoryzowane tankowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 1134,
    en: 'Competitor visit',
    pl: 'Wizyta u konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 1135,
    en: 'Driving on petrol/diesel',
    pl: 'Jazda na benzynie/diesel', 
    es: '', 
    ru: ''
  },
  {
    id: 1136,
    en: 'Statistics for the month of',
    pl: 'Statystyki za miesiąc', 
    es: '', 
    ru: ''
  },
  {
    id: 1137,
    en: 'There are no statistics data for this vehicle for the month',
    pl: 'Obecnie nie ma danych dla tego pojazdu za ten miesiąc. Spróbuj wybrać inny miesiąc.', 
    es: '', 
    ru: ''
  },
  {
    id: 1138,
    en: 'Please make sure Intelligent Gas Level (IGL) is correctly calibrated so we can calculate estimated savings for the vehicle.',
    pl: 'Upewnij się, że inteligentny poziom gazu (IGL) jest prawidłowo skalibrowany, abyśmy mogli obliczyć szacunkowe oszczędności dla pojazdu.', 
    es: '', 
    ru: ''
  },
  {
    id: 1139,
    en: 'Sent to the server',
    pl: 'Wysłane na serwer', 
    es: '', 
    ru: ''
  },
  {
    id: 1140,
    en: 'There are currently no data for the group/fleet in given month. Try selecting a different month.',
    pl: 'Obecnie brak danych dla grupy/floty w danym miesiącu. Spróbuj wybrać inny miesiąc.', 
    es: '', 
    ru: ''
  },
  {
    id: 1141,
    en: 'Please make sure IGL is callibrated correctly. Without IGL the refuel detection has lower sensitivity.',
    pl: 'Upewnij się, że IGL jest poprawnie skalibrowany. Bez IGL wykrywanie tankowania ma niższą czułość.', 
    es: '', 
    ru: ''
  },
  {
    id: 1142,
    en: 'RFID number',
    pl: 'Numer RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 1143,
    en: 'Confirm your choice by typing in the group title',
    pl: 'Potwierdź swój wybór, wpisując tytuł grupy', 
    es: '', 
    ru: ''
  },
  {
    id: 1144,
    en: 'You are about to delete group with the title:',
    pl: 'Zamierzasz usunąć grupę o tytule:', 
    es: '', 
    ru: ''
  },
  {
    id: 1145,
    en: 'Delete group with the title:',
    pl: 'Usuń grupę o tytule:', 
    es: '', 
    ru: ''
  },
  {
    id: 1146,
    en: 'Whole account',
    pl: 'Całe konto', 
    es: '', 
    ru: ''
  },
  {
    id: 1147,
    en: 'Worker name',
    pl: 'Nazwa workera', 
    es: '', 
    ru: ''
  },
  {
    id: 1148,
    en: 'Started',
    pl: 'Początek', 
    es: '', 
    ru: ''
  },
  {
    id: 1149,
    en: 'Finished',
    pl: 'Koniec', 
    es: '', 
    ru: ''
  },
  {
    id: 1150,
    en: 'Time taken',
    pl: 'Zajęty czas', 
    es: '', 
    ru: ''
  },
  {
    id: 1151,
    en: 'group',
    pl: 'grupa', 
    es: '', 
    ru: ''
  },
  {
    id: 1152,
    en: 'All groups',
    pl: 'Wszystkie grupy', 
    es: '', 
    ru: ''
  },
  {
    id: 1153,
    en: 'Ignore payment',
    pl: 'Zignoruj ​​płatność', 
    es: '', 
    ru: ''
  },
  {
    id: 1154,
    en: 'change',
    pl: 'zmien', 
    es: '', 
    ru: ''
  },
  {
    id: 1155,
    en: 'update',
    pl: 'aktualizuj', 
    es: '', 
    ru: ''
  },
  {
    id: 1156,
    en: 'Gas percentage alert',
    pl: 'Alarm procentowy gazu', 
    es: '', 
    ru: ''
  },  
  {
    id: 1157,
    en: 'Pressing this switch will cause override the settings of all vehicles in this group with current alert settings of this vehicle',
    pl: 'Naciśnięcie tego przełącznika spowoduje zastąpienie ustawień wszystkich pojazdów w tej grupie aktualnymi ustawieniami alertów tego pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1158,
    en: 'Billing currency',
    pl: 'Waluta rozliczeniowa', 
    es: '', 
    ru: ''
  },
  {
    id: 1159,
    en: 'Currency with which the invoices should be settled in',
    pl: 'Waluta, w której należy rozliczać faktury', 
    es: '', 
    ru: ''
  },
  {
    id: 1160,
    en: 'AC business and accountancy emails',
    pl: 'E-maile biznesowe i księgowe AC', 
    es: '', 
    ru: ''
  },
  {
    id: 1161,
    en: 'List of email addresses where the temporary number should be sent to (koma separated, no-spaces)',
    pl: 'Lista adresów e-mail, na które należy wysłać numer tymczasowy (oddzielone przecinkami, bez spacji)', 
    es: '', 
    ru: ''
  },
  {
    id: 1162,
    en: 'Vehicles count',
    pl: 'Liczba pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1163,
    en: 'Delete account',
    pl: 'Usuń konto', 
    es: '', 
    ru: ''
  },
  {
    id: 1164,
    en: 'Confirm your choice by typing in the company name',
    pl: 'Potwierdź swój wybór wpisując nazwę firmy', 
    es: '', 
    ru: ''
  },
  {
    id: 1165,
    en: 'Client abbraviation',
    pl: 'Skrót klienta', 
    es: '', 
    ru: ''
  },
  {
    id: 1166,
    en: 'Temporary reference number',
    pl: 'Numer faktury tymczasowej', 
    es: '', 
    ru: ''
  },
  {
    id: 1167,
    en: 'Old password',
    pl: 'Stare hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 1168,
    en: 'New password',
    pl: 'Nowe hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 1169,
    en: 'New password confirmation',
    pl: 'Potwierdzenie nowego hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 1170,
    en: 'Update password',
    pl: 'Zaktualizuj hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 1171,
    en: 'You can temporarily disable pairing for maintenance purpose. Please select desired date.',
    pl: 'Możesz tymczasowo wyłączyć parowanie w celu konserwacji. Proszę wybrać żądaną datę.', 
    es: '', 
    ru: ''
  },
  {
    id: 1172,
    en: 'Please make sure vehicle ignition is on',
    pl: 'Upewnij się, że zapłon pojazdu jest włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1173,
    en: 'Request date',
    pl: 'Data zapytania', 
    es: '', 
    ru: ''
  },
  {
    id: 1174,
    en: 'Vehicle ignition',
    pl: 'Zapłon pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1175,
    en: 'Vehicle ignition is on',
    pl: 'Zapłon pojazdu jest włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1176,
    en: 'Vehicle ignition is off',
    pl: 'Zapłon pojazdu jest wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 1177,
    en: 'Pairing state',
    pl: 'Stan parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 1178,
    en: 'Vehicle is already unpaired',
    pl: 'Pojazd jest już niesparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 1179,
    en: 'Vehicle is paired',
    pl: 'Pojazd jest sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 1180,
    en: 'Select maintenance date',
    pl: 'Wybierz datę konserwacji', 
    es: '', 
    ru: ''
  },
  {
    id: 1181,
    en: 'Submit maintenance request',
    pl: 'Prześlij prośbę o konserwację', 
    es: '', 
    ru: ''
  },
  {
    id: 1182,
    en: 'Petrol price',
    pl: 'Cena benzyny', 
    es: '', 
    ru: ''
  },
  {
    id: 1183,
    en: 'Diesel price',
    pl: 'Cena oleju napędowego', 
    es: '', 
    ru: ''
  },
  {
    id: 1184,
    en: 'Lpg price',
    pl: 'Cena Lpg', 
    es: '', 
    ru: ''
  },
  {
    id: 1185,
    en: 'Cng price',
    pl: 'Cena Cng', 
    es: '', 
    ru: ''
  },
  {
    id: 1186,
    en: 'All groups',
    pl: 'Wszystkie grupy', 
    es: '', 
    ru: ''
  },
  {
    id: 1187,
    en: 'Vehicle Now',
    pl: 'Pojazd teraz', 
    es: '', 
    ru: ''
  },
  {
    id: 1188,
    en: 'Vehicle Report',
    pl: 'Raport pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1189,
    en: 'Vehicle Fuel Usage',
    pl: 'Zużycie paliwa pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1190,
    en: 'Vehicle Routes',
    pl: 'Trasy pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1191,
    en: 'Vehicles Refuel History',
    pl: 'Historia tankowania pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1192,
    en: 'Vehicles Lock/Unlock History',
    pl: 'Historia blokowania/odblokowywania pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1193,
    en: 'Vehicles Diagnostics',
    pl: 'Diagnostyka pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1194,
    en: 'Vehicle Alerts Settings',
    pl: 'Ustawienia alertów pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1195,
    en: 'Vehicle Lock/Unlock',
    pl: 'Zablokuj/Odblokuj pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 1196,
    en: 'Vehicle Pairing',
    pl: 'Parowanie pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 1197,
    en: 'Vehicle Edit',
    pl: 'Edycja pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 1198,
    en: 'Vehicle Delete',
    pl: 'Usuń pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 1199,
    en: 'Admin section authorization',
    pl: 'Autoryzacja sekcji administracyjnej', 
    es: '', 
    ru: ''
  },
  {
    id: 1200,
    en: 'Competitors',
    pl: 'Zawodnicy', 
    es: '', 
    ru: ''
  },
  {
    id: 1201,
    en: 'Experimental section authorization',
    pl: 'Zezwolenie na sekcję eksperymentalną', 
    es: '', 
    ru: ''
  },
  {
    id: 1202,
    en: 'No vehicles',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1203,
    en: 'There are no vehicles to preview, add at least one vehicle',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1204,
    en: 'Edit checkpoint',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1205,
    en: 'Single checkpoint',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1206,
    en: 'Subscribed',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1207,
    en: 'Subscription expiring soon',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1208,
    en: 'Subscription expired',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1208,
    en: 'Initial days free (to test data transfer)',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1209,
    en: 'Days unpaid supscription expires',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1210,
    en: 'Quartal subscription cost per unit',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1211,
    en: 'Half year subscription per unit',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1212,
    en: 'Half year subscription per unit',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1213,
    en: 'Billing currency',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1214,
    en: 'Client abbreviation',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1215,
    en: 'Billing enabled',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1216,
    en: 'Vehicle prefferences',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1217,
    en: 'Refuel sensitivity',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1218,
    en: 'Initial days after which subscribtion is required',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1219,
    en: 'Days untyl payment for subscription is required',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1220,
    en: 'Cost of unit for 3 months',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1221,
    en: 'Cost of unit for 6 months',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 1222,
    en: 'Cost of unit for 12 months',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 1223,
    en: 'Distributor group title',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 1224,
    en: 'Distributor account',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 1225,
    en: 'Select account',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 1226,
    en: 'Update distributor account group',
    pl: '', 
    es: '', 
    ru: ''
  },
  { 
    id: 1227,
    en: 'Delete distributor account group',
    pl: '', 
    es: '', 
    ru: ''
  },

  

  // tables & text
  {
    id: 2000,
    en: 'items',
    pl: 'przedmiotów', 
    es: '', 
    ru: ''
  },
  {
    id: 2001,
    en: 'per page',
    pl: 'na stronę', 
    es: '', 
    ru: ''
  },
  {
    id: 2002,
    en: 'Module Serial No',
    pl: 'Moduł N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2003,
    en: 'Paired to vehicle',
    pl: 'Sparowany z pojazdem', 
    es: '', 
    ru: ''
  },
  {
    id: 2004,
    en: 'Search by Serial No ...',
    pl: 'Szukaj według N. Seryjny ...', 
    es: '', 
    ru: ''
  },
  {
    id: 2005,
    en: 'Module Serial No',
    pl: 'Moduł N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2006,
    en: 'Paired to vehicle',
    pl: 'Sparowany z pojazdem', 
    es: '', 
    ru: ''
  },
  {
    id: 2007,
    en: 'Upload Serial No',
    pl: 'Prześlij N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2008,
    en: 'Upload single or multiple Serial No(s)',
    pl: 'Prześlij jeden lub wiele N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2009,
    en: 'You can add single Serial No (ex.: 860906041740223), or multiple Serial Nos (ex.: 860906041740223, 861694032230466, 860906041739399)',
    pl: 'Możesz dodać jeden numer N. Seryjny (np .: 860906041740223) lub wiele numerów N. Seryjny (np .: 860906041740223, 861694032230466, 860906041739399)', 
    es: '', 
    ru: ''
  },
  {
    id: 2010,
    en: 'Confirm your choice by typing in the Serial No',
    pl: 'Potwierdź swój wybór, wpisując N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2011,
    en: 'You are about to remove Serial No',
    pl: 'Masz zamiar usunąć numer N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2012,
    en: 'from the database. This action can not be reversed. Are you sure you want to proceed?',
    pl: 'z bazy danych. Tej czynności nie można cofnąć. Na pewno chcesz kontynuować?', 
    es: '', 
    ru: ''
  },
  {
    id: 2013,
    en: 'Total number of refuellings in the last 14 days',
    pl: 'Łączna liczba tankowań w ciągu ostatnich 14 dni', 
    es: '', 
    ru: ''
  },
  {
    id: 2014,
    en: 'Refueled on the station',
    pl: 'Zatankowany na stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2015,
    en: 'Refueled beyiond the station',
    pl: 'Zatankowany poza stacją', 
    es: '', 
    ru: ''
  },
  {
    id: 2016,
    en: 'number of refuells, Competitors',
    pl: 'liczba tankowań, Konkurenci',
    es: '', 
    ru: ''
  },
  {
    id: 2017,
    en: 'Station ID',
    pl: 'ID stacji',
    es: '', 
    ru: ''
  },
  {
    id: 2018,
    en: 'Number of refuels',
    pl: 'Liczba tankowań',
    es: '', 
    ru: ''
  },
  {
    id: 2019,
    en: 'Phone',
    pl: 'Telefon',
    es: '', 
    ru: ''
  },
  {
    id: 2020,
    en: 'Registered',
    pl: 'Zarejestrowany',
    es: '', 
    ru: ''
  },
  {
    id: 2021,
    en: 'Current position',
    pl: 'Aktualna pozycja',
    es: '', 
    ru: ''
  },
  {
    id: 2022,
    en: 'Number of refueling at the station',
    pl: 'Liczba tankowań na stacji',
    es: '', 
    ru: ''
  },
  {
    id: 2023,
    en: 'Station registered at',
    pl: 'Stacja zarejestrowana pod adresem',
    es: '', 
    ru: ''
  },
  {
    id: 2024,
    en: 'Delete Station with ID',
    pl: 'Usuń stację z ID',
    es: '', 
    ru: ''
  },
  {
    id: 2025,
    en: 'You are about to delete station with the ID',
    pl: 'Masz zamiar usunąć stację z ID', 
    es: '', 
    ru: ''
  },
  {
    id: 2026,
    en: 'All related data will be lost. This action can not be reversed. Are you sure you want to proceed?',
    pl: 'Wszystkie powiązane dane zostaną utracone. Tej czynności nie można cofnąć. Na pewno chcesz kontynuować?', 
    es: '', 
    ru: ''
  },
  {
    id: 2027,
    en: 'Confirm your choice by typing in the station ID number',
    pl: 'Potwierdź swój wybór wpisując numer identyfikacyjny stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2028,
    en: 'Competitor station ID',
    pl: 'ID stacji konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 2029,
    en: 'Company',
    pl: 'Firma', 
    es: '', 
    ru: ''
  },
  {
    id: 2030,
    en: 'Competitor station Latitude',
    pl: 'Stacja konkurencyjna Latitude', 
    es: '', 
    ru: ''
  },
  {
    id: 2031,
    en: 'Competitor satation Longitude',
    pl: 'Stacja konkurencyjna Longitude', 
    es: '', 
    ru: ''
  },
  {
    id: 2032,
    en: 'Competitor station constraints',
    pl: 'Ograniczenia stacji konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 2033,
    en: 'Number of visits at the competitor',
    pl: 'Liczba wizyt u konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 2034,
    en: 'Competitor registered at',
    pl: 'Zawodnik zarejestrowany pod adresem', 
    es: '', 
    ru: ''
  },
  {
    id: 2035,
    en: 'Station area constraints diameter',
    pl: 'Średnica wiązań obszaru stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2036,
    en: 'Click on the map to select a station postion',
    pl: 'Kliknij mapę, aby wybrać pozycję stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2037,
    en: 'Station position',
    pl: 'Pozycja stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2038,
    en: 'You are about to delete competitor station with the ID',
    pl: 'Masz zamiar usunąć konkurencyjną stację z ID', 
    es: '', 
    ru: ''
  },
  {
    id: 2039,
    en: 'Role',
    pl: 'Rola', 
    es: '', 
    ru: ''
  },
  {
    id: 2040,
    en: 'Name',
    pl: 'Imię', 
    es: '', 
    ru: ''
  },
  {
    id: 2041,
    en: 'Email',
    pl: 'Email', 
    es: '', 
    ru: ''
  },
  {
    id: 2042,
    en: 'Password',
    pl: 'Hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 2043,
    en: 'Language',
    pl: 'Język', 
    es: '', 
    ru: ''
  },
  {
    id: 2044,
    en: 'Send email to user',
    pl: 'Wyślij e-mail do użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 2045,
    en: 'User registered on',
    pl: 'Użytkownik zarejestrował się', 
    es: '', 
    ru: ''
  },
  {
    id: 2046,
    en: 'Delete user with email address',
    pl: 'Usuń użytkownika z adresem e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 2047,
    en: 'Confirm your choice by typing in the email of the user',
    pl: 'Potwierdź swój wybór, wpisując e-mail użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 2048,
    en: 'You are about to delete user with email address',
    pl: 'Zamierzasz usunąć użytkownika z adresem e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 2049,
    en: 'Access to the aplication will be blocked. This action can not be reversed. Are you sure you want to proceed?',
    pl: 'Dostęp do aplikacji zostanie zablokowany. Tej czynności nie można cofnąć. Na pewno chcesz kontynuować?', 
    es: '', 
    ru: ''
  },
  {
    id: 2050,
    en: 'Date',
    pl: 'Data', 
    es: '', 
    ru: ''
  },
  {
    id: 2051,
    en: 'Reports for',
    pl: 'Raporty dla', 
    es: '', 
    ru: ''
  },
  {
    id: 2052,
    en: 'Distance',
    pl: 'Dystans', 
    es: '', 
    ru: ''
  },
  {
    id: 2053,
    en: 'Drive time',
    pl: 'Czas jazdy', 
    es: '', 
    ru: ''
  },
  {
    id: 2054,
    en: 'cost',
    pl: 'koszt', 
    es: '', 
    ru: ''
  },
  {
    id: 2055,
    en: 'savings',
    pl: 'oszczędności', 
    es: '', 
    ru: ''
  },
  {
    id: 2056,
    en: 'Estimated',
    pl: 'Szacowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2057,
    en: 'consumption',
    pl: 'konsumpcja', 
    es: '', 
    ru: ''
  },
  {
    id: 2058,
    en: 'Potential',
    pl: 'Potencjalne', 
    es: '', 
    ru: ''
  },
  {
    id: 2059,
    en: 'without',
    pl: 'bez', 
    es: '', 
    ru: ''
  },
  {
    id: 2060,
    en: 'on',
    pl: 'na', 
    es: '', 
    ru: ''
  },
  {
    id: 2061,
    en: 'Gas usage % (prev. day)',
    pl: 'Zużycie gazu %', 
    es: '', 
    ru: ''
  },
  {
    id: 2062,
    en: 'Fuel LVL',
    pl: 'Zużycie gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2063,
    en: 'Number of refuels',
    pl: 'Liczba tankowań', 
    es: '', 
    ru: ''
  },
  {
    id: 2064,
    en: 'Locked',
    pl: 'Zablokowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2065,
    en: 'Locked times',
    pl: 'Ile razy zablokowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2066,
    en: 'Visited competition',
    pl: 'Odwiedził konkurenta', 
    es: '', 
    ru: ''
  },
  {
    id: 2067,
    en: 'Paired',
    pl: 'Sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2068,
    en: 'Last connected',
    pl: 'Ostatnio połączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2069,
    en: 'Buffer',
    pl: 'Bufor', 
    es: '', 
    ru: ''
  },
  {
    id: 2070,
    en: 'Most recent vehicle data from',
    pl: 'Najnowsze dane pojazdu z', 
    es: '', 
    ru: ''
  },
  {
    id: 2071,
    en: 'Module state',
    pl: 'Stan modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2072,
    en: 'Fuel level',
    pl: 'Poziom paliwa', 
    es: '', 
    ru: ''
  },
  {
    id: 2073,
    en: 'Reason',
    pl: 'Powód', 
    es: '', 
    ru: ''
  },
  {
    id: 2074,
    en: 'all',
    pl: 'wszystko', 
    es: '', 
    ru: ''
  },
  {
    id: 2075,
    en: 'of',
    pl: 'z', 
    es: '', 
    ru: ''
  },
  {
    id: 2076,
    en: 'Alert for',
    pl: 'Alert dla', 
    es: '', 
    ru: ''
  },
  {
    id: 2077,
    en: 'Edit this alert',
    pl: 'Edytuj ten alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2078,
    en: 'Speed alert',
    pl: 'Alert prędkości', 
    es: '', 
    ru: ''
  },
  {
    id: 2079,
    en: 'Geo fence alert',
    pl: 'Alert o ogrodzeniu geograficznym', 
    es: '', 
    ru: ''
  },
  {
    id: 2080,
    en: 'Engine temperature alert',
    pl: 'Alarm temperatury silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 2081,
    en: 'Rpm alert',
    pl: 'Alarm obrotów', 
    es: '', 
    ru: ''
  },
  {
    id: 2082,
    en: 'Petrol/Diesel use alert',
    pl: 'Ostrzeżenie o zużyciu benzyny/dieslu', 
    es: '', 
    ru: ''
  },
  {
    id: 2083,
    en: 'Gas malfunction alert',
    pl: 'Alert o nieprawidłowym działaniu', 
    es: '', 
    ru: ''
  },
  {
    id: 2084,
    en: 'Gas maintenance alert',
    pl: 'Alarm konserwacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2085,
    en: 'actual speed',
    pl: 'rzeczywista prędkość', 
    es: '', 
    ru: ''
  },
  {
    id: 2086,
    en: 'alert treshold',
    pl: 'próg alertu', 
    es: '', 
    ru: ''
  },
  {
    id: 2087,
    en: 'actual amount',
    pl: 'rzeczywista kwota', 
    es: '', 
    ru: ''
  },
  {
    id: 2088,
    en: 'actual temperature',
    pl: 'rzeczywista temperatura', 
    es: '', 
    ru: ''
  },
  {
    id: 2089,
    en: 'Set the speed limit',
    pl: 'Ustaw ograniczenie prędkości', 
    es: '', 
    ru: ''
  },
  {
    id: 2090,
    en: 'The number you set here is the maximum speed that once crossed, will generate an alert',
    pl: 'Liczba, którą tu ustawisz, to maksymalna prędkość, która po przekroczeniu wygeneruje alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2091,
    en: 'Minimum time to alert (s)',
    pl: 'Minimalny czas na ostrzeżenie', 
    es: '', 
    ru: ''
  },
  {
    id: 2092,
    en: 'The number you set here is the minimum time condition was met required to activate the alert',
    pl: 'Podana tutaj liczba to minimalny czas, jaki został spełniony, aby aktywować alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2093,
    en: 'Maximum alert frequency (s)',
    pl: 'Maksymalna częstotliwość alertów', 
    es: '', 
    ru: ''
  },
  {
    id: 2094,
    en: 'The number you set here represents how frequently the alert notifications will be sent. It is the minimum number between two alerts',
    pl: 'Liczba, którą tu ustawisz, określa, jak często będą wysyłane powiadomienia o alertach. Jest to minimalna liczba między dwoma wpisami', 
    es: '', 
    ru: ''
  },
  {
    id: 2095,
    en: 'invert',
    pl: 'odwróć',
    es: '', 
    ru: ''
  },
  {
    id: 2096,
    en: 'Geo fence constraints diameter',
    pl: 'Średnica ograniczeń ogrodzenia geograficznegos', 
    es: '', 
    ru: ''
  },
  {
    id: 2097,
    en: 'Set the engine temperature limit',
    pl: 'Ustaw limit temperatury silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 2098,
    en: 'The number you set here is the maximum engine temperature that once crossed, will generate an alert',
    pl: 'Liczba, którą tutaj ustawisz, to maksymalna temperatura silnika, która po przekroczeniu wygeneruje alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2099,
    en: 'Set the Rpm limit',
    pl: 'Ustaw limit obrotów', 
    es: '', 
    ru: ''
  },
  {
    id: 2100,
    en: 'The number you set here is the maximum Rpm that once crossed, will generate an alert',
    pl: 'Ustawiona tutaj liczba to maksymalne obroty, które po przekroczeniu wygenerują alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2101,
    en: 'Set the maximum of gasoline used in 24h',
    pl: 'Ustaw maksymalne zużycie benzyny w ciągu 24 godzin', 
    es: '', 
    ru: ''
  },
  {
    id: 2102,
    en: 'The number you set here is the maximum km of gasoline/diesel usage that once crossed, will generate an alert',
    pl: 'Liczba, którą tu ustawisz, to maksymalne km zużycia benzyny, które po przekroczeniu wygenerują alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2103,
    en: 'Set the gas maintenance alert',
    pl: 'Ustaw alert konserwacji gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2104,
    en: 'The number you set here is the maximum number of km that once crossed, will generate an alert',
    pl: 'Liczba, którą tutaj ustawisz, to maksymalna liczba kilometrów, które po przekroczeniu wygenerują alert', 
    es: '', 
    ru: ''
  },
  {
    id: 2105,
    en: 'Custom ID',
    pl: 'Niestandardowe ID', 
    es: '', 
    ru: ''
  },
  {
    id: 2106,
    en: 'Engine type',
    pl: 'Typ silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 2107,
    en: 'petrol',
    pl: 'benzyna', 
    es: '', 
    ru: ''
  },
  {
    id: 2108,
    en: 'diesel',
    pl: 'olej napędowy', 
    es: '', 
    ru: ''
  },
  {
    id: 2109,
    en: 'Average consumption petrol/diesel',
    pl: 'Średnie zużycie benzyny / oleju napędowego', 
    es: '', 
    ru: ''
  },
  {
    id: 2110,
    en: 'Units in liters, float',
    pl: 'Jednostki w litrach, pływako', 
    es: '', 
    ru: ''
  },
  {
    id: 2111,
    en: 'Select alternative fuel type',
    pl: 'Wybierz alternatywny rodzaj paliwa', 
    es: '', 
    ru: ''
  },
  {
    id: 2112,
    en: 'that fits into the tank',
    pl: 'który pasuje do zbiornika', 
    es: '', 
    ru: ''
  },
  {
    id: 2113,
    en: 'Whole number (ex.: 55), amount of gas LPG/CNG that fits into the tank',
    pl: 'Całkowita liczba (np .: 55), ilość gazu LPG / CNG mieszcząca się w zbiorniku', 
    es: '', 
    ru: ''
  },
  {
    id: 2114,
    en: 'Custom settings (if ticked following change will apply)',
    pl: 'Ustawienia niestandardowe (jeśli zaznaczone, zastosowana zostanie zmiana)', 
    es: '', 
    ru: ''
  },
  {
    id: 2115,
    en: 'LPG/CNG vs PET/DIE fuel usage increase [%]',
    pl: 'Wzrost zużycia paliwa LPG / CNG vs PET / DIE [%]', 
    es: '', 
    ru: ''
  },
  {
    id: 2116,
    en: '(ex.: 15). Enter of how much % more LPG/CNG fuel are you using on the same distance compared to PET/DIE.',
    pl: '(np .: 15). Wpisz, o ile% więcej paliwa LPG / CNG zużywasz na tym samym dystansie w porównaniu do PET / DIE.', 
    es: '', 
    ru: ''
  },
  {
    id: 2117,
    en: 'Phone number format (ex: 48999666333)',
    pl: 'Format numeru telefonu (np .: 48999666333)', 
    es: '', 
    ru: ''
  },
  {
    id: 2118,
    en: 'Number of vehicle refuelings',
    pl: 'Liczba tankowań pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 2119,
    en: 'Current module status',
    pl: 'Aktualny stan modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2120,
    en: 'Number of times vehicle was locked',
    pl: 'Ile razy pojazd był zablokowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2121,
    en: 'Registration date',
    pl: 'Data rejestracji', 
    es: '', 
    ru: ''
  },
  {
    id: 2122,
    en: 'Settings updated',
    pl: 'Ustawienia zaktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2123,
    en: 'Hardware version',
    pl: 'Wersja sprzętu', 
    es: '', 
    ru: ''
  },
  {
    id: 2124,
    en: 'Firmware version',
    pl: 'Wersja oprogramowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2125,
    en: 'IMSI number of the SIM card',
    pl: 'Numer IMSI karty SIM', 
    es: '', 
    ru: ''
  },
  {
    id: 2126,
    en: 'Gas controler model',
    pl: 'Model zasilacza gazowego', 
    es: '', 
    ru: ''
  },
  {
    id: 2127,
    en: 'Gas controler firmware version',
    pl: 'Wersja oprogramowania sprzętowego regulatora gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2128,
    en: 'Gas controler firmware date',
    pl: 'Data oprogramowania sprzętowego regulatora gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2129,
    en: 'Buffer data from date',
    pl: 'Dane bufora od daty', 
    es: '', 
    ru: ''
  },
  {
    id: 2130,
    en: 'Buffer data number of records',
    pl: 'Liczba rekordów danych bufora', 
    es: '', 
    ru: ''
  },
  {
    id: 2131,
    en: 'Confirm your choice by typing in the Serial No number',
    pl: 'Potwierdź swój wybór, wpisując numer N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2132,
    en: 'reason',
    pl: 'powód', 
    es: '', 
    ru: ''
  },
  {
    id: 2133,
    en: 'Additional notes',
    pl: 'Dodatkowe uwagi', 
    es: '', 
    ru: ''
  },
  {
    id: 2134,
    en: 'You are trying to lock vehicle that is not paired yet. Please make sure to pair the vehicle first',
    pl: 'Próbujesz zablokować pojazd, który nie jest jeszcze sparowany. Najpierw sparuj pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 2135,
    en: 'You are about to lock vehicle with module with Serial No',
    pl: 'Zamierzasz zablokować pojazd modułem o numerze N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2136,
    en: 'Confirm your choice by typing in the Serial No',
    pl: 'Potwierdź swój wybór, wpisując numer N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2137,
    en: 'Module responded with an error',
    pl: 'Moduł odpowiedział błędem', 
    es: '', 
    ru: ''
  },
  {
    id: 2138,
    en: 'Make sure that module is connected to gas controller',
    pl: 'Upewnij się, że moduł jest podłączony do sterownika gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2139,
    en: 'Make sure that ignition key is on',
    pl: 'Upewnij się, że kluczyk zapłonu jest włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2140,
    en: 'Press the pair button to start the pairing process',
    pl: 'Naciśnij przycisk parowania, aby rozpocząć proces parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2141,
    en: 'Module is currently unpaired',
    pl: 'Moduł nie jest obecnie sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2142,
    en: 'Module is currently paired',
    pl: 'Moduł jest obecnie sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2143,
    en: 'Waiting for the new pairing request to be processed. Please turn the ignition key off for 10s and turn it on again',
    pl: 'Oczekiwanie na przetworzenie nowego żądania parowania. Wyłącz zapłon na 10 sekund i włącz go ponownie', 
    es: '', 
    ru: ''
  },
  {
    id: 2144,
    en: 'Waiting for the new unpairing request to be processed',
    pl: 'Czekam na przetworzenie nowego żądania rozparowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2145,
    en: 'Request',
    pl: 'Żądanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2146,
    en: 'State',
    pl: 'Stan', 
    es: '', 
    ru: ''
  },
  {
    id: 2147,
    en: 'Module confirmation',
    pl: 'Potwierdzenie modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2148,
    en: 'Keep me signed in',
    pl: 'Nie wylogowuj mnie', 
    es: '', 
    ru: ''
  },
  {
    id: 2149,
    en: 'No request sent yet',
    pl: 'Żaden wniosek nie został jeszcze wysłany', 
    es: '', 
    ru: ''
  },
  {
    id: 2150,
    en: 'Waiting for pairing request to be sent',
    pl: 'Czekam na wysłanie prośby o parowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2151,
    en: 'Pairing request sent',
    pl: 'Wysłano żądanie parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2152,
    en: 'Unpairing request sent',
    pl: 'Wysłano prośbę o rozparowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2153,
    en: 'Waiting for module to confirm the pairing request',
    pl: 'Czekam, aż moduł potwierdzi żądanie parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2154,
    en: 'Waiting for module to confirm the unpairing request',
    pl: 'Oczekiwanie na potwierdzenie przez moduł żądania rozparowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2155,
    en: 'Request confirmed by the module',
    pl: 'Żądanie potwierdzone przez moduł', 
    es: '', 
    ru: ''
  },
  {
    id: 2156,
    en: 'Module responded with an error',
    pl: 'Moduł odpowiedział błędem', 
    es: '', 
    ru: ''
  },
  {
    id: 2157,
    en: 'Module is waiting to be paired',
    pl: 'Moduł czeka na sparowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2158,
    en: 'Module is currently paired',
    pl: 'Moduł jest obecnie sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2159,
    en: 'You are about to delete module with Serial No',
    pl: 'Zamierzasz usunąć moduł z numerem Serial No', 
    es: '', 
    ru: ''
  },
  {
    id: 2160,
    en: 'You will loose access to all the data gathered by this vehicle. This action can not be reversed. Are you sure you want to proceed?',
    pl: 'Utracisz dostęp do wszystkich danych zebranych przez ten pojazd. Tej czynności nie można cofnąć. Na pewno chcesz kontynuować?', 
    es: '', 
    ru: ''
  },
  {
    id: 2161,
    en: 'Confirm your choice by typing in the module Serial No',
    pl: 'Potwierdź swój wybór wpisując numer N. Seryjny modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2162,
    en: 'Confirm your choice by typing in the module Serial No',
    pl: 'Potwierdź swój wybór wpisując numer N. Seryjny modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2163,
    en: 'Module Serial No is missing',
    pl: 'Brak modułu N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2164,
    en: 'Please make sure you have typed the module Serial No correctly',
    pl: 'Upewnij się, że poprawnie wpisałeś N. Seryjny modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2165,
    en: 'Custom ID is missing',
    pl: 'Brak identyfikatora niestandardowego', 
    es: '', 
    ru: ''
  },
  {
    id: 2166,
    en: 'There are no history data for this vehicle',
    pl: 'Brak danych historycznych dla tego pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2167,
    en: 'Tank level',
    pl: 'Poziom cysterny', 
    es: '', 
    ru: ''
  },
  {
    id: 2167,
    en: 'Last 30 days',
    pl: 'Ostatnie 30 dni', 
    es: '', 
    ru: ''
  },
  {
    id: 2168,
    en: 'Gas Analog',
    pl: 'Gaz analogowy', 
    es: '', 
    ru: ''
  },
  {
    id: 2169,
    en: 'Invert the graph',
    pl: 'Odwróć wykres', 
    es: '', 
    ru: ''
  },
  {
    id: 2170,
    en: 'There are no fuel usage data for this vehicle',
    pl: 'Brak danych o zużyciu paliwa dla tego pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2171,
    en: 'There are no last position data for this vehicle',
    pl: 'Brak danych dotyczących ostatniej pozycji dla tego pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2172,
    en: 'Last position date',
    pl: 'Data ostatniej pozycji', 
    es: '', 
    ru: ''
  },
  {
    id: 2172,
    en: 'Last position date',
    pl: 'Data ostatniej pozycji', 
    es: '', 
    ru: ''
  },
  {
    id: 2173,
    en: 'Last position latitude',
    pl: 'Szerokość geograficzna ostatniej pozycji', 
    es: '', 
    ru: ''
  },
  {
    id: 2174,
    en: 'Last position longitude',
    pl: 'Długość geograficzna ostatniej pozycji', 
    es: '', 
    ru: ''
  },
  {
    id: 2175,
    en: 'Last position point',
    pl: 'Ostatni punkt pozycji', 
    es: '', 
    ru: ''
  },
  {
    id: 2176,
    en: 'There are no refuel data for this vehicle.',
    pl: 'Brak danych dotyczących tankowania tego pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2177,
    en: 'Latitude',
    pl: 'Szerokość', 
    es: '', 
    ru: ''
  },
  {
    id: 2178,
    en: 'Longitude',
    pl: 'Długość geograficzna', 
    es: '', 
    ru: ''
  },
  {
    id: 2179,
    en: 'Who',
    pl: 'Kto', 
    es: '', 
    ru: ''
  },
  {
    id: 2180,
    en: 'Description',
    pl: 'Opis', 
    es: '', 
    ru: ''
  },
  {
    id: 2181,
    en: 'There are no route data for this vehicle for the date',
    pl: 'Brak danych trasy dla tego pojazdu na ten dzień', 
    es: '', 
    ru: ''
  },
  {
    id: 2182,
    en: 'Try selecting a different date',
    pl: 'Spróbuj wybrać inną datę', 
    es: '', 
    ru: ''
  },
  {
    id: 2183,
    en: 'All routes',
    pl: 'Wszystkie trasy', 
    es: '', 
    ru: ''
  },
  {
    id: 2184,
    en: 'There are no reports for this vehicle. Try a different date range.',
    pl: 'Brak raportów dla tego pojazdu. Wypróbuj inny zakres dat.', 
    es: '', 
    ru: ''
  },
  {
    id: 2186,
    en: 'Data for the period',
    pl: 'Dane za okres', 
    es: '', 
    ru: ''
  },
  {
    id: 2187,
    en: 'Total drive time',
    pl: 'Całkowity czas jazdy', 
    es: '', 
    ru: ''
  },
  {
    id: 2188,
    en: 'Driving on',
    pl: 'Jazda na', 
    es: '', 
    ru: ''
  },
  {
    id: 2189,
    en: 'Total',
    pl: 'Całkowity', 
    es: '', 
    ru: ''
  },
  {
    id: 2190,
    en: 'Total distance',
    pl: 'Całkowity dystanc', 
    es: '', 
    ru: ''
  },
  {
    id: 2191,
    en: 'Estimated savings',
    pl: 'Szacowane oszczędności', 
    es: '', 
    ru: ''
  },
  {
    id: 2192,
    en: 'Estimated total cost',
    pl: 'Szacowany całkowity koszt', 
    es: '', 
    ru: ''
  },
  {
    id: 2193,
    en: 'total distance',
    pl: 'całkowity dystans', 
    es: '', 
    ru: ''
  },
  {
    id: 2194,
    en: 'on default fuel',
    pl: 'na domyślnym paliwie', 
    es: '', 
    ru: ''
  },
  {
    id: 2195,
    en: 'potential savings if driving 100% on LPG/CNG',
    pl: 'potencjalne oszczędności', 
    es: '', 
    ru: ''
  },
  {
    id: 2196,
    en: 'estimated savings',
    pl: 'szacunkowe oszczędności', 
    es: '', 
    ru: ''
  },
  {
    id: 2197,
    en: 'estimated cost without using any',
    pl: 'szacunkowy koszt bez', 
    es: '', 
    ru: ''
  },
  {
    id: 2198,
    en: 'estimated total cost',
    pl: 'szacunkowy koszt całkowity', 
    es: '', 
    ru: ''
  },
  {
    id: 2199,
    en: 'total cost of the gas',
    pl: 'całkowity koszt gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2200,
    en: 'estimated PET/DIE cost',
    pl: 'szacunkowy koszt PET/DIE', 
    es: '', 
    ru: ''
  },
  {
    id: 2201,
    en: 'Petrol injection time',
    pl: 'Czas wtrysku benzyny', 
    es: '', 
    ru: ''
  },
  {
    id: 2202,
    en: 'Gas injection time',
    pl: 'Czas wtrysku gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2203,
    en: 'Gas pressure',
    pl: 'Ciśnienie gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2204,
    en: 'Manifold pressure',
    pl: 'Ciśnienie w kolektorze', 
    es: '', 
    ru: ''
  },
  {
    id: 2205,
    en: 'Gas temperature',
    pl: 'Temperatura gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2206,
    en: 'Reducer temperature',
    pl: 'Temperatura reduktora', 
    es: '', 
    ru: ''
  },
  {
    id: 2207,
    en: 'Supply voltage',
    pl: 'Napięcie zasilania', 
    es: '', 
    ru: ''
  },
  {
    id: 2208,
    en: 'Engine RPM',
    pl: 'Obroty silnika RPM', 
    es: '', 
    ru: ''
  },
  {
    id: 2209,
    en: 'GSM signal',
    pl: 'Sygnał GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2210,
    en: 'GSM battery module',
    pl: 'Moduł baterii GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2211,
    en: 'GSM supply voltage',
    pl: 'Napięcie zasilania GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2212,
    en: 'Speed',
    pl: 'Prędkość', 
    es: '', 
    ru: ''
  },
  {
    id: 2213,
    en: 'Fuel usage',
    pl: 'Zużycie paliwa', 
    es: '', 
    ru: ''
  },
  {
    id: 2214,
    en: 'Intelligent GAS level status',
    pl: 'Inteligentny stan poziomu gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2215,
    en: 'Enter your email address and we\'ll send you a link to reset your password.',
    pl: 'Wpisz swój adres e-mail, a wyślemy Ci link do zresetowania hasła.', 
    es: '', 
    ru: ''
  },
  {
    id: 2216,
    en: 'Password confirmation',
    pl: 'Potwierdzenie hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 2217,
    en: 'Password strength',
    pl: 'Siła hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 2218,
    en: 'Weak',
    pl: 'Słabe', 
    es: '', 
    ru: ''
  },
  {
    id: 2218,
    en: 'Weak',
    pl: 'Słabe', 
    es: '', 
    ru: ''
  },
  {
    id: 2219,
    en: 'Fair',
    pl: 'Średnie', 
    es: '', 
    ru: ''
  },
  {
    id: 2220,
    en: 'Good',
    pl: 'Dobre', 
    es: '', 
    ru: ''
  },
  {
    id: 2221,
    en: 'Strong',
    pl: 'Silne', 
    es: '', 
    ru: ''
  },
  {
    id: 2222,
    en: 'Mobile',
    pl: 'Telefon komórkowy', 
    es: '', 
    ru: ''
  },
  {
    id: 2223,
    en: 'Average speed',
    pl: 'Średnia prędkość', 
    es: '', 
    ru: ''
  },
  {
    id: 2224,
    en: 'LPG cons.',
    pl: 'Zuzycie LPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2225,
    en: 'LPG cost',
    pl: 'Koszt LPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2226,
    en: 'PET/DIE cost',
    pl: 'Koszt PET/DIE', 
    es: '', 
    ru: ''
  },
  {
    id: 2227,
    en: 'Without LPG',
    pl: 'Bez LPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2228,
    en: '% on LPG',
    pl: '% na LPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2229,
    en: 'Est. savings',
    pl: 'Szac. oszcz.', 
    es: '', 
    ru: ''
  },
  {
    id: 2230,
    en: 'Pot. savings',
    pl: 'Pot. oszcz.', 
    es: '', 
    ru: ''
  },
  {
    id: 2231,
    en: 'CO2 savings',
    pl: 'Oszcz. CO2', 
    es: '', 
    ru: ''
  },
  {
    id: 2232,
    en: 'How to correctly install the GSM module and connect it to the application',
    pl: 'Jak poprawnie zainstalować moduł GSM i połączyć go z aplikacją', 
    es: '', 
    ru: ''
  },
  {
    id: 2233,
    en: 'Help Index',
    pl: 'Indeks pomocy', 
    es: '', 
    ru: ''
  },
  {
    id: 2234,
    en: 'GSM module installation guide',
    pl: 'Instrukcja instalacji modułu GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2235,
    en: 'Connecting vehicle with the application',
    pl: 'Połączenie pojazdu z aplikacją', 
    es: '', 
    ru: ''
  },
  {
    id: 2236,
    en: 'Vehicle pairing guide (only required with lock feature turned on)',
    pl: 'Przewodnik parowania pojazdów (wymagany tylko przy włączonej funkcji blokady)', 
    es: '', 
    ru: ''
  },
  {
    id: 2237,
    en: 'Setting up the IGL (only required for the reports feature turned on)',
    pl: 'Konfigurowanie IGL (wymagane tylko przy włączonej funkcji raportów)', 
    es: '', 
    ru: ''
  },
  {
    id: 2238,
    en: 'Proceed with this step only if you managed to install the module correctly.',
    pl: 'Kontynuuj ten krok tylko wtedy, gdy udało Ci się poprawnie zainstalować moduł.', 
    es: '', 
    ru: ''
  },
  {
    id: 2239,
    en: 'Guide to connect a vehicle',
    pl: 'Przewodnik po podłączeniu pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2240,
    en: '1. Associate the module with the vehicle',
    pl: '1. Skojarz moduł z pojazdem', 
    es: '', 
    ru: ''
  },
  {
    id: 2241,
    en: 'Visit "Add new vehicle" page and fill in the details you have gathered in the first step before installing the GSM module. Fill in the details and press the "add vehicle" button. This will associate the GSM module with the vehicle in the app.',
    pl: 'Odwiedź stronę "Dodaj nowy pojazd" i wypełnij dane zebrane w pierwszym kroku przed zainstalowaniem modułu GSM. Uzupełnij dane i naciśnij przycisk „dodaj pojazd”. Spowoduje to powiązanie modułu GSM z pojazdem w aplikacji.', 
    es: '', 
    ru: ''
  },
  {
    id: 2242,
    en: 'Figure 2.1.1 - Example of how to fill the form',
    pl: 'Rysunek 2.1.1 - Przykład wypełniania formularza', 
    es: '', 
    ru: ''
  },
  {
    id: 2243,
    en: '2. Checking that vehicle is connected',
    pl: '2. Sprawdzenie, czy pojazd jest podłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2244,
    en: 'Now it is time to check if the vehicle is sending any data to the application. Click on the "vehicles" tab and find the "Last connected" column. Once your module starts to send any data you will see that the date will be updated to today\'s day and time of the installation. Your module is now correctly sending data to the application.',
    pl: 'Teraz pora sprawdzić, czy pojazd wysyła dane do aplikacji. Kliknij kartę „pojazdy” i znajdź kolumnę „Ostatnio połączone”. Gdy moduł zacznie wysyłać dane, zobaczysz, że data zostanie zaktualizowana do dzisiejszego dnia i godziny instalacji. Twój moduł teraz poprawnie wysyła dane do aplikacji.', 
    es: '', 
    ru: ''
  },
  {
    id: 2245,
    en: 'Figure 2.2.1 - Vehicle tab screen with "Last connected" column.',
    pl: 'Rysunek 2.2.1 - Ekran zakładki Pojazd z kolumną "Ostatnie połączenie".', 
    es: '', 
    ru: ''
  },
  {
    id: 2246,
    en: 'Continue to vehicle pairing guide',
    pl: 'Przejdź do przewodnika parowania pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 2247,
    en: 'Proceed with this step only if you managed to install the module and connected the vehicle to the app correctly.',
    pl: 'Kontynuuj ten krok tylko wtedy, gdy udało Ci się zainstalować moduł i poprawnie połączyć pojazd z aplikacją.', 
    es: '', 
    ru: ''
  },
  {
    id: 2248,
    en: '1. Pair the module',
    pl: '1. Sparuj moduł', 
    es: '', 
    ru: ''
  },
  {
    id: 2249,
    en: 'Select the vehicle you would like to pair with the GAS module from the list of vehicles. Click on the "settings" icon. Select the "Pair" tab. Press the "PAIR MODULE" button. Wait a few seconds and turn the ignition key on. Pairing should start and complete automatically.',
    pl: 'Wybierz pojazd, który chcesz sparować z modułem GAS z listy pojazdów. Kliknij ikonę „Ustawienia”. Wybierz kartę „Paruj”. Naciśnij przycisk „PAIR MODULE”. Poczekaj kilka sekund i włącz zapłon. Parowanie powinno rozpocząć się i zakończyć automatycznie.', 
    es: '', 
    ru: ''
  },
  {
    id: 2250,
    en: 'Figure 3.1.1 - Initial pairing screen',
    pl: 'Rysunek 3.1.1 - Ekran początkowego parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2251,
    en: 'Once the module is paired the screen should look like this:',
    pl: 'Po sparowaniu modułu ekran powinien wyglądać następująco:', 
    es: '', 
    ru: ''
  },
  {
    id: 2252,
    en: 'Figure 3.1.2 - Pairing successful screen',
    pl: 'Rysunek 3.1.2 - Ekran pomyślnego parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2253,
    en: 'Should there be an error you will be presented with the screen similar to this:',
    pl: 'W przypadku wystąpienia błędu zostanie wyświetlony ekran podobny do tego:', 
    es: '', 
    ru: ''
  },
  {
    id: 2254,
    en: 'Figure 3.1.3 - Pairing error screen',
    pl: 'Rysunek 3.1.3 - Ekran błędu parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2255,
    en: 'After completing all of the above with no errors you will be ready to use your module.',
    pl: 'Po wykonaniu wszystkich powyższych czynności bez błędów będziesz gotowy do korzystania z modułu.', 
    es: '', 
    ru: ''
  },
  {
    id: 2256,
    en: 'Proceed with this step only if you managed to install the module and connected the vehicle to the app correctly.',
    pl: 'Kontynuuj ten krok tylko wtedy, gdy udało Ci się zainstalować moduł i poprawnie połączyć pojazd z aplikacją.', 
    es: '', 
    ru: ''
  },
  {
    id: 2257,
    en: 'Enabling IGL in the AC STAG software',
    pl: 'Włączenie IGL w programie AC STAG', 
    es: '', 
    ru: ''
  },
  {
    id: 2258,
    en: 'Figure 4.1.1 - Gas level indicator configuration window',
    pl: 'Rysunek 4.1.1 - Okno konfiguracji wskaźnika poziomu gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2259,
    en: 'The current status of the "Intelligent Gas Level" function is shown in the software. Status options:',
    pl: 'Bieżący stan funkcji „Inteligentny poziom gazu” jest wyświetlany w oprogramowaniu. Opcje statusu:', 
    es: '', 
    ru: ''
  },
  {
    id: 2260,
    en: 'Inactive',
    pl: 'Nieaktywny', 
    es: '', 
    ru: ''
  },
  {
    id: 2261,
    en: 'the function is OFF',
    pl: 'funkcja jest wyłączona', 
    es: '', 
    ru: ''
  },
  {
    id: 2262,
    en: 'Active',
    pl: 'Aktywny', 
    es: '', 
    ru: ''
  },
  {
    id: 2263,
    en: 'gas level is calculated by the "Intelligent Gas Level" function',
    pl: 'poziom gazu jest obliczany przez funkcję „Intelligent Gas Level”', 
    es: '', 
    ru: ''
  },
  {
    id: 2264,
    en: 'Calibration is not done',
    pl: 'Kalibracja nie została wykonana', 
    es: '', 
    ru: ''
  },
  {
    id: 2265,
    en: 'the function is OFF and waits for auto-calibration of the WPGH indicator; it does not perform a gas level calculation',
    pl: 'funkcja jest wyłączona i oczekuje na autokalibrację wskaźnika WPGH; nie oblicza poziomu gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2266,
    en: 'Nieobsługiwany typ czujnika',
    pl: 'Nieobsługiwany typ czujnika', 
    es: '', 
    ru: ''
  },
  {
    id: 2267,
    en: 'the installed gas level sensor is not supported (WPGH version is required)',
    pl: 'zainstalowany czujnik poziomu gazu nie jest obsługiwany (wymagana wersja WPGH)', 
    es: '', 
    ru: ''
  },
  {
    id: 2268,
    en: 'The learning process (stage1)',
    pl: 'Proces uczenia się (etap 1)', 
    es: '', 
    ru: ''
  },
  {
    id: 2269,
    en: 'after the WPGH indicator autocalibration - the function waits until the driver uses all gas in the tank and fills up to full',
    pl: 'po autokalibracji wskaźnika WPGH - funkcja oczekuje aż kierowca zużyje cały gaz ze zbiornika i napełni się do pełna', 
    es: '', 
    ru: ''
  },
  {
    id: 2270,
    en: 'The learning process (stage2)',
    pl: 'Proces uczenia się (etap 2)', 
    es: '', 
    ru: ''
  },
  {
    id: 2271,
    en: 'the function waits until the driver uses all gas in the tang again and fills up to full',
    pl: 'funkcja czeka, aż kierowca ponownie zużyje cały gaz w trzpieniu i napełni się do pełna', 
    es: '', 
    ru: ''
  },
  {
    id: 2272,
    en: 'Waiting to refuel',
    pl: 'Czekam na zatankowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2273,
    en: 'the function was not able to detect the initial level of gas; this is why it\'s on hold until the tank is filled up again; this may be the case when the tank has not been refilled to full',
    pl: 'funkcja nie była w stanie wykryć początkowego poziomu gazu; dlatego jest wstrzymany do ponownego napełnienia zbiornika; może to mieć miejsce, gdy zbiornik nie został ponownie napełniony', 
    es: '', 
    ru: ''
  },
  {
    id: 2274,
    en: 'IGL calibration',
    pl: 'Kalibracja IGL', 
    es: '', 
    ru: ''
  },
  {
    id: 2275,
    en: 'This function is used to indicate the level of gas to the user more precisely (on the LED switch panel) without the impact of the terrain slope. It is particularly important in the mountain area, where long uphill driving or long downhill driving results in indications that do not represent the actual gas amount in the tank. This problem should be eliminated by the described function.',
    pl: 'Funkcja ta służy do dokładniejszego wskazywania użytkownikowi poziomu gazu (na panelu przełączników LED) bez wpływu nachylenia terenu. Jest to szczególnie ważne w obszarach górskich, gdzie długa jazda pod górę lub długa jazda z górki skutkuje wskazaniami, które nie odzwierciedlają rzeczywistej ilości gazu w zbiorniku. Ten problem powinien zostać wyeliminowany przez opisaną funkcję.', 
    es: '', 
    ru: ''
  },
  {
    id: 2276,
    en: 'Perform the following steps to use the function:',
    pl: 'Aby użyć tej funkcji, wykonaj następujące czynności:', 
    es: '', 
    ru: ''
  },
  {
    id: 2277,
    en: 'Perform WPGH level indicator auto-calibration',
    pl: 'Wykonaj autokalibrację wskaźnika poziomu WPGH', 
    es: '', 
    ru: ''
  },
  {
    id: 2278,
    en: 'Use all gas in the tank',
    pl: 'Użyj całego gazu w zbiorniku', 
    es: '', 
    ru: ''
  },
  {
    id: 2279,
    en: 'Fill up the tank to full',
    pl: 'Napełnij zbiornik do pełna', 
    es: '', 
    ru: ''
  },
  {
    id: 2280,
    en: 'Use all gas in the tank',
    pl: 'Użyj całego gazu w zbiorniku', 
    es: '', 
    ru: ''
  },
  {
    id: 2281,
    en: 'When the above-mentioned steps have been performed, the displayed gas level will be calculated based on the actually used gas, instead of the WPGH indicator signal.',
    pl: 'Po wykonaniu powyższych czynności wyświetlany poziom gazu zostanie obliczony na podstawie faktycznie używanego gazu, zamiast sygnału wskaźnika WPGH.', 
    es: '', 
    ru: ''
  },

  {
    id: 2282,
    en: 'GSM module installation guide',
    pl: 'Instrukcja instalacji modułu GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2283,
    en: '1. Connect module according to the connection diagram',
    pl: '1. Podłączyć moduł zgodnie ze schematem połączeń', 
    es: '', 
    ru: ''
  },
  {
    id: 2284,
    en: 'GSM module is compatible with the following gas modules: (list soon to be available)',
    pl: 'Moduł GSM jest kompatybilny z następującymi modułami gazowymi: (lista wkrótce dostępna)', 
    es: '', 
    ru: ''
  },
  {
    id: 2285,
    en: 'Before connecting the GSM module to the GAS module, make sure you write down the following details. It might be harder to get any of those once you install the GSM module:',
    pl: 'Przed podłączeniem modułu GSM do modułu GAS należy zanotować poniższe dane. Uzyskanie któregokolwiek z nich może być trudniejsze po zainstalowaniu modułu GSM:', 
    es: '', 
    ru: ''
  },
  {
    id: 2286,
    en: 'Serial No number of the GSM module (this can be found on the module itself)',
    pl: 'Numer N. Seryjny modułu GSM (można go znaleźć na samym module)', 
    es: '', 
    ru: ''
  },
  {
    id: 2287,
    en: 'Phone number of the SIM card you have inserted in the module',
    pl: 'Numer telefonu karty SIM włożonej do modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2288,
    en: 'Gas tank size in l',
    pl: 'Rozmiar zbiornika gazu w l', 
    es: '', 
    ru: ''
  },
  {
    id: 2289,
    en: 'Fuel type (petrol or diesel)',
    pl: 'Rodzaj paliwa (benzyna lub olej napędowy)', 
    es: '', 
    ru: ''
  },
  {
    id: 2290,
    en: 'Average consumption of petrol or diesel per 100km',
    pl: 'Średnie zużycie benzyny lub oleju napędowego na 100 km', 
    es: '', 
    ru: ''
  },
  {
    id: 2291,
    en: 'Once you have written down the details, proceed with the installation as per the following diagram:',
    pl: 'Po zapisaniu szczegółów kontynuuj instalację zgodnie z poniższym schematem:', 
    es: '', 
    ru: ''
  },
  {
    id: 2292,
    en: 'Figure 1.1.1 - Connection installation schema',
    pl: 'Rysunek 1.1.1 - Schemat instalacji połączenia', 
    es: '', 
    ru: ''
  },
  {
    id: 2293,
    en: 'Proceed to the next step only if you have managed to connect the module correctly.',
    pl: 'Przejdź do następnego kroku tylko wtedy, gdy udało Ci się poprawnie podłączyć moduł.', 
    es: '', 
    ru: ''
  },
  {
    id: 2294,
    en: '2. Check LED and fix problems indicated by it',
    pl: '2. Sprawdź diodę LED i napraw wskazywane przez nią problemy', 
    es: '', 
    ru: ''
  },
  {
    id: 2295,
    en: 'GSM module trying to log in to the GSM network is passing through an ordered set of processes. This is indicated by the LED flashing from 9 flashes to 1 flash. If there is an issue in any of the steps, the module will stop on that position. We can count the number of flashes and figure out where the issue might be:',
    pl: 'Moduł GSM próbujący zalogować się do sieci GSM przechodzi przez uporządkowany zbiór procesów. Jest to sygnalizowane miganiem diody LED z 9 do 1 mignięcia. Jeśli wystąpi problem w którymkolwiek z kroków, moduł zatrzyma się na tej pozycji. Możemy policzyć liczbę błysków i dowiedzieć się, gdzie może być problem:', 
    es: '', 
    ru: ''
  },
  {
    id: 2296,
    en: 'The number of flashes:',
    pl: 'Liczba błysków:', 
    es: '', 
    ru: ''
  },
  {
    id: 2297,
    en: 'all ok',
    pl: 'wszystko ok', 
    es: '', 
    ru: ''
  },
  {
    id: 2298,
    en: 'error connecting to the server',
    pl: 'błąd podczas łączenia się z serwerem', 
    es: '', 
    ru: ''
  },
  {
    id: 2299,
    en: 'server IP deployment error (DNS)',
    pl: 'błąd wdrożenia adresu IP serwera (DNS)', 
    es: '', 
    ru: ''
  },
  {
    id: 2300,
    en: 'error in getting the module IP from the operator',
    pl: 'błąd podczas pobierania adresu IP modułu od operatora', 
    es: '', 
    ru: ''
  },
  {
    id: 2301,
    en: 'GPRS activation error',
    pl: 'Błąd aktywacji GPRS', 
    es: '', 
    ru: ''
  },
  {
    id: 2302,
    en: 'no GSM network',
    pl: 'brak sieci GSM', 
    es: '', 
    ru: ''
  },

  {
    id: 2303,
    en: 'no APN configuration for the used SIM card',
    pl: 'brak konfiguracji APN dla używanej karty SIM', 
    es: '', 
    ru: ''
  },
  {
    id: 2304,
    en: 'the SIM card requires a PIN',
    pl: 'karta SIM wymaga kodu PIN', 
    es: '', 
    ru: ''
  },
  {
    id: 2305,
    en: 'no SIM card',
    pl: 'brak karty SIM', 
    es: '', 
    ru: ''
  },
  {
    id: 2306,
    en: 'Example',
    pl: 'Przykład', 
    es: '', 
    ru: ''
  },
  {
    id: 2307,
    en: 'server IP deployment error (DNS) will look like this',
    pl: 'tak będzie wyglądał błąd wdrożenia adresu IP serwera (DNS)', 
    es: '', 
    ru: ''
  },
  {
    id: 2308,
    en: 'Figure 1.2.1 - Example of a correctly connected module. Flash only once each 1500ms. NOTE: you do not need to open the module from its casing to be able to see the led flashing. You will be able to see the led from outside of the casing.',
    pl: 'Rysunek 1.2.1 - Przykład poprawnie podłączonego modułu. Błysk tylko raz na 1500 ms. UWAGA: nie trzeba otwierać modułu z obudowy, aby zobaczyć miganie diody. Będziesz mógł zobaczyć led z zewnątrz obudowy.', 
    es: '', 
    ru: ''
  },
  {
    id: 2309,
    en: 'Proceed to the next step only if the LED indicates no problems',
    pl: 'Przejdź do następnego kroku tylko wtedy, gdy dioda LED nie wskazuje żadnych problemów', 
    es: '', 
    ru: ''
  },
  {
    id: 2310,
    en: 'Alert for',
    pl: 'Alert dla', 
    es: '', 
    ru: ''
  },
  {
    id: 2311,
    en: 'The page you are looking for can not be found',
    pl: 'Nie można znaleźć strony, której szukasz', 
    es: '', 
    ru: ''
  },
  {
    id: 2312,
    en: 'Return back to home page',
    pl: 'Wróć do strony głównej', 
    es: '', 
    ru: ''
  },
  {
    id: 2313,
    en: 'Station ID can not be empty',
    pl: 'Identyfikator stacji nie może być pusty', 
    es: '', 
    ru: ''
  },
  {
    id: 2314,
    en: 'Please make sure you have typed the station ID correctly',
    pl: 'Upewnij się, że poprawnie wpisałeś identyfikator stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2315,
    en: 'Figure 2.2.1 - Vehicle tab screen with "Last connected" column.',
    pl: 'Rysunek 2.2.1 - Ekran zakładki Pojazd z kolumną „Ostatnie połączenie”.', 
    es: '', 
    ru: ''
  },

  {
    id: 2316,
    en: 'on',
    pl: 'włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2317,
    en: 'off',
    pl: 'wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2318,
    en: 'pause',
    pl: 'pauza', 
    es: '', 
    ru: ''
  },
  {
    id: 2319,
    en: 'Continue to the vehicle to app connecting guide',
    pl: 'Przejdź do przewodnika łączenia pojazdu z aplikacją', 
    es: '', 
    ru: ''
  },
  {
    id: 2320,
    en: 'Continue to IGL setup guide',
    pl: 'Przejdź do przewodnika łączenia pojazdu z aplikacją', 
    es: '', 
    ru: ''
  },
  {
    id: 2321,
    en: 'Quarter',
    pl: 'Kwartał', 
    es: '', 
    ru: ''
  },
  {
    id: 2322,
    en: 'Serial No is missing',
    pl: 'Brakuje N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2323,
    en: 'Please make sure you have typed the Serial No correctly',
    pl: 'Upewnij się, że poprawnie wpisałeś N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2324,
    en: 'You are trying to delete Serial No that is paired already. Please, unpair the module from the vehicle first.',
    pl: 'Próbujesz usunąć numer N. Seryjny, który jest już sparowany. Proszę najpierw odłączyć moduł od pojazdu.', 
    es: '', 
    ru: ''
  },
  {
    id: 2325,
    en: 'There is a problem with the one or more Serial No(s). Please check they are consit of number and they have valid length (15 characters)',
    pl: 'Wystąpił problem z co najmniej jednym numerem N. Seryjny. Sprawdź, czy składają się na liczbę i mają prawidłową długość (15 znaków)', 
    es: '', 
    ru: ''
  },
  {
    id: 2326,
    en: 'Please add at least one valid Serial No',
    pl: 'Dodaj co najmniej jeden prawidłowy N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 2327,
    en: 'Loading...',
    pl: 'Ładowanie...', 
    es: '', 
    ru: ''
  },

  {
    id: 2328,
    en: 'Role is required',
    pl: 'Rola jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 2329,
    en: 'Email is required',
    pl: 'Email jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 2330,
    en: 'Password is required',
    pl: 'Haslo jest wymagane', 
    es: '', 
    ru: ''
  },

  {
    id: 2331,
    en: 'Technician',
    pl: 'Technik', 
    es: '', 
    ru: ''
  },
  {
    id: 2332,
    en: 'Accounts',
    pl: 'Konta', 
    es: '', 
    ru: ''
  },
  {
    id: 2333,
    en: 'Manager',
    pl: 'Menedżer', 
    es: '', 
    ru: ''
  },
  {
    id: 2334,
    en: 'Administrator',
    pl: 'Administrator', 
    es: '', 
    ru: ''
  },
  {
    id: 2335,
    en: 'User email can not be empty',
    pl: 'Adres e-mail użytkownika nie może być pusty', 
    es: '', 
    ru: ''
  },
  {
    id: 2336,
    en: 'Please make sure that the email matches the email of the user you want to delete',
    pl: 'Upewnij się, że adres e-mail jest zgodny z adresem e-mail użytkownika, którego chcesz usunąć', 
    es: '', 
    ru: ''
  },
  {
    id: 2337,
    en: 'Not paired',
    pl: 'Nie sparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2338,
    en: 'Paired & Locked',
    pl: 'Sparowane & Zablokowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2339,
    en: 'Paired & Unlocked',
    pl: 'Sparowane & Odblokowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2340,
    en: 'Paired & Unlocked',
    pl: 'Sparowane & Odblokowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2341,
    en: 'Not Paired/Unlocked',
    pl: 'Niesparowane/odblokowane', 
    es: '', 
    ru: ''
  },

  {
    id: 2342,
    en: 'inactive',
    pl: 'nieaktywny', 
    es: '', 
    ru: ''
  },
  {
    id: 2343,
    en: 'active',
    pl: 'aktywny', 
    es: '', 
    ru: ''
  },
  {
    id: 2344,
    en: 'WPG auto-calibration not performed',
    pl: 'Nie przeprowadzono autokalibracji WPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2345,
    en: 'unsupported WPG type',
    pl: 'Nie przeprowadzono autokalibracji WPG', 
    es: '', 
    ru: ''
  },
  {
    id: 2346,
    en: 'learning in progress (stage 1)',
    pl: 'nauka w toku (etap 1)', 
    es: '', 
    ru: ''
  },
  {
    id: 2347,
    en: 'learning in progress (stage 2)',
    pl: 'nauka w toku (etap 1)', 
    es: '', 
    ru: ''
  },
  {
    id: 2348,
    en: 'waiting for refueling',
    pl: 'czekam na tankowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2349,
    en: 'Current vehicle state',
    pl: 'Aktualny stan pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2350,
    en: 'Refuel date',
    pl: 'Data tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2351,
    en: 'Refuel latitude',
    pl: 'Szerokość geograficzna miejsca tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2352,
    en: 'Refuel longitude',
    pl: 'Długość geograficzna miejsca tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2353,
    en: 'Refuel point',
    pl: 'Punkt tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2354,
    en: 'Gas tank size is missing',
    pl: 'Brak rozmiaru zbiornika gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2355,
    en: 'Engine type consumption is missing',
    pl: 'Brakuje zużycia typu silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 2356,
    en: 'Average consumption is missing',
    pl: 'Brakuje średniego zużycia', 
    es: '', 
    ru: ''
  },
  {
    id: 2357,
    en: 'Consumption difference is missing',
    pl: 'Brakuje różnicy w zużyciu', 
    es: '', 
    ru: ''
  },
  {
    id: 2358,
    en: 'Phone number is missing',
    pl: 'Brak numeru telefonu', 
    es: '', 
    ru: ''
  },
  {
    id: 2359,
    en: 'Alternative fuel type is missing',
    pl: 'Brak alternatywnego rodzaju paliwa', 
    es: '', 
    ru: ''
  },
  {
    id: 2360,
    en: 'How to calibrate the gas module',
    pl: 'Jak skalibrować moduł gazowy', 
    es: '', 
    ru: ''
  },
  {
    id: 2361,
    en: 'to',
    pl: 'do', 
    es: '', 
    ru: ''
  },
  {
    id: 2362,
    en: 'Visit date',
    pl: 'Termin wizyty', 
    es: '', 
    ru: ''
  },
  {
    id: 2363,
    en: 'Visit latitude',
    pl: 'Szerokość geograficzna punktu wizyty', 
    es: '', 
    ru: ''
  },
  {
    id: 2364,
    en: 'Visit longitude',
    pl: 'Odwiedź długość geograficzną punktu', 
    es: '', 
    ru: ''
  },
  {
    id: 2364,
    en: 'Refuel point',
    pl: 'Punkt tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2365,
    en: 'We use',
    pl: 'Używamy', 
    es: '', 
    ru: ''
  },
  {
    id: 2366,
    en: 'cookies',
    pl: 'ciasteczka', 
    es: '', 
    ru: ''
  },
  {
    id: 2367,
    en: 'to give you the best experience we can',
    pl: 'aby zapewnić jak najlepsze wrażenia użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 2368,
    en: 'Got it!',
    pl: 'Rozumiem!', 
    es: '', 
    ru: ''
  },
  {
    id: 2369,
    en: 'I agree to the',
    pl: 'Zgadzam się z', 
    es: '', 
    ru: ''
  },
  {
    id: 2370,
    en: 'Terms & Conditions',
    pl: 'Zasady i Warunki', 
    es: '', 
    ru: ''
  },
  {
    id: 2371,
    en: 'This site uses cookies – small text files that are placed on your machine to help the site provide a better user experience. In general, cookies are used to retain user preferences, store information for things like shopping baskets, and provide anonymised tracking data to third party applications like Google Analytics. As a rule, cookies will make your browsing experience better. They are important for authentication.',
    pl: 'Ta strona wykorzystuje pliki cookie - małe pliki tekstowe, które są umieszczane na Twoim komputerze, aby pomóc witrynie zapewnić lepszą obsługę. Ogólnie rzecz biorąc, pliki cookie są używane do zachowania preferencji użytkownika, przechowywania informacji o takich rzeczach jak koszyki zakupów i dostarczania anonimowych danych śledzenia do aplikacji stron trzecich, takich jak Google Analytics. Z reguły pliki cookie poprawiają jakość przeglądania. Są ważne przy uwierzytelnianiu.',
    es: '', 
    ru: ''
  },
  {
    id: 2372,
    en: 'Cookies policy',
    pl: 'Polityka plików cookies', 
    es: '', 
    ru: ''
  },
  {
    id: 2373,
    en: 'I agree to the',
    pl: 'Zgadzam się z', 
    es: '', 
    ru: ''
  },
  {
    id: 2374,
    en: 'Please make sure you have agreed to the Terms and Conditons',
    pl: 'Upewnij się, że zaakceptowałeś Zasady i Warunki', 
    es: '', 
    ru: ''
  },
  {
    id: 2375,
    en: 'Roles',
    pl: 'Role', 
    es: '', 
    ru: ''
  },
  {
    id: 2376,
    en: 'Access to all the app capacbilities. Access to the features in the development.',
    pl: 'Dostęp do wszystkich możliwości aplikacji. Dostęp do funkcji w rozwoju.', 
    es: '', 
    ru: ''
  },
  {
    id: 2377,
    en: 'Access to all the app capabilities. Can create and manage users.',
    pl: 'Dostęp do wszystkich możliwości aplikacji. Może tworzyć użytkowników i zarządzać nimi.', 
    es: '', 
    ru: ''
  },

  {
    id: 2378,
    en: 'Main function of this role is access to the savings, reports, dealing with locking and unlocking of the vehicles.',
    pl: 'Główną funkcją tej roli jest dostęp do oszczędności, raportów, zajmowanie się blokowaniem i odblokowywaniem pojazdów.', 
    es: '', 
    ru: ''
  },

  {
    id: 2379,
    en: 'Main function of this role is to install and set up correctly the module and maintenance (setting up maintenance alerts).',
    pl: 'Główną funkcją tej roli jest poprawna instalacja i konfiguracja modułu oraz konserwacja (ustawianie alertów konserwacyjnych).', 
    es: '', 
    ru: ''
  },
  {
    id: 2380,
    en: 'Translation settings',
    pl: 'Ustawienia tłumaczenia',
    ru: ''
  },
  {
    id: 2381,
    en: 'Phrases numbering',
    pl: 'Numeracja fraz',
    ru: ''
  },
  {
    id: 2382,
    en: 'Roles description and functionalities',
    pl: 'Opis ról i funkcjonalności',
    ru: ''
  },
  {
    id: 2383,
    en: 'With',
    pl: 'Z',
    ru: ''
  },
  {
    id: 2384,
    en: 'refuels',
    pl: 'tankuje',
    ru: ''
  },

  {
    id: 2385,
    en: 'reset',
    pl: 'zresetuj',
    ru: ''
  },
  {
    id: 2386,
    en: 'This will reset the counter to 0. Make sure you reset the counter once the maintenence was completed to set a new target for the next maintenance.',
    pl: 'Spowoduje to zresetowanie licznika do 0. Upewnij się, że zresetowałeś licznik po zakończeniu konserwacji, aby ustawić nowy cel dla następnej konserwacji.',
    ru: ''
  },
  {
    id: 2387,
    en: 'Roaming',
    pl: 'Roaming',
    ru: ''
  },
  {
    id: 2388,
    en: 'By turning this on you agree to the usage of the roaming when abroad. Extra charges may apply!',
    pl: 'Włączając tę ​​opcję, zgadzasz się na korzystanie z roamingu za granicą. Mogą obowiązywać dodatkowe opłaty!',
    ru: ''
  },
  {
    id: 2389,
    en: 'Data frequency',
    pl: 'Częstotliwość danych',
    ru: ''
  },
  {
    id: 2390,
    en: 'Enable email notifications for this vehicle',
    pl: 'Włącz powiadomienia e-mail dla tego pojazdu',
    ru: ''
  },
  {
    id: 2392,
    en: 'Click on the map to move the marker',
    pl: 'Kliknij mapę, aby przesunąć znacznik', 
    es: '', 
    ru: ''
  },
  {
    id: 2393,
    en: 'Geofence center position',
    pl: 'Pozycja centrum geofence', 
    es: '', 
    ru: ''
  },
  {
    id: 2394,
    en: 'Data transfer',
    pl: 'Transfer danych', 
    es: '', 
    ru: ''
  },
  {
    id: 2395,
    en: 'Compatible',
    pl: 'Zgodny', 
    es: '', 
    ru: ''
  },
  {
    id: 2396,
    en: 'CTRL connected',
    pl: 'CTRP połączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2397,
    en: 'Driving',
    pl: 'Poruszający', 
    es: '', 
    ru: ''
  },
  {
    id: 2398,
    en: 'Please select vehicles you would like to track',
    pl: 'Wybierz pojazdy, które chcesz śledzić', 
    es: '', 
    ru: ''
  },
  {
    id: 2399,
    en: 'From',
    pl: 'Z', 
    es: '', 
    ru: ''
  },
  {
    id: 2400,
    en: 'Following values are',
    pl: 'Następujące wartości są', 
    es: '', 
    ru: ''
  },
  {
    id: 2401,
    en: 'estimates',
    pl: 'szacunki', 
    es: '', 
    ru: ''
  },
  {
    id: 2402,
    en: 'Timezone',
    pl: 'Strefa czasowa', 
    es: '', 
    ru: ''
  },
  {
    id: 2403,
    en: 'days ago',
    pl: 'dni temu', 
    es: '', 
    ru: ''
  },
  {
    id: 2404,
    en: 'Status',
    pl: 'Status', 
    es: '', 
    ru: ''
  },
  {
    id: 2405,
    en: 'Buffer records',
    pl: 'Zapisy buforowe', 
    es: '', 
    ru: ''
  },
  {
    id: 2406,
    en: 'You are about to remove IP',
    pl: 'Masz zamiar usunąć IP', 
    es: '', 
    ru: ''
  },
  {
    id: 2407,
    en: 'Confirm your choice by typing in the IP',
    pl: 'Potwierdź swój wybór, wpisując IP', 
    es: '', 
    ru: ''
  },
  {
    id: 2408,
    en: 'IP address is missing',
    pl: 'Brak adresu IP', 
    es: '', 
    ru: ''
  },
  {
    id: 2409,
    en: 'Please make sure you have typed the IP address correctly',
    pl: 'Upewnij się, że wpisałeś poprawnie adres IP', 
    es: '', 
    ru: ''
  },
  {
    id: 2410,
    en: 'Label',
    pl: 'Etykieta', 
    es: '', 
    ru: ''
  },
  {
    id: 2411,
    en: 'IP address',
    pl: 'Adres IP', 
    es: '', 
    ru: ''
  },
  {
    id: 2412,
    en: 'Module v.',
    pl: 'Moduł v.', 
    es: '', 
    ru: ''
  },
  {
    id: 2413,
    en: 'Locked vehicle alert',
    pl: 'Ostrzeżenie o zablokowanym pojeździe', 
    es: '', 
    ru: ''
  },
  {
    id: 2414,
    en: 'Account language',
    pl: 'Język konta', 
    es: '', 
    ru: ''
  },
  {
    id: 2415,
    en: 'Data refreshed',
    pl: 'Odświeżono dane', 
    es: '', 
    ru: ''
  },
  {
    id: 2416,
    en: 'Auto lock',
    pl: 'Automatyczna blokada', 
    es: '', 
    ru: ''
  },
  {
    id: 2417,
    en: 'You can disable auto lock for the vehicles with unauthorized refuel. Turning this feature off will still enable you to lock the vehicle manually',
    pl: 'Możesz wyłączyć automatyczną blokadę dla pojazdów z nieautoryzowanym tankowaniem. Wyłączenie tej funkcji nadal umożliwi ręczne zablokowanie pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 2418,
    en: 'Disable payment',
    pl: 'Wyłącz płatność', 
    es: '', 
    ru: ''
  },
  {
    id: 2419,
    en: 'Disables need to pay for this vehicle while using the gas',
    pl: 'Wyłącza konieczność płacenia za ten pojazd podczas korzystania z gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 2420,
    en: 'Monthly data usage',
    pl: 'Miesięczne wykorzystanie danych', 
    es: '', 
    ru: ''
  },
  {
    id: 2420,
    en: 'In order to calculate estimated savings IGL needs to be activated and total gas injection per tank information must be available.',
    pl: 'Aby obliczyć szacunkowe oszczędności, należy aktywować IGL i udostępnić informacje o całkowitym wtrysku gazu na zbiornik.', 
    es: '', 
    ru: ''
  },
  {
    id: 2421,
    en: 'driving currently',
    pl: 'aktualnie jedzie', 
    es: '', 
    ru: ''
  },
  {
    id: 2422,
    en: 'IGL not calibrated',
    pl: 'IGL nieskalibrowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2423,
    en: 'Add RFID',
    pl: 'Dodaj RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 2424,
    en: 'Delete RFID with ID',
    pl: 'Usuń RFID z ID', 
    es: '', 
    ru: ''
  },

  {
    id: 2425,
    en: 'You are about to delete RFID record with custom ID',
    pl: 'Zamierzasz usunąć rekord RFID z niestandardowym identyfikatorem', 
    es: '', 
    ru: ''
  },
  {
    id: 2426,
    en: 'Confirm your choice by typing in the RFID record custom ID',
    pl: 'Potwierdź swój wybór, wpisując niestandardowy identyfikator rekordu RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 2427,
    en: 'Confirm your choice by typing in the RFID custom ID',
    pl: 'Potwierdź swój wybór, wpisując niestandardowy identyfikator RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 2428,
    en: 'User',
    pl: 'Użytkownik', 
    es: '', 
    ru: ''
  },
  {
    id: 2429,
    en: 'Select group',
    pl: 'Wybierz grupę', 
    es: '', 
    ru: ''
  },
  {
    id: 2430,
    en: 'Group title can not be empty',
    pl: 'Tytuł grupy nie może być pusty', 
    es: '', 
    ru: ''
  },
  {
    id: 2431,
    en: 'Please make sure that the group title matches the title of the group you want to delete',
    pl: 'Upewnij się, że tytuł grupy odpowiada tytułowi grupy, którą chcesz usunąć', 
    es: '', 
    ru: ''
  },
  {
    id: 2432,
    en: 'Intelligent Gas Level (IGL) is correctly callibrated',
    pl: 'Inteligentny poziom gazu (IGL) jest prawidłowo skalibrowany', 
    es: '', 
    ru: ''
  },
  {
    id: 2433,
    en: 'IGL stage',
    pl: 'Stan IGL', 
    es: '', 
    ru: ''
  },
  {
    id: 2434,
    en: 'Following are estimates based on vehicle average fuel consumption per 100km. You can improve the calculation by make sure',
    pl: 'Poniżej przedstawiono szacunki oparte na średnim zużyciu paliwa przez pojazd na 100 km. Możesz poprawić obliczenia, upewniając się', 
    es: '', 
    ru: ''
  },
  {
    id: 2435,
    en: 'LPG/CNG percentage fuel usage alarm',
    pl: 'Alarm procentowego zużycia paliwa LPG/CNG', 
    es: '', 
    ru: ''
  },
  {
    id: 2436,
    en: 'Minimum LPG/CNG % fuel usage',
    pl: 'Minimalne % zużycia paliwa LPG/CNG', 
    es: '', 
    ru: ''
  },
  {
    id: 2437,
    en: 'The number you set here is the minimum % LPG/CNG must be used compared to the default fuel before the alarm notification is sent',
    pl: 'Liczba, którą tutaj ustawiasz, to minimalny % LPG/CNG, który musi być użyty w porównaniu z domyślnym paliwem przed wysłaniem powiadomienia o alarmie', 
    es: '', 
    ru: ''
  },
  {
    id: 2438,
    en: 'Maximum alert frequency (s)',
    pl: 'Maksymalna częstotliwość alertów (s)', 
    es: '', 
    ru: ''
  },
  {
    id: 2439,
    en: 'The number you set here represents how frequently the alert notifications will be sent. It is the minimum number between two alerts',
    pl: 'Liczba, którą tutaj ustawisz, oznacza, jak często będą wysyłane powiadomienia o alertach. Jest to minimalna liczba między dwoma alertami', 
    es: '', 
    ru: ''
  },
  {
    id: 2440,
    en: 'Minimum LPG/CNG % fuel usage',
    pl: 'Minimalne % zużycia paliwa LPG/CNG', 
    es: '', 
    ru: ''
  },
  {
    id: 2441,
    en: 'The number you set here is the minimum % LPG/CNG must be used compared to the default fuel before the alarm notification is sent',
    pl: 'Liczba, którą tutaj ustawiasz, to minimalny % LPG/CNG, który musi być użyty w porównaniu z domyślnym paliwem przed wysłaniem powiadomienia o alarmie', 
    es: '', 
    ru: ''
  },
  {
    id: 2442,
    en: 'There are currently no vehicles added to this group',
    pl: 'Obecnie do tej grupy nie dodano żadnych pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 2443,
    en: 'You are about to delete account with company name',
    pl: 'Zamierzasz usunąć konto z nazwą firmy', 
    es: '', 
    ru: ''
  },
  {
    id: 2444,
    en: 'Pairing requests history',
    pl: 'Historia żądań parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2445,
    en: 'Request',
    pl: 'Żądanie', 
    es: '', 
    ru: ''
  },

  {
    id: 2446,
    en: 'Maintenance unpairing request',
    pl: 'Prośba o usunięcie sparowania w ramach konserwacji', 
    es: '', 
    ru: ''
  },
  {
    id: 2447,
    en: 'Unpairing request',
    pl: 'Prośba o usunięcie parowania', 
    es: '', 
    ru: ''
  },
  {
    id: 2448,
    en: 'Waiting...',
    pl: 'Czekanie...', 
    es: '', 
    ru: ''
  },
  {
    id: 2449,
    en: 'Pairing request',
    pl: 'Prośba o parowanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2450,
    en: 'Initial never paired settings',
    pl: 'Początkowe ustawienia nigdy nie sparowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2451,
    en: 'TEMPORARY UNPAIRED FOR MAINTENANCE',
    pl: 'TYMCZASOWE NIESPAROWANE DO KONSERWACJI', 
    es: '', 
    ru: ''
  },
  {
    id: 2452,
    en: 'UNPAIRED',
    pl: 'NIEPARZYSTY', 
    es: '', 
    ru: ''
  },
  {
    id: 2453,
    en: 'PAIRED',
    pl: 'SPAROWANE', 
    es: '', 
    ru: ''
  },
  {
    id: 2454,
    en: 'NEVER PAIRED',
    pl: 'NIGDY PAROWANE', 
    es: '', 
    ru: ''
  },
  {
    id: 2455,
    en: 'Cancel maintenance unpairing',
    pl: 'Anuluj parowanie konserwacyjne', 
    es: '', 
    ru: ''
  },
  {
    id: 2456,
    en: 'success',
    pl: 'powodzenie', 
    es: '', 
    ru: ''
  },
  {
    id: 2457,
    en: 'error',
    pl: 'błąd', 
    es: '', 
    ru: ''
  },
  {
    id: 2458,
    en: 'Confirmation',
    pl: 'Potwierdzenie', 
    es: '', 
    ru: ''
  },
  {
    id: 2459,
    en: 'Processed on',
    pl: 'Przetworzono dnia', 
    es: '', 
    ru: ''
  },
  {
    id: 2460,
    en: 'Unpaired for maintenance on',
    pl: 'Niesparowane do konserwacji włączone', 
    es: '', 
    ru: ''
  },
  {
    id: 2461,
    en: 'Connection lost',
    pl: 'Utracono połączenie', 
    es: '', 
    ru: ''
  },
  {
    id: 2462,
    en: 'Last data',
    pl: 'Ostatnie dane', 
    es: '', 
    ru: ''
  },
  {
    id: 2463,
    en: 'Maintenance in progress',
    pl: 'Konserwacja w toku', 
    es: '', 
    ru: ''
  },
  {
    id: 2464,
    en: 'Planned maintenance',
    pl: 'Planowana konserwacja', 
    es: '', 
    ru: ''
  },
  {
    id: 2465,
    en: 'Ignition on',
    pl: 'Zapłon włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2466,
    en: 'Ignition off',
    pl: 'Zapłon wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2467,
    en: 'Ctrl connected',
    pl: 'Połączono z Ctrl', 
    es: '', 
    ru: ''
  },
  {
    id: 2468,
    en: 'Ctrl disconnected',
    pl: 'Ctrl rozłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 2469,
    en: 'Ignition',
    pl: 'Zapłon', 
    es: '', 
    ru: ''
  },
  {
    id: 2470,
    en: 'Controller',
    pl: 'Kontroler', 
    es: '', 
    ru: ''
  },
  {
    id: 2471,
    en: 'UNLOCKED',
    pl: 'ODBLOKOWANY', 
    es: '', 
    ru: ''
  },
  {
    id: 2472,
    en: 'GAS CONTROLLER',
    pl: 'STEROWNIK GAZU', 
    es: '', 
    ru: ''
  },
  {
    id: 2473,
    en: 'GSM MODULE',
    pl: 'MODUŁ GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 2474,
    en: 'Estimated cost',
    pl: 'Szacowany koszt', 
    es: '', 
    ru: ''
  },
  {
    id: 2475,
    en: 'Alternative fuel constumption',
    pl: 'Zużycie paliwa alternatywnego', 
    es: '', 
    ru: ''
  },
  {
    id: 2476,
    en: 'Total data',
    pl: 'Całkowite dane', 
    es: '', 
    ru: ''
  },
  {
    id: 2477,
    en: 'Driving on alternative fuel',
    pl: 'Jazda na paliwie alternatywnym', 
    es: '', 
    ru: ''
  },
  {
    id: 2478,
    en: 'Calibrate IGL for better results',
    pl: 'Skalibruj IGL, aby uzyskać lepsze wyniki', 
    es: '', 
    ru: ''
  },
  {
    id: 2479,
    en: 'Number of vehicles',
    pl: 'Liczba pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 2480,
    en: 'Number representing the percentage of driving on alternative fuel (based on the distance driven on alternative VS default fuel).',
    pl: 'Liczba reprezentująca procent jazdy na paliwie alternatywnym (w oparciu o odległość przebytą na domyślnym paliwie alternatywnym VS).', 
    es: '', 
    ru: ''
  },
  {
    id: 2481,
    en: 'Number representing estimated savings.',
    pl: 'Liczba reprezentująca szacunkowe oszczędności.', 
    es: '', 
    ru: ''
  },
  {
    id: 2482,
    en: 'Number representing estimated cost of both default and alternative fuel.',
    pl: 'Liczba reprezentująca szacunkowy koszt zarówno paliwa domyślnego, jak i alternatywnego.', 
    es: '', 
    ru: ''
  },
  {
    id: 2483,
    en: 'Number representing amount of alternative fuel used.',
    pl: 'Liczba reprezentująca ilość zużytego paliwa alternatywnego.', 
    es: '', 
    ru: ''
  },
  {
    id: 2484,
    en: 'Number representing total distance driven.',
    pl: 'Liczba reprezentująca całkowitą przejechaną odległość.', 
    es: '', 
    ru: ''
  },
  {
    id: 2485,
    en: 'Number representing total time driven.',
    pl: 'Liczba reprezentująca całkowity czas jazdy.', 
    es: '', 
    ru: ''
  },
  {
    id: 2486,
    en: 'Number representing amount of data transfered.',
    pl: 'Liczba reprezentująca ilość przesłanych danych.', 
    es: '', 
    ru: ''
  },
  {
    id: 2487,
    en: 'Number representing amount of CO2 saved.',
    pl: 'Liczba reprezentująca ilość zaoszczędzonego CO2.', 
    es: '', 
    ru: ''
  },
  {
    id: 2488,
    en: 'Number of the vehicles currently included in the group.',
    pl: 'Liczba pojazdów aktualnie znajdujących się w grupie.', 
    es: '', 
    ru: ''
  },
  {
    id: 2489,
    en: 'Cost of alternative fuel is higher than default fuel cost. This will have an impact on your savings.',
    pl: 'Koszt paliwa alternatywnego jest wyższy niż domyślny koszt paliwa. Będzie to miało wpływ na twoje oszczędności.', 
    es: '', 
    ru: ''
  },
  {
    id: 2490,
    en: 'There are no group assigned to this account. Assign at least one vehicle group.',
    pl: 'Do tego konta nie przypisano żadnej grupy. Przypisz co najmniej jedną grupę pojazdów.', 
    es: '', 
    ru: ''
  },
  {
    id: 2491,
    en: 'Paired/unpaired',
    pl: 'Sparowane/niesparowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2492,
    en: 'Locked/unlocked',
    pl: 'Zablokowane/odblokowane', 
    es: '', 
    ru: ''
  },
  {
    id: 2493,
    en: 'Controller compatibility',
    pl: 'Kompatybilność kontrolera', 
    es: '', 
    ru: ''
  },
  {
    id: 2494,
    en: 'Controller connection',
    pl: 'Połączenie kontrolera', 
    es: '', 
    ru: ''
  },
  {
    id: 2495,
    en: 'Controller',
    pl: 'Kontroler', 
    es: '', 
    ru: ''
  },
  {
    id: 2496,
    en: 'DATA FROM',
    pl: 'DANE Z', 
    es: '', 
    ru: ''
  },
  {
    id: 2497,
    en: 'Module version',
    pl: 'Wersja modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 2498,
    en: 'Auto detect',
    pl: 'Automatyczne wykrywanie', 
    es: '', 
    ru: ''
  },
  {
    id: 2499,
    en: 'Version is detected automatically. Select manually only if you know what you are doing',
    pl: 'Wersja jest wykrywana automatycznie. Wybierz ręcznie tylko wtedy, gdy wiesz, co robisz', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2500,
    en: 'Show stations',
    pl: 'Pokaż stacje', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2501,
    en: 'Number of refuelling per vehicle',
    pl: 'Liczba tankowań na pojazd', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2502,
    en: 'RFID custom ID is missing',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2503,
    en: 'Please make sure you have typed the RFID custom ID correctly',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2504,
    en: 'Checkpoint custom ID is missing',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2505,
    en: 'Please make sure you have typed the checkpoint custom ID correctly',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2506,
    en: 'lowest sensitivity',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2507,
    en: 'highest sensitivity',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2508,
    en: 'Server',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2509,
    en: 'Function',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2510,
    en: 'Error',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2511,
    en: 'Time',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2512,
    en: 'only development',
    pl: '', 
    es: '', 
    ru: ''
  }, 
  {
    id: 2513,
    en: 'Calendar lock',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2514,
    en: 'Set the lock date',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2515,
    en: 'Please make sure that gas controller is compatible with GSM module, that controller is connected and that the gas controller is paired with the GSM module.',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2516,
    en: 'Vehicle is locked at the moment. By setting a date in the calendar you will unlock it and move the lock event to the future.',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2517,
    en: 'Vehicle calendar enabled',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2518,
    en: 'Select date',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 2519,
    en: 'Payment for the invoice must be completed within days',
    pl: '', 
    es: '', 
    ru: ''
  },
  // communications panel
  {
    id: 4215,
    en: 'Altitude',
    pl: 'Wysokość', 
    es: '', 
    ru: ''
  },
  {
    id: 4216,
    en: 'Heading',
    pl: 'Kierunek', 
    es: '', 
    ru: ''
  },
  {
    id: 4217,
    en: 'Sat. used',
    pl: 'Używane satelity', 
    es: '', 
    ru: ''
  },
  {
    id: 4218,
    en: 'Sat. visible',
    pl: 'Satelity widoczne', 
    es: '', 
    ru: ''
  },
  {
    id: 4219,
    en: 'GSM Signal',
    pl: 'Sygnał GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 4220,
    en: 'GSM Battery',
    pl: 'Bateria GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 4221,
    en: 'GSM Error',
    pl: 'Błąd GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 4222,
    en: 'In memory',
    pl: 'W pamięci', 
    es: '', 
    ru: ''
  },
  {
    id: 4223,
    en: 'TS in memory',
    pl: 'TS w pamięci', 
    es: '', 
    ru: ''
  },
  {
    id: 4224,
    en: 'IO state',
    pl: 'Stan IO', 
    es: '', 
    ru: ''
  },
  {
    id: 4225,
    en: 'Supply V',
    pl: 'Zaopatrzenie V', 
    es: '', 
    ru: ''
  },
  {
    id: 4226,
    en: 'Settings time',
    pl: 'Czas ustawień', 
    es: '', 
    ru: ''
  },
  {
    id: 4227,
    en: 'GSM period',
    pl: 'Okres GSM', 
    es: '', 
    ru: ''
  },
  {
    id: 4228,
    en: 'GMD period',
    pl: 'Okres GMD', 
    es: '', 
    ru: ''
  },
  {
    id: 4229,
    en: 'Driver ID',
    pl: 'ID kierowcy', 
    es: '', 
    ru: ''
  },
  {
    id: 4230,
    en: 'System state',
    pl: 'Stan systemu', 
    es: '', 
    ru: ''
  },
  {
    id: 4231,
    en: 'System flags',
    pl: 'Flagi systemowe', 
    es: '', 
    ru: ''
  },
  {
    id: 4232,
    en: 'Engine RPM',
    pl: 'Obroty silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 4233,
    en: 'Engine load',
    pl: 'Obciążenie silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 4234,
    en: 'Engine MAP',
    pl: 'MAPA silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 4235,
    en: 'GAS pressure',
    pl: 'Ciśnienie gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 4236,
    en: 'Reducer temp',
    pl: 'Temp. Reduktora', 
    es: '', 
    ru: ''
  },
  {
    id: 4237,
    en: 'Gas temp',
    pl: 'Temperatura gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 4238,
    en: 'Gas lvl',
    pl: 'Poziom gazu', 
    es: '', 
    ru: ''
  },
  {
    id: 4239,
    en: 'Gas lvl analog',
    pl: 'Poziom gazu Analog', 
    es: '', 
    ru: ''
  },
  {
    id: 4240,
    en: 'Supply V',
    pl: 'Zaopatrzenie V', 
    es: '', 
    ru: ''
  },
  {
    id: 4241,
    en: 'Pet inj time',
    pl: 'Czas zranienia zwierzaka', 
    es: '', 
    ru: ''
  },
  {
    id: 4242,
    en: 'GAS inj time',
    pl: 'Czas uszkodzenia GAS', 
    es: '', 
    ru: ''
  },
  {
    id: 4243,
    en: 'Total inj time',
    pl: 'Całkowity czas wtrysku', 
    es: '', 
    ru: ''
  },
  {
    id: 4244,
    en: 'Daily inj GAS',
    pl: 'GAZ codzienny', 
    es: '', 
    ru: ''
  },
  {
    id: 4245,
    en: 'GAS inj. since refuel',
    pl: 'Wtr. GAZU od tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 4246,
    en: 'GAS inj. since refuel',
    pl: 'Wtr. GAZU od tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 4247,
    en: 'GAS inj last s',
    pl: 'Wtr. GAZU od tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 4248,
    en: 'Inteli GAS lvl',
    pl: 'Poz. Inteli GAS', 
    es: '', 
    ru: ''
  },
  {
    id: 4249,
    en: 'GC model',
    pl: 'Model GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4250,
    en: 'GC firmware v.',
    pl: 'W. oprogramowania GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4251,
    en: 'GC minimal app v.',
    pl: 'Minimalna w. aplikacji GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4252,
    en: 'GC firmware date',
    pl: 'Data oprogramowania GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4253,
    en: 'GC firmware time',
    pl: 'Czas oprogramowania GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4254,
    en: 'GC ser. num.',
    pl: 'Numery seryjne GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4255,
    en: 'paired GC ser. num.',
    pl: 'sparowany numer seryjny GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4256,
    en: 'unlock GC',
    pl: 'odblokuj GC', 
    es: '', 
    ru: ''
  },
  {
    id: 4257,
    en: 'gas injection per tank',
    pl: 'wtrysk gazu na zbiornik', 
    es: '', 
    ru: ''
  },
  {
    id: 4258,
    en: 'tank capacity',
    pl: 'pojemność baku', 
    es: '', 
    ru: ''
  },
  {
    id: 4259,
    en: 'total time on default fuel [s]',
    pl: 'całkowity czas na paliwie standardowym [s]', 
    es: '', 
    ru: ''
  },
  {
    id: 4260,
    en: 'total time on alternative fuel [s]',
    pl: 'całkowity czas na paliwie alternatywnym [s]', 
    es: '', 
    ru: ''
  },
  {
    id: 4261,
    en: 'total distance on default fuel [km]',
    pl: 'całkowita odległość na paliwie standardowym [km]', 
    es: '', 
    ru: ''
  },
  {
    id: 4262,
    en: 'total distance on alternative fuel [km]',
    pl: 'całkowita odległość na paliwie alternatywnym [km]', 
    es: '', 
    ru: ''
  },
  {
    id: 4263,
    en: 'total distance on alternative fuel [km]',
    pl: 'całkowita odległość na paliwie alternatywnym [km]', 
    es: '', 
    ru: ''
  },
  {
    id: 4264,
    en: 'average gas consumption per 100km',
    pl: 'średnie zużycie gazu na 100km', 
    es: '', 
    ru: ''
  },
  {
    id: 4265,
    en: 'First route start',
    pl: 'Początek pierwszej trasy', 
    es: '', 
    ru: ''
  },
  {
    id: 4266,
    en: 'IP address can not be empty',
    pl: 'Adres IP nie może być pusty', 
    es: '', 
    ru: ''
  },
  {
    id: 4267,
    en: 'IP address must be valid',
    pl: 'Adres IP musi być poprawny', 
    es: '', 
    ru: ''
  },
  {
    id: 4268,
    en: 'Label can not be empty and must be within 40 charactes',
    pl: 'Etykieta nie może być pusta i musi zawierać do 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 4269,
    en: 'Total injected gas [10ms]',
    pl: 'Całkowity wtrysk gazu [10ms]', 
    es: '', 
    ru: ''
  },
  {
    id: 4270,
    en: 'total trasmitted data in kB',
    pl: 'całkowita liczba przesłanych danych w kB', 
    es: '', 
    ru: ''
  },
  {
    id: 4271,
    en: 'This means that there are no data being sent from the vehicle. If the igniton is on and you still see this message there is a number things you can check:',
    pl: 'Oznacza to, że z pojazdu nie są przesyłane żadne dane. Jeśli zapłon jest włączony i nadal widzisz ten komunikat, możesz sprawdzić kilka rzeczy:', 
    es: '', 
    ru: ''
  },
  {
    id: 4272,
    en: 'SIM card in the module has working data plan',
    pl: 'Karta SIM w module ma działający plan transmisji danych', 
    es: '', 
    ru: ''
  },
  {
    id: 4273,
    en: 'There are cell towers nearby',
    pl: 'W pobliżu są wieże komórkowe', 
    es: '', 
    ru: ''
  },
  {
    id: 4274,
    en: 'Signal is not blocked (vehicle being in the underground parking)',
    pl: 'Sygnał nie jest zablokowany (pojazd znajduje się na parkingu podziemnym)', 
    es: '', 
    ru: ''
  },
  {
    id: 4275,
    en: 'There are no data for given group at',
    pl: 'Brak danych dla danej grupy w', 
    es: '', 
    ru: ''
  },
  {
    id: 4276,
    en: 'Try selecting a different date or different group',
    pl: 'Spróbuj wybrać inną datę lub inną grupę', 
    es: '', 
    ru: ''
  },
  {
    id: 4277,
    en: 'No data',
    pl: 'Brak danych', 
    es: '', 
    ru: ''
  },
  {
    id: 4278,
    en: 'No vehicle group assigned',
    pl: 'Brak przypisanej grupy pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 4279,
    en: 'Costs and savings calculated based on following prices',
    pl: 'Koszty i oszczędności obliczone na podstawie poniższych cen', 
    es: '', 
    ru: ''
  },
  {
    id: 4280,
    en: 'Change the price',
    pl: 'Zmień cenę', 
    es: '', 
    ru: ''
  },
  {
    id: 4281,
    en: 'here',
    pl: 'tutaj', 
    es: '', 
    ru: ''
  },
  {
    id: 4282,
    en: 'GC sensor type',
    pl: '', 
    es: '', 
    ru: ''
  },
  

  // BACKEND MESSAGES

  {
    id: 8000,
    en: 'Server error',
    pl: 'Błąd serwera', 
    es: '', 
    ru: ''
  },
  {
    id: 8001,
    en: 'Invalid credentials',
    pl: 'Nieprawidłowe dane logowania', 
    es: '', 
    ru: ''
  },
  {
    id: 8002,
    en: 'The token is invalid',
    pl: 'Token jest nieprawidłowy', 
    es: '', 
    ru: ''
  },
  {
    id: 8003,
    en: 'Limit must be a number',
    pl: 'Limit musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8004,
    en: 'Skip must be a number',
    pl: 'Pomiń musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8005,
    en: 'Please include a valid email',
    pl: 'Dołącz prawidłowy adres e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 8006,
    en: 'Password is required and must be at least 8 characters long',
    pl: 'Hasło jest wymagane i musi mieć co najmniej 8 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8007,
    en: 'Your token is invalid',
    pl: 'Twój token jest nieprawidłowy', 
    es: '', 
    ru: ''
  },
  {
    id: 8008,
    en: 'You have successfully logged out',
    pl: 'Wylogowałeś się pomyślnie', 
    es: '', 
    ru: ''
  },
  {
    id: 8009,
    en: 'An email was sent with instructions on how to reset your password',
    pl: 'Wysłano e-mail z instrukcjami, jak zresetować hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 8010,
    en: 'We have sent an email with instructions on how to reset your password',
    pl: 'Wysłaliśmy wiadomość e-mail z instrukcjami, jak zresetować hasło', 
    es: '', 
    ru: ''
  },
  {
    id: 8011,
    en: 'Passwords does not match',
    pl: 'Hasła nie pasują', 
    es: '', 
    ru: ''
  },
  {
    id: 8012,
    en: 'Token must be a valid token',
    pl: 'Token musi być prawidłowym tokenem', 
    es: '', 
    ru: ''
  },
  {
    id: 8013,
    en: 'Your password was changed, you can now login with your new password',
    pl: 'Twoje hasło zostało zmienione, możesz teraz zalogować się przy użyciu nowego hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 8014,
    en: 'We were unable to change your password',
    pl: 'Nie mogliśmy zmienić Twojego hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 8015,
    en: 'No users were found',
    pl: 'Nie znaleziono żadnych użytkowników', 
    es: '', 
    ru: ''
  },
  {
    id: 8016,
    en: 'Person ID must be present',
    pl: 'Identyfikator osoby musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8017,
    en: 'Person ID must be a number',
    pl: 'Identyfikator osoby musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8018,
    en: 'You are not authorized to create user with role that is higher then your own',
    pl: 'Nie masz uprawnień do tworzenia użytkownika z rolą wyższą niż Twoja', 
    es: '', 
    ru: ''
  },
  {
    id: 8019,
    en: 'User alerady exists',
    pl: 'Użytkownik już istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8020,
    en: 'User was created',
    pl: 'Utworzono użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 8021,
    en: 'Person ID must be between 1 and 7 characters long',
    pl: 'Identyfikator osoby musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8022,
    en: 'Role must be valid role',
    pl: 'Identyfikator osoby musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8023,
    en: 'Email must be a valid email address',
    pl: 'Email musi być prawidłowym adresem e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 8024,
    en: 'Please enter name that is maxiumum 25 characters long',
    pl: 'Wpisz nazwę, która ma maksymalnie 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8025,
    en: 'Lock feature can be only true or false',
    pl: 'Wpisz nazwę, która ma maksymalnie 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8026,
    en: 'Reports feature can be only true or false',
    pl: 'Funkcja raportów może mieć tylko wartość prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8027,
    en: 'Routes feature can be only true or false',
    pl: 'Funkcja tras może mieć tylko wartość prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8028,
    en: 'Alerts feature can be only true or false',
    pl: 'Funkcja alertów może mieć tylko wartość prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8029,
    en: 'Language must be 2 characters long',
    pl: 'Język musi mieć 2 znaki', 
    es: '', 
    ru: ''
  },
  {
    id: 8030,
    en: 'Please enter phone that is maxiumum 15 characters long',
    pl: 'Wpisz numer telefonu, który ma maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8031,
    en: 'You are not authorized to create user with role that is higher then your own',
    pl: 'Nie masz uprawnień do tworzenia użytkownika z rolą wyższą niż Twoja', 
    es: '', 
    ru: ''
  },
  {
    id: 8032,
    en: 'Email already taken',
    pl: 'Email jest już zajęty', 
    es: '', 
    ru: ''
  },
  {
    id: 8033,
    en: 'Person with such ID does not exist',
    pl: 'Osoba z takim identyfikatorem nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8034,
    en: 'Password must have at least 8 characters',
    pl: 'Hasło musi mieć co najmniej 8 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8035,
    en: 'Notification can be only true or false',
    pl: 'Powiadomienie może być tylko prawdziwe lub fałszywe', 
    es: '', 
    ru: ''
  },
  {
    id: 8036,
    en: 'User was updated',
    pl: 'Użytkownik został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8037,
    en: 'User was deleted',
    pl: 'Użytkownik został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8038,
    en: 'Wrong user ID',
    pl: 'Błędne ID użytkownika', 
    es: '', 
    ru: ''
  },
  {
    id: 8039,
    en: 'Your settings have been updated',
    pl: 'Twoje ustawienia zostały uaktualnione', 
    es: '', 
    ru: ''
  },
  {
    id: 8040,
    en: 'Zoom must be a number',
    pl: 'Zoom musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8041,
    en: 'Latitude must be a number',
    pl: 'Szerokość geograficzna musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8042,
    en: 'Longitude must be a number',
    pl: 'Długość geograficzna musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8043,
    en: 'Currency must be 3 characters long',
    pl: 'Waluta musi mieć 3 znaki', 
    es: '', 
    ru: ''
  },
  {
    id: 8044,
    en: 'Gas cost must be a number',
    pl: 'Koszt gazu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8045,
    en: 'Gas CNG must be a number',
    pl: 'CNG gazu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8046,
    en: 'Petrol cost must be a number',
    pl: 'Koszt benzyny musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8047,
    en: 'Diesel cost must be a number',
    pl: 'Koszt oleju napędowego musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8048,
    en: 'No Serial No was found',
    pl: 'Nie znaleziono numerów N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 8049,
    en: 'Searched Serial No must be a number',
    pl: 'Szukany N. Seryjny musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8050,
    en: 'Search for Serial No must be between 1 and 15 character long',
    pl: 'Wyszukiwanie N. Seryjny musi mieć od 1 do 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8051,
    en: 'Serial No must be a valid Serial number, 15 characters long',
    pl: 'N. Seryjny musi być prawidłowym numerem N. Seryjny o długości 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8052,
    en: 'There is a problem with the one or more Serial No(s). Please check they are a number and they have valid length (15 characters)',
    pl: 'Wystąpił problem z co najmniej jednym numerem N. Seryjny. Sprawdź, czy to liczba i czy mają prawidłową długość (15 znaków)', 
    es: '', 
    ru: ''
  },
  {
    id: 8053,
    en: 'Please submit at least one valid Serial No',
    pl: 'Podaj co najmniej jeden prawidłowy N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 8054,
    en: 'Serial No ID must be present',
    pl: 'Identyfikator N. Seryjny musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8055,
    en: 'Serial No must be a number',
    pl: 'Identyfikator N. Seryjny musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8056,
    en: 'Serial No must be between 1 and 7 characters long',
    pl: 'Identyfikator N. Seryjny musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8057,
    en: 'Module ID must be present',
    pl: 'ID modułu musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8058,
    en: 'Module ID must be a number',
    pl: 'ID modułu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8059,
    en: 'Module ID must be a valid Serial number, 15 characters long',
    pl: 'ID modułu musi być prawidłowym numerem N. Seryjny o długości 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8060,
    en: 'Module Serial No was deleted',
    pl: 'Moduł N. Seryjny został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8061,
    en: 'Wrong module ID',
    pl: 'Błędny ID modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 8062,
    en: 'Serial No was uploaded successfuly',
    pl: 'N. Seryjny został przesłany pomyślnie', 
    es: '', 
    ru: ''
  },
  {
    id: 8063,
    en: 'Some of the Serial No(s) was not uploaded, possible duplicates',
    pl: 'Część N. Seryjny nie została przesłana, możliwe duplikaty', 
    es: '', 
    ru: ''
  },
  {
    id: 8064,
    en: 'Refuel location ID must be present',
    pl: 'Musi być obecny identyfikator miejsca tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 8065,
    en: 'Refuel location ID must be a number',
    pl: 'Identyfikator miejsca tankowania musi być jednostka', 
    es: '', 
    ru: ''
  },
  {
    id: 8066,
    en: 'Refuel location ID must be between 1 and 7 characters long',
    pl: 'Identyfikator miejsca tankowania musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8067,
    en: 'Wrong refuel history ID',
    pl: 'Błędny identyfikator historii tankowania', 
    es: '', 
    ru: ''
  },
  {
    id: 8068,
    en: 'Vehicle ID must be present',
    pl: 'Identyfikator pojazdu musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8069,
    en: 'Vehicle ID must be a number',
    pl: 'Identyfikator pojazdu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8070,
    en: 'Vehicle ID must be between 1 and 7 characters long',
    pl: 'Identyfikator pojazdu musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8071,
    en: 'Wrong vehicle ID',
    pl: 'Błędny identyfikator pojazdu', 
    es: '', 
    ru: ''
  },
  {
    id: 8072,
    en: 'Module settings were updated',
    pl: 'Zaktualizowano ustawienia modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 8073,
    en: 'There is no vehicle with such ID',
    pl: 'Nie ma pojazdu z takim ID', 
    es: '', 
    ru: ''
  },
  {
    id: 8074,
    en: 'There are no modules',
    pl: 'Brak modułów', 
    es: '', 
    ru: ''
  },
  {
    id: 8075,
    en: 'Date must be 10 characters long',
    pl: 'Data musi mieć 10 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8076,
    en: 'Date must be 10 characters long',
    pl: 'Data musi mieć 10 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8077,
    en: 'Reason must be between 1 or 3 characters',
    pl: 'Przyczyna musi mieć od 1 do 3 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8078,
    en: 'Serial No must be valid serial number',
    pl: 'N. Seryjny musi być prawidłowym numerem N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 8079,
    en: 'No communication was found',
    pl: 'Nie znaleziono komunikacji', 
    es: '', 
    ru: ''
  },
  {
    id: 8080,
    en: 'No active Serial No was found',
    pl: 'Nie znaleziono aktywnego N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 8081,
    en: 'Please enter competitor station custom ID that is maximum 15 characters long',
    pl: 'Wprowadź niestandardowy identyfikator stacji konkurencji, który ma maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8082,
    en: 'Please enter company name that is maxiumum 15 characters long',
    pl: 'Wpisz nazwę firmy o długości maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8083,
    en: 'Range must be a number',
    pl: 'Zakres musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8084,
    en: 'New competitor station was added',
    pl: 'Dodano nową stację konkurenta', 
    es: '', 
    ru: ''
  },
  {
    id: 8085,
    en: 'No competitor stations were found',
    pl: 'Nie znaleziono stacji konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 8086,
    en: 'Competitors station ID must be a number',
    pl: 'Identyfikator stacji zawodnika musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8087,
    en: 'Competitors station ID must be between 1 and 7 characters long',
    pl: 'ID stacji zawodnika musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8088,
    en: 'Competitor station with such ID does not exist',
    pl: 'Stacja konkurencji o takim ID nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8089,
    en: 'Competitor station ID must be present',
    pl: 'Musi być obecny identyfikator stacji konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 8090,
    en: 'Competitor station ID must be a number',
    pl: 'Identyfikator stacji konkurencji musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8091,
    en: 'Competitor station ID must be between 1 and 7 characters long',
    pl: 'Identyfikator stacji konkurencji musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8092,
    en: 'Competitor station ID is reuqired and must have maximum 15 characters',
    pl: 'Wymagany jest identyfikator stacji konkurencji i musi mieć maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8093,
    en: 'Please enter company name that is maxiumum 15 characters long',
    pl: 'Wpisz nazwę firmy o długości maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8094,
    en: 'Competitor station was updated',
    pl: 'Stacja konkurencji została zaktualizowana', 
    es: '', 
    ru: ''
  },
  {
    id: 8095,
    en: 'Wrong competitor station ID',
    pl: 'Nieprawidłowy identyfikator stacji konkurencji', 
    es: '', 
    ru: ''
  },
  {
    id: 8096,
    en: 'Competitor station was deleted',
    pl: 'Stacja konkurencji została usunięta', 
    es: '', 
    ru: ''
  },
  {
    id: 8097,
    en: 'No stations was found',
    pl: 'Nie znaleziono stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 8098,
    en: 'Station ID must be present',
    pl: 'ID stacji musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8099,
    en: 'Station ID must be a number',
    pl: 'ID stacji musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8100,
    en: 'Station ID must be between 1 and 7 characters long',
    pl: 'Identyfikator stacji musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8101,
    en: 'Station with such ID does not exist',
    pl: 'Stacja z takim identyfikatorem nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8102,
    en: 'Please enter station ID that is maximum 15 characters long',
    pl: 'Wprowadź identyfikator stacji o długości maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8103,
    en: 'New station was added',
    pl: 'Dodano nową stację', 
    es: '', 
    ru: ''
  },
  {
    id: 8104,
    en: 'Station ID is reuqired and must have maximum 15 characters',
    pl: 'ID stacji jest wymagany i musi mieć maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8105,
    en: 'Station was deleted',
    pl: 'Stacja została usunięta', 
    es: '', 
    ru: ''
  },
  {
    id: 8106,
    en: 'Wrong station ID',
    pl: 'Złe ID stacji', 
    es: '', 
    ru: ''
  },
  {
    id: 8107,
    en: 'Station was updated',
    pl: 'Stacja została zaktualizowana', 
    es: '', 
    ru: ''
  },
  {
    id: 8108,
    en: 'No reasons were found',
    pl: 'Nie znaleziono żadnych powodów', 
    es: '', 
    ru: ''
  },
  {
    id: 8109,
    en: 'Custom ID must be present',
    pl: 'Musi być obecne ID niestandardowe', 
    es: '', 
    ru: ''
  },
  {
    id: 8110,
    en: 'Custom ID must be between 1 and 15 characters',
    pl: 'Identyfikator niestandardowy musi mieć od 1 do 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8111,
    en: 'Module Serial No must be present',
    pl: 'Moduł N. Seryjny musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8112,
    en: 'Module Serial No must be a number',
    pl: 'Moduł N. Seryjny musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8113,
    en: 'Module Serial No must be a valid Serial number',
    pl: 'Moduł N. Seryjny musi być prawidłowym numerem N. Seryjny', 
    es: '', 
    ru: ''
  },
  {
    id: 8114,
    en: 'Tank size must be present',
    pl: 'Rozmiar zbiornika musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8115,
    en: 'Tank size must be number',
    pl: 'Rozmiar zbiornika musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8116,
    en: 'Tank size can be between 1 and 4 characters',
    pl: 'Rozmiar zbiornika może zawierać od 1 do 4 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8117,
    en: 'Engine type consumption must be present',
    pl: 'Musi być obecne zużycie typu silnika', 
    es: '', 
    ru: ''
  },
  {
    id: 8118,
    en: 'Engine type must be 6 characters',
    pl: 'Typ silnika musi mieć 6 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8119,
    en: 'Average consumption must be present',
    pl: 'Musi być obecne średnie zużycie', 
    es: '', 
    ru: ''
  },
  {
    id: 8120,
    en: 'Average consumption must be a number',
    pl: 'Średnie zużycie musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8121,
    en: 'Average consumption must be a reasonable number',
    pl: 'Średnie zużycie musi być rozsądną liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8122,
    en: 'Consumption difference must be present',
    pl: 'Musi istnieć różnica w zużyciu', 
    es: '', 
    ru: ''
  },
  {
    id: 8123,
    en: 'Consumption difference must be a number',
    pl: 'Różnica w zużyciu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8124,
    en: 'Consumption difference must be a number expressing %',
    pl: 'Różnica w zużyciu musi być liczbą wyrażającą %', 
    es: '', 
    ru: ''
  },
  {
    id: 8125,
    en: 'Alternative fuel type must be present',
    pl: 'Musi być obecny rodzaj paliwa alternatywnego', 
    es: '', 
    ru: ''
  },
  {
    id: 8126,
    en: 'Alternative fuel type can only be LPG or CNG',
    pl: 'Alternatywnym paliwem może być tylko LPG lub CNG', 
    es: '', 
    ru: ''
  },
  {
    id: 8127,
    en: 'Phone must be a valid phone number',
    pl: 'Telefon musi być prawidłowym numerem telefonu', 
    es: '', 
    ru: ''
  },
  {
    id: 8128,
    en: 'Module Serial No is already associated with vehicle',
    pl: 'Moduł N. Seryjny jest już powiązany z pojazdem', 
    es: '', 
    ru: ''
  },
  {
    id: 8129,
    en: 'New vehicle was added',
    pl: 'Dodano nowy pojazd', 
    es: '', 
    ru: ''
  },
  {
    id: 8130,
    en: 'This Serial No is not existing in the list of available modules',
    pl: 'Tego N. Seryjny nie ma na liście dostępnych modułów', 
    es: '', 
    ru: ''
  },
  {
    id: 8131,
    en: 'No vehicles were found',
    pl: 'Nie znaleziono żadnych pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 8132,
    en: 'Search term must be between 1 and 15 character long',
    pl: 'Wyszukiwane hasło musi mieć od 1 do 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8133,
    en: 'Vehicle custom ID is required, must have maximum 15 characters',
    pl: 'Wymagany jest niestandardowy ID pojazdu, musi mieć maksymalnie 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8134,
    en: 'Vehicle was updated',
    pl: 'Pojazd został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8135,
    en: 'Vehicle with such ID does not exist',
    pl: 'Pojazd z takim identyfikatorem nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8136,
    en: 'Reason must be a number',
    pl: 'Przyczyna musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8137,
    en: 'Description should not be longer then 100 characters',
    pl: 'Opis nie powinien być dłuższy niż 100 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8138,
    en: 'Vehicle was unlocked',
    pl: 'Pojazd został odblokowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8139,
    en: 'Vehicle was locked',
    pl: 'Pojazd został zablokowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8140,
    en: 'Vehicle was deleted',
    pl: 'Pojazd został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8141,
    en: 'Visit ID must be present',
    pl: 'ID wizyty musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8142,
    en: 'Visit ID must be a number',
    pl: 'ID wizyty musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8143,
    en: 'Visit ID must be between 1 and 7 characters long',
    pl: 'ID wizyty musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8144,
    en: 'There are no competitor visits for vehicle with this ID',
    pl: 'Nie ma wizyt konkurentów dla pojazdu z tym identyfikatorem', 
    es: '', 
    ru: ''
  },
  {
    id: 8145,
    en: 'No reports were found',
    pl: 'Nie znaleziono żadnych raportów', 
    es: '', 
    ru: ''
  },
  {
    id: 8146,
    en: 'No alerts were found',
    pl: 'Nie znaleziono żadnych alertów', 
    es: '', 
    ru: ''
  },
  {
    id: 8147,
    en: 'Alert with such ID does not exist',
    pl: 'Alert z takim ID nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8148,
    en: 'Alert was marked as read',
    pl: 'Alert został oznaczony jako przeczytany', 
    es: '', 
    ru: ''
  },
  {
    id: 8149,
    en: 'Alert was not marked as read',
    pl: 'Alert nie został oznaczony jako przeczytany', 
    es: '', 
    ru: ''
  },
  {
    id: 8150,
    en: 'Alert ID is required, must be a number between 1 and 7 characters long',
    pl: 'ID alertu jest wymagany, musi zawierać od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8151,
    en: 'ID must be a valid number',
    pl: 'ID musi być prawidłową liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8152,
    en: 'Some of your alerts were not deleted',
    pl: 'Niektóre z Twoich alertów nie zostały usunięte', 
    es: '', 
    ru: ''
  },

  {
    id: 8153,
    en: 'Alerts deleted successfuly',
    pl: 'Alerty zostały usunięte pomyślnie', 
    es: '', 
    ru: ''
  },
  {
    id: 8154,
    en: 'Alert ID is required, must be a number between 1 and 7 characters long',
    pl: 'Identyfikator alertu jest wymagany, musi zawierać od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8155,
    en: 'Alert enabled can only be true or false',
    pl: 'Alert włączony może mieć tylko wartość prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8156,
    en: 'Speed alert was successfuly turned on',
    pl: 'Alert prędkości został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8157,
    en: 'Speed alert was successfuly turned off',
    pl: 'Alert prędkości został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8158,
    en: 'Alert ID is required, must be a number between 1 and 7 characters long',
    pl: 'Identyfikator alertu jest wymagany, musi zawierać od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8159,
    en: 'Alert enabled can only be true or false',
    pl: 'Alert włączony może mieć tylko wartość prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8160,
    en: 'Geo fence alert was successfuly turned on',
    pl: 'Powiadomienie o ogrodzeniu geograficznym zostało pomyślnie włączone', 
    es: '', 
    ru: ''
  },
  {
    id: 8161,
    en: 'Geo fence alert was successfuly turned off',
    pl: 'Powiadomienie o ogrodzeniu geograficznym zostało pomyślnie wyłączone', 
    es: '', 
    ru: ''
  },

  {
    id: 8162,
    en: 'Engine temperature alert was successfuly turned on',
    pl: 'Alarm temperatury silnika został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8163,
    en: 'Engine temperature alert was successfuly turned off',
    pl: 'Alarm temperatury silnika został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8164,
    en: 'Rpm alert was successfuly turned on',
    pl: 'Alarm obrotów został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8165,
    en: 'Rpm alert was successfuly turned off',
    pl: 'Alarm obrotów został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },

  {
    id: 8166,
    en: 'Gasoline use alert was successfuly turned on',
    pl: 'Powiadomienie o zużyciu benzyny zostało pomyślnie włączone', 
    es: '', 
    ru: ''
  },
  {
    id: 8167,
    en: 'Gasoline use alert was successfuly turned off',
    pl: 'Alert o zużyciu benzyny został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8168,
    en: 'Gas malfunction alert was successfuly turned on',
    pl: 'Alarm awarii gazu został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8169,
    en: 'Gas malfunction alert was successfuly turned off',
    pl: 'Alarm awarii gazu został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8170,
    en: 'Gas maintenance alert was successfuly turned on',
    pl: 'Alarm konserwacji gazu został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8171,
    en: 'Gas maintenance alert was successfuly turned off',
    pl: 'Alarm konserwacji gazu został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8172,
    en: 'Alert value must be a number',
    pl: 'Wartość alertu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8173,
    en: 'MT value must be a number',
    pl: 'Wartość MT musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8174,
    en: 'MP value must be a number',
    pl: 'Wartość MP musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8175,
    en: 'Alert was updated',
    pl: 'Alert został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8176,
    en: 'Alert latitude is required, must be a number',
    pl: 'Alert szerokość geograficzna jest wymagana, musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8177,
    en: 'Alert longitude is required, must be a number',
    pl: 'Alert szerokość geograficzna jest wymagana, musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8178,
    en: 'Authorization denied',
    pl: 'Odmowa autoryzacji', 
    es: '', 
    ru: ''
  },
  {
    id: 8179,
    en: 'You are not authorized. Please login. (T1)',
    pl: 'Nie jesteś upoważniony. Proszę się zalogować. (T1)', 
    es: '', 
    ru: ''
  },
  {
    id: 8180,
    en: 'You have been logged out',
    pl: 'Zostałeś wylogowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8181,
    en: 'Your session has expired. Please login again.',
    pl: 'Twoja sesja wygasła. Proszę, zaloguj się ponownie.', 
    es: '', 
    ru: ''
  },
  {
    id: 8182,
    en: 'You are not authorized. Please login. (T3)',
    pl: 'Nie jesteś upoważniony. Proszę się zalogować. (T3)', 
    es: '', 
    ru: ''
  },
  {
    id: 8183,
    en: 'Please submit at least one valid alert ID',
    pl: 'Prześlij co najmniej jeden prawidłowe ID alertu', 
    es: '', 
    ru: ''
  },
  {
    id: 8184,
    en: 'You are not authorized. You must be owner of this record.',
    pl: 'Nie jesteś upoważniony. Musisz być właścicielem tego rekordu.', 
    es: '', 
    ru: ''
  },
  {
    id: 8185,
    en: 'You are not authorized. Lock feature disabled.',
    pl: 'Nie jesteś upoważniony. Funkcja alertów wyłączona.', 
    es: '', 
    ru: ''
  },
  {
    id: 8186,
    en: 'You are not authorized. Reports feature disabled.',
    pl: 'Nie jesteś upoważniony. Funkcja raportów wyłączona.', 
    es: '', 
    ru: ''
  },
  {
    id: 8187,
    en: 'You are not authorized. Routes feature disabled.',
    pl: 'Nie jesteś upoważniony. Funkcja tras wyłączona.', 
    es: '', 
    ru: ''
  },
  {
    id: 8188,
    en: 'You are not authorized. Alerts feature disabled.',
    pl: 'Nie jesteś upoważniony. Funkcja alertów wyłączona.', 
    es: '', 
    ru: ''
  },
  {
    id: 8189,
    en: 'There are no vehicles with activated IGL',
    pl: 'Nie ma pojazdów z aktywowanym IGL', 
    es: '', 
    ru: ''
  },
  {
    id: 8190,
    en: 'Date must be valid date in the following format YYYY-MM-DD',
    pl: 'Data musi być prawidłową datą w formacie RRRR-MM-DD', 
    es: '', 
    ru: ''
  },
  {
    id: 8191,
    en: 'Email notifications can be only true or false',
    pl: 'Powiadomienia e-mail mogą być tylko prawdziwe lub fałszywe', 
    es: '', 
    ru: ''
  },
  {
    id: 8192,
    en: 'Roaming settings must be present',
    pl: 'Ustawienia roamingu muszą być obecne', 
    es: '', 
    ru: ''
  },
  {
    id: 8193,
    en: 'Data frequency must be a number',
    pl: 'Częstotliwość danych musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8194,
    en: 'Email notifications for this vehicle were enabled',
    pl: 'Powiadomienia e-mail dla tego pojazdu zostały włączone', 
    es: '', 
    ru: ''
  },
  {
    id: 8195,
    en: 'Email notifications for this vehicle were disabled',
    pl: 'Powiadomienia e-mail dla tego pojazdu zostały wyłączone', 
    es: '', 
    ru: ''
  },
  {
    id: 8196,
    en: 'Vehicle selection was updated',
    pl: 'Wybór pojazdu został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8197,
    en: 'Can not select more then 10 vehicles',
    pl: 'Nie można wybrać więcej niż 10 pojazdów', 
    es: '', 
    ru: ''
  },
  {
    id: 8198,
    en: 'Parameter value can be only true or false',
    pl: 'Wartością parametru może być tylko prawda lub fałsz', 
    es: '', 
    ru: ''
  },
  {
    id: 8199,
    en: 'Parameter is unknown',
    pl: 'Parametr jest nieznany', 
    es: '', 
    ru: ''
  },
  {
    id: 8200,
    en: 'API limi rate reached',
    pl: 'Osiągnięto limit API', 
    es: '', 
    ru: ''
  },
  {
    id: 8201,
    en: 'Maximum number of attempts to login reached',
    pl: 'Osiągnięto maksymalną liczbę prób logowania', 
    es: '', 
    ru: ''
  },
  {
    id: 8202,
    en: 'Maximum number of attempts to reset password reached',
    pl: 'Osiągnięto maksymalną liczbę prób zresetowania hasła', 
    es: '', 
    ru: ''
  },
  {
    id: 8203,
    en: 'Time must be a valid timestamp',
    pl: 'Czas musi być prawidłowym znacznikiem czasu', 
    es: '', 
    ru: ''
  },
  {
    id: 8204,
    en: 'Search query must be a date in YYYY-MM-DD format',
    pl: 'Zapytanie musi zawierać datę w formacie RRRR-MM-DD', 
    es: '', 
    ru: ''
  },
  {
    id: 8205,
    en: 'Timezone must be a valid timezone in following format: Continent/Capital_City',
    pl: 'Strefa czasowa musi być prawidłową strefą czasową w następującym formacie: Kontynent/Stolica_Miasto', 
    es: '', 
    ru: ''
  },
  {
    id: 8206,
    en: 'Company name can not be longer then 40 characters',
    pl: 'Nazwa firmy nie może być dłuższa niż 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8207,
    en: 'Street name can not be longer then 40 characters',
    pl: 'Nazwa ulicy nie może być dłuższa niż 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8208,
    en: 'City can not be longer then 40 characters',
    pl: 'Miasto nie może być dłuższe niż 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8209,
    en: 'Postcode can not be longer then 15 characters',
    pl: 'Kod pocztowy nie może być dłuższy niż 15 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8210,
    en: 'VAT number can not be longer then 20 characters',
    pl: 'Numer VAT nie może być dłuższy niż 20 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8211,
    en: 'Account settings were updated',
    pl: 'Ustawienia konta zostały zaktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 8212,
    en: 'Settings for such accunt do not exist',
    pl: 'Ustawienia dla takiego konta nie istnieją', 
    es: '', 
    ru: ''
  },
  {
    id: 8213,
    en: 'Invoice number is required',
    pl: 'Numer faktury jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8214,
    en: 'Invoice number must be a number',
    pl: 'Numer faktury musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8215,
    en: 'Invoice number must be between 1 and 10 characters long',
    pl: 'Numer faktury musi mieć od 1 do 10 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8216,
    en: 'Invoice with such number does not exist',
    pl: 'Faktura o takim numerze nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8217,
    en: 'Country can not be longer then 40 characters',
    pl: 'Kraj nie może być dłuższy niż 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8218,
    en: 'Payment was successful',
    pl: 'Płatność się powiodła', 
    es: '', 
    ru: ''
  },
  {
    id: 8219,
    en: 'Notifications must be true or false',
    pl: 'Powiadomienia muszą być prawdziwe lub fałszywe', 
    es: '', 
    ru: ''
  },
  {
    id: 8220,
    en: 'Table settings were updated',
    pl: 'Ustawienia tabeli zostały zaktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 8221,
    en: 'Table settings can only have boolean value',
    pl: 'Ustawienia tabeli mogą mieć tylko wartość logiczną', 
    es: '', 
    ru: ''
  },

  {
    id: 8222,
    en: 'Record was deleted',
    pl: 'Moduł N. Seryjny został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8223,
    en: 'Wrong record ID',
    pl: 'Błędny ID modułu', 
    es: '', 
    ru: ''
  },
  {
    id: 8224,
    en: 'Invalid IP address',
    pl: 'Nieprawidłowy adres IP', 
    es: '', 
    ru: ''
  },
  {
    id: 8225,
    en: 'Title can be up to 40 characters long',
    pl: 'Tytuł może mieć do 40 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8226,
    en: 'IP record was added',
    pl: 'Rekord IP został dodany', 
    es: '', 
    ru: ''
  },
  {
    id: 8227,
    en: 'IP already in the database',
    pl: 'IP już w bazie danych', 
    es: '', 
    ru: ''
  },
  {
    id: 8228,
    en: 'Locked vehicle alert turned on',
    pl: 'Włączono alert o zablokowanym pojeździe', 
    es: '', 
    ru: ''
  },
  {
    id: 8229,
    en: 'Locked vehicle alert turned off',
    pl: 'Ostrzeżenie o zablokowanym pojeździe wyłączone', 
    es: '', 
    ru: ''
  },
  {
    id: 8230,
    en: 'Alert setings for such vehicle does not exists',
    pl: 'Ustawienia alertów dla takiego pojazdu nie istnieją', 
    es: '', 
    ru: ''
  },
  {
    id: 8231,
    en: 'Payment was passed for processing successfuly',
    pl: 'Płatność została pomyślnie przekazana do przetworzenia', 
    es: '', 
    ru: ''
  },
  {
    id: 8232,
    en: 'Payment was cancelled',
    pl: 'Płatność została anulowana', 
    es: '', 
    ru: ''
  },
  {
    id: 8233,
    en: 'Settings for given account are missing',
    pl: 'Brak ustawień dla danego konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8234,
    en: 'There was a problem with the payment session',
    pl: 'Wystąpił problem z sesją płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 8235,
    en: 'Payment type must be 6 characters long',
    pl: 'Rodzaj płatności musi mieć 6 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8236,
    en: 'Such payment method does not exist',
    pl: 'Taka metoda płatności nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8237,
    en: 'Account email must be a valid email',
    pl: 'Adres e-mail konta musi być prawidłowym adresem e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 8238,
    en: 'There was a problem updating the account',
    pl: 'Wystąpił problem z aktualizacją konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8239,
    en: 'Account ID is required',
    pl: 'Identyfikator konta jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8240,
    en: 'Account ID must be a valid account number',
    pl: 'Identyfikator konta musi być prawidłowym numerem konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8241,
    en: 'Such account ID does not exist',
    pl: 'Taki identyfikator konta nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8242,
    en: 'Account was updated',
    pl: 'Konto zostało zaktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 8243,
    en: 'Account email is required',
    pl: 'Adres e-mail konta jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8244,
    en: 'Account language is required',
    pl: 'Język konta jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8245,
    en: 'Account type is required',
    pl: 'Typ konta jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8246,
    en: 'Account premium cost is required',
    pl: 'Wymagany jest koszt premium konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8247,
    en: 'Account premium cost percentage per month is required',
    pl: 'Procentowy koszt konta premium miesięcznie jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8248,
    en: 'Account premium cost percentage per quater is required',
    pl: 'Procentowy koszt premium konta na kwartał jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8249,
    en: 'Account premium cost percentage per year is required',
    pl: 'Procent rocznego kosztu składki konta jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8250,
    en: 'Account standard cost is required',
    pl: 'Wymagany jest standardowy koszt konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8251,
    en: 'Account standard cost percentage per month is required',
    pl: 'Wymagany jest standardowy procent kosztów konta na miesiąc', 
    es: '', 
    ru: ''
  },
  {
    id: 8252,
    en: 'Account standard cost percentage per quater is required',
    pl: 'Wymagany jest standardowy procent kosztu konta na kwartał', 
    es: '', 
    ru: ''
  },
  {
    id: 8253,
    en: 'Account standard cost percentage per year is required',
    pl: 'Wymagany jest standardowy procent kosztów konta na rok', 
    es: '', 
    ru: ''
  },
  {
    id: 8254,
    en: 'Company name is required',
    pl: 'Nazwa firmy jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8255,
    en: 'City name is required',
    pl: 'Nazwa miasta jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8256,
    en: 'Street name is required',
    pl: 'Nazwa ulicy jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8257,
    en: 'Street name is required',
    pl: 'Nazwa ulicy jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8258,
    en: 'Postcode name is required',
    pl: 'Nazwa kodu pocztowego jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8259,
    en: 'VAT is required',
    pl: 'VAT jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8260,
    en: 'Timezone is required',
    pl: 'Strefa czasowa jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8261,
    en: 'Currency is required',
    pl: 'Waluta jest wymagana', 
    es: '', 
    ru: ''
  },
  {
    id: 8262,
    en: 'Account was added',
    pl: 'Konto zostało dodane', 
    es: '', 
    ru: ''
  },
  {
    id: 8263,
    en: 'Account email already associated with different account',
    pl: 'Adres e-mail konta już powiązany z innym kontem', 
    es: '', 
    ru: ''
  },
  {
    id: 8264,
    en: 'Google Maps API key is required',
    pl: 'Klucz API Map Google jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8265,
    en: 'Payment method must be selected',
    pl: 'Należy wybrać metodę płatności', 
    es: '', 
    ru: ''
  },
  {
    id: 8266,
    en: 'Transaction was confirmed successfully, vehicles are being updated',
    pl: 'Transakcja została pomyślnie potwierdzona, pojazdy są aktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 8267,
    en: 'New checkpoint was added',
    pl: 'Dodano nowy punkt kontrolny', 
    es: '', 
    ru: ''
  },
  {
    id: 8268,
    en: 'No checkpoints was found',
    pl: 'Nie znaleziono punktów kontrolnych', 
    es: '', 
    ru: ''
  },
  {
    id: 8269,
    en: 'Checkpoint ID must be a number',
    pl: 'Identyfikator stacji konkurencji musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8270,
    en: 'Checkpoint ID must be between 1 and 7 characters long',
    pl: 'Identyfikator punktu kontrolnego musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8271,
    en: 'Checkpoint with such ID does not exist',
    pl: 'Punkt kontrolny z takim identyfikatorem nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8272,
    en: 'Checkpoint ID must be present',
    pl: 'Identyfikator punktu kontrolnego musi być obecny', 
    es: '', 
    ru: ''
  },
  {
    id: 8273,
    en: 'Checkpoint ID must be a number',
    pl: 'Identyfikator punktu kontrolnego musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8274,
    en: 'Checkpoint ID must be between 1 and 7 characters long',
    pl: 'Identyfikator punktu kontrolnego musi mieć od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8275,
    en: 'Checkpoint ID is reuqired and must have maximum 50 characters',
    pl: 'Identyfikator punktu kontrolnego jest wymagany i musi mieć maksymalnie 50 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8276,
    en: 'Please enter checkpoint name that is maxiumum 50 characters long',
    pl: 'Proszę podać nazwę punktu kontrolnego o maksymalnej długości 50 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8277,
    en: 'Checkpoint was updated',
    pl: 'Punkt kontrolny został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8278,
    en: 'Wrong checkpoint ID',
    pl: 'Nieprawidłowy ID punktu kontrolnego', 
    es: '', 
    ru: ''
  },
  {
    id: 8279,
    en: 'Checkpoint was deleted',
    pl: 'Punkt kontrolny został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8280,
    en: 'Please enter address that is maxiumum 100 characters long',
    pl: 'Wpisz adres o maksymalnej długości 100 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8281,
    en: 'Please enter label that is maxiumum 25 characters long',
    pl: 'Podaj etykietę o maksymalnej długości 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8282,
    en: 'Date from must be 10 characters long',
    pl: 'Data od musi mieć 10 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8283,
    en: 'Date to must be 10 characters long',
    pl: 'Data do musi mieć 10 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8284,
    en: 'Auto lock settings must be present',
    pl: 'Ustawienia automatycznej blokady muszą być obecne', 
    es: '', 
    ru: ''
  },
  {
    id: 8285,
    en: 'Price of the fuel was updated successfuly',
    pl: 'Cena paliwa została zaktualizowana pomyślnie', 
    es: '', 
    ru: ''
  },
  {
    id: 8286,
    en: 'There was error updating the fuel price',
    pl: 'Podczas aktualizacji ceny paliwa wystąpił błąd', 
    es: '', 
    ru: ''
  },

  {
    id: 8287,
    en: 'RFID ID must be a number between 1 to 7 characters',
    pl: 'Identyfikator RFID musi być liczbą od 1 do 7 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8288,
    en: 'No RFID records were found',
    pl: 'Nie znaleziono rekordów RFID', 
    es: '', 
    ru: ''
  },
  {
    id: 8289,
    en: 'RFID record with such ID does not exist',
    pl: 'Rekord RFID z takim identyfikatorem nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8290,
    en: 'RFID record was deleted',
    pl: 'Rekord RFID został usunięty', 
    es: '', 
    ru: ''
  },
  {
    id: 8291,
    en: 'RFID number must be a number with 10 digits',
    pl: 'Numer RFID musi być liczbą składającą się z 10 cyfr', 
    es: '', 
    ru: ''
  },
  {
    id: 8292,
    en: 'Custom ID must be between 1 and 25 characters long',
    pl: 'Identyfikator niestandardowy musi mieć od 1 do 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8293,
    en: 'Label can only be 25 characters long',
    pl: 'Etykieta może mieć tylko 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8294,
    en: 'RFID record was updated',
    pl: 'Rekord RFID został zaktualizowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8295,
    en: 'New RFID record was added',
    pl: 'Dodano nowy rekord RFID', 
    es: '', 
    ru: ''
  },


  {
    id: 8296,
    en: 'Group ID is required',
    pl: 'Identyfikator grupy jest wymagany', 
    es: '', 
    ru: ''
  },
  {
    id: 8297,
    en: 'Group ID must be a valid UUID number',
    pl: 'Identyfikator grupy musi być prawidłowym numerem UUID', 
    es: '', 
    ru: ''
  },
  {
    id: 8298,
    en: 'Group title must be between 1 and 25 characters long',
    pl: 'Tytuł grupy musi mieć od 1 do 25 znaków', 
    es: '', 
    ru: ''
  },
  {
    id: 8299,
    en: 'Group with such ID does not exist',
    pl: 'Grupa o takim ID nie istnieje', 
    es: '', 
    ru: ''
  },
  {
    id: 8300,
    en: 'Group was updated',
    pl: 'Grupa została zaktualizowana', 
    es: '', 
    ru: ''
  },
  {
    id: 8301,
    en: 'Group was deleted',
    pl: 'Grupa została usunięta', 
    es: '', 
    ru: ''
  },
  {
    id: 8302,
    en: 'Wrong group ID',
    pl: 'Nieprawidłowy identyfikator grupy', 
    es: '', 
    ru: ''
  },
  {
    id: 8303,
    en: 'New group was added',
    pl: 'Dodano nową grupę', 
    es: '', 
    ru: ''
  },
  {
    id: 8304,
    en: 'This group is default and can not be delted',
    pl: 'Ta grupa jest domyślna i nie można jej usunąć', 
    es: '', 
    ru: ''
  },
  {
    id: 8305,
    en: 'There are no data for this group in given month',
    pl: 'Brak danych dla tej grupy w danym miesiącu', 
    es: '', 
    ru: ''
  },
  {
    id: 8306,
    en: 'Such email does not exist in our database',
    pl: 'Taki e-mail nie istnieje w naszej bazie danych', 
    es: '', 
    ru: ''
  },
  {
    id: 8307,
    en: 'Date "from" must be a valid date YYYY-MM',
    pl: 'Data „od” musi być prawidłową datą RRRR-MM', 
    es: '', 
    ru: ''
  },
  {
    id: 8308,
    en: 'Date "to" must be a valid date YYYY-MM',
    pl: 'Data "do" musi być prawidłową datą RRRR-MM', 
    es: '', 
    ru: ''
  },
  {
    id: 8309,
    en: 'Gas percentage alert was successfuly turned on',
    pl: 'Alert procentowy gazu został pomyślnie włączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8310,
    en: 'Gas percentage alert was successfuly turned off',
    pl: 'Alert procentowy gazu został pomyślnie wyłączony', 
    es: '', 
    ru: ''
  },
  {
    id: 8311,
    en: 'All vehicles settings in the group were updated',
    pl: 'Wszystkie ustawienia pojazdów w grupie zostały zaktualizowane', 
    es: '', 
    ru: ''
  },
  {
    id: 8312,
    en: 'Account was deleted',
    pl: 'Konto zostało usunięte', 
    es: '', 
    ru: ''
  },
  {
    id: 8313,
    en: 'Wrong account ID',
    pl: 'Nieprawidłowy identyfikator konta', 
    es: '', 
    ru: ''
  },
  {
    id: 8314,
    en: 'There are still vehicles associated with this account. Delete all vehicles from the account first.',
    pl: 'Z tym kontem nadal są powiązane pojazdy. Najpierw usuń wszystkie pojazdy z konta.', 
    es: '', 
    ru: ''
  },
  {
    id: 8315,
    en: 'Each of the emails must be a valid email',
    pl: 'Każdy z adresów e-mail musi być prawidłowym adresem e-mail', 
    es: '', 
    ru: ''
  },
  {
    id: 8316,
    en: 'Request for maintenance unpair was successfuly sent',
    pl: 'Prośba o usunięcie sparowania konserwacji została pomyślnie wysłana', 
    es: '', 
    ru: ''
  },
  {
    id: 8317,
    en: 'There was an error sending a request for maintenance module unpairing',
    pl: 'Wystąpił błąd podczas wysyłania prośby o usunięcie sparowania modułu konserwacji', 
    es: '', 
    ru: ''
  },
  {
    id: 8318,
    en: 'Vehicle is already unpaired',
    pl: 'Pojazd jest już niesparowany', 
    es: '', 
    ru: ''
  },
  {
    id: 8319,
    en: 'Module version must be a number',
    pl: 'Wersja modułu musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8320,
    en: 'Group title already taken',
    pl: 'Tytuł grupy jest już zajęty', 
    es: '', 
    ru: ''
  },
  {
    id: 8321,
    en: 'You are not authorized',
    pl: 'Nie jesteś upoważniony', 
    es: '', 
    ru: ''
  },
  {
    id: 8322,
    en: 'Refuel sensitivity must be a number',
    pl: 'Czułość tankowania musi być liczbą', 
    es: '', 
    ru: ''
  },
  {
    id: 8323,
    en: 'Client abbreviation must be 3 characters long',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8324,
    en: 'Keep me signed in must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8325,
    en: 'Table settings can not be emtpy',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8326,
    en: 'Authorized options are required',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8327,
    en: 'Group IDs are required',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8328,
    en: 'Such group does not exist',
    pl: '', 
    es: '', 
    ru: ''
  },


  {
    id: 8329,
    en: 'Unit cost must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8330,
    en: 'Unit cost must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8331,
    en: 'Unit cost must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },

  {
    id: 8332,
    en: 'There was an error creating invoice',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8333,
    en: 'There was an error updating vehicles',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8334,
    en: 'Vehicle subscription was updated successfuly',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8335,
    en: 'There was an error sending the request to the sales team at AC. Please contact them and let them know you have just processed subscription renewal.',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8336,
    en: 'There was an error adding new vehicle',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8337,
    en: 'Such account does not exist',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8338,
    en: 'There was an error updating vehicle payment date',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8339,
    en: 'Subscription initial days is required and must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8340,
    en: 'Subscription payment limit days is required and must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8341,
    en: 'Task was scheduled successfully',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8342,
    en: 'Task type is required',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8343,
    en: 'Task date must be in YYYY-MM-DD format',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8344,
    en: 'Task was deleted',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8345,
    en: 'Task was not deleted.',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8346,
    en: 'Too many failed login attempts. You are blocked from your account for 1h. If you forgot your password you can reset it.',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8347,
    en: 'Year must be in YYYY format',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8348,
    en: 'Year and month must be in MM-YYYY format',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8349,
    en: 'Vehicle lock date was set successfuly',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8350,
    en: 'Calendar lock can be only true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8351,
    en: 'There was an error updating calendar lock',
    pl: '', 
    es: '', 
    ru: ''
  },

  {
    id: 8352,
    en: 'Distributto account group title must be between 1 and 25 characters long',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8353,
    en: 'Distributor account group title is already taken',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8354,
    en: 'Account was changed',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8355,
    en: 'Date must be a valid date YYYY-MM',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8356,
    en: 'Exploitation hours must be a number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8357,
    en: 'Billing enabled must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8358,
    en: 'Paypal enabled must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8359,
    en: 'Stripe enabled must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8360,
    en: 'Proforma enabled must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8361,
    en: 'Self managed billing enabled must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8362,
    en: 'Default latitude must be a float number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8363,
    en: 'Default longitude must be a float number',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8364,
    en: 'Payment per vehicle must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8365,
    en: 'Billing client abbreviation must be 3 characters long',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8366,
    en: 'Exploitation Saturday must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8367,
    en: 'Exploitation Sunday must be either true or false',
    pl: '', 
    es: '', 
    ru: ''
  },
  {
    id: 8368,
    en: 'Distributor group ID must be a valid UUID number',
    pl: '', 
    es: '', 
    ru: ''
  },
]
import React from 'react'
import { Link } from 'react-router-dom'


const NotFound: React.FC = () => {
  return (
    <div className="server-page server-page--error404">
      <div className="server-page__content">
        <div className="server-page__wrapper">
          <div className="server-page__card">
            <h1 className="server-page__title">404</h1>
            <h2 className="server-page__subtitle">The page you are looking for can not be found</h2>
            <Link to="/" className="btn btn--primary">Back to home</Link>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NotFound
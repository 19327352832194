import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchImeiId, deleteImeiId } from '../../actions/imei'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'


interface ImeiDeleteProps {
  router: { params: { id: string },  navigate: (to: string) => any },
  imei: any,
  fetchImeiId: any,
  pushImeiMessage: any,
  deleteImeiId: any,
}


interface ImeiDeleteState {
  module_id: string,
  imei: any,
  formSubmitted: boolean
}


export class ImeiDelete extends React.Component<ImeiDeleteProps, ImeiDeleteState> {

  state = {
    module_id: '',
    imei: {imei: ''},
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchImeiId(this.props.router.params.id)
    .then(() => {
      if(this.props.imei.status === fetchStates.success) {
        this.setState({ imei: this.props.imei.imei })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })

    if(this.state.module_id === '') {
      this.props.imei.fields = ['imei']
      this.setState({ formSubmitted: false })
      return error(2322)
    }
    
    if(this.state.module_id !== this.props.imei.imei.imei) {
      this.props.imei.fields = ['imei']
      this.setState({ formSubmitted: false })
      return error(2323)
    }

    if(this.state.module_id === this.props.imei.imei.imei) {
      this.props.deleteImeiId(this.state.module_id)
      .then(() => {
        if(this.props.imei.status === fetchStates.success) {
          this.props.router.navigate('/imei')
        }
        this.setState({ formSubmitted: false })
      })
    }
  }


  render() {

    const { status, fields } = this.props.imei
    const { module_id, imei, formSubmitted } = this.state

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          title={`${t(110)} '${imei.imei}'`} 
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(2011)} <strong>{imei.imei}</strong> {t(2012)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="imei">
                {t(2010)}
              </label>
              <input 
                type="text" 
                name="mocule_id"
                id="module_id" 
                value={module_id}
                onChange={e => this.setState({ module_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('imei') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(110)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ imei }: RootState) => ({ imei }),
  { fetchImeiId, deleteImeiId }
)(ImeiDelete)))
import markerStation from '../img/marker-station.png'
import markerCompetitor from'../img/marker-competitor.png'
import { Marker } from '@react-google-maps/api'


export const drawCompetitorMarker = (competitor: any) => {
  return <Marker
          key={competitor.station_id}
          zIndex={1}
          position={{
            lat: parseFloat(competitor.lat),
            lng: parseFloat(competitor.lng)
          }}
          icon={
            // @ts-expect-error
            new window.google.maps.MarkerImage(
              markerCompetitor,
              null, /* size is determined at runtime */
              null, /* origin is 0,0 */
              null, /* anchor is bottom center of the scaled image */
              new window.google.maps.Size(48, 48)
            )
          }
        />
}


export const drawStationMarker = (station: any) => {
  return <Marker
            key={station.station_id}
            zIndex={2}
            position={{
              lat: parseFloat(station.lat),
              lng: parseFloat(station.lng)
            }}
            icon={
              // @ts-expect-error
              new window.google.maps.MarkerImage(
                markerStation,
                null, /* size is determined at runtime */
                null, /* origin is 0,0 */
                null, /* anchor is bottom center of the scaled image */
                new window.google.maps.Size(48, 48)
              )
            }
          />
}



export const drawCardStart = (card: any) => {
  const lat = parseFloat(card.loc_lt);
  const lng = parseFloat(card.loc_ln);

  const svgMarker = {
    path: "M20.525375,10.7746875 C21.016,10.984 21.3333125,11.46675 21.3333125,12.0000625 C21.3333125,12.533375 21.016,13.0160625 20.5253125,13.225375 L2.666625,20.8786875 L2.666625,30.6666875 C2.666625,31.404 2.070625,32 1.3333125,32 C0.5973125,32 -5.32907052e-15,31.404 -5.32907052e-15,30.6666875 L-5.32907052e-15,20 L-5.32907052e-15,4 L-5.32907052e-15,1.3333125 C-5.32907052e-15,0.596 0.597375,0 1.333375,0 C2.0706875,0 2.6666875,0.596 2.6666875,1.3333125 L2.6666875,3.1213125 L20.525375,10.7746875 Z M9.24774219,15 L10.8786016,15 L8.34930469,7.83203125 L6.49871875,7.83203125 L3.9791875,15 L5.61004687,15 L6.127625,13.3007812 L8.73016406,13.3007812 L9.24774219,15 Z M8.36883594,12.03125 L6.50848437,12.03125 C7.01629941,10.3873616 7.32391353,9.35709844 7.43133594,8.94042969 C7.45737773,9.05761777 7.50050881,9.21712139 7.56073047,9.41894531 C7.62095212,9.62076924 7.89031792,10.4915288 8.36883594,12.03125 Z",
    fillColor: '#000000',
    strokeColor: "#FFFFFF",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
    anchor: new window.google.maps.Point(12, 24),
  };

  return <Marker
    key={card.ts}
    zIndex={100}
    position={{
      lat,
      lng
    }}
    icon={svgMarker}
    // animation={card.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
  />
}



export const drawCardEnd = (card: any) => {
  const lat = parseFloat(card.loc_lt);
  const lng = parseFloat(card.loc_ln);

  const svgMarker = {
    path: "M20.5461875,10.7746875 C21.0368125,10.984 21.354125,11.46675 21.354125,12.0000625 C21.354125,12.533375 21.0368125,13.0160625 20.546125,13.225375 L2.6874375,20.8786875 L2.6874375,30.6666875 C2.6874375,31.404 2.0914375,32 1.354125,32 C0.618125,32 0.0208125,31.404 0.0208125,30.6666875 L0.0208125,20 L0.0208125,4 L0.0208125,1.3333125 C0.0208125,0.596 0.6181875,0 1.3541875,0 C2.0915,0 2.6875,0.596 2.6875,1.3333125 L2.6875,3.1213125 L20.5461875,10.7746875 Z M4.8984375,7.86132812 L4.8984375,15 L7.56933594,15 C8.37663164,15 9.01545989,14.8177102 9.48583984,14.453125 C9.9562198,14.0885398 10.1914062,13.5807324 10.1914062,12.9296875 C10.1914062,12.4707008 10.0913096,12.102866 9.89111328,11.8261719 C9.69091697,11.5494778 9.3645856,11.3606776 8.91210938,11.2597656 L8.91210938,11.2109375 C9.24414229,11.1555987 9.51025291,10.9912123 9.71044922,10.7177734 C9.91064553,10.4443346 10.0107422,10.0944032 10.0107422,9.66796875 C10.0107422,9.0397104 9.78206609,8.58154441 9.32470703,8.29345703 C8.86734797,8.00536965 8.13249204,7.86132812 7.12011719,7.86132812 L4.8984375,7.86132812 Z M6.41210938,10.6884766 L6.41210938,9.1015625 L7.20800781,9.1015625 C7.64095269,9.1015625 7.95751853,9.16096946 8.15771484,9.27978516 C8.35791116,9.39860085 8.45800781,9.5947252 8.45800781,9.86816406 C8.45800781,10.1611343 8.3660491,10.3710931 8.18212891,10.4980469 C7.99820872,10.6250006 7.70117393,10.6884766 7.29101562,10.6884766 L6.41210938,10.6884766 Z M6.41210938,11.8896484 L7.34960938,11.8896484 C8.19596777,11.8896484 8.61914062,12.1858694 8.61914062,12.7783203 C8.61914062,13.107098 8.51985776,13.3512362 8.32128906,13.5107422 C8.12272036,13.6702482 7.81510625,13.75 7.3984375,13.75 L6.41210938,13.75 L6.41210938,11.8896484 Z",
    fillColor: '#000000',
    strokeColor: "#FFFFFF",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
    anchor: new window.google.maps.Point(12, 24),
  };

  return <Marker
    key={card.ts}
    zIndex={100}
    position={{
      lat,
      lng
    }}
    icon={svgMarker}
    // animation={card.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
  />
}


export const drawRouteStart = (route: any) => {
  const lat = parseFloat(route.loc_lt);
  const lng = parseFloat(route.loc_ln);

  const svgMarker = {
    path: "M20.525375,10.7746875 C21.016,10.984 21.3333125,11.46675 21.3333125,12.0000625 C21.3333125,12.533375 21.016,13.0160625 20.5253125,13.225375 L2.666625,20.8786875 L2.666625,30.6666875 C2.666625,31.404 2.070625,32 1.3333125,32 C0.5973125,32 -5.32907052e-15,31.404 -5.32907052e-15,30.6666875 L-5.32907052e-15,20 L-5.32907052e-15,4 L-5.32907052e-15,1.3333125 C-5.32907052e-15,0.596 0.597375,0 1.333375,0 C2.0706875,0 2.6666875,0.596 2.6666875,1.3333125 L2.6666875,3.1213125 L20.525375,10.7746875 Z M9.24774219,15 L10.8786016,15 L8.34930469,7.83203125 L6.49871875,7.83203125 L3.9791875,15 L5.61004687,15 L6.127625,13.3007812 L8.73016406,13.3007812 L9.24774219,15 Z M8.36883594,12.03125 L6.50848437,12.03125 C7.01629941,10.3873616 7.32391353,9.35709844 7.43133594,8.94042969 C7.45737773,9.05761777 7.50050881,9.21712139 7.56073047,9.41894531 C7.62095212,9.62076924 7.89031792,10.4915288 8.36883594,12.03125 Z",
    fillColor: '#000000',
    strokeColor: "#FFFFFF",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
    anchor: new window.google.maps.Point(12, 24),
  };

  return <Marker
    key={route.ts}
    zIndex={100}
    position={{
      lat,
      lng
    }}
    icon={svgMarker}
    // animation={route.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
  />
}


export const drawRouteEnd = (route: any) => {
  const lat = parseFloat(route.loc_lt);
  const lng = parseFloat(route.loc_ln);

  const svgMarker = {
    path: "M20.5461875,10.7746875 C21.0368125,10.984 21.354125,11.46675 21.354125,12.0000625 C21.354125,12.533375 21.0368125,13.0160625 20.546125,13.225375 L2.6874375,20.8786875 L2.6874375,30.6666875 C2.6874375,31.404 2.0914375,32 1.354125,32 C0.618125,32 0.0208125,31.404 0.0208125,30.6666875 L0.0208125,20 L0.0208125,4 L0.0208125,1.3333125 C0.0208125,0.596 0.6181875,0 1.3541875,0 C2.0915,0 2.6875,0.596 2.6875,1.3333125 L2.6875,3.1213125 L20.5461875,10.7746875 Z M4.8984375,7.86132812 L4.8984375,15 L7.56933594,15 C8.37663164,15 9.01545989,14.8177102 9.48583984,14.453125 C9.9562198,14.0885398 10.1914062,13.5807324 10.1914062,12.9296875 C10.1914062,12.4707008 10.0913096,12.102866 9.89111328,11.8261719 C9.69091697,11.5494778 9.3645856,11.3606776 8.91210938,11.2597656 L8.91210938,11.2109375 C9.24414229,11.1555987 9.51025291,10.9912123 9.71044922,10.7177734 C9.91064553,10.4443346 10.0107422,10.0944032 10.0107422,9.66796875 C10.0107422,9.0397104 9.78206609,8.58154441 9.32470703,8.29345703 C8.86734797,8.00536965 8.13249204,7.86132812 7.12011719,7.86132812 L4.8984375,7.86132812 Z M6.41210938,10.6884766 L6.41210938,9.1015625 L7.20800781,9.1015625 C7.64095269,9.1015625 7.95751853,9.16096946 8.15771484,9.27978516 C8.35791116,9.39860085 8.45800781,9.5947252 8.45800781,9.86816406 C8.45800781,10.1611343 8.3660491,10.3710931 8.18212891,10.4980469 C7.99820872,10.6250006 7.70117393,10.6884766 7.29101562,10.6884766 L6.41210938,10.6884766 Z M6.41210938,11.8896484 L7.34960938,11.8896484 C8.19596777,11.8896484 8.61914062,12.1858694 8.61914062,12.7783203 C8.61914062,13.107098 8.51985776,13.3512362 8.32128906,13.5107422 C8.12272036,13.6702482 7.81510625,13.75 7.3984375,13.75 L6.41210938,13.75 L6.41210938,11.8896484 Z",
    fillColor: '#000000',
    strokeColor: "#FFFFFF",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1,
    anchor: new window.google.maps.Point(12, 24),
  };

  return <Marker
    key={route.ts}
    zIndex={100}
    position={{
      lat,
      lng
    }}
    icon={svgMarker}
    // animation={route.ts === this.state.selectedPoint.ts ? 1 : (this.state.openInfoBox === true ? 0 : 2)}
  />
}
const routesCreatePagination = (routes: any) => {
  let paginator = [];
  let route: any = [];
  paginator.push(routes)

  let previousTime = routes[0].ts;
  let currentTime = routes[0].ts;

  routes.map((point: {ts: number}, index: number) => {
    currentTime = point.ts;
    if(currentTime - previousTime > 300 || index === routes.length - 1) {
      paginator.push(route)
      route = [];
    }
    previousTime = point.ts;
    route.push(point);
    return null;
  });

  return paginator;
}


export default routesCreatePagination
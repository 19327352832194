import classnames from 'classnames'


export const LogoStelematicNew = (props: {size: number, color: string}) => (
  <span className={classnames('ac-icon ac-icon--logo', {})}>
    <svg width={props.size ? props.size : '40px'} height={props.size ? props.size : '40px'} viewBox="0 0 119 118" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={props.color ? props.color : '#2A2C44'}>
            <polygon id="Path" points="0.766 57.777 1.89 58.917 3.05 57.777"></polygon>
            <polygon id="Path" points="3.05 57.777 3.05 57.777 1.89 58.917 1.903 58.917"></polygon>
            <polygon id="Path" points="78.387 35.436 78.387 43.069 17.744 43.069 10.684 50.136 78.387 50.136 78.387 57.777 3.05 57.777 1.903 58.917 7.817 64.837 78.387 64.837 78.387 72.485 15.48 72.485 22.534 79.545 78.387 79.545 78.387 87.199 30.18 87.199 60.735 117.749 118.725 59.766 59.886 0.933 25.392 35.436"></polygon>
            <rect id="Rectangle" transform="translate(19.007857, 76.065939) rotate(-134.975691) translate(-19.007857, -76.065939) " x="14.017904" y="76.0159388" width="9.97990519" height="1"></rect>
            <polygon id="Path" points="59.886 0.933 25.392 35.436 25.392 35.436"></polygon>
            <rect id="Rectangle" transform="translate(4.860071, 61.933114) rotate(45.028358) translate(-4.860071, -61.933114) " x="0.675814259" y="61.8831136" width="8.36851248" height="1"></rect>
            <polygon id="Path" points="30.18 87.199 30.18 87.199 60.735 117.749"></polygon>
        </g>
      </g>
    </svg>
  </span>
);


export const LogoStelematicFullNew = (props: {size: number, color: string}) => (
  <span className={classnames('ac-icon ac-icon--logo', {})}>
    <svg width={props.size ? props.size : '241px'} height="59px" viewBox="0 0 241 59" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="logo-stag_telematic_black">
            <g id="Group" transform="translate(0.000000, 9.000000)">
                <polygon id="Path" points="1.727 19.967 1.728 19.968 1.728 19.967"></polygon>
                <polygon id="Path" points="1.173 19.413 1.727 19.967 1.728 19.967"></polygon>
                <polygon id="Path" points="38.9 12.266 38.9 8.551 13.144 8.551 9.429 12.266"></polygon>
                <polygon id="Path" points="168.752 0.487 166.688 0.487 160.145 13.348 175.295 13.348"></polygon>
            </g>
            <polygon id="Path" fill="#FFFFFF" points="62.735 45.822 69.979 45.822 69.979 57.939 75.186 57.939 75.186 45.822 82.417 45.822 82.417 41.676 62.735 41.676"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="88.447 51.422 100.91 51.422 100.91 47.848 88.447 47.848 88.447 45.501 101.578 45.501 101.578 41.676 83.562 41.676 83.562 57.939 101.672 57.939 101.672 54.031 88.447 54.031"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="108.428 41.676 103.221 41.676 103.221 57.939 119.998 57.939 119.998 53.793 108.428 53.793"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="126.014 51.422 138.477 51.422 138.477 47.848 126.014 47.848 126.014 45.501 139.145 45.501 139.145 41.676 121.129 41.676 121.129 57.939 139.24 57.939 139.24 54.031 126.014 54.031"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="154.609 52.232 149.771 41.676 140.789 41.676 140.789 57.939 145.674 57.939 145.555 45.763 146.424 45.763 152.561 57.939 156.67 57.939 162.807 45.763 163.617 45.763 163.498 57.939 168.383 57.939 168.383 41.676 159.447 41.676"></polygon>
            <polygon id="Path" points="181.061 45.465 177.926 51.625 185.182 51.625 182.049 45.465"></polygon>
            <path d="M185.434,41.676 L177.737,41.676 L169.11,57.94 L174.77,57.94 L176.163,55.188 L186.923,55.188 L188.318,57.94 L193.941,57.94 L185.434,41.676 Z M185.182,51.625 L177.926,51.625 L181.061,45.465 L185.182,51.625 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
            <polygon id="Path" fill="#FFFFFF" points="191.354 41.676 191.354 45.822 198.6 45.822 198.6 57.939 203.805 57.939 203.805 45.822 211.037 45.822 211.037 41.676"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="212.182 41.676 217.389 41.676 217.389 57.94 212.182 57.94"></polygon>
            <path d="M240.086,51.66 L235.129,51.66 C235.113,52.678 234.959,53.324 234.664,53.602 C234.441,53.817 234.016,53.956 233.383,54.019 C232.752,54.082 231.487,54.115 229.588,54.115 C228.047,54.115 226.975,54.079 226.365,54.008 C225.758,53.937 225.314,53.793 225.037,53.578 C224.744,53.356 224.545,52.993 224.441,52.488 C224.337,51.984 224.287,51.116 224.287,49.885 C224.287,48.654 224.344,47.756 224.459,47.192 C224.574,46.629 224.779,46.227 225.074,45.989 C225.32,45.791 225.74,45.657 226.336,45.59 C226.932,45.523 228.004,45.488 229.553,45.488 C231.117,45.488 232.285,45.526 233.057,45.601 C233.827,45.677 234.307,45.798 234.498,45.964 C234.633,46.083 234.723,46.235 234.766,46.417 C234.809,46.599 234.844,46.961 234.868,47.501 L239.825,47.501 C239.833,47.032 239.837,46.763 239.837,46.691 C239.837,45.356 239.687,44.345 239.384,43.658 C239.081,42.971 238.562,42.453 237.823,42.103 C237.259,41.841 236.501,41.663 235.548,41.567 C234.595,41.472 233.069,41.424 230.972,41.424 C226.779,41.424 223.99,41.577 222.609,41.883 C221.226,42.189 220.308,42.858 219.855,43.89 C219.65,44.351 219.511,44.998 219.439,45.832 C219.367,46.666 219.332,48.024 219.332,49.907 C219.332,51.837 219.367,53.172 219.439,53.91 C219.527,54.824 219.716,55.543 220.011,56.067 C220.304,56.59 220.749,57.008 221.345,57.318 C222.013,57.659 223.003,57.889 224.318,58.008 C225.633,58.127 227.839,58.187 230.937,58.187 C233.884,58.187 235.957,58.044 237.156,57.758 C238.355,57.472 239.162,56.932 239.576,56.138 C239.949,55.431 240.135,54.45 240.135,53.195 C240.133,52.967 240.117,52.455 240.086,51.66 Z" id="Path" fill="#FFFFFF"></path>
            <path d="M104.521,24.885 C104.521,22.447 104.263,20.578 103.75,19.276 C103.236,17.974 102.357,16.95 101.113,16.204 C100.018,15.574 98.547,15.146 96.697,14.923 C94.847,14.7 91.179,14.505 85.689,14.339 C79.171,14.14 75.515,13.933 74.718,13.717 C73.757,13.452 73.276,12.722 73.276,11.528 C73.276,10.284 73.757,9.525 74.718,9.252 C75.681,8.979 78.359,8.842 82.753,8.842 C86.401,8.842 88.79,8.879 89.918,8.954 C91.046,9.028 91.833,9.199 92.281,9.464 C92.645,9.68 92.89,9.962 93.014,10.31 C93.139,10.659 93.217,11.305 93.25,12.25 L103.275,12.25 C103.292,11.471 103.3,10.94 103.3,10.658 C103.3,7.573 102.719,5.38 101.558,4.078 C100.398,2.777 98.258,1.91 95.14,1.478 C93.117,1.196 89.609,1.055 84.618,1.055 C77.553,1.055 72.867,1.225 70.562,1.565 C68.257,1.905 66.548,2.689 65.438,3.916 C63.978,5.492 63.25,8.195 63.25,12.026 C63.25,15.443 63.888,17.88 65.164,19.339 C65.911,20.218 66.951,20.853 68.287,21.242 C69.621,21.632 71.533,21.868 74.02,21.951 C75.297,21.984 78.05,22.1 82.279,22.299 C86.409,22.498 89.112,22.597 90.389,22.597 C92.047,22.631 93.146,22.817 93.685,23.157 C94.224,23.497 94.494,24.173 94.494,25.184 C94.494,26.577 94.112,27.432 93.349,27.747 C92.835,27.963 92.138,28.092 91.259,28.133 C90.38,28.175 87.751,28.203 83.373,28.22 C79.194,28.187 76.71,28.141 75.923,28.083 C75.136,28.025 74.493,27.838 73.995,27.523 C73.298,27.042 72.958,25.964 72.975,24.289 L62.953,24.289 L62.953,25.881 C62.953,28.319 63.197,30.134 63.686,31.329 C64.175,32.524 65.058,33.485 66.335,34.215 C67.562,34.896 69.396,35.368 71.833,35.633 C74.271,35.899 78.043,36.031 83.151,36.031 C89.255,36.031 93.424,35.931 95.664,35.732 C97.754,35.533 99.375,35.152 100.528,34.587 C101.68,34.024 102.58,33.17 103.226,32.024 C104.089,30.466 104.521,28.086 104.521,24.885 Z" id="Path" fill="#FFFFFF"></path>
            <polygon id="Path" fill="#FFFFFF" points="121.238 35.532 132.109 35.532 132.109 10.233 147.209 10.233 147.209 1.576 106.113 1.576 106.113 10.233 121.238 10.233"></polygon>
            <path d="M153.553,35.532 L156.463,29.786 L178.926,29.786 L181.836,35.532 L193.578,35.532 L175.816,1.576 L159.746,1.576 L141.736,35.532 L153.553,35.532 Z M166.688,9.487 L168.752,9.487 L175.295,22.348 L160.145,22.348 L166.688,9.487 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
            <path d="M195.27,31.18 C195.784,32.192 196.522,33.02 197.485,33.667 C198.893,34.612 200.809,35.242 203.231,35.558 C205.653,35.872 209.782,36.031 215.62,36.031 C220.229,36.031 223.659,35.968 225.905,35.844 C228.153,35.72 230.147,35.484 231.889,35.135 C235.934,34.306 238.157,31.934 238.555,28.02 C238.805,25.599 238.928,21.991 238.928,17.199 C238.928,17.133 238.912,16.826 238.879,16.278 L215.221,16.278 L215.221,23.07 L228.828,23.07 C228.844,23.551 228.853,23.866 228.853,24.016 C228.853,24.994 228.749,25.728 228.542,26.217 C228.333,26.706 227.981,27.059 227.483,27.274 C226.87,27.523 226.057,27.676 225.045,27.734 C224.033,27.793 221.172,27.838 216.463,27.871 C211.652,27.805 208.797,27.726 207.893,27.635 C206.989,27.544 206.254,27.274 205.692,26.826 C205.128,26.362 204.747,25.562 204.547,24.426 C204.348,23.29 204.248,21.287 204.248,18.418 C204.248,15.864 204.348,14.061 204.547,13.007 C204.746,11.953 205.127,11.195 205.692,10.731 C206.255,10.283 207.303,9.976 208.838,9.811 C210.371,9.645 212.93,9.562 216.512,9.562 C219.813,9.562 222.334,9.612 224.075,9.711 C225.917,9.811 227.128,10.067 227.708,10.482 C228.022,10.714 228.229,11.001 228.329,11.34 C228.429,11.681 228.487,12.332 228.505,13.293 L238.853,13.293 C238.835,10.939 238.695,9.089 238.429,7.746 C237.882,5.043 236.306,3.285 233.704,2.472 C232.095,1.974 230.046,1.618 227.558,1.402 C225.072,1.187 221.689,1.079 217.41,1.079 C210.195,1.079 204.847,1.386 201.365,1.999 C198.179,2.563 196.099,4.155 195.121,6.775 C194.639,8.019 194.316,9.429 194.15,11.004 C193.984,12.58 193.902,14.918 193.902,18.019 C193.902,22.58 194.002,25.631 194.201,27.173 C194.398,28.833 194.756,30.168 195.27,31.18 Z" id="Path" fill="#FFFFFF"></path>
            <polygon id="Path" fill="#FFFFFF" transform="translate(2.004134, 28.690371) rotate(-45.000000) translate(-2.004134, -28.690371) " points="1.61263825 28.6898706 2.39563075 28.6898706 2.39563075 28.6908706 1.61263825 28.6908706"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="13.144 17.551 38.9 17.551 38.9 21.266 9.429 21.266 6 24.696 38.9 24.696 38.9 28.413 2.283 28.413 1.728 28.968 4.603 31.842 38.9 31.842 38.9 35.56 8.321 35.56 11.751 38.99 38.9 38.99 38.9 42.706 15.467 42.706 30.317 57.556 58.495 29.379 29.906 0.789"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="8.286 22.41 5.998 24.696 5.999 24.696"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="6 24.696 9.429 21.266 8.286 22.41 5.999 24.696"></polygon>
            <polygon id="Path" fill="#FFFFFF" points="8.32 35.56 11.751 38.99 8.321 35.56"></polygon>
        </g>
    </g>
    </svg>
  </span>
);
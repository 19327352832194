import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllAlerts, fetchAlertsNewCount, deleteAlerts } from '../../actions/alert'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { IconVisibility } from '../partials/Icons'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import { Tooltip } from 'react-tooltip'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import alertTypesToString from '../../helpers/alertTypesToString'
import alertIconFromNumber from '../../helpers/alertIconFromNumber'


interface AlertsProps {
  router: { location: {search: string}, navigate: (to: string) => any },
  auth: any,
  alert: any,
  fetchAllAlerts: (options: {limit: number, skip: number}) => Promise<any>,
  fetchAlertsNewCount: () => Promise<any>,
  deleteAlerts: (data: any) => Promise<any>,
}


interface AlertsState {
  currentPage: number,
  perPage: number,
  checkedArray: any,
  checkedAll: any,
  timezone: string,
}


export class Alerts extends React.Component<AlertsProps, AlertsState> {

  state = {
    currentPage: 0,
    perPage: 10,
    checkedArray: [],
    checkedAll: [],
    timezone: TIMEZONE,
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/alerts?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/alerts?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/alerts?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    const { perPage, currentPage } = options
    return this.props.fetchAllAlerts({ limit: perPage, skip: perPage * currentPage });
  }


  updateCheckedArray = (id: number) => {
    const checkedArray:any = this.state.checkedArray
    if(checkedArray.includes(id)) {
      const updatedArray = this.state.checkedArray.filter(function(item) {return item !== id})
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      this.setState({ checkedArray: updatedArray, checkedAll: updatedArrayAll })
    } else {
      this.setState({ checkedArray: checkedArray.concat(id)})
    }
  }


  updateCheckedArrayMultiple = () => {
    const updatedCheckedArray = this.props.alert.alerts.map((item: {id: number}) => { return item.id })
    const checkedAll: any = this.state.checkedAll
    if(checkedAll.includes(this.state.currentPage)) {
      const updatedArray = this.state.checkedArray.filter(
        (e) => this.state.checkedArray.includes(e),
        updatedCheckedArray
      );
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      this.setState({ checkedAll: updatedArrayAll, checkedArray:  updatedArray})
    } else {
      this.setState({ checkedAll: checkedAll.concat(this.state.currentPage), checkedArray: this.state.checkedArray.concat(updatedCheckedArray) })
    }
  }


  deleteCheckedItems = () => {
    const data = this.state.checkedArray
    this.props.deleteAlerts(data)
    .then(() => {
      this.props.fetchAlertsNewCount()
      this.reloadPage({ currentPage: 0, perPage: this.state.perPage })
      .then(() => {
        this.setState({ checkedArray: [], checkedAll: [], currentPage: 0 })
        this.props.router.navigate(`/alerts?page=0&perPage=${this.state.perPage}`)
      });
    })
  }


  render() {

    const { alerts, alertsCount, status } = this.props.alert;
    const { currentPage, perPage, checkedArray, checkedAll, timezone } = this.state;

    let testCheckedArray: any = []
    testCheckedArray = checkedArray

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(189)}
          time={false}
          button={true} 
          buttonLink='/accounts/add' 
          buttonTitle={t(191)}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', 'ID', t(2050), t(1030), [ checkedAll, currentPage ]]} 
                handleOnChange={this.updateCheckedArrayMultiple}
              />
            <tbody className="table__body table__body--striped">
              { alerts && alerts.map((item: {id: number, new: boolean, custom_id: string, ts: number, vehicle_id: string, type: string}, index: number) => {
                return (
                  <tr key={item.id} className={classnames('', { 'heavy': item && item.new })}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-alert-view${index}`} />
                      <Link to={`/alerts/alert/${item.id}`}>
                        <span id={`tooltip-alert-view${index}`} data-tooltip-content="Details">
                          <IconVisibility task button color="#ffffff" />
                        </span>
                      </Link>
                    </td>
                    <td>
                      {item.custom_id}
                    </td>
                    <td>
                      {moment(item.ts*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}
                    </td>
                    <td>
                      <Link to={`/vehicles/alerts/${item.vehicle_id}/${alertTypesToString(parseInt(item.type))}`}>
                        {alertIconFromNumber(parseInt(item.type))}
                      </Link>
                    </td>
                    <td>
                      <input 
                        onChange={() => this.updateCheckedArray(item.id)}
                        checked={testCheckedArray.includes(item.id)}
                        type="checkbox" 
                        name="delete"  
                      /> 
                    </td>
                  </tr>
                  )
                })
              }
            </tbody>
              <TableFooter 
                colSpan={4}
                currentPage={currentPage}
                perPage={perPage}
                itemsCount={ alertsCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
                button={ true }
                buttonTitle={t(29)}
                buttonClickable={true}
                buttonDisabled={ checkedArray.length > 0 ? false : true }
                buttonHandleOnClick={() => this.deleteCheckedItems()}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ alert, auth }: RootState) => ({ alert, auth }),
  { fetchAllAlerts, fetchAlertsNewCount, deleteAlerts }
)(Alerts)))
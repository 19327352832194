import React from 'react';
import { RootState } from '../../reducers'
import { connect } from 'react-redux';
import { logout, logoutAll } from '../../actions/auth';
import { fetchAllLockedVehicles, fetchVehiclesTotalCount } from '../../actions/vehicle';
import { fetchAlertsNewCount } from '../../actions/alert';
import Authorization from './Authorization';
import { NavLink, Link, redirect } from 'react-router-dom';
import { IconCommunication, IconImei, IconUsers, IconGroups, IconVehicles, IconStations, IconLocation, IconDashboard, IconPower, IconDropdown, IconReports, IconSettings, IconModules, IconAlerts, IconBilling, IconAccounts, IconRfid, IconHelp, IconWorkers, IconCheckpoints, IconWarning } from './Icons';
import { LogoStelematicNew, LogoStelematicFullNew} from './Logos';
import MenuButton from './MenuButton';
import t from '../translation/translate';


interface ToolBarProps {
  auth: { email: string, company_name: string, billing_enabled: boolean },
  vehicle: { vehiclesTotalCount: number },
  alert: { alertsNewCount: number },
  fetchAllLockedVehicles: (options: {limit: number, skip: number}) => void,
  fetchVehiclesTotalCount: () => void,
  fetchAlertsNewCount: () => void,
  logout: () => void,
  logoutAll: () => void,
}


interface ToolBarState {
  dropdownActive : boolean,
  sidenavActive: boolean,
  isOpen: boolean
}


export class ToolBar extends React.Component<ToolBarProps, ToolBarState> {

  state = {
    dropdownActive : false,
    sidenavActive: false,
    isOpen: false
  }

  componentDidMount() {
    this.props.fetchAllLockedVehicles({ limit: 1000, skip: 0 });
    this.props.fetchVehiclesTotalCount();
    this.props.fetchAlertsNewCount();
  }


  toggleDropdown = () => {
    this.setState({
      dropdownActive: !this.state.dropdownActive
    });
  }


  toggleSidenav = () => {
    this.setState({
      sidenavActive: !this.state.sidenavActive,
      isOpen : !this.state.isOpen
    });
  }


  logout = () => {
    this.props.logout();
    redirect('/');
  }


  logoutAll = () => {
    this.props.logoutAll();
    redirect('/');
  }


  render() {

    const { dropdownActive, sidenavActive, isOpen } = this.state
    const { email  } = this.props.auth;
    const { vehiclesTotalCount } = this.props.vehicle;
    const { alertsNewCount } = this.props.alert;

    return (
      <>
        <div className={ sidenavActive ? 'sidenav sidenav--visible animation--fade-in' : 'sidenav'}>
          <nav className="sidenav__main-nav">

            <NavLink to="/" className="sidenav__nav-item" >
              <IconDashboard color="#ffffff" /> 
              <span className="sidenav__nav-title">
                {t(1)}
              </span>
            </NavLink>


            <Authorization requiresAuth="vehicles">
              <NavLink to="/vehicles" className="sidenav__nav-item" >
                <IconVehicles color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(3)}
                </span>
                  {vehiclesTotalCount > 0 ? (<span className="sidenav__nav-title--vehicle-counter">{vehiclesTotalCount > 99 ? '+99' : vehiclesTotalCount}</span>) : null }
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="reports">
              <NavLink to="/reports" className="sidenav__nav-item" >
                <IconReports color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(5)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="alerts">
              <NavLink to="/alerts" className="sidenav__nav-item" >
                <IconAlerts color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(6)}
                </span>
                  { alertsNewCount > 0 ? (<span className="sidenav__nav-title--notification-counter">{alertsNewCount > 99 ? '+99' : alertsNewCount}</span>) : null }
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="stations">
              <NavLink to="/stations" className="sidenav__nav-item" >
              <IconStations color="#ffffff" /> 
              <span className="sidenav__nav-title">
                {t(2)}
              </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="checkpoints">
              <NavLink to="/checkpoints" className="sidenav__nav-item" >
                <IconCheckpoints color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(37)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="rfid">
              <NavLink to="/rfid" className="sidenav__nav-item" >
                <IconRfid color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  RFID
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="users">
              <NavLink to="/users" className="sidenav__nav-item" >
                <IconUsers color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(4)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="groups">
              <NavLink to="/groups" className="sidenav__nav-item" >
                <IconGroups color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(215)}
                </span>
              </NavLink>
            </Authorization>

            {
              this.props.auth.billing_enabled === true && (
                <Authorization requiresAuth="billing">
                  <NavLink to="/subscription" className="sidenav__nav-item" >
                    <IconBilling color="#ffffff" /> 
                    <span className="sidenav__nav-title">
                      {t(139)}
                    </span>
                  </NavLink>
                </Authorization>
              )
            }

            <Authorization requiresAuth="accounts">
              <NavLink to="/accounts" className="sidenav__nav-item" >
                <IconAccounts color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(189)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="distributor_account_groups">
              <NavLink to="/distributor-accounts" className="sidenav__nav-item" >
                <IconAccounts color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(34)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="accounts">
              <NavLink to="/reports/account" className="sidenav__nav-item" >
                <IconReports color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(35)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="modules">
              <NavLink to="/modules" className="sidenav__nav-item" >
                <IconModules color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(7)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="imei">
              <NavLink to="/imei" className="sidenav__nav-item" >
                <IconImei color="#ffffff" />  
                <span className="sidenav__nav-title">
                  {t(9)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="data_map">
              <NavLink to="/data-map" className="sidenav__nav-item" >
                <IconLocation color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(8)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="communication">
              <NavLink to="/communication" className="sidenav__nav-item" >
                <IconCommunication color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(10)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="workers">
              <NavLink to="/workers" className="sidenav__nav-item" >
                <IconWorkers color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(227)}
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="workers">
              <NavLink to="/workers-state" className="sidenav__nav-item" >
                <IconWorkers color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  Workers State
                </span>
              </NavLink>
            </Authorization>


            <Authorization requiresAuth="workers">
              <NavLink to="/errors" className="sidenav__nav-item" >
                <IconWarning color="#ffffff" /> 
                <span className="sidenav__nav-title">
                  {t(243)}
                </span>
              </NavLink>
            </Authorization>


            <NavLink to="/settings" className="sidenav__nav-item" >
              <IconSettings color="#ffffff" /> 
              <span className="sidenav__nav-title">
                {t(11)}
              </span>
            </NavLink>


            <NavLink to="/" onClick={() => this.logout()} className="sidenav__nav-item" >
              <IconPower color="#ffffff" /> 
              <span className="sidenav__nav-title">
                {t(13)}
              </span>
            </NavLink>


            <NavLink to="/" onClick={() => this.logoutAll()} className="sidenav__nav-item" >
              <IconPower color="#ffffff" />
              <span className="sidenav__nav-title">
                {t(14)}
              </span>
            </NavLink>

          </nav>
        </div>

        <div className="toolbar">
          <Link to="/" className="toolbar__logo-section">
            <div className="toolbar__logo">
              <LogoStelematicNew color="#ffffff" size={40} />
            </div>
            <div className="toolbar__logo-title">
              <LogoStelematicFullNew color="#ffffff" size={160} />
            </div>
          </Link>


          <div className="toolbar__spacer"></div>
          
          <span className="toolbar__mobile-nav" onClick={this.toggleSidenav}>
            <span className="mobile-nav__hint">MENU</span>
            <MenuButton
              isOpen={isOpen}
              strokeWidth={4}
              color="#ffffff"
              transition={{ ease: "easeOut", duration: 0.2 }}
              width={40}
              height={24}
              className="toolbar__mobile-nav-button"
            />
          </span>

          <a href="https://help.stagtelematic.com" className="toolbar__instructions">
            <IconHelp /> {t(36)}
          </a>

          <div className="toolbar__account-nav">
            <div className="dropdown dropdown--toolbar" onClick={this.toggleDropdown}>
              <div className="toolbar__account-nav-link dropdown__trigger">
                <span className="toolbar__account-nav--account-icon toolbar__logo-client"></span>
                <span className="toolbar__account-nav--username">{ this.props.auth.company_name } </span>
                <span className="toolbar__account-nav--email">{email}</span>
                <IconDropdown dropdown />
              </div>
              <ul className={ dropdownActive ? 'dropdown--visible' : undefined}>
                <li className="dropdown__item">
                  <Link to="/settings">
                    <IconSettings />
                    <span className="dropdown__title">
                      {t(11)}
                    </span>
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link to="/" onClick={() => this.logout()}>
                    <IconPower />
                    <span className="dropdown__title">
                      {t(13)}
                    </span>
                  </Link>
                </li>
                <li className="dropdown__item">
                  <Link to="/" onClick={() => this.logoutAll()}>
                    <IconPower />
                    <span className="dropdown__title">
                      {t(14)}
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default connect(
  ({ auth, vehicle, alert }: RootState) => ({ auth, vehicle, alert }),
  { logout, logoutAll, fetchAllLockedVehicles, fetchVehiclesTotalCount, fetchAlertsNewCount }
)(ToolBar)
import { IMEI } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;


const fetchImei = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: IMEI.FETCH });
  return fetch(`${API_ADDRESS}/imei/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: IMEI.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: IMEI.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllImei = (options: { limit: number, skip: number }) => fetchImei({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: IMEI.FETCH_ALL_SUCCESS
});


export const fetchImeiSearch = (options: { search: string, limit: number, skip: number }) => fetchImei({
  endpoint: 'search/?search=' + options.search + '&limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: IMEI.FETCH_SEARCH_SUCCESS
});


export const uploadImei = (data: string) => fetchImei({
  endpoint: 'upload',
  options: {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: IMEI.FETCH_UPLOAD_SUCCESS
});


export const fetchImeiId = (id: string) => fetchImei({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: IMEI.FETCH_ID_SUCCESS
});


export const deleteImeiId = (id: string) => fetchImei({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: IMEI.FETCH_DELETE_SUCCESS
});
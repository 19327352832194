import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { IconDialogflowSubmit, IconDialogflowChat, IconClose } from './Icons'
import { submitDialogflowQuestion } from '../../actions/dialogflow'
import fetchStates from '../../types/fetchStates'
const moment = require('moment-timezone');




interface DialogflowBotProps {
  dialogflow: any,
  submitDialogflowQuestion: (text: string) => Promise<any>,
}


interface DialogflowBotState {
  dialogflowActive: boolean,
  dialogflowInput: string,
  messages: Array<any>
}


export class DialogflowBotPage extends React.Component<DialogflowBotProps, DialogflowBotState> {

  messagesEnd: any = '<div>';

  state = {
    dialogflowActive: false,
    dialogflowInput: '',
    messages: [{ type: 'bot', text: 'Hello, how can I help you today?'}]
  }

  componentDidUpdate() {
    if(this.state.dialogflowActive === true) {
      this.messagesEnd.scrollIntoView({ behaviour: 'smooth' });
    }
  }


  dialogflowSubmit = async (e: any) => {
    e.preventDefault();
    const { messages, dialogflowInput } = this.state
    // insert question in the message question
    messages.push({ type: 'user', text: dialogflowInput })
    this.setState({ messages });
    await this.props.submitDialogflowQuestion(this.state.dialogflowInput);
    if(this.props.dialogflow.status === fetchStates.success) {
      messages.push({ type: 'bot', text: this.props.dialogflow.bot_response });
      this.setState({ messages });
    }
    this.setState({ dialogflowInput: '' });
  }


  openChatDialogBox = () => {
    this.setState({ dialogflowActive: !this.state.dialogflowActive });
  }
  

  render() {

    const { messages, dialogflowInput, dialogflowActive } = this.state;


    return (
      <>

        {
          dialogflowActive && (
            <div className="dialogflow">
            <div className="dialogflow__titlebar">
            <span className="dialogflow__bot-image"></span><span className="dialogflow__bot-name">Hana - STAGTELEMATIC</span>
            </div>
            <div className="dialogflow__content">
              <div className="dialogflow__time">
                TODAY {moment().format('hh:mm A')}
              </div>
              <div className="dialogflow__chat">
                {
                  messages && messages.map((message: any, index: number) => {
                    if(message.type === 'bot') {
                      return <div key={index} className="dialogflow__bubble-wrapper dialogflow__bubble-wrapper--bot">
                      <div className="dialogflow__avatar">
                        <span className="dialogflow__avatar-image"></span>
                        <div className="dialogflow__avatar-name">Hana</div>
                      </div>
                      <div className="dialogflow__bubble">
                        {message.text}
                      </div>
                    </div>
                    }
                    if(message.type === 'user') {
                      return <div key={index} className="dialogflow__bubble-wrapper dialogflow__bubble-wrapper--user">
                      <div className="dialogflow__bubble">
                        {message.text}
                      </div>
                    </div>
                    }
                    return null
                  })
                }
                <div ref={(el) => { this.messagesEnd = el; }} />
              </div>
              <div className="dialogflow__input">
                <form onSubmit={this.dialogflowSubmit}>
                  <input type="text" value={ dialogflowInput } onChange={e => this.setState({ dialogflowInput: e.target.value })} ref={input => input && input.focus()} />
                  <button className="dialogflow__submit">
                    <IconDialogflowSubmit size={24} />
                  </button>
                </form>
              </div>
              <div className="dialogflow__policy">
                By chatting, you consent to this chat being stored
              </div>
            </div>
          </div>
          )
        }



        {
          dialogflowActive ? (
            <div className="dialogflow-icon" onClick={this.openChatDialogBox}>
              <IconClose color="#ffffff" size={40} />
            </div> 
          ) : (
            <div className="dialogflow-icon" onClick={this.openChatDialogBox}>
              <IconDialogflowChat color="#ffffff" size={40} />
            </div> 
          )
        }

      </>
    )


  }
  
}


export default withAuthorization(withRouter(connect(
  ({ dialogflow }: RootState) => ({ dialogflow }),
  { submitDialogflowQuestion }
)(DialogflowBotPage)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { addNewCheckpoint } from '../../actions/checkpoint'
import { checkAuth } from '../../actions/auth'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import markerCheckpoint from'../../img/marker-position.png'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_RANGE } from '../../config'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import fetchStates from '../../types/fetchStates'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Preloader from '../partials/Preloader'


interface CheckpointsAddProps {
  router: { navigate: (to: string) => any },
  auth: any,
  checkpoint: any,
  checkAuth: () => Promise<any>,
  addNewCheckpoint: (options: { checkpoint: any }) => Promise<any>,
}


interface CheckpointsAddState {
  address: string,
  addressSelected: boolean,
  custom_id: string,
  label: string,
  formSubmitted: boolean,
  range: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
}


export class CheckpointsAdd extends React.Component<CheckpointsAddProps, CheckpointsAddState> {

  state = {
    address: '',
    addressSelected: false,
    custom_id: '',
    label: '',
    formSubmitted: false,
    range: DEFAULT_RANGE,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
  }


  componentDidMount () {
    this.props.checkAuth()
    .then(() => {
      const { lat, lng, zoom } = this.props.auth;
      this.setState({
        mapLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
        mapLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
        mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM
      })
      this.drawMapCircle()
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.custom_id === '') {
      error(t(2165))
      this.props.checkpoint.fields = ['custom_id']
      this.setState({ formSubmitted: false })
    }
    const checkpoint = { 
      custom_id: this.state.custom_id, 
      address: this.state.address,
      label: this.state.label,
      zoom: this.state.mapZoom, 
      range: this.state.range, 
      lat: this.state.markerLat, 
      lng: this.state.markerLng 
    } 
    this.props.addNewCheckpoint({ checkpoint })
    .then(() => {
      this.setState({ formSubmitted: false });
      if(this.props.checkpoint.status === fetchStates.success) {
        this.props.router.navigate('/checkpoints');
      }
    })
  }


  handleRangeChange = (event:any) => {
    this.setState({ range: event.target.value });
  }


  handleChangeAddress = (address: string) => {
    this.setState({ address, addressSelected: false });
  }


  handleSelect = (address: string) => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => 
          getLatLng(results[0])
          .then((result: any) => {
            this.setState({
              markerLat: result.lat,
              markerLng: result.lng,
              addressSelected: true
            })
            this.drawMarker();
            this.updateMapCenter();
        })
      )
      .catch(error => console.error('Error', error));
  }


  handleMapClick = (event:any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
      addressSelected: true
    });
    this.updateMapCenter();
  }


  drawMapCircle = () => {
      return <Circle
      // required
      center={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      // required
      options={{
        strokeColor: '#2CBFFC',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#2CBFFC',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: this.state.range,
        zIndex: 1
      }}
    />
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerCheckpoint,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }


  render() {

    const { fields, status } = this.props.checkpoint;
    const { address, addressSelected, custom_id, label, formSubmitted, mapZoom, range, mapLat, mapLng, markerLat, markerLng } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(1109)}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

          <div className="form-group">
            <label htmlFor="custom_id">
              {t(1098)} ({t(116)})
            </label>
            <input 
              type="text" 
              name="custom_id"
              id="custom_id" 
              value={custom_id}
              onChange={e => this.setState({ custom_id: e.target.value })}
              className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="label">
              {t(1062)} ({t(116)})
            </label>
            <input 
              type="text" 
              name="label"
              id="label" 
              value={label}
              onChange={e => this.setState({ label: e.target.value })}
              className={classnames('', { 'input-error': fields && fields.includes('label') })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="map">
              {t(1002)} ({t(116)})
            </label>
            <PlacesAutocomplete
              value={address}
              onChange={ this.handleChangeAddress }
              onSelect={ this.handleSelect }
            >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search places...',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>{t(2327)}</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          </PlacesAutocomplete>
          </div>

          <div className="map-constraints-slider"> 
            <label htmlFor="range">{t(1105)}: {range + ' m'}</label>
            <input type="range" id="range" value={range} name="range" min="1" max="100" onChange={this.handleRangeChange}/>
          </div>

          <div className="form-group">
            <input 
              type="submit" 
              value={t(209)}
              className="btn btn--primary card__footer--btn-left"
              disabled={ addressSelected === false || formSubmitted ? true : false }
            />
          </div>

          <div className="form-group">
            <label htmlFor="map">
              {t(2037)}: {addressSelected === true ? address : 'n/a'}
            </label>

              <GoogleMap
                center={{ 
                  lat: mapLat, 
                  lng: mapLng
                }}
                zoom={ mapZoom }
                onClick={ (e) => this.handleMapClick(e) }
              >
                { this.drawMapCircle() }
                { this.drawMarker() }

                <div className="map-constraints-container" />
              </GoogleMap>

              <span className='form-group__explanation'>
                {t(2178)}: {markerLng}, {t(2177)}: {markerLat}<br/>
                {t(105)} <Link to='/settings'>{t(106)}</Link>
              </span>
          </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, auth }: RootState) => ({ checkpoint, auth }),
  { addNewCheckpoint, checkAuth }
)(CheckpointsAdd)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllCheckpoints, fetchCheckpointVisitById } from '../../actions/checkpoint'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import { GoogleMap, Marker, InfoBox, Circle } from '@react-google-maps/api'
import fetchStates from '../../types/fetchStates'
import moment from 'moment-timezone'
import markerCheckpoint from'../../img/marker-position.png'
import markerCheckpointVehicleStop from'../../img/marker-vehicle-position.png'
import Preloader from '../partials/Preloader'


interface CheckPointProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  checkpoint: any,
  fetchAllCheckpoints: (options: { limit: number, skip: number, param: string, paramValue: boolean }) => Promise<any>,
  fetchCheckpointVisitById: (id: number) => Promise<any>,
}


interface CheckPointState {
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  checkpoints: Array<any>,
  checkpointVisit: { loc_ln: number, loc_lt: number, stop_ts: number, start_ts: number, diff: number },
  dataReceived: boolean,
}


export class CheckPoint extends React.Component<CheckPointProps, CheckPointState> {

  state = {
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    checkpoints: [],
    checkpointVisit: { loc_ln: 0, loc_lt: 0, stop_ts: 0, start_ts: 0, diff: 0 },
    dataReceived: false,
  }


  componentDidMount() {
    this.props.fetchAllCheckpoints({ limit: 1000, skip: 0, param: 'custom_id', paramValue: true })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpoints } = this.props.checkpoint
        this.setState({ checkpoints })
      }
    })
    this.props.fetchCheckpointVisitById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpointVisit } = this.props.checkpoint
        this.setState({ 
          checkpointVisit,
          mapLat: checkpointVisit.loc_lt,
          mapLng: checkpointVisit.loc_ln,
        })
      }
    })
    this.setState({ dataReceived: true })
  }



  drawCheckpointCircle = (options: { markerLat: number, markerLng: number, range: number }) => {
    const { markerLat, markerLng, range } = options
    return <Circle
    // required
    center={{
      lat: markerLat,
      lng: markerLng
    }}
    // required
    options={{
      strokeColor: '#2CBFFC',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2CBFFC',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: range,
      zIndex: 1
      }}
    />
  }


  render() {

    const { mapZoom, mapLat, mapLng, checkpoints, checkpointVisit } = this.state;
    const { status } = this.props.checkpoint;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          title={t(1205)}
          time={false}
          tabnav="checkpoints"
          animate
        >
          <GoogleMap
            center={{ 
              lat: mapLat, 
              lng: mapLng 
            }}
            zoom={ mapZoom }
            id="map"
          >

            { 
              checkpoints !== undefined && checkpoints.map((checkpoint: any) => {
                return <Marker
                  key={checkpoint.checkpoint_id}
                  position={{
                    lat: checkpoint.lat,
                    lng: checkpoint.lng
                  }}
                  icon={
                    // @ts-expect-error
                    new window.google.maps.MarkerImage(
                      markerCheckpoint,
                      null, 
                      null, 
                      null, 
                      new window.google.maps.Size(48, 48)
                    )
                  }
                >
                  { this.drawCheckpointCircle({ markerLat: checkpoint.lat, markerLng: checkpoint.lng, range: checkpoint.range }) }
                    <InfoBox
                      // @ts-expect-error
                      position={{
                        lat: checkpoint.lat,
                        lng: checkpoint.lng
                      }}
                      options={{ closeBoxURL: ``, enableEventPropagation: true,
                      pixelOffset: new window.google.maps.Size(-16, -95) }}
                    >
                      <div className='marker-label-wrapper'>
                        <div className='marker-label'>
                          <div className='marker-label-title'>{checkpoint.custom_id}</div>
                          <div className='marker-label-text'></div>
                        </div>
                        <span className='marker-label-pointer'></span>
                      </div>
                    </InfoBox>
                </Marker>
              })
            }
            { 
              checkpointVisit && (
                <Marker
                  position={{
                    lat: checkpointVisit.loc_lt,
                    lng: checkpointVisit.loc_ln
                  }}
                  icon={
                    // @ts-expect-error
                    new window.google.maps.MarkerImage(
                      markerCheckpointVehicleStop,
                      null, 
                      null, 
                      null, 
                      new window.google.maps.Size(48, 48)
                    )
                  }
                />
              )
            }

          <div className="checkpoint-info-panel">
            <div>{t(1119)}: <strong>{ checkpointVisit.stop_ts }</strong></div>
            <div>{t(1120)}: <strong>{ checkpointVisit.start_ts }</strong></div>
            <div>{t(1121)}: <strong>{ moment("1900-01-01 00:00:00").add(checkpointVisit.diff, 'seconds').format("HH:mm:ss") }</strong></div>
          </div>
          <div className="map-constraints-container" />
          </GoogleMap>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, auth, vehicle }: RootState) => ({ checkpoint, auth, vehicle }),
  { fetchAllCheckpoints, fetchCheckpointVisitById }
)(CheckPoint)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { uploadImei } from '../../actions/imei';
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface ImeiUploadProps {
  router: { navigate: (to: string) => any },
  imei: any,
  uploadImei: any
}


interface ImeiUploadState {
  formSubmitted: boolean,
  imei: string,
}


export class ImeiUpload extends React.Component<ImeiUploadProps, ImeiUploadState> {

  state = {
    formSubmitted: false,
    imei: '',
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.imei.length !== 0) {
      let imeiArray = []
      imeiArray = this.state.imei.split(',')
      const sanitizedImeiArray = imeiArray.map((imei:string) => (imei.trim()))

      const validImeiArray = sanitizedImeiArray.filter((imei: string) => {
        if(/^\d+$/.test(imei) === false || imei.length !== 15) {
          this.props.imei.fields = ['imei']
          error(2325)
        } 
        return imei;
      });

      this.props.uploadImei(validImeiArray)
      .then(() => {
        if(this.props.imei.status === fetchStates.success) {
          this.setState({ imei: '' })
        }
      })
    } else {
      this.props.imei.fields = ['imei']
      error(2326)
    }
    this.setState({ formSubmitted: false });
  }


  render() {

    const { formSubmitted, imei } = this.state;
    const { status, fields } = this.props.imei;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          title={t(109)}
          padding={['large']} 
          time={false}
          animate
        >

          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label>
                {t(2008)}
              </label>
              <input 
                type="text" 
                name="imei"
                id="imei" 
                value={imei}
                onChange={e => this.setState({ imei: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('imei') })}
              />
              <span className='form-group__explanation'>
                {t(2009)}
              </span>
            </div>

            <div className="form-group">
              <input 
                type="submit" 
                value={t(109)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true: false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ imei }: RootState) => ({ imei }),
  { uploadImei }
)(ImeiUpload)))
import { IconAlertSpeed, IconAlertsGeoFence, IconAlertsMaintenence, IconAlertsGasolineUse, IconAlertsEngineTemperature, IconAlertsRpm, IconAlertsMalfunction, IconAlertsLocked, IconAlertsPercentage } from '../components/partials/Icons'


const alertIconFromNumber = (alert_type: number, size:number = 32, color:string = '#000000') => {
  switch(alert_type) {
    case 1:
      return <IconAlertSpeed size={size} color={color} />
    case 2:
      return <IconAlertsGeoFence size={size} color={color}/>
    case 3:
      return <IconAlertsEngineTemperature size={size} color={color}/>
    case 4:
      return <IconAlertsRpm size={size} color={color}/>
    case 5:
      return <IconAlertsGasolineUse size={size} color={color}/>
    case 6:
      return <IconAlertsMalfunction size={size} color={color}/>
    case 7:
      return <IconAlertsMaintenence size={size} color={color}/>
    case 8:
      return <IconAlertsLocked size={size} color={color}/>
    case 9:
      return <IconAlertsPercentage size={size} color={color}/>
    default:
      return null
  }
}


export default alertIconFromNumber
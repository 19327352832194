import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import Authorization from '../Authorization'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabStations: React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/stations' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/stations' })}>
        {t(240)}
      </NavLink>
      <NavLink to={{ pathname: '/refuels' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/refuels' })}>
        {t(249)}
      </NavLink>
      <Authorization requiresAuth="stations">
        <NavLink to={{ pathname: '/stations/authorized' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/stations/authorized' })}>
          {t(241)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>
      <Authorization requiresAuth="competitors">
        <NavLink to={{ pathname: '/stations/unauthorized' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/stations/unauthorized' })}>
          {t(242)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Authorization>
    </nav>
  )
}


export default TabStations
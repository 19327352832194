import React from 'react'
import { RootState } from '../../reducers'
import { fetchDistributorAccountGroupById, deleteDistributorAccountGroupById } from '../../actions/account';
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'


interface DistributorAccountsDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  account: any,
  fetchDistributorAccountGroupById: (id: string) => Promise<any>,
  deleteDistributorAccountGroupById: (id: string) => Promise<any>,
}


interface DistributorAccountsDeleteState {
  distributor_account_group: { distributor_group_title: string },
  distributor_group_title: string,
  formSubmitted: boolean
}


export class DistributorAccountsDelete extends React.Component<DistributorAccountsDeleteProps, DistributorAccountsDeleteState> {

  state = {
    distributor_account_group: { distributor_group_title: '' },
    distributor_group_title: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchDistributorAccountGroupById(this.props.router.params.id)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        this.setState({ distributor_account_group: this.props.account.distributor_account_group })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.distributor_group_title === '') {
      this.props.account.fields = ['group_title']
      this.setState({ formSubmitted: false })
      return error(2430)
    }
    if(this.state.distributor_group_title !== this.props.account.distributor_account_group.distributor_group_title) {
      this.props.account.fields = ['group_title']
      this.setState({ formSubmitted: false })
      return error(2431)
    }
    if(this.state.distributor_group_title === this.props.account.distributor_account_group.distributor_group_title) {
      this.props.deleteDistributorAccountGroupById(this.props.account.distributor_account_group.distributor_group_id)
      .then(() => {
        if(this.props.account.status === fetchStates.success) {
          this.props.router.navigate('/distributor-accounts');
        }
        this.setState({ formSubmitted: false });
      })
    }
  }


  render() {

    const { status, fields } = this.props.account;
    const { distributor_account_group, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(1145)} ${distributor_account_group.distributor_group_title}`}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(1144)} <strong>{distributor_account_group.distributor_group_title}</strong>. {t(2026)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="group_title">
                {t(1143)}
              </label>
              <input 
                type="text" 
                name="group_title"
                id="group_title" 
                value={this.state.distributor_group_title}
                onChange={e => this.setState({ distributor_group_title: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('group_title') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(1227)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account }: RootState) => ({ account }),
  { fetchDistributorAccountGroupById, deleteDistributorAccountGroupById }
)(DistributorAccountsDelete)))
import { RFID } from '../types'
import fetchStates from '../types/fetchStates'


interface RfidState {
  rfid : [],
  rfidCount: number,
}


const DEFAULT_RFID:RfidState = {
  rfid : [],
  rfidCount: 0
}


const rfid = (state = DEFAULT_RFID, action:any) => {
  switch(action.type) {
    case RFID.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case RFID.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case RFID.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        rfid: action.rfid,
        rfidCount: parseInt(action.rfidCount, 10),
      };
    case RFID.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        rfid: action.rfid,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case RFID.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case RFID.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case RFID.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case RFID.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default rfid
import { jsPDF } from "jspdf"
import logoImage from'../img/logo-client.png'


function createAccountReportPDF(options: { data: any, date: string, account: any, filter_type: string }) {

  const { data, date, account, filter_type } = options

  // document settings
  const unit = "mm";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);


  // building up the report
  const tableTitle = `Reports for ${date} and account ${account.company_name} for ${filter_type} vehicles`;
  const numTableCols = 3

  // table defaults
  const fontSize = 8;
  const rowHeight = 10; // max 10 rowHeight with 15 perPage
  const rowWidth = 182;
  const perPage = 24; // max 15 perPage with 10 rowHeight
  const pagesNum = Math.ceil(data.length / perPage);
  const headerOffset = 35;
  const linePosition = headerOffset + rowHeight / 2 + fontSize / rowHeight;


  // add logo
  var img = new Image();
  img.src = logoImage;


  // loop throug pages
  for (let i = 0; i < pagesNum; i++) {

    if (i > 0) {
      doc.addPage();
    }

    // title
    doc.setTextColor(0, 0, 0);
    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.setFontSize(18);
    //doc.setFontStyle('normal');
    doc.addImage(img, 'PNG', 15, 14, 8, 8);
    doc.text(tableTitle, 27, 20);

    
    // table header
    doc.setFontSize(fontSize);
    // doc.setFontStyle('bold');
    doc.line(15, 25, 197, 25);
    

    const tableHeaderArray = ['Date', 'Vehicle', 'Module Serial'];

    
    doc.setFillColor(255, 255, 255);
    doc.rect(15, 25, rowWidth, rowHeight, 'F');
    for (let x = 0; x < tableHeaderArray.length; x++) {
      doc.text(tableHeaderArray[x], 17 + rowWidth / numTableCols * x, 31.5);
    }
    doc.line(15, headerOffset, 197, headerOffset);
    


    // table content
    const pageOffset = i * perPage;
    const slicedData = data.slice(0 + pageOffset, perPage + pageOffset);
    const pageNum = i + 1;


    slicedData.forEach(function (item: any, index: number) {

      const rowTopPosition = headerOffset + rowHeight * index;
      // making the row stripy
      if (index % 2 === 0) {
        doc.setFillColor(231, 251, 255);
      } else {
        doc.setFillColor(255, 255, 255);
      }
      doc.rect(15, rowTopPosition, rowWidth, rowHeight, 'F');

      doc.text(item.dt_stamp_to, 17, linePosition + rowHeight * index);

      doc.text(item.custom_id, 17 + rowWidth / numTableCols * 1, linePosition + rowHeight * index);

      doc.text(item.module_imei, 17 + rowWidth / numTableCols * 2, linePosition + rowHeight * index);

      return;
      
    });


    // table footer
    doc.setTextColor(0, 0, 0);
    doc.text('PAGE ' + pageNum + '/' + pagesNum, rowWidth / 2, perPage * rowHeight + headerOffset + rowHeight);


  }


  // save document
  doc.save(`stelematics-reports-${filter_type}-${date}-${account.company_name}.pdf`)

}


export default createAccountReportPDF
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertEngineTemperature, fetchAlertsSettingsById, fetchAlertsPresets } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormEngineTemperatureProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertEngineTemperature: (options: {id: number, value: string, mt: string, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormEngineTemperatureState {
  formSubmitted: boolean,
  engine_temperature: string,
  alert_min_time: string,
  alert_max_frequency: string,
}


export class AlertFormEngineTemperature extends React.Component<AlertFormEngineTemperatureProps, AlertFormEngineTemperatureState> {


  state = {
    formSubmitted: false,
    engine_temperature: '200',
    alert_min_time: '5',
    alert_max_frequency: '300',
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {
      const { alerts_engt_mp, alerts_engt_mt, alerts_engt_v } = this.props.alert.alertsSettings
      this.setState({
        engine_temperature: alerts_engt_v !== null ? alerts_engt_v : '200',
        alert_min_time: alerts_engt_mt !== null ? alerts_engt_mt : '5',
        alert_max_frequency: alerts_engt_mp !== null ? alerts_engt_mp : '300'
      })
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertEngineTemperature({ id: this.props.modalId, value: this.state.engine_temperature, mt: this.state.alert_min_time, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }


  render() {

    const { formSubmitted, engine_temperature, alert_min_time, alert_max_frequency } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2080)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="engine_temperature">{t(2097)} [C]</label>
              <input 
                type="text" 
                name="engine_temperature"
                id="engine_temperature" 
                value={engine_temperature}
                onChange={e => this.setState({ engine_temperature: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2098)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_min_time">{t(2091)}</label>
              <input 
                type="text" 
                name="alert_min_time"
                id="alert_min_time" 
                value={alert_min_time}
                onChange={e => this.setState({ alert_min_time: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2092)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_max_frequency">
                {t(2093)}
              </label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>

            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </div>
      </div>
      
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertEngineTemperature, fetchAlertsSettingsById, fetchAlertsPresets }
)(AlertFormEngineTemperature)
import { motion } from 'framer-motion';
import { IconClose } from './Icons'

const backdrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
}

const modal = {
  hidden: {
    y: "-100vh",
    opacity: 0
  },
  visible: {
    y: "200px",
    opacity: 1,
    transition: { delay: 0.5 }
  }
}

const Modal = (options: { showModal: boolean, setShowModal: any, modalType: string }) => {
  const { showModal, setShowModal, modalType } = options
  return (
    <>
      { showModal && (
        <motion.div 
          className="modal-backdrop"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div 
            variants={modal}
            className="modal__wrapper"
          >
            <div className="modal">
              <span className="btn--circular modal__btn--close" onClick={() => setShowModal(false)}>
                <IconClose color="#ffffff" />
              </span>
              { modalType }
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  )
}

export default Modal;
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchStationById, deleteStationById } from '../../actions/station'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import Preloader from '../partials/Preloader'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'


interface StationsAuthorizedDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  station: any,
  fetchStationById: (id: number) => Promise<any>,
  deleteStationById: (id: number) => Promise<any>,
}


interface StationsAuthorizedDeleteState {
  station: { custom_id: string },
  custom_id: string,
  formSubmitted: boolean
}


export class StationsAuthorizedDelete extends React.Component<StationsAuthorizedDeleteProps, StationsAuthorizedDeleteState> {

  state = {
    station:{ custom_id: '' },
    custom_id: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchStationById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.station.status === fetchStates.success) {
        this.setState({ station: this.props.station.station })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    if(this.state.custom_id === '') {
      this.props.station.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2313)
    }
    if(this.state.custom_id !== this.props.station.station.custom_id) {
      this.props.station.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2314)
    }
    if(this.state.custom_id === this.props.station.station.custom_id) {
      this.props.deleteStationById(this.props.station.station.station_id)
      .then(() => {
        if(this.props.station.status === fetchStates.success) {
          this.props.router.navigate('/stations/authorized')
        }
      })
      this.setState({ formSubmitted: false })
    }
  }


  render() {

    const { status, fields } = this.props.station;
    const { station, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(2024)} ${station.custom_id}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(2025)} <strong>{station.custom_id}</strong>. {t(2026)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2027)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={this.state.custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(113)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ station }: RootState) => ({ station }),
  { fetchStationById, deleteStationById }
)(StationsAuthorizedDelete)))
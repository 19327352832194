const cardsCreatePagination = (routes: any) => {
  let paginator = [];
  let route: any = [];
  paginator.push(routes)

  let previousCard = routes[0].card;
  let currentCard = routes[0].card;

  routes.map((point: {card: string}, index: number) => {
    currentCard = point.card;
    if(previousCard !== currentCard || index === 1) {
      paginator.push(route)
      route = [];
    }
    previousCard = point.card;
    route.push(point);
    return null;
  });
  return paginator;
}


export default cardsCreatePagination
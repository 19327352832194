import routesCalculateDistance from './routesCalculateDistance'


const routesColorRoute = (routes: any) => {
  const markerColors = [ '#e91e63', '#9c27b0', '#3f51b5', '#03a9f4', '#009688', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#607d8b', '#f44336', '#9c27b0', '#3f51b5', '#4caf50', '#cddc39', '#ffc107', '#ff5722', '#9e9e9e', '#f72585', '#f3722c', '#7209b7', '#90be6d', '#4361ee', '#820263', '#291720', '#006ba6' ];
  let previousTime = routes[0].ts;
  let currentTime = routes[0].ts;
  let loc_ln1 = routes[0].loc_ln;
  let loc_lt1 = routes[0].loc_lt;
  let loc_ln2 = routes[0].loc_ln;
  let loc_lt2 = routes[0].loc_lt;
  let colorIndex = 0;
  let distance = 0;

  routes.map((route: {ts: number, loc_lt: number, loc_ln: number, color: string, distance: number}) => {
    currentTime = route.ts;
    loc_lt1 = route.loc_lt;
    loc_ln1 = route.loc_ln;

    if(currentTime - previousTime > 300) {
      colorIndex++;
      distance = 0;
    }

    route.color = markerColors[colorIndex];
    let distanceDifference = routesCalculateDistance({loc_lt1, loc_ln1, loc_lt2, loc_ln2});
    distance = distance + distanceDifference;
    route.distance = distance/1000;

    previousTime = route.ts;
    loc_lt2 = route.loc_lt;
    loc_ln2 = route.loc_ln;
    return null;
  });

  return routes;
}


export default routesColorRoute
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchFleetEcoScore } from '../../actions/vehicle'
import { fetchPersonGroups } from '../../actions/person'
import fetchStates from '../../types/fetchStates'
import withAuthorization from '../auth/withAuthorization'
import withRouter from './WithRouter'
import Authorization from './Authorization'
import DatePicker from 'react-datepicker'
import Card from './Card'
import t from '../translation/translate'
import Preloader from './Preloader'
import TableFooter from './TableFooter'
import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom'
import { IconVisibility, IconDropUp, IconDropDown, IconNoData } from '../partials/Icons'
import CtaBox from './CtaBox'


interface CardEcoScoreProps {
  auth: any,
  vehicle: any,
  person: any,
  fetchPersonGroups: () => Promise<void>,
  fetchFleetEcoScore: (options: { limit: number, skip: number, month: string, group_id: string, param: string, paramValue: boolean }) => Promise<void>,
}


interface CardEcoScoreState {
  vehiclesEcoScore: Array<any>,
  vehiclesEcoScoreCount: number,
  filterDate: Date,
  todayDate: Date,
  currentPage: number,
  perPage: number,
  groups: any,
  filterGroup: string,
  dataLoaded: boolean,
  dataGroupsLoaded: boolean,
  fetchingData: boolean,
  param: string, 
  paramValue: boolean,
}


export class CardEcoScore extends React.Component<CardEcoScoreProps, CardEcoScoreState> {

  state = {
    vehiclesEcoScore: [],
    vehiclesEcoScoreCount: 0,
    filterDate: new Date(),
    todayDate: new Date(),
    currentPage: 0,
    perPage: 10,
    groups: [],
    filterGroup: 'all',
    dataLoaded: false,
    dataGroupsLoaded: false,
    fetchingData: true,
    param: 'custom_id',
    paramValue: true,
  }

  componentDidMount = async () => {
    if(this.props.auth.authorized.vehicles === true) {
      await this.fetchPersonGroups();
      const { param, paramValue } = this.state
      await this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue });
    }
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage, param, paramValue: !this.state.paramValue })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { param, paramValue } = this.state;
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param, paramValue });
  }


  prevPage = (prevPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param, paramValue });
  }


  nextPage = (nextPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param, paramValue });
  }


  reloadPage = async (options: { currentPage: number, perPage: number, param: string, paramValue: boolean }) => {
    this.setState({ fetchingData: true })
    const { currentPage, perPage, param, paramValue } = options;
    await this.props.fetchFleetEcoScore({ limit: perPage, skip: perPage * currentPage, month: this.state.filterDate.toISOString().slice(0, 7), group_id: this.state.filterGroup, param, paramValue });
    if(this.props.vehicle.status === fetchStates.success) {
      const { vehiclesEcoScore, vehiclesEcoScoreCount } = this.props.vehicle;
      this.setState({ vehiclesEcoScore, vehiclesEcoScoreCount, fetchingData: false });
    }
  }


  fetchPersonGroups = async () => {
    await this.props.fetchPersonGroups();
    if(this.props.person.status === fetchStates.success) {
      const { groups } = this.props.person.person;
      if(groups.length > 0) {
        let filterGroup = 'all';
        if(groups.length === 1) {
          filterGroup = groups[0].group_id;
        }     
        this.setState({ groups, filterGroup, dataGroupsLoaded: true });
      } else {
        this.setState({ dataLoaded: true, dataGroupsLoaded: false });
      }
    }
  }



  /* FUNCTIONS TO HANDLE FILTER */
  updateFilterDate = (date: Date) => {
    const { param, paramValue } = this.state
    this.setState({ filterDate: date }, () => this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue }));
  }


  updateFilterGroup = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { param, paramValue } = this.state
    this.setState({ filterGroup: e.target.value }, () => this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue }));
  }



  render() {

    const { filterDate, todayDate, filterGroup, groups, dataGroupsLoaded, fetchingData, currentPage, perPage, vehiclesEcoScore, vehiclesEcoScoreCount, param, paramValue } = this.state

    return (
      <Authorization requiresAuth="vehicles">
        <Card
          button={false} 
          title={`Fleet Eco Score`} 
          time={false}
          animate
        >
          <form className="form">
            <div className="filter">
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2050)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDate}
                    selectsStart
                    onChange={this.updateFilterDate}
                    dateFormat='yyyy-MM'
                    maxDate={todayDate}
                    minDate={new Date(2021, 0, 1, 0, 0, 0, 0)}
                    required={true}
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </span>
              </span>
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2429)}</span>
                <span className="filter_filter-select">
                  <select 
                    name="groups" 
                    onChange={e => this.updateFilterGroup(e)}
                    value={filterGroup}
                  >
                    <option key="0" value="all">{t(1152)}</option>
                    { 
                      groups.map((group: {group_id: number, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                    }
                  </select>
                </span>
              </span>
            </div>
          </form>
          <>
            { 
              dataGroupsLoaded === false ? (
                <CtaBox padding={true} type="warning" title={t(4278)} icon={<IconNoData size={54} color="#ffffff" />}>
                  {t(2490)}
                </CtaBox>
              ) : (
                <div className="table-wrapper">
                  { fetchingData ? <Preloader type="preloader-center" /> : (
                    <table className="table">
                      <thead className="table__header">
                        <tr>
                          <th></th>
                          <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('total_savings_co2_kg')}>CO2 Savings {param === 'total_savings_co2_kg' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          <th className="th--clickable" onClick={() => this.setParam('total_alternative_percent')}>% Distance on alternative fuel {param === 'total_alternative_percent' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                        </tr>
                      </thead>
                      <tbody className="table__body table__body--striped">
                          {
                            vehiclesEcoScore && vehiclesEcoScore.map((item:  {vehicle_id: string, custom_id: string, total_savings_co2_kg: number, total_alternative_percent: number, total_alternative_percent_count: number }, index: number) => {
                              return (
                                <tr key={item.vehicle_id}>
                                  <td className="simple">
                                    <Tooltip anchorId={`tooltip-on-the-fly${index}`} />
                                    <Link to={`/vehicles/now/${item.vehicle_id}`}>
                                      <span id={`tooltip-on-the-fly${index}`} data-tooltip-content={t(16)}>
                                        <IconVisibility 
                                          task 
                                          button 
                                          color="#ffffff"
                                        />
                                      </span>
                                    </Link>
                                  </td>
                                  <td>{item.custom_id}</td>
                                  <td>{Math.round(item.total_savings_co2_kg)} kg</td>
                                  <td>{Math.round(item.total_alternative_percent)} %</td>
                                </tr>
                              )
                            })
                          }
                      </tbody>
                      <TableFooter 
                          button={false}
                          colSpan={6}
                          currentPage={ currentPage }
                          perPage={ perPage }
                          itemsCount={ vehiclesEcoScoreCount }
                          prevPage={() => this.prevPage(currentPage - 1)}
                          nextPage={() => this.nextPage(currentPage + 1)}
                          updatePagination={this.updatePagination}
                        />
                      </table>
                  )}
                </div>
              )
            }
          </>
        </Card>
      </Authorization>
      )
    }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, person }: RootState) => ({ auth, vehicle, person }),
  { fetchPersonGroups, fetchFleetEcoScore }
)(CardEcoScore)))
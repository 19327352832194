import { CHECKPOINT } from '../types'
import fetchStates from '../types/fetchStates'


interface CheckpointState {
  checkpoints : [],
  checkpointsCount: number,
  checkpointsVisits: [],
  checkpointsDateVehicleVisits: [],
  checkpointsDateVehicleVisitsCount: number,
}


const DEFAULT_CHECKPOINTS: CheckpointState = {
  checkpoints : [],
  checkpointsCount: 0,
  checkpointsVisits: [],
  checkpointsDateVehicleVisits: [],
  checkpointsDateVehicleVisitsCount: 0
}


const checkpoint = (state = DEFAULT_CHECKPOINTS, action: any) => {
  switch(action.type) {
    case CHECKPOINT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case CHECKPOINT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case CHECKPOINT.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        checkpoints: action.checkpoints,
        checkpointsCount: parseInt(action.checkpointsCount, 10),
      };
    case CHECKPOINT.FETCH_ALL_VISITS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        checkpointsVisits: action.checkpointsVisits,
      };
    case CHECKPOINT.FETCH_ALL_VISITS_VEHICLE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        checkpointsDateVehicleVisits: action.checkpointsDateVehicleVisits,
        checkpointsDateVehicleVisitsCount: action.checkpointsDateVehicleVisitsCount,
      };
    case CHECKPOINT.FETCH_CHECKPOINT_VISIT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        checkpointVisit: action.checkpointVisit,
      }
    case CHECKPOINT.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        checkpoint: action.checkpoint,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case CHECKPOINT.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case CHECKPOINT.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case CHECKPOINT.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case CHECKPOINT.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case CHECKPOINT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default checkpoint
/*
0 WPGH / WPL Uniwersalny (dla GF ascending)
1 WPG_50k universal
2 WPG_90R universal
3 WPG_CNG_AEB806 descending
4 WPG_CNG_TAA_MSG352370 descending
5 WPG_CNG_55PP ascending
6 WPG_CNG_AXIS descending
7 WPG_CNG_201C_AC5V descending
8 WPG_CNG_201C_AC12V descending
9 WPG_CNG_ESIGAS ascending
10 WPG_ROCHESTER descending
11 WPG_2 universal
12 WPG_4 universal
13 WPG_50k (ascending) Ascending
14 WPG_50k (descending) Descending
15 WPG_90R (ascending) Ascending
16 WPG_90R (descending) Descending
17 WPG_20k (descending) Descending
18 WPG_CNG_DGI12V Ascending
19 WPG_CNG_T800 Descending
20 WPGH / WPL (descending) Descending
21 WPG_CNG_DGI5V Descending
22 WPG_CNG_KNC5V Ascending
23 WPG_ROCHESTER_240R Ascending
24 WPG_CNG_EASY_GAS12V Ascending
25 WPG_90R_ATIKER Ascending
26 WPG_100R_ATIKER Descending
27 WPG_110R_ATIKER Descending
28 WPG_LS050_ATIKER Descending
255 - sensor unknown (gas controller is does not provide the information)
*/


function getGasSensorType(num: string) {
  if(num) {
    switch(num) {
      case '0': 
        return 'WPGH / WPL Universal (for GF ascending)'
      case '1': 
        return 'WPG_50k universal'
      case '2': 
        return 'WPG_90R universal'
      case '3': 
        return 'WPG_CNG_AEB806 descending'
      case '4': 
        return 'WPG_CNG_TAA_MSG352370 descending'
      case '5': 
        return 'WPG_CNG_55PP ascending'
      case '6': 
        return 'WPG_CNG_AXIS descending'
      case '7': 
        return 'WPG_CNG_201C_AC5V descending'
      case '8': 
        return 'WPG_CNG_201C_AC12V descending'
      case '9': 
        return 'WPG_CNG_ESIGAS ascending'
      case '10':
        return 'WPG_ROCHESTER descending'
      case '11':
        return 'WPG_2 universal'
      case '12':
        return 'WPG_4 universal'
      case '13':
        return 'WPG_50k (ascending) Ascending'
      case '14':
        return 'WPG_50k (descending) Descending'
      case '15':
        return 'WPG_90R (ascending) Ascending'
      case '16':
        return 'WPG_90R (descending) Descending'
      case '17':
        return 'WPG_20k (descending) Descending'
      case '18':
        return 'WPG_CNG_DGI12V Ascending'
      case '19':
        return 'WPG_CNG_T800 Descending'
      case '20':
        return 'WPGH / WPL (descending) Descending'
      case '21':
        return 'WPG_CNG_DGI5V Descending'
      case '22':
        return 'WPG_CNG_KNC5V Ascending'
      case '23':
        return 'WPG_ROCHESTER_240R Ascending'
      case '24':
        return 'WPG_CNG_EASY_GAS12V Ascending'
      case '25':
        return 'WPG_90R_ATIKER Ascending'
      case '26':
        return 'WPG_100R_ATIKER Descending'
      case '27':
        return 'WPG_110R_ATIKER Descending'
      case '28':
        return 'WPG_LS050_ATIKER Descending'
      case '255':
        return 'Sensor unknown (gas controller does not provide the information)'
      default:
        return 'Sensor unknown (gas controller does not provide the information - type not in the definitions)'
    }
  }
  return null;
} 


export default getGasSensorType;
import React from 'react';
import { Link } from 'react-router-dom';
import t from '../translation/translate';
import setCookie from '../../functions/cookieSet'
import getCookie from '../../functions/cookieGet'
const { COOKIES_CONSENT_EXPIRE_DAYS } = require('../../config');


export class CookieConsent extends React.Component {

  state = { showConsent : true };

  componentDidMount() {
    if(getCookie('stagtelematicCookiesAcceptance')) {
      if(getCookie('stagtelematicCookiesAcceptance') === 'true') {
        this.setState({ showConsent : false });
      } else {
        this.setState({ showConsent : true });
      }
    }
  }

  acceptCookies = () => {
    setCookie('stagtelematicCookiesAcceptance', 'true', COOKIES_CONSENT_EXPIRE_DAYS);
    this.setState({ showConsent : false });
  }

  render() {

    return (
      <>
      { this.state.showConsent ? (
        <div className="cookie-consent">

          {t(2365)} <Link to="/cookies-policy">{t(2366)}</Link> {t(2367)}. <Link to="#!" onClick={this.acceptCookies}>{t(2368)}</Link>

        </div>
      ) : null }
      </>
    );
  }
}


export default CookieConsent
import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabVehicles: React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/vehicles' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/vehicles' })}>
        {t(135)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
    </nav>
  )
}


export default TabVehicles
import React from 'react'
import { RootState } from '../../reducers'
import { fetchAccountById, deleteAccountById } from '../../actions/account'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'


interface AccountsDeleteProps {
  router: {  params: { id: string }, navigate: (to: string) => any },
  account: any,
  fetchAccountById: (id: string) => Promise<any>,
  deleteAccountById: (id: string) => Promise<any>,
}


interface AccountsDeleteState {
  account: { company_name: string },
  company_name: string,
  formSubmitted: boolean
}


export class AccountsDelete extends React.Component<AccountsDeleteProps, AccountsDeleteState> {

  state = {
    account: { company_name: '' },
    company_name: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchAccountById(this.props.router.params.id)
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        this.setState({ account : this.props.account.account })
      }
    });
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })


    if(this.state.company_name === '') {
      this.props.account.fields = ['company_name']
      this.setState({ formSubmitted: false })
      return error(2322)
    }
    if(this.state.company_name !== this.props.account.account.company_name) {
      this.props.account.fields = ['company_name']
      this.setState({ formSubmitted: false })
      return error(2323)
    }

    if(this.state.company_name === this.props.account.account.company_name) {
      this.props.deleteAccountById(this.props.account.account.account_id)
      .then(() => {
        if(this.props.account.status === fetchStates.success) {
          this.props.router.navigate('/accounts')
        }
      })
      this.setState({ formSubmitted: false })
    }
  }


  render() {

    const { status, fields } = this.props.account;
    const { account, company_name, formSubmitted } = this.state;


    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(1163)} '${account.company_name}'`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(2443)} <strong>{account.company_name}</strong>. {t(2012)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="company_name">
                {t(1164)}
              </label>
              <input 
                type="text" 
                name="company_name"
                id="company_name" 
                value={company_name}
                onChange={e => this.setState({ company_name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('company_name') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(1163)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ account }: RootState) => ({ account }),
  { fetchAccountById, deleteAccountById }
)(AccountsDelete)))
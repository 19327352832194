interface TableHeaderProps {
  columns: Array<string>, 
  handleOnChange?: () => void,
}


const TableHeader: React.FC<TableHeaderProps> = ({ columns, handleOnChange }) => {
  return (
    <thead className="table__header">
      <tr>
        {
          columns.map((column, index) => {
            if(Array.isArray(column)) {
              return <th key={index}>
                <input 
                  onChange={handleOnChange}
                  checked={column[0].includes(column[1])}
                  type="checkbox" 
                  name="delete"  
                /> 
              </th>
            } else {
              return <th key={index}>{column}</th>
            }
          })
        }
      </tr>
    </thead>
  )
}


export default TableHeader
import { INVOICE } from '../types'
import fetchStates from '../types/fetchStates'


interface InvoiceState {
  url: string,
  invoice: {},
  invoices: [],
  account: {},
  invoicesCount: number,
  overdue: [],
  overdueCount: number,
  unpaid: [],
  unpaidCount: number,
  overdueUnpaid: [],
  overdueUnpaidCount: number,
  vehiclesSelected: [],
  option_2_savings: number,
  option_3_savings: number,
  subscription_total: number
}


const DEFAULT_INVOICE: InvoiceState = {
  url: '',
  invoice: {},
  invoices: [],
  account: {},
  invoicesCount: 0,
  overdue: [],
  overdueCount: 0,
  unpaid: [],
  unpaidCount: 0,
  overdueUnpaid: [],
  overdueUnpaidCount: 0,
  vehiclesSelected: [],
  option_2_savings: 0,
  option_3_savings: 0,
  subscription_total: 0
}


const invoice = (state = DEFAULT_INVOICE, action: any) => {
  switch(action.type) {
    case INVOICE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case INVOICE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case INVOICE.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        invoices: action.invoices,
        invoicesCount: parseInt(action.invoicesCount, 10),
      };
    case INVOICE.FETCH_OVERDUE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        overdue: action.overdue,
        overdueCount: parseInt(action.overdueCount, 10),
      };
    case INVOICE.FETCH_UNPAID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        unpaid: action.unpaid,
        unpaidCount: parseInt(action.unpaidCount, 10),
      };
    case INVOICE.FETCH_OVERDUE_AND_UNPAID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        overdueUnpaid: action.overdueUnpaid,
        overdueUnpaidCount: parseInt(action.overdueUnpaidCount, 10),
      };
    case INVOICE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        invoice: action.invoice,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case INVOICE.FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        account: action.account,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      }
    case INVOICE.FETCH_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case INVOICE.FETCH_SELECTED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
        vehiclesSelected: action.vehiclesSelected
      }
    case INVOICE.FETCH_CONFIRM_TRANSACTION_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.message,
      }
    case INVOICE.PAY_SELECTED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        url: action.url,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      }
    case INVOICE.FETCH_TEMP_NUMBER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        temp_number: action.temp_number,
        sequential_number: action.sequential_number, 
        client_abb: action.client_abb, 
        month: action.month, 
        year: action.year
      }
    case INVOICE.FETCH_CALCULATE_SUBSCRIBTION_TOTALS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        subscription_total: action.subscription_total,
        option_2_savings: action.option_2_savings,
        option_3_savings: action.option_3_savings,
      }
    case INVOICE.RESET:
      return {
        state: []
      }
    default:
      return state
  }
}


export default invoice
import { COMPETITOR } from '../types'
import fetchStates from '../types/fetchStates'


interface CompetitorState {
  competitors : [],
  competitorsCount: number,
}


const DEFAULT_COMPETITORS: CompetitorState = {
  competitors : [],
  competitorsCount: 0,
}


const competitor = (state = DEFAULT_COMPETITORS, action: any) => {
  switch(action.type) {
    case COMPETITOR.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case COMPETITOR.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case COMPETITOR.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        competitors: action.competitors,
        competitorsCount: parseInt(action.competitorsCount, 10),
      };
    case COMPETITOR.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        competitor: action.competitor,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case COMPETITOR.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case COMPETITOR.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case COMPETITOR.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case COMPETITOR.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case COMPETITOR.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default competitor
import { translations } from './translations';
import { errors } from './malfunctionErrors';
import { store } from '../../store';
import setCookie from '../../functions/cookieSet'
import getCookie from '../../functions/cookieGet'
const { COOKIES_LANGUAGE_EXPIRE_DAYS, DEFAULT_LANGUAGE } = require('../../config');


function t(phraseNumber: number, type?: string) {

  if(phraseNumber) {
    if(typeof phraseNumber === 'number') {
      let state:any = {}
      state = store.getState();

      if(type && type === 'e') {
        let errors_list: any = []
        errors_list = errors
        if(errors_list.find((x: {id: number}) => x.id === phraseNumber)) {
          if(state && state.auth && state.auth.language) {
            return errors_list.find((x: {id: number}) => x.id === phraseNumber)[state.auth.language]
          }
        }

      } else {

        let translations_list: any = []
        translations_list = translations
        if(translations_list.find((x: {id: number}) => x.id === phraseNumber)) {
          if(state && state.auth && state.auth.language) {
            setCookie('stagtelematicCookiesLanguage', state.auth.language, COOKIES_LANGUAGE_EXPIRE_DAYS);
            if(getCookie('zonePhrasesNumbering') === 'true') {
              return  (<span className="phrase-wrapper">
                        <span className="phrase-number">
                          {phraseNumber}
                        </span>
                        {translations_list.find((x: {id: number}) => x.id === phraseNumber)[state.auth.language]}
                      </span>);
            }
            return translations_list.find((x: {id: number}) => x.id === phraseNumber)[state.auth.language]
          } else {
            // get cookie
            if(getCookie('stagtelematicCookiesLanguage')) {
              // return the language stored in the cookie
              if(getCookie('zonePhrasesNumbering') === 'true') {
                return <span className="phrase-wrapper"><span className="phrase-number">{phraseNumber}</span>{translations_list.find((x: {id: number}) => x.id === phraseNumber)[getCookie('stagtelematicCookiesLanguage')]}</span>;
              }
              return translations_list.find((x: {id: number}) => x.id === phraseNumber)[getCookie('stagtelematicCookiesLanguage')];
            } else {
              if(getCookie('zonePhrasesNumbering') === 'true') {
                return <span className="phrase-wrapper"><span className="phrase-number">{phraseNumber}</span>{translations_list.find((x: {id: number}) => x.id === phraseNumber)[DEFAULT_LANGUAGE]}</span>;
              }
              return translations_list.find((x: {id: number}) => x.id === phraseNumber)[DEFAULT_LANGUAGE];
            }

          }
        } else {
          return `Phrase number ${phraseNumber} does not exist`
        }

      }


    } else {
      return phraseNumber
    } 
  } else {
    return 'Please provide phrase number'
  }


}

export default t;
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllRfid } from '../../actions/rfid'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { Link } from 'react-router-dom'
import { IconDelete, IconEdit } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import TableHeader from '../partials/TableHeader';
import TableFooter from '../partials/TableFooter';
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import { Tooltip } from 'react-tooltip'


interface RfidProps {
  rfid: any,
  auth: any,
  fetchAllRfid: (options: { limit: number, skip: number }) => Promise<any>,
}


interface RfidState {
  rfidCount: number,
  rfid: any,
  currentPage: number,
  perPage: number,
  timezone: string,
}


export class Rfid extends React.Component<RfidProps, RfidState> {

  state = {
    rfidCount: 0,
    rfid: [],
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }


  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    const { perPage, currentPage } = options
    return this.props.fetchAllRfid({ limit: perPage, skip: perPage * currentPage })
    .then(() => {
      if(this.props.rfid.status === fetchStates.success) {
        const { rfid, rfidCount } = this.props.rfid;
        this.setState({ rfid, rfidCount })
      }
    })
  }


  render() {

    const { status } = this.props.rfid;
    const { currentPage, perPage, timezone, rfid, rfidCount } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={true} 
          buttonLink='/rfid/add' 
          buttonTitle={t(214)}
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          title="RFID"
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['', 'RFID', 'ID', t(2410), t(2020), '']} 
              />
                <tbody className="table__body table__body--striped">
                  { rfid && rfid.map((item: {rfid_id: number, rfid_number: number, custom_id: string, label: string, registered: string}, index: number) => {
                    return (
                      <tr key={item.rfid_id}>
                        <td className="simple">
                          <Tooltip anchorId={`tooltip-rfid-edit${index}`}/>
                          <span id={`tooltip-rfid-edit${index}`} data-tooltip-content={t(24)}>
                            <Link to={`/rfid/edit/${item.rfid_id}`}>
                              <IconEdit task button color="#ffffff" />
                            </Link>
                          </span>
                        </td>
                        <td>
                          {item.rfid_number}
                        </td>
                        <td>
                          {item.custom_id}
                        </td>
                        <td>
                          {item.label ? item.label : 'n/a'}
                        </td>
                        <td>
                          {moment(item.registered).tz(timezone).format('DD/MM/YYYY')}
                        </td>
                        <td className="simple">
                          <Tooltip anchorId={`tooltip-rfid-delete${index}`}/>
                          <span id={`tooltip-rfid-delete${index}`} data-tooltip-content={t(29)}>
                            <Link to={`/rfid/delete/${item.rfid_id}`}>
                              <IconDelete task button color="#ffffff" />
                            </Link>
                          </span>
                        </td>
                      </tr>
                      )
                    })
                  }
                </tbody>
              <TableFooter 
                button={false}
                colSpan={6}
                currentPage={currentPage}
                perPage={perPage}
                itemsCount={ rfidCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ rfid, auth }: RootState) => ({ rfid, auth }),
  { fetchAllRfid }
)(Rfid)))
import React from 'react'
import { RootState } from '../../reducers'
import fetchStates from '../../types/fetchStates'
import { connect } from 'react-redux'
import { fetchVehicleById } from '../../actions/vehicle'
import { updateAlertSpeedSwitch, updateAlertGeoFenceSwitch, updateAlertEngineTemperatureSwitch, updateAlertRpmSwitch, updateAlertGasolineUseSwitch, updateAlertGasMalfunctionSwitch, updateAlertGasMaintenanceSwitch, fetchAlertsSettingsById, updateEmailNotifictaionVehicleId, updateAlertLockedSwitch, updateAlertGasPercentageSwitch, overrideGroupAlertSettings } from '../../actions/alert'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import Switch from '../partials/Switch'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { motion } from 'framer-motion'
import { motion_container, motion_item } from '../../helpers/motion'
import Modal from '../partials/Modal'
import { IconAlertSpeed, IconAlertsGeoFence, IconAlertsMaintenence, IconAlertsGasolineUse, IconAlertsEngineTemperature, IconAlertsRpm, IconAlertsMalfunction, IconSettings, IconAlertsLocked, IconAlertsPercentage } from '../partials/Icons'
import AlertFormSpeed from '../partials/alerts/AlertFormSpeed'
import AlertFormEngineTemperature from '../partials/alerts/AlertFormEngineTemperature'
import AlertFormRpm from '../partials/alerts/AlertFormRpm'
import AlertFormGasMaintenance from '../partials/alerts/AlertFormGasMaintenance'
import AlertFormGasolineUse from '../partials/alerts/AlertFormGasolineUse'
import AlertFormGasSystemMalfunction from '../partials/alerts/AlertFormGasSytemMalfunction'
import AlertFormGeoFence from '../partials/alerts/AlertFormGeoFence'
import AlertFormGasPercentage from '../partials/alerts/AlertFormGasPercentage'
import alertTypesTitle from '../../helpers/alertTypesTitle'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface VehiclesAlertsProps {
  router: { params: { id: string, alertType: string }, navigate: (to: string) => any },
  vehicle: any,
  auth: any,
  alert: any,
  fetchVehicleById: (id: number) => Promise<void>,
  fetchAlertsSettingsById: (id: number) => Promise<void>,
  updateEmailNotifictaionVehicleId: (options:{ id: number, enabled: boolean, email: string}) => Promise<void>,
  updateAlertSpeedSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertGeoFenceSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertEngineTemperatureSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertRpmSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertGasolineUseSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertGasMalfunctionSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertGasMaintenanceSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  updateAlertGasPercentageSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>, 
  updateAlertLockedSwitch: (options:{ id: number, enabled: boolean}) => Promise<void>,
  overrideGroupAlertSettings: (options:{ group_id: string, vehicle_id: number, enabled: boolean }) => Promise<void>,
}


interface VehiclesAlertsState {
  selectedId: number,
  showModal: boolean,
  modalType: any,
  alert_speed: boolean,
  alert_geo_fence: boolean,
  alert_engine_temp: boolean,
  alert_rpm: boolean,
  alert_gasoline_use: boolean,
  alert_gas_malfunction: boolean,
  alert_gas_maintenance: boolean,
  alert_gas_percentage: boolean,
  alert_locked: boolean,
  enable_email_vehicle: boolean,
  formSubmitted: boolean,
}


export class VehiclesAlerts extends React.Component<VehiclesAlertsProps, VehiclesAlertsState> {


  state = {
    selectedId: 0,
    showModal: false,
    modalType: '',
    alert_speed: false,
    alert_geo_fence: false,
    alert_engine_temp: false,
    alert_rpm: false,
    alert_gasoline_use: false,
    alert_gas_malfunction: false,
    alert_gas_maintenance: false,
    alert_gas_percentage: false,
    alert_locked: false,
    enable_email_vehicle: false,
    formSubmitted: false,
  }


  componentDidMount() {
    this.props.fetchVehicleById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        if(this.props.vehicle.vehicle.email_notifications && this.props.vehicle.vehicle.email_notifications.includes(this.props.auth.email)) {
          this.setState({ enable_email_vehicle: true })
        }
      }
    })
    this.props.fetchAlertsSettingsById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.alert.status === fetchStates.success) {

        const { alerts_spd_en, alerts_geo_en, alerts_engt_en, alerts_rpm_en, alerts_fuel_en, alerts_malf_en, alerts_maint_en, alerts_perc_en, alert_locked_en } = this.props.alert.alertsSettings
        
        this.setState({
          alert_speed: alerts_spd_en !== null ? (alerts_spd_en === 1 ? true : false) : false,
          alert_geo_fence: alerts_geo_en !== null ? (alerts_geo_en === 1 ? true : false) : false,
          alert_engine_temp: alerts_engt_en !== null ? (alerts_engt_en === 1 ? true : false) : false,
          alert_rpm: alerts_rpm_en !== null ? (alerts_rpm_en === 1 ? true : false) : false,
          alert_gasoline_use: alerts_fuel_en !== null ? (alerts_fuel_en === 1 ? true : false) : false,
          alert_gas_malfunction: alerts_malf_en !== null ? (alerts_malf_en === 1 ? true : false) : false,
          alert_gas_maintenance: alerts_maint_en !== null ? (alerts_maint_en === 1 ? true : false) : false,
          alert_gas_percentage: alerts_perc_en !== null ? alerts_perc_en : false,
          alert_locked: alert_locked_en !== null ? alert_locked_en : false,
        })
      }
    })
    if(this.props.router.params.alertType !== undefined) {
      this.setShowModal(true, this.props.router.params.alertType)
    }
  }

  setShowModal = (value: boolean, type: any) => {
    const getType = (type: any) => {
      if(value === true) {
        this.props.router.navigate(`/vehicles/alerts/${parseInt(this.props.router.params.id, 10)}/${type}`)
      } else {
        this.props.router.navigate(`/vehicles/alerts/${parseInt(this.props.router.params.id, 10)}`)
      }

      switch(type) {
        case 'alert-speed':
          return <AlertFormSpeed setShowModal={this.setShowModal} modalId={this.props.router.params.id}  />
        case 'alert-geo-fence':
          return <AlertFormGeoFence setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-engine-temp':
          return <AlertFormEngineTemperature setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-rpm':
          return <AlertFormRpm setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-gasoline-use':
          return <AlertFormGasolineUse setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-gas-malfunction':
          return <AlertFormGasSystemMalfunction setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-gas-maintenance':
          return <AlertFormGasMaintenance setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        case 'alert-gas-percentage':
          return <AlertFormGasPercentage setShowModal={this.setShowModal} modalId={this.props.router.params.id} />
        default:
          return null
      }
    }

    this.setState({ showModal: value, modalType: getType(type) })
  }


  enableEmailNotificationVehicleId = () => {
    this.setState({ enable_email_vehicle: !this.state.enable_email_vehicle })
    this.props.updateEmailNotifictaionVehicleId({ id: parseInt(this.props.router.params.id), enabled: !this.state.enable_email_vehicle, email: this.props.auth.email })
  }


  switchAlertSpeed = () => {
    this.setState({ alert_speed: !this.state.alert_speed })
    this.props.updateAlertSpeedSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_speed })
  }

  switchAlertGeoFence = () => {
    this.setState({ alert_geo_fence: !this.state.alert_geo_fence })
    this.props.updateAlertGeoFenceSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_geo_fence })
  }

  switchAlertEngineTemperature = () => {
    this.setState({ alert_engine_temp: !this.state.alert_engine_temp })
    this.props.updateAlertEngineTemperatureSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_engine_temp })
  }

  switchAlertRpm = () => {
    this.setState({ alert_rpm: !this.state.alert_rpm })
    this.props.updateAlertRpmSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_rpm })
  }

  switchAlertGasolineUse = () => {
    this.setState({ alert_gasoline_use: !this.state.alert_gasoline_use })
    this.props.updateAlertGasolineUseSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_gasoline_use })
  }

  switchAlertGasMalfunction = () => {
    this.setState({ alert_gas_malfunction: !this.state.alert_gas_malfunction })
    this.props.updateAlertGasMalfunctionSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_gas_malfunction })
  }

  switchAlertGasMaintanance = () => {
    this.setState({ alert_gas_maintenance: !this.state.alert_gas_maintenance })
    this.props.updateAlertGasMaintenanceSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_gas_maintenance })
  }

  switchAlertGasPercentage = () => {
    this.setState({ alert_gas_percentage: !this.state.alert_gas_percentage })
    this.props.updateAlertGasPercentageSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_gas_percentage })
  }

  switchAlertLocked = () => {
    this.setState({ alert_locked: !this.state.alert_locked })
    this.props.updateAlertLockedSwitch({ id: parseInt(this.props.router.params.id), enabled: !this.state.alert_locked })
  }


  overrideGroupAlertSettings = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { vehicle_id, group_id } = this.props.vehicle.vehicle
    console.log(vehicle_id, group_id)
    this.props.overrideGroupAlertSettings({ group_id, vehicle_id, enabled: this.state.enable_email_vehicle })
    .then(() => {
      this.setState({ formSubmitted: false })
    })
  }


  render() {

    const { vehicle, status } = this.props.vehicle;
    const { alert_speed, alert_geo_fence, alert_engine_temp, alert_rpm, alert_gasoline_use, alert_gas_malfunction, alert_gas_maintenance, alert_gas_percentage, alert_locked, enable_email_vehicle, formSubmitted } = this.state;
    const { hide_lock_feature } = this.props.auth;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        { vehicle && (
        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          button={false} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id, 10)}
          auth={this.props.auth}
          animate
        >


            <Modal showModal={this.state.showModal} setShowModal={this.setShowModal} modalType={this.state.modalType} />

            <div className="alerts__settings">
              <form className="form">
                <div className="form-group__switch form-group__switch--large">
                  <label onClick={() => this.enableEmailNotificationVehicleId()}>
                    <Switch switchState={enable_email_vehicle} />
                    <span className="label-title">{t(2390)}</span>
                  </label>
                </div>
              </form>
            </div>

            <motion.div 
              className="alerts"
              variants={motion_container}
              initial="hidden"
              animate="visible"
            >


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_speed })}
                key={1} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { 
                  alert_speed && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-speed')}>
                    <IconSettings size={24} color="#ffffff" />
                  </span> 
                }
                <IconAlertSpeed size={80} color={alert_speed ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertSpeed()}>
                  <span className="alert__title label-title">{alertTypesTitle(1)}</span>
                  <Switch switchState={alert_speed} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_geo_fence })}
                key={2} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_geo_fence && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-geo-fence')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsGeoFence size={80} color={alert_geo_fence ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertGeoFence()}>
                  <span className="alert__title label-title">{alertTypesTitle(2)}</span>
                  <Switch switchState={alert_geo_fence} />
                </label>
              </motion.div>



              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_engine_temp })}
                key={3} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_engine_temp && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-engine-temp')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsEngineTemperature size={80} color={alert_engine_temp ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertEngineTemperature()}>
                  <span className="alert__title label-title">{alertTypesTitle(3)}</span>
                  <Switch switchState={alert_engine_temp} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_rpm })}
                key={4} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_rpm && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-rpm')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsRpm size={80} color={alert_rpm ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertRpm()}>
                  <span className="alert__title label-title">{alertTypesTitle(4)}</span>
                  <Switch switchState={alert_rpm} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_gasoline_use })}
                key={5} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_gasoline_use && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-gasoline-use')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsGasolineUse size={80} color={alert_gasoline_use ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertGasolineUse()}>
                  <span className="alert__title label-title">{alertTypesTitle(5)}</span>
                  <Switch switchState={alert_gasoline_use} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_gas_malfunction })}
                key={6} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_gas_malfunction && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-gas-malfunction')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsMalfunction size={80} color={alert_gas_malfunction ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertGasMalfunction()}>
                  <span className="alert__title label-title">{alertTypesTitle(6)}</span>
                  <Switch switchState={alert_gas_malfunction} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_gas_maintenance })}
                key={7} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_gas_maintenance && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-gas-maintenance')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsMaintenence size={80} color={alert_gas_maintenance ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertGasMaintanance()}>
                  <span className="alert__title label-title">{alertTypesTitle(7)}</span>
                  <Switch switchState={alert_gas_maintenance} />
                </label>
              </motion.div>


              <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_gas_percentage })}
                key={8} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_gas_percentage && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-gas-percentage')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsPercentage size={80} color={alert_gas_percentage ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertGasPercentage()}>
                  <span className="alert__title label-title">{alertTypesTitle(9)}</span>
                  <Switch switchState={alert_gas_percentage} />
                </label>
              </motion.div>


            {
              hide_lock_feature === false && <motion.div 
                className={classnames('alert', { 'alert--disabled': !alert_locked })}
                key={9} 
                variants={motion_item}
                whileHover={{ scale: 1.1 }}
              >
                { alert_locked && <span className="alert__settings" onClick={() => this.setShowModal(true, 'alert-gas-maintenance')}>
                  <IconSettings size={24} color="#ffffff" />
                </span> }
                <IconAlertsLocked size={80} color={alert_locked ? '#33385A' : '#D4D4D4'} />
                <label onClick={() => this.switchAlertLocked()}>
                  <span className="alert__title label-title">{alertTypesTitle(8)}</span>
                  <Switch switchState={alert_locked} />
                </label>
              </motion.div>
            }



            </motion.div>

            <div className="attention-box">
              <div className="attention-box__info">
                {t(1157)}
              </div>
              <div className="alerts__group-override">
                <form className="form" onSubmit={this.overrideGroupAlertSettings}>
                  <div className="form-group">
                    <input 
                      type="submit" 
                      value={t(229)}
                      className="btn btn--primary card__footer--btn-left"
                      disabled={ formSubmitted ? true : false }
                    />
                  </div>
                </form>
              </div>
            </div>

        </Card>
        )}
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, alert, auth }: RootState) => ({ vehicle, alert, auth }),
  { fetchVehicleById, updateAlertSpeedSwitch,updateAlertGeoFenceSwitch, updateAlertEngineTemperatureSwitch, updateAlertRpmSwitch, updateAlertGasolineUseSwitch, updateAlertGasMalfunctionSwitch, updateAlertGasMaintenanceSwitch, fetchAlertsSettingsById, updateEmailNotifictaionVehicleId, updateAlertLockedSwitch, updateAlertGasPercentageSwitch, overrideGroupAlertSettings }
)(VehiclesAlerts)))
import { NavLink } from 'react-router-dom'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabVehicles: React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/checkpoints/filter' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/checkpoints/filter' })}>
        {t(205)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
      <NavLink to={{ pathname: '/checkpoints/map' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/checkpoints/map' })}>
        {t(204)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
      <NavLink to={{ pathname: '/checkpoints' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/checkpoints' })}>
      {t(203)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
    </nav>
  )
}


export default TabVehicles
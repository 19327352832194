import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchFuelPrices, updateFuelPrices, fetchFleetStatisticsFromReportsNew, fetchGroupStatisticsFromReportsNew } from '../../actions/vehicle'
import { fetchPersonGroups } from '../../actions/person'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from './WithRouter'
import moment from 'moment-timezone'
import 'moment-duration-format'
import objectIsEmpty from '../../helpers/objectIsEmpty'
import processFleetReportsConsumptionNew from '../../functions/processFleetReportsConsumptionNew'
import formatBytes from '../../helpers/formatBytes'
import { motion } from 'framer-motion'
import { motion_container, motion_item } from '../../helpers/motion'
import { IconNoData, StatsCo2Savings,StatsData, StatsTime, StatsDistance, StatsLpgConsumption, StatsGasPercentage, StatsSavings, IconCalendarInput, StatsCost, IconVehicleSettings, IconHelp, IconPricesPetrol, IconSettings, IconDone, IconWarning } from './Icons'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import Authorization from './Authorization'
import EstimationNote from './EstimationNote'
import Preloader from './Preloader'
import CtaBox from './CtaBox'
import { Tooltip } from 'react-tooltip'


interface CardFleetStatisticsProps {
  auth: any, 
  vehicle: any, 
  person: any, 
  fleetStatisticsFromReportsByMonth: any, 
  groupStatisticsFromReportsByMonth: any, 
  fuelPrices: any,
  fetchPersonGroups: () => Promise<void>,
  fetchFuelPrices: (options: { month: string, group_id: string }) => Promise<void>,
  updateFuelPrices: (options: {month: string, cost_petrol: string, cost_diesel: string, cost_lpg: string, cost_cng: string, group_id: string }) => Promise<void>,
  fetchGroupStatisticsFromReportsNew: (options: {month: string, group: string}) => Promise<void>,
  fetchFleetStatisticsFromReportsNew: (date: string) => Promise<void>,
}


interface CardFleetStatisticsState {
  dataLoaded: boolean,
  dataStatsLoaded: boolean,
  dataGroupsLoaded: boolean,
  groups: any,
  filterGroup: string,
  temp_cost_petrol: string,
  temp_cost_diesel: string,
  temp_cost_lpg: string,
  temp_cost_cng: string,
  cost_petrol: string,
  cost_diesel: string,
  cost_lpg: string,
  cost_cng: string,
  petrol_input: boolean,
  diesel_input: boolean,
  lpg_input: boolean,
  cng_input: boolean,
  consumptionProcessed: boolean,
  vehiclesCount: number,
  filterDate: any,
  todayDate: any,

  VEHICLES_COUNT: number,  
  TOTAL_DISTANCE_KM: number,
  TOTAL_TIME_S: number,
  TOTAL_DATA_KB: number,
  TOTAL_SAVINGS_CO2_KG: number,
  TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number,
  TOTAL_ALTERNATIVE_PERCENT: number,
  TOTAL_ESTIMATED_COST: number,
  TOTAL_ESTIMATED_SAVINGS: number,

  totalData: number,
  totalTime: number,
  totalDistance: number,
  totalCost: number,
  co2Savings: number,
  estimatedSavings: number,
  percentageAlternativeFuel: number,
  alternativeFuelConsumption: number,
  alternativeFuelCost: number,
  defaultFuelConsumption: number,
  defaultFuelCost: number,
  distanceOnAlternativeFuel: number,
  distanceOnDefaultFuel: number,
  estimatedFuelCostWithoutAlternative: number,
  potentialSavings: number,
  timeOnAlternativeFuel: number,
  timeOnDefaultFuel: number,
  
  reportsProcessed: boolean,
}


export class CardFleetStatistics extends React.Component<CardFleetStatisticsProps, CardFleetStatisticsState> {

  state = {
    dataLoaded: false,
    dataStatsLoaded: false,
    dataGroupsLoaded: false,
    groups: [],
    filterGroup: 'all',
    temp_cost_petrol: '0',
    temp_cost_diesel: '0',
    temp_cost_lpg: '0',
    temp_cost_cng: '0',
    cost_petrol: '0',
    cost_diesel: '0',
    cost_lpg: '0',
    cost_cng: '0',
    petrol_input: false,
    diesel_input: false,
    lpg_input: false,
    cng_input: false,
    consumptionProcessed: false,
    vehiclesCount: 0,
    filterDate: new Date(),
    todayDate: new Date(),

    VEHICLES_COUNT: 0,
    TOTAL_DISTANCE_KM: 0,
    TOTAL_TIME_S: 0,
    TOTAL_DATA_KB: 0,
    TOTAL_SAVINGS_CO2_KG: 0,
    TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: 0,
    TOTAL_ALTERNATIVE_PERCENT: 0,
    TOTAL_ESTIMATED_COST: 0,
    TOTAL_ESTIMATED_SAVINGS: 0,

    totalData: 0,
    totalTime: 0,
    totalDistance: 0,
    totalCost: 0,
    co2Savings: 0,
    estimatedSavings: 0,
    percentageAlternativeFuel: 0,
    alternativeFuelConsumption: 0,
    alternativeFuelCost: 0,
    defaultFuelConsumption: 0,
    defaultFuelCost: 0,
    distanceOnAlternativeFuel: 0,
    distanceOnDefaultFuel: 0,
    estimatedFuelCostWithoutAlternative: 0,
    potentialSavings: 0,
    timeOnAlternativeFuel: 0,
    timeOnDefaultFuel: 0,
    
    reportsProcessed: false,
  }


  componentDidMount() {
    if(this.props.auth.authorized.vehicles === true) {
      this.fetchPersonGroupsAndGetData()
    }
  }


  fetchPersonGroupsAndGetData = async () => {
    await this.fetchPersonGroups();
    await this.fetchFuelPrices({ month: moment(this.state.todayDate).format('YYYY-MM'), group_id: this.state.filterGroup });
    await this.loadFleetStatisticsFromReports(this.state.filterDate, this.state.filterGroup);
  }


  fetchPersonGroups = async () => {
    await this.props.fetchPersonGroups();
    if(this.props.person.status === fetchStates.success) {
      const { groups } = this.props.person.person;
      if(groups.length > 0) {
        let filterGroup = 'all';
        if(groups.length === 1) {
          filterGroup = groups[0].group_id;
        }     
        this.setState({ groups, filterGroup, dataGroupsLoaded: true });
      } else {
        this.setState({ dataLoaded: true, dataGroupsLoaded: false });
      }
    }
  }


  fetchFuelPrices = async (options: { month: string, group_id: string }) => {
    const { month, group_id } = options;
    await this.props.fetchFuelPrices({ month, group_id });
    if(this.props.vehicle.status === fetchStates.success) {
      const { cost_diesel, cost_petrol, cost_lpg, cost_cng } = this.props.vehicle.fuelPrices
      this.setState({
        cost_diesel,
        cost_petrol,
        cost_lpg,
        cost_cng,
      })
    }
  }


  loadFleetStatisticsFromReports = (date: any, group: string) => {
    this.setState({ dataLoaded: false, dataStatsLoaded: false })
    if(group === 'all') {
      this.props.fetchFleetStatisticsFromReportsNew(moment(date).format('YYYY-MM'))
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          const { fleetStatisticsFromReportsByMonthNew } = this.props.vehicle
          if(objectIsEmpty(fleetStatisticsFromReportsByMonthNew)) {
            this.setState({ dataLoaded: true, dataStatsLoaded: false })
          } else {
            this.updateState(fleetStatisticsFromReportsByMonthNew)
          }
        }
      })
    } else {
      this.props.fetchGroupStatisticsFromReportsNew({ month: moment(date).format('YYYY-MM'), group })
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          const { groupStatisticsFromReportsByMonthNew } = this.props.vehicle
          if(objectIsEmpty(groupStatisticsFromReportsByMonthNew)) {
            this.setState({ dataLoaded: true, dataStatsLoaded: false })
          } else {
            this.updateState(groupStatisticsFromReportsByMonthNew)
          }
        }
      })
    }
  }


  updateState = (reports: any) => {
    const { cost_petrol, cost_diesel, cost_lpg, cost_cng } = this.state;
    const fuel_prices = { cost_petrol, cost_diesel, cost_lpg, cost_cng };
    if(reports) {
      const objectProcessFleetReportsConsumption = processFleetReportsConsumptionNew({ reports, fuel_prices });
      const { VEHICLES_COUNT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_DATA_KB, TOTAL_SAVINGS_CO2_KG, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_PERCENT, TOTAL_ESTIMATED_COST, TOTAL_ESTIMATED_SAVINGS } = objectProcessFleetReportsConsumption;
      this.setState({ VEHICLES_COUNT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_DATA_KB, TOTAL_SAVINGS_CO2_KG,  TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_PERCENT, TOTAL_ESTIMATED_COST, TOTAL_ESTIMATED_SAVINGS, dataLoaded: true, dataStatsLoaded: true, });

    } else {
      this.setState({ dataLoaded: true, dataStatsLoaded: false })
    }

  }



  /* FUNCTIONS TO HANDLE FILTER */
  updateFilterDate = (date: Date) => {
    this.setState({ filterDate: date });
    this.fetchFuelPrices({ month: moment(date).format('YYYY-MM'), group_id: this.state.filterGroup });
    this.loadFleetStatisticsFromReports(date, this.state.filterGroup);
  }


  updateFilterGroup = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ filterGroup: e.target.value });
    this.fetchFuelPrices({ month: moment(this.state.filterDate).format('YYYY-MM'), group_id: e.target.value });
    this.loadFleetStatisticsFromReports(this.state.filterDate, e.target.value);
  }


  /* FUNCTIONS TO MANAGE PRICES OF THE FUEL */
  handleChangePetrol = () => {
    this.setState({ petrol_input: true })
    this.setState({ temp_cost_petrol: this.state.cost_petrol })
  }


  handleUpdatePetrol = () => {
    this.setState({ petrol_input: false })
    const { groups, cost_petrol, cost_diesel, cost_lpg, cost_cng, filterGroup } = this.state
    const month = moment(this.state.filterDate).format('YYYY-MM')
    if(groups.length === 1) {
      this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: 'all' })
    }
    this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: filterGroup })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        this.loadFleetStatisticsFromReports(this.state.filterDate, this.state.filterGroup)
      }
    })
  }


  handleValueChangePetrol = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if(e.target.value.match(/^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
      this.setState({ temp_cost_petrol: e.target.value })
      this.setState({ cost_petrol: e.target.value })
    } else {
      this.setState({ temp_cost_petrol: '' })
      this.setState({ cost_petrol: '0' })
    }
  }


  handleChangeDiesel = () => {
    this.setState({ diesel_input: true })
    this.setState({ temp_cost_diesel: this.state.cost_diesel })
  }


  handleUpdateDiesel = () => {
    this.setState({ diesel_input: false })
    const { groups, cost_petrol, cost_diesel, cost_lpg, cost_cng, filterGroup } = this.state
    const month = moment(this.state.filterDate).format('YYYY-MM')
    if(groups.length === 1) {
      this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: 'all' })
    }
    this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: filterGroup })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        this.loadFleetStatisticsFromReports(this.state.filterDate, this.state.filterGroup)
      }
    })
  }


  handleValueChangeDiesel = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if(e.target.value.match(/^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
      this.setState({ temp_cost_diesel: e.target.value })
      this.setState({ cost_diesel: e.target.value })
    } else {
      this.setState({ temp_cost_diesel: '' })
      this.setState({ cost_diesel: '0' })
    }
  }


  handleChangeLpg = () => {
    this.setState({ lpg_input: true })
    this.setState({ temp_cost_lpg: this.state.cost_lpg })
  }


  handleUpdateLpg = () => {
    this.setState({ lpg_input: false })
    const { groups, cost_petrol, cost_diesel, cost_lpg, cost_cng, filterGroup } = this.state
    const month = moment(this.state.filterDate).format('YYYY-MM')
    if(groups.length === 1) {
      this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: 'all' })
    }
    this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: filterGroup })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        this.loadFleetStatisticsFromReports(this.state.filterDate, this.state.filterGroup)
      }
    })
  }


  handleValueChangeLpg = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if(e.target.value.match(/^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
      this.setState({ cost_lpg: e.target.value })
      this.setState({ temp_cost_lpg: e.target.value })
    } else {
      this.setState({ temp_cost_lpg: '' })
      this.setState({ cost_lpg: '0' })
    }
  }


  handleChangeCng = () => {
    this.setState({ cng_input: true })
    this.setState({ temp_cost_cng: this.state.cost_cng })
  }


  handleUpdateCng = () => {
    this.setState({ cng_input: false })
    const { groups, cost_petrol, cost_diesel, cost_lpg, cost_cng, filterGroup } = this.state
    const month = moment(this.state.filterDate).format('YYYY-MM')
    if(groups.length === 1) {
      this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: 'all' })
    }
    this.props.updateFuelPrices({ month, cost_petrol, cost_diesel, cost_lpg, cost_cng, group_id: filterGroup })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        this.loadFleetStatisticsFromReports(this.state.filterDate, this.state.filterGroup)
      }
    })
  }


  handleValueChangeCng = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if(e.target.value.match(/^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
      this.setState({ cost_cng: e.target.value })
      this.setState({ temp_cost_cng: e.target.value })
    } else {
      this.setState({ temp_cost_cng: '' })
      this.setState({ cost_cng: '0' })
    }
  }





  render() {

    const { currency } = this.props.auth;
    const { filterDate, todayDate, dataLoaded, dataStatsLoaded, dataGroupsLoaded, groups, filterGroup, petrol_input, diesel_input, lpg_input, cng_input, temp_cost_petrol, temp_cost_diesel, temp_cost_lpg, temp_cost_cng, cost_petrol, cost_diesel, cost_lpg, cost_cng, VEHICLES_COUNT, TOTAL_DATA_KB, TOTAL_ALTERNATIVE_PERCENT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ESTIMATED_SAVINGS, TOTAL_ESTIMATED_COST, TOTAL_SAVINGS_CO2_KG } = this.state;

    return (
      <Authorization requiresAuth="vehicles">
      <div className="card animation--slide-in-from-bottom">
        <div className="card__header">
          <span>
            <h3 className="card__header-title">
              {t(210)}
            </h3>
          </span>
        </div>
        <div className="card__body card__body--wrapper-medium">


          {
            dataGroupsLoaded === false ? (
              <CtaBox padding={false} type="warning" title={t(4278)} icon={<IconNoData size={54} color="#ffffff" />}>
                {t(2490)}
              </CtaBox>
            ) : (
              <>
              <div className="vehicle-savings-filter vehicle-savings-filter--extended">
                <span className="filter_filter-icon">
                  <IconCalendarInput />
                </span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDate}
                    selectsStart
                    onChange={this.updateFilterDate}
                    dateFormat='yyyy-MM'
                    maxDate={todayDate}
                    minDate={new Date(2021, 0, 1, 0, 0, 0, 0)}
                    required={true}
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </span>
                <span>
                  {t(226)}
                </span>
                <span className="filter_filter-select filter-select__group">
                  <select 
                      name="group" 
                      value={filterGroup}
                      onChange={(e) => this.updateFilterGroup(e)}
                    > 
                      { groups.length > 1 && <option key={1} value="all">{t(1186)}</option> }
                      { groups.map((group: { group_id: number, group_title: string}) => (<option key={group.group_id} value={group.group_id}>{group.group_title} {t(1151)}</option>))}
                  </select>
                </span>
              </div>


          {
            dataLoaded === false ? (
              <Preloader type="preloader-center" />
            ) : (
              dataStatsLoaded === false ? (

                  <CtaBox padding={false} type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                    {t(4275)} <em>{moment(filterDate).format('YYYY/MM')}</em>. {t(4276)}. 
                  </CtaBox>


              ) : (
                <> 

                  {
                    TOTAL_DISTANCE_KM === 0 && (
                      <div className="msg msg--info"><IconWarning color="#ffffff" />No data were generated for this month yet. Try previous month.</div>
                    )
                  }


                  {
                    (Number(cost_lpg) > Number(cost_petrol) || Number(cost_lpg) > Number(cost_diesel) || Number(cost_cng) > Number(cost_petrol) || Number(cost_cng) > Number(cost_diesel)) && (
                      <div className="msg msg--danger"><IconWarning color="#ffffff" />{t(2489)}</div>
                    )
                  }

                  <motion.div 
                    className="statistics"
                    variants={motion_container}
                    initial="hidden"
                    animate="visible"
                  >


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <IconVehicleSettings size={120} />
                      </div>
                      <Tooltip anchorId={`tooltip-1`} />
                      <span className="statistics-card__help" id={`tooltip-1`} data-tooltip-content={t(2488)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        {VEHICLES_COUNT}
                      </div>

                      <div className="statistics-card__title">
                        {t(2479)}
                      </div>
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsGasPercentage />
                      </div>
                      <Tooltip anchorId={`tooltip-2`} />
                      <span className="statistics-card__help" id={`tooltip-2`} data-tooltip-content={t(2480)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {      'statistics-card__value--good': TOTAL_ALTERNATIVE_PERCENT > 89,
                      'statistics-card__value--neutral': TOTAL_ALTERNATIVE_PERCENT > 50 && TOTAL_ALTERNATIVE_PERCENT < 90,
                      'statistics-card__value--bad': TOTAL_ALTERNATIVE_PERCENT < 51,
                      })}>
                        { Math.round(TOTAL_ALTERNATIVE_PERCENT * 10) / 10 }
                        <span className="statistics-card__value-label">%</span>
                      </div>

                      <div className="statistics-card__title">
                        {t(2477)}
                      </div>
                    </motion.div>


                    <motion.div 
                        variants={motion_item}
                        className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsSavings />
                      </div>
                      <Tooltip anchorId={`tooltip-3`} />
                      <span className="statistics-card__help" id={`tooltip-3`} data-tooltip-content={t(2481)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {      
                        'statistics-card__value--good': TOTAL_ESTIMATED_SAVINGS > 0,
                        'statistics-card__value--bad': TOTAL_ESTIMATED_SAVINGS === 0,
                      })}>
                        { Math.trunc(TOTAL_ESTIMATED_SAVINGS) }
                        <span className="statistics-card__value-label">{currency}</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2191)}<sup>*</sup>
                      </div>
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsCost />
                      </div>
                      <Tooltip anchorId={`tooltip-4`} />
                      <span className="statistics-card__help" id={`tooltip-4`} data-tooltip-content={t(2482)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        { Math.trunc(TOTAL_ESTIMATED_COST) }
                        <span className="statistics-card__value-label">{currency}</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2474)}<sup>*</sup>
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsLpgConsumption />
                      </div>
                      <Tooltip anchorId={`tooltip-5`} />
                      <span className="statistics-card__help" id={`tooltip-5`} data-tooltip-content={t(2483)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {
                      'statistics-card__value--bad':  TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L === 0,
                      })}>
                        { Math.trunc(TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L) }
                        <span className="statistics-card__value-label">l</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2475)}
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsDistance />
                      </div>
                      <Tooltip anchorId={`tooltip-6`} />
                      <span className="statistics-card__help" id={`tooltip-6`} data-tooltip-content={t(2484)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        { Math.trunc(TOTAL_DISTANCE_KM) }
                        <span className="statistics-card__value-label">km</span>
                      </div>

                      <div className="statistics-card__title">
                        {t(2190)}
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsTime />
                      </div>
                      <Tooltip anchorId={`tooltip-7`} />
                      <span className="statistics-card__help" id={`tooltip-7`} data-tooltip-content={t(2485)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        { TOTAL_TIME_S === 0 ? '00:00' : moment.duration(TOTAL_TIME_S, 'seconds').format("hh:mm") }
                      </div>

                      <div className="statistics-card__title">
                        {t(1121)}
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsData />
                      </div>
                      <Tooltip anchorId={`tooltip-8`} />
                      <span className="statistics-card__help" id={`tooltip-8`} data-tooltip-content={t(2486)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        {formatBytes(TOTAL_DATA_KB * 1024)}
                      </div>
                      <div className="statistics-card__title">
                        {t(2476)}
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsCo2Savings />
                      </div>
                      <Tooltip anchorId={`tooltip-9`} />
                      <span className="statistics-card__help" id={`tooltip-9`} data-tooltip-content={t(2487)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {      
                        'statistics-card__value--good': TOTAL_SAVINGS_CO2_KG > 0,
                        'statistics-card__value--bad': TOTAL_SAVINGS_CO2_KG === 0,
                      })}>
                        { Math.trunc(TOTAL_SAVINGS_CO2_KG) }
                        <span className="statistics-card__value-label">kg</span>
                      </div>

                      <div className="statistics-card__title">
                        {t(2231)}
                      </div>
                    </motion.div>



                  </motion.div>
                  <EstimationNote />
                </>
              )
            )
          }




            <motion.div 
              className="fuel-prices"
              variants={motion_container}
              initial="hidden"
              animate="visible"
            >
  
              <motion.div 
                variants={motion_item}
                className="fuel-price fuel-price--petrol"
              >
                <div className="fuel-price__title">PETROL</div>
                <div className="fuel-price__icon">
                  <IconPricesPetrol size={120} />
                </div>
  
                  <span
                    className={classnames('fuel-price__value statistics-card__value--petrol', {
                      'fuel-price__value--editing': petrol_input === true,
                    })}
                  >
                    {
                      petrol_input === false ? (
                        <>{cost_petrol}<span className="fuel-price__tinytitle">{currency}</span></>
                      ) : (
                        <input type="text" className="fuel-price__input" value={temp_cost_petrol} onChange={e => this.handleValueChangePetrol(e)} autoFocus={true} />
                      )
                    }
  
                  </span>
                  {
                    petrol_input === false ? (
                      <span className="fuel-price__link btn--circular" onClick={this.handleChangePetrol}>
                        <IconSettings color="#ffffff" />
                      </span>
                    ) : (
                      <span className="fuel-price__link btn--circular" onClick={this.handleUpdatePetrol}>
                        <IconDone color="#ffffff" />
                      </span>
                    )
                  }
              </motion.div>
  
              <motion.div 
                variants={motion_item}
                className="fuel-price fuel-price--diesel"
              >
                <div className="fuel-price__title">DIESEL</div>
                <div className="fuel-price__icon">
                  <IconPricesPetrol size={120} />
                </div>
  
                  <span
                    className={classnames('fuel-price__value statistics-card__value--diesel', {
                      'fuel-price__value--editing': diesel_input === true,
                    })}
                  >
                    {
                      diesel_input === false ? (
                        <>{cost_diesel}<span className="fuel-price__tinytitle">{currency}</span></>
                      ) : (
                        <input type="text" className="fuel-price__input" value={temp_cost_diesel} onChange={e => this.handleValueChangeDiesel(e)} autoFocus={true} />
                      )
                    }
  
                  </span>
                  {
                    diesel_input === false ? (
                      <span className="fuel-price__link btn--circular" onClick={this.handleChangeDiesel}>
                        <IconSettings color="#ffffff" />
                      </span>
                    ) : (
                      <span className="fuel-price__link btn--circular" onClick={this.handleUpdateDiesel}>
                        <IconDone color="#ffffff" />
                      </span>
                    )
                  }
              </motion.div>
  
              <motion.div 
                variants={motion_item}
                className="fuel-price fuel-price--lpg"
              >
                <div className="fuel-price__title">LPG</div>
                <div className="fuel-price__icon">
                  <IconPricesPetrol size={120} />
                </div>
  
                <span
                    className={classnames('fuel-price__value statistics-card__value--lpg', {
                      'fuel-price__value--editing': lpg_input === true,
                    })}
                  >
                    {
                      lpg_input === false ? (
                        <>{cost_lpg}<span className="fuel-price__tinytitle">{currency}</span></>
                      ) : (
                        <input type="text" className="fuel-price__input" value={temp_cost_lpg} onChange={e => this.handleValueChangeLpg(e)} autoFocus={true} />
                      )
                    }
  
                  </span>
                  {
                    lpg_input === false ? (
                      <span className="fuel-price__link btn--circular" onClick={this.handleChangeLpg}>
                        <IconSettings color="#ffffff" />
                      </span>
                    ) : (
                      <span className="fuel-price__link btn--circular" onClick={this.handleUpdateLpg}>
                        <IconDone color="#ffffff" />
                      </span>
                    )
                  }
              </motion.div>
  
              <motion.div 
                variants={motion_item}
                className="fuel-price fuel-price--lpg"
              >
                <div className="fuel-price__title">CNG</div>
                <div className="fuel-price__icon">
                  <IconPricesPetrol size={120} />
                </div>
  
                <span
                    className={classnames('fuel-price__value statistics-card__value--cng', {
                      'fuel-price__value--editing': cng_input === true,
                    })}
                  >
                    {
                      cng_input === false ? (
                        <>{cost_cng}<span className="fuel-price__tinytitle">{currency}</span></>
                      ) : (
                        <input type="text" className="fuel-price__input" value={temp_cost_cng} onChange={e => this.handleValueChangeCng(e)} autoFocus={true} />
                      )
                    }
  
                  </span>
                  {
                    cng_input === false ? (
                      <span className="fuel-price__link btn--circular" onClick={this.handleChangeCng}>
                        <IconSettings color="#ffffff" />
                      </span>
                    ) : (
                      <span className="fuel-price__link btn--circular" onClick={this.handleUpdateCng}>
                        <IconDone color="#ffffff" />
                      </span>
                    )
                  }
              </motion.div>
  
              </motion.div>


          </>
            )
          }
        </div>
      </div>
      </Authorization>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, person }:RootState) => ({ auth, vehicle, person }),
  { fetchFuelPrices, updateFuelPrices, fetchPersonGroups, fetchFleetStatisticsFromReportsNew, fetchGroupStatisticsFromReportsNew }
)(CardFleetStatistics)))
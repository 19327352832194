import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllStations } from '../../actions/station'
import { fetchAllCompetitors } from '../../actions/competitor'
import { fetchAllRefuels } from '../../actions/refuel'
import { checkAuth } from '../../actions/auth'
import Preloader from './Preloader'
import { Link } from 'react-router-dom'
import Card from './Card'
import { GoogleMap, Marker } from '@react-google-maps/api'
import markerStation from'../../img/marker-station.png'
import markerCompetitor from'../../img/marker-competitor.png'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import t from '../translation/translate'
import Authorization from './Authorization'


interface CardStationsProps {
  auth: any, 
  station: any, 
  competitor: any,
  checkAuth: () => Promise<any>,
  fetchAllStations: (options: {limit: number, skip: number}) => Promise<any>,
  fetchAllCompetitors: (options: {limit: number, skip: number}) => Promise<any>,
}


interface CardStationsState {
  lat: number,
  lng: number,
  zoom: number
}


export class CardStations extends React.Component<CardStationsProps, CardStationsState> {


  state = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    zoom: DEFAULT_ZOOM
  }

  componentDidMount() {
    if(this.props.auth.authorized.stations === true) {
      this.props.fetchAllStations({ limit: 100, skip: 0 });
    }
    if(this.props.auth.authorized.competitors === true) {
      this.props.fetchAllCompetitors({ limit: 100, skip: 0 });
    }
    this.props.checkAuth()
    .then(() => {
      const { lat, lng } = this.props.auth;

      this.setState({
          lat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          lng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
      });
    })
  }

  render() {

    const { stationsCount, stations } = this.props.station;
    const { competitorsCount, competitors } = this.props.competitor;
    const { lat, lng } = this.state;

    if(this.props.station.status === 'fetching' || this.props.competitor.status === 'fetching') {
      return <Preloader type="fullscreen" />
    } 


    if(stationsCount === 0 && competitorsCount === 0) {
      return null
    }


    return (
      <Authorization requiresAuth="stations">


          <Card 
            button={false}
            title={`${t(2)} (${stationsCount}) / ${t(2016)} (${competitorsCount})`}
            time={false}
            animate
          >

            <GoogleMap
              center={{ lat, lng }}
              zoom={ 7 }
            >
              { 
                stations !== undefined && stations.map((station: { lat: number, lng: number, station_id: number, custom_id: string, refueled: number}) => {
                  const lat = station.lat;
                  const lng = station.lng;
                  return (
                    <span key={station.station_id}>
                      <Marker
                        position={{
                          lat,
                          lng
                        }}
                        icon={
                          // @ts-expect-error
                          new window.google.maps.MarkerImage(
                            markerStation,
                            null, /* size is determined at runtime */
                            null, /* origin is 0,0 */
                            null, /* anchor is bottom center of the scaled image */
                            new window.google.maps.Size(48, 48)
                          )
                        }
                      >
                      </Marker>
                      {
                        /*
                          <InfoBox
                            // @ts-expect-error
                            position={{
                              lat,
                              lng
                            }}
                            // @ts-expect-error
                            options={{ closeBoxURL: ``, enableEventPropagation: true,
                            pixelOffset: new window.google.maps.Size(-16, -110) }}
                          >
                            <div className='marker-label-wrapper'>
                              <div className='marker-label'>
                                <div className='marker-label-title'>{station.custom_id}</div>
                                <div className='marker-label-text'>{t(21)}: {station.refueled}</div>
                              </div>
                              <span className='marker-label-pointer'></span>
                            </div>
                          </InfoBox>
                        */
                      }

                    </span> 
                  )
                })
              }

              { 
                competitors !== undefined && competitors.map((competitor: { station_id: number, lat: number, lng: number}) => {
                  return <Marker
                            key={competitor.station_id}
                            position={{
                              lat: competitor.lat,
                              lng: competitor.lng
                            }}
                            icon={
                              // @ts-expect-error
                              new window.google.maps.MarkerImage(
                                markerCompetitor,
                                null, /* size is determined at runtime */
                                null, /* origin is 0,0 */
                                null, /* anchor is bottom center of the scaled image */
                                new window.google.maps.Size(48, 48)
                              )
                            }
                          />
                })
              }

              <div className="map-constraints-container" />
            </GoogleMap>

            <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>
          </Card>

        </Authorization>
      );
    };
};


export default connect(
  ({ auth, station, competitor }: RootState) => ({ auth, station, competitor }),
  { fetchAllRefuels, fetchAllStations, fetchAllCompetitors, checkAuth }
)(CardStations);
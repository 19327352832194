import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllDistributorAccountGroups } from '../../actions/account'
import { addNewPerson } from '../../actions/person'
import { fetchAllGroups } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LANGUAGE, TIMEZONE } from '../../config'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { IconVisibility, IconVisibilityOff, IconClose } from '../partials/Icons'
import Auth from '../partials/Auth'
import PasswordStrengthMeter from '../partials/PasswordStrengthMeter'
import momentTimezones from '../../helpers/momentTimezones'
import languageTranslations from '../../helpers/languagesTranslations'
import Switch from '../partials/Switch'
import Preloader from '../partials/Preloader'
import authByRole from '../../helpers/authByRole'


interface UsersAddProps {
  router: { navigate: (to: string) => any },
  auth: any,
  person: any,
  group: any,
  account: any,
  fetchAllGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchAllDistributorAccountGroups: (options:{limit: number, skip: number}) => Promise<any>,
  addNewPerson: ( 
    person: { 
      authorized: any,
      role: number, 
      email: string, 
      password: string,
      name: string, 
      phone: string,
      notify: boolean,
      language: string,
      timezone: string,
      group_ids: Array<string>,
    } 
  ) => Promise<any>,
}


interface UsersAddState {
  role: number,
  authorized: { 
    vehicles: boolean,
    vehicle_now: boolean,
    vehicle_reports: boolean,
    vehicle_fuel_usage: boolean,
    vehicle_routes: boolean,
    vehicle_refuel_history: boolean,
    vehicle_lock_unlock_history: boolean,
    vehicle_diagnostics: boolean,
    vehicle_alerts_settings: boolean,
    vehicle_calendar: boolean,
    vehicle_lock_unlock: boolean,
    vehicle_pairing: boolean,
    vehicle_edit: boolean,
    vehicle_delete: boolean,

    reports: boolean, 
    stations: boolean, 
    competitors: boolean, 
    alerts: boolean, 

    imei: boolean, 
    users: boolean, 
    rfid: boolean, 
    groups: boolean, 
    billing: boolean, 
    modules: boolean, 
    distributor_account_groups: boolean,
    workers: boolean, 
    accounts: boolean, 
    data_map: boolean, 
    checkpoints: boolean, 
    communication: boolean,
    error_log: boolean,
  },
  disabled: {
    vehicles: boolean,
    vehicle_now: boolean, 
    vehicle_reports: boolean,
    vehicle_fuel_usage: boolean,
    vehicle_routes: boolean,
    vehicle_refuel_history: boolean,
    vehicle_lock_unlock_history: boolean,
    vehicle_diagnostics: boolean,
    vehicle_alerts_settings: boolean,
    vehicle_calendar: boolean,
    vehicle_lock_unlock: boolean,
    vehicle_pairing: boolean,
    vehicle_edit: boolean,
    vehicle_delete: boolean,

    reports: boolean, 
    stations: boolean, 
    competitors: boolean, 
    alerts: boolean,

    users: boolean, 
    groups: boolean, 
    billing: boolean,  

    rfid: boolean, 
    checkpoints: boolean,

    imei: boolean,
    accounts: boolean, 
    modules: boolean, 
    distributor_account_groups: boolean,
    data_map: boolean, 
    communication: boolean,
    workers: boolean,
    error_log: boolean,
  },
  email: string,
  password: string,
  passwordShow: boolean,
  name: string,
  phone: string,
  notify: boolean,
  formSubmitted: boolean,
  language: string,
  timezone: string,
  groups: any,
  selectedGroups: any,
  existingGroups: any,
  distributor_account_groups: Array<string>,
  distributor_group_id: string,
}


export class UsersAdd extends React.Component<UsersAddProps, UsersAddState> {

  state = {
    role: 5,
    authorized: authByRole.user.authorized,
    disabled: authByRole.user.disabled,
    email: '',
    password: '',
    passwordShow: false,
    name: '',
    phone: '',
    notify: true,
    formSubmitted: false,
    language: DEFAULT_LANGUAGE,
    timezone: TIMEZONE,
    groups: [],
    selectedGroups: [],
    existingGroups: [],
    distributor_account_groups: [],
    distributor_group_id: ''
  }


  componentDidMount() {


    this.props.fetchAllDistributorAccountGroups({ limit: 1000, skip: 0 })
    .then(() => {
      if(this.props.account.status === fetchStates.success) {
        const { distributor_account_groups } = this.props.account
        this.setState({ distributor_account_groups })
      }
    });

    this.props.fetchAllGroups({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, groups } = this.props.group
      if(status === fetchStates.success) {
        this.setState({ groups, existingGroups: groups })
      }
    })


    let disabled = Object.assign({}, this.state.disabled)
    const { role } = this.state

    if(disabled && role === 6) {
      disabled = { ...disabled, ...authByRole.user.disabled }
    }

    if(disabled && role === 5) {
      disabled = { ...disabled, ...authByRole.mechanic.disabled }
    }

    if(disabled && role === 4) {
      disabled = { ...disabled, ...authByRole.manager.disabled }
    }

    if(disabled && role === 3) {
      disabled = { ...disabled, ...authByRole.distributor.disabled }
    }

    if(disabled && role === 2) {
      disabled = { ...disabled, ...authByRole.administrator.disabled }
    }

    if(disabled && role === 1) {
      disabled = { ...disabled, ...authByRole.superadministrator.disabled }
    }

    this.setState({ disabled })
  }


  selectRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ role: parseInt(e.target.value) })

    if(e.target.value === '1') {
      this.setState({ 
        authorized: authByRole.superadministrator.authorized,
        disabled: authByRole.superadministrator.disabled,
      });
    }

    if(e.target.value === '2') {
      this.setState({ 
        authorized: authByRole.administrator.authorized,
        disabled: authByRole.administrator.disabled,
      });
    }

    if(e.target.value === '3') {
      this.setState({
        authorized: authByRole.distributor.authorized,
        disabled: authByRole.distributor.disabled,
      });
    }

    if(e.target.value === '4') {
      this.setState({
        authorized: authByRole.manager.authorized,
        disabled: authByRole.manager.disabled,
      });
    }

    if(e.target.value === '5') {
      this.setState({
        authorized: authByRole.mechanic.authorized,
        disabled: authByRole.mechanic.disabled,
      })
    }

    if(e.target.value === '6') {
      this.setState({
        authorized: authByRole.user.authorized,
        disabled: authByRole.user.disabled,
      })
    }


  }


  handleSelectAccountGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ distributor_group_id: e.target.value })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const group_ids = this.state.selectedGroups.map((group:{group_id:string}) => group.group_id)
    const { authorized, role, email, password, name, phone, notify, language, timezone, distributor_group_id } = this.state
    const person = { 
      authorized,
      role, 
      email, 
      password,
      name, 
      phone,
      notify,
      language,
      timezone,
      group_ids,
      distributor_group_id
    }
    this.props.addNewPerson(person)
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.props.router.navigate('/users')
      }
      this.setState({ formSubmitted: false })
    })
  }


  handleSelectGroup = (id: string) => {
    let selectedGroups: any = []
    if(id === '0') {
      selectedGroups = []
    }
    if(id === '1') {
      selectedGroups = this.state.existingGroups
    }
    if(id !== '1' && id !== '0') {
      this.state.groups.filter((group: {group_id:string}) => {
        if(group.group_id === id) {
          selectedGroups.push(...this.state.selectedGroups, group)
        }
        return group.group_id !== id
      })
    }
    this.setState({ selectedGroups })
  }


  handleGroupRemove = (id: string) => {
    const filteredGroups = this.state.selectedGroups.filter((group: { group_id: string }) => {
      if(group.group_id === id) {
        const groups = []
        groups.push(...this.state.groups, group)
        this.setState({ groups })
      }
      return group.group_id !== id
    })
    this.setState({ selectedGroups: filteredGroups })
  }


  render() {

    const { fields, status } = this.props.person;
    const { authorized, disabled, role, email, password, name, phone, notify, language, timezone, passwordShow, formSubmitted, groups, distributor_group_id, distributor_account_groups, selectedGroups } = this.state;
    const creatorRole = this.props.auth.role;

    console.log('fields', fields)

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(102)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="role">
                {t(2039)} ({t(116)})
              </label>
              <select 
                name="role" 
                onChange={e => this.selectRole(e)}
                className={classnames('', { 'input-error': fields && fields.includes('role') })}
              >
                {[1,2,3,4,5,6].includes(creatorRole) && <option value={6}>{t(2428)}</option>}
                {[1,2,3,4,5].includes(creatorRole) && <option value={5}>Mechanic</option>}
                {[1,2,3,4].includes(creatorRole) && <option value={4}>{t(2333)}</option>}
                {[1,2,3].includes(creatorRole) && <option value={3}>Distributor</option>}
                {[1,2].includes(creatorRole) && <option value={2}>{t(2334)}</option>}
                {[1].includes(creatorRole) && <option value={1}>Super Administrator</option>}
              </select>
            </div>

            <Auth requiresRole={[1,2,3]}>
              <div className="form-group">
                <label htmlFor="group_ids">
                  {t(225)} ({t(116)})
                </label>
                <div className="selected-tags">
                  { selectedGroups && selectedGroups.map((group: {group_id: string, group_title: string}) => (
                    <span className="tag tag--small" key={group.group_id}>
                      <span className="tag__title">{group.group_title}</span>
                      <span className="tag__remove" onClick={e => this.handleGroupRemove(group.group_id)}>
                        <IconClose color="#ffffff"/>
                      </span>
                    </span>
                  ))}
                </div>

                <select 
                  name="groups" 
                  onChange={e => this.handleSelectGroup(e.target.value)}
                  className={classnames('', { 'input-error': fields && fields.includes('groups') })}
                >
                  <option key="0">{t(2429)}</option>
                  { groups.length !== 0 && (
                    <option key="1" value="1">--Add all groups--</option>
                  )}
                  { selectedGroups.length > 0 && (
                    <option key="2" value="0">--Remove all groups--</option>
                  )}
                  { 
                    groups.map((group: {group_id: string, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                  }
                </select>

              </div>
            </Auth>

            {
              role === 3 && (
                <Auth requiresRole={[1,2]}>
                  <div className="form-group">
                    <label htmlFor="account_groups">
                      Distrubutor Account Group
                    </label>
                    <select 
                      name="account_groups" 
                      onChange={e => this.handleSelectAccountGroup(e)}
                      className={classnames('', { 'input-error': fields && fields.includes('account_groups') })}
                      value={distributor_group_id}
                    >
                      <option value="">Select group</option>
                      { 
                        distributor_account_groups.map((account_group:{ distributor_group_id: string, distributor_group_title: string }) => <option key={account_group.distributor_group_id} value={account_group.distributor_group_id}>{account_group.distributor_group_title}</option>)
                      }
                    </select>
                  </div>
                </Auth>
              )
            }



            <div className="form-group">
              <label htmlFor="email">
                {t(2041)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="email"
                id="email" 
                value={ email }
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">
                {t(2042)} ({t(116)})
                <span className="password-reveal" onClick={e => this.setState({ passwordShow: !passwordShow })}>
                  { passwordShow === false ? (
                    <IconVisibility password size={18} />
                  ) : (
                    <IconVisibilityOff password size={18} />
                  )}
                </span>
              </label>
              <input 
                type={ passwordShow === false ? 'password' : 'text' } 
                name="password"
                id="password" 
                value={ password }
                onChange={e => this.setState({ password: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('password') })}
              />
              <PasswordStrengthMeter password={password} />
            </div>


            <div className="form-group">
              <label htmlFor="language">
                {t(2043)}
              </label>
              <select 
                name="language" 
                value={language}
                onChange={e => this.setState({ language: e.target.value })}
              >
                {
                  languageTranslations.map((language, index) => (
                    <option key={index} value={language.shortcut}>{language.title}</option>
                  ))
                }
              </select>
            </div>


            <div className="form-group">
              <label htmlFor="timezone">
                {t(2402)}
              </label>
              <select 
                name="timezone" 
                value={timezone}
                onChange={e => this.setState({ timezone: e.target.value })}
              > 
                {momentTimezones.map((item, index) => <option key={index} value={item}>{item}</option>)}
              </select>
            </div>

            <div className="form-group__checkbox form-group__checkbox--large form-group__checkbox--auth">

              <h6 className="checkbox-section-title">App section authorization</h6>
              <div className="checkbox-section">
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicles: !this.state.authorized.vehicles} })}
                  checked={authorized.vehicles}
                  type="checkbox" 
                  disabled = {disabled.vehicles}
                /> 
                  {t(3)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_now: !this.state.authorized.vehicle_now} })}
                  checked={authorized.vehicle_now}
                  type="checkbox" 
                  disabled = {disabled.vehicle_now}
                /> 
                  {t(1187)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_reports: !this.state.authorized.vehicle_reports} })}
                  checked={authorized.vehicle_reports}
                  type="checkbox" 
                  disabled = {disabled.vehicle_reports}
                /> 
                  {t(1188)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_fuel_usage: !this.state.authorized.vehicle_fuel_usage} })}
                  checked={authorized.vehicle_fuel_usage}
                  type="checkbox" 
                  disabled = {disabled.vehicle_fuel_usage}
                /> 
                  {t(1189)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_routes: !this.state.authorized.vehicle_routes} })}
                  checked={authorized.vehicle_routes}
                  type="checkbox" 
                  disabled = {disabled.vehicle_routes}
                /> 
                  {t(1190)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_refuel_history: !this.state.authorized.vehicle_refuel_history} })}
                  checked={authorized.vehicle_refuel_history}
                  type="checkbox" 
                  disabled = {disabled.vehicle_refuel_history}
                /> 
                  {t(1191)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_lock_unlock_history: !this.state.authorized.vehicle_lock_unlock_history} })}
                  checked={authorized.vehicle_lock_unlock_history}
                  type="checkbox" 
                  disabled = {disabled.vehicle_lock_unlock_history}
                /> 
                  {t(1192)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_diagnostics: !this.state.authorized.vehicle_diagnostics} })}
                  checked={authorized.vehicle_diagnostics}
                  type="checkbox" 
                  disabled = {disabled.vehicle_diagnostics}
                /> 
                  {t(1193)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_alerts_settings: !this.state.authorized.vehicle_alerts_settings} })}
                  checked={authorized.vehicle_alerts_settings}
                  type="checkbox" 
                  disabled = {disabled.vehicle_alerts_settings}
                /> 
                  {t(1194)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_calendar: !this.state.authorized.vehicle_calendar} })}
                  checked={authorized.vehicle_calendar}
                  type="checkbox" 
                  disabled = {disabled.vehicle_calendar}
                /> 
                  Vehicle calendar
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_lock_unlock: !this.state.authorized.vehicle_lock_unlock} })}
                  checked={authorized.vehicle_lock_unlock}
                  type="checkbox" 
                  disabled = {disabled.vehicle_lock_unlock}
                /> 
                  {t(1195)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_pairing: !this.state.authorized.vehicle_pairing} })}
                  checked={authorized.vehicle_pairing}
                  type="checkbox" 
                  disabled = {disabled.vehicle_pairing}
                /> 
                  {t(1196)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_edit: !this.state.authorized.vehicle_edit} })}
                  checked={authorized.vehicle_edit}
                  type="checkbox" 
                  disabled = {disabled.vehicle_edit}
                /> 
                  {t(1197)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, vehicle_delete: !this.state.authorized.vehicle_delete} })}
                  checked={authorized.vehicle_delete}
                  type="checkbox" 
                  disabled = {disabled.vehicle_delete}
                /> 
                  {t(1198)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, reports: !this.state.authorized.reports} })}
                  checked={authorized.reports}
                  type="checkbox" 
                  disabled = {disabled.reports}
                /> 
                  {t(5)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, stations: !this.state.authorized.stations} })}
                  checked={authorized.stations}
                  type="checkbox" 
                  disabled = {disabled.stations}
                /> 
                  {t(2)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, competitors: !this.state.authorized.competitors} })}
                  checked={authorized.competitors}
                  type="checkbox" 
                  disabled = {disabled.competitors}
                /> 
                  {t(1200)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, alerts: !this.state.authorized.alerts} })}
                  checked={authorized.alerts}
                  type="checkbox" 
                  disabled = {disabled.alerts}  
                /> 
                  {t(6)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, users: !this.state.authorized.users} })}
                  checked={authorized.users}
                  type="checkbox" 
                  disabled = {disabled.users}
                /> 
                  {t(4)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, groups: !this.state.authorized.groups} })}
                  checked={authorized.groups}
                  type="checkbox"  
                  disabled = {disabled.groups}
                /> 
                  {t(215)}
              </label>
              <label>
                <input 
                  onChange={e => this.setState({ authorized: {...authorized, billing: !this.state.authorized.billing} })}
                  checked={authorized.billing}
                  type="checkbox" 
                  disabled = {disabled.billing}
                /> 
                  {t(139)}
              </label>
              </div>


              {
                (this.props.auth.role === 1 || this.props.auth.role === 2) && (
                  <>
                    <h6 className="checkbox-section-title">{t(1199)}</h6>
                    <div className="checkbox-section">
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, imei: !this.state.authorized.imei} })}
                          checked={authorized.imei}
                          type="checkbox" 
                          disabled = {disabled.imei}
                        /> 
                          IMEI
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, accounts: !this.state.authorized.accounts} })}
                          checked={authorized.accounts}
                          type="checkbox" 
                          disabled = {disabled.accounts}
                        /> 
                          {t(189)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, modules: !this.state.authorized.modules} })}
                          checked={authorized.modules}
                          type="checkbox"  
                          disabled = {disabled.modules}
                        /> 
                          {t(7)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, distributor_account_groups: !this.state.authorized.distributor_account_groups} })}
                          checked={authorized.distributor_account_groups}
                          type="checkbox"  
                          disabled = {disabled.distributor_account_groups}
                        /> 
                          Distributor account groups
                      </label>
                    </div>
                  </>
                )
              }



              {
                this.props.auth.role === 1 && (
                  <>
                    <h6 className="checkbox-section-title">Super Admin section autorization</h6>
                    <div className="checkbox-section">
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, data_map: !this.state.authorized.data_map} })}
                          checked={authorized.data_map}
                          type="checkbox" 
                          disabled = {disabled.data_map}
                        /> 
                          {t(8)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, communication: !this.state.authorized.communication} })}
                          checked={authorized.communication}
                          type="checkbox" 
                          disabled = {disabled.communication}
                        /> 
                          {t(10)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, workers: !this.state.authorized.workers} })}
                          checked={authorized.workers}
                          type="checkbox" 
                          disabled = {disabled.workers}
                        /> 
                          {t(227)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, error_log: !this.state.authorized.error_log} })}
                          checked={authorized.error_log}
                          type="checkbox" 
                          disabled = {disabled.error_log}
                        /> 
                          Error Log
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, checkpoints: !this.state.authorized.checkpoints} })}
                          checked={authorized.checkpoints}
                          type="checkbox"  
                          disabled = {disabled.checkpoints}
                        /> 
                          {t(203)}
                      </label>
                      <label>
                        <input 
                          onChange={e => this.setState({ authorized: {...authorized, rfid: !this.state.authorized.rfid} })}
                          checked={authorized.rfid}
                          type="checkbox" 
                          disabled = {disabled.rfid}
                        /> 
                          RFID
                      </label>
                    </div>
                  </>
                )
              }

            </div>

            <div className="form-group">
              <label htmlFor="name">
                {t(2040)}
              </label>
              <input 
                type="text" 
                name="name"
                id="name" 
                value={ name }
                onChange={e => this.setState({ name: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('name') })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">
                {t(2019)}
              </label>
              <input 
                type="text" 
                name="phone"
                id="phone" 
                value={ phone }
                onChange={e => this.setState({ phone: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('phone') })}
              />
            </div>


            <div className="form-group__switch form-group__switch--large">
              <label onClick={e => this.setState({ notify: !notify })}>
                <Switch switchState={notify} />
                <span className="label-title">
                  {t(2044)}
                </span>
              </label>
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value={t(102)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>

          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ person, auth, group, account }: RootState) => ({ person, auth, group, account }),
  { addNewPerson, fetchAllGroups, fetchAllDistributorAccountGroups }
)(UsersAdd)))
const API_ADDRESS = '/api';
const GOOGLE_MAPS_API_KEY = 'AIzaSyBwkVlaUzhe_1C9rMzGzatKSa-5vZXubh4';
const COOKIES_CONSENT_EXPIRE_DAYS = 14;
const TERMS_CONDITIONS_CONSENT_EXPIRE_DAYS = 14;
const COOKIES_LANGUAGE_EXPIRE_DAYS = 30;
const PHRASES_NUMBERING_EXPIRE_DAYS = 30;
const DEFAULT_LAT = 53.14223009453262;
const DEFAULT_LNG = 23.20636412816221;
const DEFAULT_ZOOM = 14;
const DEFAULT_RANGE = 50;
const DEFAULT_CURRENCY = 'PLN';
const DEFAULT_COST_CNG = 3.33;
const DEFAULT_COST_GAS = 1.93;
const DEFAULT_COST_PETROL = 4.24;
const DEFAULT_COST_DIESEL = 4.35;
const DEFAULT_LANGUAGE = 'en';
const TIMEZONE = 'Europe/Warsaw';
const VERSION = '6.0.0';

const ADDRESS_LINE1 = 'AC S.A.'; 
const ADDRESS_LINE2 = 'ul. 42 Pułku Piechoty 50'; 
const ADDRESS_LINE3 = '15-181 Bialystok'; 
const ADDRESS_LINE4 = 'Polska';
const ADDRESS_LINE5 = '+48 85 743 81 17';
const NIP = '966-13-19-418'
const REGON = '050643816';


export { 
  API_ADDRESS,
  GOOGLE_MAPS_API_KEY,
  COOKIES_CONSENT_EXPIRE_DAYS,
  TERMS_CONDITIONS_CONSENT_EXPIRE_DAYS,
  COOKIES_LANGUAGE_EXPIRE_DAYS,
  PHRASES_NUMBERING_EXPIRE_DAYS,
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_ZOOM,
  DEFAULT_RANGE,
  DEFAULT_CURRENCY, 
  DEFAULT_COST_GAS, 
  DEFAULT_COST_CNG,
  DEFAULT_COST_PETROL, 
  DEFAULT_COST_DIESEL,
  DEFAULT_LANGUAGE,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  ADDRESS_LINE3,
  ADDRESS_LINE4,
  ADDRESS_LINE5,
  NIP,
  REGON,
  TIMEZONE,
  VERSION
};
import React from 'react'
import { RootState } from '../../reducers'
import { fetchAllGroups } from '../../actions/group'
import { fetchBilling, fetchVehiclesBillingSearch } from '../../actions/vehicle'
import { connect } from 'react-redux'
import BillingPage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import moment from 'moment-timezone'
import { TIMEZONE } from '../../config'
import ModalPayVehicles from '../partials/ModalPayVehicles'
import Modal from '../partials/Modal'
import TableFooter from '../partials/TableFooter'
import { IconDropUp, IconDropDown } from '../partials/Icons'
import { Tooltip } from 'react-tooltip'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface SubscriptionProps {
  router: { location: {search: string}, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  group: any,
  fetchAllGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchBilling: (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => Promise<any>,
  fetchVehiclesBillingSearch: (options: { search: string, limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => Promise<any>,
}


interface SubscriptionState {
  vehiclesBilling: any,
  vehiclesBillingCount: number,
  currentPage: number,
  perPage: number,
  search: string,
  param: string,
  checkedArray: any,
  checkedAll: any,
  paramValue: boolean,
  timezone: string,
  timeNow: any,
  timeOverdue: any,
  showModal: boolean,
  modalType: any,
  groups: Array<any>,
  group_id: string,
  dataLoaded: boolean,
}


export class Subscription extends React.Component<SubscriptionProps, SubscriptionState> {

  state = {
    vehiclesBilling: [],
    vehiclesBillingCount: 0,
    currentPage: 0,
    perPage: 10,
    search: '',
    param: 'ignition',
    checkedArray: [],
    checkedAll: [],
    paramValue: true,
    timezone: TIMEZONE,
    timeNow: moment().format('X'),
    timeOverdue: moment().subtract(2, 'weeks').format('X'),
    showModal: false,
    modalType: '',
    groups: [],
    group_id: 'all',
    dataLoaded: false
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id }); 

    this.props.fetchAllGroups({ limit: 1000, skip: 0 })
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        this.setState({ groups: this.props.group.groups  })
      }
    })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/subscription?page=0&perPage=${e.target.value}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/subscription?page=${prevPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/subscription?page=${nextPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, param: string, paramValue: boolean, group_id: string }) => {
    const { perPage, currentPage, param, paramValue, group_id } = options
    let reloadParam = this.state.param
    let reloadParamValue = this.state.paramValue
    if(param) { reloadParam = param }
    if(paramValue) { reloadParamValue = paramValue }
    if(this.state.search === '') {
      this.props.fetchBilling({ limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue, group_id }); 
    } else {
      this.props.fetchVehiclesBillingSearch({ search: this.state.search, limit: perPage, skip: perPage * currentPage, param: 'custom_id', paramValue: true, group_id: 'all' }); 
    }
  }


  handleSearch = (search: string) => {
    this.props.router.navigate(`/subscription?search=${search}`)
    if(search !== '') {
      return this.props.fetchVehiclesBillingSearch({ search: search, limit: 10, skip: 0, param: 'custom_id', paramValue: true, group_id: 'all' });
    }
  }


  handleSearchClose = () => {
    this.setState({ search: '', currentPage: 0 });
    this.props.fetchBilling({ limit: this.state.perPage, skip: 0, param: 'ignition', paramValue: true, group_id: 'all' }); 
    this.props.router.navigate(`/subscription`)
  }


  handleGroupFilterValue = (group_id: string) => {
    this.setState({ group_id, dataLoaded: false })
    this.props.router.navigate(`/subscription?search=${this.state.search}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}&group_id=${group_id}`);
    if(this.state.search !== '') {
      this.getVehicleBillingSearch({ limit: this.state.perPage, skip: 0, param: 'custom_id', paramValue: false, group_id, search: this.state.search })
    } else {
      this.props.fetchBilling({ limit: this.state.perPage, skip: 0, param: 'custom_id', paramValue: false, group_id })
    }
  }


  getVehicleBillingSearch = (options:{ search: string, limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => {
    const { search, limit, skip, param, paramValue, group_id } = options
    this.props.fetchVehiclesBillingSearch({ search, limit, skip, param, paramValue, group_id })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehiclesBilling, vehiclesBillingCount } = this.props.vehicle
        this.setState({ vehiclesBilling, vehiclesBillingCount, dataLoaded: true })
      }
    })
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    this.props.fetchBilling({ limit: this.state.perPage, skip: this.state.perPage * this.state.currentPage, param, paramValue: !this.state.paramValue, group_id: this.state.group_id }); 
    this.props.router.navigate(`/subscription?page=${this.state.currentPage}&perPage=${this.state.perPage}&param=${param}&paramValue=${!this.state.paramValue}`)
  }


  updateCheckedArray = (id: any) => {
    const checkedArray : number[] = this.state.checkedArray;
    if(checkedArray.includes(id)) {
      const updatedArray = this.state.checkedArray.filter(function(item) {return item !== id})
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      this.setState({ checkedArray: updatedArray, checkedAll: updatedArrayAll })
    } else {
      this.setState({ checkedArray: this.state.checkedArray.concat(id)})
    }
  }


  updateCheckedArrayMultiple = () => {
    const updatedCheckedArray = this.props.vehicle.vehiclesBilling.map((item: {vehicle_id: number}) => { return item.vehicle_id })
    const checkedAll : number[] = this.state.checkedAll;
    if(checkedAll.includes(this.state.currentPage)) {
      const updatedArray = this.state.checkedArray.filter(
        (e) => this.state.checkedArray.includes(e),
        updatedCheckedArray
      );
      const updatedArrayAll = this.state.checkedAll.filter(item => item !== this.state.currentPage)
      this.setState({ checkedAll: updatedArrayAll, checkedArray:  updatedArray})
    } else {
      this.setState({ checkedAll: checkedAll.concat(this.state.currentPage), checkedArray: this.state.checkedArray.concat(updatedCheckedArray) })
    }
  }

  
  payCheckedItems = () => {
    this.setState({ showModal: true, modalType: <ModalPayVehicles setShowModal={this.setShowModal} checkedArray={this.state.checkedArray}  /> })
  }


  setShowModal = (value: boolean) => {
    this.setState({ showModal: value })
    if(value === false) {
      const { perPage, currentPage, param, paramValue, group_id } = this.state;
      this.reloadPage({ perPage, currentPage, param, paramValue, group_id }); 
      this.setState({ checkedArray: [], checkedAll: [] })
    }
  }


  render() {

    const { vehiclesBilling, vehiclesBillingCount, status } = this.props.vehicle;
    const { currentPage, perPage, search, param, paramValue, timezone, timeNow, timeOverdue } = this.state;

    const checkedArray : number[] = this.state.checkedArray;
    const checkedAll : number[] = this.state.checkedAll;

    return (
      <BillingPage>

        <Card 
          button={false}
          title='Subscription' 
          time={false}
          groupFilter={true}
          handleGroupFilterValue={(e:any) => this.handleGroupFilterValue(e.target.value)}
          groupFilterGroups={this.state.groups}
          search={true} 
          searchPlaceholder={t(121)}
          searchValue={search}
          handleSearchValue={e => this.setState({ search: e.target.value })} 
          handleSearch={() => this.handleSearch(this.state.search)}
          handleSearchClose={() => this.handleSearchClose()}
          tabnav='billing'
          tabnavId={0}
          animate
        >


          { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

          <Modal showModal={this.state.showModal} setShowModal={this.setShowModal} modalType={this.state.modalType} />

          <div className="table-wrapper">
            <table className="table">
            <thead className="table__header">
              <tr>
                <th className="th--clickable" onClick={() => this.setParam('module_imei')}>{t(2002)} {param === 'module_imei' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>

                <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>

                <th className="th--clickable" onClick={() => this.setParam('connected')}>{t(2068)} {param === 'connected' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>

                <th className="th--clickable" onClick={() => this.setParam('payment_date')}>{t(1042)} {param === 'payment_date' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>

                <th className="th--clickable" onClick={() => this.setParam('payment_date')}>{t(1041)} {param === 'payment_date' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>

                <th>
                  <input 
                    onChange={this.updateCheckedArrayMultiple}
                    checked={checkedAll.includes(currentPage)}
                    type="checkbox" 
                    name="select"  
                  /> 
                </th>
              </tr>
            </thead>
            <tbody className="table__body table__body--striped">
              {vehiclesBilling !== undefined && vehiclesBilling.length > 0 && 
              vehiclesBilling.map((item: {payment_date: string, vehicle_id: number, module_imei: string, custom_id: string, connected: string, ignore_payment: boolean}) => {
                const payment_date = moment(item.payment_date).tz(timezone).format('X')
                const now = moment()
                const days_connected = now.diff(item.connected, "days")
                let vehicleDead = false;
                const ts_now : number = parseInt(moment().format('X'))
                const ts_connected: number = item.connected && item.connected !== null ? moment(item.connected).unix() : 0
                if(ts_now - ts_connected > 86400) {
                  vehicleDead = true
                }
                return (
                  <tr key={item.vehicle_id} className={classnames('table-row', { 
                    'table-row__disabled': vehicleDead
                    })}>
                    <td>
                      {item.module_imei}
                    </td>
                    <td>
                      {item.custom_id}
                    </td>
                    <td>
                      <Tooltip />
                      <span data-tip={item.connected ? moment(item.connected).tz(timezone).format('DD/MM/YYYY HH:mm') : 'n/a'}>
                        {item.connected ? `${days_connected} ${t(2403)}` : 'n/a'}
                      </span>
                    </td>
                    <td>
                      {item.payment_date ? moment(item.payment_date).tz(timezone).format('DD/MM/YYYY HH:mm') : 'n/a'}
                    </td>
                    <td>
                    {item.ignore_payment === true ? (
                        <>
                          <span className="table__highlight table__highlight--info">n/a</span>
                        </>
                      ) : (
                        <>
                          { 
                            payment_date > timeNow && 
                            payment_date > timeOverdue && 
                            <span className="table__highlight table__highlight--success">{t(1206)}</span> 
                          }

                          { 
                            payment_date < timeNow && 
                            <span className="table__highlight table__highlight--danger">{t(1208)}</span>
                          }
                        </>
                      )}
                    </td>
                    <td>
                      <input 
                        onChange={() => this.updateCheckedArray(item.vehicle_id)}
                        checked={checkedArray.includes(item.vehicle_id)}
                        type="checkbox" 
                        name="select"  
                      /> 
                    </td>
                  </tr>
                )
              })
            }
            </tbody>

            <TableFooter 
              colSpan={4}
              currentPage={currentPage}
              perPage={perPage}
              itemsCount={ vehiclesBillingCount }
              prevPage={() => this.prevPage(currentPage - 1)}
              nextPage={() => this.nextPage(currentPage + 1)}
              updatePagination={this.updatePagination}
              button={ true }
              buttonTitle={'Subscribe'}
              buttonClickable={true}
              buttonDisabled={ checkedArray.length > 0 ? false : true }
              buttonHandleOnClick={() => this.payCheckedItems()}
            />
            </table>
          </div>
        </Card>
      </BillingPage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, group, auth }: RootState) => ({ vehicle, group, auth }),
  { fetchBilling, fetchVehiclesBillingSearch, fetchAllGroups }
)(Subscription)));
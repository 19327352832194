import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchWorkersState } from '../../actions/worker'
import fetchStates from '../../types/fetchStates'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import moment from 'moment-timezone'


interface WorkersStateProps {
  worker: any,
  fetchWorkersState: () => Promise<any>,
}


interface WorkersStateState {
  workersState: Array<any>
}


export class WorkersStatePage extends React.Component<WorkersStateProps, WorkersStateState> {

  state = {
    workersState: []
  }


  componentDidMount() {
    this.fetchWorkersState();
  }


  fetchWorkersState = async () => {
    await this.props.fetchWorkersState();
    const { status, workersState } = this.props.worker;
    if(status === fetchStates.success) {
      if(workersState.length > 0) {
        this.setState({ workersState: this.props.worker.workersState });
      }
    }
  }


  render() {

    const { workersState } = this.state;
    console.log(workersState)

    return (
      <TemplatePage>
        <div className="workers-state">
          {
            workersState.map((worker: { worker_id: number, worker_name: string, worker_running: boolean, worker_started: string }) => {
              return <div className="worker-state__item" key={ worker.worker_id }>
                <span className="worker-state__item-cell">{ worker.worker_name }</span>

                { worker.worker_running === true ? <span className="worker-state__item-cell"><span className="worker-state__label worker-state__label--danger">BLOCKED</span></span> : <span className="worker-state__item-cell"><span className="worker-state__label worker-state__label--success">OK</span></span> }

                <span className="worker-state__item-cell">{ moment(worker.worker_started).format('MM/DD HH:MM') }</span>
              </div>
            })
          }
        </div>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ worker }: RootState) => ({ worker }),
  { fetchWorkersState }
)(WorkersStatePage)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { checkAuth } from '../../actions/auth'
import { updatePersonNotificationsSettings } from '../../actions/person'
import { fetchAllAuthGroups } from '../../actions/group'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import Switch from '../partials/Switch'
import t from '../translation/translate'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface SettingsNotificationsProps {
  router: { navigate: (to: string) => any },
  auth: {status: string, email_notifications: any, reports_daily: any, communication_days: string, communication_repeat: boolean, person_id: string},
  group: {status: string, authGroups: Array<string>}
  checkAuth: () => Promise<any>,
  fetchAllAuthGroups: (options:{ limit: number, skip: number }) => Promise<any>,
  updatePersonNotificationsSettings: (options: {id: string, settings: any}) => Promise<any>,
}


interface SettingsNotificationsState {
  authGroups: any,
  formSubmitted: boolean,
  reports_daily: any,
  communication_days: string,
  communication_repeat: boolean,
  days: Array<number>,
  email_notifications: {
    rpm: boolean,
    speed: boolean,
    geo_fence: boolean,
    gasoline_use: boolean,
    gas_maintenance: boolean,
    gas_malfunction: boolean,
    gas_percentage: boolean,
    engine_temperature: boolean,
    locked: boolean,
    vehicle_payment_overdue: boolean,
    vehicle_communication: boolean,
  },
}


export class SettingsNotifications extends React.Component<SettingsNotificationsProps, SettingsNotificationsState> {

  state = {
    authGroups: [],
    formSubmitted: false,
    reports_daily: [],
    communication_days: '3',
    communication_repeat: false,
    days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
    email_notifications: {
      rpm: false,
      speed: false,
      geo_fence: false,
      gasoline_use: false,
      gas_maintenance: false,
      gas_malfunction: false,
      gas_percentage: false,
      engine_temperature: false,
      locked: false,
      vehicle_payment_overdue: false,
      vehicle_communication: false,
    },
  }


  componentDidMount = () => {
    this.fetchSettings()
  }


  fetchSettings = () => {
    // fetch groups list
    this.props.fetchAllAuthGroups({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, authGroups } = this.props.group
      if(status === fetchStates.success) {
        this.setState({ authGroups })
        this.props.checkAuth()
        .then(() => {
          if(this.props.auth.status === fetchStates.success) {
            const { email_notifications, reports_daily, communication_days, communication_repeat } = this.props.auth;
            this.setState({
              email_notifications,
              reports_daily: reports_daily !== null ? reports_daily : [],
              communication_days, 
              communication_repeat
            });
            authGroups.map((group: any) => {
              if(reports_daily && reports_daily.length > 0 && reports_daily.includes(group.group_id)) {
                group.group_active = true
              } else {
                group.group_active = false
              } 
              return false
            })
            this.setState({ authGroups })
          }
        })
      }
    })
  }


  toggleGroupDailyReports = (group_id:string) => {
    const { authGroups } = this.state;
    let reports_daily : string[] = [];
    reports_daily = this.state.reports_daily
    if(reports_daily.includes(group_id)) {
      const filtered_reports_daily = reports_daily.filter(group => group !== group_id)
      authGroups.map((group: {group_id: string}) => group.group_id === group_id ? {...group, group_active: false } : group )
      this.setState({ reports_daily: filtered_reports_daily, authGroups })
    } else {
      reports_daily.push(group_id)
      const updatedAuthGroups = authGroups.map((group: {group_id: string}) => group.group_id === group_id ? {...group, group_active: true } : group )
      this.setState({ reports_daily, authGroups: updatedAuthGroups })
    }
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { email_notifications, reports_daily, communication_days, communication_repeat } = this.state;
    const settings = { email_notifications, reports_daily, communication_days, communication_repeat }
    this.props.updatePersonNotificationsSettings({ id: this.props.auth.person_id, settings })
    .then(() => {
      this.fetchSettings()
      this.setState({ formSubmitted: false })
    })
  }


  render() {

    const { formSubmitted, email_notifications, communication_days, communication_repeat, days, authGroups } = this.state;
    const { status } = this.props.auth

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(11)} 
          padding={['large']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate} 
          time={false}
          animate
          tabnav="settings"
          tabnavId={0}
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-section">
              <h2 className="form-group__title">{t(1060)}</h2>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, vehicle_payment_overdue: !email_notifications.vehicle_payment_overdue } })}>
                  <Switch switchState={email_notifications.vehicle_payment_overdue} />
                  <span className="label-title">{t(1056)}</span>
                </label>
                <span className='form-group__explanation'>{t(1057)}</span>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={() => this.setState({ email_notifications: { ...email_notifications, vehicle_communication: !email_notifications.vehicle_communication } })}>
                  <Switch switchState={email_notifications.vehicle_communication} />
                  <span className="label-title">{t(1058)}</span>
                  <span className='form-group__explanation'>{t(1058)}</span>
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="communication_days">
                  Days withoug communication
                </label>
                <select 
                  name="communication_days" 
                  value={communication_days}
                  onChange={e => this.setState({ communication_days: e.target.value })}
                > 
                  {
                    days.map((day: number) => <option key={day} value={day.toString()}>{day.toString()}</option>)
                  }
                </select>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={() => this.setState({ communication_repeat: !communication_repeat })}>
                  <Switch switchState={communication_repeat} />
                  <span className="label-title">Repeat daily</span>
                  <span className='form-group__explanation'>Notifications sent daily or just once when the situation happens</span>
                </label>
              </div>

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>

            <div className="form-section">
              <h2 className="form-group__title">{t(1032)}</h2>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, speed: !email_notifications.speed } })}>
                  <Switch switchState={email_notifications.speed} />
                  <span className="label-title">{t(1033)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, geo_fence: !email_notifications.geo_fence } })}>
                  <Switch switchState={email_notifications.geo_fence} />
                  <span className="label-title">{t(1034)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, engine_temperature: !email_notifications.engine_temperature } })}>
                  <Switch switchState={email_notifications.engine_temperature} />
                  <span className="label-title">{t(1035)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, rpm: !email_notifications.rpm } })}>
                  <Switch switchState={email_notifications.rpm} />
                  <span className="label-title">{t(1036)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, gasoline_use: !email_notifications.gasoline_use } })}>
                  <Switch switchState={email_notifications.gasoline_use} />
                  <span className="label-title">{t(1037)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, gas_malfunction: !email_notifications.gas_malfunction } })}>
                  <Switch switchState={email_notifications.gas_malfunction} />
                  <span className="label-title">{t(1038)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, gas_maintenance: !email_notifications.gas_maintenance } })}>
                  <Switch switchState={email_notifications.gas_maintenance} />
                  <span className="label-title">{t(1039)}</span>
                </label>
              </div>


              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, gas_percentage: !email_notifications.gas_percentage } })}>
                  <Switch switchState={email_notifications.gas_percentage} />
                  <span className="label-title">{t(1156)}</span>
                </label>
              </div>

              <div className="form-group__switch form-group__switch--large">
                <label onClick={e => this.setState({ email_notifications: { ...email_notifications, locked: !email_notifications.locked } })}>
                  <Switch switchState={email_notifications.locked} />
                  <span className="label-title">{t(2413)}</span>
                </label>
              </div>

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>

            <div className="form-section">
              <h2 className="form-group__title">Daily mail reports for groups</h2>

              { 
                authGroups.map((group: {group_id: string, group_title: string, group_active: boolean}) => {
                  return <div className="form-group" key={group.group_id}>
                    <div className="form-group__switch form-group__switch--large">
                      <label onClick={() => this.toggleGroupDailyReports(group.group_id)}>
                        <Switch switchState={group.group_active} />
                        <span className="label-title">{group.group_title}</span>
                      </label>
                    </div>
                  </div>
                })
              }

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, person, group }: RootState) => ({ auth, person, group }),
  { checkAuth, fetchAllAuthGroups, updatePersonNotificationsSettings }
)(SettingsNotifications)))
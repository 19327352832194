import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchRfidById, deleteRfidById } from '../../actions/rfid'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import classnames from 'classnames'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import { error } from '../../helpers/notifications'


interface RfidDeleteProps {
  router: {params: { id: string }, navigate: (to: string) => any},
  rfid: any,
  fetchRfidById: (id: string) => Promise<any>,
  deleteRfidById: (id: string) => Promise<any>,
}


interface RfidDeleteState {
  custom_id: string,
  formSubmitted: boolean,
}


export class RfidDelete extends React.Component<RfidDeleteProps, RfidDeleteState> {

  state = {
    custom_id: '',
    formSubmitted: false,
  }


  componentDidMount() {
    this.props.fetchRfidById(this.props.router.params.id)
  }

  
  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })

    if(this.state.custom_id === '') {
      this.props.rfid.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2502)
    }
    
    if(this.state.custom_id !== this.props.rfid.rfid.custom_id) {
      this.props.rfid.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2503)
    }

    if(this.state.custom_id === this.props.rfid.rfid.custom_id) {
      this.props.deleteRfidById(this.props.rfid.rfid.rfid_id)
      .then(() => {
        if(this.props.rfid.status === fetchStates.success) {
          this.props.router.navigate('/rfid')
        }
      })
      this.setState({ formSubmitted: false })
    }

  }


  render() {

    const { rfid, status, fields } = this.props.rfid;
    const { formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={`${t(2424)} ${rfid.custom_id}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(2425)} <strong>{rfid.custom_id}</strong>. {t(2426)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2427)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={this.state.custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(211)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ rfid }: RootState) => ({ rfid }),
  { fetchRfidById, deleteRfidById }
)(RfidDelete)))
const alertTypesToString = (alert_type: number) => {
  switch(alert_type) {
    case 1:
      return 'alert-speed'
    case 2:
      return 'alert-geo-fence'
    case 3:
      return 'alert-engine-temp'
    case 4:
      return 'alert-rpm'
    case 5:
      return 'alert-gasoline-use'
    case 6:
      return 'alert-gas-malfunction'
    case 7:
      return 'alert-gas-maintenance'
    case 8:
      return 'alert-locked'
    case 9:
      return 'alert-gas-percentage'
    default:
      return null
  }
}

export default alertTypesToString
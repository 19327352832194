import { WORKER } from '../types'
import fetchStates from '../types/fetchStates'


interface WorkerState {
  workers : [],
  workersCount: number,
}


const DEFAULT_WORKER_LOGS:WorkerState = {
  workers : [],
  workersCount: 0,
}


const worker = (state = DEFAULT_WORKER_LOGS, action:any) => {
  switch(action.type) {
    case WORKER.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case WORKER.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case WORKER.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        workers: action.workers,
        workersCount: parseInt(action.workersCount, 10),
      };
    case WORKER.FETCH_WORKERS_STATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        workersState: action.workersState,
      };
    default:
      return state;
  }
}


export default worker
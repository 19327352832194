import ReactDOM from "react-dom/client"
import "./css/index.css"
import App from "./App"
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from "./reportWebVitals"


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)


reportWebVitals()
import { NavLink } from 'react-router-dom'
import Auth from '../Auth'
import { IconArrowRight } from '../Icons'
import t from '../../translation/translate'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


export const TabSettings:React.FC = () => {

  const location = useLocation()

  return (
    <nav className="tabnav-wrapper">
      <NavLink to={{ pathname: '/settings' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings' })}>
        {t(11)}
      </NavLink>
      <NavLink to={{ pathname: '/settings/notifications' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings/notifications' })}>
        {t(232)}
        <span className="tabnav-tab__arrow">
          <IconArrowRight color="#ffffff" />
        </span>
      </NavLink>
      <NavLink to={{ pathname: '/settings/password' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings/password' })}>
        {t(230)}
        <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
      </NavLink>
      <Auth requiresRole={[1,2,3]}>
        <NavLink to={{ pathname: '/settings/accounts' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings/accounts' })}>
          Switch account
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Auth>
      <Auth requiresRole={[1,2]}>
        <NavLink to={{ pathname: '/settings/admin' }} className={classnames('tabnav-tab', { 'tabnav-tab--active': location.pathname === '/settings/admin' })}>
          {t(233)}
          <span className="tabnav-tab__arrow"><IconArrowRight color="#ffffff" /></span>
        </NavLink>
      </Auth>
    </nav>
  )
}


export default TabSettings
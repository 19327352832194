import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllAccounts, changeUserAccount, fetchDistributorAccountGroups } from '../../actions/account'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import fetchStates from '../../types/fetchStates'
import { IconNotifyInfo } from '../partials/Icons'


interface SettingsAccountsProps {
  router: { navigate: (to: string) => any },
  auth: any,
  account: any,
  checkAuth: () => Promise<any>,
  fetchDistributorAccountGroups: () => Promise<any>,
  fetchAllAccounts: (options: { limit: number, skip: number }) => Promise<any>,
  changeUserAccount: (account_id: string) => Promise<any>
}


interface SettingsAccountsState {
  accounts: any,
  account_id: string,
  formSubmitted: boolean,
}


export class SettingsAccounts extends React.Component<SettingsAccountsProps, SettingsAccountsState> {

  state = {
    accounts: [],
    account_id: '',
    formSubmitted: false,
  }


  componentDidMount() {
    this.fetchDistributorAccounts();
    this.setCurrentPersonAccount();
  }


  fetchDistributorAccounts = async () => {
    const { role } = this.props.auth
    if(role === 1 || role === 2) {
      await this.props.fetchAllAccounts({ limit: 1000, skip: 0 });
      const { status, accounts } = this.props.account;
      if(status === fetchStates.success) {
        this.setState({ accounts });
      }
    }
    if(role === 3) {
      
      await this.props.fetchDistributorAccountGroups();
      const { status, distributor_accounts } = this.props.account;
      if(status === fetchStates.success) {

        this.setState({ accounts: distributor_accounts });
      }
      
    }
  }


  setCurrentPersonAccount = () => {
    const { account_id } = this.props.auth;
    this.setState({ account_id });
  }


  handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { account_id } = this.state;
    await this.props.changeUserAccount(account_id);
    this.setState({ formSubmitted: false });
    window.location.reload();
  }


  render() {

    const { accounts, account_id } = this.state;

    return (
      <TemplatePage>

        <Card 
          title={t(11)} 
          padding={['large']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate} 
          time={false}
          animate
          tabnav="settings"
          tabnavId={0}
        >
          {
            accounts && accounts.length > 0 ? (
              <form className="form" onSubmit={this.handleOnSubmit}>
                <h2 className="form-group__title">Switch accounts</h2>
                <div className="form-group">
                  <label htmlFor="language">
                    Switch to account
                  </label>
                      <select 
                        name="account" 
                        value={ account_id }
                        onChange={e => this.setState({ account_id: e.target.value })}
                      >
                        { 
                          accounts.map((account:{ account_id: string, company_name: string }) => <option key={account.account_id} value={account.account_id}>{account.company_name}</option>)
                        }
                      </select>
                      </div>
                <div className="form-group">
                  <input 
                    type="submit" 
                    value={`Change account`} 
                    className="btn btn--primary card__footer--btn-left"
                  />
                </div>
              </form>
            ) : (
              <div className="msg msg--info"><IconNotifyInfo color="#ffffff" />There is currently no distributor group set up to choose from.</div>
            )
          }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, account }: RootState) => ({ auth, account }),
  { fetchAllAccounts, changeUserAccount, fetchDistributorAccountGroups }
)(SettingsAccounts)))
const ReportFunctions = {


  alternativeFuelConsumption: function (options: { TOTAL_INJECTED_GAS_10MS: number, TOTAL_GAS_INJECTION_PER_TANK_10MS: number, TANK_SIZE_L: number }) {
    const {TOTAL_INJECTED_GAS_10MS, TOTAL_GAS_INJECTION_PER_TANK_10MS, TANK_SIZE_L} = options;
    console.log('alternativeFuelConsumption', TOTAL_INJECTED_GAS_10MS, TOTAL_GAS_INJECTION_PER_TANK_10MS, TANK_SIZE_L)
    if(TOTAL_GAS_INJECTION_PER_TANK_10MS !== 0) {
      console.log(`${TOTAL_INJECTED_GAS_10MS} * ${TANK_SIZE_L} / ${TOTAL_GAS_INJECTION_PER_TANK_10MS} =`, TOTAL_INJECTED_GAS_10MS * TANK_SIZE_L / TOTAL_GAS_INJECTION_PER_TANK_10MS);
      return TOTAL_INJECTED_GAS_10MS * TANK_SIZE_L / TOTAL_GAS_INJECTION_PER_TANK_10MS;
    }
    console.log(0);
    return 0;
  },


  alternativeFuelPercent: function (options: { ALTERNATIVE_DISTANCE_KM: number, DEFAULT_DISTANCE_KM: number }) {
    const { ALTERNATIVE_DISTANCE_KM, DEFAULT_DISTANCE_KM } = options;
    if( ALTERNATIVE_DISTANCE_KM > 0 ) {
      return ALTERNATIVE_DISTANCE_KM / ((DEFAULT_DISTANCE_KM + ALTERNATIVE_DISTANCE_KM) / 100);
    }
    return 0;
  },


  iglDefaultFuelConsumptionNew: function (options: { DEFAULT_DISTANCE_KM: number, ALTERNATIVE_DISTANCE_KM: number, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number, CONSUMPTION_DIFFERENCE_PERCENTAGE: number, AVERAGE_CONSUMPTION_L: number }) {
    const { DEFAULT_DISTANCE_KM, ALTERNATIVE_DISTANCE_KM, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, CONSUMPTION_DIFFERENCE_PERCENTAGE, AVERAGE_CONSUMPTION_L } = options;
    console.log('iglDefaultFuelConsumptionNew', DEFAULT_DISTANCE_KM, ALTERNATIVE_DISTANCE_KM, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, CONSUMPTION_DIFFERENCE_PERCENTAGE, AVERAGE_CONSUMPTION_L);
    if(TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L > 0 && DEFAULT_DISTANCE_KM > 0) {
      console.log(`${TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L} / ${ALTERNATIVE_DISTANCE_KM} =`, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L / ALTERNATIVE_DISTANCE_KM);
      const ALTERNATIVE_FUEL_USAGE_PER_DISTANCE = TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L / ALTERNATIVE_DISTANCE_KM;
      console.log(`${ALTERNATIVE_FUEL_USAGE_PER_DISTANCE} * ((100 - ${CONSUMPTION_DIFFERENCE_PERCENTAGE}) / 100) =`, ALTERNATIVE_FUEL_USAGE_PER_DISTANCE * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE) / 100));
      const DEFAULT_FUEL_USAGE_PER_DISTANCE = ALTERNATIVE_FUEL_USAGE_PER_DISTANCE * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE) / 100);
      console.log(`${DEFAULT_DISTANCE_KM} * ${DEFAULT_FUEL_USAGE_PER_DISTANCE} =`, DEFAULT_DISTANCE_KM * DEFAULT_FUEL_USAGE_PER_DISTANCE);
      return DEFAULT_DISTANCE_KM * DEFAULT_FUEL_USAGE_PER_DISTANCE;
    }
    if(DEFAULT_DISTANCE_KM > 0) {
      console.log(`(${AVERAGE_CONSUMPTION_L} / 100) * ${DEFAULT_DISTANCE_KM} =`, (AVERAGE_CONSUMPTION_L / 100) * DEFAULT_DISTANCE_KM);
      return (AVERAGE_CONSUMPTION_L / 100) * DEFAULT_DISTANCE_KM
    }
    console.log(0);
    return 0;
  },


  iglCO2Savings: function (options: { CONSUMPTION_DIFFERENCE_PERCENTAGE: number, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number, ENGINE_TYPE: string }) {
    const { CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, ENGINE_TYPE } = options; 
    // inicialize constants
    const CO2_GRAMMES_PETROL_PER_1L = 2392;
    const CO2_GRAMMES_DIESEL_PER_1L = 2640;
    const CO2_GRAMMES_LPG_PER_1L = 1665;
    if(TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L !== 0) {
      // gas consumption * ((100% - %fuel difference)/100) * co2 grames per petrol
      let co2pet_emission = (TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE)/100) * CO2_GRAMMES_PETROL_PER_1L ); 
      let co2die_emission = (TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE)/100) * CO2_GRAMMES_DIESEL_PER_1L);
      let co2lpg_emission = (TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * CO2_GRAMMES_LPG_PER_1L);
      let co2savings = 0;
  
      if (ENGINE_TYPE === 'petrol') {
        return ((co2pet_emission - co2lpg_emission)/1000);
      }
  
      if (ENGINE_TYPE === 'diesel') {
        return ((co2die_emission - co2lpg_emission)/1000);
      }
  
      if(co2savings < 0) {
        return 0;
      }
    
      if(co2savings > 100) {
        return 100;
      }
    }
    return 0;
  },


  noIglCO2Savings: function (options: { CONSUMPTION_DIFFERENCE_PERCENTAGE: number, TOTAL_FUEL_CONSUMPTION_L: number, ENGINE_TYPE: string, TOTAL_ALTERNATIVE_PERCENT: number }) {
    const { CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_FUEL_CONSUMPTION_L, ENGINE_TYPE, TOTAL_ALTERNATIVE_PERCENT } = options;
  
    // inicialize constants
    const CO2_GRAMMES_PETROL_PER_1L = 2392;
    const CO2_GRAMMES_DIESEL_PER_1L = 2640;
    const CO2_GRAMMES_LPG_PER_1L = 1665;
    const GAS_CONSUMPTION_L = TOTAL_FUEL_CONSUMPTION_L * (TOTAL_ALTERNATIVE_PERCENT / 100);
    if(GAS_CONSUMPTION_L !== 0 && TOTAL_ALTERNATIVE_PERCENT !== 0) {
      // gas consumption * ((100% - %fuel difference)/100) * co2 grames per petrol
      let co2pet_emission = (GAS_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE)/100) * CO2_GRAMMES_PETROL_PER_1L); 
      let co2die_emission = (GAS_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE)/100) * CO2_GRAMMES_DIESEL_PER_1L);
      let co2lpg_emission = (GAS_CONSUMPTION_L * CO2_GRAMMES_LPG_PER_1L);
      let co2savings = 0;
      if (ENGINE_TYPE === 'petrol') {
        return (co2pet_emission - co2lpg_emission)/1000;
      }
      if (ENGINE_TYPE === 'diesel') {
        return (co2die_emission - co2lpg_emission)/1000;
      }
      if(co2savings < 0) {
        return 0;
      }
      if(co2savings > 100) {
        return 100;
      }
    }
    return 0;
  },
  
  
  noIglDefaultFuelCost: function (options: { ENGINE_TYPE: string, COST_PETROL: number, COST_DIESEL : number, TOTAL_DEFAULT_FUEL_CONSUMPTION_L: number }) {
    const { ENGINE_TYPE, COST_PETROL, COST_DIESEL, TOTAL_DEFAULT_FUEL_CONSUMPTION_L } = options;
    const COST_DEFAULT_FUEL = ENGINE_TYPE === 'petrol' ? COST_PETROL : COST_DIESEL;
    return TOTAL_DEFAULT_FUEL_CONSUMPTION_L * COST_DEFAULT_FUEL;
  },
  
  
  iglGasConsumption: function (options: { TOTAL_INJECTED_GAS_10MS: number, TANK_SIZE_L: number, TOTAL_GAS_INJECTION_PER_TANK_10MS: number }) {
    const { TOTAL_INJECTED_GAS_10MS, TANK_SIZE_L, TOTAL_GAS_INJECTION_PER_TANK_10MS } = options;
    if(TOTAL_GAS_INJECTION_PER_TANK_10MS !== 0) {
      return TOTAL_INJECTED_GAS_10MS * TANK_SIZE_L / TOTAL_GAS_INJECTION_PER_TANK_10MS
    }
    return 0;
  },
  
  
  iglDefaultFuelCost: function (options: { TOTAL_DEFAULT_FUEL_CONSUMPTION_L: number, COST_PETROL: number, COST_DIESEL: number, ENGINE_TYPE: string }) {
    const { TOTAL_DEFAULT_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL, ENGINE_TYPE } = options;
    const COST_DEFAULT_FUEL = ENGINE_TYPE === 'petrol' ? COST_PETROL : COST_DIESEL;
    // calculate if we have any gas consumption
    if(TOTAL_DEFAULT_FUEL_CONSUMPTION_L > 0) {
      return TOTAL_DEFAULT_FUEL_CONSUMPTION_L * COST_DEFAULT_FUEL
    }
    return 0;
  },
  
  
  iglAlternativeFuelCost: function (options: { ALTERNATIVE_FUEL_TYPE: string, COST_CNG: number, COST_LPG : number, IGL_GAS_CONSUMPTION_L: number }) {
    const { ALTERNATIVE_FUEL_TYPE, COST_CNG, COST_LPG, IGL_GAS_CONSUMPTION_L } = options;
    const COST_ALTERNATIVE_FUEL = ALTERNATIVE_FUEL_TYPE === 'lpg' ? COST_LPG : COST_CNG;
    return IGL_GAS_CONSUMPTION_L * COST_ALTERNATIVE_FUEL;
  },
  
  
  noIglAlternativeFuelCost: function (options: { ALTERNATIVE_FUEL_TYPE: string, COST_CNG: number, COST_LPG : number, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number }) {
    const { ALTERNATIVE_FUEL_TYPE, COST_CNG, COST_LPG, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L } = options;
    if(ALTERNATIVE_FUEL_TYPE === 'lpg') {
      return TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * COST_LPG;
    } else {
      return TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * COST_CNG;
    }
  },

  
  defaultFuelCostWithoutAlternativeNew: function (options: { ENGINE_TYPE: string, CONSUMPTION_DIFFERENCE_PERCENTAGE: number, TOTAL_DEFAULT_FUEL_CONSUMPTION_L: number, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number, COST_PETROL: number, COST_DIESEL: number }) {
    const { ENGINE_TYPE, CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL } = options;
    const COST_DEFAULT_FUEL = ENGINE_TYPE === 'petrol' ? COST_PETROL : COST_DIESEL;
    const TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_WITHOUT_DIFFERENCE_L = TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE) / 100)
    return (TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_WITHOUT_DIFFERENCE_L * COST_DEFAULT_FUEL) + (TOTAL_DEFAULT_FUEL_CONSUMPTION_L * COST_DEFAULT_FUEL);
  },
  
  
  potentialSavings: function (options: { CONSUMPTION_DIFFERENCE_PERCENTAGE: number, TOTAL_DEFAULT_FUEL_CONSUMPTION_L: number, COST_PETROL: number, COST_DIESEL: number, COST_LPG: number, COST_CNG: number, ENGINE_TYPE: string, ALTERNATIVE_FUEL_TYPE: string }) {
    const { CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL, COST_LPG, COST_CNG, ENGINE_TYPE, ALTERNATIVE_FUEL_TYPE } = options
    const COST_DEFAULT_FUEL = ENGINE_TYPE === 'petrol' ? COST_PETROL : COST_DIESEL;
    const COST_ALTERNATIVE_FUEL = ALTERNATIVE_FUEL_TYPE === 'lpg' ? COST_LPG : COST_CNG;
    if(TOTAL_DEFAULT_FUEL_CONSUMPTION_L !== 0) {
      return Math.trunc(((TOTAL_DEFAULT_FUEL_CONSUMPTION_L * COST_DEFAULT_FUEL) - (TOTAL_DEFAULT_FUEL_CONSUMPTION_L * COST_ALTERNATIVE_FUEL * ((100 + CONSUMPTION_DIFFERENCE_PERCENTAGE)/100))));
    }
    return 0;
  },
  
  
  calculateEstmiatedSavings: function (options: { CONSUMPTION_DIFFERENCE_PERCENTAGE: number, ENGINE_TYPE: string, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number, COST_PETROL: number, COST_DIESEL: number }) {
    const { CONSUMPTION_DIFFERENCE_PERCENTAGE, ENGINE_TYPE, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL } = options;
    const COST_DEFAULT_FUEL = ENGINE_TYPE === 'petrol' ? COST_PETROL : COST_DIESEL;
    const TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_WITHOUT_DIFFERENCE_L = TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * ((100 - CONSUMPTION_DIFFERENCE_PERCENTAGE) / 100);
    return TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_WITHOUT_DIFFERENCE_L * COST_DEFAULT_FUEL;
  }
  

}


export default ReportFunctions;
import { MODULE } from '../types'
import fetchStates from '../types/fetchStates'


interface ModuleState {
  gsmModule : [],
  oldSettings: [],
  modules : [],
  pairing_requests_history: [],
  lockunlock_requests_history: [],
  modulesCount: number,
}


const DEFAULT_MODULE: ModuleState = {
  gsmModule : [],
  oldSettings: [],
  modules : [],
  pairing_requests_history: [],
  lockunlock_requests_history: [],
  modulesCount: 0
}


const moduleSettings = (state = DEFAULT_MODULE, action: any) => {
  switch(action.type) {
    case MODULE.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case MODULE.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case MODULE.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        gsmModule: action.gsmModule,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case MODULE.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        modules: action.modules,
        modulesCount: parseInt(action.modulesCount, 10),
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case MODULE.FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        modules: action.modules,
        modulesCount: parseInt(action.modulesCount, 10),
      };
    case MODULE.FETCH_ID_OLD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        oldSettings: action.oldSettings,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };    
    case MODULE.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case MODULE.FETCH_PAIRING_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        pairing_requests_history: action.pairing_requests_history,
      };
    case MODULE.FETCH_LOCKUNLOCK_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        lockunlock_requests_history: action.lockunlock_requests_history,
      };
    case MODULE.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default moduleSettings
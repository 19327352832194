import { IconSearch } from './Icons'


interface ButtonSearchProps {
  handleSearch: () => void
}


const ButtonSearch: React.FC<ButtonSearchProps> = ({ handleSearch }) => {
  return (
    <span onClick={handleSearch} className='btn--circular card_header-search-button'>
      <IconSearch color="#ffffff" />
    </span>
  )
}


export default ButtonSearch
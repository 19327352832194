import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchCompetitorById, deleteCompetitorById } from '../../actions/competitor'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import Preloader from '../partials/Preloader'
import fetchStates from '../../types/fetchStates'
import { error } from '../../helpers/notifications'
import classnames from 'classnames'


interface StationsUnautorizedDeleteProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  competitor: any,
  fetchCompetitorById: (id: number) => Promise<any>,
  deleteCompetitorById: (id: number) => Promise<any>,
}


interface StationsUnautorizedDeleteState {
  competitor: { custom_id: string },
  custom_id: string,
  formSubmitted: boolean,
}


export class StationsUnautorizedDelete extends React.Component<StationsUnautorizedDeleteProps, StationsUnautorizedDeleteState> {

  state = {
    competitor: { custom_id: ''},
    custom_id: '',
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchCompetitorById(parseInt(this.props.router.params.id))
    .then(() => {
      if(this.props.competitor.status === fetchStates.success) {
        this.setState({ competitor: this.props.competitor.competitor })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    if(this.state.custom_id === '') {
      this.props.competitor.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2313)
    }
    if(this.state.custom_id !== this.props.competitor.competitor.custom_id) {
      this.props.competitor.fields = ['custom_id']
      this.setState({ formSubmitted: false })
      return error(2314)
    }
    if(this.state.custom_id === this.props.competitor.competitor.custom_id) {
      this.props.deleteCompetitorById(this.props.competitor.competitor.station_id)
      .then(() => {
        if(this.props.competitor.status === fetchStates.success) {
          this.props.router.navigate('/stations/unauthorized')
        }
        this.setState({ formSubmitted: false })
      })

    }
  }


  render() {

    const { status, fields } = this.props.competitor;
    const { competitor, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false} 
          title={`${t(1019)} ${competitor.custom_id}`}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          danger={true}
          time={false}
          animate
        >
          <p>{t(2038)} <strong>{competitor.custom_id}</strong>. {t(2026)}</p>
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2027)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={this.state.custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>
            <div className="card__footer">
              <input 
                type="submit" 
                value={t(118)} 
                className="btn btn--primary btn--left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ competitor }: RootState) => ({ competitor }),
  { fetchCompetitorById, deleteCompetitorById }
)(StationsUnautorizedDelete)))
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { Navigate } from 'react-router-dom'
import Preloader from '../partials/Preloader'

const withAuthorization = (Component: any) => {


  interface WithAuthorizationProps {
    auth: {status: string, fields: Array<string>, authenticated: boolean},
    checkAuth: () => any,
  }
  
  
  interface WithAuthorizationState {
    authTest: boolean
  }

  class WithAuthorization extends React.Component<WithAuthorizationProps, WithAuthorizationState> {

    state = {
      authTest: false
    }

    componentDidMount(){
      this.props.checkAuth()
      .then(() => {
        this.setState({ authTest: true })
      })
    }

    render() {
      const { authenticated } = this.props.auth
      const { authTest } = this.state
      
      if(authTest) {
        return authenticated ? <Component {...this.props} /> : <Navigate to="/signin" />
      } else {
        return <Preloader type="fullscreen preloader--centered" />
      }
      
    }
  }


  return connect(
    ({ auth }: RootState) => ({ auth }),
    { checkAuth  }
  )(WithAuthorization);

}


export default withAuthorization;
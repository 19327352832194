import { REFUEL } from '../types'
import { API_ADDRESS } from '../config'
import t from '../components/translation/translate'
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchRefuel = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: REFUEL.FETCH });
  return fetch(`${API_ADDRESS}/refuel/${options.endpoint}`, options.options)
  .then(response => response.json())
  .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: REFUEL.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: REFUEL.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllRefuels = (options: { limit: string, skip: string }) => fetchRefuel({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REFUEL.FETCH_ALL_SUCCESS
});


export const fetchRefuelId = (id: number) => fetchRefuel({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REFUEL.FETCH_ID_SUCCESS
});


export const fetchVehicleRefuels = (options: { limit: number, skip: number, filterDate: any, group_id: string }) => fetchRefuel({
  endpoint: `?limit=${options.limit}&skip=${options.skip}&date=${options.filterDate}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REFUEL.FETCH_VEHICLE_REFUELS_SUCCESS
});


export const fetchRefuelsByGroupAndDate = (options: { filterDate: any, group_id: string }) => fetchRefuel({
  endpoint: `refuel-pins/?date=${options.filterDate}&group_id=${options.group_id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: REFUEL.FETCH_VEHICLE_REFUELS_DATE_AND_GROUP_SUCCESS
});
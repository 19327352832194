import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllAuthGroups } from '../../actions/group'
import { fetchVehicleRefuels } from '../../actions/refuel'
import { Link } from 'react-router-dom'
import fetchStates from '../../types/fetchStates'
import Preloader from './Preloader'
import t from '../translation/translate'
import Authorization from './Authorization'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import DatePicker from 'react-datepicker'
import { Tooltip } from 'react-tooltip'
import { IconVisibility } from './Icons'
import moment from 'moment-timezone'


interface CardRefuellingsProps {
  auth: any, 
  refuel: any, 
  group: any,
  fetchAllAuthGroups: (options: {limit: number, skip: number}) => Promise<any>,
  fetchVehicleRefuels: (options: {limit: number, skip: number, filterDate: any, group_id: string}) => Promise<any>,
}


interface CardRefuellingsState {
  currentPage: number,
  perPage: number,
  filterDate: any,
  todayDate: any,
  authGroups: Array<any>,
  group_id: string,
  vehiclesRefuel: Array<any>,
  vehiclesRefuelCount: number,
  fetchingData: boolean
}


export class CardRefuellings extends React.Component<CardRefuellingsProps, CardRefuellingsState> {


  state = {
    currentPage: 0,
    perPage: 10,
    filterDate: new Date(),
    todayDate: new Date(),
    authGroups: [],
    group_id: 'all',
    vehiclesRefuel: [],
    vehiclesRefuelCount: 0,
    fetchingData: true
  }

  componentDidMount() {
    // fetch groups list
    this.props.fetchAllAuthGroups({ limit: 1000, skip: 0 })
    .then(() => {
      const { status, authGroups } = this.props.group
      if(status === fetchStates.success) {
        this.setState({ authGroups })
      }
    })
    const { perPage, currentPage, filterDate, group_id } = this.state
    this.reloadPage({ perPage, currentPage, filterDate, group_id })
  }


  reloadPage = (options: { perPage: number, currentPage: number, filterDate: any, group_id: string }) => {
    const { perPage, currentPage, filterDate, group_id } = options
    this.setState({ fetchingData: true })
    this.props.fetchVehicleRefuels({ limit: perPage, skip: perPage * currentPage, filterDate: moment(filterDate).format('YYYY-MM'), group_id })
    .then(() => {
      if(this.props.refuel.status === fetchStates.success) {
        const { vehiclesRefuel, vehiclesRefuelCount } = this.props.refuel
        this.setState({ vehiclesRefuel, vehiclesRefuelCount })
      }
    })
    this.setState({ fetchingData: false })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    const { filterDate, group_id } = this.state;
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, filterDate, group_id })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    const { filterDate, group_id } = this.state;
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, filterDate, group_id })
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    const { filterDate, group_id } = this.state;
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, filterDate, group_id })
  }


  updateFilterDate = (date: any) => {
    this.setState({ filterDate: date, currentPage: 0 });
    this.reloadPage({ perPage: this.state.perPage, currentPage: 0, filterDate: date, group_id: this.state.group_id })
  }


  handleSelectGroup = (group_id: string) => {
    this.setState({ group_id, currentPage: 0 })
    this.reloadPage({ perPage: this.state.perPage, currentPage: 0, filterDate: this.state.filterDate, group_id })
  }


  render() {

    const { filterDate, todayDate, authGroups, group_id, currentPage, perPage, fetchingData, vehiclesRefuel, vehiclesRefuelCount } = this.state

    return (
      <Authorization requiresAuth="vehicles">
        <div className="card animation--slide-in-from-bottom">
          <div className="card__header">
            <span>
              <h3 className="card__header-title">
                {t(2501)}
              </h3>
            </span>
          </div>
          <div className="card__body card__body--wrapper">
            <form className="form">
              <div className="filter">
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(2050)}</span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDate}
                      selectsStart
                      onChange={this.updateFilterDate}
                      dateFormat='yyyy-MM'
                      maxDate={todayDate}
                      required={true}
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </span>
                </span>
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(2429)}</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleSelectGroup(e.target.value)}
                      value={group_id}
                    >
                      <option key="0" value="all">{t(1152)}</option>
                      { 
                        authGroups.map((group: {group_id: string, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                      }
                    </select>
                  </span>
                </span>
              </div>
            </form>
            <div className="table-wrapper">
            { fetchingData ? <Preloader type="fullscreen" /> : (
                  <table className="table">
                    <TableHeader 
                      columns={['', 'ID', 'Refueled']} 
                    />
                    <tbody className="table__body table__body--striped">
                      {
                        vehiclesRefuel && vehiclesRefuel.map((item: {vehicle_id: string, custom_id: string, refuel_no: number}) => {
                          return (
                            <tr key={item.vehicle_id}>
                              <td className="simple">
                                <Tooltip/>
                                <Link to={`/vehicles/statistics/${item.vehicle_id}`}>
                                  <span data-tip={t(16)}>
                                    <IconVisibility 
                                      task 
                                      button 
                                      color="#ffffff"
                                    />
                                  </span>
                                </Link>
                              </td>
                              <td>{item.custom_id}</td>
                              <td>{item.refuel_no}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                    <TableFooter 
                      button={false}
                      colSpan={6}
                      currentPage={ currentPage }
                      perPage={ perPage }
                      itemsCount={ vehiclesRefuelCount }
                      prevPage={() => this.prevPage(currentPage - 1)}
                      nextPage={() => this.nextPage(currentPage + 1)}
                      updatePagination={this.updatePagination}
                    />
                  </table>
                )}
            </div>
          </div>    
        </div>
      </Authorization>
    );
  }
}


export default connect(
  ({ auth, refuel, group }: RootState) => ({ auth, refuel, group }),
  { fetchAllAuthGroups, fetchVehicleRefuels }
)(CardRefuellings);
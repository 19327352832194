import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchModuleVehicleById } from '../../actions/vehicle';
import { fetchVehicleById } from '../../actions/vehicle';
import { fetchDiagnosticsAlertsById, fetchAlertsSettingsById } from '../../actions/alert';
import { fetchModuleSettingsById } from '../../actions/module';
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { Link } from 'react-router-dom';
import { IconVehicleSettings, IconModuleGas, IconModuleTelemetry, IconNotifyClose, IconNotifyError, IconNotifySuccess, IconVisibilityOff, IconLinkOff, IconLinkOn, IconLockClosed, IconLockOpen, IconNotifyWarning } from '../partials/Icons'
import classnames from 'classnames'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import Preloader from '../partials/Preloader'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import getGasControllerModel from '../../helpers/gasControllerModel'


interface VehiclesDiagnosticsProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  alert: any,
  vehicle: any,
  fetchVehicleById: (id: number) => Promise<any>,
  fetchDiagnosticsAlertsById: (id: number) => Promise<any>,
  fetchModuleVehicleById: (id: number) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface VehiclesDiagnosticsState {
  vehicle: {custom_id: string, module_imei: string, paired: number, unlocked: number, connected: number, ignition: number, controller_connected: number, compatible: number, igl: number},
  lastConnected: number,
  formSubmitted: boolean,
  diagnosticDisabled: boolean,
  diagnosticCompleted: boolean,
  hardwareVersion: string,
  firmwareVersion: string,
  gasControlerModel: string,
  gasControlerFirmware: string,
  gasControlerFirmwareDate: string,
  diagnostics: any,
  alerts_malf_en: number,
  timezone: string,
  settingsTimestamp: string,
  simcardImsi: number,
}


export class VehiclesDiagnostics extends React.Component<VehiclesDiagnosticsProps, VehiclesDiagnosticsState> {


  state = {
    vehicle: {custom_id: '', module_imei: '', paired: 0, unlocked: 0, connected: 0, ignition: 0, controller_connected: 0, compatible: -1, igl: 0},
    lastConnected: 0,
    formSubmitted: false,
    diagnosticDisabled: true,
    diagnosticCompleted: false,
    hardwareVersion: '',
    firmwareVersion: '',
    gasControlerModel: '',
    gasControlerFirmware: '',
    gasControlerFirmwareDate: '',
    diagnostics: [],
    alerts_malf_en: 0,
    timezone: TIMEZONE,
    settingsTimestamp: '',
    simcardImsi: 0,
  }


  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchVehicleById(parseInt(this.props.router.params.id))
      .then(() => {

        if(this.props.vehicle.status === fetchStates.success) {
          const { vehicle } = this.props.vehicle
          let diagnosticDisabled = false
          let lastConnected = 0
          if(vehicle.controller_connected === 0) {
            vehicle.compatible = -1
          }
          // if last timestamp is less then 10m then we count this as still connected
          if(moment(vehicle.connected).format('X') > moment().subtract(10, 'minutes').format('X') && vehicle.ignition === 1) {
            lastConnected = 1
          }
          // disable option to pair the module if any of those are true
          if(vehicle.ignition === 0 || lastConnected === 0) {
            diagnosticDisabled = true;
          }
          this.setState({ vehicle, lastConnected, diagnosticDisabled })
        }

      })
    }
  }


  handleRunDiagnostics = () => {
    this.setState({ formSubmitted: true })
    if( this.props.router.params.id !== undefined ) {
      this.props.fetchModuleVehicleById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.vehicle.status === fetchStates.success) {
          const { hwi_hv, hwi_fv, hwi_st, hwi_im, gmi_mo, gmi_fv, gmi_cd } = this.props.vehicle.vehicleModule;
          const { vehicle } = this.props.vehicle;
          let lastConnected = 0
          if(vehicle.controller_connected === 0) {
            vehicle.compatible = -1
          }
          // if last timestamp is less then 10m then we count this as still connected
          if(moment(vehicle.connected).format('X') > moment().subtract(10, 'minutes').format('X') && vehicle.ignition === 1) {
            lastConnected = 1
          }
          this.setState({ 
            hardwareVersion: hwi_hv !== undefined ? hwi_hv : 'n/a',
            firmwareVersion: hwi_fv !== undefined ? hwi_fv : 'n/a',
            settingsTimestamp: hwi_st !== undefined ? moment(hwi_st*1000).format('YYYY/MM/DD HH:MM') : 'n/a',
            simcardImsi: hwi_im !== undefined ? hwi_im : 'n/a',
            gasControlerModel: gmi_mo !== undefined ? getGasControllerModel(gmi_mo) + ' ' + gmi_mo : 'n/a',
            gasControlerFirmware: gmi_fv !== undefined ? gmi_fv : 'n/a',
            gasControlerFirmwareDate: gmi_cd !== undefined ? gmi_cd : 'n/a',
            vehicle,
            lastConnected
          });
        }
      })
      this.props.fetchDiagnosticsAlertsById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.alert.status === fetchStates.success) {
          const { diagnostics } = this.props.alert
          this.setState({ diagnostics })
        }
      })
      this.props.fetchAlertsSettingsById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.alert.status === fetchStates.success) {
          const { alertsSettings } = this.props.alert
          console.log('alertsSettings', alertsSettings)
          if(alertsSettings && alertsSettings.alerts_malf_en) {
            this.setState({
              alerts_malf_en: alertsSettings.alerts_malf_en
            })
          }
        }
      })
    }
    this.setState({ formSubmitted: false, diagnosticCompleted: true })
  }


  render() {

    const { vehicle, lastConnected, formSubmitted, diagnosticDisabled, diagnosticCompleted, hardwareVersion, firmwareVersion, gasControlerModel, gasControlerFirmware, gasControlerFirmwareDate, diagnostics, alerts_malf_en } = this.state
    const { status } = this.props.vehicle

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          button={true} 
          buttonLink='/accounts/add' 
          buttonTitle={t(191)}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          tabnav="vehicle-settings"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
            <div className="diagnostics">


              <div className="pairing-circle-wrapper">

                <div className={classnames('pairing-circle', {          
                    'pairing-circle__completed': status === 'success',
                    'pairing-circle__processing': status === 'fetching',
                    'pairing-circle__unprocessed': status === 'error'
                  })}
                >
                  <IconVehicleSettings size={200} />


                  <span className={classnames('pairing-state-icon pairing-state-icon--paired', {          
                      'pairing-state-icon--processed': status === 'success',
                      'pairing-state-icon--processing': status === 'fetching',
                      'pairing-state-icon--unprocessed': status === 'error',
                      'pairing-state-icon--success': vehicle.paired === 1 && status === 'success' && diagnosticCompleted === true,
                      'pairing-state-icon--info': (vehicle.paired === -1 || vehicle.paired === -2 || vehicle.paired === 2 || vehicle.paired === 0 ) && status === 'success' && diagnosticCompleted === true
                    })}
                  >
                    {
                      (vehicle.paired === -1 || diagnosticCompleted === false) && <IconVisibilityOff size={48} color="#dfdfdf" />
                    }
                    {
                      vehicle.paired === 0 && diagnosticCompleted === true && <IconLinkOff size={48} color="#ffffff" />
                    }
                    {
                      vehicle.paired === 1 && diagnosticCompleted === true && <IconLinkOn size={48} color="#ffffff" />
                    }
                    {
                      vehicle.paired === 2 && diagnosticCompleted === true && <IconLinkOff size={48} color="#ffffff" />
                    }
                  </span>



                  <span className={classnames('pairing-state-icon pairing-state-icon--unlocked', {          
                        'pairing-state-icon--processed': status === 'success',
                        'pairing-state-icon--processing': status === 'fetching',
                        'pairing-state-icon--unprocessed': status === 'error',
                        'pairing-state-icon--success': vehicle.paired === 1 && vehicle.unlocked === 1 && status === 'success' && diagnosticCompleted === true,
                        'pairing-state-icon--alert': vehicle.paired === 1 && vehicle.unlocked === 0 && status === 'success' && diagnosticCompleted === true,
                        'pairing-state-icon--info': ( vehicle.paired === -2 || vehicle.paired === 2 || vehicle.paired === 0  ) && status === 'success' && diagnosticCompleted === true,
                      })}
                  >
                    { (vehicle.paired === -1 || diagnosticCompleted === false) && <IconVisibilityOff size={48} color="#dfdfdf" /> }
                    { (vehicle.paired === 0 || vehicle.paired === 2) && diagnosticCompleted === true && <IconLinkOff size={48} color="#ffffff" /> }
                    { vehicle.paired === 1 && diagnosticCompleted === true && vehicle.unlocked === 1 && <IconLockOpen size={48} color="#ffffff" /> }
                    { vehicle.paired === 1 && diagnosticCompleted === true && vehicle.unlocked === 0 && <IconLockClosed size={48} color="#ffffff"/> }
                  </span>



                  <div
                    className={classnames('pairing-state-time', {          
                      'pairing-state-time--processed': status === 'success',
                      'pairing-state-time--processing': status === 'fetching',
                      'pairing-state-time--unprocessed': status === 'error',
                    })}
                  >
                    { 
                      lastConnected === 0 ? (
                        <span className="text-error">{t(2461)}:<br/></span>
                      ) : (
                        <span>{t(2462)}:<br/></span>
                      )
                    }
                    {vehicle.connected !== 0 ? moment(vehicle.connected).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}
                  </div>

                  </div>

                  <div className={classnames('driving-state-icon', {
                    'state-icon--processed': status === 'success',
                    'state-icon--processing': status === 'fetching',
                    'state-icon--unprocessed': status === 'error'
                  })}>
                    { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                    { status === 'success' && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2465)}</span>}
                    { status === 'success' && vehicle.ignition === 0 && <span className="icon-state"><IconNotifyError color="#E34B5F"/>{t(2466)}</span>}
                  </div>

                  <div className={classnames('connected-state-icon', {
                    'state-icon--processed': status === 'success' && vehicle.ignition === 1,
                    'state-icon--processing': status === 'fetching',
                    'state-icon--unprocessed': status === 'error'
                  })}>
                    { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                    { vehicle.ignition === 0 && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
                    { status === 'success' && vehicle.controller_connected === 1 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(2467)}</span> }
                    { status === 'success' && vehicle.controller_connected === 0 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(2468)}</span> }
                  </div>

                  <div className={classnames('compatible-state-icon', {
                    'state-icon--processed': status === 'success' && vehicle.ignition === 1 && vehicle.compatible !== -1,
                    'state-icon--processing': status === 'fetching',
                    'state-icon--unprocessed': status === 'error' || vehicle.compatible === -1
                  })}>
                    { status === 'fetching' && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>{t(2448)}</span>}
                    { (vehicle.ignition === 0 || vehicle.compatible === -1) && <span className="icon-state"><IconNotifyClose color="#D4D4D4"/>n/a</span>}
                    { status === 'success' && vehicle.compatible === 0 && vehicle.ignition === 1 && <span className="icon-state"><IconNotifySuccess color="#5BC3A3"/>{t(1070)}</span>}
                    { status === 'success' && vehicle.compatible === 1 && vehicle.ignition === 1 && <span className="icon-state text-error"><IconNotifyError color="#E34B5F"/>{t(1071)}</span>}
                  </div>

                  {
                      diagnosticCompleted && (

                        <div className="modules-info">

                          <div className="module-info module-info--gas">
                            <div className="module-info__icon">
                              <IconModuleGas size={80} />
                            </div>
                            <div className="module-info__label">
                              <div>{t(1023)}: {hardwareVersion}</div>
                              <div>{t(1024)}: {firmwareVersion}</div>
                            </div>
                          </div>

                          <div className="module-info module-info--telemetry">
                            <div className="module-info__icon">
                              <IconModuleTelemetry size={80} />
                            </div>
                            <div className="module-info__label">
                              <div>{t(4249)}: {gasControlerModel}</div>
                              <div>{t(4250)}: {gasControlerFirmware}</div>
                              <div>{t(4252)}: {gasControlerFirmwareDate}</div>
                            </div>
                          </div>
                          
                        </div>

                      )
                    }
                </div>



                {
                  diagnosticCompleted && (
                    <div className="diagnostics__checks">
                      { vehicle.controller_connected !== 1 ? (<div className="msg msg--danger"><IconNotifyWarning color="#ffffff" />Controller is not connected correctly</div>) : (<div className="msg msg--success"><IconNotifySuccess color="#ffffff"/>Controller is connected correctly</div>) }
                      { vehicle.compatible === 0 ? (<div className="msg msg--success"><IconNotifySuccess color="#ffffff"/>GSM module is compatible with this version of GAS module</div>) : (<div className="msg msg--danger"><IconNotifyWarning color="#ffffff" />GSM module is not compatible with this version of GAS module</div>) }
                      { vehicle.igl === 1 ? (<div className="msg msg--success"><IconNotifySuccess color="#ffffff"/>IGL is correctly set up</div>) : (<div className="msg msg--warning"><IconNotifyWarning color="#ffffff" />Please make sure IGL is set up correctly</div>)}
                    </div>
                  )
                }


                {
                  diagnosticCompleted && (
                    diagnostics && diagnostics.length > 0 ? (
                      <div className="cb__section diagnostics__malfunction-alerts">
                        { diagnostics.map((alert: {id: number, value: string, ts: number}) => (
                          <div className="data data--info" key={alert.id}>
                            <strong>{moment(alert.ts*1000)
                              .format('YYYY/MM/DD HH:mm:ss')}</strong> - { t(parseInt(alert.value), 'e') }
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="cb__section diagnostics__malfunction-alerts">
                      { alerts_malf_en === 0 && (
                        <>
                          <h3>Malfunction alerts</h3>
                          <div className="msg msg--danger"><IconNotifyWarning color="#ffffff" />Malfunction alerts are turned off. <Link to={`/vehicles/alerts/${this.props.router.params.id}`}>Turn them on now</Link>.</div>
                        </>
                      ) }

                      </div>
                    )
                  )
                }


                {
                  diagnosticCompleted && (
                    (vehicle.controller_connected === 0 ||  vehicle.igl !== 1 || vehicle.compatible !== 0) && (
                        <div className="cb__section diagnostics__results">
                        <h3>You might experience the following</h3>
                        <div>
                          { vehicle.controller_connected === 0 && ('• You won\'t be able to pair the GSM module with the GAS module and you won\'t be able to set up the IGL.')}
                        </div>
                        <div>
                          { vehicle.igl !== 1 && ('• Sensitivity for small refuell amounts is decreased.')}
                        </div>
                        <div>
                          { vehicle.compatible !== 0 && ('• You won\'t be able to pair the GSM module with the GAS module and as a result use the lock/unlock feature')}
                        </div>
                      </div>
                    )
                  )
                }


                <div className="diagnostic-button">
                  <button 
                    className="btn btn--primary" 
                    onClick={this.handleRunDiagnostics}
                    disabled={(formSubmitted && status === 'fetching') || diagnosticDisabled}
                  >
                    Run the diagnostics
                  </button>
                </div>



              </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, alert, auth }: RootState) => ({ vehicle, alert, auth }),
  { fetchVehicleById, fetchDiagnosticsAlertsById, fetchModuleSettingsById, fetchModuleVehicleById, fetchAlertsSettingsById }
)(VehiclesDiagnostics)))
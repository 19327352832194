import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import classnames from 'classnames'


interface SwitchProps {
  switchState: boolean,
  disabled?: boolean,
  small?: boolean
}


export const Switch: React.FC<SwitchProps> = ({ switchState, disabled, small }) => {
  const [isOn, setIsOn] = useState(switchState);
  useEffect(
    () => {setIsOn(switchState)},
    [switchState],
  )
  const toggleSwitch = () => setIsOn(!isOn);
  if(disabled) {
    return (
      <span className="switch-wrapper">
        <span className={classnames('switch', { 'switch--small': small })}>
          <div className="handle" />
        </span>
      </span>
    )
  } 
  return (
    <span className="switch-wrapper">
      <span className={classnames('switch', { 'switch--small': small })} data-ison={isOn} onClick={toggleSwitch}>
        <motion.div className="handle" layout transition={spring} />
      </span>
    </span>
  );
}


export default Switch


const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30
}
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllModules, fetchModulesSearch } from '../../actions/module'
import TemplatePage from '../templates/TemplatePage'
import Preloader from '../partials/Preloader'
import Card from '../partials/Card'
import TableFooter from '../partials/TableFooter'
import moment from 'moment-timezone'
import t from '../translation/translate'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import gasControlerModel from '../../helpers/gasControllerModel'
import { IconDropUp, IconDropDown } from '../partials/Icons'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'


interface ModulesProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  auth: { timezone: any },
  moduleState: {status: string, modules: any, modulesCount: number},
  fetchAllModules: (options: {limit: number, skip: number, param: string, paramValue: boolean}) => Promise<any>,
  fetchModulesSearch: (options: { search: string, limit: number, skip: number, param: string, paramValue: boolean }) => Promise<any>,
}


interface ModulesState {
  modules: any,
  modulesCount: number,
  currentPage: number,
  param: string,
  paramValue: boolean,
  perPage: number,
  timezone: string,
  search: string,
}


export class Modules extends React.Component<ModulesProps, ModulesState> {

  state = {
    modules: [],
    modulesCount: 0,
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE,
    param: 'module_imei',
    paramValue: true,
    search: '',
  }

  componentDidMount() {

    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    let param = this.state.param
    let paramValue = this.state.paramValue
    let search = this.state.search

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.param !== null && parsed.param !== undefined) {
      param = parsed.param.toString()
    }
    if(parsed.paramValue !== null && parsed.paramValue !== undefined) {
      paramValue = parsed.paramValue === 'true' ? true : false
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/modules?page=${currentPage}&perPage=${perPage}&param=${param}&paramValue=${paramValue}`)
      }
    } else {
      this.props.router.navigate(`/modules?page=${currentPage}&perPage=${perPage}&param=${param}&paramValue=${paramValue}`)
    }
    if(parsed.search !== null && parsed.search !== undefined) {
      search = parsed.search.toString()
    }

    // update state and load the data
    this.setState({ perPage, currentPage, param, paramValue, search})

    if(search !== '') {
      return this.handleSearch(search)
    }

    this.reloadPage({ perPage, currentPage, param, paramValue }); 

  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/modules?page=0&perPage=${e.target.value}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/modules?page=${prevPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/modules?page=${nextPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, param?: string, paramValue?: boolean }) => {
    const { perPage, currentPage, param, paramValue } = options;
    let reloadParam = this.state.param
    let reloadParamValue = this.state.paramValue
    if(param) { reloadParam = param }
    if(paramValue) { reloadParamValue = paramValue }

    if(this.state.search === '') {
      return this.props.fetchAllModules({ limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue })
      .then(() => {
        if(this.props.moduleState.status === fetchStates.success) {
          const { modules, modulesCount } = this.props.moduleState
          this.setState({ modules, modulesCount })
        }
      });
    } else {
      this.props.fetchModulesSearch({ search: this.state.search, limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue })
      .then(() => {
        if(this.props.moduleState.status === fetchStates.success) {
          const { modules, modulesCount } = this.props.moduleState
          this.setState({ modules, modulesCount })
        }
      });
    }
  }


  handleSearch = (search: string) => {
    this.props.router.navigate(`/modules?search=${search}&perPage=${this.state.perPage}&param=${'ignition'}&paramValue=${true}`)
    if(search !== '') {
      return this.props.fetchModulesSearch({ search: search, limit: 10, skip: 0, param: 'ignition', paramValue: true })
      .then(() => {
        if(this.props.moduleState.status === fetchStates.success) {
          const { modules, modulesCount } = this.props.moduleState
          this.setState({ modules, modulesCount })
        }
      });
    }
  }


  handleSearchClose = () => {
    this.setState({ search: '', currentPage: 0 });
    this.props.fetchAllModules({ limit: 10, skip: 0, param: 'module_imei', paramValue: true })
    .then(() => {
      if(this.props.moduleState.status === fetchStates.success) {
        const { modules, modulesCount } = this.props.moduleState
        this.setState({ modules, modulesCount })
      }
    });
    this.props.router.navigate(`/modules`)
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    this.props.fetchAllModules({ limit: this.state.perPage, skip: this.state.perPage * this.state.currentPage, param, paramValue: !this.state.paramValue })
    .then(() => {
      if(this.props.moduleState.status === fetchStates.success) {
        const { modules, modulesCount } = this.props.moduleState
        this.setState({ modules, modulesCount })
      }
    });
    this.props.router.navigate(`/modules?page=${this.state.currentPage}&perPage=${this.state.perPage}&param=${param}&paramValue=${!this.state.paramValue}`)
  }


  render() {

    const { status } = this.props.moduleState;
    const { modules, modulesCount, search, currentPage, perPage, timezone, param, paramValue } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(7)} 
          time={false}
          search={true} 
          searchPlaceholder={'Search by Serial No'}
          searchValue={search}
          handleSearchValue={e => this.setState({ search: e.target.value })} 
          handleSearch={() => this.handleSearch(this.state.search)}
          handleSearchClose={() => this.handleSearchClose()}
          button={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
            <thead className="table__header">
              <tr>
                <th>ID</th>
                <th>{t(1128)}</th>
                <th className="th--clickable" onClick={() => this.setParam('module_imei')}>
                  {t(1021)} {param === 'module_imei' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('custom_id')}>
                  {t(1022)} {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('connected')}>
                  {t(2068)} {param === 'connected' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('buffer')}>
                  {t(2069)} {param === 'buffer' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('buffer_records')}>
                  {t(2405)} {param === 'buffer_records' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('hwi_hv')}>
                  {t(1023)} {param === 'hwi_hv' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('hwi_fv')}>
                  {t(1024)} {param === 'hwi_fv' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('gmi_mo')}>
                  {t(1025)} {param === 'gmi_mo' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('gmi_fv')}>
                  {t(1027)} {param === 'gmi_fv' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('gmi_cd')}>
                  {t(1028)} {param === 'gmi_cd' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
                <th className="th--clickable" onClick={() => this.setParam('phone_number')}>
                  {t(1029)} {param === 'phone' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}
                </th>
              </tr>
            </thead>
            <tbody className="table__body table__body--striped">
              {modules && modules.length > 0 && 
              modules.map((item: {module_imei: number, account_id: string, company_name: string, custom_id: string, connected: number, buffer: number, buffer_records: number, hwi_hv: number, hwi_fv: number, gmi_mo: string, gmi_fv: number, gmi_cd: number, phone_number: number }) => {
                return (
                <tr key={item.module_imei}>
                  <td>
                    {item.account_id}
                  </td>
                  <td>
                    {item.company_name}
                  </td>
                  <td>
                    {item.module_imei}
                  </td>
                  <td>
                    {item.custom_id}
                  </td>
                  <td>
                    {item.connected ? moment(item.connected).tz(timezone).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}
                  </td>
                  <td>
                    {item.buffer ? moment(item.buffer*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss') : 'n/a'}
                  </td>
                  <td>
                    {item.buffer_records ? item.buffer_records : (item.buffer_records === null ? 0 : item.buffer_records)}
                  </td>
                  <td>
                    {item.hwi_hv ? item.hwi_hv : 'n/a'}
                  </td>
                  <td>
                    {item.hwi_fv ? item.hwi_fv : 'n/a'}
                  </td>
                  <td>
                    {item.gmi_mo ? gasControlerModel(item.gmi_mo) + ' ' + item.gmi_mo : 'n/a'}
                  </td>
                  <td>
                    {item.gmi_fv ? item.gmi_fv : 'n/a'}
                  </td>
                  <td>
                    {item.gmi_cd ? item.gmi_cd : 'n/a'}
                  </td>
                  <td>
                    {item.phone_number ? item.phone_number : 'n/a'}
                  </td>
                </tr>
                )
              })
            }
            </tbody>
              <TableFooter 
                button={false}
                colSpan={8}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ modulesCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ moduleState, auth }: RootState) => ({ moduleState, auth }),
  { fetchAllModules, fetchModulesSearch }
)(Modules)))
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../reducers'
import { updateAlertGasMaintenance, fetchAlertsSettingsById } from '../../../actions/alert'
import t from '../../translation/translate'


interface AlertFormEngineTemperatureProps {
  alert: any,
  modalId: any,
  setShowModal: (value: boolean, type: any) => void,
  updateAlertGasMaintenance: (options: {id: number, value: string, mp: string}) => Promise<any>,
  fetchAlertsSettingsById: (id: number) => Promise<any>,
}


interface AlertFormEngineTemperatureState {
  formSubmitted: boolean,
  gas_maintenance: string,
  alert_max_frequency: string
}


export class AlertFormGasMaintenance extends React.Component<AlertFormEngineTemperatureProps, AlertFormEngineTemperatureState> {

  state = {
    formSubmitted: false,
    gas_maintenance: '10000',
    alert_max_frequency: '86400'
  }


  componentDidMount() {
    this.props.fetchAlertsSettingsById(this.props.modalId)
    .then(() => {

      const { alerts_maint_mp, alerts_maint_v } = this.props.alert.alertsSettings

      this.setState({
        gas_maintenance: alerts_maint_v !== null ? alerts_maint_v : '10000',
        alert_max_frequency: alerts_maint_mp !== null ? alerts_maint_mp : '86400'
      })

    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    this.props.updateAlertGasMaintenance({ id: this.props.modalId, value: this.state.gas_maintenance, mp: this.state.alert_max_frequency })
    .then(() => {
      this.setState({ formSubmitted: false })
      this.props.setShowModal(false, 'alert-speed')
    })
  }


  render() {

    const { formSubmitted, gas_maintenance, alert_max_frequency } = this.state;

    return (
      <div className="modal__content">
        <div className="modal__title">
          <h2>{t(2084)}</h2>
        </div>
        <div className="modal__body">
          <form className="form" onSubmit={(e) => this.handleOnSubmit(e)}>
            <div className="form-group">
              <label htmlFor="gas_maintenance">{t(2103)} [km]</label>
              <input 
                type="text" 
                name="gas_maintenance"
                id="gas_maintenance" 
                value={gas_maintenance}
                onChange={e => this.setState({ gas_maintenance: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2104)}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="alert_max_frequency">{t(2093)}</label>
              <input 
                type="text" 
                name="alert_max_frequency"
                id="alert_max_frequency" 
                value={alert_max_frequency}
                onChange={e => this.setState({ alert_max_frequency: e.target.value })}
              />
              <span className='form-group__explanation explanation--top-margin'>
                {t(2094)}
              </span>
            </div>

            <div className="form-group form-group--no-margin">
              <input 
                type="submit" 
                value={t(122)} 
                className="btn btn--primary"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </div>
      </div>
    )
  }
}


export default connect(
  ({ alert }: RootState) => ({ alert }),
  { updateAlertGasMaintenance, fetchAlertsSettingsById }
)(AlertFormGasMaintenance)
import React from 'react';
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { fetchPersonId, updatePersonSettings } from '../../actions/person'
import { fetchAllAuthGroups } from '../../actions/group'
import { checkAuth } from '../../actions/auth'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import { GoogleMap, Marker } from '@react-google-maps/api'
import markerPosition from'../../img/marker-position.png'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_CURRENCY, DEFAULT_LANGUAGE, TIMEZONE } from '../../config'
import momentTimezones from '../../helpers/momentTimezones'
import t from '../translation/translate'
import languageTranslations from '../../helpers/languagesTranslations'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface SettingsProps {
  router: { navigate: (to: string) => any },
  person: { status: string },
  auth: {status: string, person_id: number, lat: number, lng: number, zoom: number, currency: string, language: string, timezone: string},
  checkAuth: () => Promise<any>,
  updatePersonSettings: (options: { id: number, settings: any }) => Promise<any>,
}


interface SettingsState {
  lat: number,
  lng: number,
  zoom: number,
  currency: string,
  address: string,
  formSubmitted: boolean,
  language: string,
  timezone: string,
}


export class Settings extends React.Component<SettingsProps, SettingsState> {

  state = {    
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    zoom: DEFAULT_ZOOM,
    currency: DEFAULT_CURRENCY,
    address: '',
    formSubmitted: false,
    language: DEFAULT_LANGUAGE,
    timezone: TIMEZONE,
  }


  componentDidMount () {
    this.getSettings();
  }


  getSettings = () => {
    this.props.checkAuth()
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        const { lat, lng, zoom, currency, language, timezone } = this.props.auth;
        this.setState({
          lat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
          lng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
          zoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM,
          currency: currency !== undefined && currency !== null ? currency : DEFAULT_CURRENCY,
          language: language !== undefined && language !== null ? language : DEFAULT_LANGUAGE,
          timezone: timezone !== undefined && timezone !== null ? timezone : TIMEZONE
        });
        this.drawMarker();
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true })
    const settings = {
      zoom: this.state.zoom,
      lat: this.state.lat,
      lng: this.state.lng,
      currency: this.state.currency,
      language: this.state.language,
      timezone: this.state.timezone
    }
    this.props.updatePersonSettings({ id: this.props.auth.person_id, settings })
    .then(() => {
      if(this.props.person.status === fetchStates.success) {
        this.getSettings()
      }
      this.setState({ formSubmitted: false })
    })
  }


  handleChangeZoom = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ zoom: parseInt(e.target.value )});
  }


  handleChangeAddress = (address: string) => {
    this.setState({ address });
  }


  handleSelect = (e: any) => {
    this.setState({ address: e })
    geocodeByAddress(e)
      .then((results: any) => 
          getLatLng(results[0])
          .then((result: any) => {
            this.setState({
              lat: result.lat,
              lng: result.lng
            })
            this.drawMarker();
        })
      )
      .catch((error:any) => console.error('Error', error));
  };


  handleMapClick = (e: any) => {
    this.setState({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
    this.drawMarker();
  }


  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.lat,
        lng: this.state.lng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerPosition,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }


  render() {

    const { status } = this.props.person;
    const { lat, lng, zoom, address, currency, formSubmitted, language, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(11)} 
          padding={['large']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate} 
          time={false}
          animate
          tabnav="settings"
          tabnavId={0}
        >

          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-section">
              <h2 className="form-group__title">{t(1040)}</h2>
              <div className="form-group">
                <label htmlFor="timezone">
                  {t(2402)}
                </label>
                <select 
                  name="timezone" 
                  value={timezone}
                  onChange={e => this.setState({ timezone: e.target.value })}
                > 
                  {momentTimezones.map((item, index) => <option key={index} value={item}>{item}</option>)}
                </select>
              </div>

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>


            <div className="form-section">
              <h2 className="form-group__title">{t(1006)}</h2>
              <div className="form-group">
                <label htmlFor="language">
                  {t(2043)}
                </label>
                <select 
                  name="language" 
                  value={language}
                  onChange={e => this.setState({ language: e.target.value })}
                >
                  {
                    languageTranslations.map((language, index) => (
                      <option key={index} value={language.shortcut}>{language.title}</option>
                    ))
                  }
                </select>
              </div>

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>


            <div className="form-section">
              <h2 className="form-group__title">{t(1004)}</h2>
              <div className="form-group">
                <label htmlFor="currency">{t(1011)}</label>
                <input 
                  type="text" 
                  name="currency"
                  id="currency" 
                  value={currency}
                  onChange={e => this.setState({ currency: e.target.value })}
                />
              </div>

              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>
            </div>


            <div className="form-section">

            <h2 className="form-group__title">{t(1005)}</h2>
            <div className="form-group">
              <label htmlFor="map">
                {t(1016)}
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={ this.handleChangeAddress }
                onSelect={ e => this.handleSelect(e) }
              >
            {(options: { getInputProps: any, suggestions: any, getSuggestionItemProps: any, loading: any }) => (
              <div>
                <input
                  {...options.getInputProps({
                    placeholder: t(1018),
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {options.loading && <div key={options.loading}>{t(2327)}</div>}
                  {options.suggestions.map((suggestion: {active: boolean, description: string}, index: number) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div key={index}
                        {...options.getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            </PlacesAutocomplete>
            </div>


              <div className="form-group">
                <div className="map-constraints-slider"> 
                  <label htmlFor="range">{t(1017)}: {zoom}</label>
                  <input 
                    type="range" 
                    id="zoom" 
                    value={ zoom } 
                    name="zoom" 
                    min="1" 
                    max="18" 
                    onChange={ this.handleChangeZoom }
                  />
                </div>
              </div>


              <div className="form-group">
                <input 
                  type="submit" 
                  value={t(104)} 
                  className="btn btn--primary card__footer--btn-left"
                  disabled={ formSubmitted && status === 'fetching' ? true : false }
                />
              </div>


              <div className="form-group">
                <div className="google-map google-map__settings">

                    <GoogleMap
                      center={{ lat, lng }}
                      zoom={ zoom }
                      onClick={ e => this.handleMapClick(e) }
                    >
                      {this.drawMarker()}
                      <div className="map-constraints-container" />
                    </GoogleMap>


                    <span className='form-group__explanation'>
                    {t(2178)}: {lng}, {t(2177)}: {lat}
                  </span>
                </div>
              </div>
            </div>


          </form>
        </Card>
      </TemplatePage>
    )
  }

}


export default withAuthorization(withRouter(connect(
  ({ auth, person, group }: RootState) => ({ auth, person, group }),
  { fetchPersonId, updatePersonSettings, checkAuth, fetchAllAuthGroups }
)(Settings)))
import { NavLink } from 'react-router-dom'
import Authorization from './Authorization'
import { IconCommunication, IconImei, IconUsers, IconGroups, IconVehicles, IconStations, IconLocation, IconDashboard, IconReports, IconModules, IconAlertsNotification, IconBilling, IconAccounts, IconRfid, IconWorkers, IconCheckpoints, IconWarning } from './Icons'
import t from '../translation/translate'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'


interface SideBarProps {
  auth: { authenticated: boolean, role: number },
  vehiclesTotalCount: number, 
  alertsNewCount: number,
}


const SideBar: React.FC<SideBarProps> = ({ auth, vehiclesTotalCount, alertsNewCount }) => {

  const location = useLocation()

  return (auth.authenticated === true && (

    <div className="sidebar">
      <nav className="sidebar__main-nav">

        <NavLink to="/" className={classnames('sidebar__nav-item', { 'sidebar__nav-item--current': location.pathname === '/' })}>
          <IconDashboard color="#ffffff" /> 
          <span className="sidebar__nav-title">
            {t(1)}
          </span>
        </NavLink>


        <Authorization requiresAuth="vehicles">
          <NavLink to="/vehicles" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,9) === '/vehicles'})}>
            <IconVehicles color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(3)}
            </span>
            {vehiclesTotalCount > 0 ? (<span className="sidebar__notification-counter notification-counter--success">{vehiclesTotalCount > 99 ? '+99' : vehiclesTotalCount}</span>) : null }
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="reports">
          <NavLink to="/reports" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,8) === '/reports'})}>
            <IconReports color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(5)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="alerts">
          <NavLink to="/alerts" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,7) === '/alerts'})}>
            <IconAlertsNotification color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(6)}
            </span>
              {alertsNewCount > 0 ? (<span className="sidebar__notification-counter notification-counter--alert">{alertsNewCount > 99 ? '+99' : alertsNewCount}</span>) : null }
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="stations">
          <NavLink to="/stations" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,9) === '/stations'})}>
            <IconStations color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(2)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="users">
          <NavLink to="/users" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,6) === '/users'})}>
            <IconUsers color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(4)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="groups">
          <NavLink to="/groups" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,7) === '/groups'})}>
            <IconGroups color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(215)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="billing">
          <NavLink to="/subscription" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,8) === '/subscription'})}>
            <IconBilling color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(139)}
            </span>
          </NavLink>
        </Authorization>



        {
          (auth.role === 1 || auth.role === 2) && (
            <h3>ADMIN</h3>
          )
        }



        <Authorization requiresAuth="accounts">
          <NavLink to="/accounts" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,9) === '/accounts'})}>
            <IconAccounts color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(189)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="distributor_account_groups">
          <NavLink to="/distributor-accounts" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,21) === '/distributor-accounts'})}>
            <IconAccounts color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(34)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="accounts">
          <NavLink to="/reports/account" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,16) === '/reports/account'})}>
            <IconReports color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(35)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="modules">
          <NavLink to="/modules" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,8) === '/modules'})}>
            <IconModules color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(7)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="checkpoints">
          <NavLink to="/checkpoints" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,12) === '/checkpoints'})}>
            <IconCheckpoints color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(37)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="rfid">
          <NavLink to="/rfid" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,5) === '/rfid'})}>
            <IconRfid color="#ffffff" /> 
            <span className="sidebar__nav-title">
              RFID
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="imei">
          <NavLink to="/imei" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,5) === '/imei'})}>
            <IconImei color="#ffffff" />  
            <span className="sidebar__nav-title">
              {t(9)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="data_map">
          <NavLink to="/data-map" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,9) === '/data-map'})}>
            <IconLocation color="#ffffff" />
            <span className="sidebar__nav-title">
              {t(8)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="communication">
          <NavLink to="/communication" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,14) === '/communication'})}>
            <IconCommunication color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(10)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="workers">
          <NavLink to="/workers" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname === '/workers'})}>
            <IconWorkers color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(227)}
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="workers">
          <NavLink to="/workers-state" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname === '/workers-state'})}>
            <IconWorkers color="#ffffff" /> 
            <span className="sidebar__nav-title">
              Workers State
            </span>
          </NavLink>
        </Authorization>


        <Authorization requiresAuth="error_log">
          <NavLink to="/errors" className={classnames('sidebar__nav-item', {'sidebar__nav-item--current': location.pathname.substring(0,7) === '/errors'})}>
            <IconWarning color="#ffffff" /> 
            <span className="sidebar__nav-title">
              {t(243)}
            </span>
          </NavLink>
        </Authorization>

      </nav>
    </div>

  )) || null
}


export default SideBar
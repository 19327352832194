import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllAuthGroups } from '../../actions/group'
import { fetchVehicleRefuels, fetchRefuelsByGroupAndDate } from '../../actions/refuel'
import { checkAuth } from '../../actions/auth'
import { Link } from 'react-router-dom'
import fetchStates from '../../types/fetchStates'
import Preloader from './Preloader'
import t from '../translation/translate'
import Authorization from './Authorization'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import markerRefuel from'../../img/marker-vehicle-refuel.png'
import markerRefuelStation from'../../img/marker-vehicle-refuel-success.png'


interface CardRefuellingsProps {
  auth: any, 
  refuel: any, 
  group: any,
  fetchAllAuthGroups: (options: {limit: number, skip: number}) => Promise<any>,
  fetchVehicleRefuels: (options: {limit: number, skip: number, filterDate: any, group_id: string}) => Promise<any>,
  fetchRefuelsByGroupAndDate: (options: { filterDate: any, group_id: string }) => Promise<any>,
  checkAuth: () => Promise<any>,
}


interface CardRefuellingsState {
  currentPage: number,
  perPage: number,
  filterDate: any,
  todayDate: any,
  authGroups: Array<any>,
  group_id: string,
  refuelPins: Array<any>,
  vehiclesRefuelCount: number,
  fetchingData: boolean,
  lat: number,
  lng: number,
  zoom: number,
}


export class CardRefuellings extends React.Component<CardRefuellingsProps, CardRefuellingsState> {


  state = {
    currentPage: 0,
    perPage: 10,
    filterDate: new Date(),
    todayDate: new Date(),
    authGroups: [],
    group_id: 'all',
    vehiclesRefuelCount: 0,
    fetchingData: false,
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    zoom: DEFAULT_ZOOM,
    refuelPins: []
  }

  componentDidMount = async () => {
    await this.getAuthMapSettings();
    await this.fetchAllAuthGroups();
  }


  getAuthMapSettings = async () => {
    await this.props.checkAuth()
    const { status, lat, lng, zoom } = this.props.auth;
    if(status === fetchStates.success) {
      this.setState({
        lat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
        lng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
        zoom: lng !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM
      });
    }
  }


  fetchAllAuthGroups = async () => {
    await this.props.fetchAllAuthGroups({ limit: 1000, skip: 0 });
    const { status, authGroups } = this.props.group;
    if(status === fetchStates.success) {
      const { filterDate, group_id } = this.state;
      this.setState({ authGroups }, () => this.reloadPage({ filterDate, group_id }));
    }
  }


  reloadPage = async (options: { filterDate: any, group_id: string }) => {
    const { filterDate, group_id } = options;
    this.setState({ fetchingData: true });
    await this.props.fetchRefuelsByGroupAndDate({ filterDate: moment(filterDate).format('YYYY-MM'), group_id });
    const { status, refuelPins } = this.props.refuel
    if(status === fetchStates.success) {
      this.setState({ refuelPins });
    }
    this.setState({ fetchingData: false });
  }


  updateFilterDate = (date: any) => {
    this.setState({ filterDate: date, currentPage: 0 });
    this.reloadPage({ filterDate: date, group_id: this.state.group_id })
  }


  handleSelectGroup = (group_id: string) => {
    this.setState({ group_id, currentPage: 0 })
    this.reloadPage({ filterDate: this.state.filterDate, group_id })
  }


  render() {

    const { filterDate, todayDate, authGroups, group_id, lat, lng, zoom, refuelPins } = this.state;
    const { status } = this.props.refuel;

    console.log(refuelPins)

    return (
      <Authorization requiresAuth="vehicles">
        <div className="card animation--slide-in-from-bottom">
          <div className="card__header">
            <span>
              <h3 className="card__header-title">
                Refuel points
              </h3>
            </span>
          </div>
          <div className="card__body card__body--wrapper">
          <form className="form">
              <div className="filter">
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(2050)}</span>
                  <span className="filter_filter-select">
                    <DatePicker
                      selected={filterDate}
                      selectsStart
                      onChange={this.updateFilterDate}
                      dateFormat='yyyy-MM'
                      maxDate={todayDate}
                      required={true}
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </span>
                </span>
                <span className="filter__filter-group">
                  <span className="filter__filter-text">{t(2429)}</span>
                  <span className="filter_filter-select">
                    <select 
                      name="groups" 
                      onChange={e => this.handleSelectGroup(e.target.value)}
                      value={group_id}
                    >
                      <option key="0" value="all">{t(1152)}</option>
                      { 
                        authGroups.map((group: {group_id: string, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                      }
                    </select>
                  </span>
                </span>
              </div>
            </form>
            { status === fetchStates.fetching ? (
                  <Preloader type="preloader-center" />
              ) : (
                <>
                  <GoogleMap
                    center={{ lat, lng }}
                    zoom={ zoom }
                  >
                    {
                      refuelPins && refuelPins.map((pin: { id: number, loc_lt: string, loc_ln: string, station_id: string }) => {
                        if( pin.station_id === '0' ) {
                          return <Marker
                          key={pin.id}
                          position={{
                            lat: parseFloat(pin.loc_lt),
                            lng: parseFloat(pin.loc_ln)
                          }}
                          icon={
                            // @ts-expect-error
                            new window.google.maps.MarkerImage(
                              markerRefuel,
                              null, /* size is determined at runtime */
                              null, /* origin is 0,0 */
                              null, /* anchor is bottom center of the scaled image */
                              new window.google.maps.Size(48, 48)
                            )
                          }
                        />
                        }
                        return <Marker
                                  key={pin.id}
                                  position={{
                                    lat: parseFloat(pin.loc_lt),
                                    lng: parseFloat(pin.loc_ln)
                                  }}
                                  icon={
                                    // @ts-expect-error
                                    new window.google.maps.MarkerImage(
                                      markerRefuelStation,
                                      null, /* size is determined at runtime */
                                      null, /* origin is 0,0 */
                                      null, /* anchor is bottom center of the scaled image */
                                      new window.google.maps.Size(48, 48)
                                    )
                                  }
                                />
                      })
                    }
                    <div className="map-refuel-counter">REFUELS: { refuelPins.length }</div>
                    <div className="map-constraints-container" />
                  </GoogleMap>
                  <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>
                </>
              )
            }
          </div>    
        </div>
      </Authorization>
    );
  }
}


export default connect(
  ({ auth, refuel, group }: RootState) => ({ auth, refuel, group }),
  { checkAuth, fetchAllAuthGroups, fetchVehicleRefuels, fetchRefuelsByGroupAndDate }
)(CardRefuellings);
import { CHECKPOINT } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchCheckpoint = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch: any) => {
  dispatch({ type: CHECKPOINT.FETCH });
  return fetch(`${API_ADDRESS}/checkpoint/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: CHECKPOINT.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: CHECKPOINT.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllCheckpoints = (options: { limit: number, skip: number, param: string, paramValue: boolean }) => fetchCheckpoint({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip + '&param=' + options.param + '&paramValue=' + options.paramValue,
  options: { credentials: 'include' },
  SUCCESS_TYPE: CHECKPOINT.FETCH_ALL_SUCCESS
});


export const fetchAllCheckpointsVisits = (options:{ limit: number, skip: number }) => fetchCheckpoint({
  endpoint: 'visits/?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: CHECKPOINT.FETCH_ALL_VISITS_SUCCESS
});


export const fetchCheckpointById = (id: number) => fetchCheckpoint({
  endpoint: `${id}`,
  options: { credentials: 'include' },
  SUCCESS_TYPE: CHECKPOINT.FETCH_ID_SUCCESS
});


export const deleteCheckpointById = (id: number) => fetchCheckpoint({
  endpoint: `${id}`,
  options: {
    method: 'DELETE',
    options: { credentials: 'include' },
  },
  SUCCESS_TYPE: CHECKPOINT.FETCH_DELETE_SUCCESS
});


export const addNewCheckpoint = (options: {checkpoint:any}) => fetchCheckpoint({
  endpoint: '',
  options: {
    method: 'POST',
    body: JSON.stringify({ ...options.checkpoint }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: CHECKPOINT.FETCH_ADD_SUCCESS
});


export const updateCheckpoint = (options: { checkpoint:any }) => fetchCheckpoint({
  endpoint: `${options.checkpoint.id}`,
  options: {
    method: 'PUT',
    body: JSON.stringify({ ...options.checkpoint }),
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  },
  SUCCESS_TYPE: CHECKPOINT.FETCH_UPDATE_SUCCESS
});


export const fetchDateCheckpointVisitsVehicle = (options:{ limit: number, skip: number, filterDateFrom: string, filterDateTo: string, filterVehicle: string, filterCheckpoint: string }) => fetchCheckpoint({
  endpoint: 'visits-vehicle/?limit=' + options.limit + '&skip=' + options.skip + '&filterDateFrom=' + options.filterDateFrom + '&filterDateTo=' + options.filterDateTo + '&filterVehicle=' + options.filterVehicle + '&filterCheckpoint=' + options.filterCheckpoint,
  options: { credentials: 'include' },
  SUCCESS_TYPE: CHECKPOINT.FETCH_ALL_VISITS_VEHICLE_SUCCESS
});


export const fetchCheckpointVisitById = (id:number) => fetchCheckpoint({
  endpoint: 'checkpoint-visit/' + id,
  options: { credentials: 'include' },
  SUCCESS_TYPE: CHECKPOINT.FETCH_CHECKPOINT_VISIT_SUCCESS
});
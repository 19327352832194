import React from 'react'
import { RootState } from '../../reducers'
import { fetchRfidById, updateRfid } from '../../actions/rfid'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import classnames from 'classnames'


interface RfidEditProps {
  router: {params: { id: string }, navigate: (to: string) => any},
  rfid: any,
  fetchRfidById: (id: string) => Promise<any>,
  updateRfid: (rfid:any) => Promise<any>,
}


interface RfidEditState {
  rfid_id: string,
  rfid_number: string,
  custom_id: string,
  label: string,
  timezone: string,
  formSubmitted: boolean
}


export class RfidEdit extends React.Component<RfidEditProps, RfidEditState> {

  state = {
    rfid_id: '',
    rfid_number: '',
    custom_id: '',
    label: '',
    timezone: TIMEZONE,
    formSubmitted: false
  }


  componentDidMount() {
    this.props.fetchRfidById(this.props.router.params.id)
    .then(() => {
      if(this.props.rfid.status === fetchStates.success) {
        const { rfid_id, rfid_number, custom_id, label } = this.props.rfid.rfid
        this.setState({ rfid_id, rfid_number, custom_id, label })
      }
    })
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    this.setState({ formSubmitted: true })
    const { rfid_id, rfid_number, custom_id, label } = this.state;
    const rfid = {rfid_id, rfid_number, custom_id, label}
    this.props.updateRfid(rfid) 
    .then(() => {
      if(this.props.rfid.status === fetchStates.success) {
        this.setState({ formSubmitted: false });
        this.props.router.navigate('/rfid');
      }
    })
    this.setState({ formSubmitted: false })
  }



  render() {

    const { fields, status } = this.props.rfid;
    const { rfid_number, custom_id, label, formSubmitted } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(212)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>

            <div className="form-group">
              <label htmlFor="rfid_number">
                {t(1142)}
              </label>
              <input 
                type="text" 
                name="rfid_number"
                id="rfid_number" 
                value={rfid_number}
                onChange={e => this.setState({ rfid_number: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('rfid_number') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="custom_id">
                {t(2105)}
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>


            <div className="form-group">
              <label htmlFor="label">
                {t(2410)}
              </label>
              <input 
                type="text" 
                name="label"
                id="label" 
                value={label}
                onChange={e => this.setState({ label: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('label') })}
              />
            </div>


            <div className="form-group">
              <input 
                type="submit" 
                value={t(213)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
            </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ rfid }: RootState) => ({ rfid }),
  { fetchRfidById, updateRfid }
)(RfidEdit)))
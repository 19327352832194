import Button from '../Button'
import { GoogleMap, Marker, Circle } from '@react-google-maps/api'
import { DEFAULT_ZOOM } from '../../../config'
import alertTypes from '../../../helpers/alertTypesToString'
import alertTypesTitle from '../../../helpers/alertTypesTitle'
import alertIconFromNumber from '../../../helpers/alertIconFromNumber'
import markerVehiclePosition from'../../../img/marker-vehicle-position.png'
import moment from 'moment-timezone'
import t from '../../translation/translate'


interface AlertBoxGasGeoFenceProps {
  alert: any,
  settings: any,
  timezone: string,
}


const drawGeofenceCircle = (options: { lat: number, lng: number, range: number, inverted: boolean }) => {
  const { lat, lng, range, inverted } = options
  return <Circle
    // required
    center={{
      lat, lng
    }}
    // required
    options={{
      strokeColor: inverted === true ? '#E34B5F' : '#248E63',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: inverted === true ? '#E34B5F' : '#248E63',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: range,
      zIndex: 1
    }}
  />
}


const AlertBoxGasGeoFence: React.FC<AlertBoxGasGeoFenceProps> = ({ alert, settings, timezone }) => {



  return (
    <div className="alert-box alert-box--geo-fence">
      <div className="alert-box__icon">{alertIconFromNumber(parseInt(alert.type), 64, '#ffffff')}</div>
      <div className="alert-box__body">
        <h2 className="alert-box__title">{alertTypesTitle(parseInt(alert.type))}</h2>
        <div className="alert-box__date">{moment(alert.ts*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}</div>


        {
      alert.loc_lt && alert.loc_ln ? (
        <GoogleMap
        center={{ 
          lat: parseFloat(alert.loc_lt), 
          lng: parseFloat(alert.loc_ln) 
        }}
        zoom={ DEFAULT_ZOOM }
        >



        <Marker
          position={{
            lat: parseFloat(alert.loc_lt),
            lng: parseFloat(alert.loc_ln)
          }}
          icon={
            // @ts-expect-error
            new window.google.maps.MarkerImage(
              markerVehiclePosition,
              null, /* size is determined at runtime */
              null, /* origin is 0,0 */
              null, /* anchor is bottom center of the scaled image */
              new window.google.maps.Size(48, 48)
            )
          }
        >
          { drawGeofenceCircle({ lat: parseFloat(settings.alerts_geo_lt), lng: parseFloat(settings.alerts_geo_ln), range: parseInt(settings.alerts_geo_v), inverted: settings.alerts_geo_in === 1 ? true : false }) }
        </Marker>


        <div className="map-constraints-container" />
      </GoogleMap> ) :
      (
        <div className="alert__long-lat-missing">
          <div className="msg msg--danger">Sorry, there are no longitude and latitude data available.</div>
        </div>
      )
    }


      </div>
      <div className="alert-box__footer">
        <Button classes='btn btn--primary btn--centered' link={`/vehicles/alerts/${alert.vehicle_id}/${alertTypes(parseInt(alert.type))}`} title={t(2077)} clickable={false} handleOnClick={() => console.log()} />
      </div>
    </div>
  )
}


export default AlertBoxGasGeoFence
import React from 'react'
import { RootState } from '../../reducers'
import { fetchAllVehicles, fetchVehiclesSearch } from '../../actions/vehicle'
import { checkAuth } from '../../actions/auth'
import { fetchAllGroups } from '../../actions/group'
import queryString from 'query-string'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import TableFooter from '../partials/TableFooter';
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import Modal from '../partials/Modal'
import ModalTableVehicles from '../partials/ModalTableVehicles'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { IconVisibility, IconSettings, IconLinkOn, IconLinkOff, IconLockOpen, IconLockClosed, IconFuelLvl4, IconFuelLvl3, IconFuelLvl2, IconFuelLvl1, IconFuelLvl0, IconDropUp, IconDropDown, IconTableColumns, IconIGL0, IconIGL1, IconIGL2, IconIGL3, IconIGL4, IconIGL5, IconIGL6 } from '../partials/Icons'
import getGasControlerModel from '../../helpers/gasControllerModel'
import formatBytes from '../../helpers/formatBytes'
import { Tooltip } from 'react-tooltip'
import Preloader from '../partials/Preloader'
import getGasSensorType from '../../helpers/getGasSensorType'


// browser detect visibility change
let visibilityChange: string = '';
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange';
}


interface VehiclesProps {
  router: { location: {search: string}, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  group: any,
  checkAuth: () => Promise<any>,
  fetchAllGroups: (options: { limit: number, skip: number }) => Promise<any>,
  fetchAllVehicles: (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => Promise<any>,
  fetchVehiclesSearch: (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string, search: string }) => Promise<any>,
}


interface VehiclesState {
  vehicles: any,
  vehiclesCount: number,
  interval: boolean,
  currentPage: number,
  perPage: number,
  search: string,
  search_phrase: string,
  groups: Array<any>,
  group_id: string,
  runInterval: boolean,
  param: string,
  paramValue: boolean,
  timezone: string,
  timeNow: any,
  timeOverdue: any,
  tables_options: {
    vehicles: {
      buffer: boolean,
      locked: boolean,
      paired: boolean,
      auto_lock: boolean,
      fuel_lvl: boolean,
      gas_sensor_type: boolean,
      igl: boolean,
      ignition: boolean,
      refuels_number: boolean,
      unlocked: boolean,
      connected: boolean,
      last_connected: boolean,
      custom_id: boolean,
      gas_usage: boolean,
      compatible: boolean,
      calendar_lock: boolean,
      locked_times: boolean,
      payment_date: boolean,
      visited_competitor: boolean,
      controller_connected: boolean,
      module_imei: boolean,
      group_id: boolean,
      ignore_payment: boolean,
      module_version: boolean,
      data_usage: boolean,
    }
  },
  dataLoaded: boolean,
  intervalId: any,
  showModal: boolean,
  modalType: any,
}


export class Vehicles extends React.Component<VehiclesProps, VehiclesState> {

  state = {
    vehicles: [],
    vehiclesCount: 0,
    interval: false,
    currentPage: 0,
    perPage: 10,
    search_phrase: '',
    search: '',
    groups: [],
    group_id: 'all',
    runInterval: true,
    param: 'custom_id',
    paramValue: false,
    timezone: TIMEZONE,
    timeNow: moment().format('X'),
    timeOverdue: moment().subtract(2, 'weeks').format('X'),
    tables_options: {
      vehicles: {
        buffer: false,
        locked: false,
        paired: false,
        auto_lock: false,
        fuel_lvl: false,
        gas_sensor_type: false,
        igl: false,
        ignition: false,
        refuels_number: false,
        unlocked: false,
        connected: false,
        last_connected: false,
        custom_id: false,
        gas_usage: false,
        compatible: false,
        calendar_lock: false,
        locked_times: false,
        payment_date: false,
        visited_competitor: false,
        controller_connected: false,
        module_imei: false,
        group_id: false,
        ignore_payment: false,
        module_version: false,
        data_usage: false,
      }
    },
    dataLoaded: false,
    intervalId: 0,
    showModal: false,
    modalType: '',
  }


  handleVisibilityChange = () => {
    if(document['hidden']) {
      this.setState({runInterval: false});
    } else {
      this.setState({runInterval: true});
      this.props.checkAuth();
      this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id }); 
    }
  }


  componentDidMount() {

    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // add event listener for visibilityChange
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

    // set interval to referesh the page
    const intervalId = setInterval(this.timer, 10000);
    this.setState({intervalId: intervalId});

    // check for auth (we are still loged in)
    this.fetchTablesOptions();

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage
    let param = this.state.param
    let paramValue = this.state.paramValue
    let search = this.state.search
    let group_id = this.state.group_id

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search);
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.param !== null && parsed.param !== undefined) {
      param = parsed.param.toString()
    }
    if(parsed.paramValue !== null && parsed.paramValue !== undefined) {
      paramValue = parsed.paramValue === 'true' ? true : false
    }
    if(parsed.group_id !== null && parsed.group_id !== undefined) {
      group_id = parsed.group_id.toString()
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 101) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/vehicles?page=${currentPage}&perPage=${perPage}&param=${param}&paramValue=${paramValue}&group_id=${group_id}`)
      }
    }
    if(parsed.search !== null && parsed.search !== undefined) {
      search = parsed.search.toString()
    }
    if(search !== '') {
      return this.handleSearch(search)
    }

    this.setState({ perPage, currentPage, param, paramValue, search, group_id, dataLoaded: false })
    this.reloadPage({ perPage, currentPage, param, paramValue, group_id }); 

    this.props.fetchAllGroups({ limit: 1000, skip: 0 })
    .then(() => {
      if(this.props.group.status === fetchStates.success) {
        this.setState({ groups: this.props.group.groups  })
      }
    })

  }


  componentWillUnmount() {
    // remove event listener for visibilityChange
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
    this.setState({ runInterval: false });
    clearInterval(this.state.intervalId);
  }


  timer = () => {
    if(this.state.runInterval === true && this.props.vehicle.status !== 'error') {
      this.reloadPage({ perPage: this.state.perPage, currentPage: this.state.currentPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id }); 
      this.props.checkAuth()
    }
  }


  getAllVehicles = (options: {limit: number, skip: number, param: string, paramValue: boolean, group_id: string}) => {
    const { limit, skip, param, paramValue, group_id } = options
    this.props.fetchAllVehicles({ limit, skip, param, paramValue, group_id })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicles, vehiclesCount } = this.props.vehicle
        this.setState({ vehicles, vehiclesCount, dataLoaded: true })
      }
    }); 
  }


  getVehicleSearch = (options:{ search: string, limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => {
    const { search, limit, skip, param, paramValue, group_id } = options
    this.props.fetchVehiclesSearch({ search, limit, skip, param, paramValue, group_id })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicles, vehiclesCount } = this.props.vehicle
        this.setState({ vehicles, vehiclesCount, dataLoaded: true })
      }
    })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0, dataLoaded: false })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/vehicles?page=0&perPage=${e.target.value}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage, dataLoaded: false })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/vehicles?page=${prevPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage, dataLoaded: false })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
    this.props.router.navigate(`/vehicles?page=${nextPage}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number, param: string, paramValue: boolean, group_id: string }) => {
    const { perPage, currentPage, param, paramValue, group_id } = options
    let reloadParam = this.state.param
    let reloadParamValue = this.state.paramValue
    let reloadGroupId = this.state.group_id
    if(param) { reloadParam = param }
    if(paramValue) { reloadParamValue = paramValue }
    if(group_id) { reloadGroupId = group_id }
    if(this.state.search === '') {
      this.getAllVehicles({ limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue, group_id: reloadGroupId })
    } else {
      this.getVehicleSearch({ search: this.state.search, limit: perPage, skip: perPage * currentPage, param: reloadParam, paramValue: reloadParamValue, group_id: reloadGroupId })
    }
  }


  handleSearch = (search_phrase: string) => {
    if(search_phrase !== '') {
      this.setState({ search: search_phrase, dataLoaded: false })
      this.getVehicleSearch({ search: search_phrase, limit: this.state.perPage, skip: 0, param: this.state.param, paramValue: this.state.paramValue, group_id: this.state.group_id })
      this.props.router.navigate(`/vehicles?search=${search_phrase}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}&group_id=${this.state.group_id}`)
    }
  }


  handleSearchClose = () => {
    this.setState({ search: '', search_phrase: '', currentPage: 0, dataLoaded: false });
    this.getAllVehicles({ limit: this.state.perPage, skip: 0, param: 'custom_id', paramValue: false, group_id: this.state.group_id })
    this.props.router.navigate(`/vehicles`)
  }


  handleGroupFilterValue = (group_id: string) => {
    this.setState({ group_id, dataLoaded: false })
    this.props.router.navigate(`/vehicles?search=${this.state.search}&perPage=${this.state.perPage}&param=${this.state.param}&paramValue=${this.state.paramValue}&group_id=${group_id}`);
    if(this.state.search !== '') {
      this.getVehicleSearch({ limit: this.state.perPage, skip: 0, param: 'custom_id', paramValue: false, group_id, search: this.state.search })
    } else {
      this.getAllVehicles({ limit: this.state.perPage, skip: 0, param: 'custom_id', paramValue: false, group_id })
    }
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue, dataLoaded: false })
    if(this.state.search === '') {
      this.getAllVehicles({ limit: this.state.perPage, skip: this.state.perPage * this.state.currentPage, param, paramValue: !this.state.paramValue, group_id: this.state.group_id })
      this.props.router.navigate(`/vehicles?page=${this.state.currentPage}&perPage=${this.state.perPage}&param=${param}&paramValue=${!this.state.paramValue}&group_id=${this.state.group_id}`);
    } else {
      this.getVehicleSearch({ search: this.state.search, limit: 10, skip: 0, param: param, paramValue: !this.state.paramValue, group_id: this.state.group_id })
      this.props.router.navigate(`/vehicles?search=${this.state.search}&perPage=${this.state.perPage}&param=${param}&paramValue=${!this.state.paramValue}&group_id=${this.state.currentPage}`)
    }
  }


  fetchTablesOptions = () => {
    this.props.checkAuth()
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        const { tables_options } = this.props.auth
        this.setState({ tables_options })
      }
    })
  }


  setShowModal = (show: boolean) => {
    this.setState({ showModal: show })
    if(show === false) {
      this.fetchTablesOptions()
    }
  }


  updateShowTableColumns = () => {
    this.setState({ runInterval: false, showModal: true, modalType: <ModalTableVehicles showModal={this.setShowModal} /> })
  }


  render() {

    const { vehicles, vehiclesCount, currentPage, perPage, search_phrase, param, paramValue, timezone, timeNow, tables_options, dataLoaded } = this.state;
    const { billing_enabled, hide_lock_feature } = this.props.auth;

    return (
      <TemplatePage>

        <Card 
          title='Vehicles' 
          button={true} 
          buttonLink='/vehicles/add' 
          buttonTitle={t(101)} 
          buttonClickable={false}
          buttonHandleOnClick={() => null}
          groupFilter={true}
          handleGroupFilterValue={(e:any) => this.handleGroupFilterValue(e.target.value)}
          groupFilterGroups={this.state.groups}
          search={true} 
          searchPlaceholder={t(121)}
          searchValue={search_phrase}
          handleSearchValue={e => this.setState({ search_phrase: e.target.value })} 
          handleSearch={() => this.handleSearch(this.state.search_phrase)}
          handleSearchClose={() => this.handleSearchClose()}
          time={false}
          tabnav="vehicles"
          animate
        >

          { dataLoaded === false ? <Preloader type="fullscreen" /> : <></> }

          <Modal showModal={this.state.showModal} setShowModal={this.setShowModal} modalType={this.state.modalType} />

          <div className="table-wrapper">
          <table className="table">
            <thead className="table__header">
              <tr>
                <th className="th--ignore"><span className="" onClick={e => this.updateShowTableColumns()}><IconTableColumns /></span></th>



                { tables_options && tables_options.vehicles.module_imei === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('module_imei')}>{t(2002)} {param === 'module_imei' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                ) }

                { tables_options && tables_options.vehicles.custom_id === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.group_id === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('group_id')}>{t(225)} {param === 'group_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.payment_date === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('payment_date')}>{t(1131)} {param === 'payment_date' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.ignore_payment === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('ignore_payment')}>Ignore payment {param === 'ignore_payment' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.ignition === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('ignition')}>{t(2397)} {param === 'ignition' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.gas_usage === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('gas_usage')}>{`${t(2061)}`} {param === 'gas_usage' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.fuel_lvl === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('fuel_lvl')}>{t(2062)} {param === 'fuel_lvl' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}


                { tables_options && tables_options.vehicles.gas_sensor_type === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('gas_sensor_type')}>Gas sensor type {param === 'gas_sensor_type' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}


                { tables_options && tables_options.vehicles.igl === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('igl')}>IGL {param === 'igl' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { tables_options && tables_options.vehicles.refuels_number === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('refueled')}>{t(2063)} {param === 'refueled' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}


                { hide_lock_feature || (tables_options && tables_options.vehicles.calendar_lock === false) ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('calendar_lock')}>{t(2513)} {param === 'calendar_lock' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { hide_lock_feature || (tables_options && tables_options.vehicles.unlocked === false) ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('unlocked')}>{t(2064)} {param === 'unlocked' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}

                { hide_lock_feature || (tables_options && tables_options.vehicles.locked_times === false) ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('locked_times')}>{t(2065)}  {param === 'locked_times' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}   

                { tables_options && tables_options.vehicles.visited_competitor === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('visited_competitor')}>{t(2066)} {param === 'visited_competitor' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  


                { hide_lock_feature || (tables_options && tables_options.vehicles.paired === false) ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('paired')}>{t(2067)} {param === 'paired' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  

                { hide_lock_feature || (tables_options && tables_options.vehicles.auto_lock === false) ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('auto_lock')}>{t(2416)} {param === 'auto_lock' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  

                { tables_options && tables_options.vehicles.last_connected === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('connected')}>{t(2068)} {param === 'connected' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  

                { tables_options && tables_options.vehicles.buffer === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('buffer')}>{t(2394)} {param === 'buffer' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  


                { tables_options && tables_options.vehicles.connected === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('controller_connected')}>{t(2396)} {param === 'controller_connected' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}


                { tables_options && tables_options.vehicles.compatible === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('compatible')}>{t(2395)} {param === 'compatible' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  


                { tables_options && tables_options.vehicles.module_version === false ? null : (
                  <th className="th--clickable" onClick={() => this.setParam('module_version')}>{t(2412)} {param === 'module_version' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )}  

                { tables_options && tables_options.vehicles.data_usage === false ? null : ( 
                  <th className="th--clickable" onClick={() => this.setParam('data_usage')}>{t(2420)} {param === 'data_usage' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                )} 
                <th></th>
              </tr>
            </thead>
            
            <tbody className="table__body table__body--striped">
              {vehicles !== undefined && vehicles.length > 0 && 
              vehicles.map((item: any, index: number) => {


                const payment_date = moment(item.payment_date).tz(timezone).format('X')
                const now = moment()
                const days = now.diff(item.connected, "days")
                let vehicleOnline = false;
                let vehicleUnknownState = false;
                if(item.ignition === 1 && (parseInt(moment(item.connected).format('X')) > parseInt(moment().format('X')) - 300)) {
                  vehicleOnline = true;
                }
                if(item.ignition === 1 && (parseInt(moment(item.connected).format('X')) < parseInt(moment().format('X')) - 300)) {
                  vehicleUnknownState = true;
                }
                let vehicleDead = false;
                const ts_now : number = parseInt(moment().format('X'))
                const ts_connected: number = item.connected && item.connected !== null ? moment(item.connected).unix() : 0
                if(ts_now - ts_connected > 86400) {
                  vehicleDead = true
                }

                return (
                <tr key={item.vehicle_id} className={classnames('table-row', { 
                  'table-row__disabled': vehicleDead
                  })}>
                    <td className="simple">
                      <Link to={`/vehicles/now/${item.vehicle_id}`}>
                        <Tooltip anchorId={`tooltip-vehicle-overview${index}`}/>
                        <span id={`tooltip-vehicle-overview${index}`} data-tooltip-content={t(16)}>
                          <IconVisibility 
                            task 
                            button 
                            color="#ffffff"
                          />
                        </span>
                      </Link>
                    </td>

                  { tables_options && tables_options.vehicles.module_imei === false ? null : ( 
                    <td>
                      {item.module_imei}
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.custom_id === false ? null : ( 
                    <td>
                      {item.custom_id}
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.group_id === false ? null : ( 
                    <td>
                      {item.group_title}
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.payment_date === false ? null : ( 
                    <td>
                      <Tooltip anchorId={`tooltip-vehicle-payment${index}`}/>
                      { billing_enabled === true ? 
                        (
                          <>
                            {item.ignore_payment === true ? (
                              <span id={`tooltip-vehicle-payment${index}`} data-tooltip-content={moment(parseInt(payment_date)*1000).tz(timezone).format('DD/MM/YYYY')}>
                                <span className="table__highlight table__highlight--info">n/a</span>
                              </span>
                            ) : (
                              <>
                                { 
                                  payment_date > timeNow && 
                                  <span id={`tooltip-vehicle-payment${index}`} data-tooltip-content={moment(parseInt(payment_date)*1000).tz(timezone).format('DD/MM/YYYY')}>
                                    <span className="table__highlight table__highlight--success">{t(1206)}</span>
                                  </span> 
                                }

                                { 
                                  payment_date < timeNow && 
                                  <span id={`tooltip-vehicle-payment${index}`} data-tooltip-content={moment(parseInt(payment_date)*1000).tz(timezone).format('DD/MM/YYYY')}>
                                    <span className="table__highlight table__highlight--danger">{t(1208)}</span>
                                  </span>
                                }
                              </>
                            )}

                          </>
                        ) : (
                          <>
                            <span className="table__highlight table__highlight--warning">n/a</span>
                          </>
                        )
                      }
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.ignore_payment === false ? null : ( 
                    <td>
                      <div className={classnames('traffic-light', { 
                        'traffic-light--green': item.ignore_payment === true,
                        'traffic-light--red':item.ignore_payment === false
                        })}>  
                      </div>
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.ignition === false ? null : ( 
                    <td>
                      {
                        <div className={classnames('traffic-light', { 
                          'traffic-light--green': vehicleOnline,
                          'traffic-light--red' : !vehicleOnline && !vehicleUnknownState
                          })}>  
                        </div>
                      }


                    </td>
                  )}

                  { tables_options && tables_options.vehicles.gas_usage === false ? null : ( 
                    <td>
                      {item.gas_usage}%
                    </td>
                  )}


                  { tables_options && tables_options.vehicles.fuel_lvl === false ? null : ( 
                    <td>
                      {item.fuel_lvl === 0 && <IconFuelLvl0 />}
                      {item.fuel_lvl === 1 && <IconFuelLvl1 />}
                      {item.fuel_lvl === 2 && <IconFuelLvl2 />}
                      {item.fuel_lvl === 3 && <IconFuelLvl3 />}
                      {item.fuel_lvl === 4 && <IconFuelLvl4 />}
                    </td>
                  )}

                  { tables_options && tables_options.vehicles.gas_sensor_type === false ? null : ( 
                    <td>
                      <Tooltip anchorId={`tooltip-vehicle-gas-sensor-type${index}`}/>
                      {(item.gas_sensor_type === 0 || item.gas_sensor_type === 20) ? <span className="table__highlight table__highlight--success" id={`tooltip-vehicle-gas-sensor-type${index}`} data-tooltip-content={getGasSensorType(item.gas_sensor_type.toString())?.toString()}>WPGH</span> :  (item.gas_sensor_type === 255 ? <span className="table__highlight table__highlight--info" id={`tooltip-vehicle-gas-sensor-type${index}`} data-tooltip-content={getGasSensorType(item.gas_sensor_type.toString())?.toString()}>n/a</span> : <span className="table__highlight table__highlight--error" id={`tooltip-vehicle-gas-sensor-type${index}`} data-tooltip-content={getGasSensorType(item.gas_sensor_type.toString())?.toString()}>WPG</span>)}
                    </td>
                  )}


                  { tables_options && tables_options.vehicles.igl === false ? null : ( 
                    <td>
                      <Tooltip anchorId={`tooltip-vehicle-igl${index}`}/>
                      {
                        (item.gas_sensor_type === 0 || item.gas_sensor_type === 20) ? 
                        (
                          <span>
                            {item.igl === 0 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2342)}><IconIGL0 /></span>}
                            {item.igl === 1 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2343)}><IconIGL1 /></span>}
                            {item.igl === 2 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2344)}><IconIGL2 /></span>}
                            {item.igl === 3 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2345)}><IconIGL3 /></span>}
                            {item.igl === 4 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2346)}><IconIGL4 /></span>}
                            {item.igl === 5 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2347)}><IconIGL5 /></span>}
                            {item.igl === 6 && <span id={`tooltip-vehicle-igl${index}`} data-tooltip-content={t(2348)}><IconIGL6 /></span>}
                          </span>
                        ) : (
                          <span>
                            <span className="table__highlight table__highlight--info">n/a</span>
                          </span>
                        )
                      }

                    </td>
                  )}


                  { tables_options && tables_options.vehicles.refuels_number === false ? null : ( 
                    <td>
                      {item.refueled !== 0 ? (
                        <Link to={`/vehicles/refuelled/${item.vehicle_id}`}>
                          {item.refueled}
                        </Link>
                      ) : (
                        0
                      )}
                    </td>
                  )}


                  { hide_lock_feature || (tables_options && tables_options.vehicles.calendar_lock === false) ? null : ( 
                    
                    <td>
                      <Tooltip anchorId={`tooltip-vehicle-calendar-lock${index}`}/>
                      { item.calendar_lock_date === null ? 'n/a' : moment(item.calendar_lock_date).format('YYYY-MM-DD') }
                    </td>
                  
                )}


                  { hide_lock_feature || (tables_options && tables_options.vehicles.unlocked === false) ? null : ( 
                    
                      <td>
                        <Tooltip anchorId={`tooltip-vehicle-unlocked${index}`}/>
                        { item.paired !== 1 && <span id={`tooltip-vehicle-unlocked${index}`} data-tooltip-content={'not paired yet'}><IconLinkOff default color="#ffffff" /></span> }
                        { item.paired === 1 && item.unlocked === 1 && <span id={`tooltip-vehicle-unlocked${index}`} data-tooltip-content={'unlocked'}><Link to={`/vehicles/lock-unlock/${item.vehicle_id}`}><IconLockOpen table success color="#ffffff" /></Link></span> } 
                        { item.paired === 1 && item.unlocked === 0 && <span id={`tooltip-vehicle-unlocked${index}`} data-tooltip-content={'locked'}><Link to={`/vehicles/unlock/${item.vehicle_id}`}><IconLockClosed table error color="#ffffff" /></Link></span> }
                      </td>
                    
                  )}


                  { hide_lock_feature || (tables_options && tables_options.vehicles.locked_times === false) ? null : ( 
                        <td>
                          {item.locked_times !== 0 ? (
                            <Link to={`/vehicles/history/${item.vehicle_id}`}>
                              {item.locked_times}
                            </Link>
                          ) : (
                            0
                          )}
                        </td>
                  )}


                  { tables_options && tables_options.vehicles.visited_competitor === false ? null : ( 
                      <td>
                        {item.visited_competitor !== 0 ? (
                          <Link to={`/vehicles/visits/${item.vehicle_id}`}>
                            {item.visited_competitor}
                          </Link>
                        ) : (
                          0
                        )}
                      </td>
                  )}


                  { hide_lock_feature || (tables_options && tables_options.vehicles.paired === false) ? null : ( 
                    
                      <td>
                        <Tooltip anchorId={`tooltip-vehicle-paired${index}`}/>
                        <Link to={`/vehicles/pairing/${item.vehicle_id}`}>
                          { item.paired === 2 && <span id={`tooltip-vehicle-paired${index}`} data-tooltip-content={'never paired'}><IconLinkOff default color="#ffffff" /></span> /* default - when vehicle is added first time */ }
                          { item.paired === 1 && <span id={`tooltip-vehicle-paired${index}`} data-tooltip-content={'paired'}><IconLinkOn success color="#ffffff" /></span> /* paired - when vehicle is paired */ }
                          { item.paired === 0 && item.maint_prog === 0 && <span id={`tooltip-vehicle-paired${index}`} data-tooltip-content={'unpaired'}><IconLinkOff default color="#ffffff" /></span> /* unpaired - when vehicle is not paired yet */ }
                          { item.paired === 0 && item.maint_prog === 1 && <span id={`tooltip-vehicle-paired${index}`} data-tooltip-content={'unpaired for maintenance'}><IconSettings default color="#ffffff" /></span> /* unpaired - when vehicle is not paired yet */ }
                          
                        </Link>
                      </td>
                    
                  )}


                  { hide_lock_feature || (tables_options && tables_options.vehicles.auto_lock === false) ? null : ( 
                    
                      <td>
                        <div className={classnames('traffic-light', { 
                          'traffic-light--green': item.auto_lock === true,
                          'traffic-light--red':item.auto_lock === false
                          })}>  
                        </div>
                      </td>
                    
                  )}


                  { tables_options && tables_options.vehicles.last_connected === false ? null : ( 
                    <td>
                      <Tooltip anchorId={`tooltip-vehicle-connected${index}`}/>
                      <span id={`tooltip-vehicle-connected${index}`}  data-tooltip-content={item.connected ? moment(item.connected).tz(timezone).format('DD/MM/YYYY HH:mm') : 'n/a'}>
                        {item.connected ? (parseInt(moment(item.connected).format('YYYY')) < 2019 ? 'n/a' :  days + ` ${t(2403)}`) : 'n/a'}
                      </span>
                    </td>
                  )}


                  { tables_options && tables_options.vehicles.buffer === false ? null : ( 
                    <td>                      
                      <Tooltip anchorId={`tooltip-vehicle-buffer${index}`}/>
                      <Link to={`/vehicles/module/${item.vehicle_id}`}>
                        <span id={`tooltip-vehicle-buffer${index}`}  data-tooltip-content={`buffer:${item.buffer_records} ${item.buffer !== 0 ? moment(item.buffer*1000).tz(timezone).format('DD/MM/YYYY') : ''}`}>
                          <div className={classnames('traffic-light', { 
                            'traffic-light--green': item.buffer_records < 11 && moment().tz(timezone).format('DD/MM/YYYY') === moment(item.buffer*1000).tz(timezone).format('DD/MM/YYYY'),
                            'traffic-light--red': moment().diff(moment(item.buffer*1000).tz(timezone), 'days') >= 2
                            })}>  
                          </div>
                        </span>
                      </Link>
                    </td>
                  )}


                  { tables_options && tables_options.vehicles.connected === false ? null : ( 
                      <td>
                        <div className={classnames('traffic-light', { 
                          'traffic-light--green': item.controller_connected === 1 && item.ignition === 1,
                          'traffic-light--red': item.controller_connected === 0 && item.ignition === 1,
                          })}>
                        </div>
                      </td>
                  )}

                  { tables_options && tables_options.vehicles.compatible === false ? null : ( 
                    
                      <td>
                        <div className={classnames('traffic-light', { 
                          'traffic-light--green': item.compatible === 0 && item.ignition === 1 && item.controller_connected === 1,
                          'traffic-light--red': item.compatible === 1 && item.ignition === 1 && item.controller_connected === 1
                          })}>  
                        </div>
                      </td>
                    
                  )}
                  

 


                  { tables_options && tables_options.vehicles.module_version === false ? null : ( 
                      <td>
                        { item.module_version === null ? 'n/a' : getGasControlerModel(item.module_version) }
                      </td>
                  )}


                  { tables_options && tables_options.vehicles.data_usage === false ? null : ( 
                    <td>
                      { item.data_usage ? formatBytes(parseInt(item.data_usage)*1024) : 'n/a' }
                    </td>
                  )}


                  <td className="simple">
                    <Tooltip anchorId={`tooltip-vehicle-settings${index}`}/>
                    <Link to={`/vehicles/module/${item.vehicle_id}`}>
                      <span id={`tooltip-vehicle-settings${index}`} data-tooltip-content={t(11)}>
                        <IconSettings task button color="#ffffff" />
                      </span>
                    </Link>
                  </td>
                </tr>
                )
              })
            }
            </tbody>

            <TableFooter 
              button={false}
              colSpan={13}
              currentPage={ currentPage }
              perPage={ perPage }
              itemsCount={ vehiclesCount }
              prevPage={() => this.prevPage(currentPage - 1)}
              nextPage={() => this.nextPage(currentPage + 1)}
              updatePagination={this.updatePagination}
            />
          </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, group, auth }: RootState) => ({ vehicle, group, auth }),
  { fetchAllVehicles, fetchVehiclesSearch, checkAuth, fetchAllGroups }
)(Vehicles)))
import React from 'react'
import { Link } from 'react-router-dom'
import t from '../translation/translate'


interface TermsConditionsConsentProps {
  termsConditionsConsent: boolean,
  termsConditionsError: boolean,
  handleTermsConditionsContent: () => void,
}


const TermsConditionsConsent: React.FC<TermsConditionsConsentProps> = ({ termsConditionsConsent, termsConditionsError, handleTermsConditionsContent }) => {
  return (
      <div className="terms-conditions-consent">
        <div className="form-group__checkbox form-group__checkbox--large">
          <label className={termsConditionsError === true ? 'label--error' : ''}>
            <input 
              onChange={handleTermsConditionsContent}
              checked={termsConditionsConsent}
              type="checkbox" 
              name="default"  
            /> 
              {t(2373)} <Link to="/terms-conditions">{t(2370)}</Link>
          </label>
        </div>
      </div>
  )
}


export default TermsConditionsConsent
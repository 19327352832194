import { jsPDF } from "jspdf"
import moment from 'moment-timezone'
import logoImage from'../img/logo-client.png'
import t from '../components/translation/translate'
import calculateObjectReportSavingsNew from "./calculateObjectReportSavingsNew"


function createNewDayReportPDF(options: { data: any, currency: string, from: string, to: string }) {

  const { data, currency, from, to } = options

  // document settings
  const unit = "mm";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const doc = new jsPDF(orientation, unit, size);


  // building up the report
  const tableTitle = `Reports for ${from} - ${to}`;
  const numTableCols = 13

  // table defaults
  const fontSize = 8;
  const rowHeight = 10; // max 10 rowHeight with 15 perPage
  const rowWidth = 265;
  const perPage = 15; // max 15 perPage with 10 rowHeight
  const pagesNum = Math.ceil(data.length / perPage);
  const headerOffset = 35;
  const linePosition = headerOffset + rowHeight / 2 + fontSize / rowHeight;


  // add logo
  var img = new Image();
  img.src = logoImage;


  // loop throug pages
  for (let i = 0; i < pagesNum; i++) {

    if (i > 0) {
      doc.addPage();
    }

    // title
    doc.setTextColor(0, 0, 0);
    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.setFontSize(18);
    //doc.setFontStyle('normal');
    doc.addImage(img, 'PNG', 15, 14, 8, 8);
    doc.text(tableTitle, 27, 20);

    
    // table header
    doc.setFontSize(fontSize);
    // doc.setFontStyle('bold');
    doc.line(15, 25, 280, 25);
    

    const tableHeaderArray = [t(2050), 'ID', t(2052), t(2053), t(2224), t(2225), t(2226), t(2227), t(2228), t(2229), t(2230), t(2231), t(1061)];

    
    doc.setFillColor(255, 255, 255);
    doc.rect(15, 25, rowWidth, rowHeight, 'F');
    for (let x = 0; x < tableHeaderArray.length; x++) {
      doc.text(tableHeaderArray[x], 17 + rowWidth / numTableCols * x, 31.5);
    }
    doc.line(15, headerOffset, 280, headerOffset);
    


    // table content
    const pageOffset = i * perPage;
    const slicedData = data.slice(0 + pageOffset, 15 + pageOffset);
    const pageNum = i + 1;


    slicedData.forEach(function (item: any, index: number) {


      const rowTopPosition = headerOffset + rowHeight * index;
      // making the row stripy
      if (index % 2 === 0) {
        doc.setFillColor(231, 251, 255);
      } else {
        doc.setFillColor(255, 255, 255);
      }
      doc.rect(15, rowTopPosition, rowWidth, rowHeight, 'F');


      const objectReportSavings = calculateObjectReportSavingsNew(item);

      // looping through items in given row

      // date
      doc.text(item.d_stamp_to, 17, linePosition + rowHeight * index);


      // vehicle ID
      doc.text(item.custom_id.substring(0, 12), 17 + rowWidth / numTableCols * 1, linePosition + rowHeight * index);

      // distance
      doc.text(Math.round(objectReportSavings.TOTAL_DISTANCE_KM * 100) / 100 + ' km', 17 + rowWidth / numTableCols * 2, linePosition + rowHeight * index);

      // drive time
      doc.text(moment("1900-01-01 00:00:00").add(objectReportSavings.TOTAL_TIME_S, 'seconds').format("HH:mm:ss"), 17 + rowWidth / numTableCols * 3, linePosition + rowHeight * index);

      // lpg consumtion
      doc.text(Math.round(objectReportSavings.TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L * 100) / 100 + ' l', 17 + rowWidth / numTableCols * 4, linePosition + rowHeight * index);

      // lpg cost
      doc.text(Math.round(objectReportSavings.ALTERNATIVE_FUEL_COST * 100) / 100 + ' ' + currency, 17 + rowWidth / numTableCols * 5, linePosition + rowHeight * index);

      // estimated fuel costs
      doc.text(Math.round(objectReportSavings.DEFAULT_FUEL_COST * 100) / 100 + ' ' + currency, 17 + rowWidth / numTableCols * 6, linePosition + rowHeight * index);

      // estimated cost without LPG
      doc.text(Math.round(objectReportSavings.DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE * 100) / 100 + ' ' + currency, 17 + rowWidth / numTableCols * 7, linePosition + rowHeight * index);

      // % on LPG
      doc.text(Math.round(objectReportSavings.TOTAL_ALTERNATIVE_PERCENT * 100) / 100 + ' %', 17 + rowWidth / numTableCols * 8, linePosition + rowHeight * index);

      // estimated savings
      doc.text(Math.round(objectReportSavings.ESTIMATED_SAVINGS * 100) / 100 + ' ' + currency, 17 + rowWidth / numTableCols * 9, linePosition + rowHeight * index);

      // potential savings
      doc.text(Math.round(objectReportSavings.ESTIMATED_POTENTIAL_SAVINGS * 100) / 100 + ' ' + currency, 17 + rowWidth / numTableCols * 10, linePosition + rowHeight * index);

      // co2 savings
      doc.text(Math.round(objectReportSavings.TOTAL_SAVINGS_CO2_KG * 100) / 100 + ' kg', 17 + rowWidth / numTableCols * 11, linePosition + rowHeight * index);

      // data usage
      doc.text(Math.round((objectReportSavings.TOTAL_DATA_KB/1024) * 100) / 100 + ' MB', 17 + rowWidth / numTableCols * 12, linePosition + rowHeight * index);

      return;
      
    });


    // table footer
    doc.setTextColor(0, 0, 0);
    doc.text('PAGE ' + pageNum + '/' + pagesNum, rowWidth / 2, perPage * rowHeight + headerOffset + rowHeight);


  }


  // save document
  doc.save(`stelematics-reports-${from}-${to}.pdf`)

}


export default createNewDayReportPDF
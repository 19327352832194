import { Store } from 'react-notifications-component'
import t from '../components/translation/translate'


export const error = (msg: number) => {
  Store.addNotification({
    message: t(msg),
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}


export const success = (msg: number) => {
  Store.addNotification({
    message: t(msg),
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}
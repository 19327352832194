import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllErrorLogs } from '../../actions/error'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import moment from 'moment-timezone'
import { TIMEZONE } from '../../config'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import queryString from 'query-string'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface ErrorLogProps {
  router: { location: {pathname: string, search: string}, navigate: (to: string) => any },
  auth: { timezone: any },
  error: any,
  fetchAllErrorLogs: (options: {limit: number, skip: number}) => Promise<any>,
}


interface ErrorLogState {
  errorsLog: Array<any>,
  errorsLogCount: number,
  currentPage: number,
  perPage: number,
  timezone: string,
}


export class ErrorLog extends React.Component<ErrorLogProps, ErrorLogState> {

  state = {
    errorsLog: [],
    errorsLogCount: 0,
    currentPage: 0,
    perPage: 10,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/errors?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/errors?page=${currentPage}&perPage=${perPage}`)
    }

    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  })
    this.props.router.navigate(`/errors?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/errors?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/errors?page=${nextPage}&perPage=${this.state.perPage}`)
  }


  reloadPage = (options: { perPage: number, currentPage: number }) => {
    return this.props.fetchAllErrorLogs({ limit: options.perPage, skip: options.perPage * options.currentPage })
    .then(() => {
      if(this.props.error.status === fetchStates.success) {
        const { errorsLog, errorsLogCount } = this.props.error
        this.setState({ errorsLog, errorsLogCount })
      }
    });
  }

  render() {

    const { status } = this.props.error;
    const { currentPage, perPage, timezone, errorsLog, errorsLogCount } = this.state;

    return (
      <TemplatePage>

        { status === 'fetching' ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`Error Logs`} 
          button={false} 
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
              <TableHeader 
                columns={['ID', t(2508), t(2509), t(2510), t(2511)]} 
              />
              <tbody className="table__body table__body--striped">
                { errorsLog && errorsLog.map((item: {log_id: string, ts: string, server: string, func: string, error: string}) => {
                  return (
                    <tr key={item.log_id}>
                      <td>
                        {item.log_id}
                      </td>
                      <td>
                        {item.server}
                      </td>
                      <td>
                        {item.func}
                      </td>
                      <td>
                        {item.error}
                      </td>
                      <td>
                        {moment(item.ts).tz(timezone).format('DD/MM/YYYY hh:mm:ss')}
                      </td>
                    </tr>
                    )
                  })
                }
              </tbody>
              <TableFooter 
                colSpan={8}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ errorsLogCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
                button={false}
              />
            </table>
          </div>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, error }: RootState) => ({ auth, error }),
  { fetchAllErrorLogs }
)(ErrorLog)))
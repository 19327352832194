function gasLevelSensorsNames(gas_sensor_type: number) {

  switch(gas_sensor_type) {
    case 0: 
      return 'WPGH / WPL Uniwersalny (dla GF rosnący)'
    case 1:
      return 'WPG_50k uniwersalny'
    case 2:
      return 'WPG_90R uniwersalny'
    case 3:
      return 'WPG_CNG_AEB806 malejący'
    case 4:
      return 'WPG_CNG_TAA_MSG352370 malejący'
    case 5:
      return 'WPG_CNG_55PP rosnący'
    case 6:
      return 'WPG_CNG_AXIS malejący'
    case 7:
      return 'WPG_CNG_201C_AC5V malejący'
    case 8:
      return 'WPG_CNG_201C_AC12V malejący'
    case 9:
      return 'WPG_CNG_ESIGAS rosnący'
    case 10:
      return 'WPG_ROCHESTER malejący'
    case 11:
      return 'WPG_2 uniwersalny'
    case 12:
      return 'WPG_4 uniwersalny'
    case 13:
      return 'WPG_50k (rosnący) Rosnący'
    case 14:
      return 'WPG_50k (malejący) Malejący'
    case 15:
      return 'WPG_90R (rosnący) Rosnący'
    case 16:
      return 'WPG_90R (malejący) Malejący'
    case 17:
      return 'WPG_20k (malejący) Malejący'
    case 18:
      return 'WPG_CNG_DGI12V Rosnący'
    case 19:
      return 'WPG_CNG_T800 Malejący'
    case 20:
      return 'WPGH / WPL (malejący) Malejący'
    case 21:
      return 'WPG_CNG_DGI5V Malejący'
    case 22:
      return 'WPG_CNG_KNC5V Rosnący'
    case 23:
      return 'WPG_ROCHESTER_240R Rosnący'
    case 24:
      return 'WPG_CNG_EASY_GAS12V Rosnący'
    case 25:
      return 'WPG_90R_ATIKER Rosnący'
    case 26:
      return 'WPG_100R_ATIKER Malejący'
    case 27:
      return 'WPG_110R_ATIKER Malejący'
    case 28:
      return 'WPG_LS050_ATIKER Malejący'
    case 255:
      return 'sensor unknown (gas controller is does not provide the information)'
  }

}

export default gasLevelSensorsNames
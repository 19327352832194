import { ACCOUNT } from '../types'
import fetchStates from '../types/fetchStates'


interface AccountState {
  account: {},
  accounts: [],
  distributor_accounts: [],
  accountsCount: number,
  distributor_account_groups: [],
  distributorAccountGroupsCount: number,
  distributor_account_group: {},
  accountVehicleUsage: [],
  accountVehicleUsageCount: number,
  accountVehicleUsageReport: []
}


const DEFAULT_ACCOUNT: AccountState = {
  account: {},
  accounts: [],
  distributor_accounts: [],
  accountsCount: 0,
  distributor_account_groups: [],
  distributorAccountGroupsCount: 0,
  distributor_account_group: {},
  accountVehicleUsage: [],
  accountVehicleUsageCount: 0,
  accountVehicleUsageReport: []
}


const account = (state = DEFAULT_ACCOUNT, action: any) => {
  switch(action.type) {
    case ACCOUNT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ACCOUNT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case ACCOUNT.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        accounts: action.accounts,
        accountsCount: parseInt(action.accountsCount, 10),
      };
    case ACCOUNT.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        account: action.account,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case ACCOUNT.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case ACCOUNT.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case ACCOUNT.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ACCOUNT.FETCH_ALL_DISTRIBUTOR_GROUPS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        distributor_account_groups: action.distributor_account_groups,
        distributorAccountGroupsCount: parseInt(action.distributorAccountGroupsCount, 10),
      };
    case ACCOUNT.FETCH_ADD_DISTRIBUTOR_GROUPS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ACCOUNT.FETCH_ID_DISTRIBUTOR_GROUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        distributor_account_group: action.distributor_account_group,
      };
    case ACCOUNT.FETCH_DELETE_DISTRIBUTOR_GROUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ACCOUNT.FETCH_UPDATE_DISTRIBUTOR_GROUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case ACCOUNT.FETCH_CHANGE_DISTRIBUTOR_ACCOUNT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        distributor_accounts: action.distributor_accounts,
      };
    case ACCOUNT.FETCH_ALL_DISTRIBUTOR_ACCOUNTS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        distributor_accounts: action.distributor_accounts,
      };
    case ACCOUNT.FETCH_ACCOUNT_VEHICLE_USAGE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        accountVehicleUsage: action.accountVehicleUsage,
        accountVehicleUsageCount: parseInt(action.accountVehicleUsageCount, 10),
      }
    case ACCOUNT.FETCH_ACCOUNT_VEHICLE_USAGE_REPORT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        accountVehicleUsageReport: action.accountVehicleUsageReport,
      }
    case ACCOUNT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default account
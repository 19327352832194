import t from '../../components/translation/translate';

const dates = [
  [1667283900000, 400],
  [1667370300000, 300],
  [1667456700000, 200],
  [1667543100000, 800],
  [1667629500000, 273],
  [1667715900000, 255],
  [1667802300000, 577]
];

export const tseries = [
    {
      name: t(2014),
      data: dates
    },
]

export const toptions = {
  chart: {
    height: 450,
    type: 'line' as 'line', 
    fontFamily: 'Rubik-Regular, sans-serif',
    dropShadow: {
      enabled: true,
      color: '#46C8FB',
      top: 8,
      left: 0,
      blur: 8,
      opacity: 0.8
    },
    tooltip: {
      enabled: false
    },
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: false,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: false,
        reset: true,
      },
      export: {
        csv: {
          headerCategory: t(1031),
          headerValue: 'value',
        }
      }
    },
    zoom: {
      enabled: true,
      type: 'x' as 'x',  
      autoScaleYaxis: false,  
      zoomedArea: {
        fill: {
          color: '#90CAF9',
          opacity: 0.4
        },
        stroke: {
          color: '#0D47A1',
          opacity: 0.4,
          width: 1
        }
      }
    },
  },
  colors: ['#2CBFFC', '#545454'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth' as 'smooth'
  },
  title: {
    text: t(2167),
    align: 'left' as 'left',
    style: {
      fontWeight: 400,
      fontSize: '16px',
    }
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#E7FBFF', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    type: 'datetime' as 'datetime',
    labels: {
      show: true,
      hideOverlappingLabels: true,
      style: {
        colors: [],
        fontSize: '12px',
        fontWeight: 400,
      }
    },
    tooltip: {
      enabled: true,
    },
  },
  yaxis: {
    min: 0,
    max: 1023,
  },
  markers: {
    size: 1
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
    position: 'top' as 'top',
    horizontalAlign: 'right' as 'right',
    floating: true,
    offsetY: 350,
    offsetX: -5
  }
}

export const rseries = [
  {
    name: t(2014),
    data: dates
  },
]

export const roptions = {
chart: {
  height: 450,
  type: 'line' as 'line', 
  fontFamily: 'Rubik-Regular, sans-serif',
  dropShadow: {
    enabled: true,
    color: '#46C8FB',
    top: 8,
    left: 0,
    blur: 8,
    opacity: 0.8
  },
  tooltip: {
    enabled: false
  },
  toolbar: {
    show: true,
    tools: {
      download: true,
      selection: false,
      zoom: true,
      zoomin: true,
      zoomout: true,
      pan: false,
      reset: true,
    },
    export: {
      csv: {
        headerCategory: '@uantity of used gas since last refuel',
        headerValue: 'value',
      }
    }
  },
  zoom: {
    enabled: true,
    type: 'x' as 'x',  
    autoScaleYaxis: false,  
    zoomedArea: {
      fill: {
        color: '#90CAF9',
        opacity: 0.4
      },
      stroke: {
        color: '#0D47A1',
        opacity: 0.4,
        width: 1
      }
    }
}
},
colors: ['#2CBFFC', '#545454'],
dataLabels: {
  enabled: false,
},
stroke: {
  curve: 'smooth' as 'smooth'
},
title: {
  text: 'Quantity of used gas since last refuel',
  align: 'left' as 'left',
  style: {
    fontWeight: 400,
    fontSize: '16px',
  }
},
grid: {
  borderColor: '#e7e7e7',
  row: {
    colors: ['#E7FBFF', 'transparent'], // takes an array which will be repeated on columns
    opacity: 0.5
  },
},
xaxis: {
  type: 'datetime' as 'datetime',
  labels: {
    show: true,
    hideOverlappingLabels: true,
    style: {
      colors: [],
      fontSize: '12px',
      fontWeight: 400,
    }
  },
  tooltip: {
    enabled: true,
  },
},
yaxis: {
  min: 0,
  max: 4000000
},
markers: {
  size: 1
},
tooltip: {
  enabled: false,
},
legend: {
  show: false,
  position: 'top' as 'top',
  horizontalAlign: 'right' as 'right',
  floating: true,
  offsetY: 350,
  offsetX: -5
}
}
import React from 'react'
import { RootState } from '../../reducers'
import { fetchVehicleById, fetchRefuelCountVehicleById, fetchVehicleReportByMonth, fetchVehicleOverviewById, fetchFuelPrices, updateFuelPrices } from '../../actions/vehicle';
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'
import moment from 'moment-timezone'
import 'moment-duration-format'
import { motion } from 'framer-motion'
import { motion_container, motion_item } from '../../helpers/motion'
import DatePicker from 'react-datepicker'
import { IconNoData, StatsGasPercentage, StatsCo2Savings, StatsDistance, StatsTime, StatsData, StatsSavings, StatsCost, StatsLpgConsumption,  NoIglWarning, IconCalendarInput, IconHelp } from '../partials/Icons'
import objectIsEmpty from '../../helpers/objectIsEmpty'
import calculateObjectVehicleSavingsNew from '../../functions/calculateObjectVehicleSavingsNew'
import classnames from 'classnames'
import EstimationNote from '../partials/EstimationNote'
import { Tooltip } from 'react-tooltip'
import formatBytes from '../../helpers/formatBytes'
import { Link } from 'react-router-dom'
import NoIgl from '../partials/NoIgl'


interface VehiclesStatisticsProps {
  router: { params: { id: string }, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  fetchVehicleById: (id: number) => Promise<any>,
  fetchVehicleOverviewById: (id: number) => Promise<any>,
  fetchFuelPrices: (options: { month: string, group_id: string }) => Promise<any>,
  fetchVehicleReportByMonth: (options: { id: number, month: string }) => Promise<any>,
}


interface VehiclesStatisticsState {
  vehicle: { custom_id: string, module_imei: number, group_id: string, vehicle_id: number },
  dataLoaded: boolean,
  temp_cost_petrol: number,
  temp_cost_diesel: number,
  temp_cost_lpg: number,
  temp_cost_cng: number,
  petrol_input: boolean,
  diesel_input: boolean,
  lpg_input: boolean,
  cng_input: boolean,
  filterDate: any,
  todayDate: any,
  igl: number,
  cost_lpg: number,
  cost_cng: number,
  cost_petrol: number,
  cost_diesel: number,

  TOTAL_DATA_KB: number,
  TOTAL_ALTERNATIVE_PERCENT: number, 
  TOTAL_DISTANCE_KM: number, 
  TOTAL_TIME_S: number, 
  TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: number,
  TOTAL_ESTIMATED_SAVINGS: number, 
  TOTAL_ESTIMATED_COST: number, 
  TOTAL_SAVINGS_CO2_KG: number,

  sum_dg: number,
  sum_dp: number,
  sum_to: number,
  sum_kb: number,
  sum_tg: number,
  sum_tp: number,
  gmi_ti: number,
  tank_size: number,
  average_consumption: number,
  consumption_difference: number,
  engine_type: string, 
  alternative_fuel_type: string, 
}


export class VehiclesStatistics extends React.Component<VehiclesStatisticsProps, VehiclesStatisticsState> {

  state = {
    vehicle: { custom_id: '', module_imei: 0, group_id: '', vehicle_id: 0 },
    dataLoaded: false,
    temp_cost_petrol: 0,
    temp_cost_diesel: 0,
    temp_cost_lpg: 0,
    temp_cost_cng: 0,
    petrol_input: false,
    diesel_input: false,
    lpg_input: false,
    cng_input: false,
    filterDate: new Date(),
    todayDate: new Date(),
    igl: 0,

    cost_lpg: 0, 
    cost_cng: 0, 
    cost_petrol: 0, 
    cost_diesel: 0,

    TOTAL_DATA_KB: 0,
    TOTAL_ALTERNATIVE_PERCENT: 0, 
    TOTAL_DISTANCE_KM: 0, 
    TOTAL_TIME_S: 0, 
    TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L: 0,
    TOTAL_ESTIMATED_SAVINGS: 0, 
    TOTAL_ESTIMATED_COST: 0, 
    TOTAL_SAVINGS_CO2_KG: 0,

    sum_dg: 0, 
    sum_dp: 0, 
    sum_to: 0, 
    sum_kb: 0, 
    sum_tg: 0, 
    sum_tp: 0, 
    gmi_ti: 0, 
    tank_size: 0, 
    average_consumption: 0, 
    consumption_difference: 0, 
    engine_type: '', 
    alternative_fuel_type: '', 

  }


  async componentDidMount(): Promise<void> {
    await this.fetchVehicleById(parseInt(this.props.router.params.id))
  }


  fetchVehicleById = async (vehicle_id: number) => {
    await this.props.fetchVehicleById(vehicle_id)
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicle } = this.props.vehicle
        this.setState({ vehicle, igl: vehicle.igl }, () => this.reloadPage(this.state.filterDate))
      }
    })
  }


  fetchFuelPrices = async (options: { month: string, group_id: string }) => {
    const { month, group_id } = options
    await this.props.fetchFuelPrices({ month, group_id })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { cost_diesel, cost_petrol, cost_lpg, cost_cng } = this.props.vehicle.fuelPrices
        this.setState({
          cost_diesel,
          cost_petrol,
          cost_lpg,
          cost_cng,
        })
      }
    });
  }


  fetchVehicleReportByMonth = async (options: { module_imei: number, month: string }) => {
    const { module_imei, month } = options
    this.props.fetchVehicleReportByMonth({ id: module_imei , month })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicleReportByMonth } = this.props.vehicle;
        if(vehicleReportByMonth === undefined || (vehicleReportByMonth && objectIsEmpty(vehicleReportByMonth))) {
          this.setState({ dataLoaded: false })
        } else {
          const { cost_petrol, cost_diesel, cost_lpg, cost_cng } = this.state
          const fuel_prices = { cost_petrol, cost_diesel, cost_lpg, cost_cng }
          let objectVehicleSavings: any = calculateObjectVehicleSavingsNew({ vehicle_report: vehicleReportByMonth, fuel_prices })
          this.setState({ ...objectVehicleSavings, dataLoaded: true })
        }
      }
    })
  }


  updateFilterDate = (date: any) => {
    this.setState({ filterDate: date });
    this.reloadPage(date);
  }

  
  reloadPage = async (date: any) => {
    const { module_imei, group_id } = this.state.vehicle;
    await this.fetchFuelPrices({ month: moment(date).format('YYYY-MM'), group_id });
    await this.fetchVehicleReportByMonth({ module_imei, month: moment(date).format('YYYY-MM') })
  }


  render() {

    const { currency } = this.props.auth;
    const { status } = this.props.vehicle;
    const { vehicle, TOTAL_DATA_KB, TOTAL_ALTERNATIVE_PERCENT, TOTAL_DISTANCE_KM, TOTAL_TIME_S, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, TOTAL_ESTIMATED_SAVINGS, TOTAL_ESTIMATED_COST, TOTAL_SAVINGS_CO2_KG, igl, dataLoaded, filterDate, todayDate, cost_diesel, cost_petrol, cost_lpg, cost_cng } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']} 
          time={false}
          button={false}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id, 10)}
          animate
        >
          <div className="vehicle-savings-filter">
            <span className="filter_filter-icon">
              <IconCalendarInput />
            </span>
            <span className="filter_filter-select">
              <DatePicker
                selected={filterDate}
                selectsStart
                onChange={this.updateFilterDate}
                dateFormat='yyyy-MM'
                maxDate={todayDate}
                required={true}
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </span>
          </div>

          {
            status === 'fetching' ? (<Preloader type="fullsceren" />) : (
              <>
                { dataLoaded === false ? (
                  <CtaBox padding={false} type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                    <>{t(1137)} <em>{moment(filterDate).format('YYYY/MM')}</em>. {t(2182)}</>
                  </CtaBox>
                ) : (
                  <>
                  <motion.div 
                    className="statistics"
                    variants={motion_container}
                    initial="hidden"
                    animate="visible"
                  >


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsGasPercentage />
                      </div>
                      <Tooltip anchorId="stats-gas-percentage" />
                      <span className="statistics-card__help" id="stats-gas-percentage" data-tooltip-content={t(2480)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', { 'statistics-card__value--good': TOTAL_ALTERNATIVE_PERCENT > 89,
                      'statistics-card__value--neutral': TOTAL_ALTERNATIVE_PERCENT > 50 && TOTAL_ALTERNATIVE_PERCENT < 90,
                      'statistics-card__value--bad': TOTAL_ALTERNATIVE_PERCENT < 51,
                      })}>
                        { Math.round(TOTAL_ALTERNATIVE_PERCENT * 10) / 10 }
                        <span className="statistics-card__value-label">%</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2477)}
                      </div>
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsSavings />
                      </div>
                      <Tooltip anchorId="stats-estimated-savings" />
                      <span className="statistics-card__help" id="stats-estimated-savings" data-tooltip-content={t(2481)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {'statistics-card__value--good': TOTAL_ESTIMATED_SAVINGS > 0,
                      'statistics-card__value--bad':TOTAL_ESTIMATED_SAVINGS === 0,
                      })}>
                        {Math.round(TOTAL_ESTIMATED_SAVINGS)}
                        <span className="statistics-card__value-label">{currency}</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2191)}<sup>*</sup>
                      </div>
                      {
                        igl !== 1 && (
                          <div className="statiscics-card__noigl">
                            <span data-tooltip-content={t(2478)}>
                              <NoIglWarning size={50} />
                            </span>
                          </div>
                        )
                      }
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                          <StatsCost />
                      </div>
                      <Tooltip anchorId="stats-estimated-cost" />
                      <span className="statistics-card__help" id="stats-estimated-cost" data-tooltip-content={t(2482)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        {Math.round(TOTAL_ESTIMATED_COST)}
                        <span className="statistics-card__value-label">{currency}</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2474)}<sup>*</sup>
                      </div>
                      {
                        igl !== 1 && (
                          <div className="statiscics-card__noigl">
                            <span data-tooltip-content={t(2478)}>
                              <NoIglWarning size={50} />
                            </span>
                          </div>
                        )
                      }
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsLpgConsumption />
                      </div>
                      <Tooltip anchorId="stats-alternative-fuel" />
                      <span className="statistics-card__help" id="stats-alternative-fuel" data-tooltip-content={t(2483)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        {Math.round(TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L)}
                        <span className="statistics-card__value-label">l</span>
                      </div>
                      <div className="statistics-card__title">
                        {t(2475)}
                      </div>
                      {
                        igl !== 1 && (
                          <div className="statiscics-card__noigl">
                            <span data-tooltip-content={t(2478)}>
                              <NoIglWarning size={50} />
                            </span>
                          </div>
                        )
                      }
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsDistance />
                      </div>
                      <Tooltip anchorId="stats-total-distance" />
                      <span className="statistics-card__help"  id="stats-total-distance" data-tooltip-content={t(2484)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        { Math.round(TOTAL_DISTANCE_KM) }
                        <span className="statistics-card__value-label">km</span>
                      </div>

                      <div className="statistics-card__title">
                        {t(2190)}
                      </div>
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsTime />
                      </div>
                      <Tooltip anchorId="stats-total-time" />
                      <span className="statistics-card__help" id="stats-total-time" data-tooltip-content={t(2485)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        { moment.duration(TOTAL_TIME_S, 'seconds').format("hh:mm:ss") }
                      </div>

                      <div className="statistics-card__title">
                        {t(1121)}
                      </div>
                    </motion.div>


                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >               
                      <div className="statistics-card__icon">
                        <StatsData />
                      </div>
                      <Tooltip anchorId="stats-total-data" />
                      <span className="statistics-card__help" id="stats-total-data" data-tooltip-content={t(2486)}>
                        <IconHelp />
                      </span>
                      <div className="statistics-card__value">
                        {formatBytes(TOTAL_DATA_KB * 1024)}
                      </div>
                      <div className="statistics-card__title">
                        {t(2476)}
                      </div>
                    </motion.div>



                    <motion.div 
                      variants={motion_item}
                      className="statistics-card"
                    >
                      <div className="statistics-card__icon">
                        <StatsCo2Savings />
                      </div>
                      <Tooltip anchorId="stats-total-co2savings" />
                      <span className="statistics-card__help" id="stats-total-co2savings" data-tooltip-content={t(2487)}>
                        <IconHelp />
                      </span>
                      <div className={classnames('statistics-card__value', {'statistics-card__value--good': TOTAL_SAVINGS_CO2_KG > 0,
                      'statistics-card__value--bad': TOTAL_SAVINGS_CO2_KG === 0,
                      })}>
                        { Math.round(TOTAL_SAVINGS_CO2_KG) }
                        <span className="statistics-card__value-label">kg</span>
                      </div>

                      <div className="statistics-card__title">
                        {t(2231)}
                      </div>
                    </motion.div>


                  </motion.div>
                  <EstimationNote />
                    <div className="fuel-prices-info">
                      <div className="fuel-prices-info__title">
                        {t(4279)}:
                      </div>

                      <div className="fuel-prices-info__items">
                        <div className="fuel-prices-info__item fuel-prices-info__item--petrol">
                          <div className="fuel-prices-info__item-title">
                            {t(1182)}
                          </div>
                          <div className="fuel-prices-info__item-value">
                            {cost_petrol} <span>{currency}</span>
                          </div>
                        </div>
                        <div className="fuel-prices-info__item fuel-prices-info__item--diesel">
                          <div className="fuel-prices-info__item-title">
                            {t(1183)}
                          </div>
                          <div className="fuel-prices-info__item-value">
                            {cost_diesel} <span>{currency}</span>
                          </div>
                        </div>
                        <div className="fuel-prices-info__item fuel-prices-info__item--lpg">
                          <div className="fuel-prices-info__item-title">
                            {t(1184)}
                          </div>
                          <div className="fuel-prices-info__item-value">
                            {cost_lpg} <span>{currency}</span>
                          </div>
                        </div>
                        <div className="fuel-prices-info__item fuel-prices-info__item--cng">
                          <div className="fuel-prices-info__item-title">
                            {t(1185)}
                          </div>
                          <div className="fuel-prices-info__item-value">
                            {cost_cng} <span>{currency}</span>
                          </div>
                        </div>
                      </div>
                      <span className="small">{t(4280)} <Link to={`/`}>{t(4281)}</Link></span>


                    </div>


                    {igl !== 1 && (
                      <NoIgl padding={false} igl={igl} />
                    )}
                  </>
                )}

              </>
            )
          }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle }: RootState) => ({ auth, vehicle }),
  { fetchVehicleById, fetchRefuelCountVehicleById, fetchVehicleReportByMonth, fetchVehicleOverviewById, fetchFuelPrices, updateFuelPrices }
)(VehiclesStatistics)))
import { REFUEL } from '../types'
import fetchStates from '../types/fetchStates'


interface RefuelState {
  refuels : Array<any>,
  refuel: string,
  vehiclesRefuelCount: number,
  vehiclesRefuel:Array<any>,
  refuelPins: Array<any>,
}


const DEFAULT_REFUEL: RefuelState = {
  refuels : [],
  refuel: '',
  vehiclesRefuelCount: 0,
  vehiclesRefuel: [],
  refuelPins: []
}


const refuel = (state = DEFAULT_REFUEL, action:any) => {
  switch(action.type) {
    case REFUEL.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
      };
    case REFUEL.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
      };
    case REFUEL.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        refuels: action.refuels,
      };
    case REFUEL.FETCH_VEHICLE_REFUELS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        vehiclesRefuel: action.vehiclesRefuel,
        vehiclesRefuelCount: action.vehiclesRefuelCount
      };
    case REFUEL.FETCH_VEHICLE_REFUELS_DATE_AND_GROUP_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        refuelPins: action.refuelPins
      }
    case REFUEL.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        refuel: action.refuel,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case REFUEL.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default refuel
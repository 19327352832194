/*
#define _model_0_string 			"DEVTOOL"
#define _model_10_string 			"STAG 400.4 DPI"
#define _model_10_string_digitronic	"DI 4"
#define _model_10_string_ags		"AGS Eco-Pro DI 4"
#define _model_10_string_china_box	"STAG 400.4 DPI made for BOX GAS"
#define _model_11_string 			"STAG 400.6 DPI"
#define _model_11_string_digitronic	"DI 6"
#define _model_11_string_ags		"AGS Eco-Pro DI 6"
#define _model_12_string 			"STAG 400.8 DPI"
#define _model_12_string_digitronic	"DI 8"
#define _model_12_string_ags 		"AGS Eco-Pro DI 8"
#define _model_14_string			"STAG 400.4 ORTHRUS"
#define _model_20_string 			"STAG Diesel-4"
#define _model_20_string_digitronic	"DIESEL 4"
#define _model_21_string 			"STAG Diesel-6"
#define _model_21_string_digitronic	"DIESEL 6"
#define _model_22_string 			"STAG Diesel-8"
#define _model_22_string_digitronic	"DIESEL 8"
#define _model_24_string 			"STAG Diesel Plus 6"
#define _model_25_string 			"STAG CRD PERFORMANCE"
#define _model_30_string 			"STAG-4 QBOX BASIC"
#define _model_30_string_atiker		"ATIKER-4"
#define _model_30_string_mexico		"STAG-4 QBOX BASIC MEXICO"
#define _model_31_string 			"STAG-300-6 QMAX PLUS"
#define _model_32_string 			"STAG-300-8 QMAX PLUS"
#define _model_33_string			"STAG-4 QBOX PLUS"
#define _model_33_string_atiker		"ATIKER-4"
#define _model_34_string			"GoFast"
#define _model_35_string			"Titan"
#define _model_36_string			"STAG-4 QNEXT PLUS"
#define _model_36_string_atiker		"ATIKER-4"
#define _model_36_string_digitronic	"iQ-4"
#define _model_36_string_mexico		"STAG-4 QNEXT PLUS MEXICO"
#define _model_37_string			"STAG-4 QNEXT BASIC"
#define _model_37_string_atiker		"ATIKER-4"
#define _model_37_string_digitronic	"iQ BASIC"
#define _model_38_string 			"STAG-300-6 QMAX BASIC"
#define _model_38_string_digitronic	"iQ-6"
#define _model_39_string 			"STAG-300-8 QMAX BASIC"
#define _model_39_string_digitronic	"iQ-8"
#define _model_40_string 			"Stag500 Go"
#define _model_50_string 			"STAG TAP - 03"
#define _model_51_string 			"STAG TAP - 03/1"
#define _model_52_string 			"STAG TAP - 03/2"

#define _model_53_string 			"STAG-TAP MARINE 01"
#define _model_54_string 			"STAG-TAP MARINE 02"
#define _model_61_string 			"HPPDriver"
#define _model_126_string			"Fast System OBD"
#define _model_127_string			"Fast System"
#define _model_129_string			"GoFast OBD" //indie
#define _model_130_string			"GoFast" //latin america
#define _model_251_string			"QNEXT PLUS BAPE CNG"
#define _model_252_string			"QNEXT PLUS AH2 CNG"
#define _model_253_string			"QNEXT PLUS BA 5DR CNG"
#define _model_254_string			"QNEXT PLUS BA 4DR CNG"
*/


function gasControlerModel(num: string) {
  if(num) {
    const converted = num.split('.')[0]
    switch(converted) {
      case '0': 
      return 'DEVTOOL'
      case '10': 
        return 'STAG 400.4 DPI'
      case '11': 
        return 'STAG 400.6 DPI'
      case '12': 
        return 'STAG 400.8 DPI'
      case '14': 
        return 'STAG 400.4 ORTHRUS'
      case '20': 
        return 'STAG Diesel-4'
      case '21': 
        return 'STAG Diesel-6'
      case '22': 
        return 'STAG Diesel-8'
      case '24': 
        return 'STAG Diesel Plus 6'
      case '25': 
        return 'STAG CRD PERFORMANCE'
      case '30': 
        return 'STAG-4 QBOX BASIC'
      case '31':
        return 'STAG-300-6 QMAX PLUS'
      case '32':
        return 'STAG-300-8 QMAX PLUS'
      case '33':
        return 'STAG-4 QBOX PLUS'
      case '34':
        return 'STAG GoFast'
      case '35':
        return 'STAG Titan'
      case '36':
        return 'STAG-4 QNEXT PLUS'
      case '37':
        return 'STAG-4 QNEXT BASIC'
      case '38':
        return 'STAG-300-6 QMAX BASIC'
      case '39':
        return 'STAG-300-8 QMAX BASIC'
      case '40':
        return 'Stag500 Go'
      case '50':
        return 'STAG TAP03'
      case '51':
        return 'STAG TAP03/1'
      case '52':
        return 'STAG TAP03/2'
      case '53':
        return 'STAG-TAP MARINE 01'
      case '54':
        return 'STAG-TAP MARINE 02'
      case '61':
        return 'HPPDriver'
      case '126':
        return 'Fast System OBD'
      case '127':
        return 'Fast System'
      case '129':
        return 'GO FAST OBD'
      case '130':
        return 'GO FAST'
      case '251':
        return 'QNEXT PLUS BAPE CNG'
      case '252':
        return 'QNEXT PLUS AH2 CNG'
      case '253':
        return 'QNEXT PLUS BA 5DR CNG'
      case '254':
        return 'QNEXT PLUS BA 4DR CNG'
      // added manually - request by Tomasz C.
      case '400':
        return 'STAG 400'        
      case '500':
        return 'STAG 500'
      case '700':
        return 'STAG 700'
      default:
        return 'n/a'
    }
  }
  return null
} 


export default gasControlerModel
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../reducers'
import { fetchAllStations } from '../../actions/station'
import { checkAuth } from '../../actions/auth'
import Preloader from './Preloader'
import { Link } from 'react-router-dom'
import Card from './Card'
import { GoogleMap, Marker } from '@react-google-maps/api'
import markerStation from'../../img/marker-station.png'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import t from '../translation/translate'
import fetchStates from '../../types/fetchStates'


interface CardStationsProps {
  auth: any, 
  station: any, 
  checkAuth: () => Promise<any>,
  fetchAllStations: (options: {limit: number, skip: number}) => Promise<any>,
}


interface CardStationsState {
  lat: number,
  lng: number,
  zoom: number,
  role: number,
  stationsCount: number,
}


export class CardStations extends React.Component<CardStationsProps, CardStationsState> {


  state = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
    zoom: DEFAULT_ZOOM,
    role: 6,
    stationsCount: 0
  }

  componentDidMount() {

    this.props.checkAuth()
    .then(() => {
      const { lat, lng, role, status } = this.props.auth;
      if(status === fetchStates.success) {
        this.setState({
            lat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
            lng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
            role
        });
      }
    });

    this.props.fetchAllStations({ limit: 100, skip: 0 })
    .then(() => {
      const { stationsCount, status } = this.props.station;
      if(status === fetchStates.success) {
        this.setState({ stationsCount })
      }
    });
  }

  render() {

    const { stationsCount, stations } = this.props.station;
    const { lat, lng, role } = this.state;

    if(this.props.station.status === 'fetching') {
      return <Preloader type="fullscreen" />
    } 

    if(stationsCount === 0 || role < 4) {
      return null
    }


    return (
      <>
          <Card 
            button={false}
            title={`${t(248)} (${stationsCount})`}
            time={false}
            animate
          >

            <GoogleMap
              center={{ lat, lng }}
              zoom={ 7 }
            >
              { 
                stations !== undefined && stations.map((station: { lat: number, lng: number, station_id: number, custom_id: string, refueled: number}) => {
                  const lat = station.lat;
                  const lng = station.lng;
                  return (
                    <span key={station.station_id}>
                      <Marker
                        position={{
                          lat,
                          lng
                        }}
                        icon={
                          // @ts-expect-error
                          new window.google.maps.MarkerImage(
                            markerStation,
                            null, /* size is determined at runtime */
                            null, /* origin is 0,0 */
                            null, /* anchor is bottom center of the scaled image */
                            new window.google.maps.Size(48, 48)
                          )
                        }
                      >
                      </Marker>
                    </span> 
                  )
                })
              }

              <div className="map-constraints-container" />
            </GoogleMap>

            <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>
          </Card>
        </>
      );
    };
};


export default connect(
  ({ auth, station }: RootState) => ({ auth, station }),
  { fetchAllStations, checkAuth }
)(CardStations);
import React from 'react'
import AuthInfoPanel from '../partials/AuthInfoPanel'
import Footer from '../partials/Footer'
import CookieConsent from '../partials/CookieConsent'


interface TemplateAuthProps {
  children: JSX.Element
}


const TemplateAuth: React.FC<TemplateAuthProps> = ({ children }) => {
  return (
    <>
      <CookieConsent />
      <div className="template template__auth">
        <div className="main-content">
          <div className="main-content__wrapper">
            <div className="auth-panel">
              <AuthInfoPanel />
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}


export default TemplateAuth
import React from 'react'
import { RootState } from '../../reducers'
import { fetchAllRawCommunication, fetchRawImeis } from '../../actions/communication';
import fetchStates from '../../types/fetchStates'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import TableHeader from '../partials/TableHeader'
import DatePicker from 'react-datepicker'
import { IconArrowRight, IconArrowLeft, IconNoData } from '../partials/Icons'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { TIMEZONE } from '../../config'
import moment from 'moment-timezone'
import Preloader from '../partials/Preloader'


interface CommunicationProps {
  auth: any,
  communication: any,
  fetchRawImeis: (date: string) => Promise<any>,
  fetchAllRawCommunication: (options: { limit: number, skip: number, reason: string, date: string, imei: string }) =>  Promise<any>,
}


interface CommunicationState {
  currentPage: number,
  filterPerPage: number,
  filterReason: string,
  filterActiveImei: string,
  filterDate: any,
  todayDate: any,
  imeiList: Array<any>,
  timezone: string,
  noImeis: boolean,
  noData: boolean,
  communication: Array<any>, 
  communicationCount: number,
}


export class Communication extends React.Component<CommunicationProps, CommunicationState> {

  state = {
    currentPage: 0,
    filterPerPage: 10,
    filterReason: 'all',
    filterActiveImei: '',
    filterDate: new Date(),
    todayDate: new Date(),
    imeiList: [],
    timezone: TIMEZONE,
    noImeis: true,
    noData: true,
    communication: [], 
    communicationCount: 0,
  }


  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    this.reloadPage({ currentPage: this.state.currentPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }
  getTimezoneOffset(time: any) {
    const UTCServerTime = moment(time).format('YYYY-MM-DD 00:00:00')
    return moment(UTCServerTime).format('X')
  }


  reloadPage = (options: { currentPage: number, filterPerPage: number, filterReason: string, filterDate: any, filterActiveImei: string }) => {
    const { currentPage, filterPerPage, filterReason, filterActiveImei, filterDate } = options
    this.props.fetchRawImeis(filterDate)
    .then(() => {      
      if(this.props.communication.status === fetchStates.success) {
        if(this.props.communication.activeImeis.length > 0) {

          this.setState({ imeiList: this.props.communication.activeImeis, noImeis: false, filterActiveImei })

          this.props.fetchAllRawCommunication({ limit: filterPerPage, skip: filterPerPage * currentPage, reason: filterReason, date: filterDate, imei: filterActiveImei })
          .then(() => {
            if(this.props.communication.status === fetchStates.success) {
              if(this.props.communication.communication.length > 0) {
                const { communication, communicationCount } = this.props.communication
                this.setState({  communication, communicationCount, noData: false })
              } else {
                this.setState({ noData: true, communication: [], communicationCount: 0 })
              }
            }
          }); 
        } else {
          this.setState({ imeiList: [], noImeis: true, communication: [], communicationCount: 0 })
        }
      }
    });
  }


  updateFilterPerPage = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ currentPage: 0, filterPerPage: parseInt(e.target.value) });
    this.reloadPage({ currentPage: 0, filterPerPage: parseInt(e.target.value), filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  updatefilterActiveImei = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ currentPage: 0, filterActiveImei: e.target.value });
    this.reloadPage({ currentPage: 0, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: e.target.value });
  }


  updateFilterDate = (date: any) => {
    this.setState({ currentPage: 0, filterDate: date, filterActiveImei: '', filterReason: 'all' });
    this.reloadPage({ currentPage: 0, filterPerPage: this.state.filterPerPage, filterReason: 'all', filterDate: this.getTimezoneOffset(date), filterActiveImei: this.state.filterActiveImei });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ filterPerPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ filterPerPage: parseInt(e.target.value), currentPage: 0, filterReason: this.state.filterReason, filterDate: this.state.filterDate, filterActiveImei: this.state.filterActiveImei })
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  render() {

    const { status } = this.props.communication;
    const { currentPage, filterPerPage, todayDate, filterDate, timezone, noData, noImeis, imeiList, communication, communicationCount } = this.state;

    return (
      <TemplatePage>

        <Card 
          title={t(10)} 
          button={false} 
          search={false} 
          time={false}
          animate
        >
          { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }


              <>
                <form className="form">
                  <div className="filter">

                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(2050)}</span>
                      <span className="filter_filter-select">
                        <DatePicker
                          selected={filterDate}
                          selectsStart
                          onChange={(date) => this.updateFilterDate(date)}
                          dateFormat='dd/MM/yyyy'
                          maxDate={todayDate}
                          required={true}
                        />
                      </span>
                    </span>

                    <span className="filter__filter-group">
                      <span className="filter__filter-text">IMEI</span>
                        <span className="filter_filter-select">
                          <input 
                            type="text" 
                            list="data" 
                            onChange={e => this.updatefilterActiveImei(e)} 
                          />
                          <datalist id="data">
                            {imeiList.map((imei: {id_mod: string}, index: number) => (
                              <option key={index}>{imei.id_mod}</option>
                            ))}
                          </datalist>
                      </span>
                    </span>

                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(2001)}</span>
                      <span className="filter_filter-select">
                        <select className="filter__select" value={filterPerPage} onChange={e => this.updateFilterPerPage(e)}>
                          <option>10</option>
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option>500</option>
                        </select>
                      </span>
                    </span>

                    <span className="filter__filter-group filter-group--last">
                      <span className="filter__item-num">{communicationCount === 0 ? 0 : currentPage * filterPerPage + 1} - {communicationCount < filterPerPage * (currentPage + 1) ? communicationCount : filterPerPage * (currentPage + 1)} of {communicationCount}</span>
                      <span className="filter__nav">
                      {currentPage > 0 ? (
                        <span className="filter__prev filter__prev--active" onClick={() => this.prevPage(currentPage - 1)}>
                          <IconArrowLeft  color="#ffffff"/>
                        </span>
                      ) : (
                        <span className="filter__prev">
                          <IconArrowLeft color="#9697AE" />
                        </span>
                      )} 

                      {communicationCount < filterPerPage * (currentPage + 1) +1 ? (
                        <span className="filter__prev">
                          <IconArrowRight color="#9697AE" />
                        </span>
                      ) : (                              
                        <span className="filter__next filter__next--active" onClick={() => this.nextPage(currentPage + 1)}>
                          <IconArrowRight color="#ffffff" />
                        </span>
                      )} 
                      </span>
                    </span>
                  </div>
                </form>

                { noImeis === true && 
                  (
                    <CtaBox padding type="info" title={t(1202)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(1203)}
                    </CtaBox>
                  ) 
                }


                { noData === true && 
                  (
                    <CtaBox padding type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(2166)}
                    </CtaBox>
                  )
                }


                { noImeis === false && noData === false && (
                    <div className="table-wrapper">
                    <table className="table">
                    <TableHeader 
                      columns={[
                      t(1030), 
                      'FL', 
                      t(1031), 
                      t(2073), 
                      t(2178), 
                      t(2177), 
                      t(4215),
                      t(4216),
                      t(2212),
                      t(4217),
                      t(4218),
                      'HDOP', 
                      t(4219), 
                      t(4220), 
                      t(4221), 
                      t(4222), 
                      t(4223), 
                      t(4224), 
                      t(4225), 
                      'Conv1 V', 
                      'Conv2 V', 
                      t(4223), 
                      t(4224), 
                      'IMSI SIM', 
                      'ICCID SIM', 
                      'BDA BT', 
                      t(4226), 
                      t(4227), 
                      t(4228), 
                      t(4229), 
                      t(4230), 
                      t(4231), 
                      t(4232), 
                      t(4233), 
                      t(4234), 
                      t(4235), 
                      t(4236), 
                      t(4237), 
                      t(4238), 
                      t(4239), 
                      t(4240), 
                      t(4241), 
                      t(4242), 
                      t(4243), 
                      t(4244), 
                      t(4246), 
                      t(4247), 
                      t(4248),  
                      t(4264), 
                      t(4249), 
                      t(4250),
                      t(4251),
                      t(4252),
                      t(4253),
                      t(4254),
                      t(4255),
                      t(4256),
                      t(4257),
                      t(4258),
                      t(4259), 
                      t(4260), 
                      t(4261), 
                      t(4262),
                      t(4269),
                      t(4270),
                      'GAS sensor type',
                      'GAS level sensor reversed',
                      'GAS level thresholds'
                    ]} 
                    />
                      <tbody className="table__body table__body--striped">
                        {communication && communication.map((item: any, index: number) => {
                          return (
                          <tr key={index}>
                            <td>
                              {item.id_mod}
                            </td>
                            <td>
                              {item.fl === null ? 'n/a' : item.fl}
                            </td>
                            <td>
                            {moment(item.ts*1000).tz(timezone).format('YYYY/MM/DD HH:mm:ss')}<br/>
                              {item.ts}
                            </td>
                            <td>
                              {item.loc_re}
                            </td>
                            <td>
                              {item.loc_lt}
                            </td> 
                            <td>
                              {item.loc_ln}
                            </td>
                            <td>
                              {item.loc_al}
                            </td>
                            <td>
                              {item.loc_hd}
                            </td>
                            <td>
                              {item.loc_sp}
                            </td>
                            <td>
                              {item.loc_su}
                            </td>
                            <td>
                              {item.loc_sv}
                            </td>
                            <td>
                              {item.loc_ho}
                            </td>
                            <td>
                              {item.gsm_si}
                            </td>
                            <td>
                              {item.gsm_bt}
                            </td>
                            <td>
                              {item.gsm_er}
                            </td>
                            <td>
                              {item.gsm_bu}
                            </td>
                            <td>
                              {item.gsm_od /* gsm_od (TS in memory) */}
                            </td>
                            <td>
                              {item.gsm_io}
                            </td>
                            <td>
                              {item.gsm_sl}
                            </td>
                            <td>
                              {item.gsm_a1 /* gsm_a1 (Conv1) */}
                            </td>
                            <td>
                              {item.gsm_a2 /* gsm_a2 (Conv2) */}
                            </td>
                            <td>
                              {item.hwi_hv}
                            </td> 
                            <td>
                              {item.hwi_fv}
                            </td> 
                            <td>
                              {item.hwi_im /* hwi_im (IMSI SIM) */}
                            </td> 
                            <td>
                              {item.hwi_ic}
                            </td> 
                            <td>
                              {item.hwi_bd}
                            </td> 
                            <td>
                              {item.hwi_st}
                            </td> 
                            <td>
                              {item.hwi_pm}
                            </td> 
                            <td>
                              {item.hwi_pg}
                            </td> 
                            <td>
                              {item.hwi_ca}
                            </td>
                            <td>
                              {item.gmd_ss}
                            </td> 
                            <td>
                              {item.gmd_sf}
                            </td> 
                            <td>
                              {item.gmd_rp}
                            </td> 
                            <td>
                              {item.gmd_ld}
                            </td> 
                            <td>
                              {item.gmd_mp}
                            </td> 
                            <td>
                              {item.gmd_gp}
                            </td> 
                            <td>
                              {item.gmd_rt}
                            </td> 
                            <td>
                              {item.gmd_gt}
                            </td> 
                            <td>
                              {item.gmd_li}
                            </td> 
                            <td>
                              {item.gmd_la}
                            </td> 
                            <td>
                              {item.gmd_vo}
                            </td> 
                            <td>
                              {item.gmd_ip}
                            </td> 
                            <td>
                              {item.gmd_ig}
                            </td> 
                            <td>
                              {item.gmd_to}
                            </td> 
                            <td>
                              {item.gmd_da}
                            </td> 
                            <td>
                              {item.gmd_rf}
                            </td> 
                            <td>
                              {item.gmd_it}
                            </td> 
                            <td>
                              {item.gmd_is}
                            </td> 
                            <td>
                              {item.gmd_ac}
                            </td> 
                            <td>
                              {item.gmi_mo}
                            </td>
                            <td>
                              {item.gmi_fv}
                            </td>
                            <td>
                              {item.gmi_sy}
                            </td>
                            <td>
                              {item.gmi_cd}
                            </td>
                            <td>
                              {item.gmi_ch}
                            </td>
                            <td>
                              {item.gmi_se}
                            </td>
                            <td>
                              {item.gmi_pa}
                            </td>
                            <td>
                              {item.gmi_ul}
                            </td>
                            <td>
                              {item.gmi_ti}
                            </td>
                            <td>
                              {item.gmi_tl}
                            </td>
                            <td>
                              {item.rep_tp}
                            </td>
                            <td>
                              {item.rep_tg}
                            </td>
                            <td>
                              {item.rep_dp}
                            </td>
                            <td>
                              {item.rep_dg}
                            </td>
                            <td>
                              {item.rep_to}
                            </td>
                            <td>
                              {item.rep_kb}
                            </td>
                            <td>
                              {item.gmi_gs}
                            </td>
                            <td>
                              {item.gmi_gr}
                            </td>
                            <td>
                              {item.gmi_gl}
                            </td>
                          </tr>
                          )
                        })
                      }
                      </tbody>

                    </table>
                  </div>
                )}
              </>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, communication }: RootState) => ({ auth, communication }),
  { fetchAllRawCommunication, fetchRawImeis }
)(Communication)))
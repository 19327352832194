import { ALERT } from '../types'
import fetchStates from '../types/fetchStates'


interface AlertState {
  alerts : [],
  alertsCount: number,
  alertsNewCount: number,
  alertsSettings: [],
  alertsPresets: [],
}


const DEFAULT_ALERT:AlertState = {
  alerts : [],
  alertsCount: 0,
  alertsNewCount: 0,
  alertsSettings: [],
  alertsPresets: []
}


const alert = (state = DEFAULT_ALERT, action: any) => {
  switch(action.type) {
    case ALERT.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case ALERT.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case ALERT.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alerts: action.alerts,
        alertsCount: parseInt(action.alertsCount, 10),
      };
    case ALERT.FETCH_NEW_COUNT_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alertsNewCount: parseInt(action.alertsNewCount, 10),
      };
    case ALERT.FETCH_DIAGNOSTICS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        diagnostics: action.diagnostics
      }
    case ALERT.FETCH_ALERTS_PRESETS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alertsPresets: action.alertsPresets,
      };

    case ALERT.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alert: action.alert
      }
    case ALERT.FETCH_SETTINGS_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        alertsSettings: action.alertsSettings
      }
    case ALERT.FETCH_ID_RED_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SPEED_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_GEO_FENCE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_ENGINE_TEMPERATURE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_RPM_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_GASOLINE_USE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_GAS_MALFUNCTION_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_GAS_MAINTENANCE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_GEO_FENCE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_ENGINE_TEMPERATURE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_RPM_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_GASOLINE_USE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_GAS_MALFUNCTION_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_GAS_MAINTENANCE_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_SWITCH_LOCKED_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_UPDATE_EMAIL_NOTIFICATION_ID:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.FETCH_OVERRIDE_GROUP_SETTINGS:
      return {
        ...state,
        status: fetchStates.success,
      }
    case ALERT.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default alert
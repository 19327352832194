import { COMMUNICATION } from '../types'
import fetchStates from '../types/fetchStates'


interface CommunicationState {
  communication : [],
  communicationCount: number,
  activeImeis: [],
}


const DEFAULT_COMMUNICATION: CommunicationState = {
  communication : [],
  communicationCount: 0,
  activeImeis: []
}


const communication = (state = DEFAULT_COMMUNICATION, action: any) => {
  switch(action.type) {
    case COMMUNICATION.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case COMMUNICATION.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case COMMUNICATION.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        communication: action.communication,
        communicationCount: parseInt(action.communicationCount, 10),
      };
    case COMMUNICATION.FETCH_ACTIVE_IMEIS_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        activeImeis: action.activeImeis,
      };
    case COMMUNICATION.FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        communication: action.communication,
        communicationCount: parseInt(action.communicationCount, 10),
      };
    case COMMUNICATION.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case COMMUNICATION.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default communication
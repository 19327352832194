import React from 'react'
import classnames from 'classnames'


interface CtaBoxProps {
  type: string,
  icon: JSX.Element, 
  title: string, 
  padding: boolean,
  children: JSX.Element|JSX.Element[]|string, 
}


const CtaBox: React.FC<CtaBoxProps> = ({ type, icon, title, children, padding }) => {

  const ctaBox = <div className={classnames('cta-box', {
        'cta-box--info' : type === 'info',
        'cta-box--warning' : type === 'warning',
        'cta-box--success' : type === 'succes',
        'cta-box--danger' : type === 'danger'
      })}
    >
      <div className="cta-box__header">
        <div className="cta-box__icon">
          {icon}
        </div>
      </div>
      <div className="cta-box__content">
        <h3 className="cta-box__content-title">{title}</h3>
        <p className="cta-box__content-text">
          {children}
        </p>
      </div>
    </div>;

  
  return  padding ? <div className="cta-box__padding-wrapper">{ctaBox}</div> : <>{ctaBox}</>
  
}


export default CtaBox
import React from 'react'
import TemplateFullwidth from '../templates/TemplateFullwidth'
import CardInfo from '../partials/CardInfo'
import t from '../translation/translate'


const TermsConditions: React.FC = () => {
  return <TemplateFullwidth>
  <div className="centered-content">
    <CardInfo>
      <>      
        <h1>{t(2370)}</h1>
        <p>To be added ...</p>
      </>
    </CardInfo>
  </div>
</TemplateFullwidth>
}


export default TermsConditions
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import { fetchHistoryVehicleById } from '../../actions/vehicle'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { Link } from 'react-router-dom'
import Preloader from '../partials/Preloader'
import { IconVisibility, IconLockOpen, IconLockClosed, IconNoData } from '../partials/Icons'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { TIMEZONE } from '../../config'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'


interface VehiclesHistoryProps {
  router: { params: {id: string}, location: {search: string}, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  fetchHistoryVehicleById: (options: {id: number, limit: number, skip: number}) => Promise<void>,
}


interface VehiclesHistoryState {
  vehicle: { custom_id: string, module_imei: string, connected: any, },
  vehicleHistory: Array<any>,
  vehicleHistoryCount: number,
  currentPage: number,
  perPage: number,
  module_imei: string,
  noData: boolean,
  timezone: string
}


export class VehiclesHistory extends React.Component<VehiclesHistoryProps, VehiclesHistoryState> {

  state = {
    vehicle: { custom_id: '', module_imei: '', connected: null, },
    vehicleHistory: [],
    vehicleHistoryCount: 0,
    currentPage: 0,
    perPage: 10,
    module_imei: '',
    noData: false,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/vehicles/history/${parseInt(this.props.router.params.id, 10)}?page=${currentPage}&perPage=${perPage}`)
      }
    }


    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.props.router.navigate(`/vehicles/history/${parseInt(this.props.router.params.id, 10)}?page=0&perPage=${e.target.value}`)
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/vehicles/history/${parseInt(this.props.router.params.id, 10)}?page=${prevPage}&perPage=${this.state.perPage}`)
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/vehicles/history/${parseInt(this.props.router.params.id, 10)}?page=${nextPage}&perPage=${this.state.perPage}`)
  }

  
  reloadPage = (options: { perPage: number, currentPage: number }) => {
    const { perPage, currentPage } = options
    this.props.fetchHistoryVehicleById({ id: parseInt(this.props.router.params.id), limit: perPage, skip: perPage * currentPage })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicleHistory, vehicleHistoryCount, vehicle } = this.props.vehicle
        this.setState({ vehicleHistory, vehicleHistoryCount, vehicle, noData: false })
      }
      if(this.props.vehicle.vehicleHistory && this.props.vehicle.vehicleHistory.length === 0) {
        this.setState({ noData: true })
      }
    })
  }


  render() {

    const { status } = this.props.vehicle;
    const { vehicleHistory, vehicleHistoryCount, vehicle, currentPage, perPage, noData, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={`${vehicle.custom_id} - IMEI ${vehicle.module_imei}`} 
          time={false}
          padding={vehicleHistory && vehicleHistory.length > 0 ? [''] : ['medium']}
          button={false}
          buttonBack={true} 
          navigate={this.props.router.navigate}
          tabnav="vehicle-overview"
          tabnavId={parseInt(this.props.router.params.id)}
          animate
        >
          { vehicle.connected !== null ? (
            <>
              { vehicle && noData === true ? 
              (
                <CtaBox padding={false} type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                  {t(2166)}
                </CtaBox>
              ) : (

                <div className="table-wrapper">
                  <table className="table">
                    <TableHeader 
                      columns={['', t(2050), t(2179), t(2146), t(2073), t(2180)]} 
                    />
                      <tbody className="table__body table__body--striped">
                        {vehicleHistory !== undefined && vehicleHistory.length > 0 && 
                        vehicleHistory.map((item: {lock_id: number, refuel_id: number, ts: number, email: string, unlock: boolean, reason_description: string, description: string}) => {
                          return (
                          <tr key={item.lock_id}>
                            <td className="simple">
                              {item.refuel_id !== null && 
                                <Link to={`/vehicles/refuelled/location/${item.refuel_id}`}>
                                  <IconVisibility task button color="#ffffff" />
                                </Link>
                              }
                            </td>
                            <td>
                              {moment(item.ts).tz(timezone).format('DD/MM/YYYY HH:mm:ss')}
                            </td>
                            <td>
                              { item.email !== undefined && item.email !== null ? item.email : 'App' }
                            </td>
                            <td>
                              {item.unlock === true ? (<IconLockOpen table success color="#ffffff" />) : (<IconLockClosed table error color="#ffffff" />)}
                            </td>
                            <td>
                              {item.reason_description}
                            </td>
                            <td>
                              {item.description}
                            </td>

                          </tr> 
                          )
                        })}
                      </tbody>
                    <TableFooter 
                      button={false}
                      colSpan={7}
                      currentPage={ currentPage }
                      perPage={ perPage }
                      itemsCount={ vehicleHistoryCount }
                      prevPage={() => this.prevPage(currentPage - 1)}
                      nextPage={() => this.nextPage(currentPage + 1)}
                      updatePagination={this.updatePagination}
                    />
                  </table>
                </div>
              )}
            </>
          ) : ( <></> )}
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ vehicle, auth }: RootState) => ({ vehicle, auth }),
  { fetchHistoryVehicleById }
)(VehiclesHistory)))
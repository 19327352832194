import objectIsEmpty from '../helpers/objectIsEmpty';
import ReportFunctions from './functionsReportCalculation';


function calculateObjectReportSavingsNew(raw_report_data: any) {


  let TOTAL_DISTANCE_KM = 0;
  let ALTERNATIVE_DISTANCE_KM = 0;
  let DEFAULT_DISTANCE_KM = 0;
  let TOTAL_TIME_S = 0;
  let DEFAULT_TIME_S = 0; 
  let ALTERNATIVE_TIME_S = 0;
  let TOTAL_DATA_KB = 0;
  let TOTAL_SAVINGS_CO2_KG = 0;
  let TOTAL_ALTERNATIVE_PERCENT = 0;
  let TOTAL_FUEL_CONSUMPTION_L = 0;
  let TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = 0;
  let TOTAL_DEFAULT_FUEL_CONSUMPTION_L = 0;
  let IGL_GAS_CONSUMPTION_L = 0;
  let COST_PETROL = 0;
  let COST_DIESEL = 0;
  let COST_LPG = 0;
  let COST_CNG = 0;

  let ESTIMATED_SAVINGS = 0;
  let ESTIMATED_POTENTIAL_SAVINGS = 0;
  let ALTERNATIVE_FUEL_COST = 0;
  let DEFAULT_FUEL_COST = 0;
  let DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE = 0;


  // check we have reports and fuel_prices
  if(raw_report_data && !objectIsEmpty(raw_report_data)) {


    let { rep_kb_diff, rep_dg_diff, rep_dp_diff, rep_tp_diff, rep_tg_diff, gmi_ti, rep_to_diff, tank_size, average_consumption, consumption_difference, engine_type, alternative_fuel_type, igl, cost_petrol, cost_diesel, cost_lpg, cost_cng } = raw_report_data;


    // defaults data
    // default vehicle values
    let TANK_SIZE_L = 0;
    let ENGINE_TYPE = '';
    let ALTERNATIVE_FUEL_TYPE = '';
    let IGL = 0;
    let CONSUMPTION_DIFFERENCE_PERCENTAGE = 0;
    let AVERAGE_CONSUMPTION_L = 0;
    let REP_TP_DIFF = 0;
    let REP_TG_DIFF = 0;
    let REP_DP_DIFF = 0;
    let REP_DG_DIFF = 0;
    let REP_KB_DIFF = 0;
    let TOTAL_GAS_INJECTION_PER_TANK_10MS = 0;
    let TOTAL_INJECTED_GAS_10MS = 0;


    if(tank_size) {
      TANK_SIZE_L = tank_size
    } else {
      //console.error('[ERROR]: tank_size data are missing');
    }

    if(engine_type) {
      ENGINE_TYPE = engine_type
    } else {
      //console.error('[ERROR]: engine_type data are missing');
    }

    if(alternative_fuel_type) {
      ALTERNATIVE_FUEL_TYPE = alternative_fuel_type
    } else {
      //console.error('[ERROR]: alternative_fuel_type data are missing');
    }

    if(consumption_difference) {
      CONSUMPTION_DIFFERENCE_PERCENTAGE = Number(consumption_difference)
    } else {
      //console.error('[ERROR]: consumption_difference data are missing');
    }

    if(average_consumption) {
      AVERAGE_CONSUMPTION_L = Number(average_consumption)
    } else {
      //console.error('[ERROR]: consumption_difference data are missing');
    }

    if(igl) {
      IGL = igl
    } else {
      //console.error('[ERROR]: igl data are missing');
    }

    if(rep_tp_diff) {
      if(Math.sign(rep_tp_diff) === 1) {
        REP_TP_DIFF = Number(rep_tp_diff);
      } else {
        REP_TP_DIFF = 0;
      }
      // time driven on default in s
    } else {
      //console.error('[ERROR]: rep_tp_diff data are missing');
    }

    if(rep_tg_diff) {
      if(Math.sign(rep_tg_diff) === 1) {
        REP_TG_DIFF = Number(rep_tg_diff);
      } else {
        REP_TG_DIFF = 0;
      }
    } else {
      //console.error('[ERROR]: rep_tg_diff data are missing');
    }

    if(rep_dp_diff) {
      if(Math.sign(rep_dp_diff) === 1) {
        REP_DP_DIFF = Number(rep_dp_diff);
      } else {
        REP_DP_DIFF = 0;
      } // distance on default fuel in km
    } else {
      //console.error('[ERROR]: rep_dp_diff data are missing');
    }

    if(rep_dg_diff) {
      if(Math.sign(rep_dg_diff) === 1) {
        REP_DG_DIFF = Number(rep_dg_diff);
      } else {
        REP_DG_DIFF = 0;
      } // distance on default fuel in km // distance on alternative fuel in km
    } else {
      //console.error('[ERROR]: rep_dg_diff data are missing');
    }

    if(rep_to_diff) {
      if(Math.sign(rep_to_diff) === 1) {
        TOTAL_INJECTED_GAS_10MS = Number(rep_to_diff);
      } else {
        TOTAL_INJECTED_GAS_10MS = 0;
      } // this is quantity of used gas represented in some units known by gas module (unit is not important as it cancels itself in this equation)
    } else {
      //console.error('[ERROR]: rep_to_diff data are missing');
    }

    if(rep_kb_diff) {
      if(Math.sign(rep_kb_diff) === 1) {
        REP_KB_DIFF = Number(rep_kb_diff);
      } else {
        REP_KB_DIFF = 0;
      } // data transfered in kb
    } else {
      //console.error('[ERROR]: rep_kb_diff data are missing');
    }

    if(gmi_ti) {
      if(Math.sign(gmi_ti) === 1) {
        TOTAL_GAS_INJECTION_PER_TANK_10MS = Number(gmi_ti);
      } else {
        TOTAL_GAS_INJECTION_PER_TANK_10MS = 0;
      } // this is quantity of gas required to use up full gas tank (in the same units used by gas module)
    } else {
      //console.error('[ERROR]: gmi_ti data are missing');
    }


    // fuel cost
    if(cost_petrol) {
      COST_PETROL = Number(cost_petrol); // this is quantity of gas required to use up full gas tank (in the same units used by gas module)
    } else {
      //console.error('[ERROR]: cost_petrol data are missing');
    }
    
    if(cost_diesel) {
      COST_DIESEL = Number(cost_diesel); // this is quantity of gas required to use up full gas tank (in the same units used by gas module)
    } else {
      //console.error('[ERROR]: cost_diesel data are missing');
    }

    if(cost_lpg) {
      COST_LPG = Number(cost_lpg); // this is quantity of gas required to use up full gas tank (in the same units used by gas module)
    } else {
      //console.error('[ERROR]: cost_lpg data are missing');
    }

    if(cost_cng) {
      COST_CNG = Number(cost_cng); // this is quantity of gas required to use up full gas tank (in the same units used by gas module)
    } else {
      //console.error('[ERROR]: cost_cng data are missing');
    }

    // simple assignment
    TOTAL_DATA_KB = REP_KB_DIFF;
    ALTERNATIVE_DISTANCE_KM = REP_DG_DIFF;
    DEFAULT_DISTANCE_KM = REP_DP_DIFF;
    ALTERNATIVE_TIME_S = REP_TG_DIFF;
    DEFAULT_TIME_S = REP_TP_DIFF;


    // calculations
    TOTAL_TIME_S = ALTERNATIVE_TIME_S + DEFAULT_TIME_S;
    TOTAL_DISTANCE_KM = ALTERNATIVE_DISTANCE_KM + DEFAULT_DISTANCE_KM;
    TOTAL_ALTERNATIVE_PERCENT = ReportFunctions.alternativeFuelPercent({ ALTERNATIVE_DISTANCE_KM, DEFAULT_DISTANCE_KM });


    // calculations based on IGL
    if(IGL === 1) {
      TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = ReportFunctions.alternativeFuelConsumption({ TOTAL_INJECTED_GAS_10MS, TANK_SIZE_L, TOTAL_GAS_INJECTION_PER_TANK_10MS });
      TOTAL_DEFAULT_FUEL_CONSUMPTION_L = ReportFunctions.iglDefaultFuelConsumptionNew({ DEFAULT_DISTANCE_KM, ALTERNATIVE_DISTANCE_KM, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, CONSUMPTION_DIFFERENCE_PERCENTAGE, AVERAGE_CONSUMPTION_L });
      TOTAL_FUEL_CONSUMPTION_L = (TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L + TOTAL_DEFAULT_FUEL_CONSUMPTION_L);
      TOTAL_SAVINGS_CO2_KG = ReportFunctions.iglCO2Savings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, ENGINE_TYPE });

      IGL_GAS_CONSUMPTION_L = ReportFunctions.iglGasConsumption({ TOTAL_INJECTED_GAS_10MS, TANK_SIZE_L, TOTAL_GAS_INJECTION_PER_TANK_10MS });
      DEFAULT_FUEL_COST = ReportFunctions.iglDefaultFuelCost({ TOTAL_DEFAULT_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL, ENGINE_TYPE });
      ALTERNATIVE_FUEL_COST = ReportFunctions.iglAlternativeFuelCost({ ALTERNATIVE_FUEL_TYPE, COST_CNG, COST_LPG, IGL_GAS_CONSUMPTION_L });
      
      DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE = ReportFunctions.defaultFuelCostWithoutAlternativeNew({ ENGINE_TYPE, CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_DIESEL, COST_PETROL });
      
      ESTIMATED_POTENTIAL_SAVINGS = ReportFunctions.potentialSavings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL, COST_LPG, COST_CNG, ENGINE_TYPE, ALTERNATIVE_FUEL_TYPE });
      ESTIMATED_SAVINGS = ReportFunctions.calculateEstmiatedSavings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, ENGINE_TYPE, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL });

    } else {
      TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L = ((AVERAGE_CONSUMPTION_L / 100) * ALTERNATIVE_DISTANCE_KM) * ((100 + CONSUMPTION_DIFFERENCE_PERCENTAGE)/100);
      TOTAL_DEFAULT_FUEL_CONSUMPTION_L = ((AVERAGE_CONSUMPTION_L / 100) * DEFAULT_DISTANCE_KM);
      TOTAL_FUEL_CONSUMPTION_L = TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L + TOTAL_DEFAULT_FUEL_CONSUMPTION_L;
      TOTAL_SAVINGS_CO2_KG = ReportFunctions.noIglCO2Savings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_FUEL_CONSUMPTION_L, ENGINE_TYPE, TOTAL_ALTERNATIVE_PERCENT });

      DEFAULT_FUEL_COST = ReportFunctions.noIglDefaultFuelCost({ ENGINE_TYPE, COST_PETROL, COST_DIESEL, TOTAL_DEFAULT_FUEL_CONSUMPTION_L});
      ALTERNATIVE_FUEL_COST = ReportFunctions.noIglAlternativeFuelCost({ ALTERNATIVE_FUEL_TYPE, COST_CNG, COST_LPG, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L });



      DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE = ReportFunctions.defaultFuelCostWithoutAlternativeNew({ ENGINE_TYPE, CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_DIESEL, COST_PETROL });
      
      ESTIMATED_POTENTIAL_SAVINGS = ReportFunctions.potentialSavings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL, COST_LPG, COST_CNG, ENGINE_TYPE, ALTERNATIVE_FUEL_TYPE });
      ESTIMATED_SAVINGS = ReportFunctions.calculateEstmiatedSavings({ CONSUMPTION_DIFFERENCE_PERCENTAGE, ENGINE_TYPE, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, COST_PETROL, COST_DIESEL });
      
    }

  }

  return { TOTAL_DATA_KB, TOTAL_DISTANCE_KM, DEFAULT_DISTANCE_KM, ALTERNATIVE_DISTANCE_KM, TOTAL_TIME_S, TOTAL_SAVINGS_CO2_KG, TOTAL_ALTERNATIVE_PERCENT, TOTAL_DEFAULT_FUEL_CONSUMPTION_L, TOTAL_ALTERNATIVE_FUEL_CONSUMPTION_L, ESTIMATED_SAVINGS, ESTIMATED_POTENTIAL_SAVINGS, ALTERNATIVE_FUEL_COST, DEFAULT_FUEL_COST, DEFAULT_FUEL_COST_WITHOUT_ALTERNATIVE };


}

export default calculateObjectReportSavingsNew;
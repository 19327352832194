import { WORKER } from '../types';
import { API_ADDRESS } from '../config';
import t from '../components/translation/translate';
import { error, success } from '../helpers/notifications';


let prevMessage = 0;

const fetchWorker = (options: { endpoint: string, options: any, SUCCESS_TYPE: any }) => (dispatch: any) => {
  dispatch({ type: WORKER.FETCH });
  return fetch(`${API_ADDRESS}/worker/${options.endpoint}`, options.options)
        .then(response => response.json())
        .then(json => {
          if (json.errors !== undefined) {
            dispatch({ type: WORKER.FETCH_ERROR, ...json });
            json && json.errors && json.errors.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  error(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          } else {
            dispatch({ type: options.SUCCESS_TYPE, ...json });
            json && json.success && json.success.map((item: {msg: number}) => {
              if(item.msg) {
                
                if(item.msg === prevMessage && json.repeat === false) {
                  return null
                } else {
                  success(t(item.msg));
                }
                prevMessage = item.msg
                setTimeout(() => prevMessage = 0, 10000);
              }
              return null
            })
          }
        })
        .catch(error => dispatch({
          type: WORKER.FETCH_ERROR, errors: [{ msg: error }]
        }));
}


export const fetchAllWorkerLogs = (options: { limit: number, skip: number }) => fetchWorker({
  endpoint: '?limit=' + options.limit + '&skip=' + options.skip,
  options: { credentials: 'include' },
  SUCCESS_TYPE: WORKER.FETCH_ALL_SUCCESS
});


export const fetchWorkersState = () => fetchWorker({
  endpoint: 'workers-state',
  options: { credentials: 'include' },
  SUCCESS_TYPE: WORKER.FETCH_WORKERS_STATE_SUCCESS
});
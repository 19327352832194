import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllImei, fetchImeiSearch } from '../../actions/imei'
import TemplatePage from '../templates/TemplatePage'
import { Link } from 'react-router-dom'
import Card from '../partials/Card'
import { Tooltip } from 'react-tooltip'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import { IconClose, IconDone, IconDelete } from '../partials/Icons'
import Auth from '../partials/Auth'
import queryString from 'query-string'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import fetchStates from '../../types/fetchStates'
import Preloader from '../partials/Preloader'


interface ImeiProps {
  router: {location: {pathname: string, search: string}, navigate: (to: string) => any},
  imei: { status: string, imeis: any, imeisCount: number },
  fetchImeiSearch: (options: {search: string, limit: number, skip: number}) => Promise<any>,
  fetchAllImei: (options: {limit: number, skip: number}) => Promise<any>,
}


interface ImeiState {
  imeis: Array<any>,
  imeisCount: number,
  currentPage: number,
  perPage: number,
  search: string
}


export class Imei extends React.Component<ImeiProps, ImeiState> {

  state = {
    imeis: [],
    imeisCount: 0,
    currentPage: 0,
    perPage: 10,
    search: ''
  }

  componentDidMount() {

    // set the defaults
    let currentPage = this.state.currentPage
    let perPage = this.state.perPage

    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search)
    if(parsed.page !== null && parsed.page !== undefined) {
      currentPage = parseInt(parsed.page.toString())
    }
    if(parsed.perPage !== null && parsed.perPage !== undefined) {
      if(parsed && parseInt(parsed.perPage.toString()) < 51) {
        perPage = parseInt(parsed.perPage.toString())
      } else {
        this.props.router.navigate(`/imei?page=${currentPage}&perPage=${perPage}`)
      }
    } else {
      this.props.router.navigate(`/imei?page=${currentPage}&perPage=${perPage}`)
    }

    // update state and load the data
    this.setState({ perPage, currentPage })
    this.reloadPage({ perPage, currentPage })

  }

  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.props.router.navigate(`/imei?page=0&perPage=${e.target.value}`)
  }

  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage })
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage })
    this.props.router.navigate(`/imei?page=${prevPage}&perPage=${this.state.perPage}`)
  }

  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage })
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage })
    this.props.router.navigate(`/imei?page=${nextPage}&perPage=${this.state.perPage}`)
  }

  reloadPage = (options: { perPage: number, currentPage: number }) => {
    if(this.state.search === '') {
      return this.props.fetchAllImei({ limit: options.perPage, skip: options.perPage * options.currentPage })
      .then(() => {
        if(this.props.imei.status === fetchStates.success) {
          const { imeis, imeisCount } = this.props.imei
          this.setState({ imeis, imeisCount })
        }
      })
    } else {
      return this.props.fetchImeiSearch({ search: this.state.search, limit: options.perPage, skip: options.perPage * options.currentPage })
      .then(() => {
        if(this.props.imei.status === fetchStates.success) {
          const { imeis, imeisCount } = this.props.imei
          this.setState({ imeis, imeisCount })
        }
      })
    }
  }

  handleSearch = () => {
    if(this.state.search !== '') {
      return this.props.fetchImeiSearch({ search: this.state.search, limit: this.state.perPage, skip: 0 })
      .then(() => {
        if(this.props.imei.status === fetchStates.success) {
          const { imeis, imeisCount } = this.props.imei
          this.setState({ imeis, imeisCount })
        }
      })
    }
  }

  handleSearchClose = () => {
    this.setState({ search: '', currentPage: 0 });
    return this.props.fetchAllImei({ limit: this.state.perPage, skip: 0 })
    .then(() => {
      if(this.props.imei.status === fetchStates.success) {
        const { imeis, imeisCount } = this.props.imei
        this.setState({ imeis, imeisCount })
      }
    })
  }


  render() {

    const { status } = this.props.imei;
    const { currentPage, perPage, search, imeis, imeisCount } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          title={t(9)} 

          button={true} 
          buttonLink='/imei/upload' 
          buttonTitle={t(103)} 
          buttonClickable={false}
          buttonHandleOnClick={() => null}

          search={true} 
          searchPlaceholder={t(2004)}
          searchValue={search}
          handleSearchValue={e => this.setState({ search: e.target.value })} 
          handleSearch={() => this.handleSearch()}
          handleSearchClose={() => this.handleSearchClose()}
          time={false}
          animate
        >
          <div className="table-wrapper">
            <table className="table">
            <TableHeader 
              columns={['', t(2005), t(2006)]} 
            />
              <tbody className="table__body table__body--striped">
                {imeis && imeis.length > 0 && 
                imeis.map((item: {module_id: number, paired: boolean, imei: string}, index: number) => {
                  return (
                  <tr key={item.module_id}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-imei-delete${index}`} />
                      {item.paired !== true &&
                        <Auth requiresRole={[1]}>
                          <span id={`tooltip-imei-delete${index}`} data-tooltip-content={t(29)}>
                            <Link to={`/imei/delete/${item.module_id}`}>
                              <IconDelete task button color="#ffffff" />
                            </Link>
                          </span>
                        </Auth>
                      }
                    </td>
                    <td>
                      {item.imei}
                    </td>
                    <td>
                      {item.paired === true ? <IconDone table success color="#ffffff" /> : <IconClose table error color="#ffffff" />}
                    </td>
                  </tr>
                  )
                })
              }
              </tbody>

              <TableFooter 
                colSpan={3}
                button={false}
                currentPage={currentPage}
                perPage={perPage}
                itemsCount={ imeisCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />
            </table>
          </div>

        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ imei }: RootState) => ({ imei }),
  { fetchAllImei, fetchImeiSearch }
)(Imei)))
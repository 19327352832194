import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import fetchStates from '../../types/fetchStates'
import TemplateAuth from '../templates/TemplateAuth'
import { forgotPassword } from '../../actions/auth'
import { Link } from 'react-router-dom'
import { IconBack } from '../partials/Icons'
import classnames from 'classnames'
import t from '../translation/translate'
import { Navigate } from 'react-router-dom'


interface AuthForgotPasswordProps {
  auth: {status: string, fields: Array<string>, authenticated: boolean},
  forgotPassword: (options: { email: string }) => Promise<any>,
}


interface AuthForgotPasswordState {
  formSubmitted: boolean,
  email: string,
}


export class AuthForgotPassword extends React.Component<AuthForgotPasswordProps, AuthForgotPasswordState> {


  state = {
    formSubmitted: false,
    email: ''
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const { email } = this.state;
    this.props.forgotPassword({ email })
    .then(() => {
      if(this.props.auth.status === fetchStates.success) {
        this.setState({ email: '' });
      }
      this.setState({ formSubmitted: false });
    })
  }


  render() {

    const { fields, status } = this.props.auth;
    const { formSubmitted, email } = this.state;

    return this.props.auth.authenticated ? <Navigate to="/" /> : (
      <TemplateAuth>
        <div className="auth-form">
          <div className="auth-form__header">
            <Link to="/" className='btn--circular'>
              <IconBack color="#ffffff" />
            </Link>
          </div>
          <h1 className="auth-form__title">{t(133)}</h1>
          <p>{t(2215)}</p>

          <form className='form' onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                autoComplete='username'
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('email') })}
              />
            </div>
            <div className="form-group">
              <input 
                type='submit' 
                className='btn btn--primary btn--large' 
                value={t(134)} 
                disabled={formSubmitted && status === 'fetching' ? true : false} 
              />
            </div>
          </form>
        </div>
      </TemplateAuth>
    )
  }
}


export default connect(
  ({ auth }: RootState) => ({ auth }),
  { forgotPassword  }
)(AuthForgotPassword);
import React from 'react'


interface PreloaderProps {
  type: string,
}


const Preloader: React.FC<PreloaderProps> = ({ type }) => {
  return (
    <div className={`preloader-overlay ${type}`}>
      <div className="preloader-center">
        <div className="preloader">
          <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#2CBFFC" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
        </div>
      </div>
    </div>
  )
}


export default Preloader
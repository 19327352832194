import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllCheckpoints, fetchDateCheckpointVisitsVehicle } from '../../actions/checkpoint'
import { fetchAllVehicles } from '../../actions/vehicle'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import moment from 'moment-timezone'
import 'moment-duration-format'
import { GoogleMap, Marker, Circle } from '@react-google-maps/api'
import DatePicker from 'react-datepicker'
import fetchStates from '../../types/fetchStates'
import { TIMEZONE } from '../../config'
import queryString from 'query-string'
import markerCheckpoint from'../../img/marker-position.png'
import markerCheckpointVehicleStop from'../../img/marker-vehicle-position.png'
import Preloader from '../partials/Preloader'


interface CheckpointsMapProps {
  router: { location: {search: string}, navigate: (to: string) => any },
  auth: any,
  vehicle: any,
  checkpoint: any,
  fetchAllVehicles: (options: {limit: number, skip: number, param: string, paramValue: boolean, group_id: string}) => Promise<any>,
  fetchAllCheckpoints: (options: {limit: number, skip: number, param: string, paramValue: boolean}) => Promise<any>,
  fetchDateCheckpointVisitsVehicle: (options: {limit: number, skip: number, filterDateFrom: string, filterDateTo: string, filterVehicle: string, filterCheckpoint: string}) => Promise<any>,
}


interface CheckpointsMapState {
  currentPage: number,
  markerLat: number,
  markerLng: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  filterVehicle: string,
  filterDateFrom: any,
  filterDateTo: any,
  filterCheckpoint: string,
  todayDate: any,
  vehicles: any,
  checkpoints: any,
  selectedMarker: number,
  dataReceived: boolean,
  timezone: string,
  checkpointsDateVehicleVisits: any,
}


export class CheckpointsMap extends React.Component<CheckpointsMapProps, CheckpointsMapState> {


  state = {
    currentPage: 0,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    filterVehicle: '',
    filterDateFrom: moment().subtract(1, 'months').toDate(),
    filterDateTo: moment().toDate(),
    filterCheckpoint: '',
    todayDate: moment().toDate(),
    vehicles: [],
    checkpoints: [],
    selectedMarker: 0,
    dataReceived: false,
    timezone: '',
    checkpointsDateVehicleVisits: [],
  }

  componentDidMount() {
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.props.fetchAllVehicles({ limit: 1000, skip: 0, param: 'custom_id', paramValue: true, group_id: 'all' })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicles } = this.props.vehicle
        this.setState({ vehicles })
      }
    })
    this.props.fetchAllCheckpoints({ limit: 1000, skip: 0, param: 'custom_id', paramValue: false })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpoints } = this.props.checkpoint
        this.setState({ checkpoints })
      }
    })
    // set the defaults
    let { filterVehicle, filterDateFrom, filterDateTo, filterCheckpoint } = this.state;
    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search);
    if(parsed.filterVehicle !== null && parsed.filterVehicle !== undefined) {
      filterVehicle = parsed.filterVehicle.toString()
    }
    if(parsed.filterDateFrom !== null && parsed.filterDateFrom !== undefined) {
      filterDateFrom = moment.unix(parseInt(parsed.filterDateFrom.toString())).toDate()
    }
    if(parsed.filterDateTo !== null && parsed.filterDateTo !== undefined) {
      filterDateTo = moment.unix(parseInt(parsed.filterDateTo.toString())).toDate()
    }
    if(parsed.filterCheckpoint !== null && parsed.filterCheckpoint !== undefined) {
      filterCheckpoint = parsed.filterCheckpoint.toString()
    }    
    this.setState({ filterVehicle, filterDateFrom, filterDateTo, filterCheckpoint, dataReceived: true })
  }


  showMarkerTooltip = (marker_id: number) => {
    if(marker_id === this.state.selectedMarker) {
      this.setState({ selectedMarker: 0 })
    } else {
      this.setState({ selectedMarker: marker_id })
    }
  }


  updatefilterDateFrom = (date: any) => {
    this.setState({ currentPage: 0, filterDateFrom: date, dataReceived: false });
    this.reloadPage({ filterDateFrom: date, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  updatefilterDateTo = (date: any) => {
    this.setState({ currentPage: 0, filterDateTo: date, dataReceived: false });
    this.reloadPage({ filterDateFrom: this.state.filterDateFrom, filterDateTo: date, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  updatefilterVehicle = (event: any) => {
    this.setState({ currentPage: 0, filterVehicle: event.target.value, dataReceived: false });
    this.reloadPage({ filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: event.target.value, filterCheckpoint: this.state.filterCheckpoint });
  }

  updatefilterCheckpoint = (event: any) => {
    const { checkpoints } = this.state
    if(checkpoints) {
      checkpoints.map((checkpoint: {checkpoint_id: number, lat: number, lng: number}) => {
        if(checkpoint.checkpoint_id === parseInt(event.target.value)) {
          this.setState({ markerLat: checkpoint.lat, markerLng: checkpoint.lng })
          this.updateMapCenter();
        }
        return null
      })
    }
    this.setState({ currentPage: 0, filterCheckpoint: event.target.value, dataReceived: false });
    this.reloadPage({ filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: event.target.value });
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  reloadPage = (options: { filterDateFrom: any, filterDateTo: any, filterVehicle: string, filterCheckpoint: string }) => {
    const { filterDateFrom, filterDateTo, filterVehicle, filterCheckpoint } = options
    this.props.router.navigate(`/checkpoints/map?filterVehicle=${filterVehicle}&filterDateFrom=${moment(filterDateFrom).format('X')}&filterDateTo=${moment(filterDateTo).format('X')}&filterCheckpoint=${filterCheckpoint}`)
    this.props.fetchDateCheckpointVisitsVehicle({ limit: 10000, skip: 0, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), filterVehicle, filterCheckpoint })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpointsDateVehicleVisits } = this.props.checkpoint
        const { checkpoints } = this.props.checkpoint
        checkpoints.map((checkpoint: {total_visits: number, total_hours: number, checkpoint_id: number}) => {
          checkpoint.total_visits = 0
          checkpoint.total_hours = 0
          checkpointsDateVehicleVisits.map((visit: {checkpoint_id: number, diff: number}) => {
            if(checkpoint.checkpoint_id === visit.checkpoint_id) {
              checkpoint.total_visits = checkpoint.total_visits + 1
              checkpoint.total_hours = checkpoint.total_hours + visit.diff
            }
            return visit
          })
          return checkpoint
        })
        this.setState({ checkpointsDateVehicleVisits, dataReceived: true })
      }
    })
  }


  drawCheckpointCircle = (options: { markerLat: number, markerLng: number, range: number }) => {
    const { markerLat, markerLng, range } = options
    return <Circle
    // required
    center={{
      lat: markerLat,
      lng: markerLng
    }}
    // required
    options={{
      strokeColor: '#2CBFFC',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2CBFFC',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: range,
      zIndex: 1
      }}
    />
  }


  render() {

    const { mapZoom, mapLat, mapLng, filterDateFrom, filterDateTo, todayDate, vehicles, checkpoints, checkpointsDateVehicleVisits, filterCheckpoint, filterVehicle, dataReceived } = this.state;

    return (
      <TemplatePage>

        { dataReceived === false ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          buttonBack={true} 
          title={t(206)}
          time={false}
          animate
          tabnav="checkpoints"
          tabnavId={0}
        >
          <form className="form">
            <div className="filter">

            <span className="filter__filter-group">
                <span className="filter__filter-text">{t(22)}</span>
                  <span className="filter_filter-select">
                  <select 
                    className="filter__select" 
                    value={filterVehicle} 
                    onChange={e => this.updatefilterVehicle(e)}>
                    <option value="">{t(1122)}</option>
                    {vehicles.map((vehicle: {vehicle_id: number, module_imei: string, custom_id: string}) => (
                      <option key={vehicle.vehicle_id} value={vehicle.module_imei}>{vehicle.custom_id}</option>
                    ))}
                  </select>
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1123)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDateFrom}
                    selectsStart
                    onChange={this.updatefilterDateFrom}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    required={true}
                  />
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1124)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDateTo}
                    selectsEnd
                    onChange={this.updatefilterDateTo}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    required={true}
                  />
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1125)}</span>
                <span className="filter_filter-select">
                  <select 
                    className="filter__select" 
                    value={filterCheckpoint} 
                    onChange={e => this.updatefilterCheckpoint(e)}>
                    <option value="">All</option>
                    {checkpoints.map((checkpoint: { checkpoint_id: string, custom_id: string}) => (
                      <option key={checkpoint.checkpoint_id} value={checkpoint.checkpoint_id}>{checkpoint.custom_id}</option>
                    ))}
                  </select>
                </span>
              </span>


            </div>
          </form>

          <GoogleMap
            center={{ 
              lat: mapLat, 
              lng: mapLng 
            }}
            zoom={ mapZoom }
            id="map"
          >

            { 
              checkpoints !== undefined && checkpoints.map((checkpoint: {lat: number, lng: number, range: number, checkpoint_id: number, total_visits: number, custom_id: string}, index: number) => {
                return <Marker
                          key={index}
                          position={{
                            lat: checkpoint.lat,
                            lng: checkpoint.lng
                          }}
                          icon={
                            // @ts-expect-error
                            new window.google.maps.MarkerImage(
                              markerCheckpoint,
                              null, 
                              null, 
                              null, 
                              new window.google.maps.Size(48, 48)
                            )
                          }
                          onClick={() => this.showMarkerTooltip(checkpoint.checkpoint_id)}
                        >
                          { this.drawCheckpointCircle({ markerLat: checkpoint.lat, markerLng: checkpoint.lng, range: checkpoint.range }) }

                          {
                            /*
                                                        <InfoBox
                              // @ts-expect-error
                              className={classnames('info-box', { 'visible': selectedMarker === checkpoint.checkpoint_id })}
                              position={{
                                lat: checkpoint.lat,
                                lng: checkpoint.lng
                              }}
                              options={{ closeBoxURL: ``, enableEventPropagation: true,
                              pixelOffset: new window.google.maps.Size(-16, -110) }}
                            >
                              <div className='marker-label-wrapper'>
                                <div className='marker-label'>

                                  {
                                    checkpoint.total_visits > 0 ? (
                                      <>
                                        <div className='marker-label-title'>{checkpoint.custom_id}</div>
                                        <div className='marker-label-text'>{`${checkpoint.total_visits} visits`}</div>
                                      </>
                                    ) : (
                                      <>
                                        <div className='marker-label-title'>{checkpoint.custom_id}</div>
                                        <span className='marker-label-text'>No visits for this checkpoint</span>
                                      </>
                                    )
                                  }

                                </div>
                                <span className='marker-label-pointer'></span>
                              </div>
                            </InfoBox>
                            */
                          }



                        </Marker>
              })
            }






            { 
              checkpointsDateVehicleVisits && checkpointsDateVehicleVisits.map((checkpoint: {id: number, checkpoint_id: string, stop_ts: number, loc_ln: number, loc_lt: number, diff: number}) => {
                return <Marker
                          key={checkpoint.id}
                          position={{
                            lat: checkpoint.loc_lt,
                            lng: checkpoint.loc_ln
                          }}
                          icon={
                            // @ts-expect-error
                            new window.google.maps.MarkerImage(
                              markerCheckpointVehicleStop,
                              null, 
                              null, 
                              null, 
                              new window.google.maps.Size(48, 48)
                            )
                          }
                        >

                          {
                            /*
                                                      <InfoBox
                            // @ts-expect-error
                            className={classnames('info-box', { 'visible': selectedMarker === checkpoint.checkpoint_id })}
                            position={{
                              lat: checkpoint.loc_lt,
                              lng: checkpoint.loc_ln
                            }}
                            options={{ closeBoxURL: ``, enableEventPropagation: true,
                            pixelOffset: new window.google.maps.Size(-16, -110) }}
                          >
                            <div className='marker-label-wrapper'>
                              <div className='marker-label'>
                                <div className='marker-label-title'>{checkpoint.stop_ts}</div>
                                <div className='marker-label-text'>{moment("1900-01-01 00:00:00").add(checkpoint.diff, 'seconds').format("HH:mm:ss")}</div>
                              </div>
                              <span className='marker-label-pointer'></span>
                            </div>
                          </InfoBox>
                            */
                          }

                        </Marker>
              })
            }


          {
            checkpoints && filterCheckpoint ? (
              <div className="checkpoint-info-panel">
                { 
                  checkpoints.map((checkpoint: {checkpoint_id: number, custom_id: string}, index: number) => {
                    if(checkpoint.checkpoint_id === parseInt(filterCheckpoint)) {
                      return  <div key={index}><div className="checkpoint-info-panel__title">Visits for the {checkpoint.custom_id} checkpoint</div>
                      <div className="checkpoint-info-panel__scrollable-wrapper">
                        {checkpointsDateVehicleVisits && checkpointsDateVehicleVisits.map((visit: {id: number, start_ts: number, stop_ts: number, diff: number}) => {
                          return <div key={visit.id}><strong>{moment(visit.stop_ts).format('YYYY/MM/DD')}</strong> {moment(visit.stop_ts).format('HH:mm:ss')} - {moment(visit.start_ts).format('HH:mm:ss')} ({moment("1900-01-01 00:00:00").add(visit.diff, 'seconds').format("HH:mm:ss")})</div>
                        })}
                      </div>
                      </div>
                    }
                    return null
                  })
                }
              </div>
            ) : (
              filterVehicle && checkpoints && checkpoints.length > 0 && (
                <div className="checkpoint-info-panel">
                  <div className="checkpoint-info-panel__title">Time and number of visits per checkpoint</div>
                  <div className="checkpoint-info-panel__scrollable-wrapper">
                    { checkpoints && checkpoints.map((checkpoint: {total_visits: number, checkpoint_id: number, custom_id: string, total_hours: number}) => {
                      if(checkpoint.total_visits > 0) {
                        return <div key={checkpoint.checkpoint_id}><strong>{checkpoint.custom_id}</strong> (<strong>{checkpoint.total_visits} visits</strong>, <strong>{moment.duration(checkpoint.total_hours, 'seconds').format("hh:mm:ss")}</strong> total time)</div>
                      }
                      return null
                    })}
                  </div>
                </div>)
            )
          }




          <div className="map-constraints-container" />
        </GoogleMap>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, auth, vehicle }: RootState) => ({ checkpoint, auth, vehicle }),
  { fetchAllCheckpoints, fetchDateCheckpointVisitsVehicle, fetchAllVehicles }
)(CheckpointsMap)))
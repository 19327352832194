import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { checkAuth } from '../../actions/auth'
import { fetchCheckpointById, updateCheckpoint } from '../../actions/checkpoint'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, DEFAULT_RANGE, TIMEZONE } from '../../config'
import markerCheckpoint from'../../img/marker-position.png'
import { GoogleMap, Circle, Marker } from '@react-google-maps/api'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import fetchStates from '../../types/fetchStates'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Preloader from '../partials/Preloader'


interface CheckpointsEditProps {
  router: { params: { id: string }, navigate: (to: string) => any},
  auth: any,
  checkpoint: any,
  checkAuth: () => Promise<any>,
  fetchCheckpointById: (id: number) => Promise<any>,
  updateCheckpoint: (checkpoint: any) => Promise<any>,
}


interface CheckpointsEditState {
  id: string,
  custom_id: string,
  address: string,
  label: string,
  visited: string,
  registered: string,
  range: number,
  mapLat: number,
  mapLng: number,
  mapZoom: number,
  markerLat: number,
  markerLng: number,
  addressSelected: boolean,
  formSubmitted: boolean,
  timezone: string,
}


export class CheckpointsEdit extends React.Component<CheckpointsEditProps, CheckpointsEditState> {


  state = {
    id: '',
    custom_id: '',
    address: '',
    label: '',
    visited: '',
    registered: '',
    range: DEFAULT_RANGE,
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    addressSelected: false,
    formSubmitted: false,
    timezone: TIMEZONE
  }


  componentDidMount() {
    // timezone
    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.props.checkAuth()
    .then(() => {
      this.props.fetchCheckpointById(parseInt(this.props.router.params.id))
      .then(() => {
        if(this.props.checkpoint.checkpoint !== undefined) {
          const { checkpoint_id, custom_id, address, label, visited, registered, zoom, range, lat, lng } = this.props.checkpoint.checkpoint;
          this.setState({
            id: checkpoint_id,
            custom_id,
            address,
            label,
            visited,
            registered,
            mapLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
            mapLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
            markerLat: lat !== undefined && lat !== null ? lat : DEFAULT_LAT,
            markerLng: lng !== undefined && lng !== null ? lng : DEFAULT_LNG,
            mapZoom: zoom !== undefined && zoom !== null ? zoom : DEFAULT_ZOOM,
            range: range !== undefined && range !== null ? range : DEFAULT_RANGE
          });
        }
      });
      this.drawMapCircle();
    });
  }


  handleOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    this.setState({ formSubmitted: true });
    const checkpoint = { 
      id: this.state.id, 
      custom_id: this.state.custom_id, 
      address: this.state.address,
      label: this.state.label,
      zoom: this.state.mapZoom,
      range: this.state.range,
      lat: this.state.markerLat,
      lng: this.state.markerLng 
    }
    this.props.updateCheckpoint({ checkpoint })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        this.props.router.navigate('/checkpoints');
      }
      this.setState({ formSubmitted: false });
    });
  }


  handleRangeChange = (event:any) => {
    this.setState({ range: event.target.value });
  }


  handleChangeAddress = (address:string) => {
    this.setState({ address, addressSelected: false });
  }


  handleSelect = (address: string) => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => 
          getLatLng(results[0])
          .then((result: any) => {
            this.setState({
              markerLat: result.lat,
              markerLng: result.lng,
              addressSelected: true
            })
            this.drawMarker();
            this.updateMapCenter();
        })
      )
      .catch(error => console.error('Error', error));
  }


  handleMapClick = (event:any) => {
    this.setState({
      markerLat: event.latLng.lat(),
      markerLng: event.latLng.lng(),
      addressSelected: true
    });
    this.updateMapCenter();
  }


  drawMapCircle = () => {
    return <Circle
    // required
    center={{
      lat: this.state.markerLat,
      lng: this.state.markerLng
    }}
    // required
    options={{
      strokeColor: '#2CBFFC',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#2CBFFC',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: this.state.range,
      zIndex: 1
    }}
  />
  }


  updateMapCenter = () => {
    setTimeout(() => {
      this.setState({
        mapLat: this.state.markerLat,
        mapLng: this.state.markerLng,
      })
    }, 250);
  }


  drawMarker = () => {
      return <Marker
      position={{
        lat: this.state.markerLat,
        lng: this.state.markerLng
      }}
      icon={
        // @ts-expect-error
        new window.google.maps.MarkerImage(
          markerCheckpoint,
          null, /* size is determined at runtime */
          null, /* origin is 0,0 */
          null, /* anchor is bottom center of the scaled image */
          new window.google.maps.Size(48, 48)
        )
      }
    >
    </Marker>
  }


  render() {

    const { status, fields } = this.props.checkpoint;
    const { custom_id, address, label, visited, registered, range, mapLat, mapLng, mapZoom, markerLat, markerLng, formSubmitted, timezone } = this.state;

    return (
      <TemplatePage>

        { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

        <Card 
          button={false}
          title={t(1204)} 
          buttonBack={true} 
          navigate={this.props.router.navigate}
          padding={['large']}
          time={false}
          animate
        >
          <form className="form" onSubmit={this.handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="custom_id">
                {t(1098)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="custom_id"
                id="custom_id" 
                value={custom_id}
                onChange={e => this.setState({ custom_id: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('custom_id') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {t(1002)} ({t(116)})
              </label>
              <PlacesAutocomplete
                value={address}
                onChange={ this.handleChangeAddress }
                onSelect={ this.handleSelect }
              >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Search places...',
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>{t(2327)}</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            </PlacesAutocomplete>
            </div>

            <div className="form-group">
              <label htmlFor="label">
                {t(1062)} ({t(116)})
              </label>
              <input 
                type="text" 
                name="label"
                id="label" 
                value={label}
                onChange={e => this.setState({ label: e.target.value })}
                className={classnames('', { 'input-error': fields && fields.includes('label') })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="latitude">
                {t(1099)}
              </label>
              <input 
                type="text" 
                name="latitude"
                id="latitude" 
                value={markerLat}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="longitude">
                {t(1100)}
              </label>
              <input 
                type="text" 
                name="longitude"
                id="longitude" 
                value={markerLng}
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="map">
                {t(1101)}
              </label>

                <GoogleMap
                  center={{ lat: mapLat, lng: mapLng }}
                  zoom={ mapZoom }
                  onClick={ (e) => this.handleMapClick(e) }
                >
                  { this.drawMapCircle() }

                  { this.drawMarker() }

                  <div className="map-constraints-container" />
                </GoogleMap>

                <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>

                <div className="map-constraints-slider"> 
                  <label htmlFor="range">{range + ' m'}</label>
                  <input type="range" id="range" value={range} name="range" min="1" max="100" onChange={this.handleRangeChange}/>
                </div>
            </div>

            <div className="form-group">
              <label htmlFor="visited">
                {t(1102)}
              </label>
              <input 
                type="text" 
                name="visited"
                id="visited" 
                value={visited}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="registered">
                {t(1103)}
              </label>
              <input 
                type="text" 
                name="registered"
                id="registered" 
                value={moment(registered).tz(timezone).format('DD/MM/YYYY')}
                disabled
              />
            </div>
            <div className="form-group">
              <input 
                type="submit" 
                value={t(1104)} 
                className="btn btn--primary card__footer--btn-left"
                disabled={ formSubmitted ? true : false }
              />
            </div>
          </form>
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, auth }: RootState) => ({ checkpoint, auth }),
  { fetchCheckpointById, updateCheckpoint, checkAuth }
)(CheckpointsEdit)))
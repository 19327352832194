import React from 'react'
import { RootState } from '../../reducers'
import { fetchAllCheckpoints } from '../../actions/checkpoint'
import { fetchDateCheckpointVisitsVehicle } from '../../actions/checkpoint'
import { fetchAllVehicles } from '../../actions/vehicle'
import { connect } from 'react-redux'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import queryString from 'query-string'
import { TIMEZONE } from '../../config'
import createNewCheckpointsVisitsPDF from '../../functions/createNewCheckpointsVisitsPDF'
import createNewCheckpointsVisitsCSV from '../../functions/createNewCheckpointsVisitsCSV'
import TableHeader from '../partials/TableHeader'
import TableFooter from '../partials/TableFooter'
import Preloader from '../partials/Preloader'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { IconVisibility } from '../partials/Icons'
import { Tooltip } from 'react-tooltip'


interface CheckpointsFilterProps {
  router: { navigate: any, location: {search: string} },
  checkpoint: any, 
  vehicle: any, 
  auth: any,
  fetchDateCheckpointVisitsVehicle: (options: {limit: number, skip: number, filterDateFrom: string, filterDateTo: string, filterVehicle: string, filterCheckpoint: string}) => Promise<any>,
  fetchAllVehicles: (options: { limit: number, skip: number, param: string, paramValue: boolean, group_id: string }) => Promise<any>,
  fetchAllCheckpoints: (options: { limit: number, skip: number, param: string, paramValue: boolean }) => Promise<any>,
}


interface CheckpointsFilterState {
  currentPage: number,
  perPage: number,
  filterVehicle: string,
  filterDateFrom: any,
  filterDateTo: any,
  filterCheckpoint: string,
  todayDate: any,
  vehicles: any,
  checkpoints: any,
  checkpointsDateVehicleVisitsCount: number,
  checkpointsDateVehicleVisits: any,
  dataReceived: boolean,
  timezone: string,
}


export class CheckpointsFilter extends React.Component<CheckpointsFilterProps, CheckpointsFilterState> {

  state = {
    currentPage: 0,
    perPage: 10,
    filterVehicle: '',
    filterDateFrom: moment().subtract(1, 'months').toDate(),
    filterDateTo: moment().toDate(),
    filterCheckpoint: '',
    todayDate: moment().toDate(),
    vehicles: [],
    checkpoints: [],
    checkpointsDateVehicleVisitsCount: 0,
    checkpointsDateVehicleVisits: [],
    dataReceived: false,
    timezone: TIMEZONE
  }


  componentDidMount() {

    this.setState({ timezone: this.props.auth.timezone ? this.props.auth.timezone : TIMEZONE })
    this.props.fetchAllVehicles({ limit: 1000, skip: 0, param: 'custom_id', paramValue: true, group_id: 'all' })
    .then(() => {
      if(this.props.vehicle.status === fetchStates.success) {
        const { vehicles } = this.props.vehicle
        this.setState({ vehicles })
      }
    })
    this.props.fetchAllCheckpoints({ limit: 1000, skip: 0, param: 'custom_id', paramValue: false })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpoints } = this.props.checkpoint
        this.setState({ checkpoints })
      }
    })

    // set the defaults
    let { currentPage, perPage, filterVehicle, filterDateFrom, filterDateTo, filterCheckpoint } = this.state;


    // get the defaults from the URL query if it exists
    const parsed = queryString.parse(this.props.router.location.search);

    if(parsed.filterVehicle !== null && parsed.filterVehicle !== undefined) {
      filterVehicle = parsed.filterVehicle.toString()
    }

    if(parsed.filterDateFrom !== null && parsed.filterDateFrom !== undefined) {
      filterDateFrom = moment.unix(parseInt(parsed.filterDateFrom.toString())).toDate()
    }

    if(parsed.filterDateTo !== null && parsed.filterDateTo !== undefined) {
      filterDateTo = moment.unix(parseInt(parsed.filterDateTo.toString())).toDate()
    }

    if(parsed.filterCheckpoint !== null && parsed.filterCheckpoint !== undefined) {
      filterCheckpoint = parsed.filterCheckpoint.toString()
    }    

    this.setState({ filterVehicle, filterDateFrom, filterDateTo, filterCheckpoint, dataReceived: true })
    this.reloadPage({ currentPage, perPage, filterDateFrom, filterDateTo, filterVehicle, filterCheckpoint })

  }


  getTimezoneOffset(time: string) {
    const UTCServerTime = moment(time).format('YYYY-MM-DD 00:00:00')
    return moment(UTCServerTime).format('X')
    //time - (moment.tz(TIMEZONE).utcOffset(moment(this.state.filterDateFrom).format('X'))._offset * 60);
  }


  handleDownloadCSV = () => {
    const { filterDateFrom, filterDateTo, filterVehicle, filterCheckpoint } = this.state
    this.props.fetchDateCheckpointVisitsVehicle({ limit: 1000, skip: 0, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), filterVehicle, filterCheckpoint })
    .then(() => {
      const data = this.props.checkpoint.checkpointsDateVehicleVisits
      if(data.length > 0) {
        return createNewCheckpointsVisitsCSV(data);
      }
    });
  }


  handleDownloadPDF = () => {
    const { filterDateFrom, filterDateTo, filterVehicle, filterCheckpoint } = this.state
    this.props.fetchDateCheckpointVisitsVehicle({ limit: 1000, skip: 0, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), filterVehicle, filterCheckpoint })
    .then(() => {
      const data = this.props.checkpoint.checkpointsDateVehicleVisits
      if(data.length > 0) {
        return createNewCheckpointsVisitsPDF(data);
      }
    });
  }


  updatefilterDateFrom = (date:any) => {
    this.setState({ currentPage: 0, filterDateFrom: date, dataReceived: false });
    this.reloadPage({ currentPage: 0, perPage: this.state.perPage, filterDateFrom: date, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  updatefilterDateTo = (date:any) => {
    this.setState({ currentPage: 0, filterDateTo: date, dataReceived: false });
    this.reloadPage({ currentPage: 0, perPage: this.state.perPage, filterDateFrom: this.state.filterDateFrom, filterDateTo: date, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  updatefilterVehicle = (event:any) => {
    this.setState({ currentPage: 0, filterVehicle: event.target.value, dataReceived: false });
    this.reloadPage({ currentPage: 0, perPage: this.state.perPage, filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: event.target.value, filterCheckpoint: this.state.filterCheckpoint });
  }


  updatefilterCheckpoint = (event:any) => {
    this.setState({ currentPage: 0, filterCheckpoint: event.target.value, dataReceived: false });
    this.reloadPage({ currentPage: 0, perPage: this.state.perPage, filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: event.target.value });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 })
    this.reloadPage({ currentPage: 0, perPage: parseInt(e.target.value), filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage, dataReceived: false });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage, dataReceived: false });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, filterDateFrom: this.state.filterDateFrom, filterDateTo: this.state.filterDateTo, filterVehicle: this.state.filterVehicle, filterCheckpoint: this.state.filterCheckpoint });
  }


  reloadPage = (options: { currentPage: number, perPage: number, filterDateFrom: any, filterDateTo: any, filterVehicle: string, filterCheckpoint: string }) => {
    const { currentPage, perPage, filterDateFrom, filterDateTo, filterVehicle, filterCheckpoint } = options
    this.props.router.navigate(`/checkpoints/filter?filterVehicle=${filterVehicle}&filterDateFrom=${moment(filterDateFrom).format('X')}&filterDateTo=${moment(filterDateTo).format('X')}&filterCheckpoint=${filterCheckpoint}`)
    this.props.fetchDateCheckpointVisitsVehicle({ limit: perPage, skip: perPage * currentPage, filterDateFrom: moment(filterDateFrom).format('YYYY-MM-DD'), filterDateTo: moment(filterDateTo).format('YYYY-MM-DD'), filterVehicle, filterCheckpoint })
    .then(() => {
      if(this.props.checkpoint.status === fetchStates.success) {
        const { checkpointsDateVehicleVisits, checkpointsDateVehicleVisitsCount } = this.props.checkpoint
        this.setState({ checkpointsDateVehicleVisits, checkpointsDateVehicleVisitsCount, dataReceived: true })
      }
    })
  }


  render() {

    const { status } = this.props.checkpoint
    const { filterDateFrom, filterDateTo, todayDate, vehicles, checkpoints, filterCheckpoint, filterVehicle, checkpointsDateVehicleVisits, checkpointsDateVehicleVisitsCount, currentPage, perPage, dataReceived } = this.state;

    return (
      <TemplatePage>

        <Card 
          title={t(207)} 
          button={true} 
          buttonTitle='PDF' 
          buttonClickable={true}
          buttonLink=''
          buttonHandleOnClick={() => this.handleDownloadPDF()}
          secondButton={true}
          secondButtonTitle='CSV'
          secondButtonClickable={true}
          secondButtonLink=''
          secondButtonHandleOnClick={() => this.handleDownloadCSV()}
          tabnav='checkpoints'
          tabnavId={0}
          time={false}
          animate
        >

          { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

          <form className="form">
            <div className="filter">

            <span className="filter__filter-group">
                <span className="filter__filter-text">{t(22)}</span>
                  <span className="filter_filter-select">
                  <select 
                    className="filter__select" 
                    value={filterVehicle} 
                    onChange={e => this.updatefilterVehicle(e)}>
                    <option value="">{t(1122)}</option>
                    {vehicles.map((vehicle: {vehicle_id: string, module_imei: string, custom_id: string}) => (
                      <option key={vehicle.vehicle_id} value={vehicle.module_imei}>{vehicle.custom_id}</option>
                    ))}
                  </select>
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1123)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDateFrom}
                    selectsStart
                    onChange={this.updatefilterDateFrom}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    required={true}
                  />
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1124)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDateTo}
                    selectsStart
                    onChange={this.updatefilterDateTo}
                    dateFormat='dd/MM/yyyy'
                    maxDate={todayDate}
                    required={true}
                  />
                </span>
              </span>


              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(1125)}</span>
                <span className="filter_filter-select">
                  <select 
                    className="filter__select" 
                    value={filterCheckpoint} 
                    onChange={e => this.updatefilterCheckpoint(e)}>
                    <option value="">{t(1126)}</option>
                    {checkpoints.map((checkpoint: {checkpoint_id: string, custom_id: string }) => (
                      <option key={checkpoint.checkpoint_id} value={checkpoint.checkpoint_id}>{checkpoint.custom_id}</option>
                    ))}
                  </select>
                </span>
              </span>


            </div>
          </form>

            { dataReceived === false ? (<Preloader type="fullscreen" />) : (
            <div className="table-wrapper">
            <table className="table">
            <TableHeader 
              columns={['', t(22), t(1118), t(1119), t(1120), t(1121)]} 
            />
              <tbody className="table__body table__body--striped">
                {checkpointsDateVehicleVisits && checkpointsDateVehicleVisits.map((item: {id: number, vehicle: string, custom_id: string, stop_ts: number, start_ts: number, diff: number}, index:number) => {
                  return (
                  <tr key={index}>
                    <td className="simple">
                      <Tooltip anchorId={`tooltip-vehicle-overview${index}`}/>
                      <span id={`tooltip-vehicle-overview${index}`} data-tooltip-content={t(33)}>
                        <Link to={`/checkpoints/checkpoint/${item.id}`}>
                            <IconVisibility task button color="#ffffff" />
                        </Link>
                      </span>
                    </td>
                    <td>
                      {item.vehicle}
                    </td>
                    <td>
                      {item.custom_id}
                    </td>
                    <td>
                      {item.stop_ts}
                    </td>
                    <td>
                      {item.start_ts}
                    </td>
                    <td>
                      { moment("1900-01-01 00:00:00").add(item.diff, 'seconds').format("HH:mm:ss") }
                    </td>
                  </tr>
                  )
                })
              }
              </tbody>

              <TableFooter 
                button={false}
                colSpan={7}
                currentPage={ currentPage }
                perPage={ perPage }
                itemsCount={ checkpointsDateVehicleVisitsCount }
                prevPage={() => this.prevPage(currentPage - 1)}
                nextPage={() => this.nextPage(currentPage + 1)}
                updatePagination={this.updatePagination}
              />

            </table>
            </div>
            )}
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ checkpoint, vehicle, auth }: RootState) => ({ checkpoint, vehicle, auth }),
  { fetchDateCheckpointVisitsVehicle, fetchAllVehicles, fetchAllCheckpoints }
)(CheckpointsFilter)))
const authByRole = {
  superadministrator: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_lock_unlock: true,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,
      vehicle_calendar: true,

      reports: true, 
      stations: true, 
      competitors: true, 
      users: true, 
      alerts: true, 
      groups: true, 
      billing: true,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,
      data_map: true, 
      communication: true,
      workers: true,
      error_log: true,
      rfid: true, 
      checkpoints: true,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: false,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: false,

      reports: false, 
      stations: false, 
      competitors: false, 
      alerts: false,
      users: false, 
      groups: false, 
      billing: false,  

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,
      data_map: false, 
      communication: false,
      workers: false,
      error_log: false,
      rfid: false, 
      checkpoints: false,
    },
  },


  administrator: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_lock_unlock: true,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,
      vehicle_calendar: true,

      reports: true, 
      stations: true, 
      competitors: true, 
      users: true, 
      alerts: true, 
      groups: true, 
      billing: true,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,

      error_log: false,
      data_map: false, 
      communication: false,
      workers: false,
      rfid: false, 
      checkpoints: false,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: false,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: false,

      reports: false, 
      stations: false, 
      competitors: false, 
      alerts: false,
      users: false, 
      groups: false, 
      billing: false,  

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,

      error_log: true,
      data_map: true, 
      communication: true,
      workers: true,
      rfid: true, 
      checkpoints: true,
    },
  },


  distributor: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_calendar: true,
      vehicle_lock_unlock: true,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,

      reports: true, 
      stations: true, 
      competitors: true, 
      users: true, 
      alerts: true, 
      groups: true, 
      billing: true,  

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,
      data_map: false, 
      communication: false,
      workers: false,
      error_log: false,

      rfid: false, 
      checkpoints: false,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: false,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: false,

      reports: false, 
      stations: false, 
      competitors: false, 
      alerts: false,
      users: false, 
      groups: false, 
      billing: false,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,
      data_map: true, 
      communication: true,
      workers: true,
      error_log: true,

      rfid: true, 
      checkpoints: true,
    },
  },


  manager: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_lock_unlock: true,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,
      vehicle_calendar: true,

      reports: true, 
      stations: true, 
      competitors: true, 
      users: true, 
      alerts: true, 
      groups: true, 
      billing: false,  

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,
      data_map: false, 
      communication: false,
      workers: false,
      error_log: false,

      rfid: false, 
      checkpoints: false,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: false,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: false,

      reports: false, 
      stations: false, 
      competitors: false, 
      alerts: false,
      users: false, 
      groups: false, 
      billing: true,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,
      data_map: true, 
      communication: true,
      workers: true,
      error_log: true,

      rfid: true, 
      checkpoints: true,
    },
  },


  mechanic: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_lock_unlock: false,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,
      vehicle_calendar: false,

      reports: true, 
      stations: false, 
      competitors: false, 
      users: false, 
      alerts: true, 
      groups: false, 
      billing: false,  

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,
      data_map: false, 
      communication: false,
      workers: false,
      error_log: false,

      rfid: false, 
      checkpoints: false,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: true,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: true,

      reports: false, 
      stations: true, 
      competitors: true, 
      alerts: false,
      users: true, 
      groups: true, 
      billing: true,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,
      data_map: true, 
      communication: true,
      workers: true,
      error_log: true,

      rfid: true, 
      checkpoints: true,
    },
  },


  user: {
    authorized: { 
      vehicles: true,
      vehicle_now: true, 
      vehicle_reports: true,
      vehicle_fuel_usage: true,
      vehicle_routes: true,
      vehicle_refuel_history: true,
      
      vehicle_lock_unlock_history: false,
      vehicle_diagnostics: false,
      vehicle_alerts_settings: false,
      vehicle_lock_unlock: false,
      vehicle_pairing: false,
      vehicle_edit: false,
      vehicle_delete: false,
      vehicle_calendar: false,

      reports: true, 
      stations: false, 
      competitors: false, 
      users: false, 
      alerts: true, 
      billing: false,
      groups: false, 

      imei: false,
      accounts: false, 
      modules: false, 
      distributor_account_groups: false,
      data_map: false, 
      communication: false,
      workers: false,
      error_log: false,

      rfid: false, 
      checkpoints: false,
    },
    disabled: {
      vehicles: false,
      vehicle_now: false, 
      vehicle_reports: false,
      vehicle_fuel_usage: false,
      vehicle_routes: false,
      vehicle_refuel_history: false,

      vehicle_lock_unlock_history: true,
      vehicle_diagnostics: true,
      vehicle_alerts_settings: true,
      vehicle_lock_unlock: true,
      vehicle_pairing: true,
      vehicle_edit: true,
      vehicle_delete: true,
      vehicle_calendar: true,

      reports: false, 
      stations: true, 
      competitors: true, 
      alerts: false,
      users: true, 
      groups: true, 
      billing: true,  

      imei: true,
      accounts: true, 
      modules: true, 
      distributor_account_groups: true,
      data_map: true, 
      communication: true,
      workers: true,
      error_log: true,

      rfid: true, 
      checkpoints: true,
    },
  }
}

export default authByRole
import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchAllStations } from '../../actions/station'
import { fetchAllCompetitors } from '../../actions/competitor'
import { checkAuth } from '../../actions/auth'
import { fetchAllFilteredCommunication, fetchRawImeis } from '../../actions/communication'
import TemplatePage from '../templates/TemplatePage'
import Card from '../partials/Card'
import CtaBox from '../partials/CtaBox'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from '../partials/WithRouter'
import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM } from '../../config'
import { GoogleMap, Marker, InfoBox, Circle } from '@react-google-maps/api'
import moment from 'moment-timezone'
import fetchStates from '../../types/fetchStates'
import { Link } from 'react-router-dom'
import { IconArrowRight, IconArrowLeft, IconNoData } from '../partials/Icons'
import DatePicker from 'react-datepicker'
import Preloader from '../partials/Preloader'
import markerStation from'../../img/marker-station.png'
import markerCompetitor from'../../img/marker-competitor.png'
import markerLocked from'../../img/marker-locked.png'
import markerVehiclePosition from'../../img/marker-vehicle-position.png';
import markerVehicleRefuel from'../../img/marker-vehicle-refuel.png'
import markerVehicleTime from'../../img/marker-vehicle-time.png'
import markerVehicleCourse from'../../img/marker-vehicle-course.png'
import markerVehicleDistance from'../../img/marker-vehicle-distance.png'
import markerVehicleStop from'../../img/marker-vehicle-stop.png'


interface DataMapProps {
  station: any,
  competitor: any,
  communication: any,
  auth: any,
  checkAuth: () => Promise<any>,
  fetchAllStations: (options: { limit: number, skip: number }) => Promise<any>,
  fetchRawImeis: (date: any) => Promise<any>,
  fetchAllCompetitors: (options: { limit: number, skip: number }) => Promise<any>,
  fetchAllFilteredCommunication: (options: { limit: number, skip: number, reason: string, date: any, imei: string }) => Promise<any>,
}


interface DataMapState {
  mapLat: number,
  mapLng: number,
  markerLat: number,
  markerLng: number,
  mapZoom: number,
  openInfoWindowMarkerId: string,
  currentPage: number,
  filterPerPage: number,
  filterReason: string,
  filterActiveImei: string,
  filterDate: any,
  todayDate: any,
  imeiList: Array<any>,
  noImeis: boolean,
  noData: boolean,
  communication: Array<any>, 
  communicationCount: number,
}


export class DataMap extends React.Component<DataMapProps, DataMapState> {

  state = {
    mapLat: DEFAULT_LAT,
    mapLng: DEFAULT_LNG,
    markerLat: DEFAULT_LAT,
    markerLng: DEFAULT_LNG,
    mapZoom: DEFAULT_ZOOM,
    openInfoWindowMarkerId: '',
    currentPage: 0,
    filterPerPage: 10,
    filterReason: 'all',
    filterActiveImei: '',
    filterDate: new Date(),
    todayDate: new Date(),
    imeiList: [],
    noImeis: true,
    noData: true,
    communication: [],
    communicationCount: 0,
  }


  getTimezoneOffset(time: any) {
    const UTCServerTime = moment(time).format('YYYY-MM-DD 00:00:00')
    return moment(UTCServerTime).format('X')
  }


  componentDidMount() {
    this.props.fetchAllStations({ limit: 100, skip: 0 });
    this.props.fetchAllCompetitors({ limit: 100, skip: 0 });
    this.props.checkAuth()
    .then(() => {
      const { lat, lng, zoom } = this.props.auth;
      this.setState({
        mapLat: lat !== undefined && lat !== null ? parseFloat(lat) : DEFAULT_LAT,
        mapLng: lng !== undefined && lng !== null ? parseFloat(lng) : DEFAULT_LNG,
        mapZoom: zoom !== undefined && zoom !== null ? parseInt(zoom) : DEFAULT_ZOOM,
        markerLat: lat !== undefined && lat !== null ? parseFloat(lat) : DEFAULT_LAT,
        markerLng: lng !== undefined && lng !== null ? parseFloat(lng) : DEFAULT_LNG,
      });
    });
    this.reloadPage({ currentPage: this.state.currentPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  reloadPage = (options: { currentPage: number, filterPerPage: number, filterReason: string, filterDate: any, filterActiveImei: string }) => {
    const { currentPage, filterPerPage, filterReason, filterDate, filterActiveImei } = options
    this.setState({ noData: true })
    this.props.fetchRawImeis(filterDate)
    .then(() => {
      if(this.props.communication.status === fetchStates.success) {
        if(this.props.communication.activeImeis.length > 0) {
          const { activeImeis } = this.props.communication
          this.setState({ imeiList: activeImeis, noImeis: false, filterActiveImei: filterActiveImei })
          this.props.fetchAllFilteredCommunication({ limit: filterPerPage, skip: filterPerPage * currentPage, reason: filterReason, date: filterDate, imei: filterActiveImei })
          .then(() => {
            if(this.props.communication.status === fetchStates.success) {
              if(this.props.communication.communication.length > 0) {
                const { communication, communicationCount } = this.props.communication
                this.setState({ communication, communicationCount, noData: false })
              } else {
                this.setState({ noData: true })
              }
            }
          })
        }
      } else {
        this.setState({ imeiList: [], noImeis: true })
      }
    });

  }


  updateFilterPerPage = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ currentPage: 0, filterPerPage: parseInt(e.target.value) });
    this.reloadPage({ currentPage: 0, filterPerPage: parseInt(e.target.value), filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  updateFilterReason = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    this.setState({ currentPage: 0, filterReason: e.target.value });
    this.reloadPage({ currentPage: 0, filterPerPage: this.state.filterPerPage, filterReason: e.target.value, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  updatefilterActiveImei = (e: React.ChangeEvent<HTMLInputElement>): any => {
    this.setState({ currentPage: 0, filterActiveImei: e.target.value });
    this.reloadPage({ currentPage: 0, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: e.target.value });
  }


  updateFilterDate = (date:any) => {
    this.setState({ currentPage: 0, filterDate: date, filterActiveImei: 'all', filterReason: 'all' });
    this.reloadPage({ currentPage: 0, filterPerPage: this.state.filterPerPage, filterReason: 'all', filterDate: this.getTimezoneOffset(date), filterActiveImei: 'all' });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, filterPerPage: this.state.filterPerPage, filterReason: this.state.filterReason, filterDate: this.getTimezoneOffset(this.state.filterDate), filterActiveImei: this.state.filterActiveImei });
  }


  handleMapClick = (e: any) => {
    this.setState({
      mapLat: parseFloat(e.latLng.lat()),
      mapLng: parseFloat(e.latLng.lng()),
      markerLat: parseFloat(e.latLng.lat()),
      markerLng: parseFloat(e.latLng.lng()),
    });
  }


  getMarkerSymbol = (reason: string) => {
    switch(reason) {
      case 'r':
        return markerVehicleRefuel
      case 'd':
        return markerVehicleDistance
      case 't':
        return markerVehicleTime
      case 'c':
        return markerVehicleCourse
      case 's':
        return markerVehicleStop
      default:
        return markerVehiclePosition
    }
  }


  drawStationCircle = (options: { markerLat: number, markerLng: number, range: number }) => {
    const { markerLat, markerLng, range } = options
    return <Circle
      // required
      center={{
        lat: markerLat,
        lng: markerLng
      }}
      // required
      options={{
        strokeColor: '#248E63',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#248E63',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: range,
        zIndex: 1
      }}
    />
  }


  drawCompetitorCircle = (options: { markerLat: number, markerLng: number, range: number }) => {
    const { markerLat, markerLng, range } = options
    return <Circle
    // required
    center={{
      lat: markerLat,
      lng: markerLng
    }}
    // required
    options={{
      strokeColor: '#E34B5F',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#E34B5F',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: range,
      zIndex: 1
      }}
    />
  }


  render() {

    const { competitors } = this.props.competitor;
    const { stations } = this.props.station;
    const { status } = this.props.communication;
    const { communication, communicationCount, markerLat, markerLng, mapLat, mapLng, mapZoom, currentPage, filterPerPage, filterReason, filterDate, todayDate, imeiList, noImeis, noData } = this.state;


    return (
      <TemplatePage>

        <Card 
          button={false}
          title={t(8)} 
          time={false}
          animate
        >
          { status === fetchStates.fetching ? <Preloader type="fullscreen" /> : <></> }

          { noImeis === true ? 
            (
              <CtaBox padding type="info" title={t(1202)} icon={<IconNoData size={54} color="#ffffff" />}>
                {t(1203)}
              </CtaBox>
            ) : (
              <>
                <form className="form">
                  <div className="filter">

                  <span className="filter__filter-group">
                    <span className="filter__filter-text">{t(2050)}</span>
                    <span className="filter_filter-select">
                      <DatePicker
                        selected={filterDate}
                        selectsStart
                        onChange={this.updateFilterDate}
                        dateFormat='dd/MM/yyyy'
                        maxDate={todayDate}
                        required={true}
                      />
                    </span>
                  </span>


                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(2073)}</span>
                      <span className="filter_filter-select">
                        <select 
                          className="filter__select" 
                          value={filterReason} 
                          onChange={e => this.updateFilterReason(e)}
                        >
                          <option value="all">{t(2074)}</option>
                          <option>r</option>
                          <option>d</option>
                          <option>t</option>
                          <option>c</option>
                          <option>s</option>
                        </select>
                      </span>
                    </span>


                    <span className="filter__filter-group">
                      <span className="filter__filter-text">IMEI</span>
                        <span className="filter_filter-select">
                          <input type="text" list="data" onChange={e => this.updatefilterActiveImei(e)} />
                          <datalist id="data">
                            {imeiList.map((imei: {id_mod: string}, index: number) => (
                              <option key={index}>{imei.id_mod}</option>
                            ))}
                          </datalist>
                      </span>
                    </span>

                    <span className="filter__filter-group">
                      <span className="filter__filter-text">{t(2001)}</span>
                      <span className="filter_filter-select">
                        <select className="filter__select" value={filterPerPage} onChange={e => this.updateFilterPerPage(e)}>
                          <option>10</option>
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option>500</option>
                        </select>
                      </span>
                    </span>

                    <span className="filter__filter-group filter-group--last">
                    <span className="filter__item-num">{communicationCount === 0 ? 0 : currentPage * filterPerPage + 1} - {communicationCount < filterPerPage * (currentPage + 1) ? communicationCount : filterPerPage * (currentPage + 1)} {t(2075)} {communicationCount}</span>
                    <span className="filter__nav">
                    {currentPage > 0 ? (
                      <span className="filter__prev filter__prev--active" onClick={() => this.prevPage(currentPage - 1)}>
                        <IconArrowLeft  color="#ffffff"/>
                      </span>
                    ) : (
                      <span className="filter__prev">
                        <IconArrowLeft color="#9697AE" />
                      </span>
                    )} 

                    {communicationCount < filterPerPage * (currentPage + 1) +1 ? (
                      <span className="filter__prev">
                        <IconArrowRight color="#9697AE" />
                      </span>
                    ) : (                              
                      <span className="filter__next filter__next--active" onClick={() => this.nextPage(currentPage + 1)}>
                        <IconArrowRight color="#ffffff" />
                      </span>
                    )} 
                    </span>
                    </span>
                  </div>
                </form>

                { noData === true ? 
                  (
                    <CtaBox padding type="info" title={t(4277)} icon={<IconNoData size={54} color="#ffffff" />}>
                      {t(2166)}
                    </CtaBox>
                  ) : (
                    <GoogleMap
                      center={{ lat: mapLat, lng: mapLng }}
                      zoom={ mapZoom }
                      // onBoundsChanged={(e) => this.handleBoundsChanged(e)}
                      onClick={ e => this.handleMapClick(e) }
                    >
                      <Marker
                        position={{
                          lat: markerLat,
                          lng: markerLng
                        }}
                        icon={
                          // @ts-expect-error
                          new window.google.maps.MarkerImage(
                            markerLocked,
                            null, 
                            null, 
                            null, 
                            new window.google.maps.Size(48, 48)
                          )
                        }
                      />

                      { 
                        stations !== undefined && stations.map((station: any) => {
                          return (<span key={station.station_id}>
                                    <Marker
                                    
                                    position={{
                                      lat: parseFloat(station.lat),
                                      lng: parseFloat(station.lng)
                                    }}
                                    icon={
                                      // @ts-expect-error
                                      new window.google.maps.MarkerImage(
                                        markerStation,
                                        null, 
                                        null, 
                                        null, 
                                        new window.google.maps.Size(48, 48)
                                      )
                                    }
                                  />
                                  { this.drawStationCircle({ markerLat: parseFloat(station.lat), markerLng: parseFloat(station.lng), range: station.range }) }
                                  
                                  <InfoBox
                                    // @ts-expect-error
                                    position={{
                                      lat: station.lat,
                                      lng: station.lng
                                    }}
                                    options={{ closeBoxURL: ``, enableEventPropagation: true,
                                    pixelOffset: new window.google.maps.Size(-16, -95) }}
                                  >
                                    <div className='marker-label-wrapper'>
                                      <div className='marker-label'>
                                        <div className='marker-label-title'>{station.custom_id}</div>
                                        <div className='marker-label-text'>{t(21)}: {station.refueled}</div>
                                      </div>
                                      <span className='marker-label-pointer'></span>
                                    </div>
                                  </InfoBox>
                                </span> )

                        })
                      }

                      { 
                        competitors !== undefined && competitors.map((competitor: any) => {
                          return <Marker
                                    key={competitor.station_id}
                                    position={{
                                      lat: parseFloat(competitor.lat),
                                      lng: parseFloat(competitor.lng)
                                    }}
                                    icon={
                                      // @ts-expect-error
                                      new window.google.maps.MarkerImage(
                                        markerCompetitor,
                                        null, 
                                        null, 
                                        null, 
                                        new window.google.maps.Size(48, 48)
                                      )
                                    }
                                  >
                                    { this.drawCompetitorCircle({ markerLat: parseFloat(competitor.lat), markerLng: parseFloat(competitor.lng), range: competitor.range }) }
                                  </Marker>
                        })
                      }


                      {
                        communication !== undefined && communication.length > 0 && 
                          communication.map((item: any, index: number) => {
                            return (

                              <Marker
                                key={ index }
                                position={{
                                  lat: parseFloat(item.loc_lt),
                                  lng: parseFloat(item.loc_ln)
                                }}
                                icon={
                                  // @ts-expect-error
                                  new window.google.maps.MarkerImage(
                                    this.getMarkerSymbol(item.loc_re),
                                    null, 
                                    null, 
                                    null, 
                                    new window.google.maps.Size(48, 48)
                                  )
                                }
                              >
                              </Marker>
                            )
                          })
                      }
                      <div className="map-constraints-container" />
                    </GoogleMap>
                  )
                }

                <span className='form-group__explanation'>{t(105)} <Link to='/settings'>{t(106)}</Link></span>
              </>
            ) 
          }
        </Card>
      </TemplatePage>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, station, communication, competitor }: RootState) => ({ auth, station, communication, competitor }),
  { fetchAllStations, fetchAllCompetitors, fetchAllFilteredCommunication, fetchRawImeis, checkAuth }
)(DataMap)))
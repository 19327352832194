import React from 'react'
import { RootState } from '../../reducers'
import { connect } from 'react-redux'
import { fetchPersonGroups } from '../../actions/person'
import { fetchFleetExploitation } from '../../actions/vehicle';
import { fetchAccountById } from '../../actions/account';
import Card from './Card'
import t from '../translation/translate'
import withAuthorization from '../auth/withAuthorization'
import withRouter from './WithRouter'
import fetchStates from '../../types/fetchStates'
import Authorization from './Authorization'
import DatePicker from 'react-datepicker'
import Preloader from './Preloader'
import moment from 'moment-timezone'
import TableFooter from './TableFooter'
import { Tooltip } from 'react-tooltip'
import { Link } from 'react-router-dom'
import { IconVisibility, IconWarning, IconDropUp, IconDropDown, IconNoData } from '../partials/Icons'
import CtaBox from './CtaBox'


interface CardFleetExploitationProps {
  auth: any,
  vehicle: any,
  person: any,
  account: any,
  fetchAccountById: (account_id: string) => Promise<void>,
  fetchPersonGroups: () => Promise<void>,
  fetchFleetExploitation: (options: { limit: number, skip: number, param: string, paramValue: boolean, month: string, group_id: string }) => Promise<any>,
}


interface CardFleetExploitationState {
  fetchingData: boolean,
  filterDate: Date,
  todayDate: Date,
  currentPage: number,
  perPage: number,
  vehiclesExploitation: any,
  vehiclesExploitationCount: number,
  dataLoaded: boolean,
  dataGroupsLoaded: boolean,
  groups: any,
  filterGroup: string,
  param: string, 
  paramValue: boolean,

  exploitation_h_day: number,
  exploitation_satuday: boolean,
  exploitation_sunday: boolean
}


export class CardFleetExploitation extends React.Component<CardFleetExploitationProps, CardFleetExploitationState> {

  state = {
    fetchingData: true,
    filterDate: new Date(),
    todayDate: new Date(),
    currentPage: 0,
    perPage: 10,
    vehiclesExploitation: [],
    vehiclesExploitationCount: 0,
    dataLoaded: false,
    dataGroupsLoaded: false,
    groups: [],
    filterGroup: 'all',
    param: 'custom_id',
    paramValue: true,

    exploitation_h_day: 0,
    exploitation_satuday: false,
    exploitation_sunday: false
  }


  componentDidMount = async () => {
    if(this.props.auth.authorized.vehicles === true) {
      await this.fetchAccountById();
      await this.fetchPersonGroups();
      const { param, paramValue } = this.state;
      await this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue });
    }
  }


  setParam = (param: string) => {
    this.setState({ param, paramValue: !this.state.paramValue })
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage, param, paramValue: !this.state.paramValue })
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { param, paramValue } = this.state
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0 });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0, param, paramValue });
  }


  prevPage = (prevPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage, param, paramValue });
  }


  nextPage = (nextPage: number) => {
    const { param, paramValue } = this.state
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage, param, paramValue });
  }


  reloadPage = async (options: { currentPage: number, perPage: number, param: string, paramValue: boolean }) => {
    this.setState({ fetchingData: true })
    const { currentPage, perPage, param, paramValue } = options;
    await this.props.fetchFleetExploitation({ limit: perPage, skip: perPage * currentPage, month: this.state.filterDate.toISOString().slice(0, 7), group_id: this.state.filterGroup, param, paramValue });
    if(this.props.vehicle.status === fetchStates.success) {
      const { vehiclesExploitation, vehiclesExploitationCount } = this.props.vehicle;
      this.setState({ vehiclesExploitation, vehiclesExploitationCount, fetchingData: false });
    }
  }


  fetchPersonGroups = async () => {
    await this.props.fetchPersonGroups();
    if(this.props.person.status === fetchStates.success) {
      const { groups } = this.props.person.person;
      if(groups.length > 0) {
        let filterGroup = 'all';
        if(groups.length === 1) {
          filterGroup = groups[0].group_id;
        }     
        this.setState({ groups, filterGroup, dataGroupsLoaded: true });
      } else {
        this.setState({ dataLoaded: true, dataGroupsLoaded: false });
      }
    }
  }


  fetchAccountById = async () => {
    await this.props.fetchAccountById(this.props.auth.account_id);
    if(this.props.account.status === fetchStates.success) {
      const { exploitation_h_day, exploitation_satuday, exploitation_sunday } = this.props.account.account
      this.setState({ exploitation_h_day, exploitation_satuday, exploitation_sunday })
    }
  }


  /* FUNCTIONS TO HANDLE FILTER */
  updateFilterDate = (date: Date) => {
    const { param, paramValue } = this.state
    this.setState({ filterDate: date }, () => this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue }));
  }


  updateFilterGroup = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const { param, paramValue } = this.state
    this.setState({ filterGroup: e.target.value }, () => this.reloadPage({ currentPage: this.state.currentPage, perPage: this.state.perPage, param, paramValue }));
  }


  render() {

    const { vehiclesExploitation, vehiclesExploitationCount, currentPage, perPage, todayDate, filterDate, groups, filterGroup, dataGroupsLoaded, fetchingData, param, paramValue, exploitation_h_day, exploitation_satuday, exploitation_sunday } = this.state

    return (
      <Authorization requiresAuth="vehicles">
        <Card
          button={false} 
          title={`Fleet Exploitation (${exploitation_h_day}h/day ${exploitation_satuday === false ? 'excluding Saturday' : 'including Saturday'} and ${exploitation_sunday === false ? 'excluding Sunday' : 'including Sunday'})`} 
          time={false}
          animate
        >
          <form className="form">
            <div className="filter">
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2050)}</span>
                <span className="filter_filter-select">
                  <DatePicker
                    selected={filterDate}
                    selectsStart
                    onChange={this.updateFilterDate}
                    dateFormat='yyyy-MM'
                    maxDate={todayDate}
                    minDate={new Date(2021, 0, 1, 0, 0, 0, 0)}
                    required={true}
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                </span>
              </span>
              <span className="filter__filter-group">
                <span className="filter__filter-text">{t(2429)}</span>
                <span className="filter_filter-select">
                  <select 
                    name="groups" 
                    onChange={e => this.updateFilterGroup(e)}
                    value={filterGroup}
                  >
                    <option key="0" value="all">{t(1152)}</option>
                    { 
                      groups.map((group: {group_id: number, group_title: string}) => <option key={group.group_id} value={group.group_id}>{group.group_title}</option>)
                    }
                  </select>
                </span>
              </span>
            </div>
          </form>
          <>
            { 
              dataGroupsLoaded === false ? (
                <CtaBox padding={true} type="warning" title={t(4278)} icon={<IconNoData size={54} color="#ffffff" />}>
                  {t(2490)}
                </CtaBox>
              ) : (
                <div className="table-wrapper">
                { fetchingData ? <Preloader type="preloader-center" /> : (
                  <>
                    {
                      vehiclesExploitation.length === 0 ? (
                        <div className="cta-box__padding-wrapper">
                          <div className="msg msg--info msg--no-margin"><IconWarning color="#ffffff" />No data were generated for this month yet. Try previous month.</div>
                        </div>

                      ) : (
                        <table className="table">
                        <thead className="table__header">
                          <tr>
                            <th></th>
                            <th className="th--clickable" onClick={() => this.setParam('custom_id')}>ID {param === 'custom_id' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                            <th>Group</th>
                            <th className="th--clickable" onClick={() => this.setParam('total_time_s')}>Exploited (h) {param === 'total_time_s' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                            <th className="th--clickable" onClick={() => this.setParam('total_time_s')}>Exploited (%) {param === 'total_time_s' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                            <th className="th--clickable" onClick={() => this.setParam('gas_usage')}>Gas usage {param === 'gas_usage' &&  (paramValue === true ? <IconDropUp /> : <IconDropDown />)}</th>
                          </tr>
                        </thead>
                        <tbody className="table__body table__body--striped">
                          {
                            vehiclesExploitation && vehiclesExploitation.map((item:  {vehicle_id: string, custom_id: string, total_time_s:number, exploitation_percentage: number, gas_usage: number, group_title: string }, index: number) => {
                              return (
                                <tr key={item.vehicle_id}>
                                  <td className="simple">
                                    <Tooltip anchorId={`tooltip-on-the-fly${index}`} />
                                    <Link to={`/vehicles/now/${item.vehicle_id}`}>
                                      <span id={`tooltip-on-the-fly${index}`} data-tooltip-content={t(16)}>
                                        <IconVisibility 
                                          task 
                                          button 
                                          color="#ffffff"
                                        />
                                      </span>
                                    </Link>
                                  </td>
                                  <td>{item.custom_id}</td>
                                  <td>{item.group_title}</td>
                                  <td>{moment.duration(item.total_time_s, 'seconds').format("hh:mm:ss") === '00' ? '00:00:00' : moment.duration(item.total_time_s, 'seconds').format("hh:mm:ss")}</td>
                                  <td>{item.exploitation_percentage} %</td>
                                  <td>{item.gas_usage} %</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                        <TableFooter 
                          button={false}
                          colSpan={6}
                          currentPage={ currentPage }
                          perPage={ perPage }
                          itemsCount={ vehiclesExploitationCount }
                          prevPage={() => this.prevPage(currentPage - 1)}
                          nextPage={() => this.nextPage(currentPage + 1)}
                          updatePagination={this.updatePagination}
                        />
                      </table>
                      )
                    }
                  
                  
                  </>

                )}
                </div>
              )
            }
          </>
        </Card>
      </Authorization>
    )
  }
}


export default withAuthorization(withRouter(connect(
  ({ auth, vehicle, person, account }: RootState) => ({ auth, vehicle, person, account }),
  { fetchFleetExploitation, fetchPersonGroups, fetchAccountById }
)(CardFleetExploitation)))
import { STATION } from '../types'
import fetchStates from '../types/fetchStates'


interface StationState {
  stations : [],
  stationsCount: number,
}


const DEFAULT_STATIONS:StationState = {
  stations : [],
  stationsCount: 0,
}


const station = (state = DEFAULT_STATIONS, action:any) => {
  switch(action.type) {
    case STATION.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: '',
        fields: []
      };
    case STATION.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error, 
        msg: action.errors, 
        fields: action.errors && action.errors.map((item: {path: string}) => item.path !== undefined && item.path.toString())
      };
    case STATION.FETCH_ALL_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        stations: action.stations,
        stationsCount: parseInt(action.stationsCount, 10),
      };
    case STATION.FETCH_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        station: action.station,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case STATION.FETCH_DELETE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case STATION.FETCH_ADD_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
      };
    case STATION.FETCH_UPDATE_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((item: {path: string}) => item.path.toString())
      };
    case STATION.PUSH_MESSAGE:
      return {
        ...state,
        status: action.status,
        msg: action.message,
        fields: action.fields
      };
    case STATION.RESET:
      return {
        state: []
      };
    default:
      return state;
  }
}


export default station
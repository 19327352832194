import React from 'react'


interface TemplateFullwidthProps {
  children: JSX.Element
}


const TemplateFullwidth: React.FC<TemplateFullwidthProps> = ({ children }) => {
  return (
    <div className="template template__page-fullwidth">
      <div className="main-content">
        <div className="main-content__wrapper">
          {children}
        </div>
      </div>
    </div>
  )
}


export default TemplateFullwidth